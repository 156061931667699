import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { SearchSpan } from "../../../common/Global/globalStyles.style";
import Sidebar from "../../../common/Sidebar";
import Topbar from "../../../common/Topbar";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import TableDropDown from '../../../common/DropdownMenus/TableDropdown';
import MasterMenuTabs from "../Common/topmenu";
import { 
    getLocalStorageVariables, 
    getThemeSettingsVariables, 
    isEmptyVariable, 
    sortTable,
    isEmptyArray,
    truncateString,
    getStringWithSpaceAfterComma,
    GetOrderedMasterTabMenuPlanning
} from "../../../common/Global/commonFunctions";
import { BrowserRouter as Router } from "react-router-dom";
import moment from "moment";
import Pagination from "../../../common/Global/pagination";
import { getDropdownActionArrayFollowUp } from "../../../common/Global/reviewFunctions";
import AssignAllResourcesDialog from "./assignAllResourcesDialog";
import AlertDialog from "../../../common/AlertDialog";
import ReviewHistoryDialog from "../../../common/reviewhistorydialog";
// import AddTasksandResourcesChecklist from "./addTaskandResourcesChecklist";
import AddTasksandResourcesFunction from "./addTaskandResourcesFunction";
import UpdateReviewStatusDialog from "./assignmentupdatereviewstatusdialog";
import FollowUpObservation from "./followUpObservation";
import FollowUpDetailsDialog from "./followUpDetailsDialog";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const sortName = "c.companyName";
const sortReferenceNo = "a.referenceNo";
const sortDescription = "a.assignmentTypeName";
const sortEndDate = "a.endDate";
const sortReportDate = "a.reportDate";
const sortArea = "a.assignmentArea";
const sortLocation = "unitNameCityList";
const sortunitAddressList = "unitAddressList";
const sortunitCityList ="unitCityList";

const activeStatus="Reviewed";
const activeStatusLabel="Active";
const inActiveStatus="Inactive";
const underReviewStatus="Under Review";

const departmentDropdownDefaultValue = "Select Department";
const departmentFilterDropdownDefaultValue = "All";

let observationFilterArray = [
  {
    label:"Observation",
    isSelected:true
  },
  {
    label:"Task",
    isSelected:false
  }
];

class FollowUpAssignment extends Component {
    constructor(props) {
        super(props);
		this.state = {
			componentDidMountFlag: false,
            apiSearchKey: "",
            searchkey: "",
            currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
            filterDeptArr:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder: Constants.RESULT_SIZE,
            sort: "",
			sortDir: "",
            status:activeStatus,
            deletePermission:"N",
            actionType:"",
            statusArr:[
                {
                    label:activeStatus,
                    displayLabel:activeStatusLabel,
                    isSelected:true
                },
                {
                    label:underReviewStatus,
                    displayLabel:underReviewStatus,
                    isSelected:false
                },
                {
                    label:inActiveStatus,
                    displayLabel:inActiveStatus,
                    isSelected:false
                },
            ],
            resultSizeArr: Constants.RESULT_SIZE_DD_ARR,
			company:{},
            allSettings: [],
            departments:[],
            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            deleteReactivateMasterType:"",
            deleteReactivateName:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            showBtnLoader:false,
            name: "",
            
            reviewAssignmentTask:"",
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,


            showDetailsDialog:false,
            followUpDetailsItem:"",

            showResourcesDialog:false,
            assignResourcesEdit:false,
            assignReportResourcesEdit:false,
            selectedAssignmentStatus:"",

            showReviewHistoryDialog:false,

            displayAddObservationsComponent:false,
            displayAddTasksFunctionComponent:false,
            displayAddTasksChecklistComponent:false,
            checklistIdAlreadySaved:false,
            departmentIdForAddTask:"",
            observationTemplateId:"",

            showSelectChecklistFlag:false,
            selectedChecklist:"",
            changeChecklist:false,

            showSelectChecklistFlag:false,
            reviewAssignmentTask:"",

            observationTaskFilterDropDownArr: observationFilterArray,
            observationTaskFilterPlaceholder:"Observation",
        }
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.setState({
                componentDidMountFlag:false,
			    datalist: [],
                displayAddObservationsComponent:false,
                displayAddTasksFunctionComponent:false,
                displayAddTasksChecklistComponent:false,
            },()=>{
                this.getInitData();
            })
        }
    }

    componentDidMount() {
		this.getInitData();
	}

    handleChangeSearch = (e) => {
		const { name, value } = e.target;

		this.setState({
			[name]: value,
		});
	};

    onSearchIconClick = () => {
		this.setState({
            currentPageNo: 1,
        },() => {
            this.getAssignmentList();
        });
	};

    onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
            this.setState({
                currentPageNo: 1,
            },() => {
                this.getAssignmentList();
            });
        }
	};

    resultSizeDropdownClick = (item, selectedIndex) => {
		let tempArr = this.state.resultSizeArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label ;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState({
            resultSizeArr: tempArr,
			resultSize: item.label,
			resultSizePlaceholder: placeholder,
        },() => {
            this.getAssignmentList();
        });
	};

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.displayLabel;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getAssignmentList();
        })
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            followUpDetailsItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            followUpDetailsItem:item,
        });
    }

    handleAllResourcesDialogClose = (reloadFlag) => {
        this.setState({
            showResourcesDialog:false,
            assignResourcesEdit:false,
            deleteReactivateId:"",
            selectedAssignmentStatus:"",
            reviewAssignmentTask:"",
        },()=>{
            if(reloadFlag === true){
                this.getAssignmentList();
            }
        })
    }

    observationTaskFilterDropDownOnClick = (selectedItem) => {
        let tempObservationFilterDataArray = this.state.observationTaskFilterDropDownArr;
        Object.values(tempObservationFilterDataArray).map((item) => {
            if (selectedItem.label === item.label) {
                item.isSelected = true;
                if(item.label==="Observation"){
                    this.setState({
                        displayAddTasksFunctionComponent:false,
                        displayAddObservationsComponent:true
                    })
                }else{
                    this.setState({
                        displayAddTasksFunctionComponent:true,
                        displayAddObservationsComponent:false
                    })
                }
            } else {
                item.isSelected = false;
            }
        });
       
        this.setState({
            observationTaskFilterPlaceholder:selectedItem.label,
            observationTaskFilterDropDownArr: tempObservationFilterDataArray,
        })
    };

    deptFilterDropdownClick = (item, selectedIndex) => {
		let placeholder = "";
		let id = "";
		let filterDeptArr = this.state.filterDeptArr;

		for (const [i, value] of filterDeptArr.entries()) {
			if (i === selectedIndex) {
				filterDeptArr[i].isSelected = true;
				placeholder = value.name;
				id = value.departmentId;
			} else {
				filterDeptArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				selectedFilterDepartmentName: placeholder,
				selectedFilterDepartmentId: id,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

    sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState({
            sort: sortObj.sortTemp,
			sortDir: sortObj.sortDirTemp,
        },() => {
            this.getAssignmentList();
        });
    };

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Follow Up Assignment",
                proceedBtnLabel:"Delete",
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete "+dropdownItem.referenceNo+" assignment?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Activate Master",
                proceedBtnLabel:"Reactivate",
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate "+dropdownItem.referenceNo+" assignment?"
            })
        }else if(item.label === "Submit For Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Submit For Review",
                proceedBtnLabel:"Submit",
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to submit "+dropdownItem.referenceNo+" assignment for review?"
            })
        }else if(item.label === "Start Assignment"){
            if(dropdownItem.taskAssigned === "T" && dropdownItem.isResourceAssigned === 1 && dropdownItem.isReportResourceAssigned ===1){

                this.setState({
                    operationType:item.label,
                    alertDialogHeading:"Start Assignment",
                    proceedBtnLabel:"Start",
                    deleteReactivateId:dropdownItem.assignmentId,
                    showAlertDialog:true,
                    alertDialogMessage:"Are you sure you want to start "+dropdownItem.referenceNo+" assignment?"
                })
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"Please assign Tasks, Resources and Report Resources before starting the Assignment",
                });
            }
        }else if(item.label === "Start Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+dropdownItem.companyName+" assignment?"
            })
        }else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.assignmentId,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.assignmentId,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Assign Tasks"){
            if(dropdownItem.assignmentArea === "Function"){
                this.setState({
                    displayAddTasksFunctionComponent:true,
                    departmentIdForAddTask:dropdownItem.departmentId,
                    deleteReactivateId:dropdownItem.assignmentId,
                    deleteReactivateName:dropdownItem.referenceNo,
                    observationTemplateId:dropdownItem.observationTemplateId
                })
            }else{
                this.setState({
                    displayAddObservationsComponent:true,
                    departmentIdForAddTask:dropdownItem.departmentId,
                    deleteReactivateId:dropdownItem.assignmentId,
                    deleteReactivateName:dropdownItem.referenceNo,
                    observationTemplateId:dropdownItem.observationTemplateId
                })
            }
        }else if(item.label === "Assign Observation/Tasks"){
            if(!isEmptyVariable(dropdownItem.observationTemplateId)){
                this.setState({
                    displayAddObservationsComponent:true,
                    departmentIdForAddTask:dropdownItem.departmentId,
                    deleteReactivateId:dropdownItem.assignmentId,
                    deleteReactivateName:dropdownItem.referenceNo,
                    observationTemplateId:dropdownItem.observationTemplateId
                })
            }else{
                this.setState({
                    displayAddTasksFunctionComponent:true,
                    departmentIdForAddTask:dropdownItem.departmentId,
                    deleteReactivateId:dropdownItem.assignmentId,
                    deleteReactivateName:dropdownItem.referenceNo,
                    observationTemplateId:dropdownItem.observationTemplateId,
                    assignmentArea:dropdownItem.assignmentArea
                })
                
            }
        }else if(item.label === "Assign Resources"){
            this.setState({
                showResourcesDialog:true,
                assignResourcesEdit:dropdownItem.isResourceAssigned ===0?false:true,
                assignReportResourcesEdit:dropdownItem.isReportResourceAssigned ===0?false:true,
                deleteReactivateId:dropdownItem.assignmentId,
                selectedAssignmentStatus:dropdownItem.assignmentStatus,
                selectedObsTemplateId:dropdownItem.observationTemplateId,
                reviewAssignmentTask:dropdownItem.reviewAssignmentTask,
            })
        }else if(item.label === "Reassign Resources"){
            this.setState({
                showResourcesDialog:true,
                assignResourcesEdit:true,
                deleteReactivateId:dropdownItem.assignmentId,
                selectedAssignmentStatus:dropdownItem.assignmentStatus,
                selectedObsTemplateId:dropdownItem.observationTemplateId,
                reviewAssignmentTask:dropdownItem.reviewAssignmentTask,
            })
        }else if(item.label === "Discard Changes"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Discard Changes",
                proceedBtnLabel:"Discard",
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to discard changes of "+dropdownItem.companyName+" assignment?",
                actionType: dropdownItem.previousStatus === "Updated"?"Update":(dropdownItem.previousStatus === "Deleted"?"Delete":"")
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag === true){
                this.getAssignmentList();
            }
        });
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
        });
    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            assignmentId:this.state.deleteReactivateId
        }
        let callApi = true;

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteFollowUpAssignment;
        }else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitFollowUpForReview;
        }else if(this.state.operationType === "Discard Changes"){
            url = Constants.DiscardFollowUpChanges;
            postParam.actionType = this.state.actionType;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.updateFollowUpReviewStatus;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Update Review Status"){
            url = Constants.updateFollowUpReviewStatus;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_APPROVED;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ActivateFollowUpAssignment
        }

        if(callApi){
            this.setState({
                showBtnLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showBtnLoader:false,
                    },() => {
                        this.getAssignmentList();
                    });
                }else{
                    this.setState({
                        showBtnLoader:false,
                        deleteReactivateId:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

    onClickBackBtnFromAddTasks = (reloadFlag) => {
        let observationFilterArray = [
            {
                label:"Observation",
                isSelected:true
            },
            {
                label:"Task",
                isSelected:false
            }
        ];

        this.setState({
            displayAddObservationsComponent:false,
            displayAddTasksChecklistComponent:false,
            displayAddTasksFunctionComponent:false,
            checklistIdAlreadySaved:false,
            deleteReactivateName:"",
            deleteReactivateId:"",
            departmentIdForAddTask:"",
            observationTaskFilterDropDownArr: observationFilterArray,
            observationTaskFilterPlaceholder:"Observation",

        },()=>{
            if(reloadFlag === true) this.getAssignmentList();
        })
    }

    /*******************API CALLS*******************/

	getInitData = () => {
		this.setState({
            showLoader: true,
        });

		Promise.all([
			fetch(Constants.GetFollowUp, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
				    accessToken: userDetails.accessToken,
					pageNo: this.state.currentPageNo,
			        search: this.state.searchkey,
					resultsize: this.state.resultSize,
                    status: this.state.status,
					sort: this.state.sort,
					sortDir: this.state.sortDir,
				}),
			}),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
			    }),
			}),
		])
		.then(([masterRes, menuRes]) => {
			return Promise.all([masterRes.json(), menuRes.json()]);
		})
		.then(([masterRes, menuRes]) => {
			let datalistTemp = [];
			let totalCountTemp = 0;
			let menusTemp = {};
            let deptTemp = [];
			let companyTemp = {};
            let allSettings = [];
            let departments = [];
            let dataCountTemp = 0;

			if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
				datalistTemp = masterRes.data.result;
				totalCountTemp = masterRes.data.count;
                dataCountTemp = masterRes.data.counts;

                if(dataCountTemp.activeCount === 0 && dataCountTemp.underReviewCount !== 0 && this.state.status === activeStatus){
                    this.statusDropdownClick(this.state.statusArr[1],1);
                }
			} else {
			}
			if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                deptTemp = menuRes.data.departments;
				menusTemp = menuRes.data.menus;
				companyTemp = menuRes.data.company;
                allSettings = menuRes.data.allSettings;
                departments = menuRes.data.departments;
			} else {
			}

			let menusTemp2 = GetOrderedMasterTabMenuPlanning(menusTemp);
            let deletePermission = "N";

            if(menusTemp["Delete Follow Up Assignment"] === "ALL"){
                deletePermission = "Y";
            }

            let selectedDepartmentId = "";
			let selectedDepartmentName = departmentDropdownDefaultValue;
			if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
				selectedDepartmentId = deptTemp[0].departmentId;
				selectedDepartmentName = deptTemp[0].name;
			}

			//Filter Dept array
			let filterDeptArr = [];
			let selectedFilterDepartmentId = "-100";
			let selectedFilterDepartmentName = "All";
			if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
			} else {
				let obj = {
					departmentId: "-100",
					name: departmentFilterDropdownDefaultValue,
					isSelected: true,
				};
				filterDeptArr = [...deptTemp];
				filterDeptArr.splice(0, 0, obj);
			}
            
			this.setState({
				componentDidMountFlag: true,
				showLoader: false,
				datalist: datalistTemp,
				totalCount: totalCountTemp,
				menus: menusTemp2,
				company:companyTemp,
                allSettings:allSettings,
                departments:departments,
                deletePermission:deletePermission,
                
                filterDeptArr: filterDeptArr,
				selectedFilterDepartmentId: selectedFilterDepartmentId,
				selectedFilterDepartmentName: selectedFilterDepartmentName,
			});
		});
	};

    getAssignmentList = () => {
		this.setState({
			showLoader: true,
            componentDidMountFlag: false,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			pageNo: this.state.currentPageNo,
			search: this.state.searchkey,
			resultsize: this.state.resultSize,
            status: this.state.status,
			sort: this.state.sort,
			sortDir: this.state.sortDir,
		};

        if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue) {
			postParam.departmentId = this.state.selectedFilterDepartmentId;
		}

		fetch(Constants.GetFollowUp, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			} else if (data.responseCode === Constants.CODE_SUCCESS) {
				this.setState({
					showLoader: false,
					datalist: data.data.result,
					totalCount: data.data.count,
					apiSearchKey: this.state.searchkey,
					componentDidMountFlag: true
				});
			} else {
				this.setState({
					datalist: [],
					showLoader: false,
					totalCount: 0,
					componentDidMountFlag: true,
					apiSearchKey: this.state.searchkey,
				});
			}
		});
	};

    onChangePage = (page) => {
		// update state with new page of items
		if (page !== this.state.currentPageNo) {
			this.setState({
				showLoader: true,
			});

			let postParam = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				pageNo: page,
				search: this.state.searchkey,
				resultsize: this.state.resultSize,
                status: this.state.status,
				sort: this.state.sort,
				sortDir: this.state.sortDir,
			};

            if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== "All") {
				postParam.departmentId = this.state.selectedFilterDepartmentId;
			}
            
			fetch(Constants.GetFollowUp, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
                    if(data.data.result.length === 0 && this.state.status === activeStatus){
                        this.statusDropdownClick(this.state.statusArr[1],1);
                    }
					this.setState({
						showLoader: false,
						datalist: data.data.result,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
						currentPageNo: page,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
		}
	};

    /*******************API CALLS END HERE*******************/

    render() {
        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs masterMenus={this.state.menus} themeSettings={themeSettings} allSettings={this.state.allSettings} departments={this.state.departments}/>
                                {this.state.showLoader && (
                                    <div class="loader"></div>
                                )}
                                {
                                    // Need to check component did mount flag, other wise until api is called,
									// it shows no items found layout.
                                    !this.state.displayAddObservationsComponent &&
                                    !this.state.displayAddTasksFunctionComponent &&
                                    // !this.state.displayAddTasksChecklistComponent &&
                                    this.state.componentDidMountFlag && (
                                        <div className="body-wrapper">
                                            <div className="search-addnew-bg">
                                                <div className="search-addnew">
                                                    <div className="search-filter-layout">
                                                        <FilterDropDown
                                                            placeholder={this.state.resultSizePlaceholder}
                                                            dropdownArr={this.state.resultSizeArr}
                                                            onDropDownItemClick={this.resultSizeDropdownClick}
                                                            themeSettings={themeSettings}
                                                            mleft={0}
														/>
                                                        <div className="search-col m-l-10">
                                                            <input
                                                                name="searchkey"
                                                                type="text"
                                                                onChange={this.handleChangeSearch}
                                                                placeholder="Search"
                                                                onKeyPress={this.onEnterBtnPress}
                                                                value={this.state.searchkey}
                                                            />
                                                            <SearchSpan themeSettings={themeSettings} onClick={this.onSearchIconClick} className="material-icons">
                                                                {" "}
                                                                search{" "}
                                                            </SearchSpan>
														</div>
                                                        <FilterDropDown
                                                            placeholder={this.state.statusPlaceholder}
                                                            dropdownArr={this.state.statusArr}
                                                            onDropDownItemClick={this.statusDropdownClick}
                                                            themeSettings={themeSettings}
                                                            name={"displayLabel"}
                                                        />
                                                        {this.state.filterDeptArr.length > 0 && (
															<FilterDropDown
																placeholder={this.state.selectedFilterDepartmentName}
																dropdownArr={this.state.filterDeptArr}
																onDropDownItemClick={this.deptFilterDropdownClick}
																name={"name"}
																themeSettings={themeSettings}
																dropdownWidth={"120px"}
															/>
														)}
                                                    </div>
                                                </div>
                                            </div>

                                            {!isEmptyArray(this.state.datalist) && (
                                                <div className="common-tab-bg">
                                                    <div className="common-table" style={{ width: "100%" }}>
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center" width={"5%"}>No.</th>
                                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortReferenceNo)}>
                                                                        <div className="sort-header">Ref No.
                                                                        <span className={(this.state.sort === sortReferenceNo)?
                                                                            "material-icons":"material-icons hide-sort-arrow"}>
                                                                            {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                        </span>
                                                                        </div>
                                                                    </th>
                                                                    {
                                                                        this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortLocation)}>
                                                                            <div className="sort-header">Unit
                                                                                <span className={(this.state.sort === sortLocation)?
                                                                                    "material-icons":"material-icons hide-sort-arrow"}>
                                                                                    {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    }
                                                                    {
                                                                        this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortunitAddressList)}>
                                                                            <div className="sort-header">Name
                                                                                <span className={(this.state.sort === sortunitAddressList)?
                                                                                    "material-icons":"material-icons hide-sort-arrow"}>
                                                                                    {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    }
                                                                    {
                                                                        this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortunitCityList)}>
                                                                            <div className="sort-header">City
                                                                                <span className={(this.state.sort === sortunitCityList)?
                                                                                    "material-icons":"material-icons hide-sort-arrow"}>
                                                                                    {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    }
                                                                    {
                                                                        this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
                                                                            <div className="sort-header">Client
                                                                                <span className={(this.state.sort === sortName)?
                                                                                    "material-icons":"material-icons hide-sort-arrow"}>
                                                                                    {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    }
                                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDescription)}>
                                                                        <div className="sort-header">Type
                                                                            <span className={(this.state.sort === sortDescription)?
                                                                                "material-icons":"material-icons hide-sort-arrow"}>
                                                                                {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                            </span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortArea)}>
                                                                        <div className="sort-header">Scope
                                                                            <span className={(this.state.sort === sortArea)?
                                                                                "material-icons":"material-icons hide-sort-arrow"}>
                                                                                {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                            </span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortReportDate)}>
                                                                        <div className="sort-header">Report Date
                                                                            <span className={(this.state.sort === sortReportDate)?
                                                                                "material-icons":"material-icons hide-sort-arrow"}>
                                                                                {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                            </span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="text-center" width={"5%"}>
                                                                        <div className="sort-header"></div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    !isEmptyArray(this.state.datalist) &&
                                                                    this.state.datalist.map((item, idx) => {
                                                                        let endReviewUserId = "";
                                                                        for(var i=0;i<7;i++){
                                                                            if(item.endReviewLevel===i){
                                                                                endReviewUserId=item[`L${i}UserId`]
                                                                            }
                                                                        }
                                                                        let actionObj = getDropdownActionArrayFollowUp(item,1,this.state.deletePermission,endReviewUserId,userDetails.userId);
                                                                        let tempScop = [];
                                                                        if(!isEmptyVariable(item.functionList)){
                                                                            tempScop = item.functionList?.split(",").map((func) => func.split(":-:")[0]);
                                                                        }else if(!isEmptyVariable(item.processList)){
                                                                            tempScop = item.processList.split(",").map((func) => func.split(":-:")[0]);
                                                                        }else if(!isEmptyVariable(item.checkList)){
                                                                            tempScop = item.checkList.split(",").map((func) => func.split(":-:")[0]);
                                                                        }
                                                                        return (
                                                                            <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                                <td className="text-center">{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}</td>
                                                                                <td>{item.referenceNo}</td>
                                                                                {
                                                                                    this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                                    <td>
                                                                                        {truncateString(String(getStringWithSpaceAfterComma(item.unitNameCityList)).split("(")[0],100)}
                                                                                    </td>
																				}
                                                                                {
                                                                                    this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                                    <td>{item.companyName}</td>
                                                                                }
                                                                                {   
                                                                                    this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF && 
                                                                                    <td>
                                                                                        {isEmptyVariable(item.unitAddressList)? "-": String(item.unitAddressList).split(":-:")[0]}
                                                                                    </td>
                                                                                }
                                                                                {   
                                                                                    this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF && 
                                                                                    <td>{isEmptyVariable(item.unitCityList)? "-": item.unitCityList}</td>
                                                                                }
                                                                                <td>{item.assignmentTypeName}</td>
                                                                                <td>{item.assignmentArea == "CheckList" ?
                                                                                    isEmptyArray(tempScop)?"-":(`CheckList: ` + tempScop[0]) :
                                                                                    isEmptyArray(tempScop)?"-":tempScop.length==1?tempScop[0]:`${tempScop[0]} + ${tempScop.length-1}`}
                                                                                </td>
                                                                                <td>{isEmptyVariable(item.reportDate)?"": moment(item.reportDate).format("DD/MM/YYYY")}</td>
                                                                                <td>
                                                                                    {actionObj.actionArr && actionObj.actionArr.length > 0 && (
																						<TableDropDown
																							actionArr={actionObj.actionArr}
																							onDropDownItemClick={this.onTableDropDownItemClick}
																							dropDownId={item}
																							dropdownWidth={actionObj.dropdownWidth}
																							themeSettings={themeSettings}
																						/>
																					)}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                            {this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
												<div class="no-items-layout">
													<div class="no-items-card">
														<h6>
															{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
														</h6>
													</div>
												</div>
											)}
											<div className="pagination-layout">
												<div className="row custom-row">
													<div className="col-md-9 custom-col">
														{this.state.totalCount > this.state.datalist.length && (
															<Pagination
																totalLength={this.state.totalCount}
																items={this.state.datalist}
																onChangePage={this.onChangePage}
																pageSize={this.state.resultSize}
																currentPageNo={this.state.currentPageNo}
																initialPage={this.state.currentPageNo}
																themeSettings={themeSettings}
															/>
														)}
													</div>
													<div className="col-md-3 custom-col">
														<p>{"Total Records: " + this.state.totalCount}</p>
													</div>
												</div>
											</div>
                                        </div>
                                    )
                                }
                                {   
                                    this.state.displayAddTasksFunctionComponent &&
                                    // isEmptyVariable(this.state.observationTemplateId) &&
                                    <AddTasksandResourcesFunction
                                        assignmentId={this.state.deleteReactivateId}
                                        departmentId={this.state.departmentIdForAddTask}
                                        referenceNo={this.state.deleteReactivateName}
                                        observationTaskFilterDropDownArr={this.state.observationTaskFilterDropDownArr}
                                        observationTaskFilterPlaceholder={this.state.observationTaskFilterPlaceholder}
                                        observationTaskFilterDropDownOnClick={this.observationTaskFilterDropDownOnClick}
                                        observationTemplateId={this.state.observationTemplateId}
                                        onClickBackBtnFromAddTasks={this.onClickBackBtnFromAddTasks}
                                    />
                                }
                                {   
                                    this.state.displayAddObservationsComponent &&
                                    // !isEmptyVariable(this.state.observationTemplateId) &&
                                    <FollowUpObservation
                                        assignmentId={this.state.deleteReactivateId}
                                        departmentId={this.state.departmentIdForAddTask}
                                        referenceNo={this.state.deleteReactivateName}
                                        assignmentArea={this.state.assignmentArea}
                                        observationTaskFilterDropDownArr={this.state.observationTaskFilterDropDownArr}
                                        observationTaskFilterPlaceholder={this.state.observationTaskFilterPlaceholder}
                                        observationTaskFilterDropDownOnClick={this.observationTaskFilterDropDownOnClick}
                                        observationTemplateId={this.state.observationTemplateId}
                                        onClickBackBtnFromAddTasks={this.onClickBackBtnFromAddTasks}
                                    />
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            showLoader={this.state.showBtnLoader}
                            themeSettings={themeSettings}
                        />
                        
                        <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterType = {this.state.deleteReactivateMasterType}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        />

                        <ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {"Follow Up Assignment"}
                            masterDataId = {this.state.deleteReactivateId}
                        />

                        <AssignAllResourcesDialog
                            showResourcesDialog = {this.state.showResourcesDialog}
                            handleAllResourcesDialogClose = {this.handleAllResourcesDialogClose}
                            assignmentId = {this.state.deleteReactivateId}
                            themeSettings = {themeSettings}
                            assignResourcesEdit= {this.state.assignResourcesEdit}
                            assignReportResourcesEdit= {this.state.assignReportResourcesEdit}
                            selectedAssignmentStatus={this.state.selectedAssignmentStatus}
                            selectedObsTemplateId={this.state.selectedObsTemplateId}
                            reviewAssignmentTask={this.state.reviewAssignmentTask}
                        />

                        <FollowUpDetailsDialog
                            showDetailsDialog={this.state.showDetailsDialog}
                            followUpDetailsItem={this.state.followUpDetailsItem}
                            company={this.state.company}
                            handleDetailsDialogClose={this.handleDetailsDialogClose}
                        />
                    </section>
                </main>
            </Router>
        )
    }

}
export default FollowUpAssignment;