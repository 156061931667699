import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,DialogSaveButton} from '../../../common/Global/globalStyles.style';
import Sidebar from '../../../common/Sidebar';
import Topbar from '../../../common/Topbar';
import MasterMenuTabs from '../Common/topmenu';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../../common/AlertDialog';
import Pagination from '../../../common/Global/pagination';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import UpdateReviewStatusDialog from './riskmasterupdatereviewstatusdialog'
import ReviewHistoryDialog from '../../../common/reviewhistorydialog'
import AssignToClientDialog from './assignToClientDialog'
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,GetOrderedMasterTabMenu,
    getStatusSpan,SplitJoinString,getThemeSettingsVariables,isEmptyArray,ifEmptyReturnEmptyStr} from '../../../common/Global/commonFunctions';
import {getDropdownActionArrayRisk} from '../../../common/Global/reviewFunctions';
import {Modal} from 'react-bootstrap';
import AddEditRiskDialog from "./addEditRisk";
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import SelectCopyRiskDialog from './selectCopyRiskDialog';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
const sortName = "name";
const sortDescription = "description";
const sortDeptName = "levels";
const sortType = "type";

const departmentFilterDropdownDefaultValue = "All";
const departmentDropdownDefaultValue = "Select Department";
const riskLevelPlaceholderDefaultValue = "Select Risk Level";
const riskMatrixPlaceholderDefaultValue = "Enter Risk Matrix";

const activeStatus="Active";
const inActiveStatus="Inactive";
const underReviewStatus="Under Review";
const requestChangeCheckText="Request Change";

class RiskMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            defaultRisk:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",
            deletePermission:"N",

            actionType:"",

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            reviewLevel:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            showBtnLoader:false,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,

            status:activeStatus,
            statusArr:[
                {
                    label:activeStatus,
                    isSelected:true
                },
                {
                    label:underReviewStatus,
                    isSelected:false
                },
                {
                    label:inActiveStatus,
                    isSelected:false
                },
            ],
            resultSizeArr: JSON.parse(JSON.stringify(Constants.RESULT_SIZE_DD_ARR)),

            
            formErrors:{},
            submitClickedAtleastOnce:false,

            departmentArr:[],
            selectedDepartmentName:departmentDropdownDefaultValue,
            selectedDepartmentId:"",

            showDetailsDialog:false,
            selectedItem:"",
            selectedRiskLevels:[],

            showIsDefault:"N",
            showDialogLoader:false,

            showAssignToClientDialog:false,
            assignmentTypes: [],
        }
    }

    componentDidMount(){
        this.getInitData();
    }

    handleAssignToClientDialogClose = (reloadFlag) => {
        this.setState({
            showAssignToClientDialog:false,
            deleteReactivateId:""
        },()=>{
            if(reloadFlag === true) this.getRiskMasterList();
        })
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getRiskMasterList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getRiskMasterList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag === true){
                this.getRiskMasterList();
            }
        });
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateId:"",
        });
    }

    handleCreateRiskDialogShow = () => {
        this.setState({
            showCreateRiskDialog:true,
            isEdit:false,
            selectedId:"",
        });
    }

    handleCreateRiskDialogClose = (reloadFlag) =>{
        this.setState({
            showCreateRiskDialog:false,
            isEdit:false,
            selectedId:"",
        },()=>{
            if(reloadFlag === true){
                this.getRiskMasterList();
            }
        });
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            showDialogLoader:true,
            selectedId:item.riskId
        },()=>{
            this.getRiskDetails(false);
        });
    }

    onKeyBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.handleMatrixDefDialogShow();
        }
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getRiskMasterList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getRiskMasterList();
        })
    }

    deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let id = "";
        let filterDeptArr = this.state.filterDeptArr;

        for(const [i,value] of filterDeptArr.entries()){
            if(i === selectedIndex){
                filterDeptArr[i].isSelected = true;
                placeholder = value.name;
                id = value.departmentId;
            }else{
                filterDeptArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo: 1,
            selectedFilterDepartmentName:placeholder,
            selectedFilterDepartmentId:id
        },() => {
            this.getRiskMasterList();
        })
    }

    deptDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['deparment'] = "";
        }
        this.setState({
            selectedDepartmentName:item.name,
            selectedDepartmentId:item.departmentId,
            formErrors:FormErrorsTemp
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Master",
                proceedBtnLabel:"Delete",
                deleteReactivateId:dropdownItem.riskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete risk "+dropdownItem.name+"?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Activate Master",
                proceedBtnLabel:"Reactivate",
                deleteReactivateId:dropdownItem.riskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate risk "+dropdownItem.name+"?"
            })
        }else if(item.label === "Edit"){
            this.setState({
                showCreateRiskDialog:true,
                isEdit:true,
                selectedId:dropdownItem.riskId,
                showDialogLoader:true,
            });
        }else if(item.label === "Submit For Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Submit For Review",
                proceedBtnLabel:"Submit",
                deleteReactivateId:dropdownItem.riskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to submit "+dropdownItem.name+" for review?"
            })
        }else if(item.label === "Start Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.riskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+dropdownItem.name
            })
        }else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.riskId,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.riskId,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Review Remarks"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Review Remarks",
                proceedBtnLabel:"Okay",
                showAlertDialog:true,
                alertDialogMessage:'\"'+dropdownItem.lastReviewRemark+'\"'
            })
        }else if(item.label === "Assign to Client"){
            this.setState({
                deleteReactivateId:dropdownItem.riskId,
                showAssignToClientDialog:true,
            })
        }else if(item.label === "Set as Default"){
            if(this.state.departmentArr.length > 1){
                //show department dropdown dialog
                let selectedDepartmentId="";
                let selectedDepartmentName=departmentDropdownDefaultValue;

                this.setState({
                    showDeptDialog:true,
                    deleteReactivateId:dropdownItem.riskId,
                    selectedDepartmentId:selectedDepartmentId,
                    selectedDepartmentName:selectedDepartmentName,
                })

            }else{
                //call api with selected department Id
                this.setAsDefaultApi(this.state.selectedDepartmentId,dropdownItem.riskId);
            }
        }else if(item.label === "Discard Changes"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Discard Changes",
                proceedBtnLabel:"Discard",
                deleteReactivateId:dropdownItem.riskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to discard changes of "+dropdownItem.name_new+"?",
                actionType: dropdownItem.previousStatus === "Updated"?"Update":(dropdownItem.previousStatus === "Deleted"?"Delete":"")
            })
        }
    }

    handleSetAsDefault = () => {
        this.setAsDefaultApi(this.state.selectedDepartmentId,this.state.deleteReactivateId);
    }

    handleDeptDialogClose = () => {
        this.setState({
            showDeptDialog:false,
            deleteReactivateId:"",
        })
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getRiskMasterList();
        })
    }

    //copy data dialog
    showCopyRiskMasterDialog = () => {
        let selectedDepartmentId = this.state.selectedDepartmentId;
        let selectedDepartmentName = this.state.selectedDepartmentName;

        if(this.state.departmentArr.length > 1){
            selectedDepartmentId="";
            selectedDepartmentName=departmentDropdownDefaultValue;
        }

        this.setState({
            showCopyRiskMasterDialog:true,
            
            masterType:"Select Master Type",
            selectedDepartmentId:selectedDepartmentId,
            selectedDepartmentName:selectedDepartmentName,
            submitClickedAtleastOnce:false,
            formErrors:{}
        });
    }

    handleCopyRiskMasterClose = () =>{
        this.setState({
            showCopyRiskMasterDialog:false,
            
        },()=>{
            this.getRiskMasterList();
        });
    }
    
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetRiskMastersCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetRiskMasters,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status_new:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([masterRes,generalRes,menuRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,generalRes,menuRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;
            let menusTemp = {};
            let deptTemp = [];
            let defaultRisk = [];
            let showIsDefaultTemp = "N";
            let assignmentTypes = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    deptTemp = masterRes.data.departments;
                    assignmentTypes = masterRes.data.assignmentTypes || [];
            }else{
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                defaultRisk = generalRes.data.default;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenu(menusTemp)
            let deletePermission = "N";

            if(menusTemp["Delete Risk"] === "ALL"){
                deletePermission = "Y";
            }

            let selectedDepartmentId = "";
            let selectedDepartmentName = departmentDropdownDefaultValue;

            if(!isEmptyVariable(deptTemp) && deptTemp.length===1){
                selectedDepartmentId = deptTemp[0].departmentId;
                selectedDepartmentName = deptTemp[0].name;
                showIsDefaultTemp = deptTemp[0].showIsDefault
            }else if(!isEmptyVariable(deptTemp)){
                showIsDefaultTemp = deptTemp.some(dept => dept.showIsDefault === "Y")?"Y":"N";
            }

            //Filter Dept array
            let filterDeptArr = [];
            let selectedFilterDepartmentId = "-100";
            let selectedFilterDepartmentName = "All";
            if(!isEmptyArray(deptTemp) && deptTemp.length===1){

            }else{
                let obj = {
                    departmentId:"-100",
                    name:departmentFilterDropdownDefaultValue,
                    isSelected:true
                }
                filterDeptArr = [...deptTemp];
                filterDeptArr.splice(0,0,obj)
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:datalistTemp,
                defaultRisk:defaultRisk,
                totalCount:totalCountTemp,
                menus:menusTemp2,
                departmentArr:deptTemp,
                showIsDefault:showIsDefaultTemp,
                deletePermission:deletePermission,

                selectedDepartmentId:selectedDepartmentId,
                selectedDepartmentName:selectedDepartmentName,

                filterDeptArr:filterDeptArr,
                selectedFilterDepartmentId:selectedFilterDepartmentId,
                selectedFilterDepartmentName:selectedFilterDepartmentName,

                assignmentTypes: assignmentTypes, 
            })
        })
    }

    getRiskDetails = () => {
        fetch(Constants.GetRiskDetails,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                riskId:this.state.selectedId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let selectedItem = data.data.risk;
                let selectedRiskLevels = data.data.riskLevels;
                this.setState({
                    selectedItem:selectedItem,
                    selectedRiskLevels:selectedRiskLevels,
                    showDialogLoader:false
                })
                
            }else{
                this.setState({
                    showDialogLoader:false,
                });
            }
        });
    }

    getRiskMasterList = () => {
        this.setState({
            showLoader:true,
        });

        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status_new:this.state.status,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
        }

        if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
            postParam.departmentId = this.state.selectedFilterDepartmentId;
        }

        fetch(Constants.GetRiskMasters,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    defaultRisk:data.data.default,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:page,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status_new:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
            }
    
            if(this.state.filterDeptArr.length > 1 &&
                this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
                postParam.departmentId = this.state.selectedFilterDepartmentId;
            }

            fetch(Constants.GetRiskMasters,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            riskId:this.state.deleteReactivateId
        }
        let callApi = true;

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteRiskMasters;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ReactivateRiskMasters;
        }else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitForReviewRiskMasters;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.updateReviewStatusRiskMasters;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Discard Changes"){
            url = Constants.DiscardChangesRisk;
            postParam.actionType = this.state.actionType;
        }else if(this.state.operationType === "Review Remarks"){
            callApi = false;
            this.setState({
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
            })
        }

        if(callApi){
            this.setState({
                showBtnLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showBtnLoader:false,
                    },() => {
                        this.getRiskMasterList();
                    });
                }else{
                    this.setState({
                        showBtnLoader:false,
                        deleteReactivateId:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

    setAsDefaultApi = (departmentId, riskId) => {
        this.setState({
            showLoader:true,
        })

        fetch(Constants.SetAsDefault,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                departmentId:departmentId,
                riskId:riskId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showDeptDialog:false,
                },()=>{
                    this.getRiskMasterList();
                })
            }else{
                this.setState({
                    showLoader:false,
                    showDeptDialog:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    this.state.showLoader &&
                                    <div class="loader"></div>
                                }
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings={themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                        themeSettings={themeSettings}
                                                        onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                                    </div>

                                                    <FilterDropDown
                                                        placeholder={this.state.statusPlaceholder}
                                                        dropdownArr={this.state.statusArr}
                                                        onDropDownItemClick={this.statusDropdownClick}
                                                        themeSettings={themeSettings}
                                                    />
                                                    
                                                    {
                                                        this.state.filterDeptArr.length > 0 &&
                                                        <FilterDropDown
                                                            placeholder={this.state.selectedFilterDepartmentName}
                                                            dropdownArr={this.state.filterDeptArr}
                                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                                            name={"name"}
                                                            themeSettings={themeSettings}
                                                            dropdownWidth={"120px"}
                                                        />
                                                    }
                                                </div>
                                                {
                                                    this.state.menus[Constants.SCREEN_RISK] ==="ALL" && (
                                                        <div className="flex-center-layout" >
                                                            <AddNewButtonLayout themeSettings={themeSettings} style={{marginRight:10}}>
                                                                <a onClick={this.showCopyRiskMasterDialog} href="javascript:void(0);">
                                                                    <span class="material-icons" style={{fontSize:15}}> content_copy </span>
                                                                    <p>Copy Data</p>
                                                                </a>
                                                            </AddNewButtonLayout>
                                                            <AddNewButtonLayout themeSettings={themeSettings}>
                                                                <a onClick={this.handleCreateRiskDialogShow} href="javascript:void(0);">
                                                                    <span className="material-icons">add_circle</span>
                                                                    <p>Add New</p>
                                                                </a>
                                                            </AddNewButtonLayout>
                                                        </div>
                                                    )   
                                                }
                                            </div>
                                        </div>

                                        {
                                            !(isEmptyArray(this.state.defaultRisk) && 
                                            isEmptyArray(this.state.datalist)) && 
                                            <div className="common-tab-bg">
                                                <div className="common-table" style={{width:"100%"}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th className="c-pointer" width={"20%"}  onClick={this.sortTableLocal.bind(this,sortName)}>
                                                                <div className="sort-header">Name
                                                                <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDescription)}>
                                                                <div className="sort-header">Description
                                                                <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th width={"8%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortType)}>
                                                                <div className="sort-header">Types
                                                                <span className={(this.state.sort === sortType)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th width={"8%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDeptName)}>
                                                                <div className="sort-header">Levels
                                                                <span className={(this.state.sort === sortDeptName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            {/* <th width={"10%"}>Users Count</th> */}
                                                            <th className="text-center"  width={"5%"}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !isEmptyArray(this.state.defaultRisk) &&
                                                            this.state.defaultRisk.map(item=>{
                                                                let actionObj = getDropdownActionArrayRisk(item.allowEdit, item.allowReview, 
                                                                    item.status, item.currentReviewStatus,"N",item.status_new,
                                                                    item.previousStatus, false);
                                                                let spanObj = getStatusSpan(item.previousStatus,themeSettings);
                                                                return <tr className="highlight-row" onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                    <td></td>
                                                                    <td>
                                                                        {item.name}
                                                                        {
                                                                            this.state.status === "Under Review" &&
                                                                            <span style={spanObj.spanStyle} className="span-tag">
                                                                                {spanObj.spanText}
                                                                            </span>
                                                                        }  
                                                                    </td>
                                                                    <td>{truncateString(item.description + '- For prioritising Tasks' ,Constants.STRING_LENGTH_XL)}</td>
                                                                    <td className="text-capitalize">{item.Type ?? "-"}</td>
                                                                    <td>{item.levels}</td>
                                                                    <td className="text-center">
                                                                        {
                                                                            actionObj.actionArr && actionObj.actionArr.length > 0 &&
                                                                            <TableDropDown 
                                                                                actionArr={actionObj.actionArr}
                                                                                onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                                dropDownId = {item}
                                                                                dropdownWidth={actionObj.dropdownWidth}
                                                                                themeSettings={themeSettings}
                                                                            />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                        {
                                                            !isEmptyArray(this.state.datalist) && 
                                                            this.state.datalist.map((item,idx) => {

                                                                let actionObj = getDropdownActionArrayRisk(item.allowEdit, item.allowReview, 
                                                                    item.status, item.currentReviewStatus, this.state.deletePermission,item.status_new,
                                                                    item.previousStatus, this.state.showIsDefault);
                                                                let spanObj = getStatusSpan(item.previousStatus,themeSettings);
                                                                return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                    <td>
                                                                        {item.name_new}
                                                                        {
                                                                            this.state.status === "Under Review" &&
                                                                            <span style={spanObj.spanStyle} className="span-tag">
                                                                                {spanObj.spanText}
                                                                            </span>
                                                                        }  
                                                                    </td>
                                                                    <td>{truncateString(item.description_new  + '- For prioritising Tasks',Constants.STRING_LENGTH_XL)}</td>
                                                                    <td className="text-capitalize">{item.Type ?? "-"}</td>
                                                                    <td>{item.levels}</td>
                                                                    <td className="text-center">
                                                                        {
                                                                            actionObj.actionArr && actionObj.actionArr.length > 0 &&
                                                                            <TableDropDown 
                                                                                actionArr={actionObj.actionArr}
                                                                                onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                                dropDownId = {item}
                                                                                dropdownWidth={actionObj.dropdownWidth}
                                                                                themeSettings={themeSettings}
                                                                            />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        
                                        {
                                            this.state.componentDidMountFlag && 
                                            (isEmptyArray(this.state.datalist) && isEmptyArray(this.state.defaultRisk)) &&
                                            <div class="no-items-layout">
                                                <div class="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings={themeSettings}/>
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                (this.state.operationType === "Review Remarks")
                                ?
                                Constants.ALERT_TYPE_ALERT
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            showLoader={this.state.showBtnLoader}
                            themeSettings={themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        />

                        <ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {"Risk"}
                            masterDataId = {this.state.deleteReactivateId}
                        />

                        <AssignToClientDialog
                            showAssignToClientDialog={this.state.showAssignToClientDialog}
                            handleAssignToClientDialogClose={this.handleAssignToClientDialogClose}
                            riskId={this.state.deleteReactivateId}
                            themeSettings={themeSettings}
                        />

                        <SelectCopyRiskDialog
                            showCopyRiskMasterDialog={this.state.showCopyRiskMasterDialog}
                            handleCopyRiskMasterClose={this.handleCopyRiskMasterClose}
                            documentId={this.state.selectedDocumentId}
                            documentName={this.state.selectedDocumentName}
                            handleBackBtnClickFromSamplesandERP={this.handleBackBtnClickFromSamplesandERP}
                            themeSettings={themeSettings}
                        />

                        <AddEditRiskDialog
                            showCreateRiskDialog = {this.state.showCreateRiskDialog}
                            handleCreateRiskDialogClose = {this.handleCreateRiskDialogClose}
                            departmentArr = {this.state.departmentArr}
                            isEdit = {this.state.isEdit}
                            selectedId = {this.state.selectedId}
                            showIsDefault = {this.state.showIsDefault}
                            themeSettings={themeSettings}
                            assignmentTypes={this.state.assignmentTypes}
                        />
                        
                        {/* deptd */}
                        <Modal className="custom-dialog risk-dialog" show={this.state.showDeptDialog} onHide={this.handleDeptDialogClose}>
                            <Modal.Header>
                                <h5>Select Department</h5>
                                <button 
                                    onClick={this.handleDeptDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div class="modal-body">
                                {
                                    this.state.showDialogLoader &&
                                    <div class="loader"></div>
                                }
                                <div class="addnew-modal-form">
                                    <div class="addnew-modal-form-group">
                                        <p>Department*</p>
                                        <RegularDropdown 
                                            placeholder={this.state.selectedDepartmentName}
                                            dropdownArr={this.state.departmentArr.filter(dept => dept.showIsDefault === "Y")}
                                            labelParam="name"
                                            onDropDownItemClick={this.deptDropdownClick}
                                            defaultPlaceholderDropDown = {departmentDropdownDefaultValue}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['deparment']) &&
                                            <span class="cm-error">{this.state.formErrors['deparment']}</span>
                                        }
                                    </div>
                                </div>
                                <div class="modal-close-save">
                                    <div class="close-save-col">
                                        <button onClick={this.handleDeptDialogClose} type="button" class="modal-close">Cancel</button>
                                        <DialogSaveButton 
                                        themeSettings={themeSettings}
                                        onClick={this.handleSetAsDefault} type="button">Save</DialogSaveButton>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal className="custom-dialog risk-details" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
                            <Modal.Header>
                                <h5>{this.state.selectedItem.shortName}</h5>
                                <button 
                                    onClick={this.handleDetailsDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div class="modal-body">
                                {
                                    this.state.showDialogLoader &&
                                    <div class="loader"></div>
                                }
                                <div className="details-table-layout">
                                    <div className="row custom-row">
                                        <div className="col-md-6 custom-col">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width={"24%"}>Short Name</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.shortName_new}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Name</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.name_new}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Description</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{ifEmptyReturnEmptyStr(this.state.selectedItem.description_new,"-")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Status</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.status}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Levels</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.levels}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td width={"24%"}>Clients</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{ifEmptyReturnEmptyStr(SplitJoinString(this.state.selectedItem.companyList,",",":-:",","),"-")}</td>
                                                    </tr> */}
                                                    <tr>
                                                        <td width={"24%"}>Departments</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{ifEmptyReturnEmptyStr(SplitJoinString(this.state.selectedItem.departmentList,",",":-:",",",1),"-")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Assignment Type</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>
                                                            {this.state.assignmentTypes.filter(e=>e.assignmentTypeId==this.state.selectedItem.assignmentTypeId).map(e=>e.name)[0]||''}
                                                            {/* {this.state.assignmentTypes.find(item => item.assignmentTypeId?.toString() === this.state.selectedItem.assignmentTypeId?.toString())?.name ?? this.state.selectedItem.assignmentTypeId} */}
                                                        </td>
                                                    </tr>
                                                    {
                                                        this.state.selectedItem.status !== activeStatus &&
                                                        this.state.selectedItem.status !== inActiveStatus &&
                                                        <tr>
                                                            <td width={"24%"}>Review Status</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.state.selectedItem.currentReviewStatus}</td>
                                                        </tr>
                                                    }
                                                    {
                                                        this.state.selectedItem.allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW &&
                                                        this.state.selectedItem.currentReviewStatus.includes(requestChangeCheckText) &&
                                                        <tr>
                                                            <td width={"24%"}>Review Remarks</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.state.selectedItem.lastReviewRemark}</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-6 custom-col">
                                            <table>
                                                <tbody>
                                                    {
                                                        this.state.selectedRiskLevels.map((item,idx)=>{
                                                            return <tr>
                                                                <td width={"24%"}>Risk Level</td>
                                                                <td width={"4%"}>:</td>
                                                                <td width={"72%"}>
                                                                    <div className="flex-center-layout">
                                                                    {item.riskLevel_new}
                                                                    <div className="risk-level-color-wrapper">
                                                                        <div className="risk-level-color"
                                                                            style={{background:item.colorCode_new}}
                                                                        />
                                                                    </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </section>
                </main>
            </Router>
        );
    }
}

export default RiskMaster;