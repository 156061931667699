import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import * as TemplateConstants from "../../../common/Global/templateConstants";
import Sidebar from "../../../common/SidebarCollapse";
import Topbar from "../../../common/Topbar";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import AlertDialog from "../../../common/AlertDialog";
import SelectRiskDialog from "./selectRisk";
import { getFollowUpTasks } from "../../../common/Global/reviewFunctions";
import { getLocalStorageVariables, getThemeSettingsVariables, isEmptyVariable, isEmptyArray,
     removeHtmlTags, isJsonString } from "../../../common/Global/commonFunctions";
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdownTable';
import { getCheckListScoresFlag, getOnlyMyTasks, getMatrixandMyDetails, getRankingArray } from '../Tasks/execTaskUtilFunctions';
import FollowUpUpdateAssignmentTaskReviewStatus from "./followUpUpdateReviewStatusDialog";
import RegularDD from '../../../common/DropdownMenus/RegularDropdown';
import { BrowserRouter as Router } from "react-router-dom";
import FollowupHeading from "../Common/followupHeadingDetails";
import moment from "moment";
import DatePickerComponent from "../../Tasks/Common/dateSelectComponent";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import ReviewHistoryDialog from "../../../common/reviewhistorydialog";
import AddFollowUpTemplateValueDialog from "../Common/addFollowUpTemplateValueDialog";
import FollowUpDetailsDialog from "./followUpDetailsDialog";
import TaskDetailsLayout from '../../Tasks/taskDetailsWithTemplates';
import UploadAnnexureDialog from "./UploadAnnexure";

const defaultWeightPlaceholder = "Rank";
const defaultYNPlaceholder = "Rank";
const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const filterOpenCloseDropDownArr = [
    {
        label: "Checked & Open",
        isSelected: true,
    },
    {
        label: "Close",
        isSelected: false,
    },
];

class FollowUpTask extends Component {
    constructor(props) {
        super(props);
        const item = props.location.state;
        this.state = {
            assignmentId: props.match.params.id,
            departmentId: item.departmentId,
            observationTemplateId: item.observationTemplateId,
            observationTaskFilterDropDownArr: item.observationTaskFilterDropDownArr,
            observationTaskFilterPlaceholder: item.observationTaskFilterPlaceholder,

            componentDidMountFlag: false,
            showLoader: false,
            currentPageNo: 1,
            resultSize: Constants.RESULT_SIZE,
            datalist: [],
            allDataList: [],
            
            assignmentStatus: "",
            assignmentArea: "",
            assignmentDetails: "",
            
            selectedTaskTemplateFields: [],
            taskTemplateValuesMap: {},
            execTaskTemplateFields: [],
            executionTemplateValuesMap: {},
            executionTemplateFixedFields: {},
            followUpTemplateFixedFields: {},
            
            showAlertDialog: false,
            operationType: "",
            deleteReactivateId: "",
            deleteReactivateMasterType: "",
            reviewLevel: "",
            alertDialogMessage: "",
            alertDialogHeading: "",
            proceedBtnLabel: "",
            showBtnLoader: false,
            showAlertDialogInfo: false,
            alertDialogMessageInfo: "",
            showUpdateReviewDialog: false,
            showReviewHistoryDialog:false,
            remarks: "",
            currentReviewLevel:"",
            endReviewLevel:"",
            
            assignmentMatrix: [],
            selectedResourceUserName: "",
            selectedResourceId: "",
            myPosition: "",
            
            overallChecklistScoreObj: {},
            checklistMaxRank: 0,

            showSelectUploadAnnexureDialog: false,
            isBackup: false,
            assignmentTaskUserId: "",
            isEditable:false,

            filterDataList: [],
            filterDropDownArr: [],
            filterPlaceholder: "All",

            singleSelectDropDownArr: [],

            followUpTaskTemplateList: [],
            showFollowUpTemplateValueDialog:false,
            filterOpenCloseDropDownArr: filterOpenCloseDropDownArr,
            filterOpenClosePlaceholder: "Open",
            assignmentTaskId:"",
            isFollowUpTemplateValueEditDialog:false,

            //Risk
            showSelectRiskDialog: false,
            selectedRiskObj: "",
            deptDefaultRisk: {},

            allAnnexureList:[],

            selectFollowUpTemplate:"",
            showFollowUpDetailsDialog: false,
            followUpDetailsItem:{},

            showTaskDetailsLayout: false,
            selectedTaskIdForDetails: "",
            user: {},
            isShowHeader:true,
            followUpTaskItem:false
        };
    }

    componentDidMount() {
        this.getInitData();
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.setState({
                componentDidMountFlag:false,
                showLoader:false,
                datalist:[],
                allDataList: [],
            },()=>{
                this.getInitData();
            })
        }
    }
    
    // Risk
    handleSelectRiskDialogShow = (taskItem) => {
        //User should select the department before selecting default risk
        let focusObj = this.state.filterDataList.length>0?this.state.filterDataList:this.state.datalist;
        const selectedRisk = this.state.deptDefaultRisk[this.state.assignmentDetails.departmentId].filter(item => {
            if(item.riskLevelId == taskItem.followUpRiskLevelId){
                return item;
            }
        })
        
        this.setState({
            selectedRiskObj:isEmptyArray(selectedRisk)?"":selectedRisk[0],
            showSelectRiskDialog: true,
            assignmentTaskId:taskItem.assignmentTaskId,
            ...focusObj,
        });
    };

    handleSelectRiskDialogClose = (reloadFlag) => {
        this.setState({
            showSelectRiskDialog: false,
            selectedRiskObj:""
        },() => {
            if (reloadFlag === true) {
                this.getFollowUpTaskTab();
            }
        });
    };

    handleSelectRiskDialogSaveClose = (selectedRiskObj) => {
        this.setState({
            showSelectRiskDialog: false,
            selectedRiskObj: selectedRiskObj,
        });
        this.getFollowUpTaskTab();
    };

    handleAlertDialogClose = () => {
        this.setState({
            showAlertDialog: false,
            alertDialogMessage: "",
        });
    };

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo: false,
            alertDialogMessageInfo: "",
        });
    };

    handleReviewHistoryDialogClose = () => {
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateId:"",
        });
    }

    handleFollowUpDetailsDialogShow = (followUpItem,assignmentTaskId,item) => {
        this.setState({
            showFollowUpDetailsDialog:true,
            followUpDetailsItem:followUpItem,
            assignmentTaskId:assignmentTaskId,
            followUpTaskItem:this.taskEditable(item)
        });
    }
    
    handleFollowUpDetailsDialogClose = () => {
        this.setState({
            showFollowUpDetailsDialog:false
        });
    }

    handleShowTaskDetailsLayout = (taskId) => {
        this.setState({
            isShowHeader:false,
            showTaskDetailsLayout: true,
            selectedTaskIdForDetails: taskId,
        });
    };

    handleHideTaskDetailsLayout = () => {
        this.setState({
            isShowHeader:true,
            showTaskDetailsLayout: false,
            selectedTaskIdForDetails: "",
        });
    };

    onTableDropDownItemClick = (item, dropdownItem) => {
        if (item.label === "Follow Up Details") {
            this.getPreviousFollowUpTaskTemplateValues(dropdownItem.assignmentTaskId, dropdownItem.observationId, dropdownItem);
        } else if (item.label === "Submit For Review") {
            this.setState({
                operationType: item.label,
                alertDialogHeading: "Submit For Review",
                proceedBtnLabel: "Submit",
                deleteReactivateId: dropdownItem.assignmentTaskId,
                showAlertDialog: true,
                alertDialogMessage: "Are you sure you want to submit for review?",
            });
        } else if (item.label === "Start Review") {
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+dropdownItem.companyName+" assignment?"
            })
        } else if (item.label === "Update Review Status") {
            this.setState({
                reviewLevel: dropdownItem.reviewLevel,
                deleteReactivateId: dropdownItem.assignmentTaskId,
                showUpdateReviewDialog: true,
                currentReviewLevel:dropdownItem.currentReviewLevel,
                endReviewLevel:dropdownItem.endReviewLevel
            });
        } else if (item.label === "Review History") {
            this.setState({
                deleteReactivateId:isEmptyVariable(dropdownItem.observationId)?dropdownItem.assignmentTaskId:dropdownItem.observationId,
                showReviewHistoryDialog:true,
            })
        }
    };

    maxrDropdownClick = (selectedItem, udf) => {
        this.setState({
            showLoader: true,
        });

        let iapArr = udf.split(":-:");
        let index = iapArr[0];
        let parentId = iapArr[1];
        let list = this.state.datalist;
        list[parentId].taskList[index].rank = selectedItem.id;

        this.setState({
            datalist: list,
            showLoader: false,
        },
        () => {
            this.handleSaveAssignmentTaskProperties(
                list[parentId].taskList[index].assignmentTaskId,
                selectedItem.id,
                "rank"
            );
        });
    };

    resourceDropdownItemClick = (selectedItem) => {
        let arr = this.getUserTasksFromUserId(selectedItem.id);
        let tempFilterArray = [
            {
                label: "All",
                isSelected: true,
            },
        ];

        Object.values(arr).map((item) => {
            let uid = 0;
            Object.values(item.taskList).map((task) => {
                uid = task.userId;
            });
            if (uid == selectedItem.id) {
                tempFilterArray.push({
                    label: this.state.assignmentArea === "Process" ? item.processName : this.state.assignmentArea === "Function" ? item.functionName : item.heading,
                    isSelected: false,
                });
            }
        });

        this.singleSelectAll();
        
        this.setState({
            selectedResourceId: selectedItem.id,
            selectedResourceUserName: selectedItem.label,
            datalist: arr,
            filterDropDownArr: tempFilterArray,
            filterPlaceholder: "All",
            filterDataList: [],
        });
    };

    getUserTasksFromUserId = (userId) => {
        let allData = JSON.parse(JSON.stringify(this.state.allDatalist));
        let arr = allData.map((process) => {
            let taskList = [];
            let maxScore = 0;
            let totalScore = 0;
            process.taskList.forEach((task) => {
                if (task.userId + "" === userId + "") {
                    taskList.push(task);

                    //Now if it is checklist, then based on the type of checklist we need to assign scores
                    if (this.state.assignmentArea === "CheckList") {
                        let weight = isEmptyVariable(task.weight) ? 1 : task.weight;
                        let rank =
                            isEmptyVariable(task.rank) || task.rank === -1 ? 0 : task.rank;

                        //if rank is NA then that should be considered for max score also
                        let maxRankNew = task.rank === -1 ? 0 : this.state.checklistMaxRank;
                        maxScore += weight * maxRankNew;
                        totalScore += weight * rank;
                    }
                }
            });
            process.taskList = taskList;
            process.maxScore = maxScore;
            process.totalScore = totalScore;
            return process;
        });
        return arr;
    };

    getTaskDisplayValues = (taskId, fieldItem) => {
        if (isEmptyVariable(this.state.taskTemplateValuesMap[taskId])) {
            return "-";
        }

        let fieldId = fieldItem.fieldId;
        if (isEmptyVariable(this.state.taskTemplateValuesMap[taskId][fieldId])) {
            return "-";
        }

        let displayValue =
            this.state.taskTemplateValuesMap[taskId][fieldId]["value"];
        if (isEmptyVariable(displayValue)) {
            return "-";
        }

        switch (fieldItem.fieldType) {
            case TemplateConstants.FIELD_TYPE_TEXT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DATE:
                return isEmptyVariable(displayValue)
                    ? ""
                    : moment(displayValue).format("DD/MM/YYYY");
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DROPDOWN:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
                if (!isJsonString(displayValue)) {
                    return "-";
                }
                return JSON.parse(displayValue).join(", ");
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
                if (!isJsonString(displayValue)) {
                    return "-";
                }
                return JSON.parse(displayValue).join(", ");
        }
        return "-";
    };

    handleUpdateReviewDialogClose = (reloadFlag) => {
        if (reloadFlag === true) {
            this.setState({
                showUpdateReviewDialog: false,
                deleteReactivateMasterType: "",
                deleteReactivateId: "",
                reviewLevel: "",
            },() => {
                this.getFollowUpTaskTab();
            });
        }else{
            this.setState({
                showUpdateReviewDialog: false,
            })
        }
    };

    taskFilterDropDownOnClicks = (selectedItem) => {
        let tempObservationFilterDataArray = this.state.observationTaskFilterDropDownArr;
        Object.values(tempObservationFilterDataArray).map((item) => {
            if (selectedItem.label === item.label) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });
       
        if(selectedItem.label === "Observation"){
            this.props.history.replace(Constants.URL_FOLLOWUPOBSERVATION + "/" + this.state.assignmentId,{
                departmentId:this.state.departmentId,
                observationTemplateId:this.state.observationTemplateId,
                observationTaskFilterDropDownArr:tempObservationFilterDataArray,
                observationTaskFilterPlaceholder:selectedItem.label,
            }); 
        }
    }

    filterOpenCloseDropDownOnClick = (taskItem,selectedItem) => {
        let tempFilterDataArray = this.state.filterOpenCloseDropDownArr;
        let placeholder = "";

        if(!selectedItem.label.includes(taskItem.closeOpenStatus)){
            
            tempFilterDataArray.map((item) => {
                if (item.label === selectedItem.label) {
                    item.isSelected = true;
                } else {
                    item.isSelected = false;
                }
            });
            placeholder = (selectedItem.label==="Checked & Open")?"Open":selectedItem.label;
            this.getFollowupTaskTemplatevalues(taskItem.assignmentTaskId);

            this.setState({
                showFollowUpTemplateValueDialog:true,
                isFollowUpTemplateValueEditDialog:false,
                filterOpenCloseDropDownArr: tempFilterDataArray,
                selectFollowUpTemplate: selectedItem.label
            })
        }
    }

    handleFollowUpTemplateValueDialogClose = () => {
        this.setState({
            showFollowUpTemplateValueDialog:false
        })
        this.getFollowUpTaskTab()
    }

    singleSelectFilterDropDownOnClick = (fieldId,selectedItem) => {
        let placeholder = "";
        let tempSingleSelectFilterDataArray = this.state.singleSelectDropDownArr;
        
        //Set placeholder based on selection 
        Object.values(tempSingleSelectFilterDataArray).forEach(item => {
            if(item.fieldId === fieldId){
                Object.values(item.dropdownValues).map(dropdownItem => {
                    if (selectedItem.label === dropdownItem.label) {
                        dropdownItem.isSelected = true;
                        if(dropdownItem.label!=="All"){
                            placeholder = dropdownItem.label;
                        }
                        item.placeholderValue = placeholder;
                    }else{
                        dropdownItem.isSelected = false;
                    }
                })
            }
        })

        // Single Select filter datalist based on dropdown item selection
        let datalist = [];
        let tempFilterSingleSelectPlaceHolderDataList = tempSingleSelectFilterDataArray.filter(item => !isEmptyVariable(item.placeholderValue));
        let selectedPlaceHolder = tempFilterSingleSelectPlaceHolderDataList.map(item => item.placeholderValue);

        if(tempFilterSingleSelectPlaceHolderDataList.length === 0){
            datalist = this.getUserTasksFromUserId(this.state.selectedResourceId);
        }else{
            tempSingleSelectFilterDataArray.map(singleSelectItem => {
                datalist = this.getUserTasksFromUserId(this.state.selectedResourceId).map(functionObj=>({
                    ...functionObj,
                    taskList: functionObj.taskList.filter(taskItem => {
                        return selectedPlaceHolder.includes(this.state.executionTemplateValuesMap[taskItem.assignmentTaskId]?.[singleSelectItem.fieldId]?.["valueNR"]==="Y"?
                        "NA":this.state.executionTemplateValuesMap[taskItem.assignmentTaskId]?.[singleSelectItem.fieldId]?.["value"])
                    })
                })).filter(item => item.taskList.length > 0);
            })
        }

        let tempFilterDataArray = this.state.filterDropDownArr;
        tempFilterDataArray.map((item) => {
            if (item.label === "All") {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });

        this.setState({
            datalist: datalist,
            singleSelectDropDownArr: tempSingleSelectFilterDataArray,
            filterDataList:[],
            filterDropDownArr: tempFilterDataArray,
            filterPlaceholder:this.state.assignmentArea
        });
    }

    singleSelectAll = () => {
        //Single Select filter selection default All
        let tempSingleSelectFilterDataArray = this.state.singleSelectDropDownArr;
        Object.values(tempSingleSelectFilterDataArray).forEach(item => {
            Object.values(item.dropdownValues).map(dropdownItem => {
                if (dropdownItem.label === "All") {
                    dropdownItem.isSelected = true;
                    item.placeholderValue = "";
                }else{
                    dropdownItem.isSelected = false;
                }
            })
        })
        this.setState({
            singleSelectDropDownArr: tempSingleSelectFilterDataArray
        })
    }

    filterDropDownOnClick = (selectedItem) => {
        this.singleSelectAll();
        let tempFilterDataList = [];
        let arr = this.getUserTasksFromUserId(this.state.selectedResourceId);
        let tempFilterDataArray = this.state.filterDropDownArr;

        if (selectedItem.label === 'All') {
            this.setState({
                filterDataList: [],
                datalist:this.state.allDatalist
            })
        } else if (selectedItem.label !== "All") {
            Object.values(arr).map((item) => {
                let tempDataTaskList = []
                Object.values(item.taskList).map((task) => {
                    if ((selectedItem.label === (this.state.assignmentArea === "Process" ? item.processName : this.state.assignmentArea === "Function" ? item.functionName : item.heading))) {
                        tempDataTaskList.push(task)
                    }
                })
                item.taskList = tempDataTaskList
                tempFilterDataList.push(item)
            })
        }

        tempFilterDataArray.map((item) => {
            if (item.label === selectedItem.label) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });
        
        this.setState({
            filterPlaceholder: selectedItem.label,
            filterDataList: tempFilterDataList,
            filterDropDownArr: tempFilterDataArray,
        })
    }; 

    taskEditable = (taskItem) => {
        let endReviewUserId = "";
        for(var i=0;i<7;i++){
            if(taskItem.endReviewLevel===i){
                endReviewUserId=taskItem[`L${i}UserId`]
            }
        }
        if(!isEmptyVariable(endReviewUserId) && taskItem.currentReviewUserId==userDetails.userId && endReviewUserId==userDetails.userId){
            return false;
        }else{
            return !isEmptyVariable(taskItem.observationId)||taskItem.allowEdit==="N"?true:false  
        }
    }

    // Upload annexure
    uploadAnnexuresBackupIconClick = (assignmentTaskId,isEditable,type,assignmentTaskUserId) => {
        if (type === "Annexures") {
            this.setState({
                showSelectUploadAnnexureDialog: true,
                deleteReactivateId: assignmentTaskId,
                isBackup: false,
            });
        } else if (type === "Backups") {
            this.setState({
                showSelectUploadAnnexureDialog: true,
                deleteReactivateId: assignmentTaskId,
                isBackup: true,
                isEditable:isEditable
            });
        } else if (type === "Review Comments") {
            this.setState({
                deleteReactivateId: assignmentTaskId,
                showReviewCommentsDialog: true,
                assignmentTaskUserId: assignmentTaskUserId,
                type: "Comment",
            });
        }
    };

    handleSelectUploadAnnexureDialogClose = (reloadFlag) => {
        this.setState({
            showSelectUploadAnnexureDialog: false,
            isBackup: false,
        },
        () => {
            if (reloadFlag === true) {
                this.getFollowUpTaskTab();
            }
        });
    };

    /*################################## EXEC TASK RELATED #################################*/
    getExecTableDataDisplayValue = (assignmentTaskId, fieldItem) => {
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId])) {
            return "";
        }

        let fieldId = fieldItem.fieldId;
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId][fieldId])) {
            return "";
        }

        let displayValue = this.state.executionTemplateValuesMap[assignmentTaskId][fieldId]["value"];
        if (isEmptyVariable(displayValue)) {
            return "";
        }

        switch (fieldItem.fieldType) {
            case TemplateConstants.FIELD_TYPE_TEXT:
                return removeHtmlTags(displayValue);
            case TemplateConstants.FIELD_TYPE_DATE:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DROPDOWN:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
                if (!isJsonString(displayValue)) {
                    return "";
                }
                return JSON.parse(displayValue).join(", ");
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
                if (!isJsonString(displayValue)) {
                    return "";
                }
                return JSON.parse(displayValue).join(", ");
            case TemplateConstants.FIELD_TYPE_TABLE:
                if (!isJsonString(displayValue)) {
                    return "";
                }
                return JSON.parse(displayValue);
        }
        return "";
    };

    getExecTableDataDisplayValueNR = (assignmentTaskId, fieldItem) => {
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId])) {
            return "";
        }

        let fieldId = fieldItem.fieldId;
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId][fieldId])) {
            return "";
        }

        let displayValue = this.state.executionTemplateValuesMap[assignmentTaskId][fieldId]["valueNR"];
        if (displayValue === "Y") {
            return "Y";
        }else{
            return "N"
        }
    };

    getExecTaskDisplayTableData = (assignmentTaskId,fieldItem) => {
        switch (fieldItem.fieldType) { 
            case TemplateConstants.FIELD_TYPE_TEXT:
                return (
                    <td style={{height:"100%"}}>
                        <div
                            contenteditable={"false"}
                            onBlur={this.handleExecuteTextField.bind(
                                this,
                                assignmentTaskId,
                                fieldItem
                            )}
                            className="exec-task-text-field"
                        >
                            {this.getExecTableDataDisplayValue(assignmentTaskId, fieldItem)}
                        </div>
                    </td>
                );
            case TemplateConstants.FIELD_TYPE_DATE:
                let displayLabelDate = this.getExecTableDataDisplayValue(
                    assignmentTaskId,
                    fieldItem
                );
                return (
                    <td>
                        <DatePickerComponent
                            templateItemIndex={assignmentTaskId}
                            fieldItem={fieldItem}
                            onDateChange={this.onExecuteDateFieldChange}
                            placeholderValue={"Enter " + fieldItem.fieldName}
                            fieldValue={
                                !isEmptyVariable(displayLabelDate) ? displayLabelDate : ""
                            }
                            isDisabled={"Y"}
                        />
                    </td>
                );
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                let displayLabel = this.getExecTableDataDisplayValue(
                    assignmentTaskId,
                    fieldItem
                );
                let displayLabelNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );
                return (
                    <td>{displayLabelNR == "Y"?"NA":displayLabel}</td>
                );
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
            case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
                let displayLabelMulti = this.getExecTableDataDisplayValue(
                    assignmentTaskId,
                    fieldItem
                );
                let displayLabelMultiNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );
                return (
                    <td>{displayLabelMultiNR == "Y"?"NA":displayLabelMulti}</td>
                );
            case TemplateConstants.FIELD_TYPE_TABLE:
                let parsedJsonTableObj = this.getExecTableDataDisplayValue(
                    assignmentTaskId,
                    fieldItem
                );
                let displayLabelTableNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );
                return (
                    <td>
                        {
                            displayLabelTableNR == "Y"
                            ?"NA"
                            :(isEmptyVariable(parsedJsonTableObj) ? "-" : this.getAssignmentTaskExecTable(parsedJsonTableObj))
                        }
                    </td>
                );
        }
    };

    onExecuteDateFieldChange = (dateValue, assignmentTaskId, fieldItem) => {
        this.saveAssignmentTaskTemplateValues(assignmentTaskId,fieldItem,dateValue,{});
    };

    getFirstRowItemValue = (item,idx) => {
        let tempFirstRowList =["yes / no with disable","yes / no / na with disable","yes / no","yes / no / na","textfield"]
        if(idx==0)
        {
            if(!tempFirstRowList.includes(item.toLocaleLowerCase()) )
            {
                return item
            }
            
            else
            {
                return ""
            }
            
        }
        else if(idx!=0){
            return item
        }
       
    }

    getAssignmentTaskExecTable = (parsedJsonTableObj) => {
        return <table className="my-2">
            {
                Object.values(parsedJsonTableObj.tableArray).map((item, idx) => {
                    return <tr>
                        <td style={{ fontSize: 10, minwidth: "300px", Height: "22px" }}>{item[0]}</td>
                        <td style={{ fontSize: 10, minwidth: "300px", Height: "22px" }}>{this.getFirstRowItemValue(item[1],idx)}</td>
                    </tr>
                })
            }
        </table>
    }

    handleExecuteTextField = (assignmentTaskId, fieldItem, e) => {
        const value = e.currentTarget.textContent;
        let currentRemark = this.getExecTableDataDisplayValue(
            assignmentTaskId,
            fieldItem
        );

        if (currentRemark !== value) {
            this.saveAssignmentTaskTemplateValues(
                assignmentTaskId,
                fieldItem,
                value,
                {}
            );
        }
    };
    /*################################## EXEC TASK RELATED ENDS#################################*/

    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader: true,
        });

        Promise.all([
            fetch(Constants.GetFollowUpTaskTab, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                    departmentId: this.state.departmentId
                }),
            }),
            fetch(Constants.GetAssignmentsDetails, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                }),
            }),
            fetch(Constants.GetUserMenu, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                }),
            }),
        ])
        .then(([taskRes, assignmentRes, menuRes]) => {
            return Promise.all([
                taskRes.json(),
                assignmentRes.json(),
                menuRes.json()
            ]);
        })
        .then(([taskRes, assignmentRes, menuRes]) => {
            let allDatalistTemp = [];
            let myDatalistTemp = [];
            let assignmentStatus = "";
            let assignmentArea = "";
            let assignmentDetails = {};
            let deptDefaultRisk = taskRes.data.deptDefaultRisk;
            let singleSelectFilters = [];
            let singleSelectFiltersList = [];

            //Since we need assignment area lets check this first
            let selectedTaskTemplateFields = [];
            let execTaskTemplateFields = [];
            let taskTemplateValuesMap = {};
            let executionTemplateValuesMap = {};
            let executionTemplateFixedFields = {};
            let followUpTemplateFixedFields = {};
            let userTemp = {};

            if (
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                assignmentStatus = assignmentRes.data.assignmentDetails.assignmentStatus;
                assignmentArea = assignmentRes.data.assignmentDetails.assignmentArea;
                assignmentDetails = assignmentRes.data.assignmentDetails;


                //TASK TEMPLATE RELATED STUFFS
                taskTemplateValuesMap = assignmentRes.data.taskTemplateValuesMap;
                let selectedTaskTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.taskTemplateFields; //Template field IDs
                if (
                    !isEmptyVariable(selectedTaskTemplateFieldsStr) &&
                    isJsonString(selectedTaskTemplateFieldsStr)
                ) {
                    selectedTaskTemplateFields = JSON.parse(selectedTaskTemplateFieldsStr);
                }

                //EXEC TASK RELATED STUFFS
                executionTemplateValuesMap = assignmentRes.data.executionTemplateValuesMap;

                let execTaskTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.templateFields;
                if (
                    !isEmptyVariable(execTaskTemplateFieldsStr) &&
                    isJsonString(execTaskTemplateFieldsStr)
                ) {
                    execTaskTemplateFields = JSON.parse(execTaskTemplateFieldsStr);
                }

                //EXEC TASK FIXED FIELDS
                let fixedTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.fixedTemplateFields;
                if (
                    !isEmptyVariable(fixedTemplateFieldsStr) &&
                    isJsonString(fixedTemplateFieldsStr)
                ) {
                    executionTemplateFixedFields = JSON.parse(fixedTemplateFieldsStr);
                }

                //FOLLOWUP TASK FIXED FIELDS
                let followUpFixedTemplateFieldsStr = assignmentRes.data.followUpTemplateFields?.fixedTemplateFields;
                if (
                    !isEmptyVariable(followUpFixedTemplateFieldsStr) &&
                    isJsonString(followUpFixedTemplateFieldsStr)
                ) {
                    followUpTemplateFixedFields = JSON.parse(followUpFixedTemplateFieldsStr);
                }
            } else {
            }

            let maxRank = 0;
            let showChecklistScores = false;
            let overallChecklistScoreObj = {};
            let assignmentChecklistObj = {};
            let matrixandMyDetails = {};
            let task = {};

            if (
                taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (taskRes.responseCode === Constants.CODE_SUCCESS) {
                allDatalistTemp = taskRes.data.assignedTasks;
                singleSelectFilters = taskRes.data.singleSelectFilters;
                let allDataList = JSON.parse(JSON.stringify(allDatalistTemp));
                //if assignment area is checklist we may need to show scores in certain cases
                if (assignmentArea === "CheckList") {
                    assignmentChecklistObj = taskRes.data.assignmentCheckList;
                    overallChecklistScoreObj = taskRes.data.assignmentScore;
                    showChecklistScores = getCheckListScoresFlag(
                        assignmentChecklistObj.checkListType
                    );
                    if (showChecklistScores) {
                        maxRank = assignmentChecklistObj.maxRank;
                    }
                }

                //show only Selected User's/My tasks from the tasks list
                myDatalistTemp = getOnlyMyTasks(allDataList, assignmentArea, maxRank);

                //need list of users/reviewers
                let assignmentMatrixObj = taskRes.data.assignmentMatrix;

                //Get memberslist & Reviewers list for dropdown
                if(!isEmptyVariable(assignmentMatrixObj)){
                    matrixandMyDetails = getMatrixandMyDetails(assignmentMatrixObj);
                }
            } else {
            }

            if (
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                userTemp = menuRes.data.user;
            } else {
            }

            //Lets check few things that are needed for checklist based assignment
            let showWeight = false;
            let showRanking = false;
            let showYesNoDD = false;
            let minRank = 0;
            let rankingArray = getRankingArray(minRank, maxRank);

            if (!isEmptyArray(allDatalistTemp) && assignmentArea === "CheckList") {
                let firstItem = allDatalistTemp[0];
                minRank = firstItem.taskList[0].minRank;

                if (!isEmptyArray(firstItem.taskList)) {
                    if (firstItem.taskList[0].checkListType === "WR") {
                        showWeight = true;
                        showRanking = true;
                    } else if (firstItem.taskList[0].checkListType === "R") {
                        showRanking = true;
                    } else if (firstItem.taskList[0].checkListType === "Y/N") {
                        showYesNoDD = true;
                    }
                }
            }

            let tempFilterArray = [
                {
                    label: "All",
                    isSelected: true,
                },
            ];

            Object.values(myDatalistTemp).map((item) => {
                let uid = 0;
                Object.values(item.taskList).map((task) => {
                    uid = task.userId;
                });

                if (uid === userDetails.userId) {
                    tempFilterArray.push({
                        label: assignmentArea === "Process" ? item.processName : assignmentArea === "Function" ? item.functionName : item.heading,
                        isSelected: false,
                    });
                }
            });


            //Set filter for single select
            Object.values(singleSelectFilters).forEach(item => {
                let tempSingleSelectItem = {};
                tempSingleSelectItem.fieldId = item.fieldId;
                tempSingleSelectItem.fieldName = item.fieldName;
                let tempSingleSelectArr = [
                    {
                        label: "All",
                        isSelected: true
                    },
                    {
                        label:"NA",
                        isSelected: false
                    }
                ];
                Object.values(item.dropdownValues).map(dropdownItem => {
                    tempSingleSelectArr.push({
                        label: dropdownItem.value,
                        isSelected: false
                    })
                })
                tempSingleSelectItem.dropdownValues = tempSingleSelectArr;
                tempSingleSelectItem.placeholderValue = "";
                singleSelectFiltersList.push(tempSingleSelectItem);
            });

            //Risk Level
            let riskObj = {
                riskLevelId: deptDefaultRisk[assignmentDetails.departmentId].riskLevelId,
                riskLevel: deptDefaultRisk[assignmentDetails.departmentId].riskLevel,
            };
            task = {
                selectedRiskObj: riskObj
            }

            this.setState({
                componentDidMountFlag: true,
                showLoader: false,
                datalist: myDatalistTemp,
                allDatalist: allDatalistTemp,
                
                selectedTaskTemplateFields: selectedTaskTemplateFields,
                taskTemplateValuesMap: taskTemplateValuesMap,
                execTaskTemplateFields: execTaskTemplateFields,
                executionTemplateValuesMap: executionTemplateValuesMap,
                executionTemplateFixedFields: executionTemplateFixedFields,
                followUpTemplateFixedFields:followUpTemplateFixedFields,
                
                assignmentStatus: assignmentStatus,
                assignmentArea: assignmentArea,
                assignmentDetails: assignmentDetails,
                assignmentMatrix: matrixandMyDetails.assignmentMatrix,
                selectedResourceUserName: matrixandMyDetails.selectedResourceUserName,
                selectedResourceId: userDetails.userId,
                myPosition: matrixandMyDetails.myPosition,
                
                showWeight: showWeight,
                showRanking: showRanking,
                showYesNoDD: showYesNoDD,
                checklistMaxRank: maxRank,
                showChecklistScores: showChecklistScores,
                overallChecklistScoreObj: overallChecklistScoreObj,
                rankingArray: rankingArray,
                filterDropDownArr: tempFilterArray,

                singleSelectDropDownArr:singleSelectFiltersList,
                
                user: userTemp,

                ...task,
                deptDefaultRisk:deptDefaultRisk
            });
        });
    };

    getProcessGroupingTaskList = (Datalist, assignmentArea) => {
        if (assignmentArea === "Function") {
            let tempProcessList = [];
            let processName = [];
            let tempAllDataList = Object.values(Datalist);
            Object.values(Datalist).map((item) => {
                Object.values(item.taskList).map((task) => {
                    if (!processName.includes(task.processNameList)) {
                        processName.push(task.processNameList)
                    }
                })
            })

            tempAllDataList.map((item) => {
                let tempTaskList = [];
                tempProcessList = [];
                let tempItem = item;
                let tempProcessId = "";
                processName.map((process) => {
                    tempTaskList = [];
                    Object.values(item.taskList).map((task) => {
                        tempProcessId = task.processIdList
                        if (task.processNameList === process) {
                            tempTaskList.push(task)
                        }
                    })

                    tempProcessList.push({
                        processName: process,
                        functionName: item.functionName,
                        processId: tempProcessId,
                        taskList: tempTaskList
                    })
                    tempItem["processTaskList"] = tempProcessList
                })
            })
            return tempAllDataList
        }
        else {
            return Datalist
        }
    }

    getFollowUpTaskTab = () => {
        this.setState({
            showLoader: true,
        });

        fetch(Constants.GetFollowUpTaskTab, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentId: this.state.assignmentId,
                departmentId: this.state.departmentId
            }),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                let assignmentChecklistObj = {};
                let overallChecklistScoreObj = {};

                let allDatalistTemp = data.data.assignedTasks;
                let allDataList = JSON.parse(JSON.stringify(allDatalistTemp));

                let maxRank = 0;
                if (this.state.assignmentArea === "CheckList") {
                    assignmentChecklistObj = data.data.assignmentCheckList;
                    overallChecklistScoreObj = data.data.assignmentScore;

                    if (
                        assignmentChecklistObj.checkListType === "Y/N" ||
                        assignmentChecklistObj.checkListType === "WR" ||
                        assignmentChecklistObj.checkListType === "R"
                    ) {
                        maxRank = assignmentChecklistObj.maxRank;
                    }
                }

                //show only Selected User's/My tasks
                let myDatalistTemp = allDataList.map((process) => {
                    let taskList = [];
                    let maxScore = 0;
                    let totalScore = 0;
                    process.taskList.forEach((task) => {
                        if (task.userId + "" === this.state.selectedResourceId + "") {
                            taskList.push(task);

                            //Now if it is checklist, then based on the type of checklist we need to assign scores
                            if (this.state.assignmentArea === "CheckList") {
                                let weight = isEmptyVariable(task.weight) ? 1 : task.weight;
                                let rank =
                                    isEmptyVariable(task.rank) || task.rank === -1
                                        ? 0
                                        : task.rank;

                                let maxRankNew = task.rank === -1 ? 0 : maxRank;
                                maxScore += weight * maxRankNew;
                                totalScore += weight * rank;
                            }
                        }
                    });
                    process.taskList = taskList;
                    process.maxScore = maxScore;
                    process.totalScore = totalScore;
                    return process;
                });

                this.setState({
                    showLoader: false,
                    datalist: myDatalistTemp,
                    allDatalist: allDatalistTemp,
                    overallChecklistScoreObj: overallChecklistScoreObj,
                });
            } else {
                this.setState({
                    datalist: [],
                    showLoader: false,
                });
            }
        });
    };

    getAssignmentDetails = (resetStateValues) => {
        this.setState({
            showLoader: true,
        });

        fetch(Constants.GetAssignmentsDetails, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentId: this.state.assignmentId,
            }),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                let executionTemplateValuesMap = data.data.executionTemplateValuesMap;
                let executionTemplateFixedFields = {};
                let followUpTemplateFixedFields = {};
                let fixedTemplateFieldsStr = data.data.executionTemplateFields?.fixedTemplateFields;
                if (
                    !isEmptyVariable(fixedTemplateFieldsStr) &&
                    isJsonString(fixedTemplateFieldsStr)
                ) {
                    executionTemplateFixedFields = JSON.parse(fixedTemplateFieldsStr);
                }
                //FOLLOWUP TASK FIXED FIELDS
                let followUpFixedTemplateFieldsStr = data.data.followUpTemplateFields?.fixedTemplateFields;
                if (
                    !isEmptyVariable(followUpFixedTemplateFieldsStr) &&
                    isJsonString(followUpFixedTemplateFieldsStr)
                ) {
                    followUpTemplateFixedFields = JSON.parse(followUpFixedTemplateFieldsStr);
                }
                this.setState({
                    executionTemplateValuesMap: executionTemplateValuesMap,
                    executionTemplateFixedFields: executionTemplateFixedFields,
                    followUpTemplateFixedFields: followUpTemplateFixedFields,
                    showLoader: false,
                    ...resetStateValues,
                });
            } else {
            }
        });
    };

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
        };
        let callApi = true;

        if(this.state.operationType === "Follow Up Details"){
            url = Constants.GetPreviousFollowUpTaskTemplateValues;
            postParam.assignmentTaskId = this.state.deleteReactivateId;
        }else if (this.state.operationType === "Submit For Review"){
            url = Constants.SubmitFollowUpTaskForReview;
            postParam.assignmentTaskId = this.state.deleteReactivateId;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.UpdateFollowUpTaskReviewStatus;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Update Review Status"){
            url = Constants.UpdateFollowUpTaskReviewStatus;
            postParam.assignmentTaskId = this.state.deleteReactivateId;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_APPROVED;
            if (!isEmptyVariable(this.state.remarks)) {
                postParam.remark = this.state.remarks;
            } else {
                postParam.remark = "";
            }
        }

        if (callApi) {
            this.setState({
                showBtnLoader: true,
            });

            fetch(url, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams(postParam),
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                ) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.setState({
                        deleteReactivateId: "",
                        deleteReactivateMasterType: "",
                        operationType: "",
                        alertDialogHeading: "",
                        proceedBtnLabel: "",
                        alertDialogMessage: "",
                        showAlertDialog: false,
                        remarks: "",
                        showBtnLoader: false
                    },() => {
                        this.getFollowUpTaskTab();
                    });
                } else {
                    this.setState({
                        showBtnLoader: false,
                        deleteReactivateId: "",
                        deleteReactivateMasterType: "",
                        operationType: "",
                        alertDialogHeading: "",
                        proceedBtnLabel: "",
                        alertDialogMessage: "",
                        showAlertDialog: false,
                        showAlertDialogInfo: true,
                        alertDialogMessageInfo: data.responseMessage,
                        remarks: "",
                    });
                }
            });
        }
    };

    getPreviousFollowUpTaskTemplateValues = (assignmentTaskId,observationId,item) => {
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
        }
        if(isEmptyVariable(observationId)){
            postParam.assignmentTaskId = assignmentTaskId;
        }else{
            postParam.observationId = observationId;
        }
        fetch(Constants.GetPreviousFollowUpTaskTemplateValues, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams(postParam),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                const previousCheckAndOpen = data.data.previousCheckAndOpen;
                this.handleFollowUpDetailsDialogShow(previousCheckAndOpen,assignmentTaskId,item);
            } else {
                this.setState({
                    showFollowUpDetailsDialog:false
                });
            }
        });
    }

    handleSaveAssignmentTaskProperties = (assignmentTaskId,remark,fieldName) => {
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
            assignmentId: this.state.assignmentId,
            assignmentTaskId: assignmentTaskId,
            [fieldName]: remark,
        };
        if (fieldName === "remark") {
            postParam.type = "Remark";
        }

        fetch(Constants.SaveAssignmentTaskProperties, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams(postParam),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                this.setState({}, () => {
                    this.getFollowUpTaskTab();
                });
            } else {
            }
        });
    };

    saveAssignmentTaskTemplateValues = (assignmentTaskId,fieldItem,value,resetStateValues,valueNR) => {
        let postObj = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
            assignmentTaskId: assignmentTaskId,
            fieldId: fieldItem.fieldId,
            [fieldItem.fieldId]: value,
        }

        if(valueNR === "Y"){
            postObj[fieldItem.fieldId+"_NR"] = "Y";
        }

        fetch(Constants.SaveAssignmentTaskTemplateValues, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams(postObj),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                this.getAssignmentDetails(resetStateValues);
            } else {
            }
        });
    };

    getFollowupTaskTemplatevalues = (assignmentTaskId) => {
        this.setState({
			showLoader: true,
		});

        fetch(Constants.GetFollowUpTaskTemplateValues, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentTaskId: assignmentTaskId,
            }),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                let followUpTaskTemplateLists = data.data;

                this.setState({
                    followUpTaskTemplateList: followUpTaskTemplateLists,
                    assignmentTaskId: assignmentTaskId,
                    showLoader: false,
                })
            } else {
                this.setState({
                    showLoader: false,
                });
            }
        });
    }

    getAllAnnexureList = (taskItem) => {
        let tempObj ={}; 
        tempObj.assignmentTaskId = taskItem.assignmentTaskId;
        let  url = Constants.GetAssignmentTaskAnnexures;
        return fetch(url, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
              email: userDetails.email,
              accessToken: userDetails.accessToken,
              assignmentTaskId:taskItem.assignmentTaskId,
            }),
        })
        .then((response) => {
            return response.json();
        }).then((data) => {
            if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                tempObj.annexureList = data.data.annexures
                this.state.allAnnexureList.push(tempObj)
            } 
        })
    }

    /*******************API CALLS END HERE*******************/
    
    render() {
        let showEmptyCard = true;
        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />
                        <div className="dashboard-right-bg-collpase">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.componentDidMountFlag && this.state.isShowHeader && (
                                    <FollowupHeading
                                        assignmentId={this.state.assignmentId}
                                        assignmentDetails={this.state.assignmentDetails}
                                        assignmentMatrix={this.state.assignmentMatrix}
                                        userDetails ={userDetails}
                                        selectedResourceId={this.state.selectedResourceId}
                                        themeSettings={themeSettings}
                                    />
                                )}
                                {
                                    this.state.showLoader && (
                                        <div class="loader"></div>
                                )}
                                {
                                    this.state.isShowHeader &&
                                    <div className="exe-followup-resource-dd flex-center-layout">
                                        {!isEmptyVariable(this.state.observationTemplateId) &&
                                            <div style={{ marginRight: 10 }}>
                                                <FilterDropDown
                                                    placeholder={this.state.observationTaskFilterPlaceholder}
                                                    dropdownArr={this.state.observationTaskFilterDropDownArr}
                                                    onDropDownItemClick={this.taskFilterDropDownOnClicks}
                                                    themeSettings={themeSettings}
                                                    name={"label"}
                                                    dropdownWidth="200px"
                                                />
                                            </div>
                                        }
                                        {!isEmptyArray(this.state.singleSelectDropDownArr) && this.state.singleSelectDropDownArr.map(item => {
                                            return(
                                                <div style={{ marginRight: 30 }}>
                                                    <FilterDropDown
                                                        placeholder={isEmptyVariable(item.placeholderValue)?item.fieldName:item.placeholderValue}
                                                        dropdownArr={item.dropdownValues}
                                                        onDropDownItemClick={this.singleSelectFilterDropDownOnClick.bind(this,item.fieldId)}
                                                        themeSettings={themeSettings}
                                                        name={"label"}
                                                        dropdownWidth="200px"
                                                    />
                                                </div>
                                            )
                                        })}
                                        
                                        <div style={{ marginRight: 30 }}>
                                            <FilterDropDown
                                                placeholder={this.state.filterPlaceholder=="All"?this.state.assignmentArea:this.state.filterPlaceholder}
                                                dropdownArr={this.state.filterDropDownArr}
                                                onDropDownItemClick={this.filterDropDownOnClick}
                                                themeSettings={themeSettings}
                                                name={"label"}
                                                dropdownWidth="200px"
                                            />
                                        </div>
                                        {
                                            (this.state.myPosition === "Viewer" ||
                                            this.state.myPosition === "Reviewer") && (
                                                <div style={{width:"200px", marginRight: 30}}>
                                                    <RegularDD
                                                        placeholder={this.state.selectedResourceUserName}
                                                        dropdownArr={this.state.assignmentMatrix}
                                                        labelParam="label"
                                                        onDropDownItemClick={this.resourceDropdownItemClick}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                }

                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag && 
                                    !this.state.showTaskDetailsLayout && (
                                        <div className="body-wrapper common-table-scroll">
                                            {
                                                !isEmptyArray(this.state.datalist) && this.state.assignmentArea !== "Function" &&
                                                (this.state.filterDataList.length > 0 ? this.state.filterDataList : this.state.datalist).map((item, index) => {
                                                    return (
                                                        !isEmptyArray(item.taskList) && (
                                                            <div>
                                                                <h6 style={{ marginTop: index === 0 ? 15 : 10 }} className="small-h6">
                                                                    {isEmptyVariable(item.processName) ? isEmptyVariable(item.functionName) ? item.heading : item.functionName : item.processName}
                                                                </h6>
                                                                {
                                                                    !isEmptyArray(item.taskList) && (
                                                                    <div>
                                                                        <div className="common-tab-bg">
                                                                            <div className="common-table" style={{ width: "100%" }} >
                                                                                <table className="table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="text-center" width="38px"> No.</th>
                                                                                            <th width="1%">
                                                                                            <div className="flex-center-layout justify-content-around">
                                                                                                <span className="tool" data-title="Review Comments">C</span>
                                                                                                {this.state.executionTemplateFixedFields?.annexures === "Y" && (
                                                                                                    <span className="tool" data-title="Annexure">A</span>
                                                                                                )}
                                                                                                {this.state.followUpTemplateFixedFields?.backups === "Y" && (
                                                                                                    <span className="tool" data-title="Backups">B</span>
                                                                                                )}                                                                                            </div>
                                                                                            </th>
                                                                                            {
                                                                                                this.state.selectedTaskTemplateFields.map(
                                                                                                (item) => {
                                                                                                    return (
                                                                                                        <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"}>
                                                                                                            {item.fieldName}
                                                                                                        </th>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                            <th style={{minWidth:"150px"}}>Follow Up Date</th>      
                                                                                            <th></th>
                                                                                            {
                                                                                                !isEmptyVariable(this.state.assignmentDetails.riskId) &&
                                                                                                <th>Follow Up Risk</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.executionTemplateFixedFields?.assignRisk === "Y" && 
                                                                                                <th>Risk</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.showWeight &&
                                                                                                <th>Weight</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.showYesNoDD &&
                                                                                                <th>Rate</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.showRanking && 
                                                                                                <th>Rate</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.execTaskTemplateFields.map(item => {
                                                                                                    let showHeader = true;
                                                                                                    if (item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI) {
                                                                                                        if (item.isFromRiskMaster) {
                                                                                                            if (item.isRiskMasterEnabled === "Y") {
                                                                                                                showHeader = true;
                                                                                                            } else {
                                                                                                                showHeader = false;
                                                                                                            }
                                                                                                        } else {
                                                                                                            showHeader = true;
                                                                                                        }
                                                                                                    }
                                                                                                    return showHeader && <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT
                                                                                                        ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"}>
                                                                                                        {item.fieldName}
                                                                                                        {item.isMandatorySelected === "Y" ? "*" : ""}
                                                                                                    </th>
                                                                                                })
                                                                                            }
                                                                                            {
                                                                                                !isEmptyVariable(this.state.assignmentDetails.observationTemplateId) &&
                                                                                                <th>Observation</th>
                                                                                            }
                                                                                            {
                                                                                                !isEmptyVariable(this.state.assignmentStatus) && 
                                                                                                this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_COMPLETED &&
                                                                                                <th className="text-center"></th>
                                                                                            }
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            !isEmptyArray(item.taskList) &&
                                                                                            item.taskList.map((taskItem, idx) => {
                                                                                                if(taskItem.annexureCount>0)
                                                                                                {
                                                                                                    // this.getAllAnnexureList(taskItem);
                                                                                                }
                                                                                                showEmptyCard = false;
                                                                                                let yNRank = defaultYNPlaceholder;
                                                                                                let actionObj = getFollowUpTasks(taskItem, this.state.assignmentDetails?.reviewAssignmentTask);
                                                                                                if(!isEmptyVariable(taskItem.observationId)){
                                                                                                    actionObj["actionArr"]=actionObj.actionArr.filter(e => ["Follow Up Details","Review History"].includes(e.label))
                                                                                                }
                                                                                                // For Y/N, get label from yesNoRankArr
                                                                                                if (this.state.showYesNoDD && !isEmptyVariable(taskItem.rank)) {
                                                                                                    Constants.yesNoRankArr.forEach((rankItem) => { if (taskItem.rank === rankItem.id) { yNRank = rankItem.label; } });
                                                                                                }
                                                                                                
                                                                                                return (
                                                                                                    <tr style={{ background: this.taskEditable(taskItem)? "#f5f5f5" : "#ffffff", height:"40px"}}>
                                                                                                        <td className="text-center"> {(this.state.currentPageNo - 1) * this.state.resultSize + (idx + 1)}</td>
                                                                                                        <td>
                                                                                                            <div className="flex-center-layout exec-task-indicators">
                                                                                                                <div className="review-comment-layout">
                                                                                                                    <span className="material-icons" style={{
                                                                                                                        color: taskItem.remarkCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                        opacity: taskItem.remarkCount === 0 ? "0.6" : "1",
                                                                                                                    }}
                                                                                                                    // onClick={this.uploadAnnexuresBackupIconClick.bind(this, taskItem.assignmentTaskId, "Review Comments", taskItem.userId)}
                                                                                                                    >
                                                                                                                        chat
                                                                                                                    </span>
                                                                                                                    {taskItem.newRemarkCount != 0 && (
                                                                                                                        <div class="review-comment-badge">
                                                                                                                            <span>{taskItem.newRemarkCount}</span>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                                {this.state.followUpTemplateFixedFields?.backups === "Y" && (
                                                                                                                    <span className="material-icons" style={{ 
                                                                                                                        color: taskItem.FollowUpBackupCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                        opacity: taskItem.FollowUpBackupCount === 0 ? "0.6" : "1"
                                                                                                                    }}
                                                                                                                    onClick={this.uploadAnnexuresBackupIconClick.bind(this, taskItem.assignmentTaskId, this.taskEditable(taskItem), "Backups",taskItem.userId)}>
                                                                                                                        cloud_upload
                                                                                                                    </span>
                                                                                                                )}
                                                                                                                {this.state.executionTemplateFixedFields?.annexures === "Y" && (
                                                                                                                    <span className="material-icons" style={{
                                                                                                                        color: taskItem.annexureCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                        opacity: taskItem.annexureCount === 0 ? "0.6" : "1"
                                                                                                                    }}
                                                                                                                    // onClick={this.uploadAnnexuresBackupIconClick.bind(this, taskItem.assignmentTaskId, "Annexures")}
                                                                                                                    >
                                                                                                                        file_present
                                                                                                                    </span>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        {
                                                                                                            this.state.selectedTaskTemplateFields.map((item, taskIdx) => {
                                                                                                                return (
                                                                                                                    <td onClick={taskIdx + "" === "0" ? this.handleShowTaskDetailsLayout.bind(this, taskItem.taskId) : null} style={taskIdx + "" === "0" ? { borderLeft: "5px solid " + taskItem.colorCode } : {}} >
                                                                                                                        <div className={item.fieldId + "" === "1" ? "dangerouslysethtml-flex" : ""}>
                                                                                                                            <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{ __html: this.getTaskDisplayValues(taskItem.taskId, item) }} />
                                                                                                                            {
                                                                                                                                taskItem.completionStatus === "Completed" && item.fieldId + "" === "1" && (
                                                                                                                                    <span className="material-icons exec-task-done" >
                                                                                                                                        done_all
                                                                                                                                    </span>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                );
                                                                                                            }
                                                                                                        )}
                                                                                                        <td style={{minWidth:"180px"}}>
                                                                                                            <RegularDD
                                                                                                                placeholder={isEmptyVariable(taskItem.closeOpenStatus)?this.state.filterOpenClosePlaceholder:taskItem.closeOpenStatus}
                                                                                                                dropdownArr={this.state.filterOpenCloseDropDownArr}
                                                                                                                labelParam="label"
                                                                                                                onDropDownItemClick={this.filterOpenCloseDropDownOnClick.bind(this,taskItem)}
                                                                                                                disabled={this.taskEditable(taskItem)}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td></td>
                                                                                                        {
                                                                                                            !isEmptyVariable(this.state.assignmentDetails.riskId) && 
                                                                                                            <td style={{minWidth:"120px"}}>
                                                                                                                {
                                                                                                                    isEmptyVariable(taskItem.followUpRiskLevel)?
                                                                                                                    <div className="risk-level-color-wrapper" style={{ width: "110px",height:"30px", marginLeft:0 }} 
                                                                                                                        onClick={this.taskEditable(taskItem)?null:
                                                                                                                        this.handleSelectRiskDialogShow.bind(this,taskItem)}/> :
                                                                                                                    <div onClick={this.taskEditable(taskItem)? null :
                                                                                                                        this.handleSelectRiskDialogShow.bind(this,taskItem)}>
                                                                                                                        {isEmptyVariable(taskItem.followUpRiskLevel)?"":taskItem.followUpRiskLevel}
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.executionTemplateFixedFields?.assignRisk === "Y" &&
                                                                                                            <td>
                                                                                                                {isEmptyVariable(taskItem.assignmentTaskRiskLevel) ? "-" : taskItem.assignmentTaskRiskLevel}
                                                                                                            </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.showWeight && <td className="text-center"> {taskItem.weight} </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.showYesNoDD &&
                                                                                                            <td>
                                                                                                                <RegularDropdown
                                                                                                                    placeholder={isEmptyVariable(taskItem.rank) ? defaultYNPlaceholder : yNRank}
                                                                                                                    dropdownArr={Constants.yesNoRankArr}
                                                                                                                    labelParam="label"
                                                                                                                    onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                    udf={idx + ":-:" + index}
                                                                                                                    disabled={false}
                                                                                                                />
                                                                                                            </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.showRanking &&
                                                                                                            <td>
                                                                                                                <RegularDropdown
                                                                                                                    placeholder={isEmptyVariable(taskItem.rank) ? defaultWeightPlaceholder : taskItem.rank === -1 ? "N/A" : taskItem.rank}
                                                                                                                    dropdownArr={this.state.rankingArray}
                                                                                                                    labelParam="label"
                                                                                                                    onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                    udf={idx + ":-:" + index}
                                                                                                                    disabled={false}
                                                                                                                />
                                                                                                            </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.execTaskTemplateFields.map((item) => { 
                                                                                                                return this.getExecTaskDisplayTableData(taskItem.assignmentTaskId, item); 
                                                                                                            }
                                                                                                        )}
                                                                                                        {/* if task has no observation linked to it then show create button
                                                                                                        if task has no observation linked to it but completion status is completed then show just "-" */}
                                                                                                        {
                                                                                                            !isEmptyVariable(this.state.assignmentDetails.observationTemplateId) &&
                                                                                                            <td>
                                                                                                                {isEmptyVariable(taskItem.observationStatus) && taskItem.completionStatus === "Completed" ? "-" : isEmptyVariable(taskItem.observationStatus) ? "Create" : taskItem.observationStatus}
                                                                                                            </td>
                                                                                                        }

                                                                                                        <td className="text-center">
                                                                                                            {
                                                                                                                actionObj.actionArr && actionObj.actionArr.length > 0 && (
                                                                                                                <TableDropDown
                                                                                                                    actionArr={actionObj.actionArr}
                                                                                                                    onDropDownItemClick={this.onTableDropDownItemClick}
                                                                                                                    dropDownId={taskItem}
                                                                                                                    dropdownWidth={actionObj.dropdownWidth}
                                                                                                                    themeSettings={themeSettings}
                                                                                                                />
                                                                                                            )}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            this.state.showChecklistScores &&
                                                                            <div className="checklist-scores-layout">
                                                                                <p>{"Max Score: " + item.maxScore}</p>
                                                                                <p> {"Total Score: " + item.totalScore} </p>
                                                                                <p> {"Percentage: " + ((item.totalScore / item.maxScore) * 100).toFixed(1) + "%"} </p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                )}

                                                                {
                                                                    isEmptyArray(item.taskList) &&
                                                                    <div class="no-items-layout">
                                                                        <div class="no-items-card" style={{ padding: "10px" }} >
                                                                            <h6 style={{ margin: 0, fontSize: "13px", }} >
                                                                                {Constants.NO_RECORDS_WARNING}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    );
                                                })
                                            }

                                            {
                                                !isEmptyArray(this.state.datalist) && this.state.assignmentArea === "Function" &&
                                                (this.state.filterDataList.length > 0 ? this.getProcessGroupingTaskList(this.state.filterDataList, this.state.assignmentArea)
                                                    : this.getProcessGroupingTaskList(this.state.datalist, this.state.assignmentArea)
                                                ).map((process) => {
                                                    return !isEmptyArray(process.processTaskList) && Object.values(process.processTaskList).map((item, index) => {
                                                        return (
                                                            !isEmptyArray(item.taskList) && (
                                                                <div className="sticky-main-header">
                                                                    <div className="sticky-exec-header">
                                                                    {
                                                                        <h6 style={{ marginTop: index === 0 ? 5 : 5 }} className="small-h6"> 
                                                                            {!isEmptyVariable(process.functionName) ? process.functionName : ""} 
                                                                        </h6>
                                                                    }
                                                                    <center>
                                                                        <h6 style={{ marginTop: index === 0 ? 5 : 5 }} className="small-h6" >
                                                                            {!isEmptyVariable(item.processName) ? item.processName : ""}
                                                                        </h6>
                                                                    </center>
                                                                    </div>

                                                                    {
                                                                        !isEmptyArray(item.taskList) && (
                                                                        <div>
                                                                            <div className="common-tab-bg">
                                                                                <div className="common-table" style={{ width: "100%" }} >
                                                                                    <table className="table">
                                                                                        <thead className="sticky-exec-header" style={{top:29}}>
                                                                                            <tr>
                                                                                                <th className="text-center" width="38px" > No. </th>
                                                                                                <th width="1%">
                                                                                                <div className="flex-center-layout justify-content-around">
                                                                                                    <span className="tool" data-title="Review Comments">C</span>
                                                                                                    {this.state.executionTemplateFixedFields?.annexures === "Y" && (
                                                                                                        <span className="tool" data-title="Annexure">A</span>
                                                                                                    )}
                                                                                                    {this.state.followUpTemplateFixedFields?.backups === "Y" && (
                                                                                                        <span className="tool" data-title="Backups">B</span>
                                                                                                    )}                                                                                                </div>
                                                                                                </th>
                                                                                                {
                                                                                                    this.state.selectedTaskTemplateFields.map((item) => {
                                                                                                        return (
                                                                                                            <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"} > {item.fieldName} </th>
                                                                                                        );
                                                                                                    })
                                                                                                }
                                                                                                <th style={{minWidth:"150px"}}>Follow Up Date</th>
                                                                                                <th></th>
                                                                                                {
                                                                                                    !isEmptyVariable(this.state.assignmentDetails.riskId) &&
                                                                                                    <th>Follow Up Risk</th>
                                                                                                }
                                                                                                {this.state.executionTemplateFixedFields?.assignRisk === "Y" && (<th>Risk</th>)}
                                                                                                {this.state.showWeight && (<th>Weight</th>)}
                                                                                                {this.state.showYesNoDD && (<th>Rank</th>)}
                                                                                                {this.state.showRanking && (<th>Rank</th>)}
                                                                                                {
                                                                                                    this.state.execTaskTemplateFields.map(item => {
                                                                                                        let showHeader = true;
                                                                                                        if (item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI) {
                                                                                                            if (item.isFromRiskMaster) {
                                                                                                                if (item.isRiskMasterEnabled === "Y") {
                                                                                                                    showHeader = true;
                                                                                                                } else {
                                                                                                                    showHeader = false;
                                                                                                                }
                                                                                                            } else {
                                                                                                                showHeader = true;
                                                                                                            }
                                                                                                        }
                                                                                                        return showHeader && <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT
                                                                                                            ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"}>
                                                                                                            {item.fieldName}
                                                                                                            {item.isMandatorySelected === "Y" ? "*" : ""}
                                                                                                        </th>
                                                                                                    })
                                                                                                }
                                                                                                {
                                                                                                    !isEmptyVariable(this.state.assignmentDetails.observationTemplateId) &&
                                                                                                    <th>Observation</th>
                                                                                                }
                                                                                                {
                                                                                                    !isEmptyVariable(this.state.assignmentStatus) && 
                                                                                                    this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_COMPLETED &&
                                                                                                    <th className="text-center"></th>
                                                                                                }
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                !isEmptyArray(item.taskList) &&
                                                                                                item.taskList.map((taskItem, idx) => {
                                                                                                    if(taskItem.annexureCount>0)
                                                                                                    {
                                                                                                        this.getAllAnnexureList(taskItem);
                                                                                                    }
                                                                                                    showEmptyCard = false;
                                                                                                    let yNRank = defaultYNPlaceholder;
                                                                                                    let actionObj = getFollowUpTasks(taskItem, this.state.assignmentDetails?.reviewAssignmentTask);
                                                                                                    // For Y/N, get label from yesNoRankArr
                                                                                                    if (this.state.showYesNoDD && !isEmptyVariable(taskItem.rank)) {
                                                                                                        Constants.yesNoRankArr.forEach((rankItem) => {
                                                                                                            if (taskItem.rank === rankItem.id) { yNRank = rankItem.label }
                                                                                                        });
                                                                                                    }
                                                                                                    
                                                                                                    return (
                                                                                                        <tr style={{ background: this.taskEditable(taskItem) ? "#f5f5f5" : "#ffffff" }} >
                                                                                                            <td className="text-center"> {(this.state.currentPageNo - 1) * this.state.resultSize + (idx + 1)} </td>
                                                                                                            <td>
                                                                                                                <div className="flex-center-layout exec-task-indicators">
                                                                                                                    <div className="review-comment-layout">
                                                                                                                        <span className="material-icons"
                                                                                                                            style={{
                                                                                                                                color: taskItem.remarkCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                                opacity: taskItem.remarkCount === 0 ? "0.6" : "1",
                                                                                                                            }}
                                                                                                                            // onClick={this.uploadAnnexuresBackupIconClick.bind(this, taskItem.assignmentTaskId, "Review Comments", taskItem.userId)}
                                                                                                                            >
                                                                                                                            chat
                                                                                                                        </span>
                                                                                                                        {
                                                                                                                            taskItem.newRemarkCount != 0 && (
                                                                                                                            <div class="review-comment-badge">
                                                                                                                                <span>{taskItem.newRemarkCount}</span>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        this.state.followUpTemplateFixedFields?.backups === "Y" && (
                                                                                                                        <span className="material-icons"
                                                                                                                            style={{
                                                                                                                                color: taskItem.FollowUpBackupCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                                opacity: taskItem.FollowUpBackupCount === 0 ? "0.6" : "1",
                                                                                                                            }}
                                                                                                                            onClick={this.uploadAnnexuresBackupIconClick.bind(this, taskItem.assignmentTaskId, this.taskEditable(taskItem), "Backups")}>
                                                                                                                            cloud_upload
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                    {this.state.executionTemplateFixedFields?.annexures === "Y" && (
                                                                                                                        <span className="material-icons"
                                                                                                                            style={{
                                                                                                                                color: taskItem.annexureCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                                opacity: taskItem.annexureCount === 0 ? "0.6" : "1",
                                                                                                                            }}
                                                                                                                            // onClick={this.uploadAnnexuresBackupIconClick.bind(this, taskItem.assignmentTaskId, "Annexures")}
                                                                                                                            >
                                                                                                                            file_present
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            {
                                                                                                                this.state.selectedTaskTemplateFields.map((item, taskIdx) => {
                                                                                                                return (
                                                                                                                    <td onClick={taskIdx + "" === "0" ? this.handleShowTaskDetailsLayout.bind(this, taskItem.taskId) : null} style={taskIdx + "" === "0" ? { borderLeft: "5px solid " + taskItem.colorCode, } : {}} >
                                                                                                                        <div className={item.fieldId + "" === "1" ? "dangerouslysethtml-flex" : ""}>
                                                                                                                            <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{ __html: this.getTaskDisplayValues(taskItem.taskId, item) }} />
                                                                                                                            {
                                                                                                                                taskItem.completionStatus === "Completed" && item.fieldId + "" === "1" && (
                                                                                                                                    <span className="material-icons exec-task-done"> done_all </span>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                );
                                                                                                            }
                                                                                                            )}
                                                                                                            <td></td>      
                                                                                                            <td style={{minWidth:"200px"}}>
                                                                                                                <RegularDD
                                                                                                                    placeholder={isEmptyVariable(taskItem.closeOpenStatus)?this.state.filterOpenClosePlaceholder:taskItem.closeOpenStatus}
                                                                                                                    dropdownArr={this.state.filterOpenCloseDropDownArr}
                                                                                                                    labelParam="label"
                                                                                                                    onDropDownItemClick={this.filterOpenCloseDropDownOnClick.bind(this,taskItem)}
                                                                                                                    udf={idx + ":-:" + index}
                                                                                                                    disabled={this.taskEditable(taskItem)}
                                                                                                                />
                                                                                                            </td>
                                                                                                            {
                                                                                                                !isEmptyVariable(this.state.assignmentDetails.riskId) &&
                                                                                                                <td>
                                                                                                                    {
                                                                                                                        isEmptyVariable(taskItem.followUpRiskLevel)?
                                                                                                                        <div className="risk-level-color-wrapper" style={{ width: "110px",height:"30px", marginLeft:0 }} 
                                                                                                                            onClick={this.taskEditable(taskItem)?null:this.handleSelectRiskDialogShow.bind(this,taskItem)}/>
                                                                                                                        :
                                                                                                                        <div onClick={this.taskEditable(taskItem)?null:this.handleSelectRiskDialogShow.bind(this,taskItem)}>
                                                                                                                            {isEmptyVariable(taskItem.followUpRiskLevel)?"":taskItem.followUpRiskLevel}
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </td>
                                                                                                            }
                                                                                                            {
                                                                                                                this.state.executionTemplateFixedFields?.assignRisk === "Y" && 
                                                                                                                <td>
                                                                                                                    {isEmptyVariable(taskItem.assignmentTaskRiskLevel) ? "-" : taskItem.assignmentTaskRiskLevel}
                                                                                                                </td>
                                                                                                            }
                                                                                                            {this.state.showWeight && (<td className="text-center"> {taskItem.weight} </td>)}
                                                                                                            {
                                                                                                                this.state.showYesNoDD && (
                                                                                                                <td>
                                                                                                                    <RegularDropdown
                                                                                                                        placeholder={isEmptyVariable(taskItem.rank) ? defaultYNPlaceholder : yNRank}
                                                                                                                        dropdownArr={Constants.yesNoRankArr}
                                                                                                                        labelParam="label"
                                                                                                                        onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                        udf={idx + ":-:" + index}
                                                                                                                        disabled={false}
                                                                                                                    />
                                                                                                                </td>
                                                                                                            )}
                                                                                                            {
                                                                                                                this.state.showRanking && (
                                                                                                                <td>
                                                                                                                    <RegularDropdown
                                                                                                                        placeholder={isEmptyVariable(taskItem.rank) ? defaultWeightPlaceholder : taskItem.rank === -1 ? "N/A" : taskItem.rank}
                                                                                                                        dropdownArr={this.state.rankingArray}
                                                                                                                        labelParam="label"
                                                                                                                        onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                        udf={idx + ":-:" + index}
                                                                                                                        disabled={false}
                                                                                                                    />
                                                                                                                </td>
                                                                                                            )}

                                                                                                            {
                                                                                                                this.state.execTaskTemplateFields.map((item) => {
                                                                                                                    return this.getExecTaskDisplayTableData(taskItem.assignmentTaskId, item);
                                                                                                                }
                                                                                                            )}
                                                                                                            {/* if task has no observation linked to it then show create button if task has no observation linked to it but completion status is completed then show just "-" */}
                                                                                                            {
                                                                                                                !isEmptyVariable(this.state.assignmentDetails.observationTemplateId) &&
                                                                                                                <td>
                                                                                                                    {isEmptyVariable(taskItem.observationStatus) && taskItem.completionStatus === "Completed" ? "-" : isEmptyVariable(taskItem.observationStatus) ? "Create" : taskItem.observationStatus}
                                                                                                                </td>
                                                                                                            }
                                                                                                            {
                                                                                                                !isEmptyVariable(this.state.assignmentStatus) && this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_COMPLETED && (
                                                                                                                <td className="text-center">
                                                                                                                    {actionObj.actionArr && actionObj.actionArr.length > 0 && (
                                                                                                                        <TableDropDown
                                                                                                                            actionArr={actionObj.actionArr}
                                                                                                                            onDropDownItemClick={this.onTableDropDownItemClick}
                                                                                                                            dropDownId={taskItem}
                                                                                                                            dropdownWidth={actionObj.dropdownWidth}
                                                                                                                            themeSettings={themeSettings}
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </td>
                                                                                                            )}
                                                                                                        </tr>
                                                                                                    );
                                                                                                }
                                                                                                )}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.showChecklistScores && (
                                                                                <div className="checklist-scores-layout">
                                                                                    <p>{"Max Score: " + item.maxScore}</p>
                                                                                    <p> {"Total Score: " + item.totalScore} </p>
                                                                                    <p> {"Percentage: " + ((item.totalScore / item.maxScore) * 100).toFixed(1) + "%"} </p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {
                                                                        isEmptyArray(item.taskList) && (
                                                                        <div class="no-items-layout">
                                                                            <div class="no-items-card" style={{ padding: "10px" }} >
                                                                                <h6 style={{ margin: 0, fontSize: "13px", }} >
                                                                                    {Constants.NO_RECORDS_WARNING}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        );
                                                    })
                                                })
                                            }
                                            {
                                                this.state.componentDidMountFlag && showEmptyCard && (
                                                <div class="no-items-layout m-t-10">
                                                    <div class="no-items-card">
                                                        <h6>No Tasks</h6>
                                                    </div>
                                                </div>
                                            )}
                                            {
                                                this.state.showChecklistScores && (
                                                <div className="checklist-scores-layout">
                                                    <p> {"Checklist - Max Score: " + this.state.overallChecklistScoreObj?.maxScore} </p>
                                                    <p> {"Total Score: " + this.state.overallChecklistScoreObj?.actualScore} </p>
                                                    <p> {"Percentage: " + this.state.overallChecklistScoreObj?.scorePct + "%"} </p>
                                                </div>
                                            )}
                                        </div>

                                    )
                                }
                                {
                                    this.state.showTaskDetailsLayout && (
                                    <TaskDetailsLayout
                                        taskId={this.state.selectedTaskIdForDetails}
                                        handleHideTaskDetailsLayout={this.handleHideTaskDetailsLayout}
                                        subscriptionType={this.state.user.subscriptionType}
                                        handleShowTaskDetailsLayout={this.handleShowTaskDetailsLayout}
                                    />
                                )}
                            </div>
                        </div>
                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={Constants.ALERT_TYPE_INFO}
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            showLoader={this.state.showBtnLoader}
                            themeSettings={themeSettings}
                        />

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type={Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                            themeSettings={themeSettings}
                        />
                       
                        <FollowUpUpdateAssignmentTaskReviewStatus
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterDataId={this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            currentReviewLevel={this.state.currentReviewLevel}
                            endReviewLevel={this.state.endReviewLevel-1}
                            themeSettings={themeSettings}
                        />
                        
                        <SelectRiskDialog
                            showRiskDialog={this.state.showSelectRiskDialog}
                            handleSelectRiskDialogClose={this.handleSelectRiskDialogClose}
                            handleSelectRiskDialogSaveClose={
                                this.handleSelectRiskDialogSaveClose
                            }
                            assignmentTaskId={this.state.assignmentTaskId}
                            observationId={this.state.observationId}
                            selectedRiskObj={this.state.selectedRiskObj}
                            riskArr={
                                isEmptyArray(
                                    this.state.deptDefaultRisk[this.state.assignmentDetails.departmentId]
                                )? []
                                    : this.state.deptDefaultRisk[this.state.assignmentDetails.departmentId]
                            }
                            themeSettings={themeSettings}
                        />

                        <ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType={"Follow Up Assignment Task"}
                            masterDataId={this.state.deleteReactivateId}
                        />
                    
                        <AddFollowUpTemplateValueDialog
                            showFollowUpTemplateValueDialog={this.state.showFollowUpTemplateValueDialog}
                            handleFollowUpTemplateValueDialogClose={this.handleFollowUpTemplateValueDialogClose}
                            isFollowUpTemplateValueEditDialog={false}
                            themeSettings={themeSettings}
                            followUpTaskTemplateList={this.state.followUpTaskTemplateList}
                            assignmentTaskId={this.state.assignmentTaskId}
                            assignmentId={this.state.assignmentId}
                            selectFollowUpTemplate={this.state.selectFollowUpTemplate}
                            title={"Follow Up"}
                        />
                        
                        {this.state.showFollowUpDetailsDialog &&
                            <FollowUpDetailsDialog
                                showFollowUpDetailsDialog={this.state.showFollowUpDetailsDialog}
                                followUpDetailsItem={this.state.followUpDetailsItem}
                                handleFollowUpDetailsDialogClose={this.handleFollowUpDetailsDialogClose}
                                themeSettings={themeSettings}
                                item={this.state.followUpTaskItem}
                                assignmentTaskId={this.state.assignmentTaskId}
                                assignmentId={this.state.assignmentId}
                            />
                        }
                        <UploadAnnexureDialog
                            showUploadAnnexureDialog={this.state.showSelectUploadAnnexureDialog}
                            handleSelectUploadAnnexureDialogClose={this.handleSelectUploadAnnexureDialogClose}
                            isEditable={this.state.isEditable}
                            type={this.state.isBackup ? "Backup" : "Annexure"}
                            assignmentTaskId={this.state.deleteReactivateId}
                            themeSettings={themeSettings}
                        />
                    </section>
                </main>
            </Router>
        );
    }
}

export default FollowUpTask;
