import * as Constants from './url';

export const WRNG_MSG_TIMEOUT = 5000;
export const API_SERVER_PATH = Constants.BASE_URL_API;
export const LOADER_TYPE = "ThreeDots";
export const LOADER_COLOR = "#777";
export const LOADER_COLOR_BTN = "#fff";
export const VALID_PROFILE_IMAGE_SIZE = 1;
export const MAX_DOCUMENT_MASTER_FILE_SIZE = 10;

export const RESULT_SIZE = 10;
export const INFO_RESULT_SIZE = 5;
// export const RESULT_SIZE = 3;
export const RESULT_SIZE_GRID = 15;
export const STRING_LENGTH_XL = 80; //GLOBAL String Length for truncate
export const STRING_LENGTH_L = 60; //GLOBAL String Length for truncate
export const STRING_LENGTH = 40; //GLOBAL String Length for truncate
export const DEPT_STRING_LENGTH = 25; //Dept String Length for truncate
export const OTHERMASTERS_STRING_LENGTH = 25; //Other masters String Length for truncate
export const MANAGERS_STRING_LENGTH = 20; //managers String Length for truncate
export const DESC_STRING_LENGTH = 500; //GLOBAL String Length
export const REMARKS_STRING_LENGTH = 200; //GLOBAL String Length

export const ADDTASK_PATH = "addtask";
export const EDITTASK_PATH = "edittask";

export const ADDOBSERVATION_PATH = "addobservation";
export const EDITOBSERVATION_PATH = "editobservation";
export const EDITDRAFTOBSERVATION_PATH = "editdraftobservation";
export const EDITONGOINGOBSERVATION_PATH = "editongoingobservation";
export const DRAFTOBSERVATIONS_PATH = "draftobservations";
export const FINALIZEDOBSERVATIONS_PATH = "finalizedobservations";
export const INITIALDRAFTOBSERVATIONS_PATH = "initialdraftobservations";

export const TASK_TEMPLATES_PATH = "tasktemplates";
export const EX_TASK_TEMPLATES_PATH = "executetasktemplates";
export const OBSERVATION_TEMPLATES_PATH = "observationtemplates";
export const OVERALL_TEMPLATES_PATH = "overalltemplates";
export const FOLLOWUP_TEMPLATES_PATH = "followuptemplates";
export const AUDITEE_COMMENT_TEMPLATES_PATH = "auditeecommenttemplates";


export const RESULT_SIZE_DD_ARR = [
    // {
    //     label:"3",
    //     isSelected:true
    // },
    {
        label:"10",
        isSelected:true
    },
    {
        label:"20",
        isSelected:false
    },
    {
        label:"50",
        isSelected:false
    },
];
export const RESULT_SIZE_GRID_DD_ARR = [
    // {
    //     label:"3",
    //     isSelected:true
    // },
    {
        label:"15",
        isSelected:true
    },
    {
        label:"30",
        isSelected:false
    }
];
export const REPORT_DOWNLOAD_ARR = [
    {
        label:"Standard format", 
        format:"Report Format Standard"
    },
    {
        label:"Tabular format", 
        format:"Report Format Tabular"
    }
];
export const REPORT_DOWNLOAD_ARR_FINALIZED = [
    {
        label:"Standard format", 
        format:"Report Format Standard"
    },
    {
        label:"Tabular format", 
        format:"Report Format Tabular"
    },
    {
        label:"Draft Standard format", 
        format:"Report Format Standard"
    },
    {
        label:"Draft Tabular format", 
        format:"Report Format Tabular"
    },
    {
        label:"Uploaded Report", 
        format:"Report Format Uploaded"
    }
];
export const SALUTATION_ARR = [
    {
        name:"Mr.",
    },
    {
        name:"Ms.",
    },
    {
        name:"Mrs.",
    },
    {
        name:"Dr.",
    },
];
export const RISK_LEVELS = [
    {riskLevel:1},
    {riskLevel:2},
    {riskLevel:3},
    {riskLevel:4},
    {riskLevel:5},
    {riskLevel:6},
];
export const RISK_TYPES = [
    { riskType: "Tasks" },
    { riskType: "Risk" },
    { riskType: "Likelihood" },
];
export const yesNoRankArr = [
    {label:"Yes",id:1},
    {label:"No",id:0},
    {label:"N/A",id:-1},
];
export const CL_TYPE_REF = "Ref";
export const CL_TYPE_YN = "Y/N";
export const CL_TYPE_R = "R";
export const CL_TYPE_WR = "WR";

export const ChecklistType = {
    [CL_TYPE_REF]:"Reference",
    [CL_TYPE_YN]:"Yes or No",
    [CL_TYPE_R]:"Ranking",
    [CL_TYPE_WR]:"Weighted Ranking",
}

export const REPORT_STAUS = {
    inProcess: 'In Process',
    failed: 'Failed',
    completed: 'Generated'
}

export const SUBSCRIPTION_BASIC = "Basic";
export const SUBSCRIPTION_ESSENTIAL = "Essential";
export const SUBSCRIPTION_PREMIUM = "Premium";
export const SUBSCRIPTION_PROFESSIONAL = "Professional";
export const SUBSCRIPTION_ULTIMATE = "Ultimate";


export const REPORT_FORMAT_STANDARD = "Report Format Standard";
export const REPORT_FORMAT_TABULAR = "Report Format Tabular";
export const REPORT_FORMAT_UPLOADED = "Report Format Uploaded";

export const USER_ROLE_TASK_SEQUENCING = "Task Sequencing";
export const USER_ROLE_DASHBOARD_CURRENT = "Dashboard(Current Assignments)";
export const USER_ROLE_DASHBOARD_SCHEDULED = "Dashboard(Scheduled Assignments)";
export const USER_ROLE_DASHBOARD_PLANNED = "Dashboard(Planned Assignments)";
export const USER_ROLE_DASHBOARD_DRAFT = "Dashboard(Draft Reports)";
export const USER_ROLE_DASHBOARD_COMPLETED = "Dashboard(Completed Reports)";
export const USER_ROLE_DASHBOARD_NEW_TASKS = "Dashboard(New Tasks)";
export const USER_ROLE_DASHBOARD_FIGURE_AND_CHART = "Dashboard(Figure And Chart)";
export const USER_ROLE_DASHBOARD_ASSIGNMENT_SUMMARY = "Dashboard(Assignment Summary)";
export const USER_ROLE_DEPARTMENT_SETTINGS = "Department Settings";

export const USER_ENGAGEMENT_TYPE_SELF = "Self"
/****************** REVIEW LOGIC STATUS/MASTER SCREENS ****************************/

export const SCREEN_PROCESS = "Process";
export const SCREEN_FUNCTION = "Function";
export const SCREEN_INDUSTRY = "Industry";
export const SCREEN_DOCUMENT = "Document";
export const SCREEN_RISK = "Matrix";
export const SCREEN_OTHER = "Other Masters";
export const SCREEN_GENERAL = "GeneralMasters";
//Task
export const SCREEN_TASKS = "Task";
export const SCREEN_CHECKLIST = "Checklist";
//Planning
export const SCREEN_PLAN_ASSIGNMENT_ALL = "Plan Assignment(All)";
export const SCREEN_SCHEDULED_ASSIGNMENT_ALL = "Schedule Assignment(All)";
export const SCREEN_PLAN_ASSIGNMENT_MANAGERS = "Plan Assignment(Manager)";
export const SCREEN_SCHEDULED_ASSIGNMENT_MANAGERS = "Schedule Assignment(Manager)";
export const SCREEN_FOLLOW_UP_ASSIGNMENT = "Follow Up Assignment";
export const SCREEN_EXECUTE_FOLLOW_UP_ASSIGNMENT = "Follow Up Assignment";
export const SCREEN_RESPONSE_TEMPLATE = "Response Template";
export const SCREEN_EXECUTE = "Execute";
export const SCREEN_REPORT = "Report";
export const SCREEN_SCHEDULED = "Scheduled";
//Allow review status
export const ALLOW_REVIEW_SUBMITREVIEW = "SubmitReview";
export const ALLOW_REVIEW_STARTREVIEW = "StartReview";
export const ALLOW_REVIEW_UPDATEREVIEWSTATUS = "UpdateReviewStatus";
//Post param
export const REVIEW_STATUS_STARTED = "Review Started";
export const REVIEW_STATUS_REQ_CHANGE = "Request Change";
export const REVIEW_STATUS_APPROVED = "Review Approved";
/****************** WARNING MESSAGES *******************************/
export const ALERT_TYPE_WARNING = "warning";
export const ALERT_TYPE_INFO = "info";
export const ALERT_TYPE_ALERT = "alert";
export const ALERT_TYPE_OKAY_LABEL = "Okay";
export const ALERT_TYPE_PROCEED_LABEL = "Proceed";
export const CLIENT_DELETE_BUTTON_LABEL = "Delete Client";
export const CLIENT_REACTIVATE_BUTTON_LABEL = "Delete Client";

export const NO_RECORDS_WARNING = "No records found";
export const EMPTY_SEARCH_WARNING = "There aren't any results for that query."
export const NO_SINGLE_SELECT_FIELD_WARNING = "No single select field";
/****************** WARNING MESSAGES END HERE *******************************/

/****************** ROUTES *******************************/
export const URL_CLIENTS = "/listclients";
export const URL_ADDCLIENT = "/addclient";
export const URL_EDITCLIENT = "/editclient";

export const URL_DASHBOARD = "/dashboard";
export const URL_PROFILE = "/profile";
export const URL_NOTIFICATIONS = "/notifications";
export const URL_ADMIN_SETTINGS = "/adminSettings";
export const URL_CLIENT_SETTINGS = "/clientsettings";
export const URL_DEPARTMENT_SETTINGS = "/departmentsettings";

export const URL_USERLIST = "/users";
export const URL_USERROLES = "/userroles";
export const URL_ADDROLE = "/adduserrole";
export const URL_EDITROLE = "/edituserrole";
export const URL_USERDEPARTMENTS = "/userdepartments";
export const URL_MANAGERS = "/managers";
export const URL_GENERALMASTERS = "/generalmasters";

export const URL_COMPANIES = "/clients";
export const URL_CITIES = "/cityMaster";
export const URL_UNITS = "/units";
export const URL_EMPLOYEES = "/employees";

export const URL_PROCESSES = "/processes";
export const URL_FUNCTIONS = "/functions";
export const URL_INDUSTRIES = "/industries";
export const URL_DOCUMENTS = "/documents";
export const URL_RISK = "/risk";
export const URL_OTHERMASTERS = "/othermasters";

export const URL_TASKS = "/tasks";
export const URL_SCHEDULED_ASSIGNMENTS = "/scheduledassignments";
export const URL_ADDTASKS = "/addtask";
export const URL_VIEWTASKS = "/viewtasks";
export const URL_EDITTASKS = "/edittask";
export const URL_DRAFTTASKS = "/drafttasks";
export const URL_VIEWFUNCTIONTASKS = "/viewfunctiontasks";
export const URL_CHECKLIST = "/checklist";
export const URL_FOLLOWUP_ASSIGNMENTS = "/followups";
export const URL_PLANNEDASSIGNMENTS = "/assignments";
export const URL_SCHEDULEDASSIGNMENTS = "/scheduledassignments";
export const URL_UNDERREVIEWTASKS = "/underreviewtasks";
export const URL_ADDTASK_WITH_TEMPLATES = "/addtaskwithtemplates";
export const URL_EDITTASK_WITH_TEMPLATES = "/edittaskwithtemplates";


export const URL_EXECUTEASSIGNMENTS = "/executeassignmentlist";
export const URL_EXECUTEFOLLOWUP = "/executefollowups";
export const URL_FOLLOWUPTASK = "/followupstask";
export const URL_FOLLOWUPOBSERVATION = "/followupsobservation";
export const URL_EXECUTETASK = "/executetasklist";
export const URL_ADDTASK = "/addobservation";
export const URL_OBSERVATIONLIST = "/observationlist";
export const URL_SAMPLECHECKEDLIST = "/samplecheckedlist";
export const URL_EDITOBSERVATION = "/editobservation";
export const URL_WORKINGPAPERSLIST = "/workingpaperslist";
export const URL_ASSIGNMENTEMPLOYEE = "/assignmentemployee";
export const URL_OVERALL_EXECUTETASK = "/overallexecutetasklist";
export const URL_TIMELINE = "/timeline";

export const URL_ONGOING_REPORTS = "/ongoingassignmentreports";
export const URL_DRAFT_REPORTS = "/draftassignmentreports";
export const URL_COMPLETED_REPORTS = "/completedassignmentreports";
export const URL_ONGOING_OBSERVATIONS = "/ongoingobservations";
export const URL_ONGOING_ASSIGNMENTEMPLOYEE = "/ongoingassignmentemployee";


export const URL_DRAFT_ASSIGNMENTEMPLOYEE = "/draftassignmentemployee";
export const URL_FINAL_ASSIGNMENTEMPLOYEE = "/finalassignmentemployee";
export const URL_INIT_DRAFT_ASSIGNMENTEMPLOYEE = "/initdraftassignmentemployee";
export const URL_FINAL_OBSERVATIONS = "/finalizedobservations";
export const URL_COMPLETED_FOLLOWUP = "/completedfollowupobservation";
export const URL_COMPLETED_TASK = "/completedfollowuptask";
export const URL_DRAFT_OBSERVATIONS = "/draftobservations";
export const URL_EDIT_DRAFT_OBSERVATIONS = "/editdraftobservation";
export const URL_EDIT_ONGOING_OBSERVATIONS = "/editongoingobservation";
export const URL_ONGOING_SAMPLE_CHECKED = "/ongoingsamplechecked";
export const URL_ONGOING_WORKING_PAPERS = "/ongoingworkingpapers";
export const URL_ONGOING_TIMELINE = "/ongoingtimeline";
export const URL_DRAFT_SAMPLE_CHECKED = "/draftsamplechecked";
export const URL_DRAFT_WORKING_PAPERS = "/draftworkingpapers";
export const URL_DRAFT_TIMELINE = "/drafttimeline";
export const URL_FINAL_SAMPLE_CHECKED = "/finalsamplechecked";
export const URL_FINAL_WORKING_PAPERS = "/finalworkingpapers";
export const URL_FINAL_TIMELINE = "/finaltimeline";
export const URL_INIT_DRAFT_TIMELINE = "/initdrafttimeline";
export const URL_INITIAL_DRAFT_REPORT = "/initialdraftobservations";
export const URL_ONGOING_OVERALL = "/ongoingoverall";
export const URL_DRAFT_OVERALL = "/draftoverall";
export const URL_INIT_DRAFT_OVERALL = "/initdraftoverall";
export const URL_FINAL_OVERALL = "/finalizedoverall";




export const URL_TASK_TEMPLATES = "/tasktemplates";
export const URL_EXECUTE_TASK_TEMPLATES = "/executetasktemplates";
export const URL_OBSERVATION_TEMPLATES = "/observationtemplates";
export const URL_FOLLOWUP_TEMPLATES = "/followuptemplates";
export const URL_EDIT_TASK_TEMPLATE = "/edittasktemplate";
export const URL_ADD_OBSERVATION_TEMPLATE = "/addobservationtemplate";
export const URL_EDIT_OBSERVATION_TEMPLATE = "/editobservationtemplate";
export const URL_EDIT_FOLLOWUP_TEMPLATE = "/editfollowuptemplate";
export const URL_ADD_EXEC_TASK_TEMPLATE = "/addexectasktemplate";
export const URL_EDIT_EXEC_TASK_TEMPLATE = "/editexectasktemplate";
export const URL_TASK_TEMPLATE_DETAILS = "/tasktemplatedetails";
export const URL_EXEC_TASK_TEMPLATE_DETAILS = "/exectasktemplatedetails";
export const URL_OBSERVATION_TEMPLATE_DETAILS = "/observationtemplatedetails";
export const URL_OVERALL_TEMPLATES = "/overalltemplates";
export const URL_EDIT_OVERALL_TEMPLATES = "/editoveralltemplates";
export const URL_OVERALL_TEMPLATES_DETAILS = "/overalltemplatesdetails";

export const URL_AUDITEE_COMMENT_TEMPLATES = "/auditeecommenttemplates";
export const URL_EDIT_AUDITEE_COMMENT_TEMPLATES = "/editauditeecommenttemplates";
export const URL_AUDITEE_COMMENT_TEMPLATES_DETAILS = "/auditeecommenttemplatesdetails";
export const URL_FOLLOWUP_TEMPLATE_DETAILS = "/followuptemplatedetails";


/// Report Dashboard URL

export const URL_DASHBOARD_REPORT = "/reportdashboard";

/****************** ROUTE END HERE *******************************/

/****************** TASKS START HERE *******************************/
export const FIELD_NAME_TASK="Task";
export const FIELD_NAME_TASK_DEF_VAL="Task";
export const FIELD_NAME_CONTROL_OBJ="Control Objective";
export const FIELD_NAME_CONTROL_OBJ_DEF_VAL="Control Objective";
export const FIELD_NAME_ACTIVITY="Activity";
export const FIELD_NAME_ACTIVITY_DEF_VAL="Activity/Test Procedure";
export const FIELD_NAME_GUIDANCE="Guidance";
export const FIELD_NAME_GUIDANCE_DEF_VAL="Guidance";
export const FIELD_NAME_STD_OBSERVATION="Standard Observation";
export const FIELD_NAME_STD_OBSERVATION_DEF_VAL="Standard Observation";
export const FIELD_NAME_RISK_IMPACT="Risk/Impact";
export const FIELD_NAME_RISK_IMPACT_DEF_VAL="Risk/Impact";
export const FIELD_NAME_STD_SUGGESTION="Standard Suggestion";
export const FIELD_NAME_STD_SUGGESTION_DEF_VAL="Standard Suggestion";

/****************** TASKS END HERE *******************************/

/****************** EXECUTE START HERE *******************************/
export const ASSIGNMENT_STATUS_ONGOING="Ongoing";
export const ASSIGNMENT_STATUS_COMPLETED="Completed";

/******************EXECUTE END HERE *******************************/

/****************** DRAFT START HERE *******************************/
export const ASSIGNMENT_STATUS_DRAFT="Draft";

/******************DRAFT END HERE *******************************/

/****************** API ERROR CODES *******************************/
export const CODE_ACCESS_TOKEN_INVALID = 19;
export const CODE_ACCESS_TOKEN_EXPIRED = 20;
export const CODE_SUCCESS = 0;
/****************** API ERROR CODES END HERE **********************/

export const ImageBaseUrl = Constants.BASE_URL_API;

/******************** API URL Starts Here **************************/
//Login
export const CheckLogin = Constants.BASE_URL_API+"login";
export const ForgotPassword = Constants.BASE_URL_API+"forgotpassword";
export const Checkforgotpasswordcode = Constants.BASE_URL_API+"checkforgotpasswordcode";
export const Resetpassword = Constants.BASE_URL_API+"resetpassword";
export const ChangePassword = Constants.BASE_URL_API+"changePassword";
export const ChangePasswordFirstTime = Constants.BASE_URL_API+"changepasswordfirsttime";
export const UpdateProfilePic = Constants.BASE_URL_API+"updateprofilepic";
export const SaveUserThemeSettings = Constants.BASE_URL_API+"saveuserthemesettings";
export const ResetUserThemeSettings = Constants.BASE_URL_API+"resetuserthemesettings";
export const Logout = Constants.BASE_URL_API+"logout";
//Users
export const GetUsers = Constants.BASE_URL_API+"getUsers";
export const GetManagerUsers = Constants.BASE_URL_API+"getManagerUsers";
export const GetDeptUsers = Constants.BASE_URL_API+"getdepartmentUsers";
export const UpdateManagerUsersClients = Constants.BASE_URL_API+"updateManagerUsersClients";
export const DeleteManagerUsersClients = Constants.BASE_URL_API+"deleteManagerUsersClients";
export const ActivateUser = Constants.BASE_URL_API+"activateUser";
export const ExtendMonth = Constants.BASE_URL_API+"extendMonth";
export const DeleteUser = Constants.BASE_URL_API+"deleteUser";
export const RegisterUser = Constants.BASE_URL_API+"registerUser";
export const UpdateUser = Constants.BASE_URL_API+"updateUser";
export const GetUserMasterData = Constants.BASE_URL_API+"getUserCreateMasterData";
export const GetUserMenu = Constants.BASE_URL_API+"getusermenus";
export const GetClientSetting = Constants.BASE_URL_API+"getclientsettings";
export const SaveClientSetting = Constants.BASE_URL_API+"saveclientsettings";
export const GetSuperAdminSettings = Constants.BASE_URL_API+"getSuperAdminSettings";
export const UpdateSuperAdminSettings = Constants.BASE_URL_API+"updateSuperAdminSettings";


// Report Dashboard

export const GetReportDashboardData = Constants.BASE_URL_API+"getdashboardfilterdata";
export const GetDashboardData = Constants.BASE_URL_API+"getdashboarddata";
export const CreateCriteria = Constants.BASE_URL_API+"createcriteria";
export const UpdateCriteria = Constants.BASE_URL_API+"updatecriteria";
export const DeleteCriteria = Constants.BASE_URL_API+"deletecriteria";
export const GetAllCriteria = Constants.BASE_URL_API+"getallcriteria";
export const GetMultiLevelFilterData = Constants.BASE_URL_API+"getmultilevelfilterdata";
export const GetAssignmentSummary = Constants.BASE_URL_API+"getassignmentsummary";
export const GetApprovalUsers = Constants.BASE_URL_API+"getapprovalusers";




//Clients
export const GetClients = Constants.BASE_URL_API+"getClients";
export const GetClientCreateMasterData = Constants.BASE_URL_API+"getClientCreateMasterData";
export const GetClientUsers = Constants.BASE_URL_API+"GetClientUsers";
export const GetClientDetails = Constants.BASE_URL_API+"getClientDetails";
export const CreateClient = Constants.BASE_URL_API+"createClient";
export const CreateClientAdmin = Constants.BASE_URL_API+"createClientAdmin";
export const CopyClientData = Constants.BASE_URL_API+"copyadmindata";
export const updateClient = Constants.BASE_URL_API+"updateclient";
export const DeleteClient = Constants.BASE_URL_API+"deleteclient";
export const ActivateClient = Constants.BASE_URL_API+"activateclient";
//Roles
export const GetRoles = Constants.BASE_URL_API+"getroles";
export const DeleteRole = Constants.BASE_URL_API+"deleteRole";
export const ActivateRole = Constants.BASE_URL_API+"activateRole";
export const MasterScreens = Constants.BASE_URL_API+"getRoleCreateMasterData";
export const CreateRoles = Constants.BASE_URL_API+"createrole";
export const UpdateRoles = Constants.BASE_URL_API+"updaterole";
export const GetRoleMatrix = Constants.BASE_URL_API+"getrolematrix";
//Genaral Masters + Department
export const GetDepartments = Constants.BASE_URL_API+"getdepartments";
export const GetGeneralMasters = Constants.BASE_URL_API+"getgeneralmasters";
export const GetGeneralMasterCreateMasterData = Constants.BASE_URL_API+"getgeneralmastersCreateMasterData";
export const DeleteGeneralMasters = Constants.BASE_URL_API+"deletegeneralmasters";
export const ReactivateGeneralMasters = Constants.BASE_URL_API+"activategeneralMasters";
export const EditGeneralMasters = Constants.BASE_URL_API+"updategeneralmasters";
export const CreateGeneralMasters = Constants.BASE_URL_API+"creategeneralmasters";
//Company
export const GetCompanies = Constants.BASE_URL_API+"getcompanies";
export const CreateCompany = Constants.BASE_URL_API+"createcompany";
export const EditCompany = Constants.BASE_URL_API+"updatecompany";
export const DeleteCompany = Constants.BASE_URL_API+"deletecompany";
export const ActivateCompany = Constants.BASE_URL_API+"activatecompany";
export const GetCompanyCreateMasterData = Constants.BASE_URL_API+"getCompanyCreateMasterData";
export const UpdateCompanyLogo = Constants.BASE_URL_API+"updatecompanylogo";
//Units
export const GetUnits = Constants.BASE_URL_API+"getUnits";
export const EditUnit = Constants.BASE_URL_API+"updateunit";
export const CreateUnit = Constants.BASE_URL_API+"createUnit";
export const DeleteUnits = Constants.BASE_URL_API+"deleteunit";
export const ReactivateUnits = Constants.BASE_URL_API+"activateunit";
export const GetUnitCreateMasterData = Constants.BASE_URL_API+"getUnitCreateMasterData";
//Employees
export const GetEmployees = Constants.BASE_URL_API+"getemployees";
export const GetAllEmployees = Constants.BASE_URL_API+"getallemployees";
export const AddAssignmentEmployee = Constants.BASE_URL_API+"addassignmentemployee";
export const EditEmployee = Constants.BASE_URL_API+"updateemployee";
export const CreateEmployee = Constants.BASE_URL_API+"createEmployee";
export const CreateDraftEmployee = Constants.BASE_URL_API+"createDraftEmployee";
export const DeleteEmployee = Constants.BASE_URL_API+"deleteemployee";
export const ReactivateEmployees = Constants.BASE_URL_API+"activateemployee";
export const GetEmployeeCreateMasterData = Constants.BASE_URL_API+"getEmployeeCreateMasterData";
export const AddDraftAssignmentEmployee = Constants.BASE_URL_API+"addDraftAssignmentEmployee";
export const AddDraftEmployeeFunction = Constants.BASE_URL_API+"addDraftEmployeeFunction";
export const GetDraftAssignmentEmployees = Constants.BASE_URL_API+"getDraftAssignmentEmployees";
// Grouping
export const GetGroupingCreateMasterData = Constants.BASE_URL_API+"getunitgroupingcreatemasterdata";
export const GetRootGrouping = Constants.BASE_URL_API+"getrootunitgrouping";
export const AddRootGrouping = Constants.BASE_URL_API+"createrootunitgrouping";
export const GetGroupingSubtree = Constants.BASE_URL_API+"getunitgrouping";
export const AddChildGrouping = Constants.BASE_URL_API+"addChildunitgrouping";
export const UpdateGrouping = Constants.BASE_URL_API+"updateunitgrouping";
export const AddSiblingGrouping = Constants.BASE_URL_API+"addsiblingunitgrouping";
export const MoveGrouping = Constants.BASE_URL_API+"moveunitgrouping";
export const DeleteGrouping = Constants.BASE_URL_API+"deleteunitgrouping";
export const GetAllUnitData = Constants.BASE_URL_API+"getAllUnitData";
export const AddUnitGroupingList = Constants.BASE_URL_API+"addunitgroupinglist";
export const GetAllCompanyData = Constants.BASE_URL_API+"getallcompanydata";
//Other Masters
export const GetOtherMasters = Constants.BASE_URL_API+"getothermasters";
export const GetOtherMastersCreateMasterData = Constants.BASE_URL_API+"getOthermastersCreateMasterData";
export const CreateOtherMasters = Constants.BASE_URL_API+"createothermasters";
export const EditOtherMasters = Constants.BASE_URL_API+"updateothermasters";
export const DeleteOtherMasters = Constants.BASE_URL_API+"deleteOtherMasters";
export const ReactivateOtherMasters = Constants.BASE_URL_API+"activateOtherMasters";
export const SubmitForReviewOtherMasters = Constants.BASE_URL_API+"submitothermastersforreview";
export const updateReviewStatusOtherMasters = Constants.BASE_URL_API+"updateothermastersreviewstatus";
export const ReviewFormDataOtherMasters = Constants.BASE_URL_API+"getothermastersreviewformdata";
export const GetCopyOtherMastersMasterData = Constants.BASE_URL_API+"getCopyOtherMastersMasterData";
export const CopyOtherMasters = Constants.BASE_URL_API+"copyothermasters";
export const DiscardChangeOtherMasters = Constants.BASE_URL_API+"discardchangeothermasters";
//Review
export const GetReviewHistory = Constants.BASE_URL_API+"getreviewhistory";
//Notifications
export const GetUserNotifications = Constants.BASE_URL_API+"getusernotifications";
export const UpdateNewUserNotifications = Constants.BASE_URL_API+"updateNewUserNotifications";
//Functions
export const GetRootFunctions = Constants.BASE_URL_API+"getRootFunctions";
export const GetFunctionsCreateMasterData = Constants.BASE_URL_API+"getFunctionCreateMasterData";
export const GetFunctionsSubtree = Constants.BASE_URL_API+"getFunctions";
export const Movefunction = Constants.BASE_URL_API+"movefunction";
export const AddRootFunction = Constants.BASE_URL_API+"createrootfunction";
export const AddChildFunction = Constants.BASE_URL_API+"addChildFunction";
export const AddSiblingFunction = Constants.BASE_URL_API+"addsiblingfunction";
export const UpdateFunction = Constants.BASE_URL_API+"updatefunction";
export const DeleteFunction = Constants.BASE_URL_API+"deletefunction";
export const ReactivateFunction = Constants.BASE_URL_API+"activatefunction";
export const SubmitForReviewFunctions = Constants.BASE_URL_API+"submitfunctionforreview";
export const updateReviewStatusFunctions = Constants.BASE_URL_API+"updatefunctionreviewstatus";
export const ReviewFormDataFunctions = Constants.BASE_URL_API+"getfunctionreviewformdata";
export const GetCopyRootFunctionMasterData = Constants.BASE_URL_API+"getCopyRootFunctionMasterData";
export const CopyRootFunction = Constants.BASE_URL_API+"copyrootfunction";
export const DiscardChangesFunction = Constants.BASE_URL_API+"discardchangesfunction";
//Industry
export const GetRootIndustries = Constants.BASE_URL_API+"getRootIndustries";
export const GetIndustriesCreateMasterData = Constants.BASE_URL_API+"getIndustryCreateMasterData";
export const GetIndustriesSubtree = Constants.BASE_URL_API+"getindustries";
export const MoveIndustry = Constants.BASE_URL_API+"moveindustry";
export const AddRootIndustry = Constants.BASE_URL_API+"createrootindustry";
export const AddChildIndustry = Constants.BASE_URL_API+"addChildindustry";
export const AddSiblingIndustry = Constants.BASE_URL_API+"addsiblingIndustry";
export const UpdateIndustry = Constants.BASE_URL_API+"updateindustry";
export const DeleteIndustry = Constants.BASE_URL_API+"deleteindustry";
export const ReactivateIndustry = Constants.BASE_URL_API+"activateindustry";
export const SubmitForReviewIndustries = Constants.BASE_URL_API+"submitindustryforreview";
export const updateReviewStatusIndustries = Constants.BASE_URL_API+"updateindustryreviewstatus";
export const ReviewFormDataIndustries = Constants.BASE_URL_API+"getindustryreviewformdata";
export const GetCopyRootIndustryMasterData = Constants.BASE_URL_API+"getCopyRootIndustryMasterData";
export const CopyRootIndustry = Constants.BASE_URL_API+"copyrootindustry";
export const DiscardChangesIndustry = Constants.BASE_URL_API+"discardchangesindustry";
//Process
export const GetRootProcesses = Constants.BASE_URL_API+"getRootProcesses";
export const GetProcessCreateMasterData = Constants.BASE_URL_API+"getProcessCreateMasterData";//DONE
export const GetProcessesSubtree = Constants.BASE_URL_API+"getprocesses";//DONE
export const GetProcessesExceptRoot = Constants.BASE_URL_API+"getprocessesexceptroot";//DONE
export const MoveProcess = Constants.BASE_URL_API+"moveprocess";
export const AddRootProcess = Constants.BASE_URL_API+"createrootprocess";//DONE
export const AddChildProcess = Constants.BASE_URL_API+"addChildprocess";//DONE
export const AddSiblingProcess = Constants.BASE_URL_API+"addsiblingProcess";//DONE
export const UpdateProcess = Constants.BASE_URL_API+"updateprocess";//DONE
export const DeleteProcess = Constants.BASE_URL_API+"deleteprocess";//DONE
export const ReactivateProcess = Constants.BASE_URL_API+"activateindustry";
export const SubmitForReviewProcesses = Constants.BASE_URL_API+"submitprocessforreview";
export const updateReviewStatusProcesses = Constants.BASE_URL_API+"updateprocessreviewstatus";
export const ReviewFormDataProcesses = Constants.BASE_URL_API+"getprocessreviewformdata";
export const LinkProcesses = Constants.BASE_URL_API+"linkChildprocess";
export const GetCopyRootProcessMasterData = Constants.BASE_URL_API+"getCopyRootProcessMasterData";
export const CopyRootProcess = Constants.BASE_URL_API+"copyrootprocess";
export const DiscardChangesProcess = Constants.BASE_URL_API+"discardchangesprocess";
//Document Master
export const GetDocumentMaster = Constants.BASE_URL_API+"getdocuments";
export const GetTaskDocuments = Constants.BASE_URL_API+"gettaskdocuments";
export const GetDocumentMasterCreateMasterData = Constants.BASE_URL_API+"getDocumentCreateMasterData";
export const CreateDocumentMaster = Constants.BASE_URL_API+"createdocument";
export const EditDocumentMaster = Constants.BASE_URL_API+"updatedocument";
export const DeleteDocumentMaster = Constants.BASE_URL_API+"deletedocument";
export const ReactivateDocumentMaster = Constants.BASE_URL_API+"activatedocument";
export const SubmitForReviewDocumentMaster = Constants.BASE_URL_API+"submitdocumentforreview";
export const updateReviewStatusDocumentMaster = Constants.BASE_URL_API+"updatedocumentreviewstatus";
export const ReviewFormDataDocumentMaster = Constants.BASE_URL_API+"getdocumentreviewformdata";
export const GetCopyDocumentMasterData = Constants.BASE_URL_API+"getCopyDocumentMasterData";
export const CopyDocument = Constants.BASE_URL_API+"copydocument";
export const DiscardChangeDocuments = Constants.BASE_URL_API+"discardchangesdocument";
//Document Sample
export const UploadDocumentSample = Constants.BASE_URL_API+"uploaddocumentsample";
export const SaveDocumentSample = Constants.BASE_URL_API+"savedocumentsample";
export const GetDocumentSamples = Constants.BASE_URL_API+"getdocumentsamples";
export const DeleteDocumentSample = Constants.BASE_URL_API+"deletedocumentsample";
//Document ERP
export const GetDocumentERPMaster = Constants.BASE_URL_API+"getdocumentERPs";
export const GetDocumentERPMasterCreateMasterData = Constants.BASE_URL_API+"getERPCreateMasterData";
export const CreateDocumentERPMaster = Constants.BASE_URL_API+"createdocumentERP";
export const EditDocumentERPMaster = Constants.BASE_URL_API+"updatedocumenterp";
export const DeleteDocumentERPMaster = Constants.BASE_URL_API+"deletedocumenterp";
//Document ERP sample
export const UploadDocumentERPSample = Constants.BASE_URL_API+"uploadDocumentERPSample";
export const GetDocumentERPSamples = Constants.BASE_URL_API+"getdocumenterpsamples";
export const SaveDocumentERPSample = Constants.BASE_URL_API+"savedocumenterpsample";
export const DeleteDocumentERPSample = Constants.BASE_URL_API+"deletedocumenterpsample";
//Risk Master
export const GetRiskMasters = Constants.BASE_URL_API+"getrisks";
export const GetRiskDetails = Constants.BASE_URL_API+"getriskdetails";
export const GetAssignRiskMasterData = Constants.BASE_URL_API+"getassignRiskMasterData";
export const AssingRiskToClient = Constants.BASE_URL_API+"assignrisktoclient";
export const SetAsDefault = Constants.BASE_URL_API+"setdefault";
export const GetRiskMastersCreateMasterData = Constants.BASE_URL_API+"getriskcreatemasterdata";
export const CreateRiskMasters = Constants.BASE_URL_API+"createrisk";
export const EditRiskMasters = Constants.BASE_URL_API+"updaterisk";
export const DeleteRiskMasters = Constants.BASE_URL_API+"deleterisk";
export const ReactivateRiskMasters = Constants.BASE_URL_API+"activaterisk";
export const SubmitForReviewRiskMasters = Constants.BASE_URL_API+"submitriskforreview";
export const updateReviewStatusRiskMasters = Constants.BASE_URL_API+"updateriskreviewstatus";
export const ReviewFormDataRiskMasters = Constants.BASE_URL_API+"getriskreviewformdata";
export const GetCopyRiskMasterData = Constants.BASE_URL_API+"getCopyRiskMasterData";
export const CopyRisk = Constants.BASE_URL_API+"copyrisk";
export const DiscardChangesRisk = Constants.BASE_URL_API+"discardchangesrisk";

// Likelihood
export const CreateLikelihood = Constants.BASE_URL_API+"createlikelihood";
export const EditLikelihood = Constants.BASE_URL_API+"updatelikelihood";

//TASKS
export const GetTaskCreateMasterData = Constants.BASE_URL_API+"getTaskCreateMasterData";
export const GetTaskDetails = Constants.BASE_URL_API+"gettaskdetails";
export const UpdateTaskOrder = Constants.BASE_URL_API+"updatetaskorder";
export const GetTasks = Constants.BASE_URL_API+"gettasks";
export const SaveTask = Constants.BASE_URL_API+"savetask";
export const GetTaskForProcess = Constants.BASE_URL_API+"gettasksforprocess";
export const GetTaskForFunction = Constants.BASE_URL_API+"getTasksForFunction";
export const DeleteTask = Constants.BASE_URL_API+"deletetask";
export const ActivateTask = Constants.BASE_URL_API+"activatetask";
export const SubmitTaskForReview = Constants.BASE_URL_API+"submittaskforreview";
export const UpdateTaskReviewStatus = Constants.BASE_URL_API+"updatetaskreviewstatus";
export const ReviewFormDataTasks = Constants.BASE_URL_API+"gettaskreviewformdata";
export const GetProcessDepartment = Constants.BASE_URL_API+"getProcessDepartment";
export const GetUnderReviewTasks = Constants.BASE_URL_API+"getAllUnderReviewTasks";
export const ChangeProcessFortask = Constants.BASE_URL_API+"changeProcessFortask";
export const DiscardChangesTask = Constants.BASE_URL_API+"discardchangestask";
export const GetAssignmentEmployees = Constants.BASE_URL_API+"getassignmentemployees";

//Checklist
export const ChecklistCreateMasterData = Constants.BASE_URL_API+"getcheckListCreateMasterData";
export const GetChecklists = Constants.BASE_URL_API+"getcheckLists";
export const GetChecklistVersions = Constants.BASE_URL_API+"getcheckListversions";
export const CreateChecklist = Constants.BASE_URL_API+"createcheckList";
export const UpdateChecklist = Constants.BASE_URL_API+"updatechecklist";
export const DeleteChecklist = Constants.BASE_URL_API+"deletechecklist";
export const ActivateChecklist = Constants.BASE_URL_API+"activatechecklist";
export const SubmitForReviewChecklist = Constants.BASE_URL_API+"submitcheckListforreview";
export const UpdateChecklistReviewStatus = Constants.BASE_URL_API+"updatechecklistreviewstatus";
export const ReviewFormDataChecklist = Constants.BASE_URL_API+"getchecklistreviewformdata";
export const GetCheckListTasks = Constants.BASE_URL_API+"getcheckListtasks";
export const GetAddTaskChecklistMasterData = Constants.BASE_URL_API+"getaddcheckListTaskMasterData";
export const AddRemoveCheckListTasks = Constants.BASE_URL_API+"addremovechecklisttasks";
export const UpdateChecklistTaskProperties = Constants.BASE_URL_API+"updatechecklisttaskproperties";
export const ChangeChecklist = Constants.BASE_URL_API+"changechecklist";
export const DiscardChangesChecklist = Constants.BASE_URL_API+"discardchangeschecklist";
export const RemoveCheckListTask = Constants.BASE_URL_API+"removeCheckListtask";
//Checklist headings
export const GetChecklistHeadings = Constants.BASE_URL_API+"getcheckListHeadings";
export const UpdateChecklistHeadings = Constants.BASE_URL_API+"updatechecklistheading";
export const CreateChecklistHeadings = Constants.BASE_URL_API+"createchecklistheading";
export const DeleteChecklistHeadings = Constants.BASE_URL_API+"deletechecklistheading";
export const MoveChecklistHeading = Constants.BASE_URL_API+"movechecklistheading";
//Planning - Annual
export const GetAssignmentCreateMasterData = Constants.BASE_URL_API+"getAssignmentCreateMasterData";
export const GetPlannedAssignments = Constants.BASE_URL_API+"getPlannedAssignments";
export const createAssignment = Constants.BASE_URL_API+"createassignment";
export const updatePlannedAssignment = Constants.BASE_URL_API+"updatePlannedAssignment";
export const SubmitAssignmentforReview = Constants.BASE_URL_API+"submitassignmentforreview";
export const updateReviewStatusAssignments = Constants.BASE_URL_API+"updateassignmentreviewstatus";
export const ReviewFormDataAssignment = Constants.BASE_URL_API+"getassignmentreviewformdata";
export const DeleteAssignment = Constants.BASE_URL_API+"deleteassignment";
export const ReactivateAssignment = Constants.BASE_URL_API+"activateassignment";
export const ScheduleAssignment = Constants.BASE_URL_API+"scheduleassignment";
export const DiscardChangeAssignment = Constants.BASE_URL_API+"discardchangesassignment";

//planning - Sceduled 
export const GetScheduledAssignments = Constants.BASE_URL_API+"getscheduledassignments";
export const ScheduleNewAssignment = Constants.BASE_URL_API+"schedulenewassignment";
export const UpdateScheduledAssignment = Constants.BASE_URL_API+"updatescheduledassignment";
export const AssignResourceMasterData = Constants.BASE_URL_API+"getAssignResourceMasterData";
export const AssignResources = Constants.BASE_URL_API+"assignresources";
export const AssignReportResources = Constants.BASE_URL_API+"assignreportresources";
export const GetAssignedResources = Constants.BASE_URL_API+"getassignedresources";
export const GetAssignedReportResources = Constants.BASE_URL_API+"getassignedreportresources";
export const GetAssignTaskMasterData = Constants.BASE_URL_API+"getAssignTaskMasterData";
export const DiscardChangeScheduledAssignment = Constants.BASE_URL_API+"discardchangesassignment";
export const RemoveResource = Constants.BASE_URL_API+"removeresource";
export const RemoveReportResource = Constants.BASE_URL_API+"removereportresource";
export const AddResource = Constants.BASE_URL_API+"addresource";
export const AddReportResource = Constants.BASE_URL_API+"addreportresource";
export const GetPreviousRefNoData = Constants.BASE_URL_API+"getpreviousrefnodata";

//Planning - FollowUp
export const GetFollowUp = Constants.BASE_URL_API+"getfollowupassignments";
export const GetExecFollowUp = Constants.BASE_URL_API+"getExecFollowUpAssignments";
export const SubmitFollowUpForReview = Constants.BASE_URL_API+"submitfollowupforreview";
export const AssignFollowUpResources = Constants.BASE_URL_API+"assignfollowupresources";
export const GetFollowUpAssignedResources = Constants.BASE_URL_API+"getfollowupassignedresources";
export const AddFollowUpResource = Constants.BASE_URL_API+"addfollowupresource";
export const updateFollowUpReviewStatus = Constants.BASE_URL_API+"updatefollowupreviewstatus";
export const GetFollowUpReviewFormData = Constants.BASE_URL_API+"getfollowupreviewformdata";
export const AddFollowUpTasks = Constants.BASE_URL_API+"addfollowuptasks";
export const GetFollowUpDraftTaskTabAssignedTasksAll = Constants.BASE_URL_API+"getFollowupDraftTaskTabAssignedTasksAll";
export const DeleteFollowUpAssignment = Constants.BASE_URL_API+"deletefollowupassignment";
export const DiscardFollowUpChanges = Constants.BASE_URL_API+"discardfollowupchanges";
export const RemoveFollowUpResource = Constants.BASE_URL_API+"removefollowupresource";
export const AddFollowUpObservations = Constants.BASE_URL_API+"addfollowupobservations";
export const GetAssignmentFollowUpObservations = Constants.BASE_URL_API+"getAssignmentFollowUpObservations";
export const ActivateFollowUpAssignment = Constants.BASE_URL_API+"activateFollowUpAssignment";
export const FreezeFollowUpAssignment = Constants.BASE_URL_API+"freezeFollowUpAssignment";

//Execute - FollowUp
export const GetFollowUpTaskTab = Constants.BASE_URL_API+"getFollowUpTasksTab";
export const GetFreezeFollowUpTasks = Constants.BASE_URL_API+"getFreezeFollowUpTasks";
export const GetFollowUpTaskTemplateValues = Constants.BASE_URL_API+"getfollowuptasktemplatevalues";
export const SaveFollowUpTaskTemplateValues = Constants.BASE_URL_API+"saveFollowUpTaskTemplateValues";
export const SaveFollowUpObservationTemplateValues = Constants.BASE_URL_API+"saveFollowUpObservationTemplateValues";
export const MarkFollowUpTaskComplete = Constants.BASE_URL_API+"markFollowUpTaskComplete";
export const MarkFollowUpObservationComplete = Constants.BASE_URL_API+"markFollowUpObservationComplete";
export const SubmitFollowUpTaskForReview = Constants.BASE_URL_API+"submitfollowuptaskforreview";
export const SubmitFollowUpObservationForReview = Constants.BASE_URL_API+"submitFollowUpObservationForReview";
export const UpdateFollowUpTaskReviewStatus = Constants.BASE_URL_API+"updatefollowuptaskreviewstatus";
export const UpdateFollowUpObservationReviewStatus = Constants.BASE_URL_API+"updateFollowUpObservationReviewStatus";
export const GetFollowUpTaskReviewFormData = Constants.BASE_URL_API+"getfollowuptaskreviewformdata";
export const SaveFollowUpRiskLevel = Constants.BASE_URL_API+"savefollowuprisklevel";
export const GetPreviousFollowUpTaskTemplateValues = Constants.BASE_URL_API+"getpreviousfollowuptasktemplatevalues";
export const GetFollowUpDraftReportHeadings = Constants.BASE_URL_API+"getFollowUpDraftReportHeadings";
export const EditFollowUpTaskTemplateValues = Constants.BASE_URL_API+"editFollowUpTaskTemplateValues";
export const UpdateCloseOpenStatus = Constants.BASE_URL_API+"updateCloseOpenStatus";
export const GetFollowUpBackups = Constants.BASE_URL_API+"getFollowUpBackups";
export const UploadFollowUpBackup = Constants.BASE_URL_API+"uploadFollowUpBackup";
export const SaveFollowUpBackupFiles = Constants.BASE_URL_API+"saveFollowUpBackupFiles";
export const DeleteFollowUpBackup = Constants.BASE_URL_API+"deleteFollowUpBackup";

// export const Getduplicatetasksforfunction = Constants.BASE_URL_API+"getduplicatetasksforfunction";
export const AssignTasks = Constants.BASE_URL_API+"assigntasks";
export const GetAssignedTasks = Constants.BASE_URL_API+"getassignedtasks";
export const StartAssignment = Constants.BASE_URL_API+"startassignment";
export const UpdateReviewAssignmentTask = Constants.BASE_URL_API+"updatereviewassignmenttask";
//Execute
export const GetExecuteAssignments = Constants.BASE_URL_API+"getexecuteassignments";
export const GetExecutetasks = Constants.BASE_URL_API+"gettasktabassignedtasks";
export const GetAssignmentExecuteTimeline = Constants.BASE_URL_API+"getAssignmentExecuteTimeline";
export const AddEmployeeFunction = Constants.BASE_URL_API+"addemployeefunction";
export const MarkTaskComplete = Constants.BASE_URL_API+"marktaskcomplete";
export const SaveAssignmentTaskProperties = Constants.BASE_URL_API+"saveassignmenttaskproperties";
export const SaveAssignmentTaskTemplateValues = Constants.BASE_URL_API+"saveAssignmentTaskTemplateValues";
export const UpdateTaskRemark = Constants.BASE_URL_API+"updateTaskRemark";
export const GetTaskRemarks = Constants.BASE_URL_API+"gettaskremarks"; 
export const ReopenTask = Constants.BASE_URL_API+"reopentask";
export const GetDownloadReportList = Constants.BASE_URL_API+"getDownloadReportList";
export const GetClientReport = Constants.BASE_URL_API+"getClientReport";
export const GetReportsTasksAll = Constants.BASE_URL_API+"gettasktabassignedtasksall"; 
export const GetAssignmentTaskAnnexures = Constants.BASE_URL_API+"getassignmenttaskannexures"; 
export const UploadAssignmentTaskAnnexure = Constants.BASE_URL_API+"uploadassignmenttaskannexure"; 
export const DeleteAssignmentTaskAnnexureFile = Constants.BASE_URL_API+"deleteassignmenttaskannexureFile"; 
export const SaveAssignmentTaskAnnexureFiles = Constants.BASE_URL_API+"saveassignmenttaskannexureFiles"; 
export const GetAssignmentTaskBackups = Constants.BASE_URL_API+"getassignmenttaskbackups"; 
export const UploadAssignmentTaskBackup = Constants.BASE_URL_API+"uploadassignmenttaskbackup"; 
export const DeleteAssignmentTaskBackupFile = Constants.BASE_URL_API+"deleteassignmenttaskbackupFile"; 
export const SaveAssignmentTaskBackupFiles = Constants.BASE_URL_API+"saveassignmenttaskbackupFiles"; 
export const AssignRiskToAssignmentTask = Constants.BASE_URL_API+"assignriskToAssignmentTask"; 
export const SaveAssignmentTaskValueAdditions = Constants.BASE_URL_API+"saveAssignmentTaskValueAdditions"; 
export const PreviousAssignmentData = Constants.BASE_URL_API+"previousassignmentdata"; 
export const GetAssignmentLevelMasterData = Constants.BASE_URL_API+"getAssignmentLevelMasterData";
export const SaveAssignmentLevelTemplateValues = Constants.BASE_URL_API+"saveAssignmentLevelTemplateValues";
export const GetDraftAssignmentLevelMasterData = Constants.BASE_URL_API+"getDraftAssignmentLevelMasterData";
export const SaveDraftAssignmentLevelTemplateValues = Constants.BASE_URL_API+"saveDraftAssignmentLevelTemplateValues";
export const GetInitDraftAssignmentLevelMasterData = Constants.BASE_URL_API+"getInitDraftAssignmentLevelMasterData";
export const GetPreviousAssignmentTemplateData = Constants.BASE_URL_API+"getPreviousAssignmentTemplateData";
export const GetAssignmentTaskFunctionLinking = Constants.BASE_URL_API+"getAssignmentTaskFunctionLinking";
export const AddAssignmentTaskFunctionLinking = Constants.BASE_URL_API+"addAssignmentTaskFunctionLinking";
//Observation
export const GetObservations = Constants.BASE_URL_API+"getobservations";
export const GetObservationCreateMasterData = Constants.BASE_URL_API+"getObservationCreateMasterData";
export const SaveObservation = Constants.BASE_URL_API+"saveobservation";
export const GetObservationDetails = Constants.BASE_URL_API+"getobservationdetails";
export const DeleteObservation = Constants.BASE_URL_API+"deleteobservation";
export const ActivateObservation = Constants.BASE_URL_API+"activateobservation";
export const MarkObservationComplete = Constants.BASE_URL_API+"markobservationcomplete";
export const SubmitObservationForReview = Constants.BASE_URL_API+"submitobservationforreview";
export const SubmitAssignmentTaskForReview = Constants.BASE_URL_API+"submitassignmenttaskforreview";
export const ObservationReviewFormdata = Constants.BASE_URL_API+"getobservationreviewformdata";
export const AssignmentTaskReviewFormdata = Constants.BASE_URL_API+"getassignmenttaskreviewformdata";
export const UpdateObservationReviewStatus = Constants.BASE_URL_API+"updateobservationreviewstatus";
export const UpdateAssignmentTaskReviewStatus = Constants.BASE_URL_API+"updateassignmenttaskreviewstatus";
export const GetAssignmentsDetails = Constants.BASE_URL_API+"getAssignmentDetails";
export const UploadAnnexure = Constants.BASE_URL_API+"uploadAnnexure";
export const UploadBackup = Constants.BASE_URL_API+"uploadBackup";
export const GetObservationTasks = Constants.BASE_URL_API+"getobservationtasks";
//Sample Checked
export const GetSampleChecked = Constants.BASE_URL_API+"getsamplechecked";
export const SaveSampleCheckedProperties = Constants.BASE_URL_API+"savesamplecheckedproperties";
export const AddNewSampleChecked = Constants.BASE_URL_API+"addnewsamplechecked";
export const GetSampleCheckedRemarks = Constants.BASE_URL_API+"getsamplecheckedremarks";
export const SaveSampleCheckedRemarks = Constants.BASE_URL_API+"savesamplecheckedremarks";
export const GetSampleCheckedAuditTrail = Constants.BASE_URL_API+"getSampleCheckedAuditTrail";
//Working Papers
export const GetWorkingPapers = Constants.BASE_URL_API+"getworkingpapers";
export const AddNewWorkingPaper = Constants.BASE_URL_API+"addnewworkingpaper";
export const UploadWorkingPaper = Constants.BASE_URL_API+"uploadWorkingPaper";
export const SaveWorkingPaperUpload = Constants.BASE_URL_API+"saveworkingpaperupload";
export const GetWorkingPaperUploads = Constants.BASE_URL_API+"getworkingpaperuploads";
export const Deleteworkingpaperupload = Constants.BASE_URL_API+"deleteworkingpaperupload";
export const Deleteworkingpaper = Constants.BASE_URL_API+"deleteworkingpaper";
export const TagObservation = Constants.BASE_URL_API+"tagobservation";
export const GetTaggedObservations = Constants.BASE_URL_API+"gettaggedobservations";
export const UpdateWorkingPaper = Constants.BASE_URL_API+"updateworkingpaper";
//Timeline
export const UpdateAssignmentExecuteTimeline = Constants.BASE_URL_API+"updateAssignmentExecuteTimeline";

export const GetOngoingReports = Constants.BASE_URL_API+"getongoingreports";
export const Getongoingreportdetails = Constants.BASE_URL_API+"getongoingreportdetails";
export const Getdraftreportdetails = Constants.BASE_URL_API+"getdraftreportdetails";
export const GetReportHeadings = Constants.BASE_URL_API+"getreportheadings";
export const SubmitReportForReview = Constants.BASE_URL_API+"submitreportforreview";
export const GetReportReviewFormData = Constants.BASE_URL_API+"getreportreviewformdata";
export const UpdateReportReviewStatus = Constants.BASE_URL_API+"updatereportreviewstatus";
export const CreateDraft = Constants.BASE_URL_API+"createdraft";
export const FinalizeReport = Constants.BASE_URL_API+"finalizereport";
export const CreateReportHeading = Constants.BASE_URL_API+"addchildreportheading";
export const UpdateReportHeading = Constants.BASE_URL_API+"updatereportheading";
export const DeleteReportHeading = Constants.BASE_URL_API+"deletereportheading";
export const MoveObservation = Constants.BASE_URL_API+"moveobservation";
export const MoveReportHeading = Constants.BASE_URL_API+"movereportheading";
export const Addtext = Constants.BASE_URL_API+"addtext";
export const Updatetext = Constants.BASE_URL_API+"updatetext";
export const GetReportRiskLevels = Constants.BASE_URL_API+"getreportrisklevels";
export const AssignReportRisk = Constants.BASE_URL_API+"assignreportrisk";
export const GetAddResponseMasterData = Constants.BASE_URL_API+"getAddResponseMasterData";
export const AddResponse = Constants.BASE_URL_API+"addresponse";
export const UpdateResponse = Constants.BASE_URL_API+"updateresponse";
export const GetObservationResponses = Constants.BASE_URL_API+"getObservationResponses";
export const UpdateReportObservation = Constants.BASE_URL_API+"updatereportobservation";
export const UpdateOngoingobservation = Constants.BASE_URL_API+"updateOngoingobservation";
export const DownloadReport = Constants.BASE_URL_API+"downloadreport";
export const DoNotReport = Constants.BASE_URL_API+"donotReport";
export const GetDoNotReportObservations = Constants.BASE_URL_API+"getdonotreportobservations";
export const RevertDoNotReport = Constants.BASE_URL_API+"revertdonotreport";
export const GetAssignmentTypeRisk = Constants.BASE_URL_API+"getassignmenttyperiskdata";
export const ChnageRiskReport = Constants.BASE_URL_API+"chnageRiskReport"; 

//Templates
export const GetTemplates = Constants.BASE_URL_API+"getTemplates";
export const UpdateTemplate = Constants.BASE_URL_API+"updateTemplate";
export const CreateTemplate = Constants.BASE_URL_API+"createTemplate";
export const DeleteTemplate = Constants.BASE_URL_API+"deleteTemplate";
export const SubmitTemplateForReview = Constants.BASE_URL_API+"submittemplateforreview";
export const UpdateTemplateReviewStatus = Constants.BASE_URL_API+"updatetemplatereviewstatus";
export const GetTemplateReviewFormData = Constants.BASE_URL_API+"gettemplatereviewformdata";
export const DiscardChangeTemplate = Constants.BASE_URL_API+"discardchangetemplate";
export const CheckTemplateVersionCreation = Constants.BASE_URL_API+"checkTemplateVersionCreation";
export const getOngoingAssignments = Constants.BASE_URL_API+"getOngoingAssignments";
export const getDraftAssignments = Constants.BASE_URL_API+"getDraftAssignments";
export const GetCreateExecuteTemplateMasterData = Constants.BASE_URL_API+"getCreateExecuteTemplateMasterData";

//Settings
export const GetSettings = Constants.BASE_URL_API+"getsettings";
export const GetDeptSettings = Constants.BASE_URL_API+"getdepartmentsettings";
export const UpdateDepartmentSettings = Constants.BASE_URL_API+"updatedepartmentsettings";
export const SaveUserTaskFields = Constants.BASE_URL_API+"saveusertaskfields";
export const SaveUserTaskLabels = Constants.BASE_URL_API+"saveusertasklabels";
export const SaveUserObservationFields = Constants.BASE_URL_API+"saveuserobservationfields";
export const GetAssignmentGroups = Constants.BASE_URL_API+"getassignmentgroups";
export const AddAssignmentGroup = Constants.BASE_URL_API+"addassignmentgroup";
export const UpdateAssignmentGroup = Constants.BASE_URL_API+"updateassignmentgroup";
export const DeleteAssignmentGroup = Constants.BASE_URL_API+"deleteassignmentgroup";
//
export const GetDashboard = Constants.BASE_URL_API+"getdashboard";
export const DepartmentApprovedUser = Constants.BASE_URL_API+"departmentapproveduser";


export const GetCities = Constants.BASE_URL_API+"getcities";
export const GetCountries = Constants.BASE_URL_API+"getcountries";
export const GetStates = Constants.BASE_URL_API+"getstates";
export const CreateCity = Constants.BASE_URL_API+"createcity";
export const UpdateCity = Constants.BASE_URL_API+"updatecity";
export const UpdateState = Constants.BASE_URL_API+"updatestate";
export const UpdateCountry = Constants.BASE_URL_API+"updateCountry";
export const DeleteCity = Constants.BASE_URL_API+"deletecity";


/******************** API URL Ends Here ***************************/


/******************** Draft API URL Starts Here ***************************/

export const GetDraftReports = Constants.BASE_URL_API+"getdraftreports";
export const GetDraftReportHeadings = Constants.BASE_URL_API+"getdraftreportheadings";
export const GetDraftReportDetails = Constants.BASE_URL_API+"getdraftreportdetails"
export const SubmitDraftReportForReview = Constants.BASE_URL_API+"submitdraftreportforreview";
export const GetDraftReportReviewFormData = Constants.BASE_URL_API+"getdraftreportreviewformdata";
export const UpdateDraftReportReviewStatus = Constants.BASE_URL_API+"updatedraftreportreviewstatus";
export const GetDraftReviewHistory = Constants.BASE_URL_API+"getdraftreviewhistory";
export const CreateDraftReportHeading = Constants.BASE_URL_API+"adddraftchildreportheading";
export const UpdateDraftReportHeading = Constants.BASE_URL_API+"updatedraftreportheading";
export const MoveDraftReportHeading = Constants.BASE_URL_API+"movedraftreportheading";
export const DeleteDraftReportHeading = Constants.BASE_URL_API+"deletedraftreportheading";
export const MoveDraftObservation = Constants.BASE_URL_API+"movedraftobservation";
export const SaveDraftAssignmentTaskTemplateValues = Constants.BASE_URL_API+"saveDraftAssignmentTaskTemplateValues";
export const SaveDraftAssignmentTaskProperties = Constants.BASE_URL_API+"savedraftassignmenttaskproperties";
export const GetDraftBusinessRisks = Constants.BASE_URL_API+"getDraftBusinessRisks";
export const AddDrafttext = Constants.BASE_URL_API+"adddrafttext";
export const UpdateDrafttext = Constants.BASE_URL_API+"updatedrafttext";
export const GetDraftObservationResponse = Constants.BASE_URL_API+"getDraftObservationResponses";
export const GetDraftObservationResponses = Constants.BASE_URL_API+"getdraftObservationResponses";
export const GetDraftAddResponseMasterData = Constants.BASE_URL_API+"getdraftAddResponseMasterData";
export const AddDraftResponse = Constants.BASE_URL_API+"adddraftresponse";
export const UpdateDraftResponse = Constants.BASE_URL_API+"updatedraftresponse";
export const DeleteResponseSupportingFile = Constants.BASE_URL_API+"deleteResponseSupportingFile";
export const GetDraftAssignedReportResources = Constants.BASE_URL_API+"getdraftassignedreportresources";
export const GetDraftAssignmentsDetails = Constants.BASE_URL_API+"getdraftreportdetails";
export const GetDraftAssignTaskMasterData = Constants.BASE_URL_API+"getdraftassigntaskmasterdata";
export const GetDraftObservationCreateMasterData = Constants.BASE_URL_API+"getdraftObservationCreateMasterData";
export const GetDraftObservationDetails = Constants.BASE_URL_API+"getdraftobservationdetails";
export const GetDraftReportsTasksAll = Constants.BASE_URL_API+"getdrafttasktabassignedtasksall"; 
export const UpdateDraftReportObservation = Constants.BASE_URL_API+"updatedraftreportobservation";
export const GetDraftExecutetasks = Constants.BASE_URL_API+"getdrafttasktabassignedtasks";
export const DownloadDraftReport = Constants.BASE_URL_API+"downloaddraftreport";
export const GetDraftTaskCreateMasterData = Constants.BASE_URL_API+"getTaskCreateMasterData";  // not done
export const GetDraftTaskDetails = Constants.BASE_URL_API+"getdrafttaskdetails";
export const GetDraftTaskDocuments = Constants.BASE_URL_API+"getdrafttaskdocuments";
export const GetDraftDocumentSamples = Constants.BASE_URL_API+"getdraftdocumentsamples";
export const GetDraftDocumentERPMaster = Constants.BASE_URL_API+"getdraftdocumentERPs";
export const GetDraftDocumentERPSamples = Constants.BASE_URL_API+"getdraftdocumenterpsamples";
export const AddDraftAssignmentTaskFunctionLinking = Constants.BASE_URL_API+"addDraftAssignmentTaskFunctionLinking";

export const GetDraftSampleChecked = Constants.BASE_URL_API+"getdraftsamplechecked";
export const GetDraftSampleCheckedRemarks = Constants.BASE_URL_API+"getdraftsamplecheckedremarks";
export const GetDraftSampleCheckedAuditTrail = Constants.BASE_URL_API+"getdraftSampleCheckedAuditTrail";
export const GetDraftWorkingPapers = Constants.BASE_URL_API+"getdraftworkingpapers";
export const GetDraftWorkingPaperUploads = Constants.BASE_URL_API+"getdraftworkingpaperuploads";
export const GetDraftObservations = Constants.BASE_URL_API+"getdraftobservations";
export const GetDraftTaggedObservations = Constants.BASE_URL_API+"getdrafttaggedobservations";
export const GetCompletedReports = Constants.BASE_URL_API+"getcompletedreports";  // not done is it needed in draft?
export const CreateFollowUp = Constants.BASE_URL_API+"createfollowup";  
export const GetCompletedDownloadReportList = Constants.BASE_URL_API+"getCompletedDownloadReportList";
export const CreateAssignmentCompletedReports = Constants.BASE_URL_API+"createAssignmentCompletedReports";

export const GetDraftTaskRemarks = Constants.BASE_URL_API+"gettaskremarks"; 
export const AssignriskToDraftAssignmentTask = Constants.BASE_URL_API+"assignriskToDraftAssignmentTask"; 
export const SaveDraftAssignmentTaskValueAdditions = Constants.BASE_URL_API+"saveDraftAssignmentTaskValueAdditions"; 
export const GetDraftDoNotReportObservations = Constants.BASE_URL_API+"getdonotreportdraftobservations";
export const DoNotReportDraftObservation = Constants.BASE_URL_API+"donotreportDraftObservation";
export const RevertDoNotReportDraftObservation = Constants.BASE_URL_API+"revertdonotreportdraftobservation";

export const UploadDraftAnnexure = Constants.BASE_URL_API+"uploaddraftannexure";
export const UploadDraftAssignmentTaskAnnexure = Constants.BASE_URL_API+"uploaddraftassignmenttaskannexures";
export const UploadDraftBackup = Constants.BASE_URL_API+"uploadDraftbackup";
export const UploadDraftAssignmentTaskBackup = Constants.BASE_URL_API+"uploaddraftassignmenttaskbackups";
export const SaveDraftAssignmentTaskAnnexures = Constants.BASE_URL_API+"saveDraftassignmenttaskannexures"; 
export const SaveDraftAssignmentTaskBackups = Constants.BASE_URL_API+"savedraftassignmenttaskbackups"; 
export const GetDraftAssignmentTaskAnnexures = Constants.BASE_URL_API+"getDraftassignmenttaskannexures"; 
export const GetDraftAssignmentTaskBackups = Constants.BASE_URL_API+"getdraftassignmenttaskbackups"; 
export const DeleteDraftassignmenttaskannexures = Constants.BASE_URL_API+"deleteDraftassignmenttaskannexures"; 
export const Deletedraftassignmenttaskbackups = Constants.BASE_URL_API+"deletedraftassignmenttaskbackups"; 

export const UploadReport = Constants.BASE_URL_API+"uploadreport";
export const SaveReportUpload = Constants.BASE_URL_API+"savereportupload";
export const GetReportUploads = Constants.BASE_URL_API+"getreportuploads";
export const DeleteReportUpload = Constants.BASE_URL_API+"deletereportupload";
/******************** Draft API URL Ends Here ***************************/


/******************** Init Draft API URL Starts Here ***************************/

export const GetInitDraftReportHeadings = Constants.BASE_URL_API+"getinitdraftreportheadings";
export const GetInitDraftReportDetails = Constants.BASE_URL_API+"getinitdraftreportdetails";
export const GetInitDraftObservationDetails = Constants.BASE_URL_API+"getinitdraftobservationdetails";
export const DownloadInitDraftReport = Constants.BASE_URL_API+"downloadinitdraftreport";
export const GetInitDraftReportsTasksAll = Constants.BASE_URL_API+"getinitDraftTaskTabAssignedTasksAll";
export const GetInitDonotReportDraftObservations = Constants.BASE_URL_API+"getinitdonotreportdraftobservations";

/******************** Init Draft API URL Ends Here ***************************/

/******************** Init Draft API URL Starts Here ***************************/

export const ChartColors = ["#03A9F4", "#00b300", "#4CAF50", "#607D8B", "#FF5722"];

/******************** Init Draft API URL Ends Here ***************************/
