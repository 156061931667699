import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Sidebar from "../../common/Sidebar";
import { DialogSaveButton } from "../../common/Global/globalStyles.style";
import Topbar from "../../common/Topbar";
import { FilterCheckBox } from "../../common/Global/globalStyles.style";
import { getThemeSettingsVariables, getLocalStorageVariables, isEmptyVariable, 
    isEmptyArray } from "../../common/Global/commonFunctions";
import * as Constants from '../../common/Global/constants';
import { toast } from "react-toastify";
import AlertDialog from "../../common/AlertDialog";
const themeSettings = getThemeSettingsVariables();
let userDetails = "";

let superAdminDefaultSettings = {
    cityTransfer: {
        settingColumn: "cityTransfer",
        settingDisplayColumn: "city master Transfer",
        settingColumnEnabled: "Y",
        settingValue: ""
    },
}

class AdminSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            componentDidMountFlag: false,
            formErrors: {},
            showAlertDialogInfo: false,
            alertDialogMessageInfo: "",
        }
        userDetails = getLocalStorageVariables();
    }

    componentDidMount() {
        this.getInitData();
    }

    getInitData = () => {
        this.setState({
            showLoader: true
        });
        
        fetch(Constants.GetSuperAdminSettings, {
            method: "POST",
            mode: 'cors',
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken
            })
        })
        .then(response => { return response.json()})
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                let tempSuperAdminSettings = data.data;
                if (isEmptyArray(Object.keys(tempSuperAdminSettings))) {
                    tempSuperAdminSettings = superAdminDefaultSettings;
                }

                this.setState({
                    componentDidMountFlag: true,
                    showLoader: false,
                    superAdminSettings: tempSuperAdminSettings
                })
            } else {
                toast.error(data.responseMessage);
                this.setState({
                    showLoader: false
                })
            }
        })
    }

    // This function to handle checkbox click events
    handleCheckBoxClick = (e) => {
        let isChecked = e.target.checked;
        const { name } = e.target;

        let superAdminSettingsTemp = this.state.superAdminSettings;
        superAdminSettingsTemp[name]["settingColumnEnabled"] = isChecked?"Y":"N";

        this.setState({
            superAdminSettings: superAdminSettingsTemp,
        });
    }

    // This function to handle input field change events
    handleTaskFieldsChange = (e) => {
        let FormErrorsTemp = this.state.formErrors;
        const { name, value } = e.target;
        let superAdminSettingsTemp = this.state.superAdminSettings;
        superAdminSettingsTemp[name]["settingValue"] = value;

        if (this.state.submitClickedAtleastOnce) {
            FormErrorsTemp[name]["settingColumn"] = "";
        }

        this.setState({
            superAdminSettings: superAdminSettingsTemp,
            formErrors: FormErrorsTemp
        });
    };

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo: false,
            alertDialogMessageInfo: "",
        });
    };

   // This function to handle update the super admin settings
    handleSaveFields = () => {
        let isError = false;
        let formErrTemp = {};

        // Iterate through the superAdminSettings object and check for errors
        Object.values(this.state.superAdminSettings).forEach((item) => {
            if(item.showTextbox === "Y" && isEmptyVariable(item.settingValue)){
                isError = true;
                formErrTemp[item["settingColumn"]] = "Enter date and month."
            }
        });
        
        // If there are errors, update the formErrors state
        if (isError) {
            this.setState({
                formErrors: formErrTemp
            });
        } else {
            // If there are no errors, show the loader and send a POST request to the server
            this.setState({
                showLoader: true,
            });
            fetch(Constants.UpdateSuperAdminSettings, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    updatedValues: JSON.stringify(this.state.superAdminSettings),
                }),
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    // If the response code is successful, show the alert dialog and call the getInitData function
                    this.setState({
                        showLoader: false,
                        showAlertDialogInfo: true,
                        alertDialogMessageInfo: "Settings updated successfully",
                    }, () => {
                        this.getInitData();
                    });
                } else {
                    // If there is an error, show the error message
                    toast.error(data.responseMessage);
                    this.setState({
                        showLoader: false,
                    })
                }
            });
        }
    };

    render() {
        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />
                        
                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />

                                {this.state.showLoader && (
                                    <div class="loader"></div>
                                )}

                                {
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="row custom-row">
                                            <div className="col-md-12 custom-col">
                                                <div className="card-col">
                                                    {
                                                        Object.values(this.state.superAdminSettings).map((item, index, array) => {
                                                            return (
                                                                <div>
                                                                    <div className="row custom-row">
                                                                        <div className="col-md-2 custom-col flex-center-layout">
                                                                            <p style={{marginTop:8}}>{item["settingDisplayColumn"]}</p>
                                                                        </div>
                                                                        <div className="col-md-8 custom-col flex-center-layout">
                                                                            {
                                                                                item.showCheckbox === "Y" &&
                                                                                <FilterCheckBox
                                                                                    themeSettings={themeSettings}
                                                                                    style={{ marginLeft: 0 }}
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name={item["settingColumn"]}
                                                                                        id={item["settingColumn"]}
                                                                                        onChange={this.handleCheckBoxClick}
                                                                                        checked={item.settingColumnEnabled === "Y" ? true : false}
                                                                                    />
                                                                                    <label for={item["settingColumn"]}></label>
                                                                                </FilterCheckBox>
                                                                            }
                                                                                
                                                                            {
                                                                                item.showTextbox === "Y" &&
                                                                                <div>
                                                                                    <input name={item["settingColumn"]} type="text"
                                                                                        value={item["settingValue"]}
                                                                                        onChange={this.handleTaskFieldsChange} />
                                                                                        {
                                                                                            !isEmptyVariable(this.state.formErrors[item["settingColumn"]]) &&
                                                                                            <span className="cm-error">{this.state.formErrors[item["settingColumn"]]}</span>
                                                                                        }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <div className="modal-close-save m-t-15">
                                                        <div className="close-save-col">
                                                            <DialogSaveButton
                                                                themeSettings={themeSettings}
                                                                onClick={this.handleSaveFields}
                                                                type="button"
                                                                className="modal-save m-b-0"
                                                            >
                                                                Save
                                                            </DialogSaveButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type={Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                            themeSettings={themeSettings}
                        />
                    </section>
                </main>
            </Router>
        );
    }
}
export default AdminSettings;
