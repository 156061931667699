import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import RegularDropdown from '../../common/DropdownMenus/RegularDropdown';
import {DialogSaveButton} from '../../common/Global/globalStyles.style';
import { toast } from 'react-toastify';

let userDetails  = getLocalStorageVariables();
const salutationPlaceholderText = "Select";
class AddClientAdminDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            submitClickedAtleastOnce:false,
            
            salutation:salutationPlaceholderText,
            firstName:"",
            middleName:"",
            lastName:"",
            email:"",
            phone:"",
            formErrors:{},
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showAddClientAdminDialog){
            this.setState({
                submitClickedAtleastOnce:false,
                salutation:salutationPlaceholderText,
                firstName:"",
                middleName:"",
                lastName:"",
                email:"",
                phone:"",
                formErrors:{},
            })
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        let FormErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            if(name === "firstName"){
                FormErrorsTemp['firstName'] = isEmptyVariable(value)?"Please enter first name":"";
            }
            else if(name === "lastName"){
                FormErrorsTemp['lastName'] = isEmptyVariable(value)?"Please enter last name":"";
            }
            else if(name === "email"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['email'] = "Please enter email";
                }else{
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if(!pattern.test(value)) {
                        FormErrorsTemp["email"] = "Please enter valid email";
                    }else{
                        FormErrorsTemp["email"] = "";
                    }
                }
            }
            else if(name === "phone"){
                var regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
                if(!isEmptyVariable(value) && !regexp.test(value)){
                    FormErrorsTemp['phone'] = "Please enter valid phone number";
                }else{
                    FormErrorsTemp['phone'] = "";
                }
            }
        }

        this.setState({ 
            [name]: value,
            formErrors:FormErrorsTemp
        });
    }

    salutionDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;
        
        if(this.state.submitClickedAtleastOnce){
            if(isEmptyVariable(item.name) || item.name === salutationPlaceholderText){
                FormErrorsTemp['salutation'] = "Please select salutation";
            }else{
                FormErrorsTemp['salutation'] = "";
            }
        }

        this.setState({
            salutation:item.name,
            formErrors:FormErrorsTemp
        })
    }

    createClientAdmin = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        let isError = false;
        let FormErrorsTemp = {};
        userDetails  = getLocalStorageVariables();

        if(isEmptyVariable(this.state.salutation) || this.state.salutation === salutationPlaceholderText){
            isError = true;
            FormErrorsTemp['salutation'] = "Please select salutation";
        }

        if(isEmptyVariable(this.state.firstName)){
            isError = true;
            FormErrorsTemp['firstName'] = "Please enter first name";
        }

        if(isEmptyVariable(this.state.lastName)){
            isError = true;
            FormErrorsTemp['lastName'] = "Please enter last name";
        }

        if(isEmptyVariable(this.state.email)){
            isError = true;
            FormErrorsTemp['email'] = "Please enter email";
        }

        if(!isEmptyVariable(this.state.email))
        {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!pattern.test(this.state.email)) {
                isError = true;
                FormErrorsTemp["email"] = "Please enter valid email";
            }
        }

        if(!isEmptyVariable(this.state.phone)){
            var regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
            if(!regexp.test(this.state.phone)) {
                isError = true;
                FormErrorsTemp['phone'] = "Please enter valid phone number";
            }
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            
            fetch(Constants.CreateClientAdmin,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    salutation:this.state.salutation,
                    firstName:this.state.firstName,
                    middleName:this.state.middleName,
                    lastName:this.state.lastName,
                    adminEmail:this.state.email,
                    phoneNo:this.state.phone,
                    clientId:this.props.clientId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        firstName:"",
                        lastName:"",
                        email:"",
                        phone:"",
                        password:"",
                        cpassword:"",
                        formErrors:{},
                    },() => {
                        this.props.handleAddClientAdminDialogClose(true);
                    });
                }else{
                    // let formErrTemp = {};
                    // formErrTemp['apiErr'] = data.responseMessage;
                    toast.error(data.responseMessage);
                    this.setState({
                        showLoader:false,
                        // formErrors:formErrTemp,
                    })
                    // setTimeout(function(){
                    //     this.setState({
                    //         formErrors:{},
                    //     });
                    // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                }
            });
        }
    }

    render() {
        return(
            <Modal className="add-clientadmin-dialog custom-dialog" 
            show={this.props.showAddClientAdminDialog} backdrop="static">
                <Modal.Header>
                    <h5>{this.props.selectedClientName+" Client Admin"}</h5>
                    <button 
                        onClick={this.props.handleAddClientAdminDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }
                    <div className="addnew-modal-form">
                        <div className="row custom-row">
                            <div className="col-md-2 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Salutation*</p>
                                    <RegularDropdown 
                                        placeholder={this.state.salutation}
                                        dropdownArr={Constants.SALUTATION_ARR}
                                        labelParam="name"
                                        onDropDownItemClick={this.salutionDropdownClick}
                                        isFocusRequired = {true}
                                        defaultPlaceholderDropDown={salutationPlaceholderText}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['salutation']) &&
                                        <span className="cm-error">{this.state.formErrors['salutation']}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>First Name*</p>
                                    <input type="text" placeholder="Enter First Name" 
                                    name="firstName" value={this.state.firstName} onChange={this.handleChange}/>
                                    {
                                        !isEmptyVariable(this.state.formErrors['firstName']) &&
                                        <span className="cm-error">{this.state.formErrors['firstName']}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-3 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Middle Name</p>
                                    <input type="text" placeholder="Enter First Name" 
                                    name="middleName" value={this.state.middleName} onChange={this.handleChange}/>
                                    {
                                        !isEmptyVariable(this.state.formErrors['middleName']) &&
                                        <span className="cm-error">{this.state.formErrors['middleName']}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-3 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Last Name*</p>
                                    <input type="text" placeholder="Enter Last Name" 
                                    name="lastName" value={this.state.lastName} onChange={this.handleChange}/>
                                    {
                                        !isEmptyVariable(this.state.formErrors['lastName']) &&
                                        <span className="cm-error">{this.state.formErrors['lastName']}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Email*</p>
                                    <input type="text" placeholder="Enter Email"
                                    name="email" value={this.state.email} onChange={this.handleChange}/>
                                    {
                                        !isEmptyVariable(this.state.formErrors['email']) &&
                                        <span className="cm-error">{this.state.formErrors['email']}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Phone</p>
                                    <input type="text" placeholder="Enter Phone"
                                    name="phone" value={this.state.phone} onChange={this.handleChange}/>
                                    {
                                        !isEmptyVariable(this.state.formErrors['phone']) &&
                                        <span className="cm-error">{this.state.formErrors['phone']}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* {
                            !isEmptyVariable(this.state.formErrors['apiErr']) &&
                            <span className="cm-error">{this.state.formErrors['apiErr']}</span>
                        } */}
                    </div>
                    
                    <div className="modal-close-save">
                        <div className="close-save-col">
                            <button onClick={this.props.handleAddClientAdminDialogClose} type="button" className="modal-close">Cancel</button>
                            <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.createClientAdmin} type="button">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default AddClientAdminDialog;