import React, { Component } from 'react'
import * as Constants from "../../../common/Global/constants";
import Sidebar from "../../../common/SidebarCollapse";
import Topbar from "../../../common/Topbar";
import MasterMenuTabs from "./topmenu";
import { BrowserRouter as Router } from "react-router-dom";
import {getLocalStorageVariables,isEmptyVariable,getThemeSettingsVariables,truncateString,sortTableTwoStates,
    SplitJoinString,isEmptyArray,GetOrderedMasterTabMenuExecute} from '../../../common/Global/commonFunctions';
import TopmenuReports from "../../../common/TopmenuReports";
import Pagination from '../../../common/Global/pagination';

import SelectAuditeeDialog from "../../Execute/auditee/selectAuditeeDialog"
import AssignmentAuditeeFunctionDialog from "../../Execute/auditee/assignmentAuditeeFunctionDialog";
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import { AddNewButtonLayout, SearchSpan } from '../../../common/Global/globalStyles.style';
import { matchPath,withRouter } from 'react-router-dom';
import AddEmployeeDialog from '../../Companies/addemployeedialog';
import EmployeeDetailsDialog from '../../Execute/auditee/employeeDetailDialog';

const sortFirstName = "firstName";
const sortDesignation = "designation";
const sortUnitName = "unitName";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
export default class ongoingAssignmentEmployee extends Component {
    constructor(props)
    {
        super(props);

        this.state ={
            assignmentId: this.props.match.params.id,
            // matchPath(this.props.history.location.pathname, { path: "/ongoingassignmentemployee" })
            isDraftOnward: matchPath(this.props.history.location.pathname, { path: ["/draftassignmentemployee","/finalassignmentemployee","/initdraftassignmentemployee"]}),
            isInitDraftOnward: matchPath(this.props.history.location.pathname, { path: ["/initdraftassignmentemployee"]}),
            showLoader: false,
            componentDidMountFlag: false,
            searchKey:"",
            currentPageNo: 1,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            menus:{},

            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:sortFirstName,
            sortDir:"asc",
            status:"Active",
            parentEmpFuncStr: "", 
            assignmentDetails:{},

            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            functionsArr:"",
            
            //Add Employee
            showAddEmployeeDialog:false,
            showAssignFunc: false,
            showEmpDetailsDialog:false,
            selectedItem:"",
            isEdit:false,

            showAddNewEmployeeDialog:false,
            departmentArr:[],
            designationArr:[],
            selectedEmpDetails:""
        }

    }

    componentDidMount(){
        this.getInitData()
        // fetch(Constants.GetAssignmentsDetails,{
        //         method:"POST",
        //         mode:"cors",
        //         body: new URLSearchParams(
        //         {
        //             email:userDetails.email,
        //             accessToken:userDetails.accessToken,
        //             assignmentId:this.state.assignmentId
        //         })
        //     }).then(response => { return response.json(); } ).then(data=>{
        //         if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
        //             data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
        //             localStorage.clear();
        //             window.location="/";
        //         }else if(data.responseCode === Constants.CODE_SUCCESS){
        //             let assignmentDetails =data.data.assignmentDetails

        //             this.setState({
        //                 assignmentDetails:assignmentDetails
        //             })
        //             this.getInitData(data.data.assignmentDetails);
        //         }
        //     })
    }

    getInitData = () => {

        // let companyId = assignmentDetails.companyId; 

        let  assEmpUrl = ''
        if(this.state.isDraftOnward){
            assEmpUrl = Constants.GetDraftAssignmentEmployees
        }
        else{
            assEmpUrl = Constants.GetAssignmentEmployees
        }
        this.setState({
            showLoader:true,
        });

        let params ={
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            assignmentId: this.state.assignmentId,
            search:this.state.searchKey,
            resultsize:this.state.resultSize,
            status:this.state.status,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
        }
        
        if(this.state.isInitDraftOnward)
        { params.isInitDraftOnward = "Y"};
        Promise.all([
            fetch(Constants.GetEmployeeCreateMasterData,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId: this.state.assignmentId
                })
            }),
            fetch(assEmpUrl,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(params)
            }),
            fetch(Constants.GetUserMenu, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                }),
            }),
        ])
        .then(([masterRes,employeeRes,menuRes]) => {
            return Promise.all([masterRes.json(),employeeRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,employeeRes,menuRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;

            let functionsArr = [];
            let designationArr = [];
            let assignmentDetails ={};

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                functionsArr = masterRes.data.functions;
                designationArr = masterRes.data.designations;
            }else{
                functionsArr = "";
            }

            if(employeeRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                employeeRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(employeeRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = employeeRes.data.result;
                totalCountTemp = employeeRes.data.count;
                assignmentDetails = employeeRes.data.assignmentData;
            }else{
                datalistTemp = [];
                totalCountTemp = 0;
            }

            let menusTemp={};
            let companyTemp={};
    

            if (
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                menusTemp = menuRes.data.menus;
                companyTemp = menuRes.data.company;
            } else {
                menusTemp = {};
                
            }
            let menusTemp2 = GetOrderedMasterTabMenuExecute(
                menusTemp,
                // assignmentDetails.observationTemplateId
            );

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,
                functionsArr:functionsArr,
                datalist:datalistTemp,
                totalCount:totalCountTemp,
                menus: menusTemp2,
                company: companyTemp,
                assignmentDetails: assignmentDetails,
                designationArr:designationArr
            })
        });
    } 

    updateParentEmpFuncStr = (empFuncStr) => {
        this.setState({ parentEmpFuncStr: empFuncStr });
    };
    
    handleAddEmployeeDialogClose = (reloadFlag) => {
        this.setState({
            selectedItem:"",
            showAddEmployeeDialog:false,
        },()=>{
            if(reloadFlag === true){
                this.getEmployeesList();
            }
        })
    }

    handleAssignFuncDialogClose = () => {
        this.setState({
            selectedItem:"",
            showAssignFunc:false,
        },()=>{
                this.getEmployeesList();
        })
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTableTwoStates(sortColumn,this.state.sort,this.state.sortDir);
        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getEmployeesList();
        })
    }

    getEmployeesList = () => {
        let  assEmpUrl = ''
        if(this.state.isDraftOnward){
            assEmpUrl = Constants.GetDraftAssignmentEmployees
        }
        else{
            assEmpUrl = Constants.GetAssignmentEmployees
        }
        this.setState({
            showLoader:true,
        });

        let params ={
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            assignmentId: this.state.assignmentId,
            search:this.state.searchKey,
            resultsize:this.state.resultSize,
            status:this.state.status,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
        }
        
        if(this.state.isInitDraftOnward)
        { params.isInitDraftOnward = "Y"};

        fetch(assEmpUrl,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(params)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    searchKey:this.state.searchKey,
                })
            }else{
                this.setState({
                    datalist:[],
                    totalCount:0,
                    showLoader:false,
                    componentDidMountFlag:true,
                    searchKey:this.state.searchKey,
                })
            }
        });
    }

    handleEmpDetailsDialogShow = (item) => {
        this.setState({
            showEmpDetailsDialog:true,
            selectedEmpDetails:item,
        });
    }

    handleEmpDetailsDialogClose = () => {
        this.setState({
            showEmpDetailsDialog:false,
            selectedEmpDetails:""
        })
    }
  
    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            
            this.setState({
                showLoader:true,
            });

            let assEmpUrl =''

            if(this.state.isDraftOnward){
                assEmpUrl = Constants.GetDraftAssignmentEmployees
            }
            else{
                assEmpUrl = Constants.GetAssignmentEmployees
            }
            this.setState({
                showLoader:true,
            });
    
            let params ={
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                assignmentId: this.state.assignmentId,
                search:this.state.searchKey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
            }
            
            if(this.state.isInitDraftOnward)
            { params.isInitDraftOnward = "Y"};
            
            fetch(assEmpUrl,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(params)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        searchKey:this.state.searchKey,
                        currentPageNo:page,
                    })
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        componentDidMountFlag:true,
                        searchKey:this.state.searchKey,
                    })
                }
            });
        }
    }
    
    onSearchIconClick = () => {
        this.getEmployeesList();
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.getEmployeesList();
        }
    }
   
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getEmployeesList();
        })
    }

    selectAuditeeClick = (event) => {
        event.preventDefault();
        this.setState({
            showAddEmployeeDialog:true,
            isEdit:false,
            selectedItem:""
        })
    }
    
    assignFunctionClick = (event) => {
        event.preventDefault();
        this.setState({
            showAssignFunc:true,
            isEdit:false,
            selectedItem:""
        })
    }

    handleBackBtnClickFromAssignmentEmployee = () =>{
        if(matchPath(this.props.history.location.pathname, { path: "/ongoingassignmentemployee" })){
            this.props.history.replace(Constants.URL_ONGOING_OBSERVATIONS+ "/" + this.state.assignmentId);
        }else if(matchPath(this.props.history.location.pathname, { path: "/draftassignmentemployee" })){
            this.props.history.replace(Constants.URL_DRAFT_OBSERVATIONS+ "/" + this.state.assignmentId);
        }else if(matchPath(this.props.history.location.pathname, { path: "/finalassignmentemployee" })){
            this.props.history.replace(Constants.URL_FINAL_OBSERVATIONS+ "/" + this.state.assignmentId);
        } else if(matchPath(this.props.history.location.pathname, { path: "/initdraftassignmentemployee" })){
            this.props.history.replace(Constants.URL_INITIAL_DRAFT_REPORT+ "/" + this.state.assignmentId);
        }
    }

    addNewEmployeeOnClick = () =>{
        this.setState({
            showAddNewEmployeeDialog:true
        })
    }

    closeAddEmpDilogBox = ()=>{
        this.setState({
            showAddNewEmployeeDialog:false
        },()=>{
            this.getEmployeesList();
        })
    }
   
    
  render() {
    return (
      <Router>
        <main className="main-dashboard">
            <section className="dashboard-bg">
                <Sidebar />
                <div className="dashboard-right-bg-collpase">
                    <div className="dashboard-wrapper">
                        <Topbar />
                        <div className="d-flex align-items-center">
                            <div className="flex-fill">
                                <MasterMenuTabs
                                    assignmentId={this.state.assignmentId}
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}

                                />  
                            </div>
                            <div>
                                <TopmenuReports
                                    assignmentId={this.state.assignmentId}
                                    userDetails ={userDetails}
                                    themeSettings={themeSettings}
                                    company={this.state.company}
                                    isDraftOnward={this.state.isDraftOnward}
                                />
							</div>
                        </div>
                        {this.state.showLoader && (
                            <div class="loader"></div>
						)}
                        <div className="search-addnew-bg">
                            <div className="search-addnew">
                                {/* <div className="flex-center-layout secondary-top-bar" style={{marginTop:0}}>
                                    <div className="back-btn-layout" onClick={this.handleBackBtnClickFromSampleChecked}>
                                        <span class="material-icons-outlined">arrow_back_ios</span>
                                    </div>
                                    <h6>Auditee</h6> */}
                                    
                                        <div className="search-filter-layout">
                                            <div className="back-btn-layout" onClick={this.handleBackBtnClickFromAssignmentEmployee}>
                                        <span class="material-icons-outlined">arrow_back_ios</span>
                                    </div>
                                    <h6 className='mt-2'>Auditee</h6>
                                            <FilterDropDown
                                                placeholder={this.state.resultSizePlaceholder}
                                                dropdownArr={this.state.resultSizeArr}
                                                onDropDownItemClick={this.resultSizeDropdownClick}
                                                themeSettings={themeSettings}
                                                mleft={20}
                                            />
                                            <div className="search-col m-l-3">
                                                <input 
                                                    name="searchKey" 
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    placeholder="Search" 
                                                    onKeyPress={this.onEnterBtnPress}
                                                    value={this.state.searchKey} />
                                                <SearchSpan
                                                themeSettings={themeSettings} 
                                                onClick = {this.onSearchIconClick} 
                                                className="material-icons"> search </SearchSpan>
                                            </div>
                                           
                                        </div>
                                    {!matchPath(this.props.history.location.pathname, { path: ["/finalassignmentemployee","/initdraftassignmentemployee"]}) &&
                                     <div className="flex-center-layout">
                                     {matchPath(this.props.history.location.pathname, { path: ["/ongoingassignmentemployee",'/draftassignmentemployee']}) &&
                                        <AddNewButtonLayout themeSettings={themeSettings} style={{marginRight:"10px"}}>
                                            <a  onClick={this.addNewEmployeeOnClick}>
                                                <span className="material-icons"></span>
                                                <p>Add Employee</p>
                                            </a>
                                        </AddNewButtonLayout>
                                     }
                                     <AddNewButtonLayout themeSettings={themeSettings} style={{marginRight:"10px"}}>
                                         <a onClick={this.assignFunctionClick}>
                                             <span className="material-icons"></span>
                                             <p>Assign Function</p>
                                         </a>
                                     </AddNewButtonLayout>
                                     <AddNewButtonLayout themeSettings={themeSettings}>
                                         <a  onClick={this.selectAuditeeClick}>
                                             <span className="material-icons"></span>
                                             <p>Select Assignment Auditee</p>
                                         </a>
                                     </AddNewButtonLayout>
                                 </div> 
                                     }

                                {/* </div> */}
                            </div>
                        </div>
                        {
                                !(isEmptyArray(this.state.datalist)) &&
                                <div className="common-tab-bg">
                                    <div className="common-table" style={{width:"100%"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                <th className="text-center" width={60}>No.</th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortFirstName)} width="20%">
                                                    <div className="sort-header">Name
                                                        <span className={(this.state.sort === sortFirstName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDesignation)} width="20%">
                                                    <div className="sort-header">Designation
                                                        <span className={(this.state.sort === sortDesignation)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer" width="20%">
                                                    <div className="sort-header">Units
                                                        <span className={(this.state.sort === sortUnitName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer">
                                                    <div className="sort-header">Function </div>
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !isEmptyArray(this.state.datalist) && 
                                                    this.state.datalist.map((item,idx) => {
                                                        return <tr key={idx + "_" + item.employeeId} onClick={this.handleEmpDetailsDialogShow.bind(this,item)}>
                                                            <td className="text-center" key={idx + "_" + item.employeeId}>{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                            <td>{truncateString(SplitJoinString(item.name,",",":-:",", "),Constants.DEPT_STRING_LENGTH)}</td>
                                                            <td>{truncateString(SplitJoinString(item.designation,",",":-:",", "),Constants.DEPT_STRING_LENGTH)}</td>
                                                            <td>{isEmptyArray(item.units)?"":item.units.map(e=>e.unitName).join(", ") ||''}</td>
                                                            <td>{!isEmptyArray(item.scope)?item.scope.map(scope => scope.functionName).join(", "): ""}</td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                
                                    </div>
                                </div>
                            }
                            {
                                this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && 
                                <div class="no-items-layout">
                                    <div class="no-items-card">
                                        <h6>
                                        {
                                            isEmptyVariable(this.state.searchKey)?
                                            Constants.NO_RECORDS_WARNING
                                            :
                                            Constants.EMPTY_SEARCH_WARNING
                                        }
                                        </h6>
                                    </div>
                                </div>
                            }
                            <div className="pagination-layout">
                                <div className="row custom-row">
                                    <div className="col-md-9 custom-col">
                                    {
                                        (this.state.totalCount > this.state.datalist.length) && 
                                        <Pagination 
                                            totalLength ={this.state.totalCount} 
                                            items={this.state.datalist} 
                                            onChangePage={this.onChangePage} 
                                            pageSize={this.state.resultSize}
                                            currentPageNo = {this.state.currentPageNo}
                                            initialPage={this.state.currentPageNo}
                                            themeSettings={themeSettings} />
                                    }
                                    </div>
                                    <div className="col-md-3 custom-col">
                                        <p>{"Total Records: "+this.state.totalCount}</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                {this.state.showAddEmployeeDialog && 
                        <SelectAuditeeDialog
                            showDocumentDialog={this.state.showAddEmployeeDialog}
                            handleSelectDocDialogClose={this.handleAddEmployeeDialogClose}
                            assignmentId={this.state.assignmentId}
                            departmentId={this.state.selectedDepartmentId}
                            getEmployeesList={this.getEmployeesList}
                            assignmentDetails={this.state.assignmentDetails}
                            themeSettings={themeSettings}
                            isDraftOnward ={this.state.isDraftOnward}
                            
                        /> 
                    }

                    <AddEmployeeDialog 
                        isEdit = {this.state.isEdit}
                        selectedItem = {this.state.selectedItem}
                        showAddEmployeeDialog = {this.state.showAddNewEmployeeDialog}
                        handleAddEmployeeDialogClose = {this.closeAddEmpDilogBox}
                        departmentArr ={this.state.departmentArr}
                        designationArr ={this.state.designationArr}
                        themeSettings={themeSettings}
                        isShowUnit={"N"}
                        assignmentUnitId={[this.state.assignmentDetails.unitId]}
                        assignmentId ={this.state.assignmentId}
                        isDraftOnward ={this.state.isDraftOnward}
                    />

                    {this.state.showAssignFunc && 
                        <AssignmentAuditeeFunctionDialog
                            showAssignFuncDialog={this.state.showAssignFunc}
                            handleAssignFuncDialogClose={this.handleAssignFuncDialogClose}
                            assignmentId={this.state.assignmentId}
                            unitId={this.state.unitId}
                            dataList= {this.state.datalist}
                            updateParentEmpFuncStr={this.updateParentEmpFuncStr}
                            themeSettings={themeSettings}
                            isDraftOnward ={this.state.isDraftOnward}
                        />
                    }
                    <EmployeeDetailsDialog
                        employeeData={this.state.selectedEmpDetails}
                        showEmpDetailsDialog={this.state.showEmpDetailsDialog}
                        handleEmpDetailsDialogClose = {this.handleEmpDetailsDialogClose}
                    />
            </section>
        </main>

      </Router>
    )
  }
}
