import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,DialogSaveButton,TableRadioButton} from '../../../common/Global/globalStyles.style';
import Sidebar from '../../../common/Sidebar';
import Topbar from '../../../common/Topbar';
import MasterMenuTabs from '../Common/topmenu';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../../common/AlertDialog';
import Pagination from '../../../common/Global/pagination';
import CreateAnnualPlan from "./createAnnualPlan"
import ScheduleAssignment from "./scheduleAssignment"
import UpdateReviewStatusDialog from './assignmentupdatereviewstatusdialog'
import ReviewHistoryDialog from '../../../common/reviewhistorydialog'
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable,sortTable,truncateString,
    GetOrderedMasterTabMenuPlanning,SplitJoinString,DoubleSplitString,getStringWithSpaceAfterComma,
    isEmptyArray,getStatusSpan} from '../../../common/Global/commonFunctions';
import {getDropdownActionArrayPlanned} from '../../../common/Global/reviewFunctions';
import {Modal} from 'react-bootstrap';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import moment from "moment";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import Excel from 'exceljs';
import { getPlannedAssignmentsInExcel } from "../Common/assignmentsExcelFunctions";
import { saveAs } from "file-saver";
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
const sortPlanDate = "a.planDate";
const sortName = "c.companyName";
const sortDescription = "description";
const sortArea = "a.plannedAssignmentArea";
const sortunitNameCityList = "unitNameCityList";
const sortunitAddressList ="unitAddressList"
const sortunitCityList ="unitCityList";

const companyDefaultPlaceholder = "Select Client";
const unitDefaultPlaceholder = "Select Units";
const departmentDropdownDefaultValue = "Select Department";

const assignmentTypeDefaultPlaceholder = "Select Assignment Type";
const departmentFilterDropdownDefaultValue = "All";

const activeStatus="Reviewed";
const activeStatusLabel="Active";
const inActiveStatus="Inactive";
const underReviewStatus="Under Review";

const requestChangeCheckText="Request Change";

class OtherMasters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isApiCallCompleted:false,
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",
            startMonth:"",
            startYear:"",
            status:activeStatus,
            deletePermission:"N",
            actionType:"",

            statusArr:[
                {
                    label:activeStatus,
                    displayLabel:activeStatusLabel,
                    isSelected:true
                },
                {
                    label:underReviewStatus,
                    displayLabel:underReviewStatus,
                    isSelected:false
                },
                {
                    label:inActiveStatus,
                    displayLabel:inActiveStatus,
                    isSelected:false
                },
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            company:{},
            
            planDate:"",
            companies:[],
            selectedCompanyId:"",
            selectedCompanyPlaceholder:companyDefaultPlaceholder,
            
            units:[],
            companyUnits:[],
            selectedUnitIds:[],
            selectedUnitPlaceholder:unitDefaultPlaceholder,
            isUnitsDropdownEnabled:false,

            filterAssignmentStatus:[],
            filterSelectedAssignmentStatus:"Planned",
            assignmentType:[],
            assignmentTypeFiltered:[],
            selectedAssignmentTypeId:"",
            selectedAssignmentTypePlaceholder:assignmentTypeDefaultPlaceholder,

            departmentArr:[],
            selectedDepartmentName:departmentDropdownDefaultValue,
            selectedDepartmentId:"",
            assignmentArea:"",

            valueAdditions:[],
            risks:[],

            //Functions
            showSelectFunctionDialog:false,
            selectedFunctionObjArr:[],
            //Processes
            showSelectProcessDialog:false,
            selectedProcessObjArr:[],

            // formErrors:{},
            submitClickedAtleastOnce:false,

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            deleteReactivateMasterType:"",
            reviewLevel:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            showBtnLoader:false,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,

            showDetailsDialog:false,
            selectedItem:"",
            selectedId:"",

            planFromDate:"",
            planToDate:"",
            startMonth:"",
            startYear:"",
            isCustomDate:false,
        }
        this.masterTypeFilterArr = [];
    }

    componentDidMount(){
        this.getInitData();
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getPlannedAssignmentList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getPlannedAssignmentList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag === true){
                this.getPlannedAssignmentList();
            }
        });
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
        });
    }

    handleCreateDialogClose = (reloadFlag) =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            selectedId:"",
        },()=>{
            if(reloadFlag === true){
                this.getPlannedAssignmentList();
            }
        });
    }

    handleScheduleDialogClose = (reloadFlag) =>{
        this.setState({
            showScheduleDialog:false,
            selectedId:"",
        },()=>{
            if(reloadFlag === true){
                this.getPlannedAssignmentList();
            }
        });
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    onDateFromChange = (date, dateString) => {
		let planFromDate = "";
		if (!isEmptyVariable(date)) {
			planFromDate = date.format("YYYY-MM-DD");
        }
        if(this.state.planToDate < planFromDate && !isEmptyVariable(this.state.planToDate)){
            toast.warning("From Date should not exceed To Date");
        }else{
            this.setState({
                planFromDate:planFromDate,
            },()=>{
                if(!isEmptyVariable(this.state.planFromDate) &&
                !isEmptyVariable(this.state.planToDate)){
                    this.getPlannedAssignmentList();
                }else if(isEmptyVariable(this.state.planFromDate) &&
                isEmptyVariable(this.state.planToDate)){
                    this.getPlannedAssignmentList();
                }
            })
        }
    };
    onDateToChange = (date, dateString) => {
		let planToDate = "";
		if (!isEmptyVariable(date)) {
			planToDate = date.format("YYYY-MM-DD");
        }
        if(planToDate < this.state.planFromDate && !isEmptyVariable(planToDate)){
            toast.warning("From Date should not exceed To Date");
        }else{
            this.setState({
                planToDate:planToDate,
            },()=>{
                if(!isEmptyVariable(this.state.planFromDate) &&
                !isEmptyVariable(this.state.planToDate)){
                    this.getPlannedAssignmentList();
                }else if(isEmptyVariable(this.state.planFromDate) &&
                isEmptyVariable(this.state.planToDate)){
                    this.getPlannedAssignmentList();
                }
            })
        }
    };

    //TODO
    showCreateDialog = () => {
        this.setState({
            showCreateDialog:true,
            isEdit:false,
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.displayLabel;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getPlannedAssignmentList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getPlannedAssignmentList();
        })
    }

    FilterAssignmentStatusSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.filterAssignmentStatus;

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            resultSizeArr:tempArr,
            filterSelectedAssignmentStatus:item.assignmentStatus,
            isApiCallCompleted:false,
        },() => {
            this.getPlannedAssignmentList();
        })
    }

    deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let id = "";
        let filterDeptArr = this.state.filterDeptArr;

        for(const [i,value] of filterDeptArr.entries()){
            if(i === selectedIndex){
                filterDeptArr[i].isSelected = true;
                placeholder = value.name;
                id = value.departmentId;
            }else{
                filterDeptArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo: 1,
            selectedFilterDepartmentName:placeholder,
            selectedFilterDepartmentId:id
        },() => {
            this.getPlannedAssignmentList();
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Assignment",
                proceedBtnLabel:"Delete",
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete "+dropdownItem.companyName+" assignment?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Activate Master",
                proceedBtnLabel:"Reactivate",
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate "+dropdownItem.companyName+" assignment?"
            })
        }else if(item.label === "Edit"){
            this.setState({
                showCreateDialog:true,
                isEdit:true,
                selectedItem:dropdownItem,
            });
        }else if(item.label === "Submit For Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Submit For Review",
                proceedBtnLabel:"Submit",
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to submit "+dropdownItem.companyName+" assignment for review?"
            })
        }else if(item.label === "Start Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+dropdownItem.companyName+" assignment?"
            })
        }else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.assignmentId,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.assignmentId,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Review Remarks"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Review Remarks",
                proceedBtnLabel:"Okay",
                showAlertDialog:true,
                alertDialogMessage:'\"'+dropdownItem.lastReviewRemark+'\"'
            })
        }else if(item.label === "Discard Changes"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Discard Changes",
                proceedBtnLabel:"Discard",
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to discard changes of "+dropdownItem.companyName+" assignment?",
                actionType: dropdownItem.previousStatus === "Updated"?"Update":(dropdownItem.previousStatus === "Deleted"?"Delete":"")
            })
        }else if(item.label === "Schedule Assignment"){
            this.setState({
                showScheduleDialog:true,
                selectedItem:dropdownItem
            })
        }
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getPlannedAssignmentList();
        })
    }
    nextMonthAssignment = () => {
        let date = moment(this.state.startYear+"-"+this.state.startMonth+"-01").add(1, 'months')
        let newMonth=moment(date).format("MM")
        let newYear=moment(date).format("YYYY")
        this.setState({
            startMonth:newMonth,
            startYear:newYear,
        },()=>{
            this.getPlannedAssignmentList();
        })
    }
    prevMonthAssignment = () => {
        let date = moment(this.state.startYear+"-"+this.state.startMonth+"-01").subtract(1, 'months')
        let newMonth=moment(date).format("MM")
        let newYear=moment(date).format("YYYY")
        this.setState({
            startMonth:newMonth,
            startYear:newYear,
        },()=>{
            this.getPlannedAssignmentList();
        })
    }

    handleDownloadInExcelAssignmentList = (downloadDatalist) => {
        const workSheetName = 'Planned Assignments';
		const workBookName = 'Assignments';
		const workbook = new Excel.Workbook();

        let excelDataList = getPlannedAssignmentsInExcel(downloadDatalist);

        try{
            const fileName = workBookName;
			// creating one worksheet in workbook
			const worksheet = workbook.addWorksheet(workSheetName);

            //add table
            worksheet.addTable(
                {
                    name:"table",
                    ref:`A1`, //starting position of table
                    headerRow: true,
                    style: {
                        // theme: 'TableStyleDark3',
                        showRowStripes: true,
                        showFirstColumn: true
                    },
                    columns:excelDataList.tableCols,
                    rows: excelDataList.tableValue
                }
            );
            //find max string length of column and assign it to column width
			worksheet.columns.forEach((column) => {
				let maxColumnLength = 0;
				column.eachCell({ includeEmpty: true }, (cell) => {
					maxColumnLength = Math.max(
						maxColumnLength,
						10,
						cell.value ? (cell.value.toString().length>75?75:cell.value.toString().length) : 0
					);
				});
				column.width = maxColumnLength + 2;
			});
            // write the content using writeBuffer
			workbook.xlsx.writeBuffer().then((buffer)=>{
				saveAs(new Blob([buffer]), `Assignments.xlsx`);
			});

        }catch(error){
        }finally{

        }
        
    }

    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        let startMonth = moment().startOf('month').format("MM");
        let startYear = moment().startOf('month').format("YYYY");

        Promise.all([
            fetch(Constants.GetAssignmentCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetPlannedAssignments,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    startMonth:startMonth,
                    startYear:startYear
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([masterRes,generalRes,menuRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,generalRes,menuRes]) => {
            let companies = [];
            let units = [];
            let unitsTemp = [];
            let assignmentType = [];
            let datalistTemp = [];
            let totalCountTemp = 0;
            let menusTemp = {};
            let allSettings = [];
            let departments = [];
            let companyTemp = {};
            let deptTemp = [];
            let assignmentStatus = [];
            let valueAdditions = [];
            let risks = [];
            let startDate = "";

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    companies = masterRes.data.companies;
                    unitsTemp = masterRes.data.units;
                    assignmentType = masterRes.data.assignmentType;
                    deptTemp = masterRes.data.departments;
                    assignmentStatus = masterRes.data.assignmentStatus;
                    valueAdditions = masterRes.data.valueAdditions;
                    risks = masterRes.data.risks;
            }else{
            }

            units = unitsTemp.map(item=>{
                item.unitTypeCity = item.unitType+", "+item.city+"";
                return item;
            })

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
                startDate = generalRes.data.startDate;
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
                companyTemp = menuRes.data.company;
                departments = menuRes.data.departments;
                allSettings = menuRes.data.allSettings;
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenuPlanning(menusTemp)
            let deletePermission = "N";

            if(menusTemp["Delete Planned Assignment"] === "ALL"){
                deletePermission = "Y";
            }
            
            const departmentIds = !isEmptyArray(departments) && departments.map(department => department.departmentId);
            const filteredSettings =  !isEmptyArray(allSettings) && allSettings.filter(item => departmentIds.includes(item.departmentId) &&
            item.settingColumn.includes("directScheduled") && item.settingColumnEnabled === "N" );

            //Filter deptTemp data based on directScheduled selection
            const departmentArr = deptTemp.filter((elem) => filteredSettings.find(({ departmentId }) => elem.departmentId === departmentId));

            //if user belongs to single dept then dont show the dropdown
            let selectedDepartmentId = "";
            let selectedDepartmentName = departmentDropdownDefaultValue;
            if(!isEmptyArray(departmentArr) && departmentArr.length===1){
                selectedDepartmentId = departmentArr[0].departmentId;
                selectedDepartmentName = departmentArr[0].name;
            }

            //Filter Dept array
            let filterDeptArr = [];
            let selectedFilterDepartmentId = "-100";
            let selectedFilterDepartmentName = "All";
            if(!isEmptyArray(filteredSettings) && filteredSettings.length===1){

            }else{
                let obj = {
                    departmentId:"-100",
                    name:departmentFilterDropdownDefaultValue,
                    isSelected:true
                }
                filterDeptArr = [...departmentArr];
                filterDeptArr.splice(0,0,obj);
            }

            //Assignment status filter array
            let newAssignmentStatus = assignmentStatus.map(item => {
                if(item.assignmentStatus === "Planned"){
                    item.isSelected = true;
                }
                return item;
            })
            let filterSelectedAssignmentStatus = "Planned"
            newAssignmentStatus.push(newAssignmentStatus.shift())

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,
                isApiCallCompleted:true,
                deletePermission:deletePermission,
                
                valueAdditions:valueAdditions,
                risks:risks,
                companies:companies,
                units:units,
                assignmentType:assignmentType,

                datalist:datalistTemp,
                totalCount:totalCountTemp,
                menus:menusTemp2,
                departmentArr:departmentArr, 
                company:companyTemp,
                allSettings:allSettings,
                departments:departments,
                filterAssignmentStatus:newAssignmentStatus,
                filterSelectedAssignmentStatus:filterSelectedAssignmentStatus,
                filterDeptArr:filterDeptArr,
                selectedFilterDepartmentId:selectedFilterDepartmentId,
                selectedFilterDepartmentName:selectedFilterDepartmentName,

                selectedDepartmentId:selectedDepartmentId,
                selectedDepartmentName:selectedDepartmentName,
                startDate:startDate,
                startMonth:startMonth,
                startYear:startYear
            })
        })
    }

    getPlannedAssignmentList = () => {
        this.setState({
            showLoader:true,
        });

        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:this.state.status,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
            startMonth:this.state.startMonth,
            startYear:this.state.startYear
        }

        if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
            postParam.departmentId = this.state.selectedFilterDepartmentId;
        }

        if(this.state.filterSelectedAssignmentStatus !== "All"){
            postParam.assignmentStatus = this.state.filterSelectedAssignmentStatus
        }

        if(!isEmptyVariable(this.state.planFromDate) &&
        !isEmptyVariable(this.state.planToDate)){
            postParam.startDate = this.state.planFromDate;
            postParam.endDate = this.state.planToDate;
        }

        fetch(Constants.GetPlannedAssignments,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let isCustomDate = false;
                if(!isEmptyVariable(this.state.planFromDate) &&
                !isEmptyVariable(this.state.planToDate)){
                    isCustomDate = true;
                }
                
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                    isCustomDate:isCustomDate,
                    isApiCallCompleted:true,
                });
            }else{
                let isCustomDate = false;
                if(!isEmptyVariable(this.state.planFromDate) &&
                !isEmptyVariable(this.state.planToDate)){
                    isCustomDate = true;
                }
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                    isCustomDate:isCustomDate,
                    isApiCallCompleted:true,

                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            let postParam = {
                email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    startMonth:this.state.startMonth,
                    startYear:this.state.startYear
            }
            if(this.state.filterDeptArr.length > 1 &&
                this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
                postParam.departmentId = this.state.selectedFilterDepartmentId;
            }
    
            if(this.state.filterSelectedAssignmentStatus !== "All"){
                postParam.assignmentStatus = this.state.filterSelectedAssignmentStatus
            }
    
            if(!isEmptyVariable(this.state.planFromDate) &&
            !isEmptyVariable(this.state.planToDate)){
                postParam.startDate = this.state.planFromDate;
                postParam.endDate = this.state.planToDate;
            }
            
            fetch(Constants.GetPlannedAssignments,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    let isCustomDate = false;
                    if(!isEmptyVariable(this.state.planFromDate) &&
                    !isEmptyVariable(this.state.planToDate)){
                        isCustomDate = true;
                    }
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                        isCustomDate:isCustomDate
                    });
                }else{
                    let isCustomDate = false;
                    if(!isEmptyVariable(this.state.planFromDate) &&
                    !isEmptyVariable(this.state.planToDate)){
                        isCustomDate = true;
                    }
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                        isCustomDate:isCustomDate,
                    });
                }
            });
        }
    }

    getAssignmentListForDownload = () => {

        this.setState({
            showLoader:true,
        });
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:500,
            status:this.state.status,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
            startMonth:this.state.startMonth,
            startYear:this.state.startYear
        }
        if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
            postParam.departmentId = this.state.selectedFilterDepartmentId;
        }
        if(this.state.filterSelectedAssignmentStatus !== "All"){
            postParam.assignmentStatus = this.state.filterSelectedAssignmentStatus
        }

        if(!isEmptyVariable(this.state.planFromDate) &&
        !isEmptyVariable(this.state.planToDate)){
            postParam.startDate = this.state.planFromDate;
            postParam.endDate = this.state.planToDate;
        }

        fetch(Constants.GetPlannedAssignments,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    
                    let downloadData = data.data.result;
                    if(!isEmptyArray(downloadData)){
                        this.handleDownloadInExcelAssignmentList(downloadData);
                    }

                    this.setState({
                        showLoader:false,
                        // datalist:data.data.result,
                        // totalCount:data.data.count,
                    });
                }else{
                    
                    // this.setState({
                    //     datalist:[],
                    //     showLoader:false,
                    //     totalCount:0,
                    // });
                }
            });

    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            assignmentId:this.state.deleteReactivateId
        }
        let callApi = true;

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteAssignment;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ReactivateAssignment;
        }else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitAssignmentforReview;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.updateReviewStatusAssignments;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Discard Changes"){
            url = Constants.DiscardChangeAssignment;
            postParam.actionType = this.state.actionType;
        }else if(this.state.operationType === "Review Remarks"){
            callApi = false;
            this.setState({
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
            })
        }

        if(callApi){
            this.setState({
                showBtnLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showBtnLoader:false
                    },() => {
                        this.getPlannedAssignmentList();
                    });
                }else{
                    this.setState({
                        showBtnLoader:false,
                        deleteReactivateId:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar  />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                    allSettings={this.state.allSettings}
                                    departments={this.state.departments}
                                />
                                {
                                    this.state.showLoader &&
                                    <div class="loader"></div>
                                }
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings={themeSettings}
                                                        mleft={0}
                                                    />
                                                    <DatePicker
                                                        onChange={this.onDateFromChange}
                                                        format={"DD/MM/YYYY"}
                                                        placeholder="From Date"
                                                        style={{marginLeft:10}}
                                                        value={!isEmptyVariable(this.state.planFromDate) ? moment(this.state.planFromDate) : ""}
                                                    />

                                                    <DatePicker
                                                        onChange={this.onDateToChange}
                                                        format={"DD/MM/YYYY"}
                                                        style={{marginLeft:10}}
                                                        placeholder="To Date"
                                                        value={!isEmptyVariable(this.state.planToDate) ? moment(this.state.planToDate) : ""}
                                                    />

                                                    <FilterDropDown
                                                        placeholder={this.state.statusPlaceholder}
                                                        dropdownArr={this.state.statusArr}
                                                        onDropDownItemClick={this.statusDropdownClick}
                                                        name={"displayLabel"}
                                                        themeSettings={themeSettings}
                                                    />
                                                    
                                                    <FilterDropDown
                                                        placeholder={this.state.filterSelectedAssignmentStatus}
                                                        dropdownArr={this.state.filterAssignmentStatus}
                                                        name={"assignmentStatus"}
                                                        onDropDownItemClick={this.FilterAssignmentStatusSizeDropdownClick}
                                                        themeSettings={themeSettings}
                                                        dropdownWidth={"120px"}
                                                    />
                                                    {
                                                        this.state.filterDeptArr.length > 0 &&
                                                        <FilterDropDown
                                                            placeholder={this.state.selectedFilterDepartmentName}
                                                            dropdownArr={this.state.filterDeptArr}
                                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                                            name={"name"}
                                                            themeSettings={themeSettings}
                                                            dropdownWidth={"120px"}
                                                        />
                                                    }
                                                </div>
                                                <div className="flex-center-layout">
                                                        <AddNewButtonLayout themeSettings={themeSettings}>
                                                            <a onClick={this.getAssignmentListForDownload} href="javascript:void(0);">
                                                                <span class="material-icons mr-1" style={{fontSize:15}}> download </span>
                                                                <p>Download</p>
                                                            </a>
                                                        </AddNewButtonLayout>
                                                    {
                                                        this.state.menus[Constants.SCREEN_PLAN_ASSIGNMENT_ALL] ==="ALL" && (
                                                            <AddNewButtonLayout themeSettings={themeSettings}  style={{marginLeft:15}}>
                                                                <a onClick={this.showCreateDialog} href="javascript:void(0);">
                                                                    <span className="material-icons mr-1">add_circle</span>
                                                                    <p>Add New</p>
                                                                </a>
                                                            </AddNewButtonLayout>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                                
                                        </div>
                                        {
                                            // this.state.status !== underReviewStatus &&
                                            false &&
                                            <div className="filter-date-text flex-center-layout">
                                                {
                                                    !isEmptyVariable(this.state.planFromDate) &&
                                                    !isEmptyVariable(this.state.planToDate)
                                                    ?
                                                    <p>{moment(this.state.planFromDate).format("DD-MMM-YYYY")+" - "+moment(this.state.planToDate).format("DD-MMM-YYYY")}</p>
                                                    :
                                                    <p>{moment(this.state.startYear+"-"+this.state.startMonth+"-01").format("MMM-YYYY")}</p>
                                                }
                                                {
                                                    this.state.status !== underReviewStatus &&
                                                    <button onClick={this.prevMonthAssignment} href="javascript:void(0);"
                                                    disabled ={this.state.isCustomDate}>
                                                        <span 
                                                        style={{position:"relative",right:"-3px"}}
                                                        className="material-icons">arrow_back_ios</span>
                                                    </button>
                                                }
                                                {
                                                    this.state.status !== underReviewStatus &&
                                                    <button onClick={this.nextMonthAssignment} href="javascript:void(0);"
                                                    disabled ={this.state.isCustomDate}>
                                                        <span className="material-icons">arrow_forward_ios</span>
                                                    </button>
                                                }
                                            </div>
                                        }

                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            <div className="common-tab-bg">
                                                <div className="common-table" style={{width:"100%"}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th  className="c-pointer" onClick={this.sortTableLocal.bind(this,sortPlanDate)}>
                                                                <div className="sort-header">Month
                                                                <span className={(this.state.sort === sortPlanDate)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            {/* {
                                                                this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortLocation)}>
                                                                    <div className="sort-header">Location
                                                                    <span className={(this.state.sort === sortLocation)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                    </div>
                                                                </th>
                                                            } */}
                                                            {
                                                                this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
                                                                    <div className="sort-header">Client
                                                                    <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                    </div>
                                                                </th>
                                                            }

                                                            
                    
                                                            <th className="c-pointer">
                                                                <div className="sort-header" onClick={this.sortTableLocal.bind(this,sortunitNameCityList)}>Unit
                                                                <span className={(this.state.sort === sortunitNameCityList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>

                                                            {  this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                  <th className="c-pointer">
                                                                  <div className="sort-header" onClick={this.sortTableLocal.bind(this,sortunitAddressList)}>Name
                                                                  <span className={(this.state.sort === sortunitAddressList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                  </div>
                                                              </th>
                                                            }
                                                              {  this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                  <th className="c-pointer" >
                                                                  <div className="sort-header" onClick={this.sortTableLocal.bind(this,sortunitCityList)}>City
                                                                  <span className={(this.state.sort === sortunitCityList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                  </div>
                                                              </th>
                                                            }
                                                              <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDescription)}>
                                                                <div className="sort-header">Type
                                                                <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortArea)}>
                                                                <div className="sort-header">Scope
                                                                <span className={(this.state.sort === sortArea)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            {/* <th width={"10%"}>Users Count</th> */}
                                                            {
                                                                this.state.filterSelectedAssignmentStatus==="Planned" &&
                                                                <th className="text-center"  width={"5%"}></th>
                                                            }
                                                            
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !isEmptyArray(this.state.datalist) && 
                                                            this.state.datalist.map((item,idx) => {

                                                                let actionObj = getDropdownActionArrayPlanned(item.allowEdit, item.allowReview, 
                                                                    item.status, item.currentReviewStatus,this.state.deletePermission, item.previousStatus,
                                                                    item.isSchedulable);
                                                                let spanObj = getStatusSpan(item.previousStatus,themeSettings);
                                                                let tempScop = [];
                                                                if(!isEmptyVariable(item.functionList)){
                                                                    tempScop = item.functionList?.split(",").map((func) => func.split(":-:")[0]);
                                                                }else if(!isEmptyVariable(item.processList)){
                                                                    tempScop = item.processList.split(",").map((func) => func.split(":-:")[0]);
                                                                }else if(!isEmptyVariable(item.checkList)){
                                                                    tempScop = item.checkList.split(",").map((func) => func.split(":-:")[0]);
                                                                }
                                                                return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                    <td>
                                                                        {moment(item.planDate).format('MMM - YYYY')}
                                                                        {
                                                                            this.state.status === "Under Review" &&
                                                                            this.state.filterSelectedAssignmentStatus === "Planned" &&
                                                                            <span style={spanObj.spanStyle} className="span-tag">
                                                                                {spanObj.spanText}
                                                                            </span>
                                                                        }  
                                                                    </td>
                                                                    {/* {
                                                                        this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <td>{item.unitCityList}</td>
                                                                    } */}
                                                                    {
                                                                        this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <td>{item.companyName}</td>
                                                                    }
                                                                   
                                                                    <td>
                                                                        {
                                                                            truncateString(String(getStringWithSpaceAfterComma(item.unitNameCityList)).split("(")[0],100)
                                                                        }
                                                                        {/* {
                                                                            truncateString(SplitJoinString(item.unitList,",",":-:",", "),
                                                                            Constants.OTHERMASTERS_STRING_LENGTH)
                                                                        } */}
                                                                    </td>
                                                                    {
                                                                        this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <td>
                                                                             {isEmptyVariable(item.unitAddressList)? "-": String(item.unitAddressList).split(":-:")[0]}
                                                                        </td>
                                                                    }
                                                                      {
                                                                        this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <td>
                                                                             {isEmptyVariable(item.unitCityList)? "-": item.unitCityList}
                                                                        </td>
                                                                    }
                                                                    <td>{item.assignmentTypeShortName}</td>
                                                                    <td>{item.assignmentArea == "CheckList" ?
																		isEmptyArray(tempScop)?"-":(`CheckList: ` + tempScop[0]) :
																		isEmptyArray(tempScop)?"-":tempScop.length==1?tempScop[0]:`${tempScop[0]} + ${tempScop.length-1}`}
                                                                    </td>
                                                                    {
                                                                        this.state.filterSelectedAssignmentStatus==="Planned" &&
                                                                        this.state.isApiCallCompleted &&
                                                                        <td className="text-center">
                                                                            {
                                                                                actionObj?.actionArr && actionObj?.actionArr.length > 0 &&
                                                                                <TableDropDown 
                                                                                    actionArr={actionObj?.actionArr}
                                                                                    onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                                    dropDownId = {item}
                                                                                    dropdownWidth={actionObj.dropdownWidth}
                                                                                    themeSettings={themeSettings}
                                                                                />
                                                                            }
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div class="no-items-layout">
                                                <div class="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings={themeSettings}/>
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                (this.state.operationType === "Review Remarks")
                                ?
                                Constants.ALERT_TYPE_ALERT
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            showLoader={this.state.showBtnLoader}
                            themeSettings={themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterType = {this.state.deleteReactivateMasterType}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        />

                        <ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {"Plan Assignment"}
                            masterDataId = {this.state.deleteReactivateId}
                        />
                        {/* cdsh */}
                        <CreateAnnualPlan
                            showCreateDialog = {this.state.showCreateDialog}
                            handleCreateDialogClose = {this.handleCreateDialogClose}
                            departmentArr = {this.state.departmentArr}
                            companies = {this.state.companies}
                            units = {this.state.units}
                            assignmentType = {this.state.assignmentType}
                            selectedDepartmentName = {this.state.selectedDepartmentName}
                            selectedDepartmentId = {this.state.selectedDepartmentId}
                            themeSettings = {themeSettings}
                            isEdit={this.state.isEdit}
                            selectedItem={this.state.selectedItem}
                            company={this.state.company}
                        />
                        <ScheduleAssignment
                            showCreateDialog = {this.state.showScheduleDialog}
                            handleCreateDialogClose = {this.handleScheduleDialogClose}
                            departmentArr = {this.state.departmentArr}
                            valueAdditions = {this.state.valueAdditions}
                            risks = {this.state.risks}
                            companies = {this.state.companies}
                            units = {this.state.units}
                            assignmentType = {this.state.assignmentType}
                            selectedDepartmentName = {this.state.selectedDepartmentName}
                            selectedDepartmentId = {this.state.selectedDepartmentId}
                            themeSettings = {themeSettings}
                            selectedItem={this.state.selectedItem}
                            company={this.state.company}
                        />
                        <Modal className="custom-dialog" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
                            <Modal.Header>
                                <h5>{moment(this.state.selectedItem.planDate).format('MMM - YYYY')}</h5>
                                <button 
                                    onClick={this.handleDetailsDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div class="modal-body">
                                <div className="details-table-layout">
                                    <div className="row custom-row">
                                        <div className="col-md-12 custom-col">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width={"24%"}>Month</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{moment(this.state.selectedItem.planDate).format('MMM - YYYY')}</td>
                                                    </tr>
                                                    {/* {
                                                        this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                        <tr>
                                                            <td width={"24%"}>Location</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.state.selectedItem.unitCityList}</td>
                                                        </tr>
                                                    } */}
                                                    {
                                                        this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                        <tr>
                                                            <td width={"24%"}>Client</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.state.selectedItem.companyName}</td>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td width={"24%"}>Assignment Type</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.assignmentTypeName} ({this.state.selectedItem.assignmentTypeShortName})</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Units</td>
                                                        <td width={"4%"}>:</td>
                                                        {/* <td width={"72%"}>{SplitJoinString(this.state.selectedItem.unitList,",",":-:", ", ")}</td> */}
                                                        <td width={"72%"}>{getStringWithSpaceAfterComma(this.state.selectedItem.unitNameCityList)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Scope</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>
                                                            {
                                                                isEmptyVariable(this.state.selectedItem.plannedAssignmentArea)?
                                                                this.state.selectedItem.assignmentArea:
                                                                this.state.selectedItem.plannedAssignmentArea
                                                            }
                                                        </td>
                                                    </tr>
                                                    {
                                                        (this.state.selectedItem.plannedAssignmentArea === "Process" || 
                                                        this.state.selectedItem.assignmentArea === "Process") &&
                                                        !isEmptyVariable(this.state.selectedItem.processList) &&
                                                        <tr>
                                                            <td width={"24%"}>Process</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{SplitJoinString(this.state.selectedItem.processList,",",":-:",", ")}</td>
                                                        </tr>
                                                    }
                                                    {
                                                         (this.state.selectedItem.plannedAssignmentArea === "Function" || 
                                                         this.state.selectedItem.assignmentArea === "Function") &&
                                                         !isEmptyVariable(this.state.selectedItem.functionList) &&
                                                        <tr>
                                                            <td width={"24%"}>Function</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{SplitJoinString(this.state.selectedItem.functionList,",",":-:", ", ")}</td>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td width={"24%"}>Status</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>
                                                            {this.state.selectedItem.status === activeStatus
                                                            ?activeStatusLabel 
                                                            :this.state.selectedItem.status}
                                                        </td>
                                                    </tr>
                                                    {
                                                        this.state.selectedItem.status !== activeStatus &&
                                                        this.state.selectedItem.status !== inActiveStatus &&
                                                        <tr>
                                                            <td width={"24%"}>Review Status</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.state.selectedItem.currentReviewStatus}</td>
                                                        </tr>
                                                    }
                                                    {
                                                        this.state.selectedItem.allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW &&
                                                        this.state.selectedItem.currentReviewStatus.includes(requestChangeCheckText) &&
                                                        <tr>
                                                            <td width={"24%"}>Review Remarks</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.state.selectedItem.lastReviewRemark}</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                    </section>
                </main>
            </Router>
        );
    }
}

export default OtherMasters;