import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import {DialogSaveButton} from '../../../common/Global/globalStyles.style';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();

class UpdateReviewStatusDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,

            heading:"",
            formErrors:{},
            submitClickedAtleastOnce:false,
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){
            if(this.props.isEdit){
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    heading:this.props.heading,
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                })
            }else{
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    heading:"",
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleCreateDialogClose = () =>{
        this.props.handleAddEditDialogClose(false);
    }

    handleCreateMaster = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.heading)){
            isError = true;
            FormErrorsTemp['heading'] = "Please enter heading";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";
            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
            }

            if(this.props.isEdit){
                url = Constants.UpdateChecklistHeadings;

                postParam.checkListVersionId = this.props.rootCheckListVersionId;
                postParam.checkListHeadingId = this.props.selectedId;
                postParam.heading = this.state.heading;
            }else{
                url = Constants.CreateChecklistHeadings;

                postParam.checkListVersionId = this.props.rootCheckListVersionId;
                postParam.parentHeadingId = this.props.parentHeadingId;
                postParam.heading = this.state.heading;
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    let createNodeTypeTemp = this.props.createNodeType;

                    if(createNodeTypeTemp === "root"){
                        this.setState({
                            showLoader:false,
                            heading:"",
                            formErrors:{},
                        },() => {
                            this.props.handleAddEditDialogClose(true);
                        });
                    }else if(createNodeTypeTemp === "child" ||
                    createNodeTypeTemp === "sibling"){
                        this.setState({
                            showLoader:false,
                            heading:"",
                            formErrors:{},
                        },() => {
                            this.props.handleAddEditDialogClose(true);
                        });
                    }
                }else{
                    // FormErrorsTemp['apiErr'] = data.responseMessage;
                    toast.error(data.responseMessage);
                    this.setState({
                        // formErrors:FormErrorsTemp,
                        showLoader:false
                    });
                }
            });
        }
    }

    render() {
        return(
            <Modal className="custom-dialog" show={this.props.showCreateDialog} backdrop="static">
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit Heading":"Add New Heading"}</h5>
                    <button 
                        onClick={this.handleCreateDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div class="modal-body">
                    <div class="addnew-modal-form">
                        <div class="addnew-modal-form-group">
                            <p>Heading*</p>
                            <input type="text" placeholder="Enter Function Name"
                            name="heading" value={this.state.heading} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['heading']) &&
                                <span class="cm-error">{this.state.formErrors['heading']}</span>
                            }
                            {/* {
                                !isEmptyVariable(this.state.formErrors['apiErr']) &&
                                <span class="cm-error">{this.state.formErrors['apiErr']}</span>
                            } */}
                        </div>
                    </div>
                    <div class="modal-close-save">
                        <div class="close-save-col">
                            <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                            <DialogSaveButton 
                            themeSettings={this.props.themeSettings}
                            onClick={this.handleCreateMaster} type="button">
                                {this.state.showLoader ? <div class="loader-btn mt-2"></div> : 'Save'}
                            </DialogSaveButton>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default UpdateReviewStatusDialog;