import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isEmptyVariable,getThemeSettingsVariables } from '../../common/Global/commonFunctions';
import {TopTabsLayout} from '../../common/Global/globalStyles.style';

let themeSettings  = getThemeSettingsVariables();
let themeColor='';
class CompaniesTopMenu extends Component{
    constructor(props) {
        super(props);

        themeSettings  = getThemeSettingsVariables();
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
        
    }

    // This function to handle click events on Units tab
    unitsOnClick = () => {
        this.props.unitTabItemOnClick()
    }

    // This function to handle click events on Employees tab
    employeesOnClick = () => {
        this.props.employeesTabItemOnClick(this.props.unitId)
    }
    
    // This function to handle click events on Grouping tab
    groupingOnClick = () =>{
        this.props.gropingTabItemOnClick();
    }

    // This function to handle click events on City tab
    cityOnClick = () =>{
        this.props.cityTabItemOnClick();
    }

    // This function handle click events on State/Province tab
    stateOnClick = () => {
        this.props.stateTabItemOnClick();
    }

    //This function handle click events on Country tab
    countryOnClick = () => {
        this.props.countryTabItemOnClick();
    }

    render(){
        const {unitId,displayingUnits,displayingEmployees,displayingGrouping,displayingCities,displayingStates,displayingCountries,showCityMaster} = this.props;

        let width="50%";
        if(!isEmptyVariable(unitId)){
            width = "62%";
        }
        
        return(
            <TopTabsLayout themeSettings={this.props.themeSettings}>
            
                <div className="question-tabs"  style={{marginRight:0}}>
                    <ul style={{width:width}}>

                    {/* By default Units must be displayed. if employees tab is there then we need to
                    display both the tabs */}
                    <li className={displayingUnits?"questiontab-active":""}>
                        <a onClick={this.unitsOnClick} href="javascript:void(0)"
                            style={{color: !displayingUnits ? themeColor : '', fontWeight: !displayingUnits ? "bold" : ""}}>Units</a>
                    </li>
                    {
                        !isEmptyVariable(unitId) && 
                        <li className={displayingEmployees?"questiontab-active":""}>
                            <a onClick={this.employeesOnClick} href="javascript:void(0)"
                                style={{color: !displayingEmployees ? themeColor : '', fontWeight: !displayingEmployees ? "bold" : ""}}>Employees</a>
                        </li>
                    }
                     <li className={displayingGrouping?"questiontab-active":""}>
                        <a onClick={this.groupingOnClick} href="javascript:void(0)"
                            style={{color: !displayingGrouping ? themeColor : '', fontWeight: !displayingGrouping ? "bold" : ""}}>Grouping</a>
                    </li>
                    {
                        showCityMaster &&
                        <>
                            <li className={displayingCities?"questiontab-active":""}>
                                <a onClick={this.cityOnClick} href="javascript:void(0)"
                                style={{color: !displayingCities ? themeColor : '', fontWeight: !displayingCities ? "bold" : "100px"}}>City</a>
                            </li>

                            <li className={displayingStates?"questiontab-active":""}>
                                <a onClick={this.stateOnClick} href="javascript:void(0)"
                                style={{color: !displayingStates ? themeColor : '', fontWeight: !displayingStates ? "bold" : "100px"}}>State/Province</a>
                            </li>

                            <li className={displayingCountries?"questiontab-active":""}>
                                <a onClick={this.countryOnClick} href="javascript:void(0)"
                                style={{color: !displayingCountries ? themeColor : '', fontWeight: !displayingCountries ? "bold" : "100px"}}>Country</a>
                            </li>
                        </>
                    }
                    </ul>
                </div>

                <div onClick={this.props.toggleCardsDisplay}
                style={{opacity:this.props.hideInfo?"0":"1"}} className="infocard-showhide-col">
                    {/* <div className="infocard-icon-col">
                        <img src="/assets/images/topbar-info.svg" />
                    </div> */}
                </div>
            </TopTabsLayout>
        )
    }
}

export default withRouter(CompaniesTopMenu);