import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import SpinnerLoader from 'react-loader-spinner';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { getThemeSettingsVariables, isEmptyVariable } from '../../common/Global/commonFunctions';
import AlertDialog from '../../common/AlertDialog';
import { toast } from 'react-toastify';
const themeSettings = getThemeSettingsVariables();

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginEmail:"",
            loginPassword:"",
            errors:[],
            formSubmitted:false,

            showLogin:true,
            showForgotPassword:false,

            forgotEmail:"",
            forgotErrors:[],

            loggedInUserId:'',
            loggedInAccessToken:'',
            loggedInFirstName:'',
            loggedInLastName:'',
            loggedInEmail:'',
            loggedInPhoneNo:'',
            loggedInProfilePic:'',

            showOfflineModePopup: false,
            showOfflineDataLostPopup: false,
        }
    }

    componentDidMount()
    {
        const userDetailStr = localStorage.getItem('loggedInUserDetails');
        if(userDetailStr)
        {
            if(localStorage.getItem('firstLogin')+"" === "1"){
                this.props.history.push('/changepassword');
            }else{
                this.props.history.push('/dashboard');
            }
            
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.checkLogin();
        }
    }

    checkLogin = (isMandatory = false) => {
        this.setState({
            formSubmitted:true,
        });

        const { loginEmail, loginPassword } = this.state;
        
        if(!this.validateCheckLoginForm())
        {
            const body = { email: loginEmail, password: loginPassword, loginFrom: "Web" };
            if (isMandatory) {
                body.isMandatory = "Y";
            }

            fetch(Constants.CheckLogin,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(body),
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === 0)
                {
                    this.setState(
                        { 
                            loggedInUserId: data.data.userId,
                            loggedInEmail: data.data.email,
                            loggedInPhoneNo: data.data.phoneNo,
                            loggedInFirstName:data.data.firstName,
                            loggedInLastName:data.data.lastName,
                            loggedInAccessToken:data.data.accessToken,
                            loggedInProfilePic:data.data.profilePic,
                            themeSettings:data.data.themeSettings,
                            firstLogin:data.data.firstLogin,

                        },()=>{
                            this.saveLocalStorage(this.state.loggedInUserId,this.state.loggedInEmail,
                                this.state.loggedInProfilePic,this.state.loggedInPhoneNo,this.state.loggedInFirstName,
                                this.state.loggedInLastName,this.state.loggedInAccessToken,this.state.themeSettings,
                                this.state.firstLogin);
                            if(data.data.role === "SuperAdmin"){
                                this.props.history.push('/listclients');
                            }else{
                                if(this.state.firstLogin === 0){
                                    this.props.history.push('/dashboard');
                                    
                                }else if(this.state.firstLogin === 1){
                                    //goto change password screen
                                    this.props.history.push('/changepassword');
                                }
                                
                            }
                        });
                }
                else if (data.responseCode === 240)
                {
                    this.setState({
                        formSubmitted: false,
                        showOfflineModePopup: true,
                    });
                }
                else
                {
                    // let errors = {};
                    // errors['invalidLogin'] = data.responseMessage;
                    toast.error(data.responseMessage);
                    this.setState({
                        formSubmitted:false,
                        // errors: errors
                    });

                    // setTimeout(function(){
                    //     this.setState({errors:[]});
                    // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                }
            });
        }
        else
        {
            setTimeout(function(){
                this.setState({errors:[]});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);

            this.setState({
                formSubmitted:false,
            });
        }
    }

    saveLocalStorage = (loggedInUserId,loggedInUserEmail,loggedInProPic,loggedInPhoneNo,
        loggedInFirstName,loggedInLastName,loggedInAccessToken,themeSettings,firstLogin) =>{
        let parameters = {
            loggedInUserId:loggedInUserId,
            loggedInUserEmail:loggedInUserEmail,
            loggedInProPic:loggedInProPic,
            loggedInPhoneNo:loggedInPhoneNo,
            loggedInFirstName:loggedInFirstName,
            loggedInLastName:loggedInLastName,
            loggedInAccessToken:loggedInAccessToken,
        };
        localStorage.clear();
        let otherUserDetails = {
            processMastersInfoFlag: true,
            functionMastersInfoFlag: true,
            industryMastersInfoFlag: true
        }
        localStorage.setItem('loggedInUserDetails', JSON.stringify(parameters));
        localStorage.setItem('otherUserDetails', JSON.stringify(otherUserDetails));
        localStorage.setItem('firstLogin', firstLogin);
        if(!isEmptyVariable(themeSettings)){
            localStorage.setItem('themeSettings', themeSettings);
        }
    }

    validateCheckLoginForm = () => {
        var error_flag = false;
        let errors = {};

        if(this.state.loginEmail === "")
        {
            error_flag = true;
            errors['loginEmail'] = "Please enter email";
        }

        if(this.state.loginEmail !== "")
        {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!pattern.test(this.state.loginEmail)) {
                error_flag = true;
                errors["loginEmail"] = "Please enter valid email";
            }
        }
        
        if(this.state.loginPassword === "")
        {
            error_flag = true;
            errors['loginPassword'] = "Please enter password";
        }
        
        this.setState({
            errors: errors
        });

        return error_flag;
    }

    showForgotPasswordLayout = () => {
        this.setState({
            showForgotPassword:true,
            showLogin:false,
        })
    }
    
    showLoginLayout = () => {
        this.setState({
            showForgotPassword:false,
            showLogin:true,
        })
    }

    forgotPassword = () => {
        this.setState({
            formSubmitted:true,
        });

        var error_flag = false;
        let errors = {};

        if(this.state.forgotEmail === "")
        {
            error_flag = true;
            errors['forgotEmail'] = "Please enter email";
        }

        if(this.state.forgotEmail != "")
        {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!pattern.test(this.state.forgotEmail)) {
                error_flag = true;
                errors["forgotEmail"] = "Please enter valid email";
            }
        }

        if (!error_flag)
        {
            fetch(Constants.ForgotPassword,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams({email:this.state.forgotEmail})
                })
                .then(response => { return response.json(); } )
                .then(data =>
                {
                    // let errors = {};
                    // errors['invalidEmail'] = data.responseMessage;
                    toast.error(data.responseMessage)
                    this.setState({
                        formSubmitted:false,
                        // forgotErrors: errors,
                        forgotEmail:""
                    });

                    // setTimeout(function(){
                    //     this.setState({forgotErrors:[]});
                    // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                });

        }else{
            this.setState({
                forgotErrors: errors
            });

            setTimeout(function(){
                this.setState({forgotErrors:[]});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);

           this.setState({
                formSubmitted:false,
            });
        }
    }

    hideOfflineModePopup = (isOk = false) => {
        this.setState({
            showOfflineModePopup: false,
        }, () => {
            if (isOk) {
                this.setState({
                    showOfflineDataLostPopup: true,
                });
            }
            else {
                this.setState({
                    loginEmail: "",
                    loginPassword: "",
                });
            }
        });
    }

    hideOfflineDataLostPopup = (isProceed = false) => {
        this.setState({
            showOfflineDataLostPopup: false,
        }, () => {
            if (isProceed) {
                this.checkLogin(true);
            }
            else {
                this.setState({
                    loginEmail: "",
                    loginPassword: "",
                });
            }
        });
    }

    render(){
        return(
            <Router>
                <main className="login-bg">
                    <div className="login-wrapper">
                        <div className="login-wrapper-col">

                            {
                                this.state.showLogin &&
                                <div className="login-form">
                                    <div className="login-form-logo">
                                        <img src="assets/images/sameeksha.png" />
                                        <h6>Login</h6>
                                    </div>

                                    <div className="login-form-col">
                                        <div className="login-input-group">
                                            <div className="login-field">
                                                <span className="material-icons">person</span>
                                                <input autofocus="true" type="text" placeholder="Email" onChange={this.handleChange}
                                                name="loginEmail" value={this.state.loginEmail}/>
                                            </div>
                                            <span className="errorspan-login">{this.state.errors.loginEmail}</span>
                                        </div>
                                
                                    <div className="login-input-group">
                                        <div className="login-field">
                                            <span className="material-icons">lock</span>
                                            <input type="password" placeholder="Password" onChange={this.handleChange}
                                            onKeyPress={this.onEnterBtnPress}
                                            name="loginPassword" value={this.state.loginPassword}/>
                                        </div>

                                        <span className="errorspan-login">{this.state.errors.loginPassword}</span>
                                    </div>

                                    <div style={{display:"none"}} className="login-input-group">
                                        <div className="login-remember">
                                            <span className="material-icons">crop_square</span>
                                            <p>Remember Me</p>
                                        </div>
                                    </div>

                                    <div className="login-input-group">
                                        <div className="login-button">
                                            <button 
                                                type="button" 
                                                disabled={this.state.formSubmitted} 
                                                onClick={() => this.checkLogin()}>
                                                Login
                                                {
                                                    this.state.formSubmitted &&
                                                    <SpinnerLoader style={{ marginLeft:10 }} 
                                                    type={Constants.LOADER_TYPE} color={Constants.LOADER_COLOR} height={20} width={30} />
                                                }
                                            </button>
                                        </div>
                                        {/* <span className="errorspan-login">{this.state.errors.invalidLogin}</span> */}
                                    </div>
                                    <div className="login-input-group m-b-0">
                                        <div className="forgot-password-text">
                                            <p>Forgot Password? <a onClick={this.showForgotPasswordLayout} href="javascript:void(0);">Click Here</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.showForgotPassword && 
                            <div className="login-form">
                                <div className="login-form-logo">
                                    <img src="assets/images/user-profile.png" />
                                    <h6>Forgot Password</h6>
                                </div>

                                <div className="login-form-col">
                                    <div className="login-input-group">
                                        <div className="login-field">
                                            <span className="material-icons">person</span>
                                            <input type="text" placeholder="Enter your email" onChange={this.handleChange}
                                            name="forgotEmail" value={this.state.forgotEmail}/>
                                        </div>
                                        <span className="errorspan-login">{this.state.forgotErrors.forgotEmail}</span>
                                    </div>

                                    <div className="login-input-group">
                                        <div className="login-button">
                                            <button 
                                                type="button" 
                                                disabled={this.state.formSubmitted} 
                                                onClick={this.forgotPassword}>
                                                Submit
                                                {
                                                    this.state.formSubmitted &&
                                                    <SpinnerLoader style={{ marginLeft:10 }} 
                                                    type={Constants.LOADER_TYPE} color={Constants.LOADER_COLOR} height={20} width={30} />
                                                }
                                            </button>
                                        </div>
                                        <span className="errorspan-login">{this.state.forgotErrors.invalidEmail}</span>
                                    </div>
                                    <div className="login-input-group m-b-0">
                                        <div className="forgot-password-text">
                                            <p>Click here to <a onClick={this.showLoginLayout} href="javascript:void(0);">Login</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                            <div className="login-menu">
                                <ul>
                                    <li><a target="_blank" href="https://www.sarvinfo.com/">Home</a></li>
                                    <li><a target="_blank" href="https://www.sarvinfo.com/privacy-policy/">Privacy Policy</a></li>
                                    <li><a target="_blank" href="https://www.sarvinfo.com/contact-us/">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <AlertDialog
                        showAlertDialog={this.state.showOfflineModePopup}
                        handleAlertDialogClose={() => this.hideOfflineModePopup()}
                        type={Constants.ALERT_TYPE_INFO}
                        alertDialogMessage={"You were last working on offline mode on your mobile. Data will be lost, if you proceed with web mode."}
                        proceedBtnClick={() => this.hideOfflineModePopup(true)}
                        proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                        themeSettings={themeSettings}
                    />
                    <AlertDialog
                        showAlertDialog={this.state.showOfflineDataLostPopup}
                        handleAlertDialogClose={() => this.hideOfflineDataLostPopup()}
                        type={Constants.ALERT_TYPE_INFO}
                        alertDialogMessage={"Are you sure, you want to proceed with login? Offline data will get deleted and cannot be recovered."}
                        proceedBtnClick={() => this.hideOfflineDataLostPopup(true)}
                        proceedBtnLabel={Constants.ALERT_TYPE_PROCEED_LABEL}
                        themeSettings={themeSettings}
                    />
                </main>
            </Router>
        );
    }
}

export default Login;