import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { TableRadioButton, DialogSaveButton } from "../../../common/Global/globalStyles.style";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray } from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const userDetails = getLocalStorageVariables();

class AssertionAssignmentList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRiskId: "",
			riskErr: "",
			likelihoodRiskId: "",
			likelihoodRiskErr: "",
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showRiskDialog) {
			let selectedId = isEmptyVariable(this.props.selectedRiskId) ? "" : this.props.selectedRiskId;
			let selectedLikelihoodId = isEmptyVariable(this.props.selectedLikelihoodRiskId) ? "" : this.props.selectedLikelihoodRiskId;

			this.setState({
				selectedRiskId: selectedId,
				riskErr: "",
				likelihoodRiskId: selectedLikelihoodId,
			});
		}
	}

	handleSelection = (e) => {
		// e.stopPropagation();
		let isChecked = e.target.checked;
		let riskLevelArrIndex = e.target.name;
		let selectedId = "";

		if (isChecked) {
			selectedId = this.props.riskArr[riskLevelArrIndex].riskLevelId;
		}

		this.setState({
			selectedRiskId: selectedId,
		});
	};

	returnSelectedRiskId = () => {
		let riskErrTemp = "", likelihoodRiskErrTemp = "";

		if (isEmptyVariable(this.state.selectedRiskId)) {
			// riskErrTemp = "Select the risk level";
			toast.warning("Select the risk level");
			// this.setState({
			// 	riskErr: riskErrTemp,
			// });

			this.setState({
				riskErr: riskErrTemp,
			});

			setTimeout(
				function () {
					this.setState({
						riskErr: "",
					});
				}.bind(this),
				Constants.WRNG_MSG_TIMEOUT
			);
		}

		if (isEmptyVariable(this.state.likelihoodRiskId)) {
			likelihoodRiskErrTemp = "Select the likelihood level";

			this.setState({
				likelihoodRiskErr: likelihoodRiskErrTemp,
			});

			setTimeout(
				function () {
					this.setState({
						likelihoodRiskErr: "",
					});
				}.bind(this),
				Constants.WRNG_MSG_TIMEOUT
			);
		}

		if(!isEmptyVariable(this.state.selectedRiskId) && !isEmptyVariable(this.state.likelihoodRiskId)) {
            //api call
            let url = Constants.AssignRiskToAssignmentTask;
            if(this.props.isDraftOnward){
                url = Constants.AssignriskToDraftAssignmentTask;
            }
            fetch(url, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentTaskId: this.props.assignmentTaskId,
                    riskLevelId: this.state.selectedRiskId,
					likelihoodLevelId: this.state.likelihoodRiskId,
                }),
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.props.handleSelectRiskDialogClose(true);
                } else {
                    // riskErrTemp = data.responseMessage;
					toast.error(data.responseMessage);
                    // this.setState({
                    //     riskErr: riskErrTemp,
                    // });

                    // setTimeout(
                    //     function () {
                    //         this.setState({
                    //             riskErr: "",
                    //         });
                    //     }.bind(this),
                    //     Constants.WRNG_MSG_TIMEOUT
                    // );
                }
            });
			
		}
	};

	handleSelectionLikelihood = (e) => {
		let isChecked = e.target.checked;
		let likelihoodLevelArrIndex = e.target.name;
		let selectedId = "";

		if (isChecked) {
			selectedId = this.props.likelihoodRiskMatrix[likelihoodLevelArrIndex].riskLevelId;
		}

		this.setState({
			likelihoodRiskId: selectedId,
		});
	};
	/*****************************API*********************************/
	render() {
		return (
			<section>
				<Modal className="custom-dialog" show={this.props.showRiskDialog} size="lg" backdrop="static">
					<Modal.Header>
						<h5>Risk Level</h5>
						<button onClick={this.props.handleSelectRiskDialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						<div className="body-wrapper">
							<div className="row">
								<div className="col-md-6">
									{isEmptyArray(this.props.riskArr) ?
									 (
										<div class="no-items-layout">
											<div class="no-items-card">
												<h6>No risk levels found</h6>
											</div>
										</div>
									 ) : 
									 (isEmptyVariable(this.state.selectedRiskId) && this.props.assignmentTaskAllowEdit === "N") ?
									 <div class="no-items-layout">
										 <div class="no-items-card">
											 <h6>No risk selected</h6>
										 </div>
									 </div>
									: (
										<div>
											<div className="common-tab-bg">
												<div className="common-table" style={{ width: "100%", border: "1px solid #e5e5e5" }}>
													<table className="table">
														<thead>
															<tr>
																<th className="text-center" width={"8%"}></th>
																<th>Risk Level</th>
																<th>Color Code</th>
															</tr>
														</thead>
														<tbody>
															{this.props.riskArr.map((item, idx) => {
																return (
																	<tr>
																		<td>
																			<TableRadioButton themeSettings={this.props.themeSettings}>
																				<input
																					type="checkbox"
																					name={idx}
																					id={item.riskLevelId}
																					onChange={this.handleSelection}
																					checked={item.riskLevelId + "" === this.state.selectedRiskId + "" ? true : false}
																					disabled={this.props.assignmentTaskAllowEdit === "Y" ? false : true}
																				/>
																				<label for={item.riskLevelId} style={{ marginLeft: 6 }}></label>
																			</TableRadioButton>
																		</td>
																		<td>{item.riskLevel}</td>
																		<td>
																			<div className="risk-level-color-wrapper" style={{ width: "38px" }}>
																				<div className="risk-level-color" style={{ background: item.colorCode }} />
																			</div>
																		</td>
																	</tr>
																);
															})}
														</tbody>
													</table>
												</div>
											</div>
											{!isEmptyVariable(this.state.riskErr) && <span className="cm-error">{this.state.riskErr}</span>}
										</div>
									)}
								</div>
								<div className="col-md-6">
									{isEmptyArray(this.props.likelihoodRiskMatrix) ?
									 (
										<div class="no-items-layout">
											<div class="no-items-card">
												<h6>No likelihood levels found</h6>
											</div>
										</div>
									 )
									: (
										<div>
											<div className="common-tab-bg">
												<div className="common-table" style={{ width: "100%", border: "1px solid #e5e5e5" }}>
													<table className="table">
														<thead>
															<tr>
																<th className="text-center" width={"8%"}></th>
																<th>Likelihood Level</th>
																<th>Color Code</th>
															</tr>
														</thead>
														<tbody>
															{this.props.likelihoodRiskMatrix.map((item, idx) => {
																return (
																	<tr>
																		<td>
																			<TableRadioButton themeSettings={this.props.themeSettings}>
																				<input
																					type="checkbox"
																					name={idx}
																					id={"likelihood_" + item.riskLevelId}
																					onChange={this.handleSelectionLikelihood}
																					checked={item.riskLevelId + "" === this.state.likelihoodRiskId + "" ? true : false}
																					disabled={this.props.assignmentTaskAllowEdit === "Y" ? false : true}
																				/>
																				<label for={"likelihood_" + item.riskLevelId} style={{ marginLeft: 6 }}></label>
																			</TableRadioButton>
																		</td>
																		<td>{item.riskLevel}</td>
																		<td>
																			<div className="risk-level-color-wrapper" style={{ width: "38px" }}>
																				<div className="risk-level-color" style={{ background: item.colorCode }} />
																			</div>
																		</td>
																	</tr>
																);
															})}
														</tbody>
													</table>
												</div>
											</div>
											{!isEmptyVariable(this.state.likelihoodRiskErr) && <span className="cm-error">{this.state.likelihoodRiskErr}</span>}
										</div>
									)}
								</div>
							</div>
							{/* )} */}
							{/* {!isEmptyVariable(this.state.riskErr) && <span className="cm-error">{this.state.riskErr}</span>} */}
							<div className="modal-close-save m-t-15">
								<div className="close-save-col">
									<button onClick={this.props.handleSelectRiskDialogClose} type="button" className="modal-close m-b-0">
										Cancel
									</button>
									{this.props.assignmentTaskAllowEdit === "Y" &&
										<DialogSaveButton
											themeSettings={this.props.themeSettings}
											onClick={this.returnSelectedRiskId}
											type="button"
											className="modal-save m-b-0"
										>
											Save
										</DialogSaveButton>
									}
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</section>
		);
	}
}

export default AssertionAssignmentList;
