import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {TableCheckBox,DialogSaveButton,AddNewButtonLayout,TableIcon} from '../../../common/Global/globalStyles.style';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,
    isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import SelectDocument from "./selectDocument"
import SelectSampleChecked from "./selectSampleChecked"
import { toast } from 'react-toastify';
const userDetails  = getLocalStorageVariables();

class DocumentSampleList extends Component{
    constructor(props){
        super(props);
        this.state = {
            componentDidMountFlag:false,
            datalist:[],

            showDocumentsListDialog:false,
            // documentFormErr:"",

            showSampleCheckedListDialog:false,
            // documentFormErr:"",


        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showDocumentDialog){
            this.setState({
                componentDidMountFlag:true,
                datalist:[],
                showLoader:false,
                showDocumentsListDialog:false,
            },()=>{
                // this.getDocumentList();
            })
            
        }
    }

    showSampleCheckedListDialog = () => {
        this.setState({
            showSampleCheckedListDialog:true
        })
    }

    closeSampleCheckedListDialog = () => {
        this.setState({
            showSampleCheckedListDialog:false
        })
    }
    SampleCheckedListDialogSaveClose = (arr) => {
        this.setState({
            showSampleCheckedListDialog:false,
            datalist:[...this.state.datalist,...arr]
        })
    }
    showDocumentListDialog = () => {
        this.setState({
            showDocumentsListDialog:true
        })
    }

    closeDocumentListDialog = () => {
        this.setState({
            showDocumentsListDialog:false
        })
    }

    documentListDialogSaveClose = (arr) => {
        this.setState({
            showDocumentsListDialog:false,
            datalist:[...this.state.datalist,...arr]
        });
    }

    deleteSelectedDocument = (index) => {
        let datalist = this.state.datalist;

        datalist.splice(index,1)

        this.setState({
            datalist:datalist
        })
    }

    handleBlurSizeValue = (index, e) => {
		const value = e.currentTarget.textContent;
		let datalistTemp = this.state.datalist;

		if (datalistTemp[index].description + "" !== value) {
            datalistTemp[index].description = value;
            this.setState({
                datalist: datalistTemp,
            });
		}
	};
    /*****************************API*********************************/
    addNewWorkingPaper = () => {
        this.setState({
            showLoader:true
        })
        let isError = false;
        if(isEmptyArray(this.state.datalist)){
            isError = true;
        }

        if(isError){
            toast.warning("Please select atleast one document");
            this.setState({
                showLoader:false,
                // documentFormErr:"Please select atleast one document"
            })
        }else{
            fetch(Constants.AddNewWorkingPaper, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.props.assignmentId,
                    documents: JSON.stringify(this.state.datalist),
                }),
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.setState({
                        showLoader:true
                    })
                    this.props.handleSelectDocDialogSaveClose(true);
                } else {
                }
            });
        }
    }
    render(){
        return(
            <section>
                <Modal className="task-select-table-dialog custom-dialog"
                    show={this.props.showDocumentDialog} 
                    backdrop="static">
                    <Modal.Header>
                        <h5>Add New</h5>
                        <button 
                            onClick={this.props.handleSelectDocDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div class="loader"></div>
                        }
                        {
                            // Need to check component did mount flag, other wise until api is called,
                            // it shows no items found layout.
                            this.state.componentDidMountFlag &&
                            <div className="body-wrapper">
                                <div className="search-addnew-bg" style={{marginTop:0}}>
                                    <div className="search-addnew">
                                        <div></div>
                                        <div className="flex-center-layout">
                                            <AddNewButtonLayout style={{marginRight:10}}themeSettings={this.props.themeSettings}>
                                                <a onClick={this.showSampleCheckedListDialog} href="javascript:void(0);">
                                                    <span className="material-icons">add_circle</span>
                                                    <p>Sample Checked</p>
                                                </a>
                                            </AddNewButtonLayout>
                                            <AddNewButtonLayout themeSettings={this.props.themeSettings}>
                                                <a onClick={this.showDocumentListDialog} href="javascript:void(0);">
                                                    <span className="material-icons">add_circle</span>
                                                    <p>Documents Master</p>
                                                </a>
                                            </AddNewButtonLayout>
                                        </div>
                                    </div>
                                </div>

                                <div style={{position:"relative"}}>
                                    {
                                        !isEmptyArray(this.state.datalist) && 
                                        <div className="common-tab-bg">
                                            <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th>Name</th>
                                                            <th width={"12%"}>Type</th>
                                                            <th width={"320px"}>
                                                            {
                                                                this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING 
                                                                ?
                                                                <div className="flex-center-layout table-cell-edit"><p>Description</p><span className="material-icons">edit</span></div>
                                                                :
                                                                <div>Description</div>

                                                            }
                                                            </th>
                                                            <th className="text-center"  width={"10%"}>Delete</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        !isEmptyArray(this.state.datalist) && 
                                                        this.state.datalist.map((item,idx) => {

                                                            return <tr>
                                                                <td className="text-center">{(idx+1)}</td>
                                                                <td>{item.name}</td>
                                                                <td>{item.documentType}</td>
                                                                <td><div  contentEditable="true" className="single-line-editable" spellCheck="true" 
                                                                onBlur={this.handleBlurSizeValue.bind(this, idx)}>
                                                                    {item.description}
                                                                </div></td>
                                                                <TableIcon 
                                                                    onClick={this.deleteSelectedDocument.bind(this,idx)}
                                                                    themeSettings={this.props.themeSettings} className="text-center">
                                                                    <span class="material-icons">delete</span>
                                                                </TableIcon>
                                                            </tr>
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                        <div class="no-items-layout">
                                            <div class="no-items-card">
                                                <h6>Please add documents</h6>
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/* {
                                    !isEmptyVariable(this.state.documentFormErr) &&
                                    <span className="cm-error">{this.state.documentFormErr}</span>
                                } */}
                                <div className="modal-close-save m-t-15">
                                    <div className="close-save-col">
                                        <button onClick={this.props.handleSelectDocDialogClose} type="button" 
                                        className="modal-close m-b-0">Cancel</button>
                                        <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                        onClick={this.addNewWorkingPaper} type="button" 
                                        className="modal-save m-b-0">Save</DialogSaveButton>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal>

                <SelectSampleChecked
                    showDocumentDialog = {this.state.showSampleCheckedListDialog}
                    handleSelectDocDialogClose = {this.closeSampleCheckedListDialog}
                    handleSelectDocDialogSaveClose = {this.SampleCheckedListDialogSaveClose}
                    themeSettings={this.props.themeSettings}
                    departmentId={this.props.departmentId}
                    assignmentId={ this.props.assignmentId}
                    subscriptionType={this.props.subscriptionType}
                />
                <SelectDocument
                    showDocumentDialog = {this.state.showDocumentsListDialog}
                    handleSelectDocDialogClose = {this.closeDocumentListDialog}
                    handleSelectDocDialogSaveClose = {this.documentListDialogSaveClose}
                    themeSettings={this.props.themeSettings}
                    departmentId={this.props.departmentId}
                    subscriptionType={this.props.subscriptionType}
                />
            </section>
        )
    }
}

export default DocumentSampleList;