import React from 'react';
import {Modal} from 'react-bootstrap';
import {getLocalStorageVariables, isEmptyVariable} from '../Global/commonFunctions';
import * as Constants from '../Global/constants'
import RegularDropdown from '../DropdownMenus/RegularDropdown'
import { DialogProceedButton} from "../Global/globalStyles.style";
import ReactSelectDropdown from '../DropdownMenus/ReactSelectDropdownWithSearch';
import { toast } from 'react-toastify';


let userDetails = {};


class AlertWithRadioDialog extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            newUserId:"",
            newUserPlaceholder:"Select User",
            errorMsg:"",
            showLoader: false
        }
    };

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showAlertDialog){
            this.setState({
                newUserId:"",
                newUserPlaceholder:"Select User",
                errorMsg:"",
                showLoader: false
            })
        }
    }

    usersDropdownClick = (item) => {
        this.setState({
            newUserId:item.userId,
            newUserPlaceholder:item.fullName,
            errorMsg:""
        })
    }

    handleProceedButtonClick = () => {
        let isError = false;
        let errorMessage = "";

        if(isEmptyVariable(this.state.newUserId)){
            isError = true;
            errorMessage = "Please select the new user"
        }

        if(isError){
            this.setState({
                errorMsg:errorMessage
            })
        }else{
            this.setState({
                showLoader:true
            })
            userDetails  = getLocalStorageVariables();

            let url = "";
            if(this.props.resourceAssignmentType === "Report"){
                url = Constants.AddReportResource;
            }else if(this.props.resourceAssignmentType === "Follow Up"){
                url = Constants.AddFollowUpResource;
            }else{
                url = Constants.AddResource;
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                    newUserId:this.state.newUserId,
                    level:this.props.addUserLevel,
                })
            }).then(res=>{
                return res.json();
            }).then(res=>{
                if(res.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    res.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(res.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader: false
                    })
                    this.props.handleAlertDialogClose(true);
                }else{
                    toast.error(res.responseMessage);
                    this.setState({
                        showLoader: false
                        // errorMsg:res.responseMessage
                    })            
                }
            })
        }
    }

    render() {
        return (
            <Modal show={this.props.showAlertDialog} backdrop="static">
                <Modal.Body>
                    <div class="dialog-layout">
                        <button 
                            onClick={this.props.handleAlertDialogClose}>
                            <span className="material-icons">close</span>
                        </button>
                        {/* <span className="material-icons">error_outline</span> */}
                        {
                            // !this.props.type === Constants.ALERT_TYPE_ALERT &&
                            !isEmptyVariable(this.props.alertDialogHeading) &&
                            <h5>
                                {this.props.alertDialogHeading}
                            </h5>
                        }
                        <div className="newuser-dd-layout">
                            {/* <RegularDropdown 
                                placeholder={this.state.newUserPlaceholder}
                                dropdownArr={this.props.usersList}
                                labelParam="fullName"
                                onDropDownItemClick={this.usersDropdownClick}
                                defaultPlaceholderDropDown={"Select User"}
                            /> */}

                            <ReactSelectDropdown
                                handleReactSelectClose={this.usersDropdownClick}
                                selectDropdownArr={this.props.usersList}
                                label={"fullName"}
                                value={"userId"}
                                placeholder={this.state.newUserPlaceholder}
                            />
                        </div>
                        {
                            this.state.errorMsg &&
                            <span className="cm-error dialog-error">{this.state.errorMsg}</span>
                        }

                        <div class="dialog-btn-layout">
                            <a class="alert-cancel" href="javascript:void(0);"
                                onClick={this.props.handleAlertDialogClose}
                            >Cancel</a>
                            
                            <DialogProceedButton
								themeSettings={this.props.themeSettings}
								class="alert-proceed"
								href="javascript:void(0);"
								onClick = {this.handleProceedButtonClick}
								style={{
									marginLeft:(this.props.type === Constants.ALERT_TYPE_ALERT)?"0px":"10px"
								}}
							>
                                {this.state.showLoader ? <div class="loader-btn mt-2"></div> : this.props.proceedBtnLabel}
                            </DialogProceedButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AlertWithRadioDialog;