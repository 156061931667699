import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { SearchSpan, DialogSaveButton, AddNewButtonLayout } from "../../../common/Global/globalStyles.style";
import Sidebar from "../../../common/Sidebar";
import Topbar from "../../../common/Topbar";
// import MasterMenuTabs from '../Common/topmenu';
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import AlertDialog from "../../../common/AlertDialog";
import Pagination from "../../../common/Global/pagination";
import MasterMenuTabs from "../Common/topmenu";
import RegularDropdown from "../../../common/DropdownMenus/RegularDropdown";
// import UpdateReviewStatusDialog from './othermastersupdatereviewstatusdialog'
// import ReviewHistoryDialog from '../../../common/reviewhistorydialog'
import {
	getLocalStorageVariables,
	getThemeSettingsVariables,
	GetOrderedMasterTabMenuReports,
	isEmptyVariable,
	sortTable,
	truncateString,
	isEmptyArray,
	getStringWithSpaceAfterComma,
} from "../../../common/Global/commonFunctions";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";

import { Modal, Tabs, Tab } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";
import moment from "moment";
import { getDropdownActionArrayCompletedReports } from "../../../common/Global/reviewFunctions";
import { toast } from "react-toastify";
// import ReportDetails from "../Common/reportDetails";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const sortName = "c.companyName";
const sortReferenceNo = "a.referenceNo";
const sortDescription = "a.assignmentTypeName";
const sortEndDate = "a.endDate";
const sortReportDate = "a.reportDate";
const sortArea = "a.assignmentArea";
const sortLocation = "unitNameCityList";
const sortunitAddressList = "unitAddressList";
const sortunitCityList ="unitCityList";

const masterTypeDefaultPlaceholder = "Select Master Type";
const masterTypeFilterDefaultPlaceholder = "All Master Types";
const departmentDropdownDefaultValue = "Select Department";
const departmentFilterDropdownDefaultValue = "All";

// const showObservations = {
// 	icon: "topic",
// 	label: "Show Observations",
// };
let dropdownWidth = "160px";

let groupSetArr = [
	{
		label: "All",
		isSelected: true,
	}
];
class ReportAssignmentList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
			// statusPlaceholder: "Active",
			resultSizePlaceholder: Constants.RESULT_SIZE ,
			sort: "",
			sortDir: "",
			company:{},
			masterTypeArr: [],
			masterTypeSelected: "All",
			masterFilterPlaceholder: masterTypeFilterDefaultPlaceholder,

			departmentArr: [],
			selectedDepartmentName: departmentDropdownDefaultValue,
			selectedDepartmentId: "",

			deptOnly: "N",

			operationType: "",
			deleteReactivateId: "",
			deleteReactivateMasterType: "",
			reviewLevel: "",


			showUpdateReviewDialog: false,
			showReviewHistoryDialog: false,
			filterDataList: [],
            groupSetDropDownArr: [],
            groupSetPlaceholder: "All",
			groupSetId: "",
			groupSetName: "",
			// status: activeStatus,
			// statusArr: [
			// 	{
			// 		label: activeStatus,
			// 		isSelected: true,
			// 	},
			// 	{
			// 		label: inActiveStatus,
			// 		isSelected: false,
			// 	},
			// 	{
			// 		label: underReviewStatus,
			// 		isSelected: false,
			// 	}
			// ],
			observationFilterDropDownArr: [
                {
                    label:"Observation",
                    isSelected:true
                },
                {
                    label:"Task",
                    isSelected:false
                }
            ],
            taskFilterDropDownArr: [
                {
                    label:"Observation",
                    isSelected:false
                },
                {
                    label:"Task",
                    isSelected:true
                }
            ],
            observationFilterPlaceholder:"Observation",
            taskFilterPlaceholder:"task",
			resultSizeArr: Constants.RESULT_SIZE_DD_ARR,

			name: "",
			shortName: "",
			description: "",
			masterType: "",
			formErrors: {},
			submitClickedAtleastOnce: false,
			// showDetailsDialog:false,
            // selectedItem:"",
		};
		this.masterTypeFilterArr = [];
	}

	componentDidMount() {
		this.getInitData();
	}

	handleChange = (e) => {
		const { name, value } = e.target;

		let FormErrorsTemp = this.state.formErrors;

		if (this.state.submitClickedAtleastOnce) {
			if (name === "shortName") {
				if (isEmptyVariable(value)) {
					FormErrorsTemp["shortName"] = "Please enter master short name";
				} else {
					FormErrorsTemp["shortName"] = "";
				}
			} else if (name === "name") {
				console.log("Inside name");
				if (isEmptyVariable(value)) {
					console.log("Inside name value:" + value);
					FormErrorsTemp["name"] = "Please enter master name";
				} else {
					FormErrorsTemp["name"] = "";
				}
			} else if (name === "description") {
				if (!isEmptyVariable(value) && value.length > Constants.DESC_STRING_LENGTH) {
					FormErrorsTemp["description"] = "Description must be less then 500 characters";
				} else {
					FormErrorsTemp["description"] = "";
				}
			}
		}

		this.setState({
			[name]: value,
			formErrors: FormErrorsTemp,
		});
	};

	handleCheck = (e) => {
		let isChecked = e.target.checked;
		let name = e.target.name;

		if (isChecked) {
			this.setState(
				{
					deptOnly: "Y",
				},
				() => {
					this.getAssignmentList();
				}
			);
		} else {
			this.setState(
				{
					deptOnly: "N",
				},
				() => {
					this.getAssignmentList();
				}
			);
		}
	};

	handleChangeSearch = (e) => {
		const { name, value } = e.target;

		this.setState({
			[name]: value,
		});
	};

	onSearchIconClick = () => {
		this.setState(
			{
				currentPageNo: 1,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
			this.setState(
				{
					currentPageNo: 1,
				},
				() => {
					this.getAssignmentList();
				}
			);
		}
	};

	handleUpdateReviewDialogClose = (reloadFlag) => {
		this.setState(
			{
				showUpdateReviewDialog: false,
				deleteReactivateMasterType: "",
				deleteReactivateId: "",
				reviewLevel: "",
			},
			() => {
				if (reloadFlag === true) {
					this.getAssignmentList();
				}
			}
		);
	};

	handleReviewHistoryDialogClose = () => {
		this.setState({
			showReviewHistoryDialog: false,
			deleteReactivateMasterType: "",
			deleteReactivateId: "",
		});
	};

	

	// handleDetailsDialogClose = () =>{
    //     this.setState({
    //         showDetailsDialog:false,
    //         selectedItem:"",
    //     });
    // }

    // handleDetailsDialogShow = (item) => {
    //     this.setState({
    //         showDetailsDialog:true,
    //         selectedItem:item,
    //     });
    // }

	

	// statusDropdownClick = (item, selectedIndex) => {
	// 	let tempArr = this.state.statusArr;
	// 	let placeholder = "";

	// 	for (const [i, value] of tempArr.entries()) {
	// 		if (i === selectedIndex) {
	// 			tempArr[i].isSelected = true;
	// 			placeholder = value.label;
	// 		} else {
	// 			tempArr[i].isSelected = false;
	// 		}
	// 	}

	// 	this.setState(
	// 		{
	// 			currentPageNo: 1,
	// 			statusArr: tempArr,
	// 			status: item.label,
	// 			statusPlaceholder: placeholder,
	// 		},
	// 		() => {
	// 			this.getAssignmentList();
	// 		}
	// 	);
	// };

	handleShowObservations = (assignmentId) => {
		console.log("assignmentId::",assignmentId)
		this.props.history.push(Constants.URL_FINAL_OBSERVATIONS + "/" + assignmentId);
	};

	handleCompletedFollowUp = (e,item) => {
		e.stopPropagation();
		 if(isEmptyVariable(item.observationTemplateId)){
            this.props.history.push(Constants.URL_COMPLETED_TASK + "/" + item.assignmentId,{
                departmentId:item.departmentId,
                observationTemplateId:item.observationTemplateId,
                observationTaskFilterDropDownArr:this.state.taskFilterDropDownArr,
                observationTaskFilterPlaceholder:this.state.observationFilterPlaceholder,
            });
        }else{
            this.props.history.push(Constants.URL_COMPLETED_FOLLOWUP + "/" + item.assignmentId,{
                departmentId:item.departmentId,
                assignmentArea:item.assignmentArea,
                observationTemplateId:item.observationTemplateId,
                observationTaskFilterDropDownArr:this.state.observationFilterDropDownArr,
                observationTaskFilterPlaceholder:this.state.observationFilterPlaceholder,
            });
        }
	}

	resultSizeDropdownClick = (item, selectedIndex) => {
		let tempArr = this.state.resultSizeArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label ;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				resultSizeArr: tempArr,
				resultSize: item.label,
				resultSizePlaceholder: placeholder,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	masterFilterDropdownClick = (item, selectedIndex) => {
		let placeholder = "";

		for (const [i, value] of this.masterTypeFilterArr.entries()) {
			if (i === selectedIndex) {
				this.masterTypeFilterArr[i].isSelected = true;
				placeholder = value.masterTypeDesc;
			} else {
				this.masterTypeFilterArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				masterTypeSelected: placeholder,
				masterFilterPlaceholder: placeholder === "All" ? masterTypeFilterDefaultPlaceholder : placeholder,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	deptFilterDropdownClick = (item, selectedIndex) => {
		let placeholder = "";
		let id = "";
		let filterDeptArr = this.state.filterDeptArr;

		for (const [i, value] of filterDeptArr.entries()) {
			if (i === selectedIndex) {
				filterDeptArr[i].isSelected = true;
				placeholder = value.name;
				id = value.departmentId;
			} else {
				filterDeptArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				selectedFilterDepartmentName: placeholder,
				selectedFilterDepartmentId: id,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	masterTypeDropdownClick = (item) => {
		let FormErrorsTemp = this.state.formErrors;

		if (this.state.submitClickedAtleastOnce) {
			if (isEmptyVariable(this.state.masterType) || this.state.masterType === masterTypeDefaultPlaceholder) {
				FormErrorsTemp["masterType"] = "";
			} else {
				FormErrorsTemp["masterType"] = "";
			}
		}

		this.setState({
			masterType: item.masterTypeDesc,
			formErrors: FormErrorsTemp,
		});
	};

	deptDropdownClick = (item) => {
		let FormErrorsTemp = this.state.formErrors;

		if (this.state.submitClickedAtleastOnce) {
			FormErrorsTemp["deparment"] = "";
		}

		this.setState({
			selectedDepartmentName: item.name,
			selectedDepartmentId: item.departmentId,
			formErrors: FormErrorsTemp,
		});
	};

	handleReportStatus = (item) => {
		if(item.downloadReportStatus == 'inQueue' || item.downloadReportStatus == 'inProcess' || item.downloadReportStatus == 'error'){
			return Constants.REPORT_STAUS.inProcess;
		}else if(item.downloadReportStatus == 'failed'){
			return Constants.REPORT_STAUS.failed;
		}else if(item.downloadReportStatus == 'completed'){
			return Constants.REPORT_STAUS.completed;
		}
	}

	onTableDropDownItemClick = (item, dropdownItem) => {
		if (item.label === "Generate Report") {
			this.generateReport(dropdownItem.assignmentId);
		}
	}

	groupSetDropdownClick = (selectedItem, selectedIndex) => {
		let filterGroupArr = this.state.groupSetDropDownArr;
		let placeholder = "";

		for (const [i,value] of filterGroupArr.entries()) {
			if (i === selectedIndex) {
				filterGroupArr[i].isSelected = true;
				placeholder = value.label;
			} else {
				filterGroupArr[i].isSelected = false;
			}
		}

		this.setState({	
				currentPageNo: 1,
				groupSetPlaceholder: placeholder,
				groupSetId:selectedItem.groupSetId,
				groupSetName:selectedItem.groupSetName
			},() => {
				this.getAssignmentList();
			});
	}

	sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState(
			{
				sort: sortObj.sortTemp,
				sortDir: sortObj.sortDirTemp,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	handleExecuteTaskList = (assignmentId) => {
		this.props.history.push(Constants.URL_EXECUTETASK + "/" + assignmentId);
	};
	/*******************API CALLS*******************/
	getInitData = () => {
		this.setState({
			showLoader: true,
			showDetailsDialog:false,
            selectedItem:"",
		});

			Promise.all([
				fetch(Constants.GetCompletedReports, {
					method: "POST",
					mode: "cors",
					body: new URLSearchParams({
						email: userDetails.email,
						accessToken: userDetails.accessToken,
						pageNo: this.state.currentPageNo,
						resultsize: this.state.resultSize,
						// status:this.state.status,
						sort: this.state.sort,
						sortDir: this.state.sortDir,
						
					}),
				}),
				fetch(Constants.GetUserMenu, {
					method: "POST",
					mode: "cors",
					body: new URLSearchParams({
						email: userDetails.email,
						accessToken: userDetails.accessToken,
					}),
				}),
			])
			.then(([masterRes, menuRes]) => {
				return Promise.all([masterRes.json(), menuRes.json()]);
			})
			.then(([masterRes, menuRes]) => {
				let datalistTemp = [];
				let totalCountTemp = 0;
				let deptTemp = [];
				let menusTemp = {};
				let companyTemp = {};
				let groupSetTemp = [];
				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					datalistTemp = masterRes.data.result;
					totalCountTemp = masterRes.data.count;
					groupSetTemp = masterRes.data.groupSets;
				} else {
				}
				if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
					deptTemp = menuRes.data.departments;
					menusTemp = menuRes.data.menus;
					companyTemp = menuRes.data.company;

				} else {
				}



				let menusTemp2 = GetOrderedMasterTabMenuReports(menusTemp);

				//Filter Dept array
				let filterDeptArr = [];
				let selectedFilterDepartmentId = "-100";
				let selectedFilterDepartmentName = "All";
				if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
				} else {
					let obj = {
						departmentId: "-100",
						name: departmentFilterDropdownDefaultValue,
						isSelected: true,
					};
					filterDeptArr = [...deptTemp];
					filterDeptArr.splice(0, 0, obj);
				}
				if (!isEmptyArray(groupSetTemp) && groupSetTemp.length !== 1) {
					Object.values(groupSetTemp).map((item)=>{
						if(!groupSetArr.some(element => element.displayName === item.displayName)){
							groupSetArr.push({
								label:item.displayName,
								isSelected:false,
								...item
							})
						}
					})
				}


				this.setState({
					componentDidMountFlag: true,
					showLoader: false,
					datalist: datalistTemp,
					totalCount: totalCountTemp,
					menus: menusTemp2,
					company:companyTemp,
					groupSetDropDownArr: groupSetArr,
					filterDeptArr: filterDeptArr,
					selectedFilterDepartmentId: selectedFilterDepartmentId,
					selectedFilterDepartmentName: selectedFilterDepartmentName,
				});
			});
	};

	getAssignmentList = () => {
		this.setState({
			showLoader: true,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			pageNo: this.state.currentPageNo,
			search: this.state.searchkey,
			resultsize: this.state.resultSize,
			// status:this.state.status,
			sort: this.state.sort,
			sortDir: this.state.sortDir,
		};

		if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue) {
			postParam.departmentId = this.state.selectedFilterDepartmentId;
		}

		if(this.state.groupSetPlaceholder!="All"){
			postParam.groupSetId = this.state.groupSetId;
			postParam.groupSetName = this.state.groupSetName;
		}

		fetch(Constants.GetCompletedReports, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					if (!isEmptyArray(data.data.groupSets) && data.data.groupSets.length !== 1) {
						Object.values(data.data.groupSets).map((item)=>{
							if(!groupSetArr.some(element => element.displayName === item.displayName)){
								groupSetArr.push({
									label:item.displayName,
									isSelected:false,
								})
							}
						})
					}
					this.setState({
						showLoader: false,
						datalist: data.data.result,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						totalCount: 0,
						componentDidMountFlag: true,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};

	onChangePage = (page) => {
		// update state with new page of items
		if (page !== this.state.currentPageNo) {
			this.setState({
				showLoader: true,
			});

			let postParam = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				pageNo: page,
				search: this.state.searchkey,
				resultsize: this.state.resultSize,
				// status:this.state.status,
				sort: this.state.sort,
				sortDir: this.state.sortDir,
			};
			
			if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== "All") {
				postParam.departmentId = this.state.selectedFilterDepartmentId;
			}
			
			if(this.state.groupSetPlaceholder!="All"){
				postParam.groupSetId = this.state.groupSetId;
				postParam.groupSetName = this.state.groupSetName;
			}

			fetch(Constants.GetCompletedReports, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						if (!isEmptyArray(data.data.groupSets) && data.data.groupSets.length !== 1) {
							Object.values(data.data.groupSets).map((item)=>{
								if(!groupSetArr.some(element => element.displayName === item.displayName)){
									groupSetArr.push({
										label:item.displayName,
										isSelected:false,
									})
								}
							})
						}
						this.setState({
							showLoader: false,
							datalist: data.data.result,
							totalCount: data.data.count,
							apiSearchKey: this.state.searchkey,
							currentPageNo: page,
						});
					} else {
						this.setState({
							datalist: [],
							showLoader: false,
							apiSearchKey: this.state.searchkey,
						});
					}
				});
		}
	};

	generateReport = (assignmentId) => {
        fetch(Constants.CreateAssignmentCompletedReports, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
				assignmentId: assignmentId
            }),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
				this.getAssignmentList();
				toast.success("Your reports are getting generated. Kindly check reports after some time.");
            }
        })
    }
	/*******************API CALLS END HERE*******************/

	render() {
		return (
			<Router>
				<main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />

						<div className="dashboard-right-bg position-relative">
							<div className="dashboard-wrapper">
								<Topbar />
								<MasterMenuTabs assignmentId={this.state.assignmentId} masterMenus={this.state.menus} themeSettings={themeSettings} />
								{this.state.showLoader && (
									<div class="loader"></div>
								)}
								{
									// Need to check component did mount flag, other wise until api is called,
									// it shows no items found layout.
									this.state.componentDidMountFlag && (
										<div className="body-wrapper">
											<div className="search-addnew-bg">
												<div className="search-addnew">
													<div className="search-filter-layout">
														<FilterDropDown
															placeholder={this.state.resultSizePlaceholder}
															dropdownArr={this.state.resultSizeArr}
															onDropDownItemClick={this.resultSizeDropdownClick}
															themeSettings={themeSettings}
															mleft={0}
														/>
														<div className="search-col m-l-10">
															<input
																name="searchkey"
																type="text"
																onChange={this.handleChangeSearch}
																placeholder="Search"
																onKeyPress={this.onEnterBtnPress}
																value={this.state.searchkey}
															/>
															<SearchSpan themeSettings={themeSettings} onClick={this.onSearchIconClick} className="material-icons">
																{" "}
																search{" "}
															</SearchSpan>
														</div>
															
															{ this.state.groupSetDropDownArr.length>1 &&
																<FilterDropDown
																	placeholder={this.state.groupSetPlaceholder==="All"?"Grouping":this.state.groupSetPlaceholder}
																	dropdownArr={this.state.groupSetDropDownArr}
																	onDropDownItemClick={this.groupSetDropdownClick}
																	themeSettings={themeSettings}
																	name={"label"}
																	dropdownWidth="200px"
																/>
															}
														{/* <FilterDropDown
															placeholder={this.state.statusPlaceholder}
															dropdownArr={this.state.statusArr}
															onDropDownItemClick={this.statusDropdownClick}
															themeSettings={themeSettings}
														/> */}
														

														{this.state.filterDeptArr.length > 0 && (
															<FilterDropDown
																placeholder={this.state.selectedFilterDepartmentName}
																dropdownArr={this.state.filterDeptArr}
																onDropDownItemClick={this.deptFilterDropdownClick}
																name={"name"}
																themeSettings={themeSettings}
																dropdownWidth={"120px"}
															/>
														)}
													</div>
												</div>
											</div>

											{!isEmptyArray(this.state.datalist) && (
												<div className="common-tab-bg">
													<div className="common-table" style={{ width: "100%" }}>
														<table className="table">
															<thead>
																<tr>
																	<th className="text-center" width={"5%"}>
																		No.
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortReferenceNo)}>
																		<div className="sort-header">
																			Ref No.
																			<span
																				className={
																					this.state.sort === sortReferenceNo ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	{
																		this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
																		<th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortLocation)}>
																			<div className="sort-header">Unit
																			<span className={(this.state.sort === sortLocation)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
																			</div>
																		</th>
																	}
																	{
																		this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
																		<th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortunitAddressList)}>
																			<div className="sort-header">Name
																			<span className={(this.state.sort === sortunitAddressList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
																			</div>
																		</th>
																	}
																	{
																		this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
																		<th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortunitCityList)}>
																			<div className="sort-header">City
																			<span className={(this.state.sort === sortunitCityList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
																			</div>
																		</th>
																	}
																	{
																		this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
																		<th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
																			<div className="sort-header">Client
																			<span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
																			</div>
																		</th>
																	}
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDescription)}>
																		<div className="sort-header">
																			Type
																			<span
																				className={
																					this.state.sort === sortDescription
																						? "material-icons"
																						: "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortArea)}>
																		<div className="sort-header">
																			Scope
																			<span
																				className={
																					this.state.sort === sortArea ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortEndDate)}>
																		<div className="sort-header">
																			End Date
																			<span
																				className={
																					this.state.sort === sortEndDate ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortReportDate)}>
																		<div className="sort-header">
																			Report Date
																			<span
																				className={
																					this.state.sort === sortReportDate ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	
																	<th></th>
																	<th>Report Status</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																{
                                                                    !isEmptyArray(this.state.datalist) &&
																	this.state.datalist.map((item, idx) => {
																	let tempScop = [];
																	if(!isEmptyVariable(item.functionList)){
																		tempScop = item.functionList?.split(",").map((func) => func.split(":-:")[0]);
																	}else if(!isEmptyVariable(item.processList)){
																		tempScop = item.processList.split(",").map((func) => func.split(":-:")[0]);
																	}else if(!isEmptyVariable(item.checkList)){
																		tempScop = item.checkList.split(",").map((func) => func.split(":-:")[0]);
																	}
																	let actionObj = getDropdownActionArrayCompletedReports(item.downloadReportStatus);
    																return (
																			<tr onClick={this.handleShowObservations.bind(this, item.assignmentId)}>

																				<td className="text-center">
																					{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}
																				</td>
																				<td>{item.referenceNo}</td>
																				{
																					this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
																					<td>{truncateString(String(getStringWithSpaceAfterComma(item.unitNameCityList)).split("(")[0],100)}</td>
																				}
																				{
																					this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
																					<td>{item.companyName}</td>
																				}
																				{   
                                                                                    this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF && 
                                                                                    <td>
																				        {isEmptyVariable(item.unitAddressList)? "-": String(item.unitAddressList).split(":-:")[0]}
																				    </td>
                                                                                }
																				{   
                                                                                    this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF && 
                                                                                    <td>
																				        {isEmptyVariable(item.unitCityList)? "-": item.unitCityList}
																				    </td>
                                                                                }
																				
																				<td>{item.assignmentTypeName}</td>
																				{/* <td>{item.assignmentArea}</td> */}
																				<td>{item.assignmentArea == "CheckList" ?
																					isEmptyArray(tempScop)?"-":(`CheckList: ` + tempScop[0]) :
																					isEmptyArray(tempScop)?"-":(tempScop.length==1?tempScop[0]:`${tempScop[0]} + ${tempScop.length-1}`)}
																				</td>
                                                                                <td>{moment(item.endDate).format("DD/MM/YYYY")}</td>
																				<td>{isEmptyVariable(item.reportDate)?"":moment(item.reportDate).format("DD/MM/YYYY")}</td>
																				{!isEmptyVariable(item.isFollowUpFreezed) && item.isFollowUpFreezed=="Y"?
																				<td onClick={(e)=>this.handleCompletedFollowUp(e,item)} href="javascript:void(0);" className="followup-btn"
																				style={{
																				color:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`}}>
																					Follow Up
																				</td>
																				:<td></td>
																				}
																				<td>{this.handleReportStatus(item)}</td>
																				<td className="text-center">
																					{actionObj.actionArr && actionObj.actionArr.length > 0 && (
																						<TableDropDown
																							actionArr={actionObj.actionArr}
																							onDropDownItemClick={this.onTableDropDownItemClick}
																							dropDownId={item}
																							dropdownWidth={actionObj.dropdownWidth}
																							themeSettings={themeSettings}
																						/>
																					)}
																				</td>
																			</tr>
																		);
																	})}
															</tbody>
														</table>
													</div>
												</div>
											)}
											{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
												<div class="no-items-layout">
													<div class="no-items-card">
														<h6>
															{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
														</h6>
													</div>
												</div>
											)}
											<div className="pagination-layout">
												<div className="row custom-row">
													<div className="col-md-9 custom-col">
														{this.state.totalCount > this.state.datalist.length && (
															<Pagination
																totalLength={this.state.totalCount}
																items={this.state.datalist}
																onChangePage={this.onChangePage}
																pageSize={this.state.resultSize}
																currentPageNo={this.state.currentPageNo}
																initialPage={this.state.currentPageNo}
																themeSettings={themeSettings}
															/>
														)}
													</div>
													<div className="col-md-3 custom-col">
														<p>{"Total Records: " + this.state.totalCount}</p>
													</div>
												</div>
											</div>
										</div>
									)
								}
							</div>
						</div>

						{/* <UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterType = {this.state.deleteReactivateMasterType}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        /> */}

						{/* <ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {this.state.deleteReactivateMasterType}
                            masterDataId = {this.state.deleteReactivateId}
                        /> */}

						{/* <ReportDetails
							showDetailsDialog={this.state.showDetailsDialog}
							handleDetailsDialogClose={this.handleDetailsDialogClose}
							selectedItem= {this.state.selectedItem}
							themeSettings={themeSettings}
						/> */}
					</section>
				</main>
			</Router>
		);
	}
}

export default ReportAssignmentList;
