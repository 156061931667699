import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import {DialogSaveButton} from '../../../common/Global/globalStyles.style';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();

const reviewPlaceholderDefaultText = "Select Status";
const sendToUserLevelDefaultText = "Select User Level";

const reviewStatusApproved = "Approved";
const reviewStatusReqChange = "Request Change";

class UpdateReviewStatusDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,

            reviewStatusArr:[
                {
                    status:reviewStatusApproved
                },
                {
                    status:reviewStatusReqChange
                },
            ],
            reviewStatus:reviewPlaceholderDefaultText,
            sendToUserLevel:sendToUserLevelDefaultText,
            sendToUserLevelId:"",
            remarks:"",
            users:[],
            formErrors:{},
            submitClickedAtleastOnce:false,
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showUpdateReviewDialog){
            this.getInitData();
        }
    }

    reviewStatusDropdownClick = (item) => {
        let formErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            //since the item is selected from dropdown, make the error span empty
            formErrorsTemp['reviewStatus'] = "";

            if(item.status === reviewStatusApproved){
                formErrorsTemp['remarks'] = "";
                formErrorsTemp['sendToUserLevel'] = "";
            }
        }

        if(item.status === reviewStatusApproved){
            this.setState({
                reviewStatus:item.status,
                sendToUserLevel:sendToUserLevelDefaultText,
                sendToUserLevelId:"",
                formErrors:formErrorsTemp
            })
        }else{
            this.setState({
                reviewStatus:item.status,
                formErrors:formErrorsTemp
            })
        }
        
    }

    sendToUserLevelDropdownClick = (item) => {
        let formErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce && this.state.reviewStatus === reviewStatusReqChange){
            formErrorsTemp['sendToUserLevel'] = "";
        }

        this.setState({
            sendToUserLevel:item.label,
            sendToUserLevelId:item.sendToUserLevel,
            formErrors:formErrorsTemp
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.ReviewFormDataProcesses,
        {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams(
            {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                rootProcessId:this.props.masterDataId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let users = data.data.users;

                if(!isEmptyArray(users)){
                    users.map((value,idx) => {
                        users[idx].label = "Level "+value.sendToUserLevel+" ("+value.sendToUserEmail+")"
                    })
                }

                this.setState({
                    reviewStatus:reviewPlaceholderDefaultText,
                    remarks:"",
                    formErrors:{},
                    showLoader:false,
                    users:users
                })
            }else{
                this.setState({
                    reviewStatus:reviewPlaceholderDefaultText,
                    remarks:"",
                    formErrors:{},
                    showLoader:false,
                    users:[]
                })
            }
        });
    }

    updateReviewStatusApi = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.reviewStatus) || this.state.reviewStatus === reviewPlaceholderDefaultText){
            isError = true;
            FormErrorsTemp['reviewStatus'] = "Please select review status";
        }

        if(this.state.reviewStatus === reviewStatusReqChange){
            if(isEmptyVariable(this.state.remarks)){
                isError = true;
                FormErrorsTemp['remarks'] = "Please enter remarks";
            }
            if(isEmptyVariable(this.state.sendToUserLevel) || this.state.sendToUserLevel === sendToUserLevelDefaultText){
                isError = true;
                FormErrorsTemp['sendToUserLevel'] = "Please select level";
            }
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });
        }else{
            let postParams = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                rootProcessId:this.props.masterDataId,
                reviewLevel:this.props.reviewLevel,
                remark:this.state.remarks,
            }

            if(this.state.reviewStatus === reviewStatusReqChange){
                postParams.sendToUserLevel = this.state.sendToUserLevelId;
                postParams.reviewStatus = Constants.REVIEW_STATUS_REQ_CHANGE;
            }else if(this.state.reviewStatus === reviewStatusApproved){
                postParams.reviewStatus = Constants.REVIEW_STATUS_APPROVED;
            }

            fetch(Constants.updateReviewStatusProcesses,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParams)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        reviewStatus:reviewPlaceholderDefaultText,
                        remarks:"",
                        formErrors:{},
                    },() => {
                        this.props.handleUpdateReviewDialogClose(true);
                    });
                }else{
                    // let formErrTemp = {};
                    // formErrTemp['apiErr'] = data.responseMessage;
                    toast.error(data.responseMessage);
                    this.setState({
                        showLoader:false,
                        // formErrors:formErrTemp,
                    })

                    // setTimeout(function(){
                    //     this.setState({
                    //         formErrors:{},
                    //     });
                    // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                }
            });
        }
    }

    render() {
        return(
            <Modal className="update-review-status-dialog custom-dialog" 
            show={this.props.showUpdateReviewDialog} backdrop="static">
                <Modal.Header>
                    <h5>Update Review Status</h5>
                    <button 
                        onClick={this.props.handleUpdateReviewDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }
                    <div className="addnew-modal-form">
                        <div className="row custom-row">
                            <div className="col-md-12 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Review Status*</p>
                                    <RegularDropdown 
                                        placeholder={this.state.reviewStatus}
                                        dropdownArr={this.state.reviewStatusArr}
                                        labelParam="status"
                                        onDropDownItemClick={this.reviewStatusDropdownClick}
                                        isFocusRequired={true}
                                        defaultPlaceholderDropDown={reviewPlaceholderDefaultText}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['reviewStatus']) &&
                                        <span className="cm-error">{this.state.formErrors['reviewStatus']}</span>
                                    }
                                </div>
                            </div>
                            {
                                this.state.reviewStatus === reviewStatusReqChange &&
                                <div className="col-md-12 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Send To*</p>
                                        <RegularDropdown 
                                            placeholder={this.state.sendToUserLevel}
                                            dropdownArr={this.state.users}
                                            labelParam="label"
                                            onDropDownItemClick={this.sendToUserLevelDropdownClick}
                                            defaultPlaceholderDropDown={sendToUserLevelDefaultText}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['sendToUserLevel']) &&
                                            <span className="cm-error">{this.state.formErrors['sendToUserLevel']}</span>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="col-md-12 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Remarks</p>
                                    <textarea type="text" placeholder="Enter Remarks"
                                    name="remarks" value={this.state.remarks} onChange={this.handleChange}/>
                                    {
                                        !isEmptyVariable(this.state.formErrors['remarks']) &&
                                        <span className="cm-error">{this.state.formErrors['remarks']}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* {
                            !isEmptyVariable(this.state.formErrors['apiErr']) &&
                            <span className="cm-error">{this.state.formErrors['apiErr']}</span>
                        } */}
                    </div>
                    
                    <div className="modal-close-save">
                        <div className="close-save-col">
                            <button onClick={this.props.handleUpdateReviewDialogClose} type="button" className="modal-close">Cancel</button>
                            <DialogSaveButton 
                            themeSettings={this.props.themeSettings}
                            onClick={this.updateReviewStatusApi} type="button">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default UpdateReviewStatusDialog;