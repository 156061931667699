import React, { Component } from "react";
import * as Constants from "../../common/Global/constants";
import {
    TaskTagButtonsLayout,
    PageSaveButton,
    FilterCheckBox,
    AddNewButtonLayout,
} from "../../common/Global/globalStyles.style";
import Sidebar from "../../common/SidebarCollapse";
import Topbar from "../../common/Topbar";
import AlertDialog from "../../common/AlertDialog";
import AlertWithInput from "../../common/AlertDialog/alertwithInput";
import MasterMenuTabs from "./topmenu";
import RegularDropdown from "../../common/DropdownMenus/RegularDropdown";
import {
    getLocalStorageVariables,
    getThemeSettingsVariables,
    removeHtmlTags,
    GetOrderedMasterTabMenuTask,
    isEmptyVariable,
    isEmptyArray,
    isJsonString,
} from "../../common/Global/commonFunctions";
import { BrowserRouter as Router } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ListTasksFromHeading from "./listTasksFromProcessHeadingDialog";
import ProcessCardListDialog from "./ChangeProcess/processCardList";
import * as TemplateConstants from "../../common/Global/templateConstants";
import MultiSelectComponentTemplate from "./Common/multiSelectComponent";
import DatePickerComponent from "./Common/dateSelectComponent";
import SelectFunctionDialog from "./selectFunction";
import SelectIndustryDialog from "./selectIndustry";
import SelectDocumentDialog from "./selectDocument";
import SelectRiskDialog from "./selectRisk";
import LeftSideSelectDialog from "./Common/selectDropdownValues";
import TaskDetailsLayout from './taskDetailsWithTemplates';

const themeSettings = getThemeSettingsVariables();
const userDetails = getLocalStorageVariables();

//if the status is draft or created then there will be auto save other wise no...
const draftStatus = "Draft";

class AddEditTask extends Component {
    constructor(props) {
        super(props);

        let processId = "";
        let isEditTask = false;
        let assignmentTypeId = "";
        if (this.props.location.pathname.includes(Constants.URL_ADDTASK_WITH_TEMPLATES)) {
            let ids = this.props.match.params.id;
            let arr = ids.split("-");

            processId = arr[0];
            assignmentTypeId = arr[1];
        }

        let taskId = "";
        let item = this.props.location.state;
        if (this.props.location.pathname.includes(Constants.URL_EDITTASK_WITH_TEMPLATES)) {
            let ids = this.props.match.params.id;
            let arr = ids.split("-");

            taskId = arr[0];
            assignmentTypeId = arr[1];
            isEditTask = true;
        }
        // let item = props.location.state;
        this.state = {
            menus: {},
            user: {},
            departmentArr: [],
            deptDefaultRisk: {},
            businessRisk: [],
            componentDidMountFlag: true,
            datalist: [],
            datalistLeft: [],
            processName: "",
            assignmentTypeId: assignmentTypeId,
            taskId: taskId,
            processId: processId,
            isEditTask: isEditTask,
            status: "Draft",
            templateFieldValuesMap: {},
            // formErrors: {},
            showProcessTasksDialog: false,
            formCustomRisk: "",
            formCustomRisk_isFocused: false,

            //TAGS
            //Functions
            showSelectFunctionDialog: false,
            selectedFunctionObjArr: [],
            //Industries
            showSelectIndustryDialog: false,
            selectedIndustryObjArr: [],
            industrySelectionFlag: "",
            //Document
            showSelectDocumentDialog: false,
            selectedDocumentObjArr: [],
            document_NR: "",
            //Assignment Type
            showSelectAssigmentTypeDialog: false,
            selectedAssigmentTypeObjArr: [],
            //Risk
            showSelectRiskDialog: false,
            selectedRiskObj: "",

            showAlertDialog: false,
            operationType: "",
            deleteReactivateId: "",
            deleteReactivateMasterType: "",
            alertDialogMessage: "",

            showSelectDropdownDialog: false,
            templateFieldItem: {},

            showProcessCardsDialog: false,
            usedInAssignment: "",

            isFirstRowTypeDisable: "N",
            isUsedForExec: "N",
            firstRowType: "",
            showTaskDetailsLayout:false,
        };
    }

    componentDidMount() {
        this.initData();
    }

    handleShowTasksDialog = () => {
        this.setState({
            showProcessTasksDialog: true,
        });
    };

    handleTasksDialogClose = () => {
        this.setState({
            showProcessTasksDialog: false,
        });
    };

    handleQuillFocus = (focusIndex) => {
        let tempArr = this.state.datalist.map((item, index) => {
            if (index === focusIndex) {
                item.isFocused = true;
            } else {
                item.isFocused = false;
            }
            return item;
        });
        this.setState({
            datalist: tempArr,
        });
    };

    handleChangeReactQuill = (focusedIndex, value) => {
        let tempArr = this.state.datalist;
        tempArr[focusedIndex].fieldValue = value;

        this.setState({
            datalist: tempArr,
        });
    };

    handleFocus = () => {
        let tempArr = this.state.datalist.map((item) => {
            item.isFocused = false;
            return item;
        });
        this.setState({
            datalist: tempArr,
        });
    };

    handleCheck = (e) => {
        let isChecked = e.target.checked;
        let name = e.target.name;
        let arr = name.split(":-:");
        let tempArr = this.state.datalist;
        let index = parseInt(arr[1]);

        let item = tempArr[index];
        item.isNRChecked = isChecked ? "Y" : "N";
        tempArr.splice(index, 1, item);
        this.setState({
            datalist: tempArr,
        });
    };
    modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            [
                "bold",
                "italic",
                "underline",
                "strike",
                { color: [] },
                { background: [] },
            ],
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }, "link"],
            ["clean"],
        ],
    };

    //Custom Risk React Quill Functions
    handleChangeFormCustomRisk = (value) => {
        this.setState({
            formCustomRisk: value,
        });
    };

    onCustomRiskFocus = () => {
        this.handleFocus();
        this.setState({
            formCustomRisk_isFocused: true,
        });
    };
    onCustomRiskBlur = () => {
        this.setState({
            formCustomRisk_isFocused: false,
        });
    };

    addSelectedValues = (value, index) => {
        let tempArr = this.state.datalist;
        tempArr[index].fieldValue.push(value);
        this.setState({
            datalist: tempArr,
        });
    };

    removeSelectedValues = (value, index) => {
        let tempArr = this.state.datalist;
        let fieldValueArr = tempArr[index].fieldValue;

        let formCustomRisk = this.state.formCustomRisk;

        if (!isEmptyVariable(value)) {
            let fieldValueIndex = fieldValueArr.indexOf(value);
            if (fieldValueIndex !== -1) {
                fieldValueArr.splice(fieldValueIndex, 1);
            }
            // If "Other" in Risk/Impact is removed, make formCustomRisk empty
            if (value === -1) {
                formCustomRisk = "";
            }
        }
        this.setState({
            datalist: tempArr,
            formCustomRisk: formCustomRisk,
        });
    };

    onDropDownItemClick = (item, index) => {
        this.handleFocus();
        let tempArr = this.state.datalist;
        tempArr[index].fieldValue = item.value;

        this.setState({
            datalist: tempArr,
        });
    };

    onDateChange = (dateValue, index) => {
        let tempArr = this.state.datalist;
        tempArr[index].fieldValue = dateValue;
        this.setState({
            datalist: tempArr,
        });
    };

    onAddTableClick = (index, isUsedForExec) => {
        this.setState({
            isUsedForExec: isUsedForExec,
            showAlertWithInputDialog: true,
            alertWithInputDialogMessage:
                isUsedForExec !== "Y"
                    ? "Enter the number of Rows and Columns"
                    : "Enter the number of Rows",
            addToTableIndex: index,
        });
    };

    handleAlertWithInputDialogClose = () => {
        this.setState({
            showAlertWithInputDialog: false,
            alertWithInputDialogMessage: "",
        });
    };
    handleAddIntanceTable = (rows, cols, excelData) => {
        let tableArray = [];

        for (let i = 0; i < rows; i++) {
            tableArray[i] = [];
            let j = 0;
            for (j = 0; j < cols; j++) {
                if(!isEmptyArray(excelData)){
                    tableArray[i][j]= excelData[i][j] ?? "";
                }else{
                    tableArray[i][j] = "";
                }
            }
            tableArray[i][j] = "Action";
        }

        let tempArr = this.state.datalist;
        tempArr[this.state.addToTableIndex].fieldValue = tableArray;
        tempArr[this.state.addToTableIndex].isTableAdded = true;

        this.setState({
            showAlertWithInputDialog: false,
            alertWithInputDialogMessage: "",
            tableArray: tableArray,
            datalist: tempArr,
            addToTableIndex: "",
        });
    };

    handleTableInputBlur = (rowIndex, colIndex, index, e) => {
        const value = e.currentTarget.textContent;

        let tempArr = this.state.datalist;
        let tableArray = tempArr[index].fieldValue;

        tableArray[rowIndex][colIndex] = value;

        this.setState({
            datalist: tempArr,
        });
    };

    addNewRow = (index, e) => {
        let tempArr = this.state.datalist;
        let tableArray = tempArr[index].fieldValue;
        let singleRow = [];
        let colLength = tableArray[0].length;
        for (let i = 0; i < colLength; i++) {
            if (i === colLength - 1) {
                singleRow.push("Action");
            } else {
                singleRow.push("");
            }
        }

        tableArray.push(singleRow);
        tempArr[index].fieldValue = tableArray;

        this.setState({
            datalist: tempArr,
        });
    };

    addNewColumn = (index, e) => {
        let tempArr = this.state.datalist;
        let tableArray = tempArr[index].fieldValue;
        let rowLength = tableArray.length;
        let colLength = tableArray[0].length;
        let newArr = [];

        for (let i = 0; i < rowLength; i++) {
            let rowArray = tableArray[i];
            rowArray.splice(colLength - 1, 0, "");
            newArr.push(rowArray);
        }
        tempArr[index].fieldValue = newArr;
        this.setState({
            datalist: tempArr,
        });
    };

    deleteRow = (rowIndex, index, e) => {
        let tempArr = this.state.datalist;
        let tableArray = tempArr[index].fieldValue;
        tableArray.splice(rowIndex, 1);
        tempArr[index].fieldValue = tableArray;
        this.setState({
            datalist: tempArr,
        });
    };

    deleteColumn = (colIndex, index, e) => {
        let tempArr = this.state.datalist;
        let tableArray = tempArr[index].fieldValue;
        let tableArrayTemp = [];

        tableArray.forEach((rowItem, rowIndex) => {
            rowItem.splice(colIndex, 1);
            tableArrayTemp[rowIndex] = [...rowItem];
        });
        tempArr[index].fieldValue = tableArrayTemp;

        this.setState({
            datalist: tempArr,
        });
    };

    handleDeleteTableClickAlert = (fieldName, index, e) => {
        e.stopPropagation();
        this.setState({
            operationType: "Delete",
            alertDialogHeading: "Delete Field",
            proceedBtnLabel: "Delete",
            deleteReactivateId: index,
            showAlertDialog: true,
            alertDialogMessage:
                "Are you sure you want to delete field- " + fieldName + "?",
        });
    };

    handleAlertDialogClose = () => {
        this.setState({
            showAlertDialog: false,
            alertDialogMessage: "",
        });
    };

    handleDeleteTable = () => {
        let tempArr = this.state.datalist;
        tempArr[this.state.deleteReactivateId].fieldValue = [];
        tempArr[this.state.deleteReactivateId].isTableAdded = false;

        this.setState({
            datalist: tempArr,
            deleteReactivateId: "",
            operationType: "",
            alertDialogHeading: "",
            proceedBtnLabel: "",
            alertDialogMessage: "",
            showAlertDialog: false,
        });
    };
    //Industries
    handleSelectIndustryDialogClose = () => {
        this.setState({
            showSelectIndustryDialog: false,
        });
    };
    handleSelectIndustryDialogSaveClose = (
        selectedIndustryObjArr,
        selectionFlag
    ) => {
        this.setState({
            showSelectIndustryDialog: false,
            selectedIndustryObjArr: selectedIndustryObjArr,
            industrySelectionFlag: selectionFlag,
        });
    };
    handleSelectIndustryDialogShow = () => {
        let focusObj = this.handleFocus();
        this.setState({
            showSelectIndustryDialog: true,
        });
    };
    //Document
    handleSelectDocumentDialogClose = () => {
        this.setState({
            showSelectDocumentDialog: false,
        });
    };
    handleSelectDocumentDialogSaveClose = (
        selectedDocumentObjArr,
        document_NR
    ) => {
        this.setState({
            showSelectDocumentDialog: false,
            selectedDocumentObjArr: selectedDocumentObjArr,
            document_NR: document_NR,
        });
    };
    handleSelectDocumentDialogShow = () => {
        this.handleFocus();
        this.setState({
            showSelectDocumentDialog: true,
        });
    };
    //Functions
    handleSelectFunctionDialogClose = () => {
        this.setState({
            showSelectFunctionDialog: false,
        });
    };
    handleSelectFunctionDialogSaveClose = (selectedFunctionObjArr) => {
        this.setState({
            showSelectFunctionDialog: false,
            selectedFunctionObjArr: selectedFunctionObjArr,
        });
    };
    handleSelectFunctionDialogShow = () => {
        this.handleFocus();
        this.setState({
            showSelectFunctionDialog: true,
        });
    };
    //Default Risk
    handleSelectRiskDialogClose = () => {
        this.setState({
            showSelectRiskDialog: false,
        });
    };
    handleSelectRiskDialogSaveClose = (selectedRiskObj) => {
        this.setState({
            showSelectRiskDialog: false,
            selectedRiskObj: selectedRiskObj,
        });
    };
    handleSelectRiskDialogShow = () => {
        //User should select the department before selecting default risk
        let focusObj = this.handleFocus();
        if (isEmptyVariable(this.state.selectedDepartmentId)) {
            this.setState({
                showAlertDialogInfo: true,
                alertDialogMessageInfo: "Please select the deparment",

                ...focusObj,
            });
        } else {
            this.setState({
                showSelectRiskDialog: true,

                ...focusObj,
            });
        }
    };
    //Left Side Dialog Functions
    handleSelectDropdownDialogShow = (item, index) => {
        this.setState({
            showSelectDropdownDialog: true,
            templateFieldItem: item,
            deleteReactivateId: index,
        });
    };
    handleSelectDropdownDialogClose = () => {
        this.setState({
            showSelectDropdownDialog: false,
            editTemplateItem: {},
            deleteReactivateId: "",
        });
    };

    dialogSelectedValues = (selectedValObj, arrIndex) => {
        //dialog is for left side options only
        let tempArr = this.state.datalistLeft;
        if (
            selectedValObj.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT
        ) {
            tempArr[arrIndex].fieldValue = selectedValObj.selectedValues[0];
        } else if (
            selectedValObj.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT
        ) {
            tempArr[arrIndex].fieldValue = selectedValObj.selectedValues;
        }

        this.setState({
            datalistLeft: tempArr,

            showSelectDropdownDialog: false,
            editTemplateItem: {},
            deleteReactivateId: "",
        });
    };

    handleProcessCardsDialogClose = (reloadFlag, processId, processName) => {
        if (reloadFlag === true) {
            this.setState({
                showProcessCardsDialog: false,
                processName: processName,
                processId: processId,
            });
        } else {
            this.setState({
                showProcessCardsDialog: false,
            });
        }
    };

    handleshowProcessCardsDialog = () => {
        if (this.state.usedInAssignment === "Y") {
            this.setState({
                showAlertDialogInfo: true,
                alertDialogMessageInfo:
                    "Process can not be changed for Task, since the Task is already used in an Assignment",
            });
        } else if (this.state.usedInAssignment === "N") {
            this.setState({
                showProcessCardsDialog: true,
            });
        }
    };
    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo: false,
            alertDialogMessageInfo: "",
        });
    };
    onCancelClick = () => {
        // this.props.history.replace(Constants.URL_VIEWTASKS+"/"+this.state.processId,{
        //     showTaskDetailsLayout:false
        // })
        this.props.history.goBack();
    }
    /************************API CALLS****************************/

    initData = () => {
        this.setState({
            showLoader: true,
        });

        if (this.state.isEditTask) {
            Promise.all([
                fetch(Constants.GetTaskCreateMasterData, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                        taskId: this.state.taskId,
                    }),
                }),
                fetch(Constants.GetTaskDetails, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                        taskId: this.state.taskId,
                    }),
                }),
                fetch(Constants.GetUserMenu, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                    }),
                }),
                fetch(Constants.GetTemplates, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                        templateType: TemplateConstants.TEMPLATE_TYPE_TASK,
                        assignmentTypeId: this.state.assignmentTypeId,
                    }),
                }),
            ])
                .then(([masterRes, detailsRes, menuRes, generalRes]) => {
                    return Promise.all([
                        masterRes.json(),
                        detailsRes.json(),
                        menuRes.json(),
                        generalRes.json(),
                    ]);
                })
                .then(([masterRes, detailsRes, menuRes, generalRes]) => {
                    let menusTemp = {};
                    let userTemp = {};
                    let businessRisks = [];
                    let deptArrTemp = [];
                    let selectedDeptId = "";
                    let deptDefaultRisk = [];
                    let businessRiskArr = [];
                    let usedInAssignment = "";

                    let datalistTemp = [];
                    let datalistLeftTemp = [];
                    let datalist = [];
                    let datalistLeft = [];
                    let templateObj = {};
                    let templateName = "";
                    let templateId = "";
                    let templateFieldValuesMap = {};
                    let formCustomRisk = "";

                    if (
                        masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                    ) {
                        localStorage.clear();
                        window.location = "/";
                    } else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
                        let businessRiskObj = {
                            businessRiskId: -1,
                            shortName: "",
                            name: "Other",
                            description: "",
                        };
                        businessRisks = masterRes.data.businessRisk;
                        businessRisks.push(businessRiskObj);
                        //add property "displayname: name (description)" to businessRisk array
                        businessRisks.forEach((item) => {
                            if (item.name !== "Other") {
                                item.displayName =
                                    item.name +
                                    (!isEmptyVariable(item.description)
                                        ? ": " + item.description
                                        : "");
                            } else {
                                item.displayName = item.name;
                            }
                            businessRiskArr.push(item);
                        });

                        deptArrTemp = masterRes.data.departments;

                        deptDefaultRisk = masterRes.data.deptDefaultRisk;
                        selectedDeptId = masterRes.data.selectedDepartmentId;
                    } else {
                    }

                    if (
                        menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                    ) {
                        localStorage.clear();
                        window.location = "/";
                    } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                        menusTemp = menuRes.data.menus;
                        userTemp = menuRes.data.user;
                    } else {
                        menusTemp = {};
                    }

                    //Order the menu tabs in a specific way
                    let menusTemp2 = GetOrderedMasterTabMenuTask(menusTemp);

                    let task = {};
                    if (
                        detailsRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        detailsRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                    ) {
                        localStorage.clear();
                        window.location = "/";
                    } else if (detailsRes.responseCode === Constants.CODE_SUCCESS) {
                        usedInAssignment = detailsRes.data.usedInAssignment;

                        let taskObj = detailsRes.data.task;
                        selectedDeptId = taskObj?.departmentId;
                        let processId = "";
                        let processName = "";

                        let tagList = taskObj.tagList;
                        let selectedDocumentObjArr = [];
                        let document_NR = "";
                        let selectedFunctionObjArr = [];
                        let selectedAssigmentTypeObjArr = [];
                        let selectedIndustryObjArr = [];
                        let industrySelectionFlag = "";
                        let selectAllExceptIds = "";

                        taskObj.templateFieldValues.forEach((item) => {
                            templateFieldValuesMap[JSON.stringify(item.fieldId)] = item;
                        });

                        if (
                            !isEmptyVariable(taskObj.industrySelectAll) &&
                            taskObj.industrySelectAll === "Y"
                        ) {
                            industrySelectionFlag = "selectAll";
                        }

                        if (
                            !isEmptyVariable(taskObj.industrySelectAllExcept) &&
                            taskObj.industrySelectAllExcept === "Y"
                        ) {
                            industrySelectionFlag = "selectAllExcept";
                            selectAllExceptIds = taskObj.industrySelectAllExceptIds;

                            selectAllExceptIds.split(",").forEach((item) => {
                                let industryObj = {
                                    industryId: item,
                                    industryName: "",
                                };
                                selectedIndustryObjArr.push(industryObj);
                            });
                        }

                        //SAMPLE REPONSE
                        //"tagList":"assertion:4:Completeness3:N,assertion:1:Completeness:N,assignmentType:4:IA Assignment from L3:N,
                        // assignmentType:1:Sox Assignments From L3:N,document:55:Purchase New 6:N,document:51:Purchase 2:N,
                        // function:30:Import Purchase:N,function:29:Local Purchase:N,industry:34:Goods 1.2.1:N,industry:30:Goods 1.2:N,
                        // process:23:Purchase Order:N",
                        if (!isEmptyVariable(tagList)) {
                            let tagArr = tagList.split(",");

                            tagArr.map((item) => {
                                let individualTagSplitArr = item.split(":");

                                if (!isEmptyVariable(individualTagSplitArr[0])) {
                                    switch (individualTagSplitArr[0]) {
                                        case "document":
                                            if (individualTagSplitArr[3] === "Y") {
                                                document_NR = "Y";
                                            } else {
                                                let obj = {
                                                    documentId: individualTagSplitArr[1],
                                                    name: individualTagSplitArr[2],
                                                };
                                                selectedDocumentObjArr.push(obj);
                                            }
                                            break;
                                        case "function":
                                            let functionObj = {
                                                functionId: individualTagSplitArr[1],
                                                functionName: individualTagSplitArr[2],
                                            };
                                            selectedFunctionObjArr.push(functionObj);

                                            break;
                                        case "industry":
                                            if (isEmptyVariable(industrySelectionFlag)) {
                                                let industryObj = {
                                                    industryId: individualTagSplitArr[1],
                                                    industryName: individualTagSplitArr[2],
                                                };
                                                selectedIndustryObjArr.push(industryObj);
                                            }
                                            break;
                                        case "assignmentType":
                                            let assignmentTypeObj = {
                                                id: individualTagSplitArr[1],
                                                name: individualTagSplitArr[2],
                                            };
                                            selectedAssigmentTypeObjArr.push(assignmentTypeObj);

                                            break;
                                        case "process":
                                            processId = individualTagSplitArr[1];

                                            processName = individualTagSplitArr[2];
                                            break;
                                    }
                                }
                            });
                        }

                        //Now Risk
                        let riskObj = {
                            riskLevelId: taskObj.riskLevelId,
                            riskLevel: taskObj.riskLevel,
                        };

                        task = {
                            processId: processId,
                            processName: processName,

                            document_NR: document_NR,
                            selectedDocumentObjArr: selectedDocumentObjArr,
                            selectedIndustryObjArr: selectedIndustryObjArr,
                            industrySelectionFlag: industrySelectionFlag,
                            selectedFunctionObjArr: selectedFunctionObjArr,
                            selectedAssigmentTypeObjArr: selectedAssigmentTypeObjArr,

                            selectedRiskObj: riskObj,
                            status: taskObj.status,
                            usedInAssignment: usedInAssignment,
                        };
                        //Get Other Risk/Impact Value
                        if (!isEmptyVariable(taskObj.customBusinessRisk)) {
                            formCustomRisk = taskObj.customBusinessRisk;
                        }
                    }

                    if (
                        generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                    ) {
                        localStorage.clear();
                        window.location = "/";
                    } else if (generalRes.responseCode === Constants.CODE_SUCCESS) {
                        templateObj = generalRes.data.result[0];
                        if (
                            !isEmptyVariable(templateObj.templateFields) &&
                            isJsonString(templateObj.templateFields)
                        ) {
                            datalistTemp = JSON.parse(templateObj.templateFields);
                        }
                        if (
                            !isEmptyVariable(templateObj.templateFieldsLeft) &&
                            isJsonString(templateObj.templateFieldsLeft)
                        ) {
                            datalistLeftTemp = JSON.parse(templateObj.templateFieldsLeft);
                        }

                        templateName = templateObj.templateName;
                        templateId = templateObj.templateId;

                        //Add fieldValue and isNRChecked from ValuesMap to datalist
                        datalist = datalistTemp.map((item) => {
                            if (
                                item.fieldType ===
                                TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI ||
                                item.fieldType === TemplateConstants.FIELD_TYPE_TABLE
                            ) {
                                if (
                                    !isEmptyVariable(
                                        templateFieldValuesMap[item.fieldId]?.value
                                    ) &&
                                    templateFieldValuesMap[item.fieldId]?.valueNR !== "Y" &&
                                    isJsonString(templateFieldValuesMap[item.fieldId]?.value)
                                ) {
                                    item.fieldValue = JSON.parse(
                                        templateFieldValuesMap[item.fieldId]?.value
                                    );
                                    if (item.fieldType === TemplateConstants.FIELD_TYPE_TABLE) {
                                        item.isTableAdded = true;
                                    }
                                } else {
                                    item.fieldValue = [];
                                }
                            } else {
                                if (templateFieldValuesMap[item.fieldId]?.valueNR !== "Y") {
                                    item.fieldValue = templateFieldValuesMap[item.fieldId]?.value;
                                }
                            }

                            item.isNRChecked = templateFieldValuesMap[item.fieldId]?.valueNR;
                            return item;
                        });

                        datalistLeft = datalistLeftTemp.map((item) => {
                            if (
                                item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT
                            ) {
                                if (
                                    !isEmptyVariable(
                                        templateFieldValuesMap[item.fieldId]?.value
                                    ) &&
                                    templateFieldValuesMap[item.fieldId]?.valueNR !== "Y" &&
                                    isJsonString(templateFieldValuesMap[item.fieldId]?.value)
                                ) {
                                    item.fieldValue = JSON.parse(
                                        templateFieldValuesMap[item.fieldId]?.value
                                    );
                                } else {
                                    item.fieldValue = [];
                                }
                            } else {
                                if (templateFieldValuesMap[item.fieldId]?.valueNR !== "Y") {
                                    item.fieldValue = templateFieldValuesMap[item.fieldId]?.value;
                                }
                            }
                            item.isNRChecked = templateFieldValuesMap[item.fieldId]?.valueNR;
                            return item;
                        });
                    } else {
                    }

                    this.setState({
                        menus: menusTemp2,
                        user: userTemp,
                        showLoader: false,
                        componentDidMountFlag: true,
                        departmentArr: deptArrTemp,
                        selectedDepartmentId: selectedDeptId,
                        deptDefaultRisk: deptDefaultRisk,
                        businessRisk: businessRiskArr,
                        ...task,

                        datalist: datalist,
                        datalistLeft: datalistLeft,
                        templateName: templateName,
                        templateId: templateId,
                        templateFieldValuesMap: templateFieldValuesMap,
                        formCustomRisk: formCustomRisk,
                    });
                });
        } else {
            Promise.all([
                fetch(Constants.GetTaskCreateMasterData, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                        processId: this.state.processId,
                    }),
                }),
                fetch(Constants.GetUserMenu, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                    }),
                }),
                fetch(Constants.GetTemplates, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                        templateType: TemplateConstants.TEMPLATE_TYPE_TASK,
                        assignmentTypeId: this.state.assignmentTypeId,
                        // status: this.state.status,
                    }),
                }),
            ])
                .then(([response, menuRes, generalRes]) => {
                    return Promise.all([
                        response.json(),
                        menuRes.json(),
                        generalRes.json(),
                    ]);
                })
                .then(([data, menuRes, generalRes]) => {
                    let menusTemp = {};
                    let userTemp = {};
                    let businessRisks = [];
                    let deptArrTemp = [];
                    let selectedDeptId = "";
                    let processName = "";
                    let businessRiskArr = [];

                    let datalistTemp = [];
                    let datalistLeftTemp = [];
                    let datalist = [];
                    let datalistLeft = [];
                    let templateObj = {};
                    let templateName = "";
                    let templateId = "";

                    if (
                        menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                    ) {
                        localStorage.clear();
                        window.location = "/";
                    } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                        menusTemp = menuRes.data.menus;
                        userTemp = menuRes.data.user;
                    } else {
                        menusTemp = {};
                    }

                    if (
                        generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                    ) {
                        localStorage.clear();
                        window.location = "/";
                    } else if (generalRes.responseCode === Constants.CODE_SUCCESS) {
                        templateObj = generalRes.data.result[0];

                        if (
                            !isEmptyVariable(templateObj.templateFields) &&
                            isJsonString(templateObj.templateFields)
                        ) {
                            datalistTemp = JSON.parse(templateObj.templateFields);
                        }
                        if (
                            !isEmptyVariable(templateObj.templateFieldsLeft) &&
                            isJsonString(templateObj.templateFieldsLeft)
                        ) {
                            datalistLeftTemp = JSON.parse(templateObj.templateFieldsLeft);
                        }

                        templateName = templateObj.templateName;
                        templateId = templateObj.templateId;

                        datalist = datalistTemp.map((item) => {
                            if (
                                item.fieldType ===
                                TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI ||
                                item.fieldType === TemplateConstants.FIELD_TYPE_TABLE
                            ) {
                                item.fieldValue = [];
                            }
                            return item;
                        });
                        datalistLeft = datalistLeftTemp.map((item) => {
                            if (
                                item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT
                            ) {
                                item.fieldValue = [];
                            }
                            return item;
                        });
                    } else {
                    }

                    //Order the menu tabs in a specific way
                    let menusTemp2 = GetOrderedMasterTabMenuTask(menusTemp);

                    if (
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                    ) {
                        localStorage.clear();
                        window.location = "/";
                    } else if (data.responseCode === Constants.CODE_SUCCESS) {
                        let businessRiskObj = {
                            businessRiskId: -1,
                            shortName: "",
                            name: "Other",
                            description: "",
                        };
                        businessRisks = data.data.businessRisk;
                        businessRisks.push(businessRiskObj);

                        //add property "displayname: name (description)" to businessRisk array
                        businessRisks.forEach((item) => {
                            if (item.name !== "Other") {
                                item.displayName =
                                    item.name +
                                    (!isEmptyVariable(item.description)
                                        ? ": " + item.description
                                        : "");
                            } else {
                                item.displayName = item.name;
                            }
                            businessRiskArr.push(item);
                        });
                        deptArrTemp = data.data.departments;

                        selectedDeptId = data.data.selectedDepartmentId;
                        processName = data.data.process.processName;
                    } else {
                        this.setState({
                            departmentArr: [],
                            deptDefaultRisk: {},
                            businessRisk: [],
                            showLoader: false,
                            componentDidMountFlag: true,
                        });
                    }

                    this.setState({
                        showLoader: false,
                        componentDidMountFlag: true,
                        menus: menusTemp2,
                        user: userTemp,

                        departmentArr: deptArrTemp,
                        selectedDepartmentId: selectedDeptId,
                        deptDefaultRisk: data.data.deptDefaultRisk,
                        businessRisk: businessRiskArr,
                        processName: processName,

                        datalist: datalist,
                        datalistLeft: datalistLeft,
                        templateName: templateName,
                        templateId: templateId,
                    });
                });
        }
    };

    saveAsDraft = () => {
        this.saveTask("Y");
    };

    submitTask = (btnName) => {
        this.saveTask("N", btnName);
    };

    saveTask = (isDraft, btnName) => {
        this.setState({
            showLoader: true,
        });

        let isError = false;
        let datalist = this.state.datalist;
        let datalistLeft = this.state.datalistLeft;
        this.handleFocus();

        datalist.forEach((item) => {
            if (
                item.fieldType !== TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                item.fieldType !== TemplateConstants.FIELD_TYPE_TABLE &&
                item.isMandatorySelected === "Y"
            ) {
                if (isEmptyVariable(item.fieldValue)) {
                    isError = true;
                    item.valueErr = "Please enter " + item.fieldName + " Value";
                } else if (isEmptyVariable(removeHtmlTags(item.fieldValue))) {
                    isError = true;
                    item.valueErr = "Please enter " + item.fieldName + " Value";
                }
            }
            if (
                (item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI ||
                    item.fieldType === TemplateConstants.FIELD_TYPE_TABLE) && item.isUsedForExec !== "Y" &&
                isEmptyArray(item.fieldValue) &&
                item.isMandatorySelected === "Y"
            ) {
                isError = true;
                item.valueErr = "Please enter " + item.fieldName + " Value";
            }

            if (
                (item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI ||
                    item.fieldType === TemplateConstants.FIELD_TYPE_TABLE) && item.isUsedForExec === "Y" &&
                isEmptyArray(item.fieldValue?.tableArray) &&
                item.isMandatorySelected === "Y"
            ) {
                isError = true;
                item.valueErr = "Please enter " + item.fieldName + " Value";
            }


            //Check for empty table header
            if (
                item.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                !isEmptyArray(item.fieldValue)
            ) {
                let rowHeading = item.fieldValue[0];
                isError = rowHeading.some((rowItem) => isEmptyVariable(rowItem));
                if (isError) {
                    item.valueErr = "Please enter " + item.fieldName + " Header Value";
                }
            }
        });

        datalistLeft.forEach((item) => {
            if (
                item.fieldType !== TemplateConstants.FIELD_TYPE_MULTI_SELECT &&
                item.isMandatorySelected === "Y"
            ) {
                if (isEmptyVariable(item.fieldValue)) {
                    isError = true;
                    item.valueErr = "Please enter Value";
                } else if (isEmptyVariable(removeHtmlTags(item.fieldValue))) {
                    isError = true;
                    item.valueErr = "Please enter Value";
                }
            }
            if (
                item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT &&
                isEmptyArray(item.fieldValue) &&
                item.isMandatorySelected === "Y"
            ) {
                isError = true;
                item.valueErr = "Please enter " + item.fieldName + " Value";
            }
        });

        if (!isError) {
            let postObj = {
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                departmentId: this.state.selectedDepartmentId,
                processId: this.state.processId,
                assignmentTypeId: this.state.assignmentTypeId,
                templateId: this.state.templateId,

                riskLevelId: isEmptyVariable(this.state.selectedRiskObj.riskLevelId)
                    ? ""
                    : this.state.selectedRiskObj.riskLevelId,
                riskLevel: isEmptyVariable(this.state.selectedRiskObj.riskLevel)
                    ? ""
                    : this.state.selectedRiskObj.riskLevel,
                draft: isDraft,
            };

            this.state.datalist.forEach((item, index) => {
                if (
                    (item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI ||
                        (item.fieldType === TemplateConstants.FIELD_TYPE_TABLE && item.isUsedForExec !== "Y")) &&
                    !isEmptyArray(item.fieldValue) &&
                    item.isNRChecked !== "Y"
                ) {
                    postObj[item.fieldId] = JSON.stringify(item.fieldValue);

                }
                else if (
                    (item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI ||
                        (item.fieldType === TemplateConstants.FIELD_TYPE_TABLE && item.isUsedForExec === "Y")) &&
                    !isEmptyArray(item.fieldValue?.tableArray) &&
                    item.isNRChecked !== "Y"
                ) {
                    postObj[item.fieldId] = JSON.stringify(item.fieldValue);
                }

                else if (
                    item.fieldType !== TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                    !isEmptyVariable(item.fieldValue) &&
                    item.isNRChecked !== "Y"
                ) {
                    postObj[item.fieldId] = item.fieldValue;
                    if (item.fieldId + "" === "1") {
                        // postObj.task = item.fieldValue;
                        postObj.Task = item.fieldValue;
                    }
                }
                if (!isEmptyVariable(item.isNRChecked)) {
                    postObj[item.fieldId + "_NR"] = item.isNRChecked;
                }
            });

            this.state.datalistLeft.forEach((item, index) => {
                if (
                    item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT &&
                    !isEmptyArray(item.fieldValue)
                ) {
                    postObj[item.fieldId] = JSON.stringify(item.fieldValue);
                } else if (
                    item.fieldType !== TemplateConstants.FIELD_TYPE_MULTI_SELECT &&
                    !isEmptyVariable(item.fieldValue)
                ) {
                    postObj[item.fieldId] = item.fieldValue;
                }
                if (!isEmptyVariable(item.isNRChecked)) {
                    postObj[item.fieldId + "_NR"] = item.isNRChecked;
                }
            });

            if (!isEmptyVariable(this.state.taskId)) {
                postObj.taskId = this.state.taskId;
            }
            if (!isEmptyVariable(this.state.formCustomRisk)) {
                postObj.customBusinessRisk = this.state.formCustomRisk;
            }

            //Lets see if select all or select all except option is selected in industries
            let taskTags = [];
            //assertion tag
            if (isEmptyVariable(this.state.industrySelectionFlag)) {
                //proceed as usual
                taskTags = taskTags.concat(
                    this.getTaskTags(
                        this.state.selectedIndustryObjArr,
                        "N",
                        "industry",
                        "industryId",
                        "industryName"
                    )
                );
            } else if (this.state.industrySelectionFlag === "selectAll") {
                //let create a industry object
                let industryObj = {
                    tagType: "industry",
                    selectAll: "Y",
                };
                taskTags.push(industryObj);
            } else if (this.state.industrySelectionFlag === "selectAllExcept") {
                //let create a industry object with except ids array
                let exceptIds = this.state.selectedIndustryObjArr.map(
                    (item) => item.industryId
                );
                let industryObj = {
                    tagType: "industry",
                    selectAllExcept: "Y",
                    selectAllExceptIds: exceptIds.join(","),
                };
                taskTags.push(industryObj);
            }

            taskTags = taskTags.concat(
                this.getTaskTags(
                    this.state.selectedDocumentObjArr,
                    this.state.document_NR,
                    "document",
                    "documentId",
                    "name"
                )
            );
            taskTags = taskTags.concat(
                this.getTaskTags(
                    this.state.selectedFunctionObjArr,
                    "N",
                    "function",
                    "functionId",
                    "functionName"
                )
            );
            // taskTags = taskTags.concat(this.getTaskTags(this.state.selectedAssigmentTypeObjArr,"N","assignmentType","id","name"));
            postObj.taskTags = JSON.stringify(taskTags);

            fetch(Constants.SaveTask, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams(postObj),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                    ) {
                        localStorage.clear();
                        window.location = "/";
                    } else if (data.responseCode === Constants.CODE_SUCCESS) {
                        
                        if(btnName == "submit")  {
                            this.setState({
                                showLoader: false
                            })
                            if (this.props.location.pathname.includes(Constants.URL_EDITTASK_WITH_TEMPLATES)) {
                                this.props.history.replace(Constants.URL_VIEWTASKS+"/"+this.state.processId,{
                                    showTaskDetailsLayout:true,
                                    taskId:this.state.taskId,
                                    assignmentTypeId:this.state.assignmentTypeId
                                })
                            }
                            if (this.props.location.pathname.includes(Constants.URL_ADDTASK_WITH_TEMPLATES)) {
                                this.props.history.replace(Constants.URL_VIEWTASKS+"/"+this.state.processId,{
                                    showTaskDetailsLayout:true,
                                    taskId:data.data.taskId,
                                    assignmentTypeId:this.state.assignmentTypeId
                                })
                            }
                        }else {
                            this.setState({
                                showLoader: false
                            })

                            if (this.props.location.pathname.includes(Constants.URL_ADDTASK_WITH_TEMPLATES)) {
                                window.location.reload()
                            }

                            if (this.props.location.pathname.includes(Constants.URL_EDITTASK_WITH_TEMPLATES)) {
                                this.props.history.replace(Constants.URL_ADDTASK_WITH_TEMPLATES+"/"+this.state.processId+"-"+this.state.assignmentTypeId);
                            }
                        }
                    } else {
                        this.setState({
                            showAlertDialogInfo: true,
                            alertDialogMessageInfo: data.responseMessage,
                            showLoader: false,
                        });
                    }
                });
        } else {
            this.setState({
                datalist: datalist,
                datalistLeft: datalistLeft,
                showLoader: false,

            });

            setTimeout(() => {
                datalist.forEach((item) => {
                    item.valueErr = "";
                });

                datalistLeft.forEach((item) => {
                    item.valueErr = "";
                });

                this.setState({
                    datalist: datalist,
                    datalistLeft: datalistLeft,
                });
            }, Constants.WRNG_MSG_TIMEOUT);
        }
    };

    getTaskTags = (tagArr, tag_NR, type, idParam, valueParam) => {
        let taskTags = [];
        if (tag_NR === "Y") {
            let temp = {
                tagType: type,
                id: "",
                value: "",
                notRequired: "Y",
            };
            taskTags.push(temp);
        } else {
            !isEmptyArray(tagArr) &&
                tagArr.map((item) => {
                    let temp = {
                        tagType: type,
                        id: item[idParam],
                        value: item[valueParam],
                        notRequired: "N",
                    };
                    taskTags.push(temp);
                });
        }
        return taskTags;
    };

    /// Akshay's Code Start Here

    /// This Method Handle Execute TableCell Input.
    handleTableInputBlurExec = (rowIndex, colIndex, index, e) => {
        let tempArr = this.state.datalist;
        const value = e.currentTarget.textContent;
        let tableArr = tempArr[index].fieldValue;
        tableArr.tableArray[rowIndex][colIndex] = value;
        this.setState({
            datalist: tempArr,
        });
    };

  

    /// This Method Add New Row to The Execute Table.
    addNewRowExec = (index, e) => {
        let tempArr = this.state.datalist;
        let tableArr = tempArr[index].fieldValue;
        let singleRow = [];
        let colLength = 3;
        if (tableArr.tableArray.length === 0) {
            this.setState({
                firstRowType: "TEXTFIELD",
                isFirstRowTypeDisable: "N"
            });
            for (let i = 0; i < colLength; i++) {
                if (i === colLength - 1) {
                    singleRow.push("Action");
                } else if (i === 1) {
                    singleRow.push("TEXTFIELD");
                } else {
                    singleRow.push("");
                }
            }
        } else {
            for (let i = 0; i < colLength; i++) {
                if (i === colLength - 1) {
                    singleRow.push("Action");
                } else {
                    singleRow.push("");
                }
            }
        }
        tableArr.tableArray.push(singleRow);
        tempArr[index].fieldValue.tableArray = tableArr.tableArray;

        this.setState({
            datalist: tempArr,
        });
    };

    /// This Method Delete ROW From Execute Table.
    deleteRowExec = (rowIndex, index, e) => {
        let tempArr = this.state.datalist;
        let tableArr = tempArr[index].fieldValue;

        tableArr.tableArray.splice(rowIndex, 1);
        if (rowIndex === 0 && tableArr.tableArray.length !== 0) {
            this.setState({
                firstRowType: "TEXTFIELD",
                isFirstRowTypeDisable: "N"
            });

            tempArr[index].fieldValue.tableArray[0][1] = "TEXTFIELD";
        }

        tempArr[index].fieldValue.tableArray = tableArr.tableArray;

        this.setState({
            datalist: tempArr,
        });
    };

    /// This Method Create Execute Table According User Input.
    handleAddIntanceTableExec = (rows, selectedRadioBox, isDisableSelected) => {
        let tableArray = [];
        for (let i = 0; i < rows; i++) {
            tableArray[i] = [];
            let j = 0;
            for (j = 0; j < 2; j++) {
                tableArray[i][j] = "";
            }
            tableArray[i][j] = "Action";
        }

        let tempArr = this.state.datalist;
        tempArr[this.state.addToTableIndex].fieldValue = {
            tableArray: tableArray,
            firstRowType: selectedRadioBox,
            isFirstRowTypeDisable: isDisableSelected ? "Y" : "N",
        };
        tempArr[this.state.addToTableIndex].isTableAdded = true;

        if (selectedRadioBox === "YN") {
            if (isDisableSelected) {
                tempArr[this.state.addToTableIndex].fieldValue.tableArray[0][1] =
                    "YES / NO with DISABLE";
            } else {
                tempArr[this.state.addToTableIndex].fieldValue.tableArray[0][1] =
                    "YES / NO";
            }
        } else if (selectedRadioBox === "YNNA") {
            if (isDisableSelected) {
                tempArr[this.state.addToTableIndex].fieldValue.tableArray[0][1] =
                    "YES / NO / NA with DISABLE";
            } else {
                tempArr[this.state.addToTableIndex].fieldValue.tableArray[0][1] =
                    "YES / NO / NA";
            }
        }
        else if (selectedRadioBox === "TEXTFIELD") {
            tempArr[this.state.addToTableIndex].fieldValue.tableArray[0][1] =
                "TEXTFIELD";
        }

        this.setState({
            showAlertWithInputDialog: false,
            alertWithInputDialogMessage: "",
            tableArray: tableArray,
            datalist: tempArr,
            firstRowType: selectedRadioBox,
            addToTableIndex: "",
            isFirstRowTypeDisable: isDisableSelected ? "Y" : "N",
        });
    };

    /// This Method Edit First Row Type of Execute Table.
    handleEditExecRow = (selectedRadioBox, isDisableSelected) => {
        let tempArr = this.state.datalist;
        tempArr[this.state.addToTableIndex].fieldValue.firstRowType =
            selectedRadioBox;
        tempArr[this.state.addToTableIndex].fieldValue.isFirstRowTypeDisable =
            isDisableSelected ? "Y" : "N";

        if (selectedRadioBox === "YN") {
            if (isDisableSelected) {
                tempArr[this.state.addToTableIndex].fieldValue.tableArray[0][1] =
                    "YES / NO with DISABLE";
            } else {
                tempArr[this.state.addToTableIndex].fieldValue.tableArray[0][1] =
                    "YES / NO";
            }
        } else if (selectedRadioBox === "YNNA") {
            if (isDisableSelected) {
                tempArr[this.state.addToTableIndex].fieldValue.tableArray[0][1] =
                    "YES / NO / NA with DISABLE";
            } else {
                tempArr[this.state.addToTableIndex].fieldValue.tableArray[0][1] =
                    "YES / NO / NA";
            }
        }
        else if (selectedRadioBox === "TEXTFIELD") {
            tempArr[this.state.addToTableIndex].fieldValue.tableArray[0][1] =
                "TEXTFIELD";
            this.setState({
                isFirstRowTypeDisable: "N"
            })
        }

        this.setState({
            datalist: tempArr,
            deleteReactivateId: "",
            operationType: "",
            alertDialogHeading: "",
            proceedBtnLabel: "",
            alertDialogMessage: "",
            showAlertDialog: false,
            firstRowType: selectedRadioBox,
            isFirstRowTypeDisable: isDisableSelected ? "Y" : "N",
        });
    };

    /// This Method is call AlertDialog Box for Execute Edit Option.
    editFirstRowType = (rowIndex, colIndex, index, e) => {
        const firstRowType = this.state.datalist[index].fieldValue.firstRowType;
        const isFirstRowTypeDisable = this.state.datalist[index].fieldValue.isFirstRowTypeDisable;
        e.stopPropagation();
        this.setState({
            operationType: "",
            alertDialogHeading: "Edit Field",
            firstRowType: firstRowType,
            isFirstRowTypeDisable: isFirstRowTypeDisable,
            proceedBtnLabel: "Update",
            deleteReactivateId: index,
            alertDialogMessage: "Row Type Settings",
            showAlertDialog: true,
            addToTableIndex: index,
            isUsedForExec: "Y"
        });
    };

    /// Akshay's Code End Here
    render() {
        let fields = { text: "value", value: "value" };
        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg-collpase">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {!isEmptyVariable(this.state.processName) && 
                                    <div className="add-task-process-heading">
                                        <div className="flex-center-layout">
                                            <h6
                                                className="c-pointer"
                                                onClick={this.handleShowTasksDialog}
                                            >
                                                {this.state.processName}
                                            </h6>
                                        </div>
                                    </div>
                                }
                                {this.state.showLoader && (
                                    <div class="loader"></div>
                                )}
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag && 
                                    (
                                        <div className="body-wrapper m-t-10">
                                            <div className="row custom-row">
                                                <div className="col-md-3 custom-col">
                                                    <TaskTagButtonsLayout themeSettings={themeSettings}>
                                                        <ul>
                                                            <li>
                                                                <a href={Constants.URL_TASKS}>Back</a>
                                                            </li>
                                                            {this.state.isEditTask && (
                                                                <li>
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        onClick={this.handleshowProcessCardsDialog}
                                                                    >
                                                                        Change Process
                                                                    </a>
                                                                </li>
                                                            )}
                                                            <li>
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    onClick={this.handleSelectIndustryDialogShow}
                                                                >
                                                                    Industry
                                                                    {(!isEmptyArray(
                                                                        this.state.selectedIndustryObjArr
                                                                    ) ||
                                                                        this.state.industrySelectionFlag ===
                                                                        "selectAll") && (
                                                                            <span className="material-icons">
                                                                                check_circle
                                                                            </span>
                                                                        )}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    onClick={this.handleSelectDocumentDialogShow}
                                                                >
                                                                    Documents
                                                                    {(!isEmptyArray(
                                                                        this.state.selectedDocumentObjArr
                                                                    ) ||
                                                                        this.state.document_NR === "Y") && (
                                                                            <span className="material-icons">
                                                                                check_circle
                                                                            </span>
                                                                        )}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    onClick={this.handleSelectFunctionDialogShow}
                                                                >
                                                                    Function
                                                                    {!isEmptyArray(
                                                                        this.state.selectedFunctionObjArr
                                                                    ) && (
                                                                            <span className="material-icons">
                                                                                check_circle
                                                                            </span>
                                                                        )}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    onClick={this.handleSelectRiskDialogShow}
                                                                >
                                                                    Risk
                                                                    {!isEmptyVariable(
                                                                        this.state.selectedRiskObj.riskLevelId
                                                                    ) && (
                                                                            <span class="material-icons">
                                                                                check_circle
                                                                            </span>
                                                                        )}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                {this.state.datalistLeft.map((item, index) => {
                                                                    return (
                                                                        <li>
                                                                            <div class="template-field-left-layout m-b-10">
                                                                                <p
                                                                                    className="m-b-5 flex-center-layout"
                                                                                    style={{ marginBottom: 10 }}
                                                                                    onClick={this.handleSelectDropdownDialogShow.bind(
                                                                                        this,
                                                                                        item,
                                                                                        index
                                                                                    )}
                                                                                >
                                                                                    {item.fieldName}
                                                                                    {item.isMandatorySelected === "Y"
                                                                                        ? "*"
                                                                                        : ""}
                                                                                    {item.fieldType ===
                                                                                        TemplateConstants.FIELD_TYPE_MULTI_SELECT &&
                                                                                        !isEmptyArray(item.fieldValue) && (
                                                                                            <span className="material-icons template-field-tick">
                                                                                                check_circle
                                                                                            </span>
                                                                                        )}
                                                                                    {item.fieldType ===
                                                                                        TemplateConstants.FIELD_TYPE_SINGLE_SELECT &&
                                                                                        !isEmptyVariable(
                                                                                            item.fieldValue
                                                                                        ) && (
                                                                                            <span className="material-icons template-field-tick">
                                                                                                check_circle
                                                                                            </span>
                                                                                        )}
                                                                                </p>
                                                                            </div>
                                                                            {!isEmptyVariable(item.valueErr) && (
                                                                                <span className="cm-error">
                                                                                    {item.valueErr}
                                                                                </span>
                                                                            )}
                                                                        </li>
                                                                    );
                                                                })}
                                                            </li>
                                                        </ul>
                                                    </TaskTagButtonsLayout>
                                                </div>
                                                <div className="col-md-9 custom-col">
                                                    {this.state.datalist.map((item, index) => {
                                                        let showHeader = true;
                                                        if(item.fieldType===TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI){
                                                            if(item.isFromRiskMaster){
                                                                if(item.isRiskMasterEnabled === "Y"){
                                                                    showHeader = true;
                                                                }else{
                                                                    showHeader = false;
                                                                }
                                                            }else{
                                                                showHeader = true;
                                                            }
                                                        }
                                                        return (
                                                            <div className="editor-card">
                                                                {
                                                                    showHeader &&
                                                                    <div className="task-header-layout">
                                                                        <h6 className="editor-heading">
                                                                            {item.fieldName}
                                                                            {item.isMandatorySelected === "Y"
                                                                                ? "*"
                                                                                : ""}
                                                                        </h6>
                                                                        {item.isNotReqSelected === "Y" && (
                                                                            <FilterCheckBox
                                                                                themeSettings={themeSettings}
                                                                                style={{ marginLeft: 0 }}
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name={item.fieldName + ":-:" + index}
                                                                                    id={item.fieldName + ":-:" + index}
                                                                                    onChange={this.handleCheck}
                                                                                    checked={
                                                                                        item.isNRChecked === "Y"
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                />
                                                                                <label
                                                                                    for={item.fieldName + ":-:" + index}
                                                                                >
                                                                                    Not Required
                                                                                </label>
                                                                            </FilterCheckBox>
                                                                        )}
                                                                    </div>
                                                                }

                                                                {item.fieldType ===
                                                                    TemplateConstants.FIELD_TYPE_TEXT && (
                                                                        <div className="row custom-row">
                                                                            <div className="col-md-12 custom-col">
                                                                                <div
                                                                                    className={
                                                                                        item.isNRChecked === "Y"
                                                                                            ? "editor-layout editor-disabled"
                                                                                            : "editor-layout"
                                                                                    }
                                                                                >
                                                                                    <ReactQuill
                                                                                        placeholder={item.placeholderValue}
                                                                                        value={
                                                                                            !isEmptyVariable(item.fieldValue)
                                                                                                ? item.fieldValue
                                                                                                : ""
                                                                                        }
                                                                                        id={item.fieldName + ":-:" + index}
                                                                                        onChange={this.handleChangeReactQuill.bind(
                                                                                            this,
                                                                                            index
                                                                                        )}
                                                                                        className={
                                                                                            item.isFocused
                                                                                                ? item.isNRChecked === "Y"
                                                                                                    ? "two-lines"
                                                                                                    : "two-lines-focus"
                                                                                                : "two-lines"
                                                                                        }
                                                                                        modules={this.modules}
                                                                                        onFocus={this.handleQuillFocus.bind(
                                                                                            this,
                                                                                            index
                                                                                        )}
                                                                                        // onBlur={this.handleQuillBlur}
                                                                                        readOnly={
                                                                                            item.isNRChecked === "Y"
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {item.fieldType ===
                                                                    TemplateConstants.FIELD_TYPE_DROPDOWN && (
                                                                        <div className="row custom-row">
                                                                            <div className="col-md-12 custom-col">
                                                                                <div
                                                                                    className={
                                                                                        item.isNRChecked === "Y"
                                                                                            ? "editor-layout editor-disabled"
                                                                                            : "editor-layout"
                                                                                    }
                                                                                >
                                                                                    <RegularDropdown
                                                                                        placeholder={
                                                                                            isEmptyVariable(item.fieldValue)
                                                                                                ? item.placeholderValue
                                                                                                : item.fieldValue
                                                                                        }
                                                                                        dropdownArr={item.dropdownValues}
                                                                                        labelParam="value"
                                                                                        onDropDownItemClick={
                                                                                            this.onDropDownItemClick
                                                                                        }
                                                                                        disabled={
                                                                                            item.isNRChecked === "Y"
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        defaultPlaceholderDropDown={
                                                                                            item.placeholderValue
                                                                                        }
                                                                                        udf={index}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {item.fieldType ===
                                                                    TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                                    item.isRiskMasterEnabled === "Y" &&
                                                                    item.isFromRiskMaster && (
                                                                        <div className="row custom-row">
                                                                            <div className="col-md-12 custom-col">
                                                                                <div
                                                                                    className={
                                                                                        item.isNRChecked === "Y"
                                                                                            ? "editor-layout editor-disabled"
                                                                                            : "editor-layout"
                                                                                    }
                                                                                
                                                                                >
                                                                                    <MultiSelectComponentTemplate
                                                                                        templateItemIndex={index}
                                                                                        // multiselectId={item.fieldName+index}
                                                                                        fields={{
                                                                                            text: "displayName",
                                                                                            value: "businessRiskId",
                                                                                        }}
                                                                                        dropdownValues={
                                                                                            this.state.businessRisk
                                                                                        }
                                                                                        placeholderValue={
                                                                                            item.placeholderValue
                                                                                        }
                                                                                        addSelectedValues={
                                                                                            this.addSelectedValues
                                                                                        }
                                                                                        removeSelectedValues={
                                                                                            this.removeSelectedValues
                                                                                        }
                                                                                        fieldValue={
                                                                                            !isEmptyArray(item.fieldValue)
                                                                                                ? item.fieldValue
                                                                                                : ""
                                                                                        }
                                                                                        allowFiltering={true}
                                                                                        isFromRiskMaster={
                                                                                            item.isFromRiskMaster
                                                                                        }
                                                                                        handleFocus={this.handleFocus}
                                                                                    />

                                                                                    {item.fieldValue.some(
                                                                                        (fieldItem) => fieldItem === -1
                                                                                    ) && (
                                                                                            <div
                                                                                                class="editor-layout"
                                                                                                style={{ marginTop: 15 }}
                                                                                            >
                                                                                                <ReactQuill
                                                                                                    placeholder={
                                                                                                        item.placeholderValue
                                                                                                    }
                                                                                                    value={
                                                                                                        this.state.formCustomRisk
                                                                                                    }
                                                                                                    onChange={
                                                                                                        this
                                                                                                            .handleChangeFormCustomRisk
                                                                                                    }
                                                                                                    className={
                                                                                                        this.state
                                                                                                            .formCustomRisk_isFocused
                                                                                                            ? "two-lines-focus"
                                                                                                            : "two-lines"
                                                                                                    }
                                                                                                    modules={this.modules}
                                                                                                    onFocus={this.onCustomRiskFocus}
                                                                                                    onBlur={this.onCustomRiskBlur}
                                                                                                // readOnly={this.state.formCustomRisk_NR === "Y"?true:false}
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {item.fieldType ===
                                                                    TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                                    !item.isFromRiskMaster && (
                                                                        <div className="row custom-row">
                                                                            <div className="col-md-12 custom-col">
                                                                                <div
                                                                                    className={
                                                                                        item.isNRChecked === "Y"
                                                                                            ? "editor-layout editor-disabled"
                                                                                            : "editor-layout"
                                                                                    }
                                                                                >
                                                                                    <MultiSelectComponentTemplate
                                                                                        templateItemIndex={index}
                                                                                        // multiselectId={item.fieldName+index}
                                                                                        
                                                                                        fields={{
                                                                                            text: "value",
                                                                                            value: "value",
                                                                                        }}
                                                                                        dropdownValues={item.dropdownValues}
                                                                                        placeholderValue={
                                                                                            item.placeholderValue
                                                                                        }
                                                                                        addSelectedValues={
                                                                                            this.addSelectedValues
                                                                                        }
                                                                                        allowFiltering={true}

                                                                                        removeSelectedValues={
                                                                                            this.removeSelectedValues
                                                                                        }
                                                                                        fieldValue={
                                                                                            !isEmptyArray(item.fieldValue)
                                                                                                ? item.fieldValue
                                                                                                : ""
                                                                                        }
                                                                                        handleFocus={this.handleFocus}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {item.fieldType ===
                                                                    TemplateConstants.FIELD_TYPE_DATE && (
                                                                        <div className="row custom-row">
                                                                            <div className="col-md-12 custom-col">
                                                                                <div
                                                                                    className={
                                                                                        item.isNRChecked === "Y"
                                                                                            ? "editor-layout editor-disabled"
                                                                                            : "editor-layout"
                                                                                    }
                                                                                >
                                                                                    <DatePickerComponent
                                                                                        templateItemIndex={index}
                                                                                        onDateChange={this.onDateChange}
                                                                                        format={"DD/MM/YYYY"}
                                                                                        placeholderValue={
                                                                                            item.placeholderValue
                                                                                        }
                                                                                        isNRChecked={item.isNRChecked}
                                                                                        fieldValue={
                                                                                            !isEmptyVariable(item.fieldValue)
                                                                                                ? item.fieldValue
                                                                                                : ""
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {item.fieldType ===
                                                                    TemplateConstants.FIELD_TYPE_TABLE &&
                                                                    item.isUsedForExec !== "Y" && (
                                                                        <div
                                                                            className="flex-center-layout"
                                                                            style={{ justifyContent: "center" }}
                                                                        >
                                                                            {!item.isTableAdded && (
                                                                                <AddNewButtonLayout
                                                                                    themeSettings={themeSettings}
                                                                                >
                                                                                    <a
                                                                                        onClick={this.onAddTableClick.bind(
                                                                                            this,
                                                                                            index,
                                                                                            item.isUsedForExec
                                                                                        )}
                                                                                        href="javascript:void(0);"
                                                                                        disabled={
                                                                                            item.isNRChecked === "Y"
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                    >
                                                                                        <span className="material-icons">
                                                                                            add_circle
                                                                                        </span>
                                                                                        <p>Add Table</p>
                                                                                    </a>
                                                                                </AddNewButtonLayout>
                                                                            )}
                                                                            {item.isTableAdded && (
                                                                                // !isEmptyArray(this.state.columnArray) && !isEmptyArray(this.state.rowArray) &&
                                                                                <div
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        marginTop: 10,
                                                                                        boxShadow: "none",
                                                                                    }}
                                                                                    class={
                                                                                        item.isNRChecked === "Y"
                                                                                            ? "editor-layout editor-disabled common-table"
                                                                                            : "common-table editor-layout"
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className="flex-center-layout m-b-10"
                                                                                        style={{ justifyContent: "right" }}
                                                                                    >
                                                                                        <button
                                                                                            className="flex-center-layout instances-new-row"
                                                                                            onClick={this.addNewColumn.bind(
                                                                                                this,
                                                                                                index
                                                                                            )}
                                                                                        >
                                                                                            <span className="material-icons">
                                                                                                add
                                                                                            </span>
                                                                                            <p>Add New Column</p>
                                                                                        </button>
                                                                                        <button
                                                                                            className="flex-center-layout instances-new-row"
                                                                                            onClick={this.addNewRow.bind(
                                                                                                this,
                                                                                                index
                                                                                            )}
                                                                                        >
                                                                                            <span className="material-icons">
                                                                                                add
                                                                                            </span>
                                                                                            <p>Add New Row</p>
                                                                                        </button>
                                                                                    </div>

                                                                                    <table class="table instances-table">
                                                                                        {item.fieldValue.map(
                                                                                            (rowItem, rowIndex) => {
                                                                                                if (rowIndex === 0) {
                                                                                                    return (
                                                                                                        <thead className="instances-thead">
                                                                                                            <tr>
                                                                                                                {rowItem.map(
                                                                                                                    (
                                                                                                                        colItem,
                                                                                                                        colIndex
                                                                                                                    ) => {
                                                                                                                        return (
                                                                                                                            <th
                                                                                                                                width={
                                                                                                                                    colItem ===
                                                                                                                                        "Action"
                                                                                                                                        ? "5%"
                                                                                                                                        : ""
                                                                                                                                }
                                                                                                                                className={
                                                                                                                                    colItem ===
                                                                                                                                        "Action"
                                                                                                                                        ? "text-center"
                                                                                                                                        : ""
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <div className="instances-table-header">
                                                                                                                                    <p
                                                                                                                                        contenteditable={
                                                                                                                                            colItem ===
                                                                                                                                                "Action"
                                                                                                                                                ? "false"
                                                                                                                                                : "true"
                                                                                                                                        }
                                                                                                                                        onBlur={this.handleTableInputBlur.bind(
                                                                                                                                            this,
                                                                                                                                            rowIndex,
                                                                                                                                            colIndex,
                                                                                                                                            index
                                                                                                                                        )}
                                                                                                                                    >
                                                                                                                                        {colItem}
                                                                                                                                    </p>
                                                                                                                                    {colItem ===
                                                                                                                                        "Action" ? null : (
                                                                                                                                        <div className="instances-table-del-column">
                                                                                                                                            <span
                                                                                                                                                onClick={this.deleteColumn.bind(
                                                                                                                                                    this,
                                                                                                                                                    colIndex,
                                                                                                                                                    index
                                                                                                                                                )}
                                                                                                                                                className="material-icons"
                                                                                                                                                style={{
                                                                                                                                                    cursor:
                                                                                                                                                        "pointer",
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                delete
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                            </th>
                                                                                                                        );
                                                                                                                    }
                                                                                                                )}
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                    );
                                                                                                } else {
                                                                                                    return (
                                                                                                        <tbody className="instances-thead">
                                                                                                            <tr>
                                                                                                                {rowItem.map(
                                                                                                                    (
                                                                                                                        colItem,
                                                                                                                        colIndex
                                                                                                                    ) => {
                                                                                                                        return (
                                                                                                                            <td
                                                                                                                                className={
                                                                                                                                    colItem ===
                                                                                                                                        "Action"
                                                                                                                                        ? "text-center"
                                                                                                                                        : ""
                                                                                                                                }
                                                                                                                                contenteditable={
                                                                                                                                    colItem ===
                                                                                                                                        "Action"
                                                                                                                                        ? "false"
                                                                                                                                        : "true"
                                                                                                                                }
                                                                                                                                onBlur={this.handleTableInputBlur.bind(
                                                                                                                                    this,
                                                                                                                                    rowIndex,
                                                                                                                                    colIndex,
                                                                                                                                    index
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                {colItem ===
                                                                                                                                    "Action" ? (
                                                                                                                                    <div className="instances-table-del-cell">
                                                                                                                                        <span
                                                                                                                                            onClick={this.deleteRow.bind(
                                                                                                                                                this,
                                                                                                                                                rowIndex,
                                                                                                                                                index
                                                                                                                                            )}
                                                                                                                                            className="material-icons"
                                                                                                                                            style={{
                                                                                                                                                cursor:
                                                                                                                                                    "pointer",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            delete
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                ) : (
                                                                                                                                    colItem
                                                                                                                                )}
                                                                                                                            </td>
                                                                                                                        );
                                                                                                                    }
                                                                                                                )}
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        )}
                                                                                    </table>
                                                                                    <div className="addnew-row-wrapper">
                                                                                        <button
                                                                                            className="flex-center-layout instances-new-row"
                                                                                            onClick={this.handleDeleteTableClickAlert.bind(
                                                                                                this,
                                                                                                item.fieldName,
                                                                                                index
                                                                                            )}
                                                                                        >
                                                                                            <span className="material-icons">
                                                                                                delete
                                                                                            </span>
                                                                                            <p>Delete Table</p>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}

                                                                {/* Akshay's  Code Strat Here */}
                                                                {item.fieldType ===
                                                                    TemplateConstants.FIELD_TYPE_TABLE &&
                                                                    item.isUsedForExec === "Y" && (
                                                                        <div
                                                                            className="flex-center-layout"
                                                                            style={{ justifyContent: "center" }}
                                                                        >
                                                                            {!item.isTableAdded && (
                                                                                <AddNewButtonLayout
                                                                                    themeSettings={themeSettings}
                                                                                >
                                                                                    <a
                                                                                        onClick={this.onAddTableClick.bind(
                                                                                            this,
                                                                                            index,
                                                                                            item.isUsedForExec
                                                                                        )}
                                                                                        href="javascript:void(0);"
                                                                                        disabled={
                                                                                            item.isNRChecked === "Y"
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                    >
                                                                                        <span className="material-icons">
                                                                                            add_circle
                                                                                        </span>
                                                                                        <p>Add Table</p>
                                                                                    </a>
                                                                                </AddNewButtonLayout>
                                                                            )}
                                                                            {
                                                                                item.isTableAdded && (
                                                                                // !isEmptyArray(this.state.columnArray) && !isEmptyArray(this.state.rowArray) &&
                                                                                <div
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        marginTop: 10,
                                                                                        boxShadow: "none",
                                                                                    }}
                                                                                    class={
                                                                                        item.isNRChecked === "Y"
                                                                                            ? "editor-layout editor-disabled common-table"
                                                                                            : "common-table editor-layout"
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className="flex-center-layout m-b-10"
                                                                                        style={{ justifyContent: "right" }}
                                                                                    >
                                                                                        <button
                                                                                            className="flex-center-layout instances-new-row"
                                                                                            onClick={this.addNewRowExec.bind(
                                                                                                this,
                                                                                                index
                                                                                            )}
                                                                                        >
                                                                                            <span className="material-icons">
                                                                                                add
                                                                                            </span>
                                                                                            <p>Add New Row</p>
                                                                                        </button>
                                                                                    </div>

                                                                                    <table class="table instances-table">
                                                                                      
                                                                                        {
                                                                                            !isEmptyVariable(item?.fieldValue?.tableArray) &&
                                                                                            item.fieldValue.tableArray.map(
                                                                                            (rowItem, rowIndex) => {
                                                                                                return (
                                                                                                    <tbody className="instances-thead">
                                                                                                        <tr>
                                                                                                   
                                                                                                            {rowItem.map(
                                                                                                                (colItem, colIndex) => {
                                                                                                                    return (
                                                                                                                        <td
                                                                                                                            style={{
                                                                                                                                backgroundColor:
                                                                                                                                    colIndex === 1
                                                                                                                                        ? "#f5f5f5"
                                                                                                                                        : "",
                                                                                                                            }}

                                                                                                        
                                                                                                                            width={
                                                                                                                                colItem ===
                                                                                                                                    "Action"
                                                                                                                                    ? "5%"
                                                                                                                                    : colIndex ===
                                                                                                                                        1
                                                                                                                                        ? "40%"
                                                                                                                                        : ""
                                                                                                                            }
                                                                                                                            className={
                                                                                                                                colItem ===
                                                                                                                                    "Action"
                                                                                                                                    ? "text-center"
                                                                                                                                    : ""
                                                                                                                            }
                                                                                                                            contenteditable={
                                                                                                                                colItem ===
                                                                                                                                    "Action" ||
                                                                                                                                    colIndex === 1
                                                                                                                                    ? "false"
                                                                                                                                    : "true"
                                                                                                                            }

                                                                                                                    
                                                                                                                            onBlur={this.handleTableInputBlurExec.bind(
                                                                                                                                this,
                                                                                                                                rowIndex,
                                                                                                                                colIndex,
                                                                                                                                index
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            {colItem ===
                                                                                                                                "Action" ? (
                                                                                                                                <div className="instances-table-del-cell">
                                                                                                                                    <span
                                                                                                                                        onClick={this.deleteRowExec.bind(
                                                                                                                                            this,
                                                                                                                                            rowIndex,
                                                                                                                                            index
                                                                                                                                        )}
                                                                                                                                        className="material-icons"
                                                                                                                                        style={{
                                                                                                                                            cursor:"pointer",
                                                                                                                                            justifyContent:"center",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        delete
                                                                                                                                    </span>
                                                                                                                                    
                                                                                                                                </div>
                                                                                                                            ) : (
                                                                                                                            colIndex===1 & rowIndex===0?<>
                                                                                                                            {colItem} <span
                                                                                                                                    onClick={this.editFirstRowType.bind(
                                                                                                                                        this,
                                                                                                                                        rowIndex,
                                                                                                                                        colIndex,
                                                                                                                                        index
                                                                                                                                    )}
                                                                                                                                    className="material-icons mx-2"
                                                                                                                                    style={{
                                                                                                                                        cursor: "pointer",
                                                                                                                                        alignItems: "center",
                                                                                                                                        justifyContent: "center",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    edit
                                                                                                                                </span>
                                                                                                                            </>:colItem 
                                                                                                                )}
     
                                                                                                                        </td>
                                                                                                                    );
                                                                                                                }
                                                                                                            )}
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </table>

                                                                                    <div className="addnew-row-wrapper">
                                                                                        <button
                                                                                            className="flex-center-layout instances-new-row"
                                                                                            onClick={this.handleDeleteTableClickAlert.bind(
                                                                                                this,
                                                                                                item.fieldName,
                                                                                                index
                                                                                            )}
                                                                                        >
                                                                                            <span className="material-icons">
                                                                                                delete
                                                                                            </span>
                                                                                            <p>Delete Table</p>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}

                                                                {/* Akshay's  Code End Here */}

                                                                {!isEmptyVariable(item.valueErr) && (
                                                                    <span className="cm-error">
                                                                        {item.valueErr}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        );
                                                    })}

                                                    <div></div>
                                                </div>
                                            </div>
                                            <div className="row custom-row">
                                                <div className="col-md-12 custom-col">
                                                    <div className="common-close-save m-b-20">
                                                        <div className="common-close-save-col">
                                                            <button
                                                                onClick={this.onCancelClick}
                                                                type="button"
                                                                className="common-close"
                                                            >
                                                                Cancel
                                                            </button>
                                                            {this.state.status === draftStatus && (
                                                                <PageSaveButton
                                                                    themeSettings={themeSettings}
                                                                    onClick={this.saveAsDraft}
                                                                    type="button"
                                                                >
                                                                    Save as draft
                                                                </PageSaveButton>
                                                            )}

                                                            <PageSaveButton
                                                                themeSettings={themeSettings}
                                                                onClick={()=>{this.submitTask("submit")}}
                                                                type="button"
                                                            >
                                                                Submit
                                                            </PageSaveButton>
                                                            <PageSaveButton
                                                                name="Submit & Create New"
                                                                themeSettings={themeSettings}
                                                                onClick={()=>{this.submitTask()}}
                                                                type="button"
                                                            >
                                                                Submit & Create New
                                                            </PageSaveButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <SelectFunctionDialog
                            showFunctionsDialog={this.state.showSelectFunctionDialog}
                            handleSelectFuncDialogClose={this.handleSelectFunctionDialogClose}
                            handleSelectFunctionDialogSaveClose={
                                this.handleSelectFunctionDialogSaveClose
                            }
                            selectedFunctionObjArr={this.state.selectedFunctionObjArr}
                            departmentId={this.state.selectedDepartmentId}
                            themeSettings={themeSettings}
                        />

                        <SelectIndustryDialog
                            showIndustriesDialog={this.state.showSelectIndustryDialog}
                            handleSelectIndustryDialogClose={
                                this.handleSelectIndustryDialogClose
                            }
                            handleSelectIndustryDialogSaveClose={
                                this.handleSelectIndustryDialogSaveClose
                            }
                            selectedIndustryObjArr={this.state.selectedIndustryObjArr}
                            departmentId={this.state.selectedDepartmentId}
                            industrySelectionFlag={this.state.industrySelectionFlag}
                            themeSettings={themeSettings}
                        />

                        <SelectDocumentDialog
                            showDocumentDialog={this.state.showSelectDocumentDialog}
                            handleSelectDocDialogClose={this.handleSelectDocumentDialogClose}
                            handleSelectDocDialogSaveClose={
                                this.handleSelectDocumentDialogSaveClose
                            }
                            selectedDocumentObjArr={this.state.selectedDocumentObjArr}
                            document_NR={this.state.document_NR}
                            subscriptionType={this.state.user.subscriptionType}
                            departmentId={this.state.selectedDepartmentId}
                            themeSettings={themeSettings}
                        />

                        <SelectRiskDialog
                            showRiskDialog={this.state.showSelectRiskDialog}
                            handleSelectRiskDialogClose={this.handleSelectRiskDialogClose}
                            handleSelectRiskDialogSaveClose={
                                this.handleSelectRiskDialogSaveClose
                            }
                            selectedRiskObj={this.state.selectedRiskObj}
                            riskArr={
                                isEmptyArray(
                                    this.state.deptDefaultRisk[this.state.selectedDepartmentId]
                                )
                                    ? []
                                    : this.state.deptDefaultRisk[this.state.selectedDepartmentId]
                            }
                            themeSettings={themeSettings}
                        />

                        <ListTasksFromHeading
                            showProcessTasksDialog={this.state.showProcessTasksDialog}
                            handleTaskClose={this.handleTasksDialogClose}
                            themeSettings={themeSettings}
                            processId={this.state.processId}
                        />
                        {/* Akshay's code Start Here */}
                        <AlertWithInput
                            showAlertDialog={this.state.showAlertWithInputDialog}
                            handleAlertDialogClose={this.handleAlertWithInputDialogClose}
                            alertDialogHeading={"Instances"}
                            alertDialogMessage={this.state.alertWithInputDialogMessage}
                            proceedBtnClick={
                                this.state.isUsedForExec !== "Y"
                                    ? this.handleAddIntanceTable
                                    : this.handleAddIntanceTableExec
                            }
                            proceedBtnLabel={"Create"}
                            themeSettings={themeSettings}
                            isUsedForExec={this.state.isUsedForExec}
                            isFirstRowTypeDisable={this.state.isFirstRowTypeDisable === "Y" ? true : false}
                        />

                        <AlertDialog
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                this.state.operationType === "Delete"
                                    ? Constants.ALERT_TYPE_WARNING
                                    : Constants.ALERT_TYPE_INFO
                            }
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={
                                this.state.operationType === "Delete"
                                    ? this.handleDeleteTable
                                    : this.state.isUsedForExec === "Y"
                                        ? this.handleEditExecRow
                                        : ""
                            }
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            themeSettings={themeSettings}
                            isUsedForExec={this.state.isUsedForExec}
                            firstRowType={this.state.firstRowType}
                            isFirstRowTypeDisable={this.state.isFirstRowTypeDisable === "Y" ? true : false}
                            showAlertDialog={this.state.showAlertDialog}
                        />
                        {/* Akshay's code End Here */}
                        <LeftSideSelectDialog
                            showSelectDropdownDialog={this.state.showSelectDropdownDialog}
                            handleSelectDropdownDialogClose={
                                this.handleSelectDropdownDialogClose
                            }
                            fieldObj={this.state.templateFieldItem}
                            viewOnly={"N"}
                            themeSettings={themeSettings}
                            dialogSelectedValues={this.dialogSelectedValues}
                            fieldArrIndex={this.state.deleteReactivateId}
                        />

                        <ProcessCardListDialog
                            handleProcessCardsDialogClose={this.handleProcessCardsDialogClose}
                            showProcessCardsDialog={this.state.showProcessCardsDialog}
                            themeSettings={themeSettings}
                            departmentId={this.state.selectedDepartmentId}
                            taskId={this.state.taskId}
                        />
                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type={Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                            themeSettings={themeSettings}
                        />
                    </section>
                </main>
            </Router>
        );
    }
}

export default AddEditTask;
