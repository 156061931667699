import * as Constants from './constants';
import * as DefaultStyles from './defaultStyles';
// Get varialbles from local storage
export const getLocalStorageVariables = () => {
    let userId = typeof window !== 'undefined'?localStorage.getItem('loggedInUserDetails')?JSON.parse(localStorage.getItem('loggedInUserDetails')).loggedInUserId:'':'';
    let email = typeof window !== 'undefined'?localStorage.getItem('loggedInUserDetails')?JSON.parse(localStorage.getItem('loggedInUserDetails')).loggedInUserEmail:'':'';
    let accessToken = typeof window !== 'undefined'?localStorage.getItem('loggedInUserDetails')?JSON.parse(localStorage.getItem('loggedInUserDetails')).loggedInAccessToken:'':'';
    let firstName = typeof window !== 'undefined'?localStorage.getItem('loggedInUserDetails')?JSON.parse(localStorage.getItem('loggedInUserDetails')).loggedInFirstName:'':'';
    let lastName = typeof window !== 'undefined'?localStorage.getItem('loggedInUserDetails')?JSON.parse(localStorage.getItem('loggedInUserDetails')).loggedInLastName:'':'';
    let phoneNo = typeof window !== 'undefined'?localStorage.getItem('loggedInUserDetails')?JSON.parse(localStorage.getItem('loggedInUserDetails')).loggedInPhoneNo:'':'';
    let profilePic = typeof window !== 'undefined'?localStorage.getItem('loggedInUserDetails')?JSON.parse(localStorage.getItem('loggedInUserDetails')).loggedInProPic:'':'';
    
    let processMastersInfoFlag = typeof window !== 'undefined'?localStorage.getItem('otherUserDetails')?JSON.parse(localStorage.getItem('otherUserDetails')).processMastersInfoFlag:'':'';
    let functionMastersInfoFlag = typeof window !== 'undefined'?localStorage.getItem('otherUserDetails')?JSON.parse(localStorage.getItem('otherUserDetails')).functionMastersInfoFlag:'':'';
    let industryMastersInfoFlag = typeof window !== 'undefined'?localStorage.getItem('otherUserDetails')?JSON.parse(localStorage.getItem('otherUserDetails')).industryMastersInfoFlag:'':'';
    
    let userData = {
        userId:userId===undefined?"":userId,
        email:email===undefined?"":email,
        accessToken:accessToken===undefined?"":accessToken,
        firstName:firstName===undefined?"":firstName,
        lastName:lastName===undefined?"":lastName,
        phoneNo:phoneNo===undefined?"":phoneNo,
        profilePic:profilePic===undefined?"":profilePic,

        processMastersInfoFlag:processMastersInfoFlag===undefined?"":processMastersInfoFlag,
        functionMastersInfoFlag:functionMastersInfoFlag===undefined?"":functionMastersInfoFlag,
        industryMastersInfoFlag:industryMastersInfoFlag===undefined?"":industryMastersInfoFlag
    }
    return userData;
}

export const getThemeSettingsVariables = () => {
    let themeColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).themeColor:'':'';
    let themeTextColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).themeTextColor:'':'';
    let themeHoverColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).themeHoverColor:'':'';
    let themeHoverTextColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).themeHoverTextColor:'':'';

    let sidebarBg = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).sidebarBg:'':'';
    let sidebarBorderRight = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).sidebarBorderRight:'':'';
    let sidebarDividerColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).sidebarDividerColor:'':'';

    let sidebarTextColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).sidebarTextColor:'':'';
    let sidebarIconColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).sidebarIconColor:'':'';

    let sidebarSelectedItemBg = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).sidebarSelectedItemBg:'':'';
    let sidebarSelectedItemTextColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).sidebarSelectedItemTextColor:'':'';
    let sidebarSelectedItemIconColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).sidebarSelectedItemIconColor:'':'';

    let sidebarHoverItemBg = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).sidebarHoverItemBg:'':'';
    let sidebarHoverItemTextColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).sidebarHoverItemTextColor:'':'';
    let sidebarHoverItemIconColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).sidebarHoverItemIconColor:'':'';

    let topbarBg = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).topbarBg:'':'';
    let topbarIconColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).topbarIconColor:'':'';
    let topbarSelectedIconBg = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).topbarSelectedIconBg:'':'';
    let notificationBadgeBg = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).notificationBadgeBg:'':'';
    let badgeTextColor = typeof window !== 'undefined'?localStorage.getItem('themeSettings')?JSON.parse(localStorage.getItem('themeSettings')).badgeTextColor:'':'';

    let themeSettings = {
        themeColor:isEmptyVariable(themeColor)?DefaultStyles.themeDefaultStyles.themeColor:themeColor,
        themeTextColor:isEmptyVariable(themeTextColor)?DefaultStyles.themeDefaultStyles.themeTextColor:themeTextColor,
        themeHoverColor:isEmptyVariable(themeHoverColor)?DefaultStyles.themeDefaultStyles.themeHoverColor:themeHoverColor,
        themeHoverTextColor:isEmptyVariable(themeHoverTextColor)?DefaultStyles.themeDefaultStyles.themeHoverTextColor:themeHoverTextColor,

        sidebarBg:isEmptyVariable(sidebarBg)?DefaultStyles.sideBarDefaultStyles.background:sidebarBg,
        sidebarBorderRight:isEmptyVariable(sidebarBorderRight)?DefaultStyles.sideBarDefaultStyles.borderRight:sidebarBorderRight,
        sidebarDividerColor:isEmptyVariable(sidebarDividerColor)?DefaultStyles.sideBarDefaultStyles.dividerColor:sidebarDividerColor,

        sidebarTextColor:isEmptyVariable(sidebarTextColor)?DefaultStyles.sideBarDefaultStyles.textColor:sidebarTextColor,
        sidebarIconColor:isEmptyVariable(sidebarIconColor)?DefaultStyles.sideBarDefaultStyles.iconColor:sidebarIconColor,

        sidebarSelectedItemBg:isEmptyVariable(sidebarSelectedItemBg)?DefaultStyles.sideBarDefaultStyles.selectedMenuItemBg:sidebarSelectedItemBg,
        sidebarSelectedItemTextColor:isEmptyVariable(sidebarSelectedItemTextColor)?DefaultStyles.sideBarDefaultStyles.selectedMenuItemTextColor:sidebarSelectedItemTextColor,
        sidebarSelectedItemIconColor:isEmptyVariable(sidebarSelectedItemIconColor)?DefaultStyles.sideBarDefaultStyles.selectedMenuItemIconColor:sidebarSelectedItemIconColor,

        sidebarHoverItemBg:isEmptyVariable(sidebarHoverItemBg)?DefaultStyles.sideBarDefaultStyles.hoverMenuItembg:sidebarHoverItemBg,
        sidebarHoverItemTextColor:isEmptyVariable(sidebarHoverItemTextColor)?DefaultStyles.sideBarDefaultStyles.hoverMenuItemTextColor:sidebarHoverItemTextColor,
        sidebarHoverItemIconColor:isEmptyVariable(sidebarHoverItemIconColor)?DefaultStyles.sideBarDefaultStyles.hoverMenuItemIconColor:sidebarHoverItemIconColor,

        topbarBg:isEmptyVariable(topbarBg)?DefaultStyles.topbarDefaultStyles.background:topbarBg,
        topbarIconColor:isEmptyVariable(topbarIconColor)?DefaultStyles.topbarDefaultStyles.iconColor:topbarIconColor,
        topbarSelectedIconBg:isEmptyVariable(topbarSelectedIconBg)?DefaultStyles.topbarDefaultStyles.selectedIconBgColor:topbarSelectedIconBg,
        notificationBadgeBg:isEmptyVariable(notificationBadgeBg)?DefaultStyles.topbarDefaultStyles.notificationBadgeBg:notificationBadgeBg,
        badgeTextColor:isEmptyVariable(badgeTextColor)?DefaultStyles.topbarDefaultStyles.notificationBadgeTextColor:badgeTextColor,
    }
    document.body.style.setProperty("--theme-color", `rgb(${themeSettings.themeColor.r}, ${themeSettings.themeColor.g}, ${themeSettings.themeColor.b}, ${themeSettings.themeColor.a})`);
    document.body.style.setProperty("--theme-text-color", `rgb(${themeSettings.themeTextColor.r}, ${themeSettings.themeTextColor.g}, ${themeSettings.themeTextColor.b}, ${themeSettings.themeTextColor.a})`);
    return themeSettings;
}

export const getDefaultStylesObj = () => {
    let themeSettings = {
        themeColor:DefaultStyles.themeDefaultStyles.themeColor,
        themeTextColor:DefaultStyles.themeDefaultStyles.themeTextColor,
        themeHoverColor:DefaultStyles.themeDefaultStyles.themeHoverColor,
        themeHoverTextColor:DefaultStyles.themeDefaultStyles.themeHoverTextColor,

        sidebarBg:DefaultStyles.sideBarDefaultStyles.background,
        sidebarBorderRight:DefaultStyles.sideBarDefaultStyles.borderRight,
        sidebarDividerColor:DefaultStyles.sideBarDefaultStyles.dividerColor,

        sidebarTextColor:DefaultStyles.sideBarDefaultStyles.textColor,
        sidebarIconColor:DefaultStyles.sideBarDefaultStyles.iconColor,

        sidebarSelectedItemBg:DefaultStyles.sideBarDefaultStyles.selectedMenuItemBg,
        sidebarSelectedItemTextColor:DefaultStyles.sideBarDefaultStyles.selectedMenuItemTextColor,
        sidebarSelectedItemIconColor:DefaultStyles.sideBarDefaultStyles.selectedMenuItemIconColor,

        sidebarHoverItemBg:DefaultStyles.sideBarDefaultStyles.hoverMenuItembg,
        sidebarHoverItemTextColor:DefaultStyles.sideBarDefaultStyles.hoverMenuItemTextColor,
        sidebarHoverItemIconColor:DefaultStyles.sideBarDefaultStyles.hoverMenuItemIconColor,

        topbarBg:DefaultStyles.topbarDefaultStyles.background,
        topbarIconColor:DefaultStyles.topbarDefaultStyles.iconColor,
        topbarSelectedIconBg:DefaultStyles.topbarDefaultStyles.selectedIconBgColor,
        notificationBadgeBg:DefaultStyles.topbarDefaultStyles.notificationBadgeBg,
        badgeTextColor:DefaultStyles.topbarDefaultStyles.notificationBadgeTextColor,
    }
    return themeSettings;
}
//Check Variable is empty
export const isEmptyVariable = (variableName) => {
    if(variableName === ''){
        return true;
    }
        
    if(variableName === undefined){
        return true;
    }

    if(variableName === null){
        return true;
    }

    if(variableName === "null"){
        return true;
    }
    
    return false;
}

//Check if array is empty
export const isEmptyArray = (arrayName) => {
    if(arrayName === ''){
        return true;
    }
        
    if(arrayName === undefined){
        return true;
    }

    if(arrayName === null){
        return true;
    }

    if(!Array.isArray(arrayName)){
        return true;
    }

    if(arrayName.length === 0){
        return true;
    }
    
    return false;
}

export const ifEmptyReturnEmptyStr = (variableName, EmptyStr) => {
    return isEmptyVariable(variableName)?EmptyStr:variableName;
}

export const ifEmptyReturn = (variableName) => {
    return isEmptyVariable(variableName)?"":variableName;
}

export const removeHtmlTags = (htmlStr) => {
    return htmlStr.replace(/(<([^>]+)>)/gi, "");
}

export const sortTable = (sortColumn, currentSortColumn, currentSortDir) => {

    let sortObj = {
        sortDirTemp:"",
        sortTemp:sortColumn
    };
    
    //if the clicked column is same as previously clicked column
    //then just change the sorting direction
    //if it is different then change the column param to new & reinitialise sortdir to asc 
    if(currentSortColumn === sortColumn){
        if(currentSortDir === ""){
            sortObj.sortDirTemp = "asc";
        }else if(currentSortDir === "asc"){
            sortObj.sortDirTemp = "desc";
        }else if(currentSortDir === "desc"){
            sortObj.sortDirTemp = "";
            sortObj.sortTemp="";
        }
    }else{
        sortObj.sortDirTemp = "asc";
    }

    return sortObj;
}

export const sortTableTwoStates = (sortColumn, currentSortColumn, currentSortDir) => {

    let sortObj = {
        sortDirTemp:"",
        sortTemp:sortColumn
    };

    //if the clicked column is same as previously clicked column
    //then just change the sorting direction
    //if it is different then change the column param to new & reinitialise sortdir to asc 
    if(currentSortColumn === sortColumn){
        if(currentSortDir === ""){
            sortObj.sortDirTemp = "asc";
        }else if(currentSortDir === "asc"){
            sortObj.sortDirTemp = "desc";
        }else if(currentSortDir === "desc"){
            //Go back to default setting
            sortObj.sortDirTemp = "asc";
        }
    }else{
        sortObj.sortDirTemp = "asc";
    }

    return sortObj;
}

export const validatePassword = (password) =>{
    let respObj = {
        err:false,
        responseMsg:""
    }

    // password empty
    if(isEmptyVariable(password)){
        respObj = {
            err:true,
            responseMsg:"Please enter password"
        }
        return respObj;
    }

    // password length should be greater than 8
    if(password.length < 7){
        respObj = {
            err:true,
            responseMsg:"Password must have minimum 8 characters"
        }
        return respObj;
    }

    return respObj;
}

export const truncateString = (str, num) => {
    
    if(isEmptyVariable(str)){
        return "-";
    }

    if(str.length <= num){
        return str;
    }

    if(str.length > num){
        return str.substring(0,num) + "...";
    }
}

export const truncateStringV2 = (str, num) => {
    
    if(isEmptyVariable(str)){
        return "";
    }

    if(str.length <= num){
        return str;
    }

    if(str.length > num){
        return str.substring(0,num) + "...";
    }
}

//PROJECT SPECIFIC FUNCTIONS
export const SplitJoinString = (str, splitParam1, splitParam2,joinParam,pos) => {
    // alert(str)
    let resArr = [];
    let position = pos;
    if(isEmptyVariable(pos)){
        position = 0;
    }
    if(isEmptyVariable(str)) return "";

    let splitArr = str.split(splitParam1);

    if(isEmptyArray(splitArr)) return "";

    splitArr.map((item)=>{
        resArr.push(item.split(splitParam2)[position])
    })

    return resArr.join(joinParam);
        
}
export const DoubleSplitString = (str, splitParam1, splitParam2,pos) => {
    // alert(str)
    let resArr = [];
    let position = pos;
    if(isEmptyVariable(pos)){
        position = 0;
    }
    if(isEmptyVariable(str)) return "";

    let splitArr = str.split(splitParam1);

    if(isEmptyArray(splitArr)) return "";

    splitArr.map((item)=>{
        resArr.push(item.split(splitParam2)[position])
    })

    return resArr;
        
}

export const GetOrderedMasterTabMenuTask = (menusTemp) => {
    let menusTemp2 = {};

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_TASKS]) &&
    menusTemp[Constants.SCREEN_TASKS] !== "NONE"){
        menusTemp2[Constants.SCREEN_TASKS] = menusTemp[Constants.SCREEN_TASKS];
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_CHECKLIST]) &&
    menusTemp[Constants.SCREEN_CHECKLIST] !== "NONE"){
        menusTemp2[Constants.SCREEN_CHECKLIST] = menusTemp[Constants.SCREEN_CHECKLIST];
    }

    console.log(menusTemp2)
    return menusTemp2;
}
export const GetOrderedMasterTabMenuExecutes = (menusTemp) => {
    let menusTemp2 = {};

    // if((!isEmptyVariable(menusTemp[Constants.SCREEN_EXECUTE]) &&
    // menusTemp[Constants.SCREEN_EXECUTE] !== "NONE")){
        // menusTemp2["Execute"] = "Execute";
        // menusTemp2["Follow Up"] = "Follow Up";
        if((!isEmptyVariable(menusTemp[Constants.SCREEN_EXECUTE]) !== "NONE"))
        {
            menusTemp2[Constants.SCREEN_EXECUTE] = menusTemp[Constants.SCREEN_EXECUTE];
        }
        if((!isEmptyVariable(menusTemp[Constants.SCREEN_EXECUTE_FOLLOW_UP_ASSIGNMENT]) !== "NONE"))
        {
            menusTemp2[Constants.SCREEN_EXECUTE_FOLLOW_UP_ASSIGNMENT] = menusTemp[Constants.SCREEN_EXECUTE_FOLLOW_UP_ASSIGNMENT];
        }
    // }

    return menusTemp2;
}

export const GetOrderedMasterTabMenuExecute = (menusTemp,obsTemplateId) => {
    let menusTemp2 = {};

    if((!isEmptyVariable(menusTemp[Constants.SCREEN_EXECUTE]) &&
    menusTemp[Constants.SCREEN_EXECUTE] !== "NONE")){
        menusTemp2["Tasks"] = "Tasks";
        if(!isEmptyVariable(obsTemplateId)){
            menusTemp2["Observations"] = "Observations";
        }
        if(!isEmptyVariable(menusTemp['Assignment Level Template']) && menusTemp["Assignment Level Template"] !== "NONE") {
            menusTemp2['Assignment Level Template'] = menusTemp['Assignment Level Template'];
        }
        menusTemp2["Sample Checked"] = "Sample Checked";
        menusTemp2["Working Papers"] = "Working Papers";
        menusTemp2["Auditee"] = "Auditee";
        menusTemp2["Timeline"] = "Timeline";
    }

    return menusTemp2;
}

export const GetOrderedMasterTabMenuReports = (menusTemp) => {
    let menusTemp2 = {};

    if((!isEmptyVariable(menusTemp[Constants.SCREEN_REPORT]) &&
    menusTemp[Constants.SCREEN_REPORT] !== "NONE")){
        menusTemp2["Ongoing"] = "Ongoing";
        menusTemp2["Draft"] = "Draft";
        menusTemp2["Completed"] = "Completed";
    }

    return menusTemp2;
}

export const GetOrderedMasterTabMenuPlanning = (menusTemp) => {
    let menusTemp2 = {};

    if((!isEmptyVariable(menusTemp[Constants.SCREEN_SCHEDULED_ASSIGNMENT_ALL]) &&
    menusTemp[Constants.SCREEN_SCHEDULED_ASSIGNMENT_ALL] !== "NONE") ||
    (!isEmptyVariable(menusTemp[Constants.SCREEN_SCHEDULED_ASSIGNMENT_MANAGERS]) &&
    menusTemp[Constants.SCREEN_SCHEDULED_ASSIGNMENT_MANAGERS] !== "NONE")
    ){
        menusTemp2[Constants.SCREEN_SCHEDULED_ASSIGNMENT_ALL] = menusTemp[Constants.SCREEN_SCHEDULED_ASSIGNMENT_ALL];
    }

    if((!isEmptyVariable(menusTemp[Constants.SCREEN_PLAN_ASSIGNMENT_ALL]) &&
    menusTemp[Constants.SCREEN_PLAN_ASSIGNMENT_ALL] !== "NONE") ||
    (!isEmptyVariable(menusTemp[Constants.SCREEN_PLAN_ASSIGNMENT_MANAGERS]) &&
    menusTemp[Constants.SCREEN_PLAN_ASSIGNMENT_MANAGERS] !== "NONE")
    ){
        menusTemp2[Constants.SCREEN_PLAN_ASSIGNMENT_ALL] = menusTemp[Constants.SCREEN_PLAN_ASSIGNMENT_ALL];
    }

    if((!isEmptyVariable(menusTemp[Constants.SCREEN_FOLLOW_UP_ASSIGNMENT]) !== "NONE")
    ){
        menusTemp2[Constants.SCREEN_FOLLOW_UP_ASSIGNMENT] = menusTemp[Constants.SCREEN_FOLLOW_UP_ASSIGNMENT];
    }
    return menusTemp2;
}

export const GetOrderedMasterTabMenu = (menusTemp) => {
    let menusTemp2 = {};

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_PROCESS]) &&
    menusTemp[Constants.SCREEN_PROCESS] !== "NONE"){
        menusTemp2[Constants.SCREEN_PROCESS] = menusTemp[Constants.SCREEN_PROCESS];
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_FUNCTION]) &&
    menusTemp[Constants.SCREEN_FUNCTION] !== "NONE"){
        menusTemp2[Constants.SCREEN_FUNCTION] = menusTemp[Constants.SCREEN_FUNCTION];
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_INDUSTRY]) &&
    menusTemp[Constants.SCREEN_INDUSTRY] !== "NONE"){
        menusTemp2[Constants.SCREEN_INDUSTRY] = menusTemp[Constants.SCREEN_INDUSTRY];
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_DOCUMENT]) &&
    menusTemp[Constants.SCREEN_DOCUMENT] !== "NONE"){
        menusTemp2[Constants.SCREEN_DOCUMENT] = menusTemp[Constants.SCREEN_DOCUMENT];
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_RISK]) &&
    menusTemp[Constants.SCREEN_RISK] !== "NONE"){
        menusTemp2[Constants.SCREEN_RISK] = menusTemp[Constants.SCREEN_RISK];
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_OTHER]) &&
    menusTemp[Constants.SCREEN_OTHER] !== "NONE"){
        menusTemp2[Constants.SCREEN_OTHER] = menusTemp[Constants.SCREEN_OTHER];
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_GENERAL]) &&
    menusTemp[Constants.SCREEN_GENERAL] !== "NONE"){
        menusTemp2[Constants.SCREEN_GENERAL] = menusTemp[Constants.SCREEN_GENERAL];
    }

    return menusTemp2;
}
export const GetSidebarTemplateLink = (menusTemp) => {
    if(!isEmptyVariable(menusTemp["Task Template"]) &&
    menusTemp["Task Template"] !== "NONE"){
        return Constants.URL_TASK_TEMPLATES;
    }
    
    if(!isEmptyVariable(menusTemp["Execution Template"]) &&
    menusTemp["Execution Template"] !== "NONE"){
        return Constants.URL_EXECUTE_TASK_TEMPLATES;
    }

    if(!isEmptyVariable(menusTemp["Observation Template"]) &&
    menusTemp["Observation Template"] !== "NONE"){
        return Constants.URL_OBSERVATION_TEMPLATES;
    }
    
    if(!isEmptyVariable(menusTemp["Follow Up Template"]) &&
    menusTemp["Follow Up Template"] !== "NONE"){
        return Constants.URL_FOLLOWUP_TEMPLATES;
    }
}
export const GetSidebarMasterLink = (menusTemp) => {

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_PROCESS]) &&
    menusTemp[Constants.SCREEN_PROCESS] !== "NONE"){
        return Constants.URL_PROCESSES;
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_FUNCTION]) &&
    menusTemp[Constants.SCREEN_FUNCTION] !== "NONE"){
        return Constants.URL_FUNCTIONS;
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_INDUSTRY]) &&
    menusTemp[Constants.SCREEN_INDUSTRY] !== "NONE"){
        return Constants.URL_INDUSTRIES;
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_DOCUMENT]) &&
    menusTemp[Constants.SCREEN_DOCUMENT] !== "NONE"){
        return Constants.URL_DOCUMENTS;
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_RISK]) &&
    menusTemp[Constants.SCREEN_RISK] !== "NONE"){
        return Constants.URL_RISK;
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_OTHER]) &&
    menusTemp[Constants.SCREEN_OTHER] !== "NONE"){
        return Constants.URL_OTHERMASTERS;
    }

    if(!isEmptyVariable(menusTemp[Constants.SCREEN_GENERAL]) &&
    menusTemp[Constants.SCREEN_GENERAL] !== "NONE"){
        return Constants.URL_GENERALMASTERS;
    }

    return "";
}

export const GetTemplateTabMenu = (menusTemp) => {
    let menuObj = {};
    if(!isEmptyVariable(menusTemp['Task Template']) &&
    menusTemp["Task Template"] !== "NONE"){
        menuObj['Task Template'] = menusTemp['Task Template'];
    }
    if(!isEmptyVariable(menusTemp['Execution Template']) &&
    menusTemp["Execution Template"] !== "NONE"){
        menuObj['Execution Template'] = menusTemp['Execution Template'];
    }
    if(!isEmptyVariable(menusTemp['Observation Template']) &&
    menusTemp["Observation Template"] !== "NONE"){
        menuObj['Observation Template'] = menusTemp['Observation Template'];
    }
    if(!isEmptyVariable(menusTemp['Assignment Level Template']) &&
    menusTemp["Assignment Level Template"] !== "NONE"){
        menuObj['Assignment Level Template'] = menusTemp['Assignment Level Template'];
    }
    if(!isEmptyVariable(menusTemp['Follow Up Template']) &&
    menusTemp["Follow Up Template"] !== "NONE"){
        menuObj['Follow Up Template'] = menusTemp['Follow Up Template'];
    } 
    if(!isEmptyVariable(menusTemp['Response Template']) &&
    menusTemp["Response Template"] !== "NONE"){
        menuObj['Response Template'] = menusTemp['Response Template'];
    }
    return menuObj;
}

export const getOnlyFileNameFromPath = (path) => {
    if(isEmptyVariable(path)){
        return "";
    }

    return path.split("_-_").pop();
}

export const rgb2hex = (rgb) => {
    rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb && rgb.length === 4) ? "#" +
     ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
     ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
     ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
   }

export const isJsonString = (str) => {
    try{
        JSON.parse(str)
    }catch(e){
        return false;
    }
    return true;
}

export const getStringWithSpaceAfterComma = (str) => {
    let resStr = "";
    if(isEmptyVariable(str)){
        return "";
    }
    resStr = str.split(",").join(", ");
    return resStr;
}

export const isNumber = (num) => {
    return (typeof(num) === 'number' || typeof(num) === 'string' && num.trim() !== '') && !isNaN(num);
}

export const isAlphaNumericCharacter = (str) =>{
    
    let pattern =/^[^\`"':]+$/i;
    return pattern.test(str);
    
}


export const getStatusSpan = (previousStatus, themeSettings) => {
    // alert(previousStatus);
    let spanText = "";
    let spanStyle = {
        background:"#f5f5f5",
        color:"#000"
    }
    if(previousStatus === "Updated"){
        spanText = "Updated";
        spanStyle = {
            background:"#ddd",
            color:"#000"
        }
    }else if(previousStatus === "Created"){
        spanText = "New";
        spanStyle = {
            background:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b})`,
            color:`rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`
        }
    }else if(previousStatus === "Inactive"){
        spanText = "Deleted";
        spanStyle = {
            background:"#ff0000",
            color:"#fff"
        }
    }else if(previousStatus === "Deleted"){
        spanText = "Deleted";
        spanStyle = {
            background:"#ff0000",
            color:"#fff"
        }
    }

    return {
        spanText:spanText,
        spanStyle:spanStyle,
    }
}

export const getRechartXAxisLabel = (text, index) => {
    const maxChar = 10;
    if (text.length > maxChar) {
        text = text.slice(0, maxChar) + "...";
    }
    return text;
}
export const getRechartYAxisLabel = (text, index) => {

    return text +"%";
}
