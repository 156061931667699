import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,DialogSaveButton,FilterCheckBox,
    TableIcon} from '../../../common/Global/globalStyles.style';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../../common/AlertDialog';
import Pagination from '../../../common/Global/pagination';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import UpdateReviewStatusDialog from './documentmasterupdatereviewstatusdialog'
import UploadDocumentSampleDialog from './uploadDocSample';
import ReviewHistoryDialog from '../../../common/reviewhistorydialog'
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,SplitJoinString,
    isEmptyArray,ifEmptyReturnEmptyStr,getStatusSpan} from '../../../common/Global/commonFunctions';
import {getDocMasterDropdownActionArray} from '../../../common/Global/reviewFunctions';
import {Modal} from 'react-bootstrap';
import MultiSelectFunctionDialog from "./functionMultiSelect";
import SelectCopyDocumentDialog from './CopyDocumentMaster/selectCopyDocumentDialog';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();

const sortName = "d.name";
const sortDescription = "d.description";
const sortDeptName = "departmentName";

const functionPlaceholder = "Select Function";
const departmentDropdownDefaultValue = "Select Department";
const defaultDocTypePlaceholder = "Select Document Type";

const activeStatus="Active";
const inActiveStatus="Inactive";
const underReviewStatus="Under Review";
const departmentFilterDropdownDefaultValue = "All";
const requestChangeCheckText="Request Change";

class OtherMasters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:props.searchkey,
            searchkey:props.searchkey,
            currentPageNo:props.currentPageNo,
            showLoader:false,
            totalCount:0,
            datalist:[],
            resultSize:props.resultSize,
            resultSizePlaceholder:props.resultSize,
            sort:props.sort,
            sortDir:props.sortDir,

            departmentArr:[],
            selectedDepartmentName:departmentDropdownDefaultValue,
            selectedDepartmentId:"",

            deptOnly:props.deptOnly,
            actionType:"",

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            reviewLevel:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            showBtnLoader:false,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,

            status:props.status,
            statusPlaceholder:props.status,
            statusArr:[
                {
                    label:activeStatus,
                    isSelected:props.status===activeStatus?true:false
                },
                {
                    label:underReviewStatus,
                    isSelected:props.status===underReviewStatus?true:false
                },
                {
                    label:inActiveStatus,
                    isSelected:props.status===inActiveStatus?true:false
                },
            ],
            resultSizeArr: JSON.parse(JSON.stringify(Constants.RESULT_SIZE_DD_ARR)),

            name:"",
            shortName:"",
            description:"",
            checkedFunctionName:functionPlaceholder,
            checkedFunctionIds:[],
            docTypes:[],
            selectedDocType:defaultDocTypePlaceholder,
            formErrors:{},
            submitClickedAtleastOnce:false,

            showDetailsDialog:false,
            selectedItem:"",
            
            showMultiSelectFunctionTreeDialog:false,

        }
    }

    componentDidMount(){
        this.getInitData();
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        let FormErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            if(name === "shortName"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['shortName'] = "Please enter master short name";
                }else{
                    FormErrorsTemp['shortName'] = "";
                }
            }else if(name === "name"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['name'] = "Please enter master name";
                }else{
                    FormErrorsTemp['name'] = "";
                }
            }else if(name === "description"){
                if(!isEmptyVariable(value) && value.length > Constants.DESC_STRING_LENGTH){
                    FormErrorsTemp['description'] = "Description must be less then 500 characters";
                }else{
                    FormErrorsTemp['description'] = "";
                }
            }
        }

        this.setState({ 
            [name]: value ,
            formErrors:FormErrorsTemp
        });
    }

    handleCheck = (e) =>{
        let isChecked = e.target.checked;
        let name = e.target.name;

        if(isChecked){
            this.setState({
                deptOnly:"Y"
            },()=>{
                this.getDocumentMasterList()
            })
        }else{
            this.setState({
                deptOnly:"N"
            },()=>{
                this.getDocumentMasterList()
            })
        }

    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getDocumentMasterList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getDocumentMasterList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag === true){
                this.getDocumentMasterList();
            }
        });
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateId:"",
        });
    }

    handleCreateDialogClose = () =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            selectedId:"",
            name:"",
            shortName:"",
            description:"",
        });
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleMultiSelectFunctionTreeDialogClose = () => {
        this.setState({
            showMultiSelectFunctionTreeDialog:false,
        })
    }

    handleMultiSelectFuncDialogShow = () => {
        let formErrors = this.state.formErrors;
        
        if(isEmptyVariable(this.state.selectedDepartmentId)){
            formErrors["checkedFunctionName"] = "Please select the department"
            this.setState({
                formErrors:formErrors
            })
        }else{
            formErrors["checkedFunctionName"] = ""
            this.setState({
                formErrors:formErrors,
                showMultiSelectFunctionTreeDialog:true,
            })
        }
    }

    onKeyBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.handleMultiSelectFuncDialogShow();
        }
    }

    handleMultiSelectSubmit = (selectedFunctionIds,selectedFunctionName) => {
        this.setState({
            showMultiSelectFunctionTreeDialog:false,
            checkedFunctionIds:selectedFunctionIds,
            checkedFunctionName:selectedFunctionName
        })
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    //TODO
    showCreateDialog = () => {
        let selectedDepartmentId = this.state.selectedDepartmentId;
        let selectedDepartmentName = this.state.selectedDepartmentName;

        if(this.state.departmentArr.length > 1){
            selectedDepartmentId="";
            selectedDepartmentName=departmentDropdownDefaultValue;
        }

        this.setState({
            showCreateDialog:true,
            isEdit:false,
            selectedId:"",
            checkedFunctionName:functionPlaceholder,
            checkedFunctionIds:[],
            selectedDocType:defaultDocTypePlaceholder,
            name:"",
            shortName:"",
            description:"",
            selectedDepartmentId:selectedDepartmentId,
            selectedDepartmentName:selectedDepartmentName,
            submitClickedAtleastOnce:false,
            formErrors:{}
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getDocumentMasterList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getDocumentMasterList();
        })
    }

    deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let id = "";
        let filterDeptArr = this.state.filterDeptArr;

        for(const [i,value] of filterDeptArr.entries()){
            if(i === selectedIndex){
                filterDeptArr[i].isSelected = true;
                placeholder = value.name;
                id = value.departmentId;
            }else{
                filterDeptArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo: 1,
            selectedFilterDepartmentName:placeholder,
            selectedFilterDepartmentId:id
        },() => {
            this.getDocumentMasterList();
        })
    }

    deptDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['deparment'] = "";
        }

        this.setState({
            selectedDepartmentName:item.name,
            selectedDepartmentId:item.departmentId,
            formErrors:FormErrorsTemp
        })
    }

    docTypeDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['docType'] = "";
        }

        this.setState({
            selectedDocType:item.documentType,
            formErrors:FormErrorsTemp
        })
    }

    handleUploadSampleDocDialogClose = () => {
        this.setState({
            showUploadDocSampleDialog:false,
            deleteReactivateId:"",
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Master",
                proceedBtnLabel:"Delete",
                deleteReactivateId:dropdownItem.documentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete master "+dropdownItem.name+"?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Activate Master",
                proceedBtnLabel:"Reactivate",
                deleteReactivateId:dropdownItem.documentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate master "+dropdownItem.name+"?"
            })
        }else if(item.label === "Edit"){
            //first get the list of functionIds
            let selectedFunctionArr =  [];
            let functionListArr = [];
            if(!isEmptyVariable(dropdownItem.functionList)){
                functionListArr = dropdownItem.functionList.split(";;");
            }
            functionListArr.forEach(item=>{
                selectedFunctionArr.push(item.split(":-:")[1])
            })
            let functionName = "";
            if(functionListArr.length === 1){
                functionName = "1 Function Selected";
            }else{
                functionName = selectedFunctionArr.length+" Functions Selected"
            }
            this.setState({
                showCreateDialog:true,
                isEdit:true,
                selectedId:dropdownItem.documentId,
                name:dropdownItem.name_new,
                shortName:dropdownItem.shortName_new,
                checkedFunctionName:functionName,
                checkedFunctionIds:selectedFunctionArr,
                selectedDocType:dropdownItem.documentType_new,
                description:dropdownItem.description_new,
                formErrors:{},
                selectedDepartmentId:dropdownItem.departmentId,
            });
        }else if(item.label === "Submit For Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Submit For Review",
                proceedBtnLabel:"Submit",
                deleteReactivateId:dropdownItem.documentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to submit "+dropdownItem.name+" for review?"
            })
        }else if(item.label === "Start Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.documentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+dropdownItem.name
            })
        }else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.documentId,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.documentId,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Review Remarks"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Review Remarks",
                proceedBtnLabel:"Okay",
                showAlertDialog:true,
                alertDialogMessage:'\"'+dropdownItem.lastReviewRemark+'\"'
            })
        }else if(item.label === "Upload Sample"){
            this.setState({
                showUploadDocSampleDialog:true,
                deleteReactivateId:dropdownItem.documentId,
            })
        }else if(item.label === "Show Samples"){
            this.props.handleActionDropdownItemClick(dropdownItem,this.state.currentPageNo,this.state.searchkey,
                this.state.resultSize, this.state.sort, this.state.sortDir, this.state.deptOnly,this.state.status,
                "documentSampleList");
        }else if(item.label === "Discard Changes"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Discard Changes",
                proceedBtnLabel:"Discard",
                deleteReactivateId:dropdownItem.documentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to discard changes of "+dropdownItem.name_new+"?",
                actionType: dropdownItem.previousStatus === "Updated"?"Update":(dropdownItem.previousStatus === "Deleted"?"Delete":"")
            })
        }
    }

    showERPListing = (dropdownItem,e) => {
        e.stopPropagation();
        this.props.handleActionDropdownItemClick(dropdownItem,this.state.currentPageNo,this.state.searchkey,
            this.state.resultSize, this.state.sort, this.state.sortDir, this.state.deptOnly,this.state.status,
            "documentERPList");
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getDocumentMasterList();
        })
    }
    
     //copy data dialog
     showCopyDocumentDialog = () => {
        let selectedDepartmentId = this.state.selectedDepartmentId;
        let selectedDepartmentName = this.state.selectedDepartmentName;

        if(this.state.departmentArr.length > 1){
            selectedDepartmentId="";
            selectedDepartmentName=departmentDropdownDefaultValue;
        }

        this.setState({
            showCopyDocumentDialog:true,
           
            masterType:"Select Master Type",
            selectedDepartmentId:selectedDepartmentId,
            selectedDepartmentName:selectedDepartmentName,
            submitClickedAtleastOnce:false,
            formErrors:{}
        });
    }
    handleCopyDocumentClose = () =>{
        this.setState({
            showCopyDocumentDialog:false,
           
        },()=>{
            this.getDocumentMasterList();
        });
    }
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetDocumentMasterCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetDocumentMaster,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status_new:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    departmentOnly:this.state.deptOnly,
                })
            })
        ])
        .then(([masterRes,generalRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json()]) 
        })
        .then(([masterRes,generalRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;
            let deptTemp = [];
            let docTypes = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    deptTemp = masterRes.data.departments;
                    docTypes = masterRes.data.documentTypes;
            }else{
                deptTemp = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            let selectedDepartmentId = "";
            let selectedDepartmentName = departmentDropdownDefaultValue;

            if(!isEmptyVariable(deptTemp) && deptTemp.length===1){
                selectedDepartmentId = deptTemp[0].departmentId;
                selectedDepartmentName = deptTemp[0].name;
            }

            //Filter Dept array
            let filterDeptArr = [];
            let selectedFilterDepartmentId = "-100";
            let selectedFilterDepartmentName = "All";
            if(!isEmptyArray(deptTemp) && deptTemp.length===1){

            }else{
                let obj = {
                    departmentId:"-100",
                    name:departmentFilterDropdownDefaultValue,
                    isSelected:true
                }
                filterDeptArr = [...deptTemp];
                filterDeptArr.splice(0,0,obj)
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:datalistTemp,
                totalCount:totalCountTemp,
                departmentArr:deptTemp,

                docTypes:docTypes,

                selectedDepartmentId:selectedDepartmentId,
                selectedDepartmentName:selectedDepartmentName,

                filterDeptArr:filterDeptArr,
                selectedFilterDepartmentId:selectedFilterDepartmentId,
                selectedFilterDepartmentName:selectedFilterDepartmentName,
            })
        })
    }

    getDocumentMasterList = () => {
        this.setState({
            showLoader:true,
        });

        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status_new:this.state.status,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
        }

        if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
            postParam.departmentId = this.state.selectedFilterDepartmentId;
        }

        fetch(Constants.GetDocumentMaster,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:page,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status_new:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
            }
    
            if(this.state.filterDeptArr.length > 1 &&
                this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
                postParam.departmentId = this.state.selectedFilterDepartmentId;
            }

            fetch(Constants.GetDocumentMaster,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            documentId:this.state.deleteReactivateId
        }
        let callApi = true;

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteDocumentMaster;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ReactivateDocumentMaster;
        }else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitForReviewDocumentMaster;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.updateReviewStatusDocumentMaster;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Discard Changes"){
            url = Constants.DiscardChangeDocuments;
            postParam.actionType = this.state.actionType;
        }else if(this.state.operationType === "Review Remarks"){
            callApi = false;
            this.setState({
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
            })
        }

        if(callApi){
            this.setState({
                showBtnLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        actionType:"",
                        showAlertDialog:false,
                        showBtnLoader:false,
                    },() => {
                        this.getDocumentMasterList();
                    });
                }else{
                    this.setState({
                        showBtnLoader:false,
                        deleteReactivateId:"",
                        operationType:"",
                        actionType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

    handleCreateMaster = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.selectedDocType) ||
        this.state.selectedDocType===defaultDocTypePlaceholder){
            isError = true;
            FormErrorsTemp['docType'] = "Please select document type";
        }

        if(isEmptyVariable(this.state.name)){
            isError = true;
            FormErrorsTemp['name'] = "Please enter master name";
        }

        if(isEmptyVariable(this.state.shortName)){
            isError = true;
            FormErrorsTemp['shortName'] = "Please enter master short name";
        }

        if(isEmptyArray(this.state.checkedFunctionIds)){
            isError = true;
            FormErrorsTemp['checkedFunctionName'] = "Please select atleast one function";
        }

        if(!isEmptyVariable(this.state.description)){
            if(this.state.description.length > Constants.DESC_STRING_LENGTH){
                isError = true;
                FormErrorsTemp['description'] = "Description must be less then 500 characters";
            }
        }

        if(!this.state.isEdit){
            if(isEmptyVariable(this.state.selectedDepartmentName) || 
            this.state.selectedDepartmentName === departmentDropdownDefaultValue){
                isError = true;
                FormErrorsTemp['deparment'] = "Please select the department";
            }
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";

            if(this.state.isEdit){
                url = Constants.EditDocumentMaster;
            }else{
                url = Constants.CreateDocumentMaster;
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    shortName:this.state.shortName,
                    name:this.state.name,
                    description:this.state.description,
                    departmentId:this.state.selectedDepartmentId,
                    functionIds:JSON.stringify(this.state.checkedFunctionIds),
                    documentId:this.state.selectedId,
                    documentType:this.state.selectedDocType
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showCreateDialog:false,
                        showLoader:false,
                        isEdit:false,
                        selectedId:"",
                        name:"",
                        shortName:"",
                        description:"",
                        checkedFunctionIds:[],
                        selectedDocType:defaultDocTypePlaceholder,
                    },() => {
                        this.getDocumentMasterList();
                    });
                }else{
                    // FormErrorsTemp['apiErr'] = data.responseMessage;
                    toast.error(data.responseMessage);
                    this.setState({
                        // formErrors:FormErrorsTemp,
                        showLoader:false
                    });
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <section>
                {
                    this.state.showLoader && 
                    <div class="loader"></div>
                }
                {
                    // Need to check component did mount flag, other wise until api is called,
                    // it shows no items found layout.
                    this.state.componentDidMountFlag &&
                    <div className="body-wrapper">
                        <div className="search-addnew-bg">
                            <div className="search-addnew">
                                
                                <div className="search-filter-layout">
                                    <FilterDropDown
                                        placeholder={this.state.resultSizePlaceholder}
                                        dropdownArr={this.state.resultSizeArr}
                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                        themeSettings={this.props.themeSettings}
                                        mleft={0}
                                    />
                                    <div className="search-col m-l-10">
                                        <input 
                                            name="searchkey" 
                                            type="text"
                                            onChange={this.handleChangeSearch}
                                            placeholder="Search" 
                                            onKeyPress={this.onEnterBtnPress}
                                            value={this.state.searchkey}
                                        />
                                        <SearchSpan
                                        themeSettings={this.props.themeSettings}
                                        onClick = {this.onSearchIconClick} 
                                        className="material-icons"> search </SearchSpan>
                                    </div>

                                    <FilterDropDown
                                        placeholder={this.state.statusPlaceholder}
                                        dropdownArr={this.state.statusArr}
                                        onDropDownItemClick={this.statusDropdownClick}
                                        themeSettings={this.props.themeSettings}
                                    />
                                    
                                    {
                                        this.state.filterDeptArr.length > 0 &&
                                        <FilterDropDown
                                            placeholder={this.state.selectedFilterDepartmentName}
                                            dropdownArr={this.state.filterDeptArr}
                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                            name={"name"}
                                            themeSettings={this.props.themeSettings}
                                            dropdownWidth={"120px"}
                                        />
                                    }
                                </div>
                                {
                                    this.props.masterMenus[Constants.SCREEN_DOCUMENT] ==="ALL" && (
                                        <div className="flex-center-layout" >
                                            <AddNewButtonLayout themeSettings={this.props.themeSettings} style={{marginRight:10}}>
                                                <a onClick={this.showCopyDocumentDialog} href="javascript:void(0);">
                                                <span class="material-icons" style={{fontSize:15}}>content_copy</span>
                                                    <p>Copy Data</p>
                                                </a>
                                            </AddNewButtonLayout>

                                            <AddNewButtonLayout themeSettings={this.props.themeSettings}>
                                                <a onClick={this.showCreateDialog} href="javascript:void(0);">
                                                    <span className="material-icons">add_circle</span>
                                                    <p>Add New</p>
                                                </a>
                                            </AddNewButtonLayout>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        {
                            !isEmptyArray(this.state.datalist) && 
                            <div className="common-tab-bg">
                                <div className="common-table" style={{width:"100%"}}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                            <th className="text-center" width={"5%"}>No.</th>
                                            
                                            <th  width={"20%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
                                                <div className="sort-header">Name
                                                <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDescription)}>
                                                <div className="sort-header">Description
                                                <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            {
                                                !isEmptyArray(this.state.departmentArr) && this.state.departmentArr.length > 1 && 
                                                <th width={"14%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDeptName)}>
                                                    <div className="sort-header">Department
                                                    <span className={(this.state.sort === sortDeptName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                            }
                                            {/* <th width={"10%"}>Users Count</th> */}
                                            {
                                                this.props.subscription?.docErp == "Y" &&
                                                <th className="text-center"  width={"5%"}>ERP</th>
                                            }
                                            <th className="text-center"  width={"5%"}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            this.state.datalist.map((item,idx) => {

                                                let actionObj = getDocMasterDropdownActionArray(item.allowEdit, item.allowReview, 
                                                    item.status, item.currentReviewStatus, this.props.deletePermission,item.status_new,
                                                    item.previousStatus,this.props.subscription);
                                                let spanObj = getStatusSpan(item.previousStatus,this.props.themeSettings);
                                                return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                    <td>
                                                        {item.name_new}
                                                        {
                                                            this.state.status === "Under Review" &&
                                                            <span style={spanObj.spanStyle} className="span-tag">
                                                                {spanObj.spanText}
                                                            </span>
                                                        }       
                                                    </td>
                                                    <td>{truncateString(item.description_new,Constants.STRING_LENGTH_L)}</td>
                                                    {!isEmptyArray(this.state.departmentArr) && this.state.departmentArr.length > 1 && 
                                                        <td>{item.departmentName}</td>
                                                    }
                                                    {
                                                        this.props.subscription?.docErp == "Y" &&
                                                        <TableIcon 
                                                        themeSettings={this.props.themeSettings} className="text-center">
                                                            <span class="material-icons" onClick={this.showERPListing.bind(this, item)}>visibility</span>
                                                        </TableIcon>
                                                    }
                                                    <td className="text-center">
                                                        {
                                                            actionObj.actionArr && actionObj.actionArr.length > 0 &&
                                                            <TableDropDown 
                                                                actionArr={actionObj.actionArr}
                                                                onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                dropDownId = {item}
                                                                dropdownWidth={actionObj.dropdownWidth}
                                                                themeSettings={this.props.themeSettings}
                                                            />
                                                        }
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {
                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                            <div class="no-items-layout">
                                <div class="no-items-card">
                                    <h6>
                                    {
                                        isEmptyVariable(this.state.apiSearchKey)?
                                        Constants.NO_RECORDS_WARNING
                                        :
                                        Constants.EMPTY_SEARCH_WARNING
                                    }
                                    </h6>
                                </div>
                            </div>
                        }
                        <div className="pagination-layout">
                            <div className="row custom-row">
                                <div className="col-md-9 custom-col">
                                {
                                    (this.state.totalCount > this.state.datalist.length) && 
                                    <Pagination 
                                        totalLength ={this.state.totalCount} 
                                        items={this.state.datalist} 
                                        onChangePage={this.onChangePage} 
                                        pageSize={this.state.resultSize}
                                        currentPageNo = {this.state.currentPageNo}
                                        initialPage={this.state.currentPageNo}
                                        themeSettings={this.props.themeSettings} />
                                }
                                </div>
                                <div className="col-md-3 custom-col">
                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={
                        (this.state.operationType === "Delete")
                        ?
                        Constants.ALERT_TYPE_WARNING
                        :
                        (this.state.operationType === "Review Remarks")
                        ?
                        Constants.ALERT_TYPE_ALERT
                        :
                        Constants.ALERT_TYPE_INFO
                    }
                    alertDialogHeading={this.state.alertDialogHeading}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={this.handleRecordOperation}
                    proceedBtnLabel={this.state.proceedBtnLabel}
                    showLoader={this.state.showBtnLoader}
                    themeSettings={this.props.themeSettings}
                />

                <AlertDialog 
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type= {Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                    themeSettings={this.props.themeSettings}
                />

                <UpdateReviewStatusDialog
                    showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                    handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                    masterDataId = {this.state.deleteReactivateId}
                    reviewLevel={this.state.reviewLevel}
                    themeSettings={this.props.themeSettings}
                />

                <ReviewHistoryDialog
                    showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                    handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                    masterType = {"Document"}
                    masterDataId = {this.state.deleteReactivateId}
                />

                <Modal className="custom-dialog" show={this.state.showCreateDialog} backdrop="static">
                    <Modal.Header>
                        <h5>{this.state.isEdit?"Edit Document":"Add Document"}</h5>
                        <button 
                            onClick={this.handleCreateDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        <div class="addnew-modal-form">
                            {
                                !this.state.isEdit &&
                                !isEmptyArray(this.state.departmentArr) &&
                                this.state.departmentArr.length > 1 &&
                                <div class="addnew-modal-form-group">
                                    <p>Department*</p>
                                    <RegularDropdown 
                                        placeholder={this.state.selectedDepartmentName}
                                        dropdownArr={this.state.departmentArr}
                                        labelParam="name"
                                        onDropDownItemClick={this.deptDropdownClick}
                                        defaultPlaceholderDropDown={departmentDropdownDefaultValue}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['deparment']) &&
                                        <span class="cm-error">{this.state.formErrors['deparment']}</span>
                                    }
                                </div>
                            }
                            <div class="addnew-modal-form-group">
                                <p>Document Type*</p>
                                <RegularDropdown 
                                    placeholder={this.state.selectedDocType}
                                    dropdownArr={this.state.docTypes}
                                    labelParam="documentType"
                                    onDropDownItemClick={this.docTypeDropdownClick}
                                    defaultPlaceholderDropDown = {defaultDocTypePlaceholder}
                                    
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['docType']) &&
                                    <span class="cm-error">{this.state.formErrors['docType']}</span>
                                }
                            </div>
                            <div class="addnew-modal-form-group">
                                <p>Short Name*</p>
                                <input type="text" placeholder="Enter Short Name"
                                name="shortName" value={this.state.shortName} onChange={this.handleChange}/>
                                {
                                    !isEmptyVariable(this.state.formErrors['shortName']) &&
                                    <span class="cm-error">{this.state.formErrors['shortName']}</span>
                                }
                            </div>
                            <div class="addnew-modal-form-group">
                                <p>Name*</p>
                                <input type="text" placeholder="Enter Name" spellCheck="true"  
                                name="name" value={this.state.name} onChange={this.handleChange}/>
                                {
                                    !isEmptyVariable(this.state.formErrors['name']) &&
                                    <span class="cm-error">{this.state.formErrors['name']}</span>
                                }
                            </div>
                            <div className="addnew-modal-form-group">
                                <p>Functions*</p>
                                <div className="dummy-dropdown" tabIndex={0} onKeyPress={this.onKeyBtnPress}  
                                onClick={this.handleMultiSelectFuncDialogShow}>
                                    <p class="placeholder-text">{this.state.checkedFunctionName}</p>
                                    <span class="material-icons">keyboard_arrow_right</span>
                                </div>
                                {
                                    !isEmptyVariable(this.state.formErrors['checkedFunctionName']) &&
                                    <span className="cm-error">{this.state.formErrors['checkedFunctionName']}</span>
                                }
                            </div>
                            <div class="addnew-modal-form-group">
                                <p>Description</p>
                                <textarea type="text" placeholder="Enter Description" spellCheck="true" 
                                name="description" value={this.state.description} onChange={this.handleChange}/>
                                {
                                    !isEmptyVariable(this.state.formErrors['description']) &&
                                    <span class="cm-error">{this.state.formErrors['description']}</span>
                                }
                                {/* {
                                    !isEmptyVariable(this.state.formErrors['apiErr']) &&
                                    <span class="cm-error">{this.state.formErrors['apiErr']}</span>
                                } */}
                            </div>
                        </div>
                        <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton themeSettings={this.props.themeSettings}  onClick={this.handleCreateMaster} type="button">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>

                </Modal>

                <Modal className="custom-dialog" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
                    <Modal.Header>
                        <h5>{this.state.selectedItem.shortName}</h5>
                        <button 
                            onClick={this.handleDetailsDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        <div className="details-table-layout">
                            <div className="row custom-row">
                                <div className="col-md-12 custom-col">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td width={"24%"}>Short Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.shortName_new}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.name_new}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Function</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{SplitJoinString(this.state.selectedItem.functionList,";;",":-:",", ")}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Description</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{ifEmptyReturnEmptyStr(this.state.selectedItem.description_new,"-")}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Department</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.departmentName}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Status</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.status}</td>
                                            </tr>
                                            {
                                                this.state.selectedItem.status !== activeStatus &&
                                                this.state.selectedItem.status !== inActiveStatus &&
                                                <tr>
                                                    <td width={"24%"}>Review Status</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"72%"}>{this.state.selectedItem.currentReviewStatus}</td>
                                                </tr>
                                            }
                                            {
                                                this.state.selectedItem.allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW &&
                                                this.state.selectedItem.currentReviewStatus.includes(requestChangeCheckText) &&
                                                <tr>
                                                    <td width={"24%"}>Review Remarks</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"72%"}>{this.state.selectedItem.lastReviewRemark}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <MultiSelectFunctionDialog
                    showFunctionsDialog={this.state.showMultiSelectFunctionTreeDialog}
                    selectedFunctionIdsArr={this.state.checkedFunctionIds}
                    handleSelectFuncDialogClose={this.handleMultiSelectFunctionTreeDialogClose}
                    handleSelectFunctionDialogSaveClose={this.handleMultiSelectSubmit}
                    themeSettings={this.props.themeSettings}
                    departmentId={this.state.selectedDepartmentId}
                />
                <UploadDocumentSampleDialog
                    showUploadDocSampleDialog={this.state.showUploadDocSampleDialog}
                    handleUploadSampleDocDialogClose={this.handleUploadSampleDocDialogClose}
                    documentId={this.state.deleteReactivateId}
                    themeSettings={this.props.themeSettings}
                />
                <SelectCopyDocumentDialog
                    showCopyDocumentDialog={this.state.showCopyDocumentDialog}
                    handleCopyDocumentClose={this.handleCopyDocumentClose}
                    documentId={this.state.selectedDocumentId}
                    documentName={this.state.selectedDocumentName}
                    handleBackBtnClickFromSamplesandERP={this.handleBackBtnClickFromSamplesandERP}
                    themeSettings={this.props.themeSettings}
                    subscriptionType ={this.props.subscriptionType}
                />
            </section>
        );
    }
}

export default OtherMasters;