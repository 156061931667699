import React, { Component } from 'react'
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import Sidebar from "../../../common/SidebarCollapse";
import Topbar from "../../../common/Topbar";
import MasterMenuTabs from '../Common/topmenu';
import * as Constants from '../../../common/Global/constants';
import {PageSaveButton,AddNewButtonLayout, CustomTableRow, TableIcon, AlertInfo} from '../../../common/Global/globalStyles.style';
import {GetOrderedMasterTabMenuExecute, getLocalStorageVariables,getStringWithSpaceAfterComma,getThemeSettingsVariables, isEmptyArray, isEmptyVariable} from '../../../common/Global/commonFunctions';
import TopmenuReports from '../../../common/TopmenuReports';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import AlertDialog from '../../../common/AlertDialog';
import SelectFunctionDialog from '../Common/selectFunction';
import { toast } from "react-toastify";


const themeSettings = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();
const format = 'HH:mm';
const functionDropdownDefaultValue = "Select Function";
const typeDefaultPlaceholder = "Select Type";

class Timeline extends Component {
    constructor(props){
        super(props);
        this.state = {
            assignmentId: this.props.match.params.id,
            showLoader: false,
            componentDidMountFlag: false,
            assignmentArea:"",
            typeArr:[],
            typePlaceholder:typeDefaultPlaceholder,
            functionArr:[],
            extraFunctions:[],
            selectedScopeObj:[],
            employeeArr:[],
            employeePlaceholder:"Select Employee",
            timeLineData:[],
            addNewRowIsVisible:false,
            timeLineTypeErr:"",
            startDateErr:"",
            formErrors:{},
            showAlertDialog:false,
            alertDialogMessage:"",
            showDeleteTimelineAlertDialog:false,
            deleteTimelineAlertDialogMessage:"",
			rootFunctionId: "",
			showFunctionsTreeDialog: false,
			idToBeExpandedAfterAddingChild: "",
            selectedFunctionObjArr:[],
            isSavebuttonDisable:false,
            shouldReset:false
        }
    }

    componentDidMount(){
        // window.addEventListener('beforeunload', this.handleClickOutside);
        this.getInitData();
    }

    componentWillUnmount(){
        // window.removeEventListener('beforeunload', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
        event.preventDefault();
        let timeLineDataFilter = this.state.timeLineData.filter(e => e.isEditable == true && !isEmptyVariable(e.timelineType) && !isEmptyVariable(e.startDate));
        if(timeLineDataFilter.length > 0){
            this.setState({
                showAlertDialog:true,
                alertDialogMessage:"There is unsaved data. Do you want to save the data?"
            })
            //for back button
            // return event.returnValue = 'There is unsaved data. Do you want to save the data?';
        }
    }

    typeDropdownItemClick = (selectedItem,timelineOrderId) => {
        let tempTimelineData = this.state.timeLineData;
        let tempTypeArray = this.state.typeArr;
        tempTypeArray.map((item) => {
            if (item.label === selectedItem.label) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });
        Object.values(tempTimelineData).map(item => {
            if(timelineOrderId === item.timelineOrderId){
                if(selectedItem.label === "Other"){
                    item.typeOther = true
                }
                item.timelineType = (selectedItem.label=="Other"?"":selectedItem.label)
            }
            return item;
        })
        this.setState({
            timeLineData:tempTimelineData,
            typeArr:tempTypeArray,
        })
    }

    //Reset dropdown value
    resetTypeDropDown = (timelineOrderId) => {
        let tempTimelineData = this.state.timeLineData;
        let tempTypeArray = this.state.typeArr;
        tempTypeArray.map((item) => {
            item.isSelected = false;
        });
        tempTimelineData.map(item => {
            if(timelineOrderId === item.timelineOrderId){
                item.timelineType = "";
                item.typeOther = false
            }
            return item;
        })
        
		this.setState({
            timeLineData:tempTimelineData,
            typeArr:tempTypeArray
        })
    }

    employeeName = (item) => {
        let arr = item.employeeId;
        let selectedEmpObj = []
        let name = [];
        arr.map(stringItem => {
            this.state.employeeArr.filter((e) => {
                if(e.employeeId == stringItem){
                    name.push(e.employeeName);
                    selectedEmpObj.push(e.employeeId)
                }
            })
        })
        
        if(item.isEditable){
            return selectedEmpObj;
        }else{
            return getStringWithSpaceAfterComma(name.toString());
        }
    }

    //Drag & Drop functionality
    onDragEnd = (result) => {
        const {destination, source} = result;
        //Outside the droppable
        if(!destination){
            return
        }

        //if draggable is dropped in same droppable and in same position
        if(destination.droppableId === source.droppableId &&
        destination.index === source.index){
            return
        }

        let newPosition = destination.index;
        let oldPosition = source.index;
        let timeLineData = this.state.timeLineData;

        let fieldObj = timeLineData[oldPosition];
        timeLineData.splice(oldPosition,1)//remove from old position
        timeLineData.splice(newPosition,0,fieldObj);//move the field Obj to new pos

        let timeLineDataNew = timeLineData.map((item,idx)=>{
            item.timelineOrderId = idx+1;
            return item;
        })
        this.setState({
            timeLineData:timeLineDataNew
        })
    }

    //delete timeline row
    handleDeleteTimeline = (idx) => {
        let tempRow = this.state.timeLineData;
        tempRow.splice(idx-1,1);

        this.setState({
            showDeleteTimelineAlertDialog:false,
            deleteTimelineAlertDialogMessage:""
        })

        //Manage Save & Add Row button visibility
        if(tempRow.length === 0){
            this.setState({
                addNewRowIsVisible:true
            })
        }
        
        //Manage Add Row button visibility
        tempRow.filter((item,index) => {
            item.timelineOrderId = index+1;
            if(isEmptyVariable(item.timelineType) && isEmptyVariable(item.startDate)){
                this.setState({
                    addNewRowIsVisible:false
                })
            }
                
            if(!isEmptyVariable(item.timelineType) && !isEmptyVariable(item.startDate)){
                this.setState({
                    addNewRowIsVisible:true
                })
            }
        })

        this.setState({
            timeLineData:tempRow
        })
    }

    //edit row
    handleEditTimeline = (timelineOrderId) => {
        let tempTimelineData = this.state.timeLineData;
        tempTimelineData.map(item => {
            if(timelineOrderId === item.timelineOrderId){
                item.isEditable = true;
                const type = this.state.typeArr.filter(tempItem => {
                    if(tempItem.label == item.timelineType){
                        return tempItem
                    }
                })
                if(type.length == 0){
                    item.typeOther = true
                }
            }
            return item;
        })
        this.setState({
            timeLineData:tempTimelineData
        })
    } 

    //save row
    handleSaveTimelineValidation = (timelineOrderId) => {
        let tempTimelineData = this.state.timeLineData;
        let isError = false;
        let FormErrorsTemp = {};
        tempTimelineData.map(item => {
            if(timelineOrderId === item.timelineOrderId){
                if(isEmptyVariable(item.timelineType)){
                    isError = true;
                    FormErrorsTemp[`${item.timelineOrderId}_type`] = "Please select type"
                }
                if(isEmptyVariable(item.startDate)){
                    isError = true;
                    FormErrorsTemp[`${item.timelineOrderId}_date`] = "Please select date"
                }
            }
        })
        if(isError){
            this.setState({
                showLoader:false,
                formErrors:FormErrorsTemp,
            })
            setTimeout(()=>{
                this.setState({
                    formErrors: {},
                })
            },Constants.WRNG_MSG_TIMEOUT);
        }else {
            let timeLineDataArr = [];

            //set initialTimeLineObj object in timeLineData for blank row
            let initialTimeLineObj = {
                executeTimelineId:"",
                assignmentId:this.state.assignmentId,
                functionId:[],
                employeeId:[],
                startDate:"",
                startTime:"",
                endDate:"",
                endTime:"",
                timelineType:"",
                timelineOrderId:tempTimelineData.length+1,
                isEditable:true,
                typeOther:false
            }

            tempTimelineData.map(item => {
                if(timelineOrderId === item.timelineOrderId){
                    if(!isEmptyVariable(item.timelineType) && !isEmptyVariable(item.startDate)){
                        item.isEditable=false;
                    }else{
                        item.isEditable=true;
                    }
                }
                return item;
            })

            //Filter empty row
            const arr = tempTimelineData.filter(item => isEmptyVariable(item.timelineType) || isEmptyVariable(item.startDate))
            if(arr.length > 0){
                timeLineDataArr.push(...tempTimelineData);
            }else{
                timeLineDataArr.push(...tempTimelineData,initialTimeLineObj);
            }

            this.setState({
                timeLineData:timeLineDataArr,
                addNewRowIsVisible:false,
                formErrors:{},
            })
        }
    };

    handleOnChange = (timelineOrderId,fieldName,value) => {
        let tempTimelineData = this.state.timeLineData;
        tempTimelineData.map(item => {
            if(timelineOrderId === item.timelineOrderId){
                switch (fieldName) {
                    case "startDate":
                         if (!isEmptyVariable(value)) {
                            if(item.endDate < value.format("YYYY-MM-DD") && !isEmptyVariable(item.endDate)){
                                toast.warning("End date cannot be before start date");
                            }else{
                                item.startDate = value.format("YYYY-MM-DD");
                            }
                        }
                        break;
                    case "startTime":
                         if (!isEmptyVariable(value)) {
                            item.startTime = value.format("HH:mm");
                        }
                        break;
                    case "endDate":
                         if (!isEmptyVariable(value)) {
                            if(value.format("YYYY-MM-DD") < item.startDate && !isEmptyVariable(value.format("YYYY-MM-DD"))){
                                toast.warning("End date cannot be before start date");
                            }else{
                                item.endDate = value.format("YYYY-MM-DD");
                            }
                        }
                        break;
                    case "endTime":
                         if (!isEmptyVariable(value)) {
                            item.endTime = value.format("HH:mm");
                        }
                        break;
                    case "timelineType":
                         item.timelineType = value.target.value;
                         item.typeOther = true;
                        break;
                }
            }
        })
        this.setState({
            timeLineData:tempTimelineData,
        })
    }
    
    //get Function/Process name
    functionName = (item) => {
        let arr = item.functionId;
        let selectedScopeObj = []
        let name = [];
        let tempFunctionArray = this.state.assignmentArea==="Function"?this.state.extraFunctions:this.state.functionArr;
        arr.map(stringItem => {
            tempFunctionArray.filter((scopeItem) => {
                if(scopeItem.functionId == stringItem){
                    name.push(scopeItem.functionName);
                    selectedScopeObj.push(scopeItem.functionId)
                }
            })
        })
        if(item.isEditable){
            return this.state.assignmentArea==="Function"?selectedScopeObj:getStringWithSpaceAfterComma(name.toString())    
        }else{
            return getStringWithSpaceAfterComma(name.toString());
        }
    }

    addSelection = (timelineOrderId,args) => {
        let timeline = this.state.timeLineData;
        timeline.map(timeLineItem => {
            if(timelineOrderId === timeLineItem.timelineOrderId){
                if(!isEmptyVariable(args.itemData.functionId)){
                    timeLineItem.functionId.push(args.itemData.functionId);
                }
                if(!isEmptyVariable(args.itemData.employeeId)){
                    timeLineItem.employeeId.push(args.itemData.employeeId);
                }
                return timeLineItem;
            }
        })
        this.setState({
            timeLineData:timeline
        })
    }

    removeSelection = (timelineOrderId,args) => {
        let timeline = this.state.timeLineData;
        timeline.map(timeLineItem => {
            if(timelineOrderId === timeLineItem.timelineOrderId){
                if(!isEmptyVariable(args.itemData.functionId)){
                    timeLineItem.functionId = timeLineItem.functionId.filter(e => e != args.itemData.functionId)
                }
                if(!isEmptyVariable(args.itemData.employeeId)){
                    timeLineItem.employeeId = timeLineItem.employeeId.filter(e => e != args.itemData.employeeId)
                }
                return timeLineItem;
            }
        })
        this.setState({
            timeLineData:timeline
        })
    }

    //Add blank row
    addNewRow = () => {
        let timeLineData = this.state.timeLineData;
        let tempTimeLineData = [];
        let initialTimeLineObj = {
            executeTimelineId:"",
            assignmentId:this.state.assignmentId,
            functionId:[],
            employeeId:[],
            startDate:"",
            startTime:"",
            endDate:"",
            endTime:"",
            timelineType:"",
            timelineOrderId:timeLineData.length+1,
            isEditable:true,
            typeOther:false
        }
        tempTimeLineData.push(...timeLineData,initialTimeLineObj);
        if(tempTimeLineData.length>0){
            this.setState({
                addNewRowIsVisible:false
            })
        }
        this.setState({
            timeLineData:tempTimeLineData
        })
    }

    handleAlertDialogClose = () => {
        this.setState({
            showAlertDialog:false
        })
    }

    handleShowDeleteTimelineAlertDialog = (timelineOrderId) => {
        this.setState({
            showDeleteTimelineAlertDialog:true,
            deleteTimelineAlertDialogMessage:"Do you want to delete the timeline record?",
            timelineOrderId:timelineOrderId
        })
    }

    handleDeleteTimelineAlertDialogClose = () => {
        this.setState({
            showDeleteTimelineAlertDialog:false,
            deleteTimelineAlertDialogMessage:"",
            timelineOrderId:""
        })
    }

    //functionTree Modal for process/checklist code start here
    handleSelectFunctionDialogClose = () => {
        this.setState({
            showSelectFunctionDialog:false,
        })
    }

    handleSelectFunctionDialogSaveClose = (selectedFunctionObjArr) => {
        let functionIds = []; 
        let timeline = this.state.timeLineData;
        selectedFunctionObjArr.map(item=>{
            functionIds.push(item.functionId)
        })
        let tmpAllFuncArr = this.state.functionArr;
        let selectedEmpFucIds = tmpAllFuncArr.map(e=>e.functionId) ||[];
        selectedFunctionObjArr.map(e=>{
            if(!selectedEmpFucIds.includes(e.functionId)){
                tmpAllFuncArr.push({
                    functionId:e.functionId,
                    functionName:e.functionName
                })
            }
        });

        //add selected value in functionArr
        timeline.map(timeLineItem => {
            if(this.state.timelineOrderId === timeLineItem.timelineOrderId){
                timeLineItem.functionId = functionIds;
                if(this.state.assignmentArea==="Function"){
                    this.state.extraFunctions.filter(tempItem => {
                        timeLineItem.functionId.map(e => {
                            if(e == tempItem.functionId){
                                tmpAllFuncArr.push(tempItem)
                            }
                        })
                    })
                }
                //duplicate value remove from array
                tmpAllFuncArr = Array.from(new Set(tmpAllFuncArr.map(JSON.stringify))).map(JSON.parse);
            }
        })
        
        this.setState({
            showSelectFunctionDialog:false,
            selectedFunctionObjArr:selectedFunctionObjArr,
            functionArr:tmpAllFuncArr,
            timeLineData:timeline
        })
    }

    handleSelectFunctionDialogShow = (timelineOrderId,functionId) => {
        let selectedFunctionObjArr = [];
        functionId.map(e => {
            selectedFunctionObjArr.push({
                functionId:e
            })
        })

        this.setState({
            selectedFunctionObjArr:selectedFunctionObjArr,
            timelineOrderId:timelineOrderId,
            showSelectFunctionDialog:true,
        })
    }
    /*******************API CALLS*******************/

    getInitData = () => {
        this.setState({
            showLoader: true,
        });

        Promise.all([
            fetch(Constants.GetAssignmentExecuteTimeline, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                }),
            }),
            fetch(Constants.GetUserMenu, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                }),
            }),
            fetch(Constants.GetAssignmentsDetails, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                }),
            }),
        ])
        .then(([timelineRes, menuRes, assignmentRes]) => {
            return Promise.all([
                timelineRes.json(),
                menuRes.json(),
                assignmentRes.json()
            ]);
        })
        .then(([timelineRes, menuRes, assignmentRes]) => {
            let menusTemp = {};
            let companyTemp = {};
            let assignmentArea = "";
            let assignmentDetails = {};
            let type = [];
            let employeeData = [];
            let tempTypeArray = [];
            let tempFunctionArr = [];
            let tempEmployeeArr = [];
            let functionArr = [];
            let timeline = [];
            let extraFunctions = [];
            let assignmentStatus = "";

            if (
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                assignmentArea = assignmentRes.data.assignmentDetails.assignmentArea;
                assignmentDetails = assignmentRes.data.assignmentDetails;
                assignmentStatus = assignmentRes.data.assignmentDetails.assignmentStatus;
            } else {
            }
            
            if (
                timelineRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                timelineRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (timelineRes.responseCode === Constants.CODE_SUCCESS) {
                type = timelineRes.data.standardTimeline;
                employeeData = timelineRes.data.employeeData;
                functionArr = timelineRes.data.functionData;
                timeline = timelineRes.data.timeline;
                extraFunctions = timelineRes.data.extraFunctions;
                if(timeline.length > 0){
                    timeline.map(item => {
                        item.startDate = !isEmptyVariable(item.startDate)?moment(item.startDate).format("YYYY-MM-DD"):""
                        item.endDate = !isEmptyVariable(item.endDate)?moment(item.endDate).format("YYYY-MM-DD"):""
                        item.functionId = !isEmptyVariable(item.functionId)?JSON.parse(item.functionId):[]
                        item.employeeId = !isEmptyVariable(item.employeeId)?JSON.parse(item.employeeId):[]
                        item.isEditable = false
                        item.typeOther = false
                        //add selected value in functionArr
                        if(assignmentArea==="Function"){
                            extraFunctions.filter(tempItem => {
                                item.functionId.map(e => {
                                    if(e == tempItem.functionId){
                                        functionArr.push(tempItem)
                                    }
                                })
                            })
                            
                        }
                    })
                    //duplicate value remove from array
                    functionArr = Array.from(new Set(functionArr.map(JSON.stringify))).map(JSON.parse);

                    this.setState({
                        addNewRowIsVisible:true
                    })
                }else{
                    if(assignmentStatus !== Constants.ASSIGNMENT_STATUS_DRAFT){
                        let initialTimeLineObj = {
                            executeTimelineId:"",
                            assignmentId:this.state.assignmentId,
                            functionId:[],
                            employeeId:[],
                            startDate:"",
                            startTime:"",
                            endDate:"",
                            endTime:"",
                            timelineType:"",
                            timelineOrderId:timeline.length+1,
                            isEditable:true,
                            typeOther:false
                        }
                        timeline.push(initialTimeLineObj);
                    }
                }

                Object.values(type).map((item)=>{
                    tempTypeArray.push({
                        label:item.dataTypeName,
                        isSelected: false
                    })
                })
                tempTypeArray.push({
                    label:"Other",
                    isSelected:false
                })
                
                Object.values(functionArr).map((item)=>{
                    tempFunctionArr.push(item);
                })

                Object.values(employeeData).map((item)=>{
                    tempEmployeeArr.push(item);
                })
            } else {
            }

            if (
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                menusTemp = menuRes.data.menus;
                companyTemp = menuRes.data.company;
            } else {
                menusTemp = {};
            }
            let menusTemp2 = GetOrderedMasterTabMenuExecute(
                menusTemp,
                assignmentDetails.observationTemplateId
            );

            this.setState({
                componentDidMountFlag: true,
                showLoader: false,
                menus: menusTemp2,
                company: companyTemp,
                assignmentDetails: assignmentDetails,
                assignmentArea: assignmentArea,
                assignmentStatus:assignmentStatus,
                typeArr: tempTypeArray,
                employeeArr: tempEmployeeArr,
                functionArr: tempFunctionArr,
                timeLineData:timeline,
                selectedDepartmentId:assignmentDetails.departmentId,
                extraFunctions:extraFunctions
            });
        });
    };

    getAssignmentExecuteTimeline = () => {
        this.setState({
			showLoader: true,
		});

		fetch(Constants.GetAssignmentExecuteTimeline, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentId: this.state.assignmentId,
            }),
		})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			} else if (data.responseCode === Constants.CODE_SUCCESS) {
				let timeline = data.data.timeline;
                let functionArr = data.data.functionData;
                if(timeline.length > 0){
                    timeline.map(item => {
                        item.startDate = !isEmptyVariable(item.startDate)?moment(item.startDate).format("YYYY-MM-DD"):""
                        item.endDate = !isEmptyVariable(item.endDate)?moment(item.endDate).format("YYYY-MM-DD"):""
                        item.functionId = !isEmptyVariable(item.functionId)?JSON.parse(item.functionId):[]
                        item.employeeId = !isEmptyVariable(item.employeeId)?JSON.parse(item.employeeId):[]
                        item.isEditable = false
                        item.typeOther = false
                        if(this.state.assignmentArea==="Function"){
                            this.state.extraFunctions.filter(tempItem => {
                                item.functionId.map(e => {
                                    if(e == tempItem.functionId){
                                        functionArr.push(tempItem)
                                    }
                                })
                            })
                        }
                        return item;
                    })
                    functionArr = Array.from(new Set(functionArr.map(JSON.stringify))).map(JSON.parse);
                    this.setState({
                        addNewRowIsVisible:true,
                    })
                }else{
                    let initialTimeLineObj = {
                        executeTimelineId:"",
                        assignmentId:this.state.assignmentId,
                        functionId:[],
                        employeeId:[],
                        startDate:"",
                        startTime:"",
                        endDate:"",
                        endTime:"",
                        timelineType:"",
                        timelineOrderId:timeline.length+1,
                        isEditable:true,
                        typeOther:false
                    }
                    timeline.push(initialTimeLineObj);
                    this.setState({
                        addNewRowIsVisible:false,
                    })
                }

                this.setState({
                    showLoader:false,
                    timeLineData:timeline,
                    functionArr:functionArr
                })
			} else {
				this.setState({
                    showLoader:false
                })
			}
		});
    }
    
    handleSaveTimeline = (isSave) => {
        this.setState({
            showLoader: true,
            isSavebuttonDisable:true
        });

        setTimeout(()=>{
            this.setState({
                isSavebuttonDisable:false
            })
        },Constants.WRNG_MSG_TIMEOUT);
        
        let isError = false;
        let FormErrorsTemp = {};

        let timeLineData = this.state.timeLineData;

        //Filter editable row
        let timeLineDataFilter = timeLineData.filter(e => e.isEditable == true && !isEmptyVariable(e.timelineType) && !isEmptyVariable(e.startDate));
        if(isSave == false){
            //set error if timeline data is in editable mode
            if(timeLineDataFilter.length > 0){
                isError = true;
                toast.warning(`Please save No.${timeLineDataFilter[0].timelineOrderId} timeline data`)
            }
        }
        if (isError) {
			this.setState({
				formErrors: FormErrorsTemp,
				showLoader: false,
			});
            setTimeout(()=>{
                this.setState({
                    formErrors: {},
                })
            },Constants.WRNG_MSG_TIMEOUT);
		} else {
            let timeLineDataEmpty = timeLineData.filter(e => e.isEditable == true && isEmptyVariable(e.timelineType) && isEmptyVariable(e.startDate));
            let timeLineDataNotEmpty = timeLineData.filter(e => !isEmptyVariable(e.timelineType) && !isEmptyVariable(e.startDate));

            if(timeLineDataEmpty.length === 1 && isEmptyArray(timeLineDataNotEmpty)){
                toast.warning("Please fill mandatory details");
                this.setState({
                    showLoader: false
                })
            }else{
                //Remove editable row from timelinedata & unnecessary key/value remove from timelinedata array
                timeLineData.forEach((item,index) => {
                    delete item['typeOther'];
                    //data is in edit mode and user not save particular row then data row remove
                    if(isSave == false){
                        if(item.isEditable == true && isEmptyVariable(item.timelineType) || isEmptyVariable(item.startDate)){
                            timeLineData.splice(index,1)
                        }
                    }
                    delete item['isEditable'];
                    return item;
                })
                //Again set timelineorder id after editable row delete
                timeLineData.map((item,index) => {
                    item.timelineOrderId = index+1;
                })
                
                fetch(Constants.UpdateAssignmentExecuteTimeline,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams({
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        assignmentId:this.state.assignmentId,
                        timelineData:JSON.stringify(timeLineData)
                    })
                })
                .then(response => { return response.json(); } )
                .then(data =>
                {
                    if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                        localStorage.clear();
                        window.location="/";
                    }else if(data.responseCode === Constants.CODE_SUCCESS){
                        this.getAssignmentExecuteTimeline();
                        this.setState({
                            showLoader:false,
                            formErrors: {},
                            showAlertDialog:false,
                            alertDialogMessage:""
                        },()=>{
                            toast.success(`Assignment Timeline updated successfully`)
                        })
                    }else{
                        // FormErrorsTemp["apiErr"] = data.responseMessage;
                        this.setState({
                            showLoader:false,
                            formErrors: FormErrorsTemp,
                            showAlertDialog:false,
                            alertDialogMessage:""
                        },()=>{
                            toast.error(data.responseMessage)
                        })
                    }
                });
            }
        }
    }
    /*******************API CALLS END HERE*******************/

    render() {
        let fields = {}
        let empFields = {}
        fields = {text:"functionName",value:"functionId"}
        empFields = {text:"employeeName",value:"employeeId"}

        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />
                        <div className="dashboard-right-bg-collpase">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    <div className="d-flex align-items-center">
                                        <div className="flex-fill">
                                            <MasterMenuTabs
                                                assignmentId={this.state.assignmentId}
                                                masterMenus={this.state.menus}
                                                themeSettings={themeSettings}
                                                timeLineData={this.state.timeLineData}
                                                saveTimeLineData={this.handleSaveTimeline.bind(this,true)}
                                            />
                                        </div>
                                        <div>
                                            <TopmenuReports
                                                assignmentId={this.state.assignmentId}
                                                userDetails ={userDetails}
                                                themeSettings={themeSettings}
                                                company={this.state.company}
                                            />
                                        </div>
                                    </div>
                                }                       
                                {
                                    this.state.showLoader && (
                                    <div class="loader"></div>
                                )}

                                    <div className="flex-center-layout mt-2 mb-2" style={{justifyContent:"right"}}>
                                        {this.state.assignmentStatus !== Constants.ASSIGNMENT_STATUS_DRAFT && this.state.addNewRowIsVisible &&
                                            <AddNewButtonLayout themeSettings={themeSettings}>
                                                <a onClick={this.addNewRow}>
                                                    <span class="material-icons" style={{fontSize:15}}>add_circle</span>
                                                    <p>Add Row</p>
                                                </a>
                                            </AddNewButtonLayout>
                                        }
                                        {this.state.assignmentStatus !== Constants.ASSIGNMENT_STATUS_DRAFT &&
                                            <PageSaveButton 
                                                style={{marginLeft:15, cursor: this.state.isSavebuttonDisable ? 'not-allowed': 'pointer'}}
                                                themeSettings={themeSettings}
                                                disabled={this.state.isSavebuttonDisable}
                                                onClick={this.handleSaveTimeline.bind(this,false)} 
                                                type="button">
                                                    Save
                                            </PageSaveButton>
                                        }
                                    </div>
                                    {/* <div className="flex-center-layout mt-2 mb-2" style={{justifyContent:"right"}}>
                                        {!isEmptyVariable(this.state.formErrors["timeline"]) && <span class="cm-error mt-2 mb-2">{this.state.formErrors["timeline"]}</span>}
                                        {!isEmptyVariable(this.state.formErrors["apiErr"]) && <span class="cm-error mt-2 mb-2">{this.state.formErrors["apiErr"]}</span>}
                                    </div> */}
                                
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper mt-2">
                                        <div className="common-tab-bg">
                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                <div className="common-table" style={{width:"100%",borderRadius:4}}>
                                                    <div className="table-custom-layout">
                                                        <div className="table-custom-header">
                                                            <div className="table-custom-header-row">
                                                                <p style={{width:"4%",justifyContent:"center", minHeight: "42px"}}>No.</p>
                                                                <p style={{width:"15%",marginRight:10}}>Type*</p>
                                                                <p style={{width:"17%",marginRight:10}}>Function</p>
                                                                <p style={{width:"15%"}}>Employee</p>
                                                                <p style={{width:"10%"}}>Start Date*</p>
                                                                <p style={{width:"10%"}}>Start Time</p>
                                                                <p style={{width:"10%"}}>End Date</p>
                                                                <p style={{width:"10%"}}>End time</p>
                                                                {this.state.assignmentStatus !== Constants.ASSIGNMENT_STATUS_DRAFT &&
                                                                    <p style={{width:"10%",justifyContent:"center"}}></p>
                                                                }
                                                            </div>
                                                        </div>
                                                        <Droppable droppableId={"droppable"}>
                                                            {(provided)=>(
                                                                <div className="table-custom-body"
                                                                    ref={provided.innerRef}
                                                                    {...provided.droppableProps}>
                                                                    {Object.values(this.state.timeLineData).map((item,idx)=>{
                                                                    let assignmentArea = this.state.assignmentArea; 
                                                                    return(
                                                                        <Draggable draggableId={item.timelineOrderId+""} index={idx} key={item.timelineOrderId+""} 
                                                                            isDragDisabled={this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_DRAFT?true:isEmptyVariable(item.timelineType)&&isEmptyVariable(item.startDate)}>
                                                                                {(provided,snapshot) => (
                                                                                    <CustomTableRow
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        ref={provided.innerRef}
                                                                                        isDragging={snapshot.isDragging}
                                                                                        isDragDisabled={this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_DRAFT?true:isEmptyVariable(item.timelineType)&&isEmptyVariable(item.startDate)}
                                                                                        themeSettings={themeSettings}
                                                                                    >
                                                                                        <p style={{width:"4%", justifyContent:"center"}}>{item.timelineOrderId}</p>
                                                                                        <p style={{width:"15%",marginRight:10}}>
                                                                                            {item.isEditable?
                                                                                            (item.typeOther==false)?
                                                                                                <div style={{width:"100%"}}>    
                                                                                                    <RegularDropdown
                                                                                                        placeholder={isEmptyVariable(item.timelineType)?this.state.typePlaceholder:item.timelineType}
                                                                                                        dropdownArr={this.state.typeArr}
                                                                                                        labelParam="label"
                                                                                                        onDropDownItemClick={this.typeDropdownItemClick.bind(item.timelineOrderId)}
                                                                                                        udf={item.timelineOrderId}
                                                                                                        defaultPlaceholderDropDown={typeDefaultPlaceholder}
                                                                                                    />
                                                                                                    {
                                                                                                        !isEmptyVariable(this.state.formErrors[`${item.timelineOrderId}_type`]) &&
                                                                                                        <span className="cm-error">{this.state.formErrors[`${item.timelineOrderId}_type`]}</span>
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <div style={{width:"100%"}}>
                                                                                                    <div className="flex-center-layout addnew-modal-form-group" style={{marginBottom:0}}>
                                                                                                        <input 
                                                                                                            type="text" 
                                                                                                            style={{marginRight:10, padding:"4px 12px"}}
                                                                                                            placeholder="Enter Other Type" 
                                                                                                            name="other"
                                                                                                            onChange={this.handleOnChange.bind(this,item.timelineOrderId,"timelineType")}
                                                                                                            value={item.timelineType}
                                                                                                        />
                                                                                                        <TableIcon themeSettings={themeSettings} className="text-center" 
                                                                                                        style={{marginLeft:10}}>
                                                                                                            <span 
                                                                                                                className="material-icons"
                                                                                                                onClick={this.resetTypeDropDown.bind(this,item.timelineOrderId)}
                                                                                                            >
                                                                                                                restart_alt
                                                                                                            </span>
                                                                                                        </TableIcon>
                                                                                                    </div>
                                                                                                    {
                                                                                                        !isEmptyVariable(this.state.formErrors[`${item.timelineOrderId}_type`]) &&
                                                                                                        <span className="cm-error">{this.state.formErrors[`${item.timelineOrderId}_type`]}</span>
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                item.timelineType
                                                                                            }
                                                                                        </p>
                                                                                        <div style={{width:"17%",marginRight:10,padding:"0px 10px"}}>
                                                                                            {
                                                                                                assignmentArea!=="Function"?
                                                                                                <div className={item.isEditable && "dummy-dropdown"} tabIndex={0}  
                                                                                                    onClick={item.isEditable?this.handleSelectFunctionDialogShow.bind(this,item.timelineOrderId,item.functionId):null}>
                                                                                                    <p class="placeholder-text">
                                                                                                        {isEmptyArray(item.functionId)
                                                                                                        ?item.isEditable&&functionDropdownDefaultValue
                                                                                                        :this.functionName(item)}
                                                                                                    </p>
                                                                                                </div>
                                                                                                :
                                                                                                item.isEditable? 
                                                                                                <div className="flex-center-layout addnew-modal-form-group mt-1 mb-0">
                                                                                                    <MultiSelectComponent 
                                                                                                        id={"multi"+idx}
                                                                                                        dataSource={this.state.functionArr}
                                                                                                        fields={fields}
                                                                                                        placeholder={"Select Function"} 
                                                                                                        mode="CheckBox"
                                                                                                        closePopupOnSelect={true}
                                                                                                        allowFiltering={false}
                                                                                                        showSelectAll={true}
                                                                                                        readOnly={true}
                                                                                                        cssClass="clientsChecklist dialogcl"
                                                                                                        showDropDownIcon={true}
                                                                                                        enableSelectionOrder={true}
                                                                                                        showClearButton={false}
                                                                                                        select={this.addSelection.bind(this,item.timelineOrderId)}
                                                                                                        removed={this.removeSelection.bind(this,item.timelineOrderId)}
                                                                                                        value={this.functionName(item)}
                                                                                                    >
                                                                                                        <Inject services={[CheckBoxSelection]} />
                                                                                                    </MultiSelectComponent>
                                                                                                    <TableIcon themeSettings={themeSettings} className="text-center" style={{marginLeft:10, marginBottom:6}}>
                                                                                                        <a className="tool" data-title="Add more function"
                                                                                                        onClick={this.handleSelectFunctionDialogShow.bind(this,item.timelineOrderId,item.functionId)}>
                                                                                                            <span className="material-icons">add_circle</span>
                                                                                                        </a>
                                                                                                    </TableIcon>
                                                                                                </div>
                                                                                                :
                                                                                                <p>{this.functionName(item)}</p>
                                                                                            }
                                                                                        </div>
                                                                                        <div style={{width:"15%",padding:"0px 10px"}}>
                                                                                            {item.isEditable?
                                                                                                <div className="addnew-modal-form-group mt-1 mb-0">
                                                                                                    <MultiSelectComponent 
                                                                                                        id={"multi"+idx}
                                                                                                        dataSource={this.state.employeeArr}
                                                                                                        fields={empFields}
                                                                                                        placeholder={"Select Employee"} 
                                                                                                        mode="CheckBox"
                                                                                                        closePopupOnSelect={false}
                                                                                                        allowFiltering={false}
                                                                                                        readOnly={true}
                                                                                                        cssClass="clientsChecklist dialogcl"
                                                                                                        showDropDownIcon={true}
                                                                                                        enableSelectionOrder={true}
                                                                                                        showClearButton={false}
                                                                                                        select={this.addSelection.bind(this,item.timelineOrderId)}
                                                                                                        removed={this.removeSelection.bind(this,item.timelineOrderId)}
                                                                                                        value={this.employeeName(item)}
                                                                                                    >
                                                                                                        <Inject services={[CheckBoxSelection]} />
                                                                                                    </MultiSelectComponent>
                                                                                                </div>
                                                                                                :
                                                                                                <p>{this.employeeName(item)}</p>
                                                                                            }
                                                                                        </div>
                                                                                        <p style={{width:"10%"}}>
                                                                                            {item.isEditable?
                                                                                                <div>
                                                                                                    <DatePicker
                                                                                                        onChange={this.handleOnChange.bind(this,item.timelineOrderId,"startDate")}
                                                                                                        format={"DD/MM/YYYY"}
                                                                                                        placeholder="Select Date"
                                                                                                        value={isEmptyVariable(item.startDate)?"":moment(item.startDate)}
                                                                                                        clearIcon={
                                                                                                            <span
                                                                                                                className="material-icons"
                                                                                                                onMouseDown={()=>item.startDate=""}
                                                                                                                style={{fontSize: 18}}
                                                                                                            >
                                                                                                                cancel
                                                                                                            </span>
                                                                                                        }
                                                                                                    />
                                                                                                    {
                                                                                                        !isEmptyVariable(this.state.formErrors[`${item.timelineOrderId}_date`]) &&
                                                                                                        <span className="cm-error">{this.state.formErrors[`${item.timelineOrderId}_date`]}</span>
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <p>{isEmptyVariable(item.startDate)?"":moment(item.startDate).format("DD/MM/YYYY")}</p>
                                                                                            }
                                                                                        </p>
                                                                                        <p style={{width:"10%"}}>
                                                                                            {item.isEditable?
                                                                                                <TimePicker
                                                                                                    style={{width:"85%"}}
                                                                                                    onChange={this.handleOnChange.bind(this,item.timelineOrderId,"startTime")}
                                                                                                    use12Hours
                                                                                                    placeholder="Select Time"
                                                                                                    format={format}
                                                                                                    value={isEmptyVariable(item.startTime)?"":moment(item.startTime, format)}
                                                                                                    clearIcon={
                                                                                                        <span 
                                                                                                            className="material-icons" 
                                                                                                            onMouseDown={()=>item.startTime=""}
                                                                                                            style={{fontSize: 18}}
                                                                                                        >
                                                                                                            cancel
                                                                                                        </span>
                                                                                                    }
                                                                                                />
                                                                                                :
                                                                                                <p>{isEmptyVariable(item.startTime)?"":moment(item.startTime,"HH:mm").format("HH:mm")}</p>
                                                                                            }
                                                                                        </p>
                                                                                        <p style={{width:"10%"}}>
                                                                                            {item.isEditable?
                                                                                                <DatePicker
                                                                                                    onChange={this.handleOnChange.bind(this,item.timelineOrderId,"endDate")}
                                                                                                    format={"DD/MM/YYYY"}
                                                                                                    placeholder="Select Date"
                                                                                                    value={isEmptyVariable(item.endDate)?"":moment(item.endDate)}
                                                                                                    clearIcon={
                                                                                                        <span 
                                                                                                            className="material-icons" 
                                                                                                            onMouseDown={()=>item.endDate=""}
                                                                                                            style={{fontSize: 18}}
                                                                                                        >
                                                                                                            cancel
                                                                                                        </span>
                                                                                                    }
                                                                                                />
                                                                                                :
                                                                                                <p>{isEmptyVariable(item.endDate)?"":moment(item.endDate).format("DD/MM/YYYY")}</p>
                                                                                            }
                                                                                        </p>
                                                                                        <p style={{width:"10%"}}>
                                                                                            {item.isEditable?
                                                                                                <TimePicker 
                                                                                                    style={{width:"85%"}}
                                                                                                    onChange={this.handleOnChange.bind(this,item.timelineOrderId,"endTime")}
                                                                                                    use12Hours
                                                                                                    placeholder="Select Time"
                                                                                                    format={format}
                                                                                                    value={isEmptyVariable(item.endTime)?"":moment(item.endTime, format)}
                                                                                                    clearIcon={
                                                                                                        <span 
                                                                                                            className="material-icons" 
                                                                                                            onMouseDown={()=>item.endTime=""}
                                                                                                            style={{fontSize: 18}}
                                                                                                        >
                                                                                                            cancel
                                                                                                        </span>
                                                                                                    }
                                                                                                />
                                                                                                :
                                                                                                <p>{isEmptyVariable(item.endTime)?"":moment(item.endTime,"HH:mm").format("HH:mm")}</p>
                                                                                            }
                                                                                        </p>
                                                                                        {this.state.assignmentStatus !== Constants.ASSIGNMENT_STATUS_DRAFT &&
                                                                                            <p style={{width:"10%"}}>
                                                                                                {item.isEditable ? 
                                                                                                    <TableIcon themeSettings={themeSettings} className="text-center"
                                                                                                        style={{marginLeft:10}}>
                                                                                                        <span
                                                                                                            onClick={this.handleSaveTimelineValidation.bind(this,item.timelineOrderId)}
                                                                                                            className="material-icons mx-2"
                                                                                                            style={{fontSize: 18}}
                                                                                                        >
                                                                                                            add_circle
                                                                                                        </span>
                                                                                                    </TableIcon> :
                                                                                                    <TableIcon themeSettings={themeSettings} className="text-center" 
                                                                                                        style={{marginLeft:10}}>
                                                                                                        <span
                                                                                                            onClick={this.handleEditTimeline.bind(this,item.timelineOrderId)}
                                                                                                            className="material-icons mx-2"
                                                                                                            style={{fontSize: 18}}
                                                                                                        >
                                                                                                            edit
                                                                                                        </span>
                                                                                                    </TableIcon>
                                                                                                }
                                                                                                <TableIcon themeSettings={themeSettings} className="text-center"
                                                                                                    style={{marginLeft:10}} >
                                                                                                    <span
                                                                                                        onClick={this.handleShowDeleteTimelineAlertDialog.bind(this,item.timelineOrderId)}
                                                                                                        className="material-icons mx-2"
                                                                                                        style={{fontSize: 18}}
                                                                                                    >
                                                                                                        delete
                                                                                                    </span>
                                                                                                </TableIcon>
                                                                                            </p>
                                                                                        }
                                                                                    </CustomTableRow>
                                                                                )}
                                                                        </Draggable>
                                                                        )
                                                                    })}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            </DragDropContext>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type= {Constants.ALERT_TYPE_WARNING}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleSaveTimeline.bind(this,true)}
                            proceedBtnLabel={"Yes"}
                            themeSettings={themeSettings}
                        />
                        <AlertDialog
                            showAlertDialog={this.state.showDeleteTimelineAlertDialog}
                            handleAlertDialogClose={this.handleDeleteTimelineAlertDialogClose}
                            type= {Constants.ALERT_TYPE_WARNING}
                            alertDialogMessage={this.state.deleteTimelineAlertDialogMessage}
                            proceedBtnClick={this.handleDeleteTimeline.bind(this,this.state.timelineOrderId)}
                            proceedBtnLabel={"Yes"}
                            themeSettings={themeSettings}
                        />

                        <SelectFunctionDialog
                            showFunctionsDialog={this.state.showSelectFunctionDialog}
                            handleSelectFuncDialogClose={this.handleSelectFunctionDialogClose}
                            handleSelectFunctionDialogSaveClose={this.handleSelectFunctionDialogSaveClose}
                            selectedFunctionObjArr={this.state.selectedFunctionObjArr}
                            departmentId={this.state.selectedDepartmentId}
                            themeSettings={themeSettings}
                        />
                    </section>
                </main>
            </Router>
        )
    }
}
export default withRouter(Timeline);
