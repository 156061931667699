import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { TableIcon, DialogSaveButton, AddNewButtonLayout,FilterCheckBox } from "../../../common/Global/globalStyles.style";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray, truncateString,
	getOnlyFileNameFromPath,getThemeSettingsVariables } from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import UploadAnnuxureSample from "./uploadAnnexureSample"
import AlertDialog from '../../../common/AlertDialog';
import { toast } from "react-toastify";

const userDetails = getLocalStorageVariables();

class AssertionAssignmentList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showUploadAnnSampleDialog: false,
			datalist:[],
			annexure_NR:"",
            reloadFlag:false,
		};
	}

    componentDidMount() {}
    
	componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showUploadAnnexureDialog) {
            this.setState({
                datalist:[],
                reloadFlag:false,
			},()=>{
                this.initData();
			})
		}
	}
    
    openUploadAnnSampleDialog = () => {
        this.setState({
            showUploadAnnSampleDialog:true
        })
    }

    closeUploadAnnSampleDialog = () => {
        this.setState({
            showUploadAnnSampleDialog:false
        })
    }

	downloadAttachment = (documentPath,e) => {
        e.stopPropagation();
        fetch(Constants.API_SERVER_PATH+documentPath)
        .then(response => { return response.blob(); } )
        .then(blob =>
        {
            let fileName = getOnlyFileNameFromPath(documentPath);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

	onTableDropDownItemClick = (item, dropdownItem) => {
		let arr = dropdownItem.split(":-:");
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete "+this.props.type,
                proceedBtnLabel:"Delete",
                deleteReactivateId:arr[0],
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete "+getOnlyFileNameFromPath(arr[1])+"?"
            })
        }
    }

	handleAlertDialogClose = () => {
		this.setState({
			operationType:"",
			alertDialogHeading:"",
			proceedBtnLabel:"",
			deleteReactivateId:"",
			showAlertDialog:false,
			alertDialogMessage:""
		})
	}

	handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

	handleRecordOperation = () => {
		this.setState({
			showBtnLoader: true
		})
        let url = "";
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
        };

        if (this.state.operationType === "Delete") {
            if(this.props.type === "Backup"){
                url = Constants.DeleteAssignmentTaskBackupFile;
                postParam.assignmentTaskBackupId = this.state.deleteReactivateId;
            }else if(this.props.type === "Annexure"){
                url = Constants.DeleteAssignmentTaskAnnexureFile;
                postParam.assignmentTaskAnnexureId = this.state.deleteReactivateId;
            }

            if(this.props.isDraftOnward === true){
                if(this.props.type === "Backup"){
                    url = Constants.Deletedraftassignmenttaskbackups;
                    postParam.assignmentTaskBackupId = this.state.deleteReactivateId;
                }else if(this.props.type === "Annexure"){
                    url = Constants.DeleteDraftassignmenttaskannexures;
                    postParam.assignmentTaskAnnexureId = this.state.deleteReactivateId;
                }
            }
        }

        fetch(url, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams(postParam),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                this.setState({
                    operationType:"",
                    alertDialogHeading:"",
                    proceedBtnLabel:"",
                    deleteReactivateId:"",
                    showAlertDialog:false,
                    alertDialogMessage:"",
					showBtnLoader: false
                },()=>{
                    this.initData();
                })
            } else {
				this.setState({
					operationType:"",
                    alertDialogHeading:"",
                    proceedBtnLabel:"",
                    deleteReactivateId:"",
                    showAlertDialog:false,
                    alertDialogMessage:"",
					showBtnLoader: false,
					showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
				})
            }
        });

	}

	//Callback from upload annexure sample
	returnUploadedArray = () => {
        this.setState({
            showUploadAnnSampleDialog:false,
            reloadFlag:true,
        },()=>{
            this.initData();
        })
	}

	handleCheck = (e) =>{
        let isChecked = e.target.checked;
        let name = e.target.name;

        if(isChecked){
            this.setState({
                [name]:"Y",
				datalist:"",
            })
        }else{
            this.setState({
                [name]:"N"
            })
        }
    }

    handleAnnexureBackupListDialogClose = () => {
        this.props.handleSelectUploadAnnexureDialogClose(this.state.reloadFlag);
    }
	/*****************************API*********************************/
	
	initData = () => {
		this.setState({
			showLoader: true,
		});

        let url = "";
        if(this.props.type === "Backup"){
            url = Constants.GetAssignmentTaskBackups;
        }else if(this.props.type === "Annexure"){
            url = Constants.GetAssignmentTaskAnnexures;
        }

        if(this.props.isDraftOnward === true){
            if(this.props.type === "Backup"){
                url = Constants.GetDraftAssignmentTaskBackups;
            }else if(this.props.type === "Annexure"){
                url = Constants.GetDraftAssignmentTaskAnnexures;
            }
        }

		fetch(url, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentTaskId: this.props.assignmentTaskId,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					let dataList = [];
                    if(this.props.type === "Annexure"){
					    dataList = data.data.annexures;
                    }else if(this.props.type === "Backup"){
                        dataList = data.data.backups;
                    }
					this.setState({
						showLoader: false,
						componentDidMountFlag: true,
						datalist: dataList,
					});
				} else {
					this.setState({
						showLoader: false,
						componentDidMountFlag: true,
						datalist: [],
					});
				}
			});
	};

    render() {
		const delDropdownObj = {
            icon:"delete",
            label:"Delete"
        }
        let actionObjAllowEdit = [delDropdownObj];
        let actionObjDisallowEdit = [];

		return (
			<section>
				<Modal
					className="task-select-dept-dialog custom-dialog"
					show={this.props.showUploadAnnexureDialog}
					onHide={this.handleAnnexureBackupListDialogClose}
				>
					<Modal.Header>
						<h5>{this.props.type==="Backup"?"Backups":"Annexures"}</h5>
						<button onClick={this.handleAnnexureBackupListDialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						<div className="body-wrapper">
							{this.props.assignmentTaskAllowEdit !== "N" && 
								<div className="search-addnew-bg m-t-0">
									<div className="search-addnew" style={{alignItems:"center"}}>
										<div />
										<div className="search-filter-layout" style={{position:"relative"}}>
											<AddNewButtonLayout themeSettings={this.props.themeSettings}>
												<a href="javascript:void(0);" onClick={this.openUploadAnnSampleDialog}>
													<span className="material-icons">add_circle</span>
													<p>{this.props.type === "Backup"?"Add Backup":"Add Annexure"}</p>
												</a>
											</AddNewButtonLayout>
										</div>
									</div>
								</div>
							}

							<div style={{position:"relative"}}>
							{
								!isEmptyArray(this.state.datalist) && 
								<div className="common-tab-bg">
									<div className="common-table" style={{width:"100%"}}>
										<table className="table">
											<thead>
												<tr>
													<th className="text-center" width={"5%"}>No.</th>
													<th width={"5%"}>File Name</th>
													<th width={"5%"}>Remarks</th>
													<th className="text-center" width={"5%"}>Download</th>
													<th className="text-center"  width={"5%"}></th>
												</tr>
											</thead>
											<tbody>
											{
												!isEmptyArray(this.state.datalist) && 
												this.state.datalist.map((item,idx) => {
													return !item.isDeleted?<tr>
														<td className="text-center">{(idx+1)}</td>
														<td>{getOnlyFileNameFromPath(item.documentPath)}</td>
														<td>{truncateString(item.remark,Constants.REMARKS_STRING_LENGTH)}</td>
														<TableIcon themeSettings={this.props.themeSettings} className="text-center">
															<span class="material-icons" onClick={this.downloadAttachment.bind(this, item.documentPath)}>get_app</span>
														</TableIcon>
														<td className="text-center">
															{
																<TableDropDown 
																	actionArr={actionObjAllowEdit}
																	onDropDownItemClick = {this.onTableDropDownItemClick}
																	dropDownId = {
                                                                        (this.props.type === "Annexure")
                                                                        ?
                                                                        item.assignmentTaskAnnexureId+":-:"+item.documentPath
                                                                        :
                                                                        item.assignmentTaskBackupId+":-:"+item.documentPath
                                                                    }
																	dropdownWidth={"130px"}
																	themeSettings={this.props.themeSettings}
																/>
															}
														</td>
													</tr>
													:
													null
												})
											}
											</tbody>
										</table>
									</div>
								</div>
							}
							{
								isEmptyArray(this.state.datalist) &&
								<div class="no-items-layout">
									<div class="no-items-card">
										<h6>
										{
											isEmptyVariable(this.state.apiSearchKey)?
											Constants.NO_RECORDS_WARNING
											:
											Constants.EMPTY_SEARCH_WARNING
										}
										</h6>
									</div>
								</div>
							}
							</div>
						</div>
					</div>
				</Modal>

				<UploadAnnuxureSample
					showUploadDocSampleDialog = {this.state.showUploadAnnSampleDialog}
					handleUploadSampleDocDialogClose = {this.closeUploadAnnSampleDialog}
					themeSettings={this.props.themeSettings}
					returnUploadedArray={this.returnUploadedArray}
					isDraftOnward = {this.props.isDraftOnward}
					assignmentTaskId= {this.props.assignmentTaskId}
                    type={this.props.type}
				/>

				<AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={
                        (this.state.operationType === "Delete")
                        ?
                        Constants.ALERT_TYPE_WARNING
                        :
                        Constants.ALERT_TYPE_INFO
                    }
                    alertDialogHeading={this.state.alertDialogHeading}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={this.handleRecordOperation}
                    proceedBtnLabel={this.state.proceedBtnLabel}
					showLoader={this.state.showBtnLoader}
                    themeSettings={this.props.themeSettings}
                />

				<AlertDialog 
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type= {Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                    themeSettings={this.props.themeSettings}
                />
			</section>
		);
	}
}

export default AssertionAssignmentList;
