import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,TableCheckBox,TableRadioButton,DialogSaveButton,FilterCheckBox} from '../../common/Global/globalStyles.style';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import Pagination from '../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,
    isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();
const sortShortName = "d.shortName";
const sortName = "d.name";
const sortDescription = "d.description";
const ActiveStatus = "Active";
const mTAssignmentType = "Assignment Type";

class AssertionAssignmentList extends Component{
    constructor(props){
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            resultSizePlaceholder:Constants.RESULT_SIZE,
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            sort:"",
            sortDir:"",

            // selectedDocumentMap:{},
            selectedTypeObj:{},
			selectedId:"",
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showOtherMasterDialog){
            // let docMap = {};
            // this.props.selectedOtherMasterObjArr.map((item)=>{
            //     docMap[item.id] = item;
            // })
            this.setState({
                componentDidMountFlag:false,
                apiSearchKey:"",
                searchkey:"",
                currentPageNo: 1,
                showLoader:false,
                resultSize:Constants.RESULT_SIZE,
                totalCount:0,
                datalist:[],
                resultSizePlaceholder:Constants.RESULT_SIZE,
                resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
                sort:"",
                sortDir:"",
                // selectedDocumentMap:docMap,
				
				selectedId:"",
				selectedTypeObj:{}
            },()=>{
                this.getOtherMastersList();
            })
            
        }
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getOtherMastersList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getOtherMastersList();
            })
        }
    }

    // handleCheck = (e) =>{
    //     let isChecked = e.target.checked;
    //     let name = e.target.name;

    //     if(isChecked){
    //         let datalist = this.state.datalist;
    //         datalist.map((item,idx)=>{
    //             datalist[idx].isSelected=false;
    //         });

    //         this.setState({
    //             [name]:"Y",
    //             selectedDocumentMap:{},
    //             datalist:datalist
    //         })
    //     }else{
    //         this.setState({
    //             [name]:"N"
    //         })
    //     }
    // }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getOtherMastersList();
        })
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let isChecked = e.target.checked;
        let index = e.target.name;

		// let selectedDocArrTemp = this.state.selectedDocumentMap;
        // if(isChecked){
        //     selectedDocArrTemp[this.state.datalist[index].id] = this.state.datalist[index];
        // }else{
        //     delete selectedDocArrTemp[this.state.datalist[index].id]
        // }
        // this.state.datalist[index].isSelected = isChecked;
        // this.setState({
        //     selectedDocumentMap:selectedDocArrTemp
        // });


		let selectedId = "";
		let selectedTypeObj = {};
		if(isChecked){
            selectedId = this.state.datalist[index].id;
            selectedTypeObj = this.state.datalist[index];
        }
		this.setState({
			selectedId:selectedId,
			selectedTypeObj:selectedTypeObj
		})
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getOtherMastersList();
        })
    }

    returnSelectedMasterId = () => {

	
		// let selectedOtherMasterObjArr= [];
		// let documentFormErrTemp = "";

		// for (const [key, item] of Object.entries(this.state.selectedDocumentMap)) {
		// 	if(item.isSelected){
		// 		selectedOtherMasterObjArr.push(item);
		// 	}else{
		// 	}
		// }

		if(isEmptyVariable(this.state.selectedId)){
			if(this.props.masterType === mTAssignmentType){
				// documentFormErrTemp =  "Select Assignment type";
                toast.warning("Select Assignment type");
			}
			
			// this.setState({
			// 	otherMasterFormErr:documentFormErrTemp,
			// })

			// setTimeout(function(){
			// 	this.setState({
			// 		otherMasterFormErr:"",
			// 	});
			// }.bind(this),Constants.WRNG_MSG_TIMEOUT);
		}else{
			this.props.handleSelectOtherMasterDialogSaveClose(this.state.selectedId);
		}
    }
    /*****************************API*********************************/
    getOtherMastersList = () => {
        this.setState({
            showLoader:true,
        });
        
        fetch(Constants.GetOtherMasters,
        {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:ActiveStatus,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                masterType:this.props.masterType,
                // departmentId:this.props.departmentId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let dataList = data.data.result;
                // dataList.map((item,idx)=>{
                //     if(!isEmptyVariable(this.state.selectedDocumentMap[item.id])){
                //         dataList[idx].isSelected=true;
                //     }
                // })

                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:dataList,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:[],
                    totalCount:0,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetOtherMasters,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    status:ActiveStatus,
                    resultsize:this.state.resultSize,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    masterType:this.props.masterType,
                    // departmentId:this.props.departmentId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    let dataList = data.data.result
                    // dataList.map((item,idx)=>{
                    //     if(!isEmptyVariable(this.state.selectedDocumentMap[item.id])){
                    //         dataList[idx].isSelected=true;
                    //     }
                    // })

                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:dataList,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:[],
                        totalCount:0,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    render(){
        return(
            <section>
                <Modal className="task-select-table-dialog custom-dialog"
                    show={this.props.showOtherMasterDialog} 
                    backdrop="static">
                    <Modal.Header>
                        <h5>{this.props.masterType}</h5>
                        <button 
                            onClick={this.props.handleSelectOtherMasterDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div class="loader"></div>
                        }
                        {
                            // Need to check component did mount flag, other wise until api is called,
                            // it shows no items found layout.
                            this.state.componentDidMountFlag &&
                            <div className="body-wrapper">
                                <div className="search-addnew-bg" style={{marginTop:0}}>
                                    <div className="search-addnew">
                                        
                                        <div className="search-filter-layout" style={{position:"relative"}}>
                                            <FilterDropDown
                                                placeholder={this.state.resultSizePlaceholder}
                                                dropdownArr={this.state.resultSizeArr}
                                                onDropDownItemClick={this.resultSizeDropdownClick}
                                                addBorder={true}
                                                themeSettings={this.props.themeSettings}
                                                mleft={0}
                                            />
                                            <div className="search-col m-l-10" style={{border:"1px solid #e5e5e5"}}>
                                                <input 
                                                    name="searchkey" 
                                                    type="text"
                                                    onChange={this.handleChangeSearch}
                                                    placeholder="Search" 
                                                    onKeyPress={this.onEnterBtnPress}
                                                    value={this.state.searchkey}
                                                />
                                                <SearchSpan 
                                                themeSettings={this.props.themeSettings}
                                                onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div style={{position:"relative"}}>
                                    {
                                        !isEmptyArray(this.state.datalist) && 
                                        <div className="common-tab-bg">
                                            <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" width={"8%"}></th>
                                                            <th className="text-center" width={"5%"}>No</th>
                                                            <th  className="c-pointer" onClick={this.sortTableLocal.bind(this,sortShortName)}>
                                                                <div className="sort-header">Short Name
                                                                <span className={(this.state.sort === sortShortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th width={"35%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
                                                                <div className="sort-header">Name
                                                                <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th width={"35%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDescription)}>
                                                                <div className="sort-header">Description
                                                                <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        !isEmptyArray(this.state.datalist) && 
                                                        this.state.datalist.map((item,idx) => {

                                                            return <tr>
                                                                <td>
                                                                    <TableRadioButton
                                                                        themeSettings={this.props.themeSettings}>
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={idx}
                                                                            id={item.id}
                                                                            onChange={this.handleSelection}
                                                                            // checked={item.isSelected?true:false}
																			checked={item.id===this.state.selectedId?true:false}

                                                                        />
                                                                        <label for={item.id} 
                                                                        style={{marginLeft:6}}></label>
                                                                    </TableRadioButton>
                                                                </td>
                                                                <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                <td>{item.shortName}</td>
                                                                <td>{item.name}</td>
                                                                <td>{truncateString(item.description,Constants.OTHERMASTERS_STRING_LENGTH)}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                        <div class="no-items-layout">
                                            <div class="no-items-card">
                                                <h6>
                                                {
                                                    isEmptyVariable(this.state.apiSearchKey)?
                                                    Constants.NO_RECORDS_WARNING
                                                    :
                                                    Constants.EMPTY_SEARCH_WARNING
                                                }
                                                </h6>
                                            </div>
                                        </div>
                                    }
                                    <div className="pagination-layout">
                                        <div className="row custom-row">
                                            <div className="col-md-9 custom-col">
                                            {
                                                (this.state.totalCount > this.state.datalist.length) && 
                                                <Pagination 
                                                    totalLength ={this.state.totalCount} 
                                                    items={this.state.datalist} 
                                                    onChangePage={this.onChangePage} 
                                                    pageSize={this.state.resultSize}
                                                    currentPageNo = {this.state.currentPageNo}
                                                    initialPage={this.state.currentPageNo}
                                                    themeSettings={this.props.themeSettings} />
                                            }
                                            </div>
                                            <div className="col-md-3 custom-col">
                                                <p>{"Total Records: "+this.state.totalCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {
                                    !isEmptyVariable(this.state.otherMasterFormErr) &&
                                    <span className="cm-error">{this.state.otherMasterFormErr}</span>
                                } */}
                                <div className="modal-close-save m-t-15">
                                    <div className="close-save-col">
                                        <button onClick={this.props.handleSelectOtherMasterDialogClose} type="button" 
                                        className="modal-close m-b-0">Cancel</button>
                                        <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                        onClick={this.returnSelectedMasterId} type="button" 
                                        className="modal-save m-b-0">Add Task</DialogSaveButton>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal>
            </section>
        )
    }
}

export default AssertionAssignmentList;