import React, { Component } from 'react'
import {getLocalStorageVariables,getStatusSpan,isEmptyVariable, isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import * as Constants from '../../common/Global/constants';
import {DialogSaveButton} from '../../common/Global/globalStyles.style';
import { toast } from 'react-toastify';


const userDetails  = getLocalStorageVariables();

export default class AddUnitGroupingDialog extends Component {
    constructor(props)
    {
        super(props);
        this.state ={
            showLoader:false,
            showBtnLoader:false,
            componentDidMountflag:false,
            unitList:[],
            selectedUnitPlaceholder:"Select Units",
            selectedUnitIds:[],
            // formErrors:{},

            companyList:[],
            selectedCompanyPlaceholder:"Select Clients",
            selectedCompanyIds:[],

        }
    }

    componentDidMount(){
        this.getInitData();
        
    }

    getInitData = () =>{
        this.setState({
            showLoader:true
        })

        let postParam={}
        let postUrl="";
        if(this.props.isClient==="Y")
        {
             postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                isClient:this.props.isClient,
                unitGroupId:this.props.selectedId,
                isSelf:this.props.companyType===Constants.USER_ENGAGEMENT_TYPE_SELF?"Y":"N",
               

            }
            postUrl = Constants.GetAllCompanyData
        }
        else{

            postParam =
            {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                unitGroupId:this.props.selectedId,
                isSelf:this.props.companyType===Constants.USER_ENGAGEMENT_TYPE_SELF?"Y":"N",
                isClient:this.props.isClient,
                rootGroupingId:this.props.rootGroupingId

            }

            postUrl = Constants.GetAllUnitData

        }
       
        fetch(postUrl,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } ).
            then(data => {

                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    console.log(data)

             if(this.props.isClient==="Y"){
                let tempSelctedCompanyList = [];
                Object.values(data.selctedCompanyList).map((item)=>{
                    tempSelctedCompanyList.push(item.companyId)
                })
                this.setState({
                    showLoader:false,
                    componentDidMountflag:true,
                    companyList:data.data,
                    selectedCompanyIds:tempSelctedCompanyList
                })
             }
             
             else { 
                 let unitList = data.data;
                let tempSelctedUnitList = [];
                Object.values(data.selctedUnitList).map((item)=>{
                    tempSelctedUnitList.push(item.unitId)
                })
                let tempNewUnitList =[]
                Object.values(unitList).map((item)=>{
                    let  tempItem = item
                    tempItem["unitNameAndCity"] = item.unitName+", "+item.city
                    tempNewUnitList.push(tempItem)
                })
                this.setState({
                    showLoader:false,
                    componentDidMountflag:true,
                    unitList:tempNewUnitList,
                    selectedUnitIds:tempSelctedUnitList
                })}

            }
            })
    }

    addSelection = (args) => {
        // let FormErrorsTemp  = this.state.formErrors;
        // FormErrorsTemp.selectedUnit = "";
        // FormErrorsTemp.selctedCompany="";
        if(this.props.isClient==="Y")
        {
            if(!isEmptyVariable(args.itemData.companyId))
            {
                this.state.selectedCompanyIds.push(args.itemData.companyId)
            }
            this.setState({
                selectedCompanyIds:this.state.selectedCompanyIds,
                // FormErrors:FormErrorsTemp
            })
        }
        else{
        if(!isEmptyVariable(args.itemData.unitId))
        {
            this.state.selectedUnitIds.push(args.itemData.unitId)
        }

        this.setState({
            selectedUnitIds:this.state.selectedUnitIds,
            // FormErrors:FormErrorsTemp
        })
    }

    }

    removeSelection = (args) => {
        if(this.props.isClient==="Y")
        {
            if(!isEmptyVariable(args.itemData.companyId))
            {
               let idx = this.state.selectedCompanyIds.indexOf(args.itemData.companyId);
               if(idx !== -1){
                this.state.selectedCompanyIds.splice(idx,1);
            }
            }
            this.setState({
                selectedCompanyIds:this.state.selectedCompanyIds,
            })
        }


        else{
        if(!isEmptyVariable(args.itemData.unitId)){
            let idx = this.state.selectedUnitIds.indexOf(args.itemData.unitId);
            if(idx !== -1){
                this.state.selectedUnitIds.splice(idx,1);
            }
        }
        this.setState({
            selectedUnitIds:this.state.selectedUnitIds
        })
    }
    }

    handleUnitSave = () =>{
        // let formErrors = this.state.formErrors;

        
        let  postParam=   this.props.isClient==="Y"? {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            unitGroupId:this.props.selectedId,
            selctedCompanyList:JSON.stringify(this.state.selectedCompanyIds),
            isClient:this.props.isClient

        }:{
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            unitGroupId:this.props.selectedId,
            selctedUnitList:JSON.stringify(this.state.selectedUnitIds),
            isClient:this.props.isClient

        };
        
        if(this.state.selectedUnitIds.length==0 && this.props.isClient!=="Y")
        {
            toast.warning("Please Select Units.");
            // formErrors["selectedUnit"]="Please Select Units.";
            // this.setState({
            //     formErrors:formErrors
            // })
        }
        if(this.state.selectedCompanyIds.length==0 && this.props.isClient==="Y")
        {
            toast.warning("Please Select Clients.");
            // formErrors["selctedCompany"]="Please Select Clients.";
            // this.setState({
            //     formErrors:formErrors
            // })
           
        }
         
        else{
            this.setState({
                showBtnLoader:true
            })
            fetch(Constants.AddUnitGroupingList,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams(postParam)
                })
                .then(response => { return response.json(); } ).
                then(data => {
    
                    if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                        localStorage.clear();
                        window.location="/";
                    }else if(data.responseCode === Constants.CODE_SUCCESS){
                        
                       if(this.props.isClient!=="Y") { this.setState({
                            selectedUnitIds:[],
                            showBtnLoader:false
                        },()=>{
                            this.props.handleUnitDialogClose()
                            this.props.getGroupingTree()
                        })}
                        else {
                            this.setState({
                                selectedCompanyIds:[],
                                showBtnLoader:false
                                
                            },()=>{
                                this.props.handleUnitDialogClose()
                                this.props.getGroupingTree()
                            })
                        }
                    }
                })
        }

    }
  render() {
    let fields ={}

    if(this.props.isClient==="Y")
    {
        fields = {text:"companyName",value:"companyId"}
    }
    else{
        fields = {text:"unitNameAndCity",value:"unitId"}
    }
    

    return (
        <Modal className="custom-dialog" show={this.props.showUnitDialog} backdrop="static">
            <Modal.Header>
                    <h5>{this.props.isEdit?"Edit Unit":"Add Unit"}</h5>
                    <button 
                        onClick={this.props.handleUnitDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
            </Modal.Header>
            <hr />
            <div class="modal-body">
           {
           this.state.componentDidMountflag &&  
           <div className="addnew-modal-form-group">
                <p>{this.props.isClient=="Y"? "Clients*":"Units*"}</p> 

             { this.props.isClient!=="Y" && 
                <MultiSelectComponent 
                    id="unitsCheckbox"
                    dataSource={this.state.unitList}
                    fields={fields}
                    placeholder={this.state.selectedUnitPlaceholder} 
                    mode="CheckBox"
                    closePopupOnSelect={false}
                    allowFiltering={false}
                    select={this.addSelection}
                    removed={this.removeSelection}
                    cssClass="clientsChecklist dialogcl"
                    value={this.state.selectedUnitIds}
                    showDropDownIcon={true}
                    showClearButton={false}
                >
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
                
            }
           

             { this.props.isClient==="Y" && 
                <MultiSelectComponent 
                    id="companyCheckbox"
                    dataSource={this.state.companyList}
                    fields={fields}
                    placeholder={this.state.selectedCompanyPlaceholder} 
                    mode="CheckBox"
                    closePopupOnSelect={false}
                    allowFiltering={false}
                    select={this.addSelection}
                    removed={this.removeSelection}
                    cssClass="clientsChecklist dialogcl"
                    value={this.state.selectedCompanyIds}
                    showDropDownIcon={true}
                    showClearButton={false}
                >
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
             }
                {/* {
                    !isEmptyVariable(this.state.formErrors['selectedUnit']) &&
                    <span className="cm-error">{this.state.formErrors['selectedUnit']}</span>
                }
                {
                !isEmptyVariable(this.state.formErrors['selctedCompany']) &&
                <span class="cm-error">{this.state.formErrors['selctedCompany']}</span>
                } */}
            </div>
            }


            <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.props.handleUnitDialogClose} type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton disabled={this.state.showBtnLoader}
                                themeSettings={this.props.themeSettings} onClick={this.handleUnitSave} type="button">
                                    {this.state.showBtnLoader ? <div class="loader-btn mt-2"></div> : 'Save'}
                                </DialogSaveButton>
                            </div>
                        </div>
            
            </div>

        </Modal>
    )
  }
}
