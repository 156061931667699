import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {DialogSaveButton,TreeNodeCheckBox, ExpandCollapseWrapper, TableRadioButton} from '../../common/Global/globalStyles.style';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();

const IndustryTree = (props) => {

    return <div className="dialog-tree-structure">
        <div className="tree-node checkboxparent" style={{marginLeft:(props.level*40)+"px"}}>
            <span 
                onClick={()=>props.onClickNode(props.industryObj)}
                class="material-icons" style={{opacity:isEmptyArray(props.industryObj.children)?"0":"1"}}>
                {props.industryObj.isShowingChildren?"keyboard_arrow_down":"keyboard_arrow_right"}
            </span>
            <TreeNodeCheckBox
            themeSettings={props.themeSettings}>
                <input 
                    type="checkbox"
                    name={props.industryObj.industryId}
                    id={props.industryObj.industryId}
                    onChange={props.handleSelection}
                    checked={props.industryObj.isSelected?true:false}
                    disabled={props.industryObj.isDisabled}
                />
                <label for={props.industryObj.industryId} 
                style={{marginLeft:6}}>{props.industryObj.industryName}</label>
            </TreeNodeCheckBox>
            {/* <p for={props.industryObj.industryId}>{props.industryObj.IndustryName}</p> */}
        </div>

        {
            //Exit Condition
            props.industryObj.isShowingChildren &&
            props.getChildNodes(props.industryObj).map((child, idx)=>{
                let levelTemp = props.level + 1;
                return <IndustryTree 
                    industryObj = {child} 
                    getChildNodes = {props.getChildNodes}
                    level={levelTemp}
                    handleSelection={props.handleSelection}
                    onClickNode={props.onClickNode}
                    themeSettings={props.themeSettings}
                />
            })
        }
    </div>
}

class SelectIndustryDialog extends Component{
    constructor(props){
        super(props);
        this.state={
            industryMap:{},
            rootNodes:[],
            refreshFlag:false,
            showLoader:false,
            // industryFormErr:"",
            selectionFlag:"",
        }
        this.selectedIndustryObjArr = [];
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showIndustriesDialog){
            this.selectedIndustryObjArr = [];
            this.setState({
                industryMap:{},
                rootNodes:[],
                // industryFormErr:"",
                refreshFlag:false,
                showLoader:false,
                selectionFlag:this.props.industrySelectionFlag,
            },()=>{
                this.selectedIndustryObjArr = this.props.selectedIndustryObjArr;
                this.getIndustryTree();
            })
            
        }
    }

    onClickNode = (node) => {
        if(this.state.industryMap[node.industryId].isShowingChildren){
            this.state.industryMap[node.industryId].isShowingChildren = false;
        }else{
            this.state.industryMap[node.industryId].isShowingChildren = true;
        }
        
        this.setState({
            refreshFlag:!this.state.refreshFlag
        })
    }

    handleSelectIndustryDialogClose = () => {

    }

    getRootNodes = (industryMap) => {
        return Object.values(industryMap).filter(node => node.level === 0)
    }

    getChildNodes = (node) => {
        if(isEmptyArray(node.children)){
            return [];
        }else{
            return node.children.map(item => this.state.industryMap[item.industryId]);
        }
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let isChecked = e.target.checked;
        let industryId = e.target.name;

        // this.selectUnSelectAllChildren(industryId,isChecked);
        this.disableEnableAllChildren(industryId,isChecked,true);

        let industryMap = this.state.industryMap;
        industryMap[industryId].isSelected = isChecked;

        this.setState({
            refreshFlag:!this.state.refreshFlag
        });
    }

    selectUnSelectAllChildren = (industryId,isChecked) => {
        this.state.industryMap[industryId].isSelected = isChecked;
        //show children of checked item
        if(isChecked && !isEmptyArray(this.state.industryMap[industryId].children)){
            this.state.industryMap[industryId].isShowingChildren = true;
        }

        this.state.industryMap[industryId].children.map(child => {
            this.selectUnSelectAllChildren(child.industryId,isChecked);
        })
    }

    disableEnableAllChildren = (industryId,isChecked,isParent) => {
        if(!isParent){
            this.state.industryMap[industryId].isDisabled = isChecked;
            this.state.industryMap[industryId].isSelected = false;
        }

        this.state.industryMap[industryId].children.map(child => {
            this.disableEnableAllChildren(child.industryId,isChecked,false);
        })
    }

    returnSelectedIndustryId = () => {
        this.selectedIndustryObjArr = [];
        // let industryFormErrTemp = "";

        for (const [key, item] of Object.entries(this.state.industryMap)) {
            if(item.isSelected){
                this.selectedIndustryObjArr.push(item);
            }else{
            }
        }

        if(isEmptyArray(this.selectedIndustryObjArr) && this.state.selectionFlag!=="selectAll"){
            toast.warning("Select atleast one industry");
            // industryFormErrTemp =  "Select atleast one industry";
            // this.setState({
            //     industryFormErr:industryFormErrTemp,
            // })

            // setTimeout(function(){
            //     this.setState({
            //         industryFormErr:"",
            //     });
            // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }else{
            // alert(this.selectedIndustryObjArr);
            this.props.handleSelectIndustryDialogSaveClose(this.selectedIndustryObjArr,
                this.state.selectionFlag);
        }
    }

    expandCollapseAll = (industryId,isChecked) => {
        this.state.industryMap[industryId].isShowingChildren = isChecked;

        this.state.industryMap[industryId].children.map(child => {
            this.expandCollapseAll(child.industryId,isChecked);
        })

        this.setState({
            industryMap:this.state.industryMap
        })
    }

    expandAll = () => {
        this.state.rootNodes.map((industryObj)=>{
            this.expandCollapseAll(industryObj.industryId,true);
        })
    }

    collapseAll = () => {
        this.state.rootNodes.map((industryObj)=>{
            this.expandCollapseAll(industryObj.industryId,false);
        })
    }

    handleRadioSelection = (e) => {
        // e.stopPropagation();
        let selectionFlag = e.target.name;

        if(selectionFlag === this.state.selectionFlag){
            selectionFlag = "";
        }

        this.setState({
            selectionFlag:selectionFlag,
        });
    }


    /***********************API CALLS***********************/
    getIndustryTree = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetIndustriesSubtree,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                departmentId:this.props.departmentId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                const industryMap = data.result.industryMap;
                const rootNodes = this.getRootNodes(industryMap);

                //Select Checkboxes from selectedIndustryObjArr array
                this.selectedIndustryObjArr.map((industryObj)=>{
                    industryMap[industryObj.industryId].isSelected =true;
                })

                this.setState({
                    showLoader:false,
                    industryMap:industryMap,
                    rootNodes:rootNodes
                });
            }else{
                this.setState({
                    industryMap:{},
                    showLoader:false,
                });
            }
        });
    }

    render(){
        
        return(
            <Modal className="task-tree-dialog custom-dialog" 
            show={this.props.showIndustriesDialog} backdrop="static">
                <Modal.Header>
                    <h5>Industries</h5>
                    <button 
                        onClick={this.props.handleSelectIndustryDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>

                <hr />
                <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }
                    <div className="flex-center-layout m-b-10"
                    style={{justifyContent:"space-between"}}>
                        <div className="flex-center-layout just-content m-b-10">
                            <TableRadioButton
                                style={{marginLeft:0,display:"inline-flex"}}
                                themeSettings={this.props.themeSettings}>
                                <input 
                                    type="checkbox"
                                    name={"selectAll"}
                                    id={"selectAllCb"}
                                    onChange={this.handleRadioSelection}
                                    checked={this.state.selectionFlag==="selectAll"?true:false}
                                />
                                <label for={"selectAllCb"} 
                                style={{marginLeft:0}}>Select All</label>
                            </TableRadioButton>

                            <TableRadioButton
                                style={{marginLeft:10,display:"inline-flex"}}
                                themeSettings={this.props.themeSettings}>
                                <input 
                                    type="checkbox"
                                    name={"selectAllExcept"}
                                    id={"selectAllExceptCb"}
                                    onChange={this.handleRadioSelection}
                                    checked={this.state.selectionFlag==="selectAllExcept"?true:false}
                                />
                                <label for={"selectAllExceptCb"} 
                                style={{marginLeft:0}}>Select All Except</label>
                            </TableRadioButton>

                        </div>

                        <ExpandCollapseWrapper themeSettings={this.props.themeSettings}>
                            <div className="expand-collapse-layout"
                                onClick={this.expandAll}
                                style={{marginRight:10}}
                                type="button">
                                <span class="material-icons">unfold_more</span>
                                <p>Expand All</p>
                            </div>
                            <div
                                className="expand-collapse-layout"
                                onClick={this.collapseAll}
                                style={{marginLeft:10}}
                                type="button">
                                <span class="material-icons">unfold_less</span>
                                <p>Collapse All</p>
                            </div>

                        </ExpandCollapseWrapper>
                    </div>
                    <div className="body-wrapper">
                        
                        <div  className={this.state.selectionFlag==="selectAll"?"tree-wrapper editor-disabled":"tree-wrapper"}>
                            {
                                this.state.rootNodes.map((item)=>{
                                    return <IndustryTree 
                                        industryObj = {item} 
                                        getChildNodes = {this.getChildNodes}
                                        level={0}
                                        handleSelection={this.handleSelection}
                                        themeSettings = {this.props.themeSettings}
                                        onClickNode={this.onClickNode}  //EXPAND/COLLAPSE
                                    />
                                })
                            }
                            {/* {
                                !isEmptyVariable(this.state.industryFormErr) &&
                                <span className="cm-error">{this.state.industryFormErr}</span>
                            } */}
                        </div>
                        <div className="modal-close-save m-t-15">
                            <div className="close-save-col">
                                <button onClick={this.props.handleSelectIndustryDialogClose} type="button" className="modal-close m-b-0">Cancel</button>
                                <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                onClick={this.returnSelectedIndustryId} type="button" 
                                className="modal-save m-b-0">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default SelectIndustryDialog;