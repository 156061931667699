import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,DialogSaveButton} from '../../../common/Global/globalStyles.style';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../../common/AlertDialog';
import Pagination from '../../../common/Global/pagination';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import UploadDocumentERPSampleDialog from './uploadDocERPSample';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,
    isEmptyArray,ifEmptyReturnEmptyStr} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import DocERPSamplesListDialog from "./listDocumentERPSample";
import { toast } from 'react-toastify';
const userDetails  = getLocalStorageVariables();

const sortShortName = "e.shortName";
const sortNavigationMenu = "navigationMenu";
const sortTrasactionCode = "transactionCode";
const sortDescription = "description";

const ERPDropdownDefaultValue = "Select ERP";
const editDropdownObj = {
    icon:"edit",
    label:"Edit"
}
const delDropdownObj = {
    icon:"delete",
    label:"Delete"
}
const uploadSample = {
    icon:"cloud_upload",
    label:"Upload Sample"
}
const getSamples = {
    icon:"topic",
    label:"Show Samples"
}
const actionArrAllowEdit = [editDropdownObj,uploadSample,getSamples,delDropdownObj];
const actionArrDontAllowEdit = [getSamples];

class DocumentERPMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo:1,
            showLoader:false,
            totalCount:0,
            datalist:[],
            resultSize:Constants.RESULT_SIZE,
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            showBtnLoader:false,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,

            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,

            erpArr:[],
            selectedERPShortName:ERPDropdownDefaultValue,
            selectedERPName:"",
            selectedERPId:"",
            navigationMenu:"",
            transactionCode:"",
            description:"",
            formErrors:{},
            submitClickedAtleastOnce:false,

            showDetailsDialog:false,
            showDocERPSamplesDialog:false,
            selectedItem:"",
        }
    }

    componentDidMount(){
        this.getInitData();
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        let FormErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            if(name === "transactionCode"){
                if(isEmptyVariable(value)){
                    // FormErrorsTemp['transactionCode'] = "Please enter transaction code";
                }else{
                    FormErrorsTemp['transactionCode'] = "";
                }
            }else if(name === "navigationMenu"){
                if(isEmptyVariable(value)){
                    // FormErrorsTemp['navigationMenu'] = "Please enter navigation menu content";
                }else if(!isEmptyVariable(value) && value.length > Constants.DESC_STRING_LENGTH){
                    FormErrorsTemp['navigationMenu'] = "Content must be less then 500 characters";
                }else{
                    FormErrorsTemp['navigationMenu'] = "";
                }
            }else if(name === "description"){
                if(isEmptyVariable(value)){
                    // FormErrorsTemp['description'] = "Please enter description content";
                }else if(!isEmptyVariable(value) && value.length > Constants.DESC_STRING_LENGTH){
                    FormErrorsTemp['description'] = "Content must be less then 500 characters";
                }else{
                    FormErrorsTemp['description'] = "";
                }
            }
        }

        this.setState({ 
            [name]: value ,
            formErrors:FormErrorsTemp
        });
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getDocumentERPMasterList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getDocumentERPMasterList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleCreateDialogClose = () =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            submitClickedAtleastOnce:false,

            selectedId:"",
            selectedERPShortName:ERPDropdownDefaultValue,
            selectedERPName:"",
            selectedERPId:"",
            navigationMenu:"",
            transactionCode:"",
            description:"",
            formErrors:{},

        });
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    //TODO
    showCreateDialog = () => {
        this.setState({
            showCreateDialog:true,
            isEdit:false,
            submitClickedAtleastOnce:false,

            selectedId:"",
            selectedERPShortName:ERPDropdownDefaultValue,
            selectedERPName:"",
            selectedERPId:"",
            navigationMenu:"",
            transactionCode:"",
            description:"",
            formErrors:{},
        });
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getDocumentERPMasterList();
        })
    }

    ERPDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['erpShortName'] = "";
        }

        this.setState({
            selectedERPShortName:item.shortName,
            selectedERPName:item.name,
            selectedERPId:item.erpId,
            formErrors:FormErrorsTemp
        })
    }

    handleUploadSampleERPDocDialogClose = () => {
        this.setState({
            showUploadDocERPSampleDialog:false,
            deleteReactivateId:"",
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete ERP",
                proceedBtnLabel:"Delete",
                deleteReactivateId:dropdownItem.documentERPId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete ERP "+dropdownItem.erpShortName+"?"
            })
        }else if(item.label === "Edit"){
            this.setState({
                showCreateDialog:true,
                isEdit:true,
                
                selectedId:dropdownItem.documentERPId,
                selectedERPId:dropdownItem.erpId,
                selectedERPName:dropdownItem.erpName,
                selectedERPShortName:dropdownItem.erpShortName,
                navigationMenu:dropdownItem.navigationMenu,
                transactionCode:dropdownItem.transactionCode,
                description:dropdownItem.description,
                formErrors:{}
            });
        }else if(item.label === "Upload Sample"){
            this.setState({
                showUploadDocERPSampleDialog:true,
                deleteReactivateId:dropdownItem.documentERPId,
            })
        }else if(item.label === "Show Samples"){
            this.setState({
                showDocERPSamplesDialog:true,
                selectedItem:dropdownItem,
            });
        }
    }

    handleDocERPSamplesDialogClose = () => {
        this.setState({
            showDocERPSamplesDialog:false,
            selectedItem:"",
        },()=>{
            this.getDocumentERPMasterList();
        });
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getDocumentERPMasterList();
        })
    }
    
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetDocumentERPMasterCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetDocumentERPMaster,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    documentId:this.props.documentId
                })
            })
        ])
        .then(([masterRes,generalRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json()]) 
        })
        .then(([masterRes,generalRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;
            let erpArrTemp = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    erpArrTemp = masterRes.data.erps;
            }else{
                erpArrTemp = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:datalistTemp,
                totalCount:totalCountTemp,
                erpArr:erpArrTemp,
            })
        })
    }

    getDocumentERPMasterList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetDocumentERPMaster,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                documentId:this.props.documentId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetDocumentERPMaster,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    documentId:this.props.documentId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            documentERPId:this.state.deleteReactivateId
        }

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteDocumentERPMaster;
        }

        this.setState({
            showBtnLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogHeading:"",
                    proceedBtnLabel:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                    showBtnLoader:false,
                },() => {
                    this.getDocumentERPMasterList();
                });
            }else{
                this.setState({
                    showBtnLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogHeading:"",
                    proceedBtnLabel:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,

                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
        
    }

    handleCreateDocERPMaster = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.transactionCode) && isEmptyVariable(this.state.navigationMenu)){
            isError = true;
            FormErrorsTemp['transactionCode'] = "Please enter transaction code or navigation menu content";
        }
        
        if(isEmptyVariable(this.state.selectedERPShortName) ||
        this.state.selectedERPShortName === ERPDropdownDefaultValue){
            isError = true;
            FormErrorsTemp['erpShortName'] = "Please select a ERP";
        }
        
        // if(isEmptyVariable(this.state.navigationMenu)){
            //     isError = true;
            //     FormErrorsTemp['navigationMenu'] = "Please enter navigation menu content";
            // }
            
        if(!isEmptyVariable(this.state.navigationMenu)){
            if(this.state.navigationMenu.length > Constants.DESC_STRING_LENGTH){
                isError = true;
                FormErrorsTemp['navigationMenu'] = "Content must be less then 500 characters";
            }
        }
        
        // if(isEmptyVariable(this.state.description)){
        //     isError = true;
        //     FormErrorsTemp['description'] = "Please enter description";
        // }

        if(!isEmptyVariable(this.state.description)){
            if(this.state.description.length > Constants.DESC_STRING_LENGTH){
                isError = true;
                FormErrorsTemp['description'] = "Content must be less then 500 characters";
            }
        }
            

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";
            let postParams = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                erpId:this.state.selectedERPId,
                navigationMenu:this.state.navigationMenu,
                transactionCode:this.state.transactionCode,
                description:this.state.description,
                documentId:this.props.documentId
            };

            if(this.state.isEdit){
                url = Constants.EditDocumentERPMaster;
                postParams.documentERPId = this.state.selectedId;
            }else{
                url = Constants.CreateDocumentERPMaster;
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParams)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showCreateDialog:false,
                        showLoader:false,
                        isEdit:false,
                        selectedERPName:"",
                        selectedERPId:"",
                        navigationMenu:"",
                        transactionCode:"",
                        description:"",
                        formErrors:{},
                    },() => {
                        this.getDocumentERPMasterList();
                    });
                }else{
                    // FormErrorsTemp['apiErr'] = data.responseMessage;
                    toast.error(data.responseMessage);
                    this.setState({
                        // formErrors:FormErrorsTemp,
                        showLoader:false
                    });
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <section>
                {
                    this.state.showLoader &&
                    <div class="loader"></div>
                }
                {
                    // Need to check component did mount flag, other wise until api is called,
                    // it shows no items found layout.
                    this.state.componentDidMountFlag &&
                    <div className="body-wrapper">
                        <div className="flex-center-layout secondary-top-bar">
                            <div className="back-btn-layout" onClick={this.props.handleBackBtnClickFromSamplesandERP}>
                                <span class="material-icons-outlined">arrow_back_ios</span>
                            </div>
                            <h6>{this.props.documentName+"\u00A0\u00A0>\u00A0\u00A0ERP"}</h6>
                        </div>

                        <div className="search-addnew-bg">
                            <div className="search-addnew">
                                
                                <div className="search-filter-layout">
                                     <FilterDropDown
                                        placeholder={this.state.resultSizePlaceholder}
                                        dropdownArr={this.state.resultSizeArr}
                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                        themeSettings={this.props.themeSettings}
                                        mleft={0}
                                    />
                                    <div className="search-col m-l-10">
                                        <input 
                                            name="searchkey" 
                                            type="text"
                                            onChange={this.handleChangeSearch}
                                            placeholder="Search" 
                                            onKeyPress={this.onEnterBtnPress}
                                            value={this.state.searchkey}
                                        />
                                        <SearchSpan 
                                        themeSettings={this.props.themeSettings}
                                        onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                    </div>

                                    
                                </div>
                                {
                                    this.props.masterMenus[Constants.SCREEN_DOCUMENT] ==="ALL" && (
                                        <AddNewButtonLayout themeSettings={this.props.themeSettings}>
                                            <a onClick={this.showCreateDialog} href="javascript:void(0);">
                                                <span className="material-icons">add_circle</span>
                                                <p>Add New</p>
                                            </a>
                                        </AddNewButtonLayout>
                                    )
                                }
                            </div>
                        </div>

                        {
                            !isEmptyArray(this.state.datalist) && 
                            <div className="common-tab-bg">
                                <div className="common-table" style={{width:"100%"}}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                            <th className="text-center" width={"5%"}>No</th>
                                            <th width={"15%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortShortName)}>
                                                <div className="sort-header">ERP Short Name
                                                <span className={(this.state.sort === sortShortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortNavigationMenu)}>
                                                <div className="sort-header">Navigation Menu
                                                <span className={(this.state.sort === sortNavigationMenu)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th width={"15%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortTrasactionCode)}>
                                                <div className="sort-header">Transaction Code
                                                <span className={(this.state.sort === sortTrasactionCode)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDescription)}>
                                                <div className="sort-header">Description
                                                <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            {/* <th width={"10%"}>Users Count</th> */}
                                            <th className="text-center"  width={"10%"}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            this.state.datalist.map((item,idx) => {
                                                return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                    <td>{item.erpShortName}</td>
                                                    <td>{truncateString(item.navigationMenu,Constants.OTHERMASTERS_STRING_LENGTH)}</td>
                                                    <td>{item.transactionCode}</td>
                                                    <td>{truncateString(item.description,Constants.OTHERMASTERS_STRING_LENGTH)}</td>
                                                    <td className="text-center">
                                                        <TableDropDown 
                                                            actionArr={this.props.allowEdit==="Y"?actionArrAllowEdit:actionArrDontAllowEdit}
                                                            onDropDownItemClick = {this.onTableDropDownItemClick}
                                                            dropDownId = {item}
                                                            dropdownWidth={"170px"}
                                                            themeSettings={this.props.themeSettings}
                                                        />
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {
                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                            <div class="no-items-layout">
                                <div class="no-items-card">
                                    <h6>
                                    {
                                        isEmptyVariable(this.state.apiSearchKey)?
                                        Constants.NO_RECORDS_WARNING
                                        :
                                        Constants.EMPTY_SEARCH_WARNING
                                    }
                                    </h6>
                                </div>
                            </div>
                        }
                        <div className="pagination-layout">
                            <div className="row custom-row">
                                <div className="col-md-9 custom-col">
                                {
                                    (this.state.totalCount > this.state.datalist.length) && 
                                    <Pagination 
                                        totalLength ={this.state.totalCount} 
                                        items={this.state.datalist} 
                                        onChangePage={this.onChangePage} 
                                        pageSize={this.state.resultSize}
                                        currentPageNo = {this.state.currentPageNo}
                                        initialPage={this.state.currentPageNo} 
                                        themeSettings={this.props.themeSettings} />
                                }
                                </div>
                                <div className="col-md-3 custom-col">
                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={
                        (this.state.operationType === "Delete")
                        ?
                        Constants.ALERT_TYPE_WARNING
                        :
                        (this.state.operationType === "Review Remarks")
                        ?
                        Constants.ALERT_TYPE_ALERT
                        :
                        Constants.ALERT_TYPE_INFO
                    }
                    alertDialogHeading={this.state.alertDialogHeading}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={this.handleRecordOperation}
                    proceedBtnLabel={this.state.proceedBtnLabel}
                    showLoader={this.state.showBtnLoader}
                    themeSettings={this.props.themeSettings}
                />

                <AlertDialog 
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type= {Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                    themeSettings={this.props.themeSettings}
                />

                <Modal className="custom-dialog" show={this.state.showCreateDialog} backdrop="static">
                    <Modal.Header>
                        <h5>{this.state.isEdit?"Edit ERP Details":"ERP Details"}</h5>
                        <button 
                            onClick={this.handleCreateDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        <div class="addnew-modal-form">
                            <div class="addnew-modal-form-group">
                                <p>ERP*</p>
                                <RegularDropdown 
                                    placeholder={this.state.selectedERPShortName}
                                    dropdownArr={this.state.erpArr}
                                    labelParam="shortName"
                                    onDropDownItemClick={this.ERPDropdownClick}
                                    defaultPlaceholderDropDown={ERPDropdownDefaultValue}
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['erpShortName']) &&
                                    <span class="cm-error">{this.state.formErrors['erpShortName']}</span>
                                }
                            </div>
                            <div class="addnew-modal-form-group">
                                <p>ERP Name*</p>
                                <input type="text" placeholder="ERP Name" disabled
                                name="selectedERPName" value={this.state.selectedERPName}/>
                            </div>
                            <div class="addnew-modal-form-group">
                                <p>Navigation Menu</p>
                                <textarea type="text" placeholder="Enter Navigation Menu" spellCheck="true" 
                                name="navigationMenu" value={this.state.navigationMenu} onChange={this.handleChange}/>
                                {
                                    !isEmptyVariable(this.state.formErrors['navigationMenu']) &&
                                    <span class="cm-error">{this.state.formErrors['navigationMenu']}</span>
                                }
                            </div>
                            <div class="addnew-modal-form-group">
                                <p>Transaction Code</p>
                                <input type="text" placeholder="Enter Transaction Code"
                                name="transactionCode" value={this.state.transactionCode} onChange={this.handleChange}/>
                                {
                                    !isEmptyVariable(this.state.formErrors['transactionCode']) &&
                                    <span class="cm-error">{this.state.formErrors['transactionCode']}</span>
                                }
                                
                            </div>
                            <div class="addnew-modal-form-group">
                                <p>Description</p>
                                <textarea type="text" placeholder="Enter Description" spellCheck="true" 
                                name="description" value={this.state.description} onChange={this.handleChange}/>
                                {
                                    !isEmptyVariable(this.state.formErrors['description']) &&
                                    <span class="cm-error">{this.state.formErrors['description']}</span>
                                }
                                {/* {
                                    !isEmptyVariable(this.state.formErrors['apiErr']) &&
                                    <span class="cm-error">{this.state.formErrors['apiErr']}</span>
                                } */}
                            </div>
                        </div>
                        <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton themeSettings={this.props.themeSettings}
                                onClick={this.handleCreateDocERPMaster} type="button">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>

                </Modal>

                <Modal className="custom-dialog" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
                    <Modal.Header>
                        <h5>{this.state.selectedItem.erpShortName+" Details"}</h5>
                        <button 
                            onClick={this.handleDetailsDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        <div className="details-table-layout">
                            <div className="row custom-row">
                                <div className="col-md-12 custom-col">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td width={"24%"}>Document Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.props.documentName}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>ERP Short Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.erpShortName}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>ERP Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.erpName}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Navigation Menu</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{ifEmptyReturnEmptyStr(this.state.selectedItem.navigationMenu,"-")}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Trasaction Code</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.transactionCode}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Description</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.description}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Status</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.status}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <UploadDocumentERPSampleDialog
                    showUploadDocERPSampleDialog={this.state.showUploadDocERPSampleDialog}
                    handleUploadSampleERPDocDialogClose={this.handleUploadSampleERPDocDialogClose}
                    documentERPId={this.state.deleteReactivateId}
                    themeSettings={this.props.themeSettings}
                />

                <DocERPSamplesListDialog
                    allowEdit = {this.props.allowEdit}
                    documentName = {this.props.documentName}
                    showDocERPSamplesDialog = {this.state.showDocERPSamplesDialog}
                    handleDocERPSamplesDialogClose = {this.handleDocERPSamplesDialogClose}
                    selectedERP = {this.state.selectedItem}
                    themeSettings={this.props.themeSettings}
                    masterMenus={this.props.masterMenus}
                />
            </section>
        );
    }
}

export default DocumentERPMaster;