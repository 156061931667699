import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {SearchSpan,TableIcon} from '../../../common/Global/globalStyles.style';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import Pagination from '../../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,
    isEmptyArray,ifEmptyReturnEmptyStr} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import DocERPSamplesListDialog from "./listDocumentERPSample";
const userDetails  = getLocalStorageVariables();

const sortShortName = "e.shortName";
const sortNavigationMenu = "navigationMenu";
const sortTrasactionCode = "transactionCode";

class DocumentERPMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo:1,
            showLoader:false,
            totalCount:0,
            datalist:[],
            resultSize:Constants.RESULT_SIZE,
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",

            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,

            showDetailsDialog:false,
            showDocERPSamplesDialog:false,
            selectedItem:"",
        }
    }

    componentDidMount(){
        this.getDocumentERPMasterList();
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getDocumentERPMasterList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getDocumentERPMasterList();
            })
        }
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getDocumentERPMasterList();
        })
    }

    handleDocERPSamplesDialogClose = () => {
        this.setState({
            showDocERPSamplesDialog:false,
            selectedItem:"",
        },()=>{
            this.getDocumentERPMasterList();
        });
    }

    handleShowDocumentERPSamplesListing = (erp,e) => {
        e.stopPropagation();
        this.setState({
            showDocERPSamplesDialog:true,
            selectedItem:erp,
        })
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getDocumentERPMasterList();
        })
    }
    
    /*******************API CALLS*******************/
    getDocumentERPMasterList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetDocumentERPMaster,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                documentId:this.props.documentId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetDocumentERPMaster,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    documentId:this.props.documentId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <section>
                {
                    this.state.showLoader &&
                    <div class="loader"></div>
                }
                {
                    // Need to check component did mount flag, other wise until api is called,
                    // it shows no items found layout.
                    this.state.componentDidMountFlag &&
                    <div className="body-wrapper">
                        <div className="flex-center-layout secondary-top-bar" style={{marginTop:-10}}>
                            <div className="back-btn-layout" onClick={this.props.handleBackBtnClickFromSamplesandERP}>
                                <span class="material-icons-outlined">arrow_back_ios</span>
                            </div>
                            <h6>{this.props.documentName+"\u00A0\u00A0>\u00A0\u00A0ERP"}</h6>
                        </div>

                        <div className="search-addnew-bg">
                            <div className="search-addnew">
                                
                                <div className="search-filter-layout">
                                <FilterDropDown
                                        placeholder={this.state.resultSizePlaceholder}
                                        dropdownArr={this.state.resultSizeArr}
                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                        themeSettings={this.props.themeSettings}
                                        mleft={0}
                                    />
                                    <div className="search-col m-l-10">
                                        <input 
                                            name="searchkey" 
                                            type="text"
                                            onChange={this.handleChangeSearch}
                                            placeholder="Search" 
                                            onKeyPress={this.onEnterBtnPress}
                                            value={this.state.searchkey}
                                        />
                                        <SearchSpan 
                                        themeSettings={this.props.themeSettings}
                                        onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                    </div>

                                    
                                </div>

                            </div>
                        </div>

                        {
                            !isEmptyArray(this.state.datalist) && 
                            <div className="common-tab-bg">
                                <div className="common-table" style={{width:"100%"}}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                            <th className="text-center" width={"5%"}>No.</th>
                                            <th width={"20%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortShortName)}>
                                                <div className="sort-header">ERP Short Name
                                                <span className={(this.state.sort === sortShortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortNavigationMenu)}>
                                                <div className="sort-header">Navigation Menu
                                                <span className={(this.state.sort === sortNavigationMenu)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th width={"20%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortTrasactionCode)}>
                                                <div className="sort-header">Transaction Code
                                                <span className={(this.state.sort === sortTrasactionCode)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            {/* <th width={"10%"}>Users Count</th> */}
                                            <th className="text-center"  width={"10%"}>Samples</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            this.state.datalist.map((item,idx) => {
                                                return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                    <td>{item.erpShortName}</td>
                                                    <td>{truncateString(item.navigationMenu,Constants.OTHERMASTERS_STRING_LENGTH)}</td>
                                                    <td>{item.transactionCode}</td>
                                                    <TableIcon 
                                                        themeSettings={this.props.themeSettings} className="text-center">
                                                        <span class="material-icons" onClick={this.handleShowDocumentERPSamplesListing.bind(this, item)}>topic</span>
                                                    </TableIcon>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {
                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                            <div class="no-items-layout">
                                <div class="no-items-card">
                                    <h6>
                                    {
                                        isEmptyVariable(this.state.apiSearchKey)?
                                        Constants.NO_RECORDS_WARNING
                                        :
                                        Constants.EMPTY_SEARCH_WARNING
                                    }
                                    </h6>
                                </div>
                            </div>
                        }
                        <div className="pagination-layout">
                            <div className="row custom-row">
                                <div className="col-md-9 custom-col">
                                {
                                    (this.state.totalCount > this.state.datalist.length) && 
                                    <Pagination 
                                        totalLength ={this.state.totalCount} 
                                        items={this.state.datalist} 
                                        onChangePage={this.onChangePage} 
                                        pageSize={this.state.resultSize}
                                        currentPageNo = {this.state.currentPageNo}
                                        initialPage={this.state.currentPageNo} 
                                        themeSettings={this.props.themeSettings} />
                                }
                                </div>
                                <div className="col-md-3 custom-col">
                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <Modal className="custom-dialog" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
                    <Modal.Header>
                        <h5>{this.state.selectedItem.erpShortName+" Details"}</h5>
                        <button 
                            onClick={this.handleDetailsDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        <div className="details-table-layout">
                            <div className="row custom-row">
                                <div className="col-md-12 custom-col">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td width={"24%"}>Document Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.props.documentName}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>ERP Short Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.erpShortName}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>ERP Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.erpName}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Navigation Menu</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{ifEmptyReturnEmptyStr(this.state.selectedItem.navigationMenu,"-")}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Trasaction Code</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.transactionCode}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Status</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.status}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <DocERPSamplesListDialog
                    documentName = {this.props.documentName}

                    showDocERPSamplesDialog = {this.state.showDocERPSamplesDialog}
                    handleDocERPSamplesDialogClose = {this.handleDocERPSamplesDialogClose}
                    selectedERP = {this.state.selectedItem}
                    themeSettings={this.props.themeSettings}
                />
            </section>
        );
    }
}

export default DocumentERPMaster;