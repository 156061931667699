import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,FilterCheckBox,AlertInfo} from '../../../common/Global/globalStyles.style';
import Sidebar from '../../../common/Sidebar';
import Topbar from '../../../common/Topbar';
import MasterMenuTabs from '../Common/topmenu';
import IndustryTreeDialog from './industryTree'
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../../common/AlertDialog';
import Pagination from '../../../common/Global/pagination';
import UpdateReviewStatusDialog from './industryupdatereviewstatusdialog'
import ReviewHistoryDialog from '../../../common/reviewhistorydialog'
import AddIndustryDialog from './addrootIndustrydialog'
import {getLocalStorageVariables,isEmptyVariable,GetOrderedMasterTabMenu,
    getThemeSettingsVariables,isEmptyArray,getStatusSpan} from '../../../common/Global/commonFunctions';
import {getDropdownActionArray} from '../../../common/Global/reviewFunctions';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import SelectCopyIndustryDialog from './CopyIndustry/selectCopyIndustryDialog';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
const activeStatus="Active";
const inActiveStatus="Inactive";
const underReviewStatus="Under Review";
const departmentFilterDropdownDefaultValue = "All";
class IndustryMasters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE_GRID,
            totalCount:0,
            datalist:[],
            deletePermission:"N",

            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE_GRID,
            departmentArr:[],
            deptOnly:"N",
            actionType:"",

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            reviewLevel:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            showBtnLoader:false,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,

            status:activeStatus,
            statusArr:[
                {
                    label:activeStatus,
                    isSelected:true
                },
                {
                    label:underReviewStatus,
                    isSelected:false
                },
            ],
            resultSizeArr: JSON.parse(JSON.stringify(Constants.RESULT_SIZE_GRID_DD_ARR)),

            showDetailsDialog:false,
            selectedItem:"",

            industryName_new:"",
            industryShortName:"",
            selectedId:"",
            rootIndustryId:"",
            showIndustryTreeDialog:false,

            idToBeExpandedAfterAddingChild:"",
            industryMastersInfoFlag:userDetails.industryMastersInfoFlag,
        }
    }

    componentDidMount(){
        this.getInitData();
    }

    handleIndustryTreeDialogClose = (reloadFlag) => {
        this.setState({
            showIndustryTreeDialog:false,
            rootIndustryId:""
        },()=> {
            if(reloadFlag === true) this.getIndustryMasterList();
        })
    }

    showIndustryTreeDialog = (item) =>{
        this.setState({
            showIndustryTreeDialog:true,
            rootIndustryId:item.industryId,
            rootNode:item
        })
    }

    handleCheck = (e) =>{
        let isChecked = e.target.checked;
        let name = e.target.name;

        if(isChecked){
            this.setState({
                deptOnly:"Y"
            },()=>{
                this.getIndustryMasterList()
            })
        }else{
            this.setState({
                deptOnly:"N"
            },()=>{
                this.getIndustryMasterList()
            })
        }

    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getIndustryMasterList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getIndustryMasterList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag === true){
                this.getIndustryMasterList();
            }
        });
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateId:"",
        });
    }

    handleCreateDialogClose = () =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            selectedId:"",
            name:"",
            shortName:"",
            description:"",
        });
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getIndustryMasterList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getIndustryMasterList();
        })
    }

    deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let id = "";
        let filterDeptArr = this.state.filterDeptArr;

        for(const [i,value] of filterDeptArr.entries()){
            if(i === selectedIndex){
                filterDeptArr[i].isSelected = true;
                placeholder = value.name;
                id = value.departmentId;
            }else{
                filterDeptArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo: 1,
            selectedFilterDepartmentName:placeholder,
            selectedFilterDepartmentId:id
        },() => {
            this.getIndustryMasterList();
        })
    }

    //When user clicks on add new from search and filters layout(Change required in 3 functions)
    handleCreateDialogShow = () => {
        this.setState({
            showCreateDialog:true,

            isEdit:false,
            selectedId:"",
            industryName_new:"",
            rootIndustryId:""
        });
    }

    handleAddEditDialogClose = (reloadFlag) => {
        this.setState({
            isEdit:false,
            showCreateDialog:false,
        },()=>{
            if(reloadFlag === true) this.getIndustryMasterList();
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Industry",
                proceedBtnLabel:"Delete",
                deleteReactivateId:dropdownItem.industryId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete industry "+dropdownItem.industryName_new+"?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Activate Industry",
                proceedBtnLabel:"Reactivate",
                deleteReactivateId:dropdownItem.industryId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate industry "+dropdownItem.industryName_new+"?"
            })
        }else if(item.label === "Edit"){
            this.setState({
                showCreateDialog:true,

                isEdit:true,
                selectedId:dropdownItem.industryId,
                industryName_new:dropdownItem.industryName_new,
                industryShortName:dropdownItem.industryShortName,
                rootIndustryId:dropdownItem.industryId,
            });
        }else if(item.label === "Submit For Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Submit For Review",
                proceedBtnLabel:"Submit",
                deleteReactivateId:dropdownItem.industryId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to submit "+dropdownItem.industryName_new+" for review?"
            })
        }else if(item.label === "Start Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.industryId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+dropdownItem.industryName_new
            })
        }else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.industryId,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.industryId,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Review Remarks"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Review Remarks",
                proceedBtnLabel:"Okay",
                showAlertDialog:true,
                alertDialogMessage:'\"'+dropdownItem.lastReviewRemark+'\"'
            })
        }else if(item.label === "Discard Changes"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Discard Changes",
                proceedBtnLabel:"Discard",
                deleteReactivateId:dropdownItem.industryId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to discard changes of "+dropdownItem.industryName_new+"?",
                actionType: dropdownItem.previousStatus === "Updated"?"Update":(dropdownItem.previousStatus === "Deleted"?"Delete":"")
            })
        }
    }
    
     //copy data dialog
    showCopyIndustryDialog = () => {
        let selectedDepartmentId = this.state.selectedDepartmentId;
        let selectedDepartmentName = this.state.selectedDepartmentName;

        if(this.state.departmentArr.length > 1){
            selectedDepartmentId="";
            selectedDepartmentName="Select Department";
        }

        this.setState({
            showCopyIndustryDialog:true,
           
            masterType:"Select Master Type",
            selectedDepartmentId:selectedDepartmentId,
            selectedDepartmentName:selectedDepartmentName,
            submitClickedAtleastOnce:false,
            // formErrors:{}
        });
    }
    handleCopyIndustryClose = () =>{
        this.setState({
            showCopyIndustryDialog:false,
           
        },()=>{
            this.getIndustryMasterList();
        });
    }

    handleCloseFunctionMastersInfo = () =>{

        this.setState({
            operationType:"Close Message",
            alertDialogHeading:"Close Message",
            proceedBtnLabel:"Okay",
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to close message?"
        });
    }

    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetIndustriesCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetRootIndustries,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status_new:this.state.status,
                    departmentOnly:this.state.deptOnly,
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([masterRes,generalRes,menuRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,generalRes,menuRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;
            let menusTemp = {};
            let deptTemp = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    deptTemp = masterRes.data.departments;
            }else{
                deptTemp = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenu(menusTemp)
            let deletePermission = "N";

            if(menusTemp["Delete Industry"] === "ALL"){
                deletePermission = "Y";
            }
            //Filter Dept array
            let filterDeptArr = [];
            let selectedFilterDepartmentId = "-100";
            let selectedFilterDepartmentName = "All";
            if(!isEmptyArray(deptTemp) && deptTemp.length===1){

            }else{
                let obj = {
                    departmentId:"-100",
                    name:departmentFilterDropdownDefaultValue,
                    isSelected:true
                }
                filterDeptArr = [...deptTemp];
                filterDeptArr.splice(0,0,obj)
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:datalistTemp,
                totalCount:totalCountTemp,
                menus:menusTemp2,
                departmentArr:deptTemp,
                deletePermission:deletePermission,

                filterDeptArr:filterDeptArr,
                selectedFilterDepartmentId:selectedFilterDepartmentId,
                selectedFilterDepartmentName:selectedFilterDepartmentName,
            })
        })
    }

    getIndustryMasterList = () => {
        this.setState({
            showLoader:true,
        });

        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status_new:this.state.status,
        }

        if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
            postParam.departmentId = this.state.selectedFilterDepartmentId;
        }

        fetch(Constants.GetRootIndustries,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:page,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status_new:this.state.status,
            }
    
            if(this.state.filterDeptArr.length > 1 &&
                this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
                postParam.departmentId = this.state.selectedFilterDepartmentId;
            }

            fetch(Constants.GetRootIndustries,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
        }
        let callApi = true;

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteIndustry;
            postParam.industryId = this.state.deleteReactivateId;
            postParam.rootIndustryId = this.state.deleteReactivateId;
            postParam.deleteChildren = "Y";
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ReactivateIndustry;
            postParam.industryId = this.state.deleteReactivateId;
        }else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitForReviewIndustries;
            postParam.rootIndustryId = this.state.deleteReactivateId;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.updateReviewStatusIndustries;
            postParam.rootIndustryId = this.state.deleteReactivateId;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Discard Changes"){
            url = Constants.DiscardChangesIndustry;
            postParam.rootIndustryId = this.state.deleteReactivateId;
            postParam.actionType = this.state.actionType;
        }else if(this.state.operationType === "Review Remarks"){
            callApi = false;
            this.setState({
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
            })
        }else if(this.state.operationType === "Close Message"){
            callApi = false;
            this.setState({
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
                industryMastersInfoFlag:false
            },()=>{
                // set localstorage if industryMastersInfoFlag is true
                if(userDetails.industryMastersInfoFlag){
                    let otherUserDetails = {
                        processMastersInfoFlag: userDetails.processMastersInfoFlag,
                        functionMastersInfoFlag: userDetails.functionMastersInfoFlag,
                        industryMastersInfoFlag: false
                }
                localStorage.setItem('otherUserDetails', JSON.stringify(otherUserDetails));
                }
            })
        }

        if(callApi){
            this.setState({
                showBtnLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showBtnLoader:false,
                    },() => {
                        this.getIndustryMasterList();
                    });
                }else{
                    this.setState({
                        showBtnLoader:false,
                        deleteReactivateId:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        actionType:"",
                        showAlertDialog:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    this.state.showLoader &&
                                    <div class="loader"></div>
                                }
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings={themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                        themeSettings={themeSettings}
                                                        onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                                    </div>

                                                    <FilterDropDown
                                                        placeholder={this.state.statusPlaceholder}
                                                        dropdownArr={this.state.statusArr}
                                                        onDropDownItemClick={this.statusDropdownClick}
                                                        themeSettings={themeSettings}
                                                    />
                                                    
                                                    {
                                                        this.state.filterDeptArr.length > 0 &&
                                                        <FilterDropDown
                                                            placeholder={this.state.selectedFilterDepartmentName}
                                                            dropdownArr={this.state.filterDeptArr}
                                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                                            name={"name"}
                                                            themeSettings={themeSettings}
                                                            dropdownWidth={"120px"}
                                                        />
                                                    }
                                                </div>
                                                {
                                                    this.state.menus[Constants.SCREEN_INDUSTRY] ==="ALL" && (
                                                        <div className="flex-center-layout" >
                                                            <AddNewButtonLayout themeSettings={themeSettings} style={{marginRight:10}}>
                                                                <a onClick={this.showCopyIndustryDialog} href="javascript:void(0);">
                                                                    <span class="material-icons" style={{fontSize:15}}> content_copy </span>
                                                                    <p>Copy Data</p>
                                                                </a>
                                                            </AddNewButtonLayout>
                                                            <AddNewButtonLayout themeSettings={themeSettings}>
                                                                <a onClick={this.handleCreateDialogShow} href="javascript:void(0);">
                                                                    <span className="material-icons">add_circle</span>
                                                                    <p>Add New</p>
                                                                </a>
                                                            </AddNewButtonLayout>
                                                        </div>
                                                    )   
                                                }
                                            </div>
                                        </div>
                                        {
                                            this.state.industryMastersInfoFlag && 
                                            <AlertInfo themeSettings={themeSettings}>
                                                <span className="material-icons info-icon" >info</span>
                                                <p className="flex-center-layout just-content">
                                                    <p>
                                                    <strong>Important: Concept for creating Industry tree:</strong> Tasks can be attached to any level of the nodes. Tasks get cumulated at the last node. Common tasks
                                                        can be attached to higher level nodes and specific tasks can be attached to specified nodes.
                                                    </p>
                                                    <span className="material-icons close-icon" onClick={this.handleCloseFunctionMastersInfo}>close</span>
                                                </p>
                                            </AlertInfo>
                                        }
                                        
                                        <div className="row custom-row-5">
                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            this.state.datalist.map((item,idx) => {
                                                let actionObj = getDropdownActionArray(item.allowEdit, item.allowReview, 
                                                    item.status, item.currentReviewStatus, this.state.deletePermission,item.status_new,item.previousStatus);
                                                let spanObj = getStatusSpan(item.previousStatus,themeSettings);
                                                let marTop = 10;
                                                if(idx === 0 || idx === 1 || idx === 2){
                                                    marTop=0;
                                                }

                                                return <div className="col-md-4 custom-col-5">
                                                    <div onClick={this.showIndustryTreeDialog.bind(this,item)}
                                                    className="tree-list-card-layout c-pointer" style={{marginTop:marTop}}>
                                                        <div className="tree-card-text-layout">
                                                            <p className="font-bold">{item.industryName_new}
                                                            {
                                                                this.state.status === "Under Review" &&
                                                                <span style={spanObj.spanStyle} className="span-tag">
                                                                    {spanObj.spanText}
                                                                </span>
                                                            }</p>
                                                            {!isEmptyArray(this.state.departmentArr) && this.state.departmentArr.length > 1 && 
                                                                <span>{item.deptName}</span>
                                                            }
                                                        </div>
                                                        {
                                                            actionObj.actionArr && actionObj.actionArr.length > 0 &&
                                                            <div className="action-dd-layout">
                                                            <TableDropDown 
                                                                actionArr={actionObj.actionArr}
                                                                onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                dropDownId = {item}
                                                                dropdownWidth={actionObj.dropdownWidth}
                                                                themeSettings={themeSettings}
                                                            />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                        </div>
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div class="no-items-layout">
                                                <div class="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings={themeSettings}/>
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                (this.state.operationType === "Review Remarks")
                                ?
                                Constants.ALERT_TYPE_ALERT
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            showLoader={this.state.showBtnLoader}
                            themeSettings={themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        />

                        <ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterDataId = {this.state.deleteReactivateId}
                            masterType={"Industry"}
                        />

                        <IndustryTreeDialog
                            showIndustryTreeDialog={this.state.showIndustryTreeDialog}
                            handleIndustryTreeDialogClose={this.handleIndustryTreeDialogClose}
                            rootIndustryId={this.state.rootIndustryId}
                            rootNode={this.state.rootNode}
                            idToBeExpandedAfterAddingChild={this.state.idToBeExpandedAfterAddingChild}
                            themeSettings={themeSettings}
                            newFlag = {"Y"}
                        />

                        <AddIndustryDialog
                            isEdit={this.state.isEdit}
                            showCreateDialog={this.state.showCreateDialog}
                            handleAddEditDialogClose={this.handleAddEditDialogClose}
                            createNodeType={"root"}
                            departmentArr={this.state.departmentArr}

                            selectedId={this.state.selectedId}
                            industryName={this.state.industryName_new}
                            industryShortName={this.state.industryShortName}
                            rootIndustryId={this.state.rootIndustryId}
                            themeSettings={themeSettings}
                        />

                        <SelectCopyIndustryDialog
                            showCopyIndustryDialog={this.state.showCopyIndustryDialog}
                            handleCopyIndustryClose={this.handleCopyIndustryClose}
                            themeSettings={themeSettings}
                        />
                    </section>
                </main>
            </Router>
        );
    }
}

export default IndustryMasters;