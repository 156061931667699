import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {PageSaveButton,TableCheckBox,CustomTableRow,AddNewButtonLayout,
    DialogSaveButton} from '../../../common/Global/globalStyles.style';
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable,getStatusSpan,
    removeHtmlTags,isEmptyArray} from '../../../common/Global/commonFunctions';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import {Accordion, Button, Card, Modal} from 'react-bootstrap';
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import { toast } from "react-toastify";

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();

const resourceDropdownPlaceholder="Select Resource";
const activeStatus="Active";

class AddTaskAndResource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            resultSizePlaceholder:Constants.RESULT_SIZE,
            status:activeStatus,

            users:[],
            processMap:[],
            processId:this.props.processId,

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            // addErrors:{},
            showAssignResourcesDialogFlag:false,
            resourceDropdownPlaceholder:resourceDropdownPlaceholder,

            filterDataList: [],
            FilterDropDownArr: [],
            FilterPlaceholder: "All",

            assigneResourcesFilterDropDownArr :[],
            assigneResourcesFilterDataList:[],
            assignResourcesPlaceholder:"All",
            activeKey: null,
            activeAssignResourceKey: null

        }
    }

    componentDidMount(){
        this.getInitData();
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.setState({
                componentDidMountFlag:false,
                apiSearchKey:"",
                searchkey:"",
                currentPageNo: 1,
                showLoader:false,
                resultSize:Constants.RESULT_SIZE,
                totalCount:0,
                datalist:[],
                resultSizePlaceholder:Constants.RESULT_SIZE,
                status:activeStatus,

                users:[],
                processMap:[],
                processId:this.props.processId,

                showAlertDialogInfo:false,
                alertDialogMessageInfo:"",

                // addErrors:{},
                showProcessFunctionsDialog:false,
                resourceDropdownPlaceholder:"Select Resource",

                
            },()=>{
                this.getInitData();
            })
            
        }
    }

    handleShowOtherMasterDialog = () => {
        this.setState({
            showAssignResourcesDialogFlag:true,
        })
    }

    resourceDropdownClick = (resource) => {

        let newDataList = {};
        for (const [key, value] of Object.entries(this.state.datalist)) {
            let newValue = value;
            let newTaskList = value.taskList.map(item=>{
                if(item.isResourceTaskSelected){
                    item.addedResourceToAssignment = true;
                    item.resourceName = resource.userName;
                    item.resourceId = resource.userId;
                    item.resourceDesignation = resource.designation;
                    item.resourcefullName = resource.fullName;
                }
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }
        this.setState({
            datalist:newDataList
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onCancelClick = () => {
        this.props.onClickBackBtnFromAddTasks();
    }

    //No need of return value since the array itself is passed and updated.
    addParentIdandHasChildrenFlag = (tempArr, processId, parentProcessId, parentProcessBreadCrumb) => {
        tempArr[processId].parentProcessId = parentProcessId;
        let breadCrumb = "";
        if(isEmptyVariable(parentProcessBreadCrumb)){
            breadCrumb = tempArr[processId].processName;
            tempArr[processId].breadCrumb = breadCrumb;
        }else{
            breadCrumb = parentProcessBreadCrumb + " > " + tempArr[processId].processName;
            tempArr[processId].breadCrumb = breadCrumb;
        }

        if(!isEmptyArray(tempArr[processId].children)){
            tempArr[processId].hasChildren = true;

            tempArr[processId].children.map(childNode => {
                this.addParentIdandHasChildrenFlag(tempArr,childNode.processId,processId,breadCrumb);
            });
        }else{
            tempArr[processId].hasChildren = false;
        }
    }

    handleStopPropagation = (e) => {
        e.stopPropagation();
    }
    // Tasks to Assignment Selection
    handleSelectionTask = (e) => {
        e.stopPropagation();
        let isChecked = e.target.checked;
        let indexAndProcessId = e.target.name;
        let iapArr = indexAndProcessId.split(":-:")
        let index = iapArr[0];
        let processId = iapArr[1];
        let list = this.state.datalist;

        //now select/unselect the task
        list[processId].taskList[index].isSelected = isChecked;

        //check whether all the tasks with in proc are selected
        let atleastOneNotSelected = list[processId].taskList.some(item => !item.isSelected)
        list[processId].isProcSelected = !atleastOneNotSelected;

        this.setState({
            datalist:list
        });
    }

    handleSelectionProcessTasks = (e) => {
        e.stopPropagation();
        let isChecked = e.target.checked;
        let processId = e.target.name;
        let list = this.state.datalist;

        list[processId].isProcSelected = isChecked;

        // also select all the tasks
        let newTasklist = list[processId].taskList.map(item=>{
            item.isSelected = isChecked
            return item;
        })
        list[processId].taskList = newTasklist;
        this.setState({
            datalist:list
        });
    }

    selectAll = () => {
        let newDataList = {};
        for (const [key, value] of Object.entries(this.state.datalist)) {
            let newValue = value;
            newValue.isProcSelected = true;
            let newTaskList = value.taskList.map(item=>{
                item.isSelected = true;
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }
        this.setState({
            datalist:newDataList
        })
    }

    unSelectAll = () => {
        let newDataList = {};
        for (const [key, value] of Object.entries(this.state.datalist)) {
            let newValue = value;
            newValue.isProcSelected = false;
            let newTaskList = value.taskList.map(item=>{
                item.isSelected = false;
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }
        this.setState({
            datalist:newDataList
        })
    }

    // Tasks to Resources Selection
    handleSelectionTaskInResourcesDialog = (e) => {
        e.stopPropagation();

        let isChecked = e.target.checked;
        let indexAndProcessId = e.target.name;
        let iapArr = indexAndProcessId.split(":-:")
        let index = iapArr[0];
        let processId = iapArr[1];
        let list = this.state.datalist;

        list[processId].taskList[index].isResourceTaskSelected = isChecked;

        //check whether all the tasks with in proc are selected
        let atleastOneNotSelected = list[processId].taskList.some(item => !item.isResourceTaskSelected)
        list[processId].isProcSelectedInResources = !atleastOneNotSelected;

        this.setState({
            datalist:list
        });
    }

    handleSelectionProcessTasksInResourcesDialog = (e) => {
        e.stopPropagation();
        let isChecked = e.target.checked;
        let processId = e.target.name;
        let list = this.state.datalist;

        list[processId].isProcSelectedInResources = isChecked;

        // also select all the tasks
        let newTasklist = list[processId].taskList.map(item=>{
            item.isResourceTaskSelected = isChecked
            return item;
        })
        list[processId].taskList = newTasklist;

        this.setState({
            datalist:list
        });
    }

    selectAllTasksResourcesDialog = () => {
        let newDataList = {};
        for (const [key, value] of Object.entries(this.state.datalist)) {
            let newValue = value;
            newValue.isProcSelectedInResources = true;
            //when user "selects all" in resource dialog, we should select all the tasks
            //that are added to the assignment
            let newTaskList = value.taskList.map(item=>{
                item.isResourceTaskSelected = item.added?true:false;
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }
        this.setState({
            datalist:newDataList
        })
    }

    unSelectAllTasksResourcesDialog = () => {
        let newDataList = {};
        for (const [key, value] of Object.entries(this.state.datalist)) {
            let newValue = value;
            newValue.isProcSelectedInResources = false;
            let newTaskList = value.taskList.map(item=>{
                item.isResourceTaskSelected = false;
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }
        this.setState({
            datalist:newDataList
        })
    }

    onClickAddToAssignment = () => {
        let newDataList = {};
        for (const [key, value] of Object.entries(this.state.datalist)) {
            let newValue = value;
            let newTaskList = value.taskList.map(item=>{
                if(item.isSelected){
                    item.added = true;
                }
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }
        this.setState({
            datalist:newDataList
        })

    }

    onClickRemoveFromAssignment = () => {
        let newDataList = {};
        for (const [key, value] of Object.entries(this.state.datalist)) {
            let newValue = value;
            let newTaskList = value.taskList.map(item=>{
                if(item.isSelected){
                    item.added = false;
                }
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }
        this.setState({
            datalist:newDataList
        })
    }

    getRootLevelFunctions = (functionMapTemp) => {
        let rootFunctionArr = [];
        Object.values(functionMapTemp).map((item)=>{
            if(item.level === 0){
                rootFunctionArr.push(item);
            }
        })
        return rootFunctionArr;
    }

    assignResourcesDialogClose = () => {
        this.setState({
            showAssignResourcesDialogFlag:false,
            activeAssignResourceKey:null
        })
    }
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetProcessesSubtree,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    departmentId:this.props.departmentId,
                    status:"ALL",
                    activeFlag:"Y"//get values from active tables
                })
            }),
            fetch(Constants.GetAssignTaskMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                })
            }),
            fetch(Constants.GetAssignedTasks,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                })
            })
        ])
        .then(([masterRes,generalRes,assignedTasksRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),assignedTasksRes.json()]) 
        })
        .then(([masterRes,generalRes,assignedTasksRes]) => {
            let processMapTemp = {};
            let datalistTemp = {};
            let assignedTasksMap = {};

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                processMapTemp = masterRes.result.processMap;
                let rootLevelArr = this.getRootLevelFunctions(processMapTemp)
                for(const [index,value] of rootLevelArr.entries()){
                    this.addParentIdandHasChildrenFlag(processMapTemp,value.processId,value.processId,"");
                }
            }else{
            }

            if(assignedTasksRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignedTasksRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(assignedTasksRes.responseCode === Constants.CODE_SUCCESS){
                let assignedTasks = assignedTasksRes.data.assignedTasks;

                assignedTasks.forEach(item=>{
                    assignedTasksMap[item.taskId] = item;
                })
            }else{
            }

            // console.log("Assigned Tasks: "+ JSON.stringify(assignedTasksMap));

            let users = [];
            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                let datalistTempArr = generalRes.data.taskList;//list of process and task
                let assignmentReviewMatrix = generalRes.data.assignmentReviewMatrix;
                let assignmentMemberMatrix = generalRes.data.assignmentMemberMatrix;
                //need to get only active users
                let activeMembers = assignmentMemberMatrix.filter(item=> item.status === "Active");
                let activeReviewers = assignmentReviewMatrix.filter(item=> item.status === "Active");

                users = [...activeMembers,...activeReviewers]
                
                datalistTemp = {};

                datalistTempArr.forEach((item)=>{
                    //add only leaf level nodes
                    if(processMapTemp[item.processId] && !processMapTemp[item.processId].hasChildren){
                        //Now lets add the already assigned tasks
                        let updatedTaskList = [];
                        item.taskList.forEach(task=>{
                            //check if the taskId is there in task map, if yes then add the 
                            //user info
                            if(!isEmptyVariable(assignedTasksMap[task.taskId])){
                                task.added = true;
                                task.assigmentTaskStatus = assignedTasksMap[task.taskId].status;
                                task.assignmentTaskId = assignedTasksMap[task.taskId].assignmentTaskId;
                                task.completionStatus = assignedTasksMap[task.taskId].completionStatus;
                                //check if user is assigned to the task
                                if(!isEmptyVariable(assignedTasksMap[task.taskId].userId)){
                                    task.resourceName = assignedTasksMap[task.taskId].userName;
                                    task.resourceId = assignedTasksMap[task.taskId].userId;
                                    task.resourceDesignation = assignedTasksMap[task.taskId].designation;
                                    task.resourcefullName = assignedTasksMap[task.taskId].userName+"("+assignedTasksMap[task.taskId].designation+")";
                                }
                            }

                            updatedTaskList.push(task);
                        })
                        item.taskList = updatedTaskList;
                        
                        datalistTemp[item.processId] = item;
                        datalistTemp[item.processId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists
                    }
                })
            }else{
            }

            let tempFilterArray = [
                {
                  label: "All",
                  isSelected: true,
                },
              ];
              Object.values(datalistTemp).map((processObj)=>{
                tempFilterArray.push({
                    label:processMapTemp[processObj.processId].processName,
                    isSelected:false
                })
              })
            console.log(JSON.stringify(datalistTemp));
            
            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                processMap:processMapTemp,
                datalist:datalistTemp,
                users:users,

                FilterDropDownArr:tempFilterArray,
                
            })
        })
    }

    onClickNextButton = () => {

        this.setState({
            showLoader:true
        })

        let isError = true;
        // let formErr = {};
        // formErr.assignTaskErr = "Please add atleast one task to the assignment"

        Object.values(this.state.datalist).map(processObj=>{
            processObj.taskList.map(item=>{
                if(item.added){
                    isError = false;
                    // formErr.assignTaskErr = "";
                }else{
                }
            })
        })

        let tempFilterArray = [
            {
                label: "All",
                isSelected: true,
            },
        ];

            
        Object.values(this.state.datalist).map((processObj)=>{
        
            let  showTasks=(processObj.taskList).some((item) =>item.added===true);
            if(showTasks){
                tempFilterArray.push({
                    label:this.state.processMap[processObj.processId].processName,
                    isSelected:false
                })
            }
        })

        //now do the validation
        if(isError){
            toast.warning("Please add atleast one task to the assignment");
            this.setState({
                // addErrors:formErr,
                showLoader:false
            });

            // setTimeout(() => {
            //     this.setState({
            //         addErrors:{},
            //     });
            // },Constants.WRNG_MSG_TIMEOUT);
        }else{
            this.setState({
                showAssignResourcesDialogFlag:true,
                showLoader:false,

                assigneResourcesFilterDataList:[],
                assigneResourcesFilterDropDownArr:tempFilterArray,
                assignResourcesPlaceholder:"All"
            })
        }
    }

    onClickAddResourceToAssignment = () => {
        let isError = false;
        // let otherMasterFormErr = "";
        Object.values(this.state.datalist).forEach(processObj=>{
            processObj.taskList.forEach(item=>{
                if(item.added && isEmptyVariable(item.resourceId)){
                    isError = true;
                    // otherMasterFormErr = "Please add resource to all the tasks";
                }else{
                    
                }
            })
        })

        if(isError){
            toast.warning("Please add resource to all the tasks");
            // this.setState({
            //     otherMasterFormErr:otherMasterFormErr
            // })

            // setTimeout(() => {
            //     this.setState({
            //         otherMasterFormErr:"",
            //     });
            // },Constants.WRNG_MSG_TIMEOUT);
        }else{
            //now parse the array
            let assignmentTasks = []
            Object.values(this.state.datalist).forEach(processObj=>{
                processObj.taskList.forEach(item=>{
                    if(item.added && !isEmptyVariable(item.resourceId)){
                        let obj = {
                            taskId:item.taskId,
                            userId:item.resourceId,
                            userName:item.resourceName,
                            designation:item.resourceDesignation,
                        }

                        if(!isEmptyVariable(item.assignmentTaskId)){
                            obj.assignmentTaskId = item.assignmentTaskId;
                        }
                        assignmentTasks.push(obj);
                    }
                })
            })


            //call the fetch function

            fetch(Constants.AssignTasks,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentTasks:JSON.stringify(assignmentTasks),
                    assignmentId:this.props.assignmentId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.props.onClickBackBtnFromAddTasks(true);
                }else{
                    toast.error(data.responseMessage);
                    // this.setState({
                    //     otherMasterFormErr:data.responseMessage
                    // })
        
                    // setTimeout(() => {
                    //     this.setState({
                    //         otherMasterFormErr:"",
                    //     });
                    // },Constants.WRNG_MSG_TIMEOUT);
                }
            });

        }
    }
    /*******************API CALLS END HERE*******************/



    filterDropDownOnClick = (selectedItem) =>{
        let tempFilterDataList =[];
        let tempFilterDataArray = this.state.FilterDropDownArr;
        if(selectedItem.label==="All")
        {
            this.setState({
                filterDataList:[],
            })
        }
        else{

            Object.values(this.state.datalist).map((item)=>{
                if(selectedItem.label === item.processName)
                {
                    tempFilterDataList.push(
                        item
                    )
                }
            })

        }
        tempFilterDataArray.map((item) => {
            if (item.label === selectedItem.label) {
            item.isSelected = true;
            } else {
            item.isSelected = false;
            }
          });

        this.setState({
            filterDataList:tempFilterDataList,
            FilterDropDownArr:tempFilterDataArray,
            FilterPlaceholder:selectedItem.label

        })

    }

    assigneResourceFilterDropDownOnClick = (selectedItem) =>{
        let tempFilterDataList =[];
        let tempFilterDataArray = this.state.assigneResourcesFilterDropDownArr;
        if(selectedItem.label==="All")
        {
            this.setState({
                filterDataList:[],
            })
        }
        else{

            Object.values(this.state.datalist).map((item)=>{
                if(selectedItem.label === item.processName)
                {
                    tempFilterDataList.push(
                        item
                    )
                }
            })

        }
        tempFilterDataArray.map((item) => {
            if (item.label === selectedItem.label) {
            item.isSelected = true;
            } else {
            item.isSelected = false;
            }
          });

        this.setState({
            assigneResourcesFilterDataList:tempFilterDataList,
            assigneResourcesFilterDropDownArr:tempFilterDataArray,
            assignResourcesPlaceholder:selectedItem.label

        })
    }

    handleAccordionToggle = (eventKey) => {
        if (this.state.activeKey === eventKey) {
            this.setState({ activeKey: null });
        } else {
            this.setState({ activeKey: eventKey });
        }
    }

    handleAccordionToggleAssignResource = (eventKey) => {
        if (this.state.activeAssignResourceKey === eventKey) {
            this.setState({ activeAssignResourceKey: null });
        } else {
            this.setState({ activeAssignResourceKey: eventKey });
        }
    }

    render() {
        return(
            <div>
                {
                    this.state.showLoader &&
                    <div class="loader"></div>
                }
                {
                    // Need to check component did mount flag, other wise until api is called,
                    // it shows no items found layout.
                    this.state.componentDidMountFlag &&
                    <div className="body-wrapper">
                        <div className="secondary-bar-wrapper">
                            <div className="flex-center-layout secondary-top-bar m-t-0">
                                <div className="back-btn-layout" onClick={this.props.onClickBackBtnFromAddTasks}>
                                    <span class="material-icons-outlined">arrow_back_ios</span>
                                </div>
                                <h6>{this.props.referenceNo}</h6>
                            </div>
                            <div className="flex-center-layout">
                                <div style={{ marginRight: 10 }}>
                                    <FilterDropDown
                                        placeholder={this.state.FilterPlaceholder}
                                        dropdownArr={this.state.FilterDropDownArr}
                                        onDropDownItemClick={this.filterDropDownOnClick}
                                        themeSettings={themeSettings}
                                        name={"label"}
                                        dropdownWidth="200px"
                                    />
                                </div>

                                <div className="bordered-anchor"
                                style={{marginRight:5}}>
                                    <a onClick={this.unSelectAll} href="javascript:void(0);">
                                        <span class="material-icons-outlined">
                                        check_box_outline_blank
                                        </span>
                                        <p>Unselect All</p>
                                    </a>
                                </div>
                                <div className="bordered-anchor"
                                style={{marginRight:5}}>
                                    <a onClick={this.selectAll} href="javascript:void(0);">
                                        <span class="material-icons-outlined">
                                        check_box
                                        </span>
                                        <p>Select All</p>
                                    </a>
                                </div>
                                <AddNewButtonLayout themeSettings={themeSettings}
                                style={{marginRight:5}}>
                                    <a onClick={this.onClickRemoveFromAssignment} href="javascript:void(0);">
                                        <span className="material-icons">remove_circle_outline</span>
                                        <p>Remove</p>
                                    </a>
                                </AddNewButtonLayout>

                                <AddNewButtonLayout themeSettings={themeSettings}>
                                    <a onClick={this.onClickAddToAssignment} href="javascript:void(0);">
                                        <span className="material-icons">add_circle</span>
                                        <p>Add to Assignment</p>
                                    </a>
                                </AddNewButtonLayout>

                                <AddNewButtonLayout themeSettings={themeSettings} style={{marginLeft:5}}>
                                    <a onClick={this.onClickNextButton} href="javascript:void(0);">
                                        {/* <span className="material-icons">add_circle</span> */}
                                        <p>Next</p>
                                        <span class="material-icons-outlined">arrow_forward_ios</span>
                                    </a>
                                </AddNewButtonLayout>
                                
                            </div>
                        </div>

                        <div className="assign-tasks-layout accordion-layout">
                            {
                                Object.values( isEmptyArray(this.state.filterDataList)?this.state.datalist:this.state.filterDataList).map((processObj,index)=>{
                                    let className;
                                    if (index === 0) {
                                        className = 'card-radius'; // CSS for the first item
                                    } else if (index === (Object.values(isEmptyArray(this.state.filterDataList)?
                                    this.state.datalist:this.state.filterDataList).length - 1)) {
                                        className = 'card-last'; // CSS for the last item
                                    } else {
                                        className = 'card-middle'; // CSS for all other items
                                    }
                                    return <div className="process-task-list">
                                        <Accordion style={{marginTop:index === 0?15:0}} activeKey={this.state.activeKey}>
                                            <Card className={className}> 
                                                <Card.Header>
                                                    <Accordion.Toggle
                                                        as={Button}
                                                        variant="link"
                                                        eventKey={processObj.processId}
                                                        onClick={() => this.handleAccordionToggle(processObj.processId)}
                                                    >
                                                        <div className='row align-items-center'>
                                                            <TableCheckBox onClick={this.handleStopPropagation}
                                                                themeSettings={themeSettings}>
                                                                <input 
                                                                    type="checkbox"
                                                                    name={processObj.processId}
                                                                    id={processObj.processId}
                                                                    onChange={this.handleSelectionProcessTasks}
                                                                    checked={processObj.isProcSelected?true:false}
                                                                />
                                                                <label for={processObj.processId} 
                                                                style={{marginLeft:-3}}></label>
                                                            </TableCheckBox>
                                                            <h6 className='col-md-6 text-left'>{this.state.processMap[processObj.processId].breadCrumb}</h6>
                                                                {console.log(processObj.count,":::processObj.count")}
                                                                {
                                                                    processObj.count > 0 &&
                                                                    <h6 className='col-md-2 text-left'>{"Total Task: "+processObj.count}</h6>
                                                                }
                                                                <h6 className='col-md-3 text-center'>{"Added to Assignment: "+processObj.taskList.filter(item => item.added == true).length}</h6>
                                                        </div>
                                                    </Accordion.Toggle>
                                                </Card.Header> 
                                                <Accordion.Collapse eventKey={processObj.processId}>
                                                    <Card.Body>
                                                        {
                                                            !isEmptyArray(processObj.taskList) && 
                                                            <div className="common-tab-bg">
                                                                <div class="common-table" style={{width: "100%", borderRadius: "4px"}}>
                                                                    <div className="table-custom-layout">
                                                                        <div className="table-custom-header">
                                                                            <div className="table-custom-header-row">
                                                                                <p style={{width:"5%"}}></p>
                                                                                <p style={{width:"5%",justifyContent:"center"}}>No</p>
                                                                                <p style={{width:"52%"}}>Task</p>
                                                                                <p style={{width:"30%"}}>Resource</p>
                                                                                <p style={{width:"8%"}}>Added</p>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div className="table-custom-body">
                                                                        {
                                                                            !isEmptyArray(processObj.taskList) && 
                                                                            processObj.taskList.map((item,idx) => {
                                                                                let spanObj = getStatusSpan(item.assigmentTaskStatus,themeSettings);

                                                                                return <CustomTableRow themeSettings={themeSettings}
                                                                                className={item.disabled?"row-disable":""}>
                                                                                    <div className="table-cell" style={{width:"5%"}}>
                                                                                        <TableCheckBox onClick={this.handleStopPropagation}
                                                                                            themeSettings={themeSettings}>
                                                                                            <input 
                                                                                                type="checkbox"
                                                                                                name={idx+":-:"+processObj.processId}
                                                                                                id={item.taskId}
                                                                                                onChange={this.handleSelectionTask}
                                                                                                disabled={item.disabled}
                                                                                                checked={item.isSelected?true:false}
                                                                                            />
                                                                                            <label for={item.taskId} 
                                                                                            style={{marginLeft:6}}></label>
                                                                                        </TableCheckBox>
                                                                                    </div>
                                                                                    <p style={{width:"5%",justifyContent:"center"}}>{(idx+1)}</p>
                                                                                    <p style={{width:"52%"}}>
                                                                                        {/* {removeHtmlTags(item.task)} */}
                                                                                        <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: item.task}} />
                                                                                        {
                                                                                            item.completionStatus==="Completed" &&
                                                                                            <span style={{marginLeft:6}}className="material-icons">done_all</span>
                                                                                        }
                                                                                        {
                                                                                            !isEmptyVariable(spanObj.spanText) &&
                                                                                            <span style={spanObj.spanStyle} className="span-tag">
                                                                                                {spanObj.spanText}
                                                                                            </span>
                                                                                        }
                                                                                    </p>
                                                                                    <p style={{width:"30%"}}>{isEmptyVariable(item.resourcefullName)?"":item.resourcefullName}</p>
                                                                                    <p style={{width:"8%"}}>
                                                                                        {
                                                                                            item.added &&
                                                                                            <span class="material-icons"
                                                                                            style={{color:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`}}>check_circle</span>
                                                                                        }
                                                                                    </p>
                                                                                    
                                                                                </CustomTableRow>
                                                                            })
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(processObj.taskList) &&
                                            <div class="no-items-layout">
                                                <div class="no-items-card" style={{padding:"10px"}}>
                                                    <h6 style={{margin:0}}>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                })
                            }
                            {/* {
                                !isEmptyVariable(this.state.addErrors['assignTaskErr']) &&
                                <span class="cm-error">{this.state.addErrors['assignTaskErr']}</span>
                            } */}
                            {/* <div className="row custom-row">
                                <div className="col-md-12 custom-col">
                                    <div className="common-close-save">
                                        <div className="common-close-save-col">
                                            <button onClick={this.onCancelClick} type="button" className="common-close">Cancel</button>
                                            <PageSaveButton 
                                            themeSettings = {themeSettings}
                                            onClick={this.onClickNextButton} type="button">Next</PageSaveButton>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                }

                {/* Add Resources Dialog */}

                <Modal className="task-select-table-dialog custom-dialog"
                    show={this.state.showAssignResourcesDialogFlag} 
                    backdrop="static">
                    <Modal.Header>
                        <h5>Assign Resources</h5>
                        <button 
                            onClick={this.assignResourcesDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                            <div className="body-wrapper">
                                <div className="search-addnew-bg" style={{marginTop:0}}>
                                    <div className="search-addnew">
                                        <div className="flex-center-layout">
                                            <div style={{ marginRight: 10 }}>

                                                <FilterDropDown
                                                    placeholder={this.state.assignResourcesPlaceholder}
                                                    dropdownArr={this.state.assigneResourcesFilterDropDownArr}
                                                    onDropDownItemClick={this.assigneResourceFilterDropDownOnClick}
                                                    themeSettings={themeSettings}
                                                    name={"label"}
                                                    dropdownWidth="200px"
                                                />
                                            </div>
                                            <div className="bordered-anchor"
                                            style={{marginRight:5}}>
                                                <a onClick={this.unSelectAllTasksResourcesDialog} href="javascript:void(0);">
                                                    <span class="material-icons-outlined">
                                                    check_box_outline_blank
                                                    </span>
                                                    <p>Unselect All</p>
                                                </a>
                                            </div>
                                            <div className="bordered-anchor"
                                            style={{marginRight:5}}>
                                                <a onClick={this.selectAllTasksResourcesDialog} href="javascript:void(0);">
                                                    <span class="material-icons-outlined">
                                                    check_box
                                                    </span>
                                                    <p>Select All</p>
                                                </a>
                                            </div>
                                            <div style={{width:"300px"}}>
                                                <RegularDropdown 
                                                    placeholder={this.state.resourceDropdownPlaceholder}
                                                    dropdownArr={this.state.users}
                                                    labelParam="fullName"
                                                    onDropDownItemClick={this.resourceDropdownClick}
                                                    defaultPlaceholderDropDown={resourceDropdownPlaceholder}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <DialogSaveButton  themeSettings={themeSettings} 
                                            onClick={this.onClickAddResourceToAssignment} type="button" 
                                            className="modal-save m-b-0">Assign</DialogSaveButton>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="assign-taskresource accordion-layout" style={{position:"relative"}}>
                                    {
                                       Object.values( isEmptyArray(this.state.assigneResourcesFilterDataList)?this.state.datalist:this.state.assigneResourcesFilterDataList)
                                       .filter(processObj => processObj.taskList.some((item) => item.added === true))
                                       .map((processObj,index)=>{
                                        let showTasks =false;
                                        let className;
                                        if (index === 0) {
                                            className = 'card-radius'; // CSS for the first item
                                        } else if (index === ((Object.values(isEmptyArray(this.state.assigneResourcesFilterDataList)?
                                            this.state.datalist:this.state.assigneResourcesFilterDataList))
                                            .filter(processObj => processObj.taskList.some((item) => item.added===true))).length - 1) {
                                            className = 'card-last'; // CSS for the last item
                                        } else {
                                            className = 'card-middle'; // CSS for all other items
                                        }
                                        // showTasks=!!processObj.taskList.find(({added}) =>added===true)
                                        showTasks=processObj.taskList.some((item) =>item.added===true)
                                        if(showTasks){
                                            return <div className="process-task-list">
                                                <Accordion style={{marginTop:index === 0?15:0}} activeKey={this.state.activeAssignResourceKey}>
                                                    <Card className={className}> 
                                                        <Card.Header>
                                                            <Accordion.Toggle
                                                                as={Button}
                                                                variant="link"
                                                                eventKey={processObj.processId}
                                                                onClick={() => this.handleAccordionToggleAssignResource(processObj.processId)}
                                                            >
                                                                <div className='row align-items-center'>
                                                                    <TableCheckBox
                                                                        themeSettings={themeSettings}>
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={processObj.processId}
                                                                            id={"RES"+processObj.processId}
                                                                            onChange={this.handleSelectionProcessTasksInResourcesDialog}
                                                                            checked={processObj.isProcSelectedInResources?true:false}
                                                                        />
                                                                        <label for={"RES"+processObj.processId} 
                                                                        style={{marginLeft:5}}></label>
                                                                    </TableCheckBox> 
                                                                    <h6 className='ml-3 text-left'>{this.state.processMap[processObj.processId].breadCrumb}</h6>
                                                                </div>
                                                            </Accordion.Toggle>
                                                        </Card.Header> 
                                                        <Accordion.Collapse eventKey={processObj.processId}>
                                                            <Card.Body>                                            
                                                                {
                                                                    !isEmptyArray(processObj.taskList) && 
                                                                    <div className="common-tab-bg">
                                                                        <div className="common-table" style={{width:"100%"}}>
                                                                            <table className="table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-center" width={"8%"}></th>
                                                                                        <th className="text-center" width={"5%"}>No.</th>
                                                                                        <th width={"55%"}>Tasks</th>
                                                                                        <th width={"33%"}>User</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>

                                                                                {/* {
                                                                                    Object.values(this.state.datalist).map((processObj,index)=>{
                                                                                        let i = 0; */}
                                                                                        {/* return processObj.taskList.map((item,idx) => { */}
                                                                                {
                                                                                    !isEmptyArray(processObj.taskList) && 
                                                                                    processObj.taskList.map((item,idx) => {

                                                                                        if(item.added){
                                                                                            return <tr>
                                                                                                <td>
                                                                                                    <TableCheckBox
                                                                                                        themeSettings={themeSettings}>
                                                                                                        <input 
                                                                                                            type="checkbox"
                                                                                                            name={idx+":-:"+processObj.processId}
                                                                                                            id={"RES"+item.taskId}
                                                                                                            onChange={this.handleSelectionTaskInResourcesDialog}
                                                                                                            checked={item.isResourceTaskSelected?true:false}
                                                                                                        />
                                                                                                        <label for={"RES"+item.taskId} 
                                                                                                        style={{marginLeft:6}}></label>
                                                                                                    </TableCheckBox>
                                                                                                </td>
                                                                                                <td className="text-center">{(++idx)}</td>
                                                                                                <td>
                                                                                                    <div className="flex-center-layout">
                                                                                                    {removeHtmlTags(item.task)}
                                                                                                    {
                                                                                                        item.completionStatus==="Completed" &&
                                                                                                        <span style={{marginLeft:6,fontSize:18}}
                                                                                                        className="material-icons">done_all</span>
                                                                                                    }
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>{isEmptyVariable(item.resourcefullName)?"":item.resourcefullName}</td>
                                                                                            </tr>
                                                                                        }
                                                                                    })
                                                                                }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                            </div>}
                                        })
                                    }
                                    </div>
                                </div>
                                {/* {
                                    !isEmptyVariable(this.state.otherMasterFormErr) &&
                                    <span className="cm-error">{this.state.otherMasterFormErr}</span>
                                } */}
                                {/* <div className="modal-close-save m-t-15" style={{marginBottom:20}}>
                                    <div className="close-save-col">
                                        <button onClick={this.assignResourcesDialogClose} type="button" 
                                        className="modal-close m-b-0">Cancel</button>
                                        
                                    </div>
                                </div> */}
                            </div>
                </Modal>
            </div>
        );
    }
}

export default AddTaskAndResource;