import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import jszip from "jszip";
import {
  getLocalStorageVariables,
  getThemeSettingsVariables,
  GetOrderedMasterTabMenuReports,
  isEmptyVariable,
  isEmptyArray,
  removeHtmlTags,
  isJsonString,
} from "../../../common/Global/commonFunctions";
import TreeNodeDropdown from "../../../common/DropdownMenus/TreeNodeDropdownObservations";
import AddHeadingDialog from "./addHeadingdialog";
import AddTextDialog from "./addTextdialog";
import AlertDialog from "../../../common/AlertDialog";
import {
  ExpandCollapseWrapper,
  TaskTagButtonsLayout,
} from "../../../common/Global/globalStyles.style";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-navigations/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import Sidebar from "../../../common/SidebarCollapse";
import Topbar from "../../../common/Topbar";
import MasterMenuTabs from "../Common/topmenu";
import ObservationDetailsLayout from "../../Execute/Observation/ObservationDetails/observationDetails";
import SelectRiskDialog from "../Common/selectRisk";
import { Document, Packer, PageOrientation, BorderStyle } from "docx";
import { saveAs } from "file-saver";
import {
  getFirstPageContent,
  getHeaderContent,
  getFooterContent,
  getParagraphStyles,
  getTableContent,
  getAssignementTableHeadingContent,
  getExecutiveSummaryContent,
  getValueAdditionContent,
  getObservationContentWithTemplates,
  getSecondFormatObservationsContentWithTemplates,
} from "../Common/createDownloadReportFunctions";
import AssignmentHeading from "../Common/assignmentHeadingDetails";
import RegularDropdown from "../../../common/DropdownMenus/RegularDropdownReports";
import DonotReportObservationsLayout from "../Common/DonotReportObservationList";
import AssignmentTaskLayout from "../Common/assignmentTaskList";
import TopmenuReports from "../../../common/TopmenuReports";
import OverallAssignmentComponent from '../../../common/OverallAssignment';

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();

const AddChildDropdownObj = {
  icon: "add_circle",
  label: "Add Child",
};
const AddTextDropdownObj = {
  icon: "add_circle",
  label: "Add Text",
};

const AddSiblingDropdownObj = {
  icon: "add_box",
  label: "Add Sibling",
};
const editDropdownObj = {
  icon: "edit",
  label: "Edit",
};
const editTextDropdownObj = {
  icon: "edit",
  label: "Edit Text",
};
const delDropdownObj = {
  icon: "delete",
  label: "Delete",
};
const ObsDetailsDropdownObj = {
  icon: "description",
  label: "Observation Details",
};
const donotReportDropdownObj = {
  icon: "description",
  label: "Do not Report",
};
const downloadReportPlaceholder = "Download Report";

const resetLayoutFlags = {
  showObservationTree: false,
  showObservationDetails: false,
  showDonotReportObservations: false,
  showTaskList: false,
};


class HeadingTreeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,

      assignmentId: this.props.match.params.id,
      headingMap: {},
      assignmentObj: {},
      actionArr: [],
      allowEdit: "Y",
      syncFusionData: [],
      rootReportHeadingId: "",
      company: {},

      showCreateDialog: false,
      showCreateTextDialog: false,
      isEdit: false,
      createNodeType: "",
      selectedId: "",
      heading: "",
      relativeFunctionId: "",
      clearAndResetFunctionTree: true,
      idToBeExpandedAfterAddingChild: "",

      operationType: "",
      deleteReactivateId: "",
      showAlertWithRadioDialog: false,
      alertWithRadioDialogMessage: "",

      showAlertDialogInfo: false,
      alertDialogMessageInfo: "",

      showObservationTree: false,
      showObservationDetails: false,
      showDonotReportObservations: false,
      showTaskList: false,

      isCreatingDraft: false,

      //download report
      companyDownloadReport: "",
      observationsDownloadReport: "",
      downloadReportArr: Constants.REPORT_DOWNLOAD_ARR,
      downloadReportPlaceholder: downloadReportPlaceholder,

      filterPlaceholder: "Tasks",
    };
    this.fields = {};
    this.headingObj = {};
    this.newObservationsMap = {};
    this.newObservationsArr = [];
    this.headingList = [];
  }

  componentDidMount() {
    this.fields = {
      dataSource: [],
      id: "nodeId",
      text: "reportHeadingText",
      parentID: "parentId",
      child: "newChildren",
      expanded: "isExpanded",
    };
    this.getFunctionTree();

  }

 

  // Overall Risk
  handleSelectRiskDialogClose = (reloadFlag) => {
    this.setState(
      {
        showSelectRiskDialog: false,
      },
      () => {
        if (reloadFlag === true) {
          this.getAssignmentDetails();
        }
      }
    );
  };

  handleSelectRiskDialogShow = () => {
    this.setState({
      showSelectRiskDialog: true,
    });
  };

  handleShowCreateDraftDialog = () => {
    this.setState({
      operationType: "Create Draft 1",
      showAlertWithRadioDialog: true,
      alertWithRadioDialogMessage:
        "Report has been finalised and no further changes are required to be done?",
    });
  };

  showSecondDraftAlert = () => {
    this.setState({
      operationType: "Create Draft 2",
      alertWithRadioDialogMessage: "Create Draft?",
    });
  };

  handleAlertWithDialogDialogClose = () => {
    this.setState({
      showAlertWithRadioDialog: false,
      alertWithRadioDialogMessage: "",
      operationType: "",
    });
  };

  handleAddEditDialogClose = (reloadFlag) => {
    let id = "";
    if (this.state.createNodeType === "child")
      id = this.state.relativeFunctionId;
    this.setState(
      {
        showCreateDialog: false,
        clearAndResetFunctionTree: false,
        isEdit: false,
        createNodeType: "",
        selectedId: "",
        heading: "",
        relativeFunctionId: "",
        idToBeExpandedAfterAddingChild: id,
      },
      () => {
        if (reloadFlag === true) this.getFunctionTree();
      }
    );
  };

  handleAddEditTextDialogClose = (reloadFlag) => {
    let id = "";
    id = this.state.relativeFunctionId;
    this.setState(
      {
        showCreateTextDialog: false,
        clearAndResetFunctionTree: false,
        isEdit: false,
        createNodeType: "",
        selectedId: "",
        heading: "",
        relativeFunctionId: "",
        idToBeExpandedAfterAddingChild: id,
      },
      () => {
        if (reloadFlag === true) this.getFunctionTree();
      }
    );
  };

  onDropDownItemClick = (item, headingObj) => {
    if (item.label === "Add Child") {
      this.setState({
        showCreateDialog: true,
        createNodeType: "child",
        relativeFunctionId: headingObj.nodeId,

        isEdit: false,
      });
    } else if (item.label === "Add Sibling") {
      this.setState({
        showCreateDialog: true,
        createNodeType: "sibling",
        relativeFunctionId: headingObj.parentId,

        isEdit: false,
      });
    } else if (item.label === "Delete") {
      this.setState({
        operationType: item.label,
        deleteReactivateId: headingObj.nodeId,
        showAlertWithRadioDialog: true,
        alertWithRadioDialogMessage:
          "Are you sure you want to delete " + headingObj.heading + "?",
      });
    } else if (item.label === "Edit") {
      this.setState({
        showCreateDialog: true,
        createNodeType: "sibling",
        relativeFunctionId: headingObj.nodeId,

        isEdit: true,
        selectedId: headingObj.nodeId,
        heading: headingObj.heading,
      });
    } else if (item.label === "Add Text") {
      this.setState({
        showCreateTextDialog: true,
        relativeFunctionId: headingObj.nodeId,
        isEdit: false,
      });
    } else if (item.label === "Edit Text") {
      this.setState({
        showCreateTextDialog: true,
        relativeFunctionId: headingObj.nodeId,

        isEdit: true,
        selectedId: headingObj.nodeId,
        heading: headingObj.observationHeading,
      });
    } else if (item.label === "Observation Details") {
      this.setState({
        ...resetLayoutFlags,
        showObservationDetails: true,
        selectedId: headingObj.nodeId,
      });
    } else if (item.label === "Do not Report") {
      this.setState({
        operationType: item.label,
        deleteReactivateId: headingObj.nodeId,
        showAlertWithRadioDialog: true,
        alertWithRadioDialogMessage:
          "Are you sure you do not want to report the observation- " +
          `${
            !isEmptyVariable(removeHtmlTags(headingObj.observationHeading))
              ? `${removeHtmlTags(headingObj.observationHeading)}`
              : `${removeHtmlTags(headingObj.observationDetail)}`
          }` +
          "?",
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleAlertDialogCloseInfo = () => {
    this.setState({
      showAlertDialogInfo: false,
      alertDialogMessageInfo: "",
    });
  };

  getRootLevelFunctions = (functionMapTemp) => {
    let rootFunctionArr = [];
    Object.values(functionMapTemp).map((item) => {
      if (item.level === 0) {
        rootFunctionArr.push(item);
      }
    });
    return rootFunctionArr;
  };

  //No need of return value since the array itself is passed and updated.
  //Javascript maintains single array with pointers. it will not deep copy the array
  addParentIdandHasChildrenFlag = (
    tempArr,
    nodeId,
    level,
    parentHeadingId,
    prefix
  ) => {
    tempArr[nodeId].level = level;
    tempArr[nodeId].parentHeadingId = parentHeadingId;

    if (prefix !== "0") {
      tempArr[nodeId].reportHeadingText =
        prefix + ".\xa0\xa0" + tempArr[nodeId].reportHeadingText;
    }

    // add parent Id to all the observations
    let ObsList = tempArr[nodeId].obsList.map((item, index) => {
      item.parentId = parentHeadingId;
      if (prefix === "0") {
        item.reportHeadingText =
          index + 1 + ".\xa0\xa0\xa0\xa0" + item.reportHeadingText;
      } else {
        item.reportHeadingText =
          prefix +
          "." +
          (index + 1) +
          ".\xa0\xa0\xa0\xa0" +
          item.reportHeadingText;
      }

      item.level = level + 1;
      return item;
    });
    tempArr[nodeId].obsList = ObsList;

    if (!isEmptyArray(tempArr[nodeId].children)) {
      tempArr[nodeId].children.map((childNode, index) => {
        let newPrefix = prefix === "0" ? index + 1 : prefix + "." + (index + 1);
        this.addParentIdandHasChildrenFlag(
          tempArr,
          childNode.nodeId,
          level + 1,
          nodeId,
          newPrefix
        );
      });
    } else {
      tempArr[nodeId].hasChildren = false;
    }
  };

  createSyncHRFusionData = (parentId, nodeId, headingMap, isExpanded) => {
    //Now create an object
    let headingObj = headingMap[nodeId];

    headingObj.newChildren = [...headingObj.obsList];
    headingObj.isExpanded = isExpanded;
    headingObj.fontbold = true;
    if (parentId !== nodeId) {
      headingObj.parentId = parentId;
    }
    //now add children recursively
    headingObj.children.map((item) => {
      headingObj.newChildren.push(
        this.createSyncHRFusionData(nodeId, item.nodeId, headingMap, true)
      );
    });
    return headingObj;
  };

  updateSyncHRFusionData = (parentId, nodeId, headingMap) => {
    //Now create an object
    let headingObj = headingMap[nodeId];
    headingObj.newChildren = [...headingObj.obsList];
    headingObj.fontbold = true;
    //Check the old function map
    if (!isEmptyVariable(this.state.headingMap[nodeId])) {
      headingObj.isExpanded = this.state.headingMap[nodeId].isExpanded;
      headingMap[nodeId].isExpanded = this.state.headingMap[nodeId].isExpanded;
    }

    if (parentId !== nodeId) {
      headingObj.parentId = parentId;
    }
    //now add children recursively
    headingObj.children.map((item) => {
      headingObj.newChildren.push(
        this.updateSyncHRFusionData(nodeId, item.nodeId, headingMap)
      );
    });
    return headingObj;
  };

  expandAll = () => {
    this.reference.expandAll();
  };

  collapseAll = () => {
    this.reference.collapseAll();
  };

  nodeExpanded = (args) => {
    this.state.headingMap[args.nodeData.id].isExpanded = true;
  };

  nodeCollapsed = (args) => {
    this.state.headingMap[args.nodeData.id].isExpanded = false;
  };

  getNodeType = (text) => {
    let type = "heading";

    let textArr = text.split("$_$");
    if (!isEmptyVariable(textArr[1])) {
      type = textArr[1];
    }
    return type;
  };

  dragStop = (args) => {
    console.log("Drag STOP Node: " + JSON.stringify(args.draggedNodeData));
    console.log("Drop STOP Node: " + JSON.stringify(args.droppedNodeData));
    console.log("Drop STOP Level: " + args.dropLevel);

    if (isEmptyVariable(args.droppedNodeData)) {
      console.log("Drop node is empty. Operation not allowed");
      args.cancel = true;
      return;
    }

    if (isEmptyVariable(args.dropLevel)) {
      console.log("Drop level is empty. Operation not allowed");
      args.cancel = true;
      return;
    }

    let draggedType = this.getNodeType(args.draggedNodeData?.text);

    let droppedType = this.getNodeType(args.droppedNodeData?.text);
    let droppedParentId = args.droppedNodeData.parentID;
    let droppedNodeId = args.droppedNodeData.id;

    let dropLevel = args.dropLevel;

    //Observation cannot have child
    if (droppedType === "observation") {
      let droppedNodeLevel = this.state.headingMap[droppedParentId].level + 1;

      if (dropLevel > droppedNodeLevel) {
        console.log("Child node of observation. Operation not allowed");
        args.cancel = true;
        return;
      }
    }

    //if the node is dropped above the parent node then cancel the drag
    if (args.dropLevel === 1) {
      console.log("Multiple root level headings. Operation not allowed");
      args.cancel = true;
      return;
    }

    if (draggedType !== droppedType) {
      //TODOSUHAS
      //we need to check if dropped type is not equal to dragged type,
      //and dropped type is observation, and if it is not last observation then disable.
      //same goes for heading

      //Check if observation is moved inside the heading
      if (droppedType === "heading") {
        let droppedNodeLevel = this.state.headingMap[droppedNodeId].level;
        if (dropLevel > droppedNodeLevel) {
          console.log("Child node of heading. Operation is allowed");
          return;
        }
      }
      args.cancel = true;
    }
  };
  nodeDrop = (args) => {
    //when it comes to this both function drag type and drop type will be same.

    console.log("Drag Node: " + JSON.stringify(args.draggedNodeData));
    console.log("Drop Node: " + JSON.stringify(args.droppedNodeData));
    console.log("Drop Index: " + args.dropIndex);
    console.log("Drop Level: " + args.dropLevel);

    let draggedNodeId = "";
    let draggedNodePid = "";
    let droppedNodeType = "";

    let droppedNodeid = "";
    let droppedNodePid = "";
    let draggedNodeType = "";

    let droppedPos = "";
    let dropLevel = "";

    if (
      !isEmptyVariable(args.draggedNodeData) &&
      !isEmptyVariable(args.droppedNodeData)
    ) {
      draggedNodeId = args.draggedNodeData.id;
      draggedNodePid = args.draggedNodeData.parentID;
      draggedNodeType = this.getNodeType(args.draggedNodeData.text);

      droppedNodeid = args.droppedNodeData.id;
      droppedNodePid = args.droppedNodeData.parentID;
      droppedNodeType = this.getNodeType(args.droppedNodeData.text);

      droppedPos = args.dropIndex + 1;
      dropLevel = args.dropLevel;

      if (draggedNodeType !== droppedNodeType) {
        //then it is child node of heading.
        //All other operations are stopped in dragStop method

        //call move observations
        this.moveObservation(draggedNodeId, droppedNodeid, droppedPos);
      } else {
        // based on the type call either move heading or move observation function

        if (draggedNodeType === "heading") {
          //headings are displayed at last always, top nodes will be observations
          //so we need to deduct the observation count in dropped pos
          let parentHeadingObsCount =
            this.state.headingMap[droppedNodePid].obsList.length;
          let newDroppedPos = droppedPos - parentHeadingObsCount;

          let dropRelativeNodeLevel =
            this.state.headingMap[droppedNodeid].level;
          //dropped level is greater than relative node level,
          //then it is a child of that relative node
          if (dropLevel > dropRelativeNodeLevel) {
            droppedNodePid = droppedNodeid;
            newDroppedPos = droppedPos;
          }

          this.moveHeading(draggedNodeId, droppedNodePid, newDroppedPos);
        } else if (draggedNodeType === "observation") {
          //if the dragged and dropped nodes are of the type observation
          //then we need the heading Id, hence we are passing observation
          //parent Id which is heading
          this.moveObservation(draggedNodeId, droppedNodePid, droppedPos);
        } else {
          this.getFunctionTree();
        }
      }

      // now check what is the level of drop id
      // let dropRelativeNodeLevel = this.state.headingMap[droppedNodeid].level;
      // console.log("Drop R Level: "+dropRelativeNodeLevel)
      // //dropped level is greater than relative node level,
      // //then it is a child of that relative node
      // //hence parent id is relative node id
      // if(dropLevel > dropRelativeNodeLevel){
      //     droppedNodePid = droppedNodeid;
      // }

      // this.moveTask(draggedNodeId,draggedNodePid,droppedNodePid,droppedPos);
    }
  };

  downloadReportDropdownClick = (item) => {
    this.setState(
      {
        downloadReportPlaceholder: item.label,
      },
      () => {
        this.handleDownloadReport(item.format);
      }
    );
  };

  generateBlobFromUrl = () => {
    if (isEmptyVariable(this.state.companyDownloadReport.companyLogo)) {
      return null;
    }
    return fetch(
      Constants.ImageBaseUrl + this.state.companyDownloadReport.companyLogo
    )
      .then((resp) => resp.blob())
      .then((data) => data);
  };

  downloadFormatOneReportContent = async (
    observationTemplateFieldsAll,
    businessRiskMap
  ) => {
    //first lets initialise the styles
    let paraStyles = getParagraphStyles();

    let imgblob = this.generateBlobFromUrl();

    let firstPage = getFirstPageContent(
      this.state.companyDownloadReport?.clientName,
      this.state.companyDownloadReport?.unitNameCityList,
      this.state.companyDownloadReport?.unitAddressList,
      this.state.companyDownloadReport?.companyName,
      this.state.companyDownloadReport?.address1,
      this.state.companyDownloadReport?.address2,
      this.state.companyDownloadReport?.city,
      this.state.companyDownloadReport?.state,
      this.state.companyDownloadReport?.pinCode,
      imgblob
    );

    let headersContent = getHeaderContent(
      this.state.companyDownloadReport?.clientName,
      this.state.companyDownloadReport?.assignmentTypeName
    );
    let footersContent = getFooterContent(
      this.state.companyDownloadReport?.companyName
    );
    let assignementTableHeadingContent = getAssignementTableHeadingContent();

    let tableContent = getTableContent(
      this.state.companyDownloadReport?.assignmentTypeName,
      this.state.companyDownloadReport?.unitList,
      this.state.companyDownloadReport?.unitAddressList,
      this.state.companyDownloadReport?.assignmentArea,
      this.state.companyDownloadReport?.processList,
      this.state.companyDownloadReport?.functionList,
      this.state.companyDownloadReport?.checkList,
      this.state.companyDownloadReport?.memberList,
      this.state.companyDownloadReport?.L1UserName,
      this.state.companyDownloadReport?.startDate,
      this.state.companyDownloadReport?.endDate,
      this.state.companyDownloadReport?.reportDate,
      this.state.companyDownloadReport?.periodFromDate,
      this.state.companyDownloadReport?.periodToDate,
      this.state.companyDownloadReport?.reportRiskLevel
    );
    let executiveSummaryContent = getExecutiveSummaryContent(
      this.newObservationsArr
    );
    let ValueAdditiontemp = [];
    this.newObservationsArr.map((val) => {
      if (val.valueAdditions.length > 0) {
        ValueAdditiontemp.push(val.valueAdditions);
      }
    });
    let ValueAdditionContent = getValueAdditionContent(this.newObservationsArr);

    let observationsContent = getObservationContentWithTemplates(
      this.newObservationsArr,
      observationTemplateFieldsAll,
      businessRiskMap
    );

    let sectionTemp = [];
    sectionTemp.push({
      properties: {
        page: {
          borders: {
            pageBorderBottom: {
              style: BorderStyle.SINGLE,
              size: 3,
              color: "auto",
              space: 1,
            },
            pageBorderLeft: {
              style: BorderStyle.SINGLE,
              size: 3,
              color: "auto",
              space: 1,
            },
            pageBorderRight: {
              style: BorderStyle.SINGLE,
              size: 3,
              color: "auto",
              space: 1,
            },
            pageBorderTop: {
              style: BorderStyle.SINGLE,
              size: 3,
              color: "auto",
              space: 1,
            },
          },
        },
      },
      children: firstPage,
    });
    sectionTemp.push({
      headers: headersContent,
      footers: footersContent,
      children: [assignementTableHeadingContent, tableContent],
    });
    sectionTemp.push({
      children: executiveSummaryContent,
    });
    if (ValueAdditiontemp.length > 0) {
      sectionTemp.push({
        children: ValueAdditionContent,
      });
    }
    sectionTemp.push({
      children: observationsContent,
    });

    const doc = new Document({
      styles: {
        paragraphStyles: paraStyles,
      },
      sections: sectionTemp,
    });

    const tempAnnexturelist = this.getAllAnnexturesList();

    Packer.toBlob(doc).then((blob) => {
      var fZip = new jszip();
      Promise.all(
        tempAnnexturelist.map((item) => {
          return fetch(Constants.API_SERVER_PATH + item.path, {
            method: "GET",
          })
            .then((response) => response.blob())
            .then((blob) => {
              fZip.file(item.name, blob);
            });
        })
      ).then(() => {
        fZip.file("report.doc", blob);
        fZip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, "report.zip");
        });
      });
    });
  };

  getAllAnnexturesList = () => {
    let tempAnnexturelist = [];
    this.newObservationsArr.map((item) => {
      if (item.annexures.length > 0) {
        Object.values(item.annexures).map((annex) => {
          tempAnnexturelist.push({
            name: JSON.stringify(annex.documentPath)
              .split("_-_")[1]
              .replace('"', ""),
            path: annex.documentPath,
          });
        });
      }
    });

    return tempAnnexturelist;
  };

  downloadFormatTwoReportContent = (
    observationTemplateFieldsAll,
    businessRiskMap
  ) => {
    //first lets initialise the styles
    let paraStyles = getParagraphStyles();

    let imgblob = this.generateBlobFromUrl();

    let firstPage = getFirstPageContent(
      // this.state.companyDownloadReport?.clientName,
      this.state.companyDownloadReport?.unitNameCityList,
      this.state.companyDownloadReport?.unitAddressList,
      this.state.companyDownloadReport?.companyName,
      this.state.companyDownloadReport?.address1,
      this.state.companyDownloadReport?.address2,
      this.state.companyDownloadReport?.city,
      this.state.companyDownloadReport?.state,
      this.state.companyDownloadReport?.pinCode,
      imgblob
    );

    let headersContent = getHeaderContent(
      this.state.companyDownloadReport?.clientName,
      this.state.companyDownloadReport?.assignmentTypeName
    );

    let footersContent = getFooterContent(
      this.state.companyDownloadReport?.companyName
    );

    let assignementTableHeadingContent = getAssignementTableHeadingContent();

    let tableContent = getTableContent(
      this.state.companyDownloadReport?.assignmentTypeName,
      this.state.companyDownloadReport?.unitList,
      this.state.companyDownloadReport?.unitAddressList,
      this.state.companyDownloadReport?.assignmentArea,
      this.state.companyDownloadReport?.processList,
      this.state.companyDownloadReport?.functionList,
      this.state.companyDownloadReport?.checkList,
      this.state.companyDownloadReport?.memberList,
      this.state.companyDownloadReport?.L1UserName,
      this.state.companyDownloadReport?.startDate,
      this.state.companyDownloadReport?.endDate,
      this.state.companyDownloadReport?.reportDate,
      this.state.companyDownloadReport?.periodFromDate,
      this.state.companyDownloadReport?.periodToDate,
      this.state.companyDownloadReport?.reportRiskLevel
    );

    let executiveSummaryContent = getExecutiveSummaryContent(
      this.newObservationsArr
    );

    let ValueAdditionContent = getValueAdditionContent(this.newObservationsArr);

    let observationsContent = getSecondFormatObservationsContentWithTemplates(
      this.newObservationsArr,
      observationTemplateFieldsAll,
      businessRiskMap
    );

    const doc = new Document({
      styles: {
        paragraphStyles: paraStyles,
      },

      sections: [
        {
          headers: headersContent,
          footers: footersContent,
          children: firstPage,
        },
        {
          children: [assignementTableHeadingContent, tableContent],
        },
        {
          children: executiveSummaryContent,
        },
        {
          children: ValueAdditionContent,
        },
        {
          properties: {
            page: {
              size: {
                orientation: PageOrientation.LANDSCAPE,
              },
            },
          },
          children: observationsContent,
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "report.docx");
    });
  };

  handleshowDonotReportObservationss = () => {
    this.setState({
      ...resetLayoutFlags,
      showDonotReportObservations: true,
    });
  };

  hideDonotReportObservations = (reloadFlag) => {
    this.setState(
      {
        ...resetLayoutFlags,
        showObservationTree: true,
      },
      () => {
        if (reloadFlag === true) {
          this.getFunctionTree();
        }
      }
    );
  };

  showAssignmentTaskListLayout = () => {
    this.setState({
      ...resetLayoutFlags,
      showTaskList: true,
    });
  };

  hideAssignmentTaskListLayout = () => {
    this.setState({
      ...resetLayoutFlags,
      showObservationTree: true,
    });
  };

  hideObservationDetails = () => {
    this.setState({
      ...resetLayoutFlags,
      showObservationTree: true,
      selectedId: "",
    });
  };

  /************************API CALLS **************************/
  HeadingTree = (headingObj) => {
    return (
      <div
        className={
          headingObj.fontbold
            ? "dialog-tree-structure-sync"
            : "dialog-tree-structure-sync border-bottom"
        }
      >
        {!isEmptyVariable(headingObj) && (
          <div className="tree-node-sync">
            {/* {
                        (isEmptyVariable(this.state.allowEdit) ||
                        this.state.allowEdit === "N") &&
                        <div className="tree-text-layout">
                            <p className={headingObj.fontbold?"font-bold":"m-t-0"}>
                                {removeHtmlTags(headingObj.reportHeadingText.split("$_$")[0])}
                            </p>
                        </div>
                    } */}
            {
              // this.state.allowEdit === "Y" &&
              <div className="tree-dropdown-layout-sync">
                <TreeNodeDropdown
                  // placeholder={headingObj.reportHeadingText.split("$_$")[0]}
                  placeholder={headingObj.reportHeadingText}
                  dropdownArr={
                    headingObj.level === 1
                      ? this.state.actionArrRoot
                      : headingObj.type === "heading"
                      ? this.state.actionArr
                      : headingObj.observationType === "comment"
                      ? this.state.actionArrFreeText
                      : this.state.actionArrObs
                  }
                  labelParam={"label"}
                  onDropDownItemClick={this.onDropDownItemClick}
                  dropdownId={headingObj}
                  fontbold={headingObj.fontbold}
                  paraMT={"1.5px"}
                  themeSettings={themeSettings}
                />
              </div>
            }
          </div>
        )}
      </div>
    );
  };

  deleteActivateFunctionNode = () => {
    let url = "";
    let postParams = {};

    if (this.state.operationType === "Delete") {
      url = Constants.DeleteReportHeading;
      postParams = {
        email: userDetails.email,
        accessToken: userDetails.accessToken,
        reportHeadingId: this.state.deleteReactivateId,
      };
    } else if (this.state.operationType === "Create Draft 2") {
      url = Constants.CreateDraft;
      postParams = {
        email: userDetails.email,
        accessToken: userDetails.accessToken,
        assignmentId: this.state.assignmentId,
      };
    } else if (this.state.operationType === "Do not Report") {
      url = Constants.DoNotReport;
      postParams = {
        email: userDetails.email,
        accessToken: userDetails.accessToken,
        observationId: this.state.deleteReactivateId,
      };
    }
    if (!this.state.isCreatingDraft) {
      this.setState({
        showLoader: true,
        isCreatingDraft:
          this.state.operationType === "Create Draft 2" ? true : false,
      });

      fetch(url, {
        method: "POST",
        mode: "cors",
        body: new URLSearchParams(postParams),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (
            data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
            data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
          ) {
            localStorage.clear();
            window.location = "/";
          } else if (data.responseCode === Constants.CODE_SUCCESS) {
            this.setState(
              {
                deleteReactivateId: "",
                operationType: "",
                alertWithRadioDialogMessage: "",
                showAlertWithRadioDialog: false,
                clearAndResetFunctionTree: false,
                isCreatingDraft: false,
              },
              () => {
                this.getFunctionTree();
              }
            );
          } else {
            this.setState({
              showLoader: false,
              deleteReactivateId: "",
              operationType: "",
              alertWithRadioDialogMessage: "",
              showAlertWithRadioDialog: false,
              clearAndResetFunctionTree: false,
              isCreatingDraft: false,

              showAlertDialogInfo: true,
              alertDialogMessageInfo: data.responseMessage,
            });
          }
        });
    }
  };

  getFunctionTree = () => {
    this.setState({
      showLoader: true,
    });

    Promise.all([
      fetch(Constants.GetReportHeadings, {
        method: "POST",
        mode: "cors",
        body: new URLSearchParams({
          email: userDetails.email,
          accessToken: userDetails.accessToken,
          assignmentId: this.state.assignmentId,
          donotReport: "N",
        }),
      }),
      fetch(Constants.Getongoingreportdetails, {
        method: "POST",
        mode: "cors",
        body: new URLSearchParams({
          email: userDetails.email,
          accessToken: userDetails.accessToken,
          assignmentId: this.state.assignmentId,
        }),
      }),
      fetch(Constants.GetUserMenu, {
        method: "POST",
        mode: "cors",
        body: new URLSearchParams({
          email: userDetails.email,
          accessToken: userDetails.accessToken,
        }),
      }),
    ])
      .then(([response, assignmentRes, menuRes]) => {
        return Promise.all([
          response.json(),
          assignmentRes.json(),
          menuRes.json(),
        ]);
      })
      .then(([data, assignmentRes, menuRes]) => {
        let menusTemp = {};
        let companyTemp = {};
        let assignmentObj = {};
        if (
          menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
          menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
        ) {
          localStorage.clear();
          window.location = "/";
        } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
          menusTemp = menuRes.data.menus;
          companyTemp = menuRes.data.company;
        } else {
        }
        let menusTemp2 = GetOrderedMasterTabMenuReports(menusTemp);

        if (
          assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
          assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
        ) {
          localStorage.clear();
          window.location = "/";
        } else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
          if (!isEmptyArray(assignmentRes.data.result)) {
            assignmentObj = assignmentRes.data.result[0];
          }
        } else {
        }

        if (
          data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
          data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
        ) {
          localStorage.clear();
          window.location = "/";
        } else if (data.responseCode === Constants.CODE_SUCCESS) {
          let temp = data.result.headingMap;
          let rootFunctionArr = this.getRootLevelFunctions(temp);
          let rootNodeId = "";

          rootFunctionArr.map((rootNode) => {
            rootNodeId = rootNode.nodeId;
            this.addParentIdandHasChildrenFlag(
              temp,
              rootNodeId,
              1,
              rootNodeId,
              "0"
            );
          });

          let syncFusionData = [];
          //clearAndResetFunctionTree - if it is false, then copy the isShowingChildren param
          //from old state array, this is required because whenever the user adds a new node at the nth level
          //all the nodes are collapsed and shows only first level nodes since the API is called again
          if (
            !isEmptyArray(Object.keys(this.state.headingMap)) &&
            !this.state.clearAndResetFunctionTree
          ) {
            let headingObj = this.updateSyncHRFusionData(
              rootNodeId,
              rootNodeId,
              temp
            );
            headingObj.fontbold = true;
            syncFusionData.push(headingObj);
          } else {
            //create datastructure and Expand root level node's children by default
            let headingObj = this.createSyncHRFusionData(
              rootNodeId,
              rootNodeId,
              temp,
              true
            );
            temp[rootNodeId].isExpanded = true;
            headingObj.fontbold = true;
            syncFusionData.push(headingObj);
          }
          //Action item arrays based on allowEdit flag
          let actionArrTemp = [];
          let actionArrRootTemp = [];
          let actionArrFreeTextTemp = [];
          let actionArrObsTemp = [ObsDetailsDropdownObj];
          let allowEditFlag =
            !isEmptyVariable(assignmentObj?.allowEdit) &&
            assignmentObj.allowEdit === "Y"
              ? "Y"
              : "N";

          if (allowEditFlag === "Y") {
            actionArrTemp = [
              AddChildDropdownObj,
              AddSiblingDropdownObj,
              AddTextDropdownObj,
              editDropdownObj,
              delDropdownObj,
            ];
            actionArrRootTemp = [AddChildDropdownObj, AddTextDropdownObj];
            actionArrFreeTextTemp = [editTextDropdownObj];
            actionArrObsTemp = [ObsDetailsDropdownObj, donotReportDropdownObj];
            // actionArrObsTemp = [ObsDetailsDropdownObj]
          }

          //set the tree fields
          this.fields = {
            dataSource: syncFusionData,
            id: "nodeId",
            text: "reportHeadingText",
            parentID: "parentId",
            child: "newChildren",
            expanded: "isExpanded",
          };

          // console.log(JSON.stringify(syncFusionData));
          // console.log(JSON.stringify(temp));

          //check if observation template id is there or not
          let showOnlyTasks = false;
          if (isEmptyVariable(assignmentObj.observationTemplateId)) {
            showOnlyTasks = true;
          }

          this.setState(
            {
              headingMap: temp,
              actionArr: actionArrTemp,
              actionArrRoot: actionArrRootTemp,
              actionArrFreeText: actionArrFreeTextTemp,
              actionArrObs: actionArrObsTemp,
              idToBeExpandedAfterAddingChild: "",
              syncFusionData: syncFusionData,
              rootNodeId: rootNodeId,
              showLoader: false,
              clearAndResetFunctionTree: false,

              showTaskList: showOnlyTasks,
              showObservationTree: !showOnlyTasks,
              showOnlyTasks: showOnlyTasks,

              menus: menusTemp2,
              company: companyTemp,
              assignmentObj: assignmentObj,
              allowEdit: allowEditFlag,
              userMenus: menusTemp,
            },
            () => {
              if (!showOnlyTasks) this.reference.refresh();
            }
          );
        } else {
          this.setState({
            headingMap: {},
            showLoader: false,
            syncFusionData: [],
            clearAndResetFunctionTree: false,
          });
        }
      });
  };

  getAssignmentDetails = () => {
    fetch(Constants.Getongoingreportdetails, {
      method: "POST",
      mode: "cors",
      body: new URLSearchParams({
        email: userDetails.email,
        accessToken: userDetails.accessToken,
        assignmentId: this.state.assignmentId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (
          data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
          data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
        ) {
          localStorage.clear();
          window.location = "/";
        } else {
          let assignmentObj = data.data.result[0];

          let actionArrTemp = [];
          let actionArrRootTemp = [];
          let actionArrFreeTextTemp = [];
          let allowEditFlag =
            !isEmptyVariable(assignmentObj?.allowEdit) &&
            assignmentObj.allowEdit === "Y"
              ? "Y"
              : "N";

          if (allowEditFlag === "Y") {
            actionArrTemp = [
              AddChildDropdownObj,
              AddSiblingDropdownObj,
              AddTextDropdownObj,
              editDropdownObj,
              delDropdownObj,
            ];
            actionArrRootTemp = [AddChildDropdownObj, AddTextDropdownObj];
            actionArrFreeTextTemp = [editTextDropdownObj];
          }
          this.setState({
            assignmentObj: assignmentObj,
            allowEdit: allowEditFlag,
            actionArr: actionArrTemp,
            actionArrRoot: actionArrRootTemp,
            actionArrFreeText: actionArrFreeTextTemp,
          });
        }
      });
  };

  moveObservation = (draggedNodeId, droppedNodePid, droppedPos) => {
    fetch(Constants.MoveObservation, {
      method: "POST",
      mode: "cors",
      body: new URLSearchParams({
        email: userDetails.email,
        accessToken: userDetails.accessToken,
        rootHeadingId: this.state.rootHeadingId,
        observationId: draggedNodeId,

        toHeadingId: droppedNodePid,
        toChildOrder: droppedPos,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (
          data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
          data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
        ) {
          localStorage.clear();
          window.location = "/";
        } else {
          this.getFunctionTree();
        }
      });
  };

  moveHeading = (draggedNodeId, droppedNodePid, droppedPos) => {
    fetch(Constants.MoveReportHeading, {
      method: "POST",
      mode: "cors",
      body: new URLSearchParams({
        email: userDetails.email,
        accessToken: userDetails.accessToken,
        rootHeadingId: this.state.rootHeadingId,
        reportHeadingId: draggedNodeId,

        toHeadingId: droppedNodePid,
        toChildOrder: droppedPos,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (
          data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
          data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
        ) {
          localStorage.clear();
          window.location = "/";
        } else {
          this.getFunctionTree();
        }
      });
  };

  parseObservations = (nodeId, prefix, headingListPrev) => {
    //11,0 //14,1 ,15,2 //13,3, // 140,1.1 , 141,1.2
    let headingNode = this.newObservationsMap[nodeId];

    let isHeadingUsed = false;
    this.headingList = [...headingListPrev];

    if (prefix + "" !== "0") {
      this.headingList.push(prefix + ". " + headingNode.heading);
    }

    //Add Index to each observation
    let newObsList = [];
    headingNode.obsList.forEach((item, idx) => {
      if (prefix + "" === "0") {
        //root level
        item.observationHeading = idx + 1 + ". " + item.observationHeading;
      } else {
        //heading is required only for first observation
        if (idx === 0) {
          isHeadingUsed = true;
          item.reportCustomHeading = this.headingList.join(":--:");
        }
        item.observationHeading =
          prefix + "." + (idx + 1) + ". " + item.observationHeading;
      }
      newObsList.push(item);
    });
    headingNode.obsList = newObsList;
    this.newObservationsArr = [...this.newObservationsArr, ...newObsList];

    if (isHeadingUsed) {
      this.headingList = [];
    }

    //14.15.13 //14th children - 140,141
    headingNode.children.map((childNode, index) => {
      let newPrefix =
        prefix + "" === "0" ? index + 1 : prefix + "." + (index + 1);
      this.parseObservations(childNode.nodeId, newPrefix, this.headingList);
    });
  };

  handleDownloadReport = (reportFormat) => {
    this.setState({
      showLoader: true,
    });
    let postParam = {
      email: userDetails.email,
      accessToken: userDetails.accessToken,
      assignmentId: this.props.match.params.id,
    };

    fetch(Constants.DownloadReport, {
      method: "POST",
      mode: "cors",
      body: new URLSearchParams(postParam),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (
          data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
          data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
        ) {
          localStorage.clear();
          window.location = "/";
        } else if (data.responseCode === Constants.CODE_SUCCESS) {
          this.newObservationsArr = [];

          this.newObservationsMap = data.data.observations;
          let businessRiskMap = data.data.businessRiskMap;

          let rootHeadings = this.getRootLevelFunctions(
            this.newObservationsMap
          );
          //Template Fields
          let observationTemplateFieldsStr =
            data.data.observationTemplateFields;
          let observationTemplateFieldsLeftStr =
            data.data.observationTemplateFieldsLeft;

          let observationTemplateFields = [];
          if (
            !isEmptyVariable(observationTemplateFieldsStr) &&
            isJsonString(observationTemplateFieldsStr)
          ) {
            observationTemplateFields = JSON.parse(
              observationTemplateFieldsStr
            );
          }
          let observationTemplateFieldsLeft = [];
          if (
            !isEmptyVariable(observationTemplateFieldsLeftStr) &&
            isJsonString(observationTemplateFieldsLeftStr)
          ) {
            observationTemplateFieldsLeft = JSON.parse(
              observationTemplateFieldsLeftStr
            );
          }

          let observationTemplateFieldsAll = [
            ...observationTemplateFields,
            ...observationTemplateFieldsLeft,
          ];

          if (!isEmptyArray(rootHeadings)) {
            this.parseObservations(rootHeadings[0].nodeId, 0, []);
          }
          this.setState(
            {
              showLoader: false,
              companyDownloadReport: data.data.company,
              observationsDownloadReport: this.newObservationsMap,
            },
            () => {
              if (reportFormat === Constants.REPORT_FORMAT_STANDARD) {
                this.downloadFormatOneReportContent(
                  observationTemplateFieldsAll,
                  businessRiskMap
                );
              } else if (reportFormat === Constants.REPORT_FORMAT_TABULAR) {
                this.downloadFormatTwoReportContent(
                  observationTemplateFieldsAll,
                  businessRiskMap
                );
              }
            }
          );
        } else {
          this.setState({
            companyDownloadReport: "",
            observationsDownloadReport: "",
            showLoader: false,
          });
        }
      });
  };


  handleFilterDropdownClick = (selectedItem)=>{

    let tempShowObservationTree = false
    let tempShowTaskList = false
    let tempShowSelectRiskDialog= false

    if(selectedItem.label==="Tasks")
    {
      tempShowTaskList=true
    }
    else if(selectedItem.label==="Working Papers"){
     window.location= Constants.URL_ONGOING_WORKING_PAPERS +
                              "/" +
                              this.state.assignmentId
    }
    else if(selectedItem.label==="Timeline"){
      window.location= Constants.URL_ONGOING_TIMELINE + "/" + this.state.assignmentId
    }
    else if(selectedItem.label==="Sample Checked")
    {
      window.location=Constants.URL_ONGOING_SAMPLE_CHECKED +
      "/" +
      this.state.assignmentId
    }
    else if(selectedItem.label==="Assign Overall Risk")
    {

      tempShowSelectRiskDialog=true
      tempShowTaskList=true
    }
    else if(selectedItem.label==="Overall")
    {
      window.location=Constants.URL_ONGOING_OVERALL +
      "/" +
      this.state.assignmentId
    }
    else if(selectedItem.label==="Auditee")
    {

      window.location=Constants.URL_ONGOING_ASSIGNMENTEMPLOYEE +
      "/" +
      this.state.assignmentId
    }


    this.setState({
      filterPlaceholder:selectedItem.label,
      showTaskList:tempShowTaskList,
      showObservationTree:tempShowObservationTree,
      showSelectRiskDialog: tempShowSelectRiskDialog,
    })


  }

  handleShowOverallAssignment = (event) => {
    event.preventDefault();
    window.location = Constants.URL_ONGOING_OVERALL + "/" + this.state.assignmentId;
  }

  render() {
    //this allowEdit State variable must be set to Y by default or else the tree
    //will not work properly
    let dnd = false;
    if (this.state.allowEdit === "Y") {
      dnd = true;
    }

    return (
      <main className="main-dashboard">
        <section className="dashboard-bg">
          <Sidebar />

          <div className="dashboard-right-bg-collpase">
            <div className="dashboard-wrapper">
              <Topbar />
              <div className="d-flex align-items-center">
                <div className="flex-fill">
                    <MasterMenuTabs
                      assignmentId={this.state.assignmentId}
                      masterMenus={this.state.menus}
                      themeSettings={themeSettings}
                      filterPlaceholder = {this.state.filterPlaceholder}
                      dropdownOnClick = {this.handleFilterDropdownClick}
                      observationTemplatedId ={(this.state.assignmentObj).observationTemplateId}
                      filterDisplayFlag={true}
                      userMenus={this.state.userMenus}
                    />
                </div>
                <div>
                    <TopmenuReports
                        assignmentId={this.state.assignmentId}
                        userDetails ={userDetails}
                        themeSettings={themeSettings}
                        company={this.state.company}
                    />
                </div>
              </div>
              {this.state.showObservationTree && (
                <ExpandCollapseWrapper themeSettings={themeSettings}>
                  <div
                    className="expand-collapse-layout"
                    onClick={this.expandAll}
                    style={{ marginRight: 10 }}
                    type="button"
                  >
                    <span class="material-icons">unfold_more</span>
                    <p>Expand All</p>
                  </div>
                  <div
                    className="expand-collapse-layout"
                    onClick={this.collapseAll}
                    style={{ marginLeft: 10 }}
                    type="button"
                  >
                    <span class="material-icons">unfold_less</span>
                    <p>Collapse All</p>
                  </div>
                </ExpandCollapseWrapper>
              )}
              {this.state.showLoader && (
                <div class="loader"></div>
              )}
              {this.state.showObservationTree && (
                <div className="row custom-row">
                  <div className="col-md-3 custom-col m-t-10">
                    <TaskTagButtonsLayout themeSettings={themeSettings}>
                      <ul>
                        <li>
                          <a
                            href={
                              Constants.URL_ONGOING_REPORTS +
                              "/" +
                              this.state.assignmentId
                            }
                          >
                            Back
                          </a>
                        </li>
                        {this.state.allowEdit === "Y" && (
                          <li>
                            <a
                              href="javascript:void(0)"
                              onClick={this.handleSelectRiskDialogShow}
                            >
                              {isEmptyVariable(
                                this.state.assignmentObj?.reportRiskLevel
                              )
                                ? "Assign Overall Risk"
                                : "Reassign Overall Risk"}
                            </a>
                          </li>
                        )}
                        <li>
                          <a onClick={this.showAssignmentTaskListLayout}>
                            Tasks
                          </a>
                        </li>
                        {
                          this.state.userMenus["Assignment Level Template"] && this.state.userMenus["Assignment Level Template"] !== "NONE" &&
                          !isEmptyVariable(this.state.assignmentObj?.assignmentLevelTemplateId) &&
                          <li>
                            <a href="#" onClick={this.handleShowOverallAssignment}>
                              Overall
                            </a>
                          </li>
                        }
                        <li>
                          <a href={ Constants.URL_ONGOING_ASSIGNMENTEMPLOYEE + "/" + this.state.assignmentId } >
                            Auditee
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              Constants.URL_ONGOING_SAMPLE_CHECKED +
                              "/" +
                              this.state.assignmentId
                            }
                          >
                            Sample Checked
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              Constants.URL_ONGOING_WORKING_PAPERS +
                              "/" +
                              this.state.assignmentId
                            }
                          >
                            Working Papers
                          </a>
                        </li>
                        
                        <li>
                          <a
                            href={
                              Constants.URL_ONGOING_TIMELINE +
                              "/" +
                              this.state.assignmentId
                            }
                          >
                            Timeline
                          </a>
                        </li>
                        <li>
                          <a onClick={this.handleshowDonotReportObservationss}>
                            Do not report Observations
                          </a>
                        </li>
                        <li>
                          <RegularDropdown
                            placeholder={downloadReportPlaceholder}
                            dropdownArr={this.state.downloadReportArr}
                            labelParam="label"
                            onDropDownItemClick={
                              this.downloadReportDropdownClick
                            }
                            defaultPlaceholderDropDown={
                              downloadReportPlaceholder
                            }
                          />
                        </li>
                        {!isEmptyVariable(
                          this.state.assignmentObj?.createDraft
                        ) &&
                          this.state.assignmentObj.createDraft === "Y" && (
                            <li>
                              <a
                                href="javascript:void(0)"
                                onClick={this.handleShowCreateDraftDialog}
                              >
                                Create Draft
                              </a>
                            </li>
                          )}
                      </ul>
                    </TaskTagButtonsLayout>
                  </div>
                  <div className="col-md-9 custom-col">
                    <div
                      style={{ padding: "0px 20px 20px" }}
                      className="card-col tree-node-bottom-border"
                    >
                      <TreeViewComponent
                        fields={this.fields}
                        allowDragAndDrop={dnd}
                        nodeTemplate={this.HeadingTree}
                        ref={(treeNode) => {
                          this.reference = treeNode;
                        }}
                        nodeDragStop={this.dragStop}
                        nodeDropped={this.nodeDrop}
                        nodeExpanded={this.nodeExpanded}
                        nodeCollapsed={this.nodeCollapsed}
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.state.showDonotReportObservations && (
                <DonotReportObservationsLayout
                  assignmentId={this.state.assignmentId}
                  hideDonotReportObservationsLayout={
                    this.hideDonotReportObservations
                  }
                  allowEdit={this.state.allowEdit}
                  editUrl={
                    Constants.URL_EDIT_ONGOING_OBSERVATIONS +
                    "/" +
                    this.state.selectedId +
                    "-" +
                    this.state.assignmentId
                  }
                  isReportCompleted={false}
                />
              )}
              {this.state.showObservationDetails && (
                <ObservationDetailsLayout
                  observationId={this.state.selectedId}
                  assignmentId={this.state.assignmentId}
                  handleHideObsDetailsLayout={this.hideObservationDetails}
                  allowEdit={this.state.allowEdit === "Y"}
                  editUrl={
                    Constants.URL_EDIT_ONGOING_OBSERVATIONS +
                    "/" +
                    this.state.selectedId +
                    "-" +
                    this.state.assignmentId
                  }
                />
              )}
              {this.state.showTaskList && (
                <AssignmentTaskLayout
                  // observationId = {this.state.selectedId}
                  allowEdit={this.state.allowEdit}
                  assignmentId={this.state.assignmentId}
                  hideAssignmentTaskListLayout={
                    this.hideAssignmentTaskListLayout
                  } 
                  themeSettings={themeSettings}
                  isDraftOnward={false}
                  showOnlyTasks={this.state.showOnlyTasks}
                  location={this.props.location}
                />
              )}
            </div>
          </div>
        </section>

        <AddHeadingDialog
          isEdit={this.state.isEdit}
          showCreateDialog={this.state.showCreateDialog}
          handleAddEditDialogClose={this.handleAddEditDialogClose}
          createNodeType={this.state.createNodeType}
          parentHeadingId={this.state.relativeFunctionId}
          assignmentId={this.state.assignmentId}
          selectedId={this.state.selectedId}
          heading={this.state.heading}
          themeSettings={themeSettings}
        />

        <AddTextDialog
          isEdit={this.state.isEdit}
          showCreateTextDialog={this.state.showCreateTextDialog}
          handleAddEditTextDialogClose={this.handleAddEditTextDialogClose}
          reportHeadingId={this.state.relativeFunctionId}
          assignmentId={this.state.assignmentId}
          selectedId={this.state.selectedId}
          heading={this.state.heading}
          themeSettings={themeSettings}
        />

        <AlertDialog
          showAlertDialog={this.state.showAlertWithRadioDialog}
          handleAlertDialogClose={this.handleAlertWithDialogDialogClose}
          type={
            this.state.operationType === "Delete" ||
            this.state.operationType === "Do not Report"
              ? Constants.ALERT_TYPE_WARNING
              : Constants.ALERT_TYPE_INFO
          }
          alertDialogHeading={
            this.state.operationType === "Delete"
              ? "Delete"
              : this.state.operationType === "Do not Report"
              ? "Do not Report"
              : "Create Draft"
          }
          alertDialogMessage={this.state.alertWithRadioDialogMessage}
          proceedBtnClick={
            this.state.operationType === "Delete" ||
            this.state.operationType === "Do not Report"
              ? this.deleteActivateFunctionNode
              : this.state.operationType === "Create Draft 1"
              ? this.showSecondDraftAlert
              : this.deleteActivateFunctionNode
          }
          proceedBtnLabel={
            this.state.operationType === "Delete" ? "Delete" : "Yes"
          }
          themeSettings={themeSettings}
          showLoader={this.state.isCreatingDraft}
        />

        <AlertDialog
          showAlertDialog={this.state.showAlertDialogInfo}
          handleAlertDialogClose={this.handleAlertDialogCloseInfo}
          type={Constants.ALERT_TYPE_ALERT}
          alertDialogMessage={this.state.alertDialogMessageInfo}
          proceedBtnClick={this.handleAlertDialogCloseInfo}
          proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
          themeSettings={themeSettings}
        />

        <SelectRiskDialog
          showRiskDialog={this.state.showSelectRiskDialog}
          handleSelectRiskDialogClose={this.handleSelectRiskDialogClose}
          selectedRiskId={
            isEmptyVariable(this.state.assignmentObj?.reportRiskLevelId)
              ? ""
              : this.state.assignmentObj.reportRiskLevelId
          }
          assignmentId={this.state.assignmentId}
          themeSettings={themeSettings}
        />
      </main>
    );
  }
}

export default HeadingTreeDialog;
