import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {DialogSaveButton,TableRadioButton,TreeNodeCheckBox,AssigmentDetailsIconLayout} from '../../../common/Global/globalStyles.style';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import {getLocalStorageVariables,isEmptyVariable,
    isEmptyArray} from '../../../common/Global/commonFunctions';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import ReactSelectDropdown from '../../../common/DropdownMenus/ReactSelectDropdownWithSearch';
import moment from "moment";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import {Modal} from 'react-bootstrap';
import SelectFunctionDialog from '../Common/selectFunction';
import SelectProcessDialog from '../Common/selectProcess';
import SelectPlannedAssignment from '../Common/selectPlannedAssignment';
import CreatableSelect from "react-select/creatable";
import PreviousRefNoDialog from './previousRefNoDialog';
import { toast } from "react-toastify";

const epicCustomStyles = {
	placeholder: (provided, state) => ({
		...provided,
		fontSize: 13,
        color:"#ccc"
	}),
	option: (provided, state) => ({
		...provided,
		fontSize: 13,
        background:state.isFocused?"#f8f9fa":"transparent",
        ":hover":{
            background:"#f8f9fa"
        }
	}),
	singleValue: (provided, state) => ({
		...provided,
		fontSize: 13,
	}),
	input: (provided, state) => ({
		...provided,
		fontSize: 14,
	}),
	control: (provided, state) => ({
		...provided,
		// border: "1px solid transparent",
		minHeight: 34,
		// ":hover": {
		// 	border: "1px solid red",
		// },
	}),
    menuList: (provided, state) => ({
		...provided,
		maxHeight:300,
        paddingTop:0,
        paddingBottom:0,
	}),
    menu:(provided, state)=>({
        ...provided,
        marginTop:2
    })
};
const functionDropdownDefaultValue = "Select Function";
const processDropdownDefaultValue = "Select Process";
const companyDefaultPlaceholder = "Select Client";
const plannedAssignmentDefaultPlaceholder = "Select Assignment";
const unitDefaultPlaceholder = "Select Units";
const departmentDropdownDefaultValue = "Select Department";
const assignmentTypeDefaultPlaceholder = "Select Assignment Type";
const observationTemplateDefaultPlaceholder = "Select Observation Template";
const execTaskTemplateDefaultPlaceholder = "Select Execution Task Template";
const defaultRiskPlaceholder = "Select Risk";
const defaultVAPlaceholder = "Select Value Addition";
const unplannedReasonDefaultPlaceholder = "Select Reason";
const defaultLikelihoodPlaceholder = "Select Likelihood";
const userDetails  = getLocalStorageVariables();


class CreateAnnualPlan extends Component{
    constructor(props){
        super(props);
        this.state = {
            isUnplannedNewAssignment:false,
            planDate:"",
            selectedCompanyId:"",
            selectedCompanyPlaceholder:companyDefaultPlaceholder,
            
            companyUnits:[],
            selectedUnitObj:[],
            selectedUnitPlaceholder:unitDefaultPlaceholder,
            isUnitsDropdownEnabled:false,
            
            selectedAssignmentTypeId:"",
            assignmentTypeFiltered:[],
            selectedAssignmentTypePlaceholder:assignmentTypeDefaultPlaceholder,
            
            assignmentArea:"",
            selectedFunctionObjArr:[],
            selectedProcessObjArr:[],

            selectedPlannedAssignment:plannedAssignmentDefaultPlaceholder,
            selectedPlannedAssignmentId:"",
            datalist:[],
            disablePlannedAssignment:true,

            selectedDepartmentId:"",
            selectedDepartmentName:departmentDropdownDefaultValue,
            
            startDate:"",
            endDate:"",
            reportDate:"",
            referenceNo:"",

            selectedRiskPlaceholder:defaultRiskPlaceholder,
            selectedRiskId:"",

            selectedValuePlaceholder:defaultVAPlaceholder,
            selectedVAIds:[],

            periodFromDate:"",
            periodToDate:"",
            unplannedReasonPlaceholder:unplannedReasonDefaultPlaceholder,
            unplannedRemarks:"",

            observationTemplatesFiltered:[],
            selectedObservationTemplateId:"",
            selectedObservationTemplatePlaceholder:observationTemplateDefaultPlaceholder,

            execTaskTemplatesFiltered:[],
            selectedExecTaskTemplateId:"",
            selectedExecTaskTemplatePlaceholder:execTaskTemplateDefaultPlaceholder,

            tagsSelectedDD1:[],
            tagsSelectedDD2:[],
            tagsSelectedDD3:[],

            submitClickedAtleastOnce:false,
            formErrors:{},
            showLoader:false,

            selectedScheduleAssignment:false,
            selectedCompanyObj:{},

            showPreviousRefDialog:false,
            hidePlannedAssignment:false,
            departmentSetting:[],
            
            selectedLikelihood: "",
            selectedLikelihoodId: "",

        }
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){
            let selectedDepartmentId = this.props.selectedDepartmentId;
            let selectedDepartmentName = this.props.selectedDepartmentName;

            if(this.props.isEdit){
                let dropdownItem = this.props.selectedItem;
                let companyUnits = [];
                companyUnits = this.props.units.filter(unit => unit.companyId === dropdownItem.companyId);
                let assignmentTypeFiltered = this.props.assignmentType.filter(at => at.departmentId === dropdownItem.departmentId);
                let observationTemplatesFiltered = this.props.observationTemplates.filter(at => at.departmentId === dropdownItem.departmentId);
                let execTaskTemplatesFiltered = this.props.execTaskTemplates.filter(at => at.assignmentTypeId === dropdownItem.assignmentTypeId);
                
                let obsObj = observationTemplatesFiltered.find(item=>item.templateId == dropdownItem.observationTemplateId);
                let flagO = obsObj?.fixedTemplateFields?.includes('"valueAddition":"Y"');
                let execObj = execTaskTemplatesFiltered.find(item=>item.templateId == dropdownItem.executionTemplateId);
                let flagE = execObj?.fixedTemplateFields?.includes('"valueAddition":"Y"');
                
                let tagsSelectedDD1 = [];
                if(!isEmptyVariable(dropdownItem.groupSet1)){
                    let splitArr = dropdownItem.groupSet1.split(":-:");
                    tagsSelectedDD1 = splitArr.map(item=>{
                        let idNameArr = item.split("$-$");
                        let obj = {value:parseInt(idNameArr[0]),label:idNameArr[1]}
                        return obj;
                    });
                }
                let tagsSelectedDD2 = [];
                if(!isEmptyVariable(dropdownItem.groupSet2)){
                    let splitArr = dropdownItem.groupSet2.split(":-:");
                    tagsSelectedDD2 = splitArr.map(item=>{
                        let idNameArr = item.split("$-$");
                        let obj = {value:parseInt(idNameArr[0]),label:idNameArr[1]}
                        return obj;
                    });
                }
                let tagsSelectedDD3 = [];
                if(!isEmptyVariable(dropdownItem.groupSet3)){
                    let splitArr = dropdownItem.groupSet3.split(":-:");
                    tagsSelectedDD3 = splitArr.map(item=>{
                        let idNameArr = item.split("$-$");
                        let obj = {value:parseInt(idNameArr[0]),label:idNameArr[1]}
                        return obj;
                    });
                }

                let selectedAssignmentGroups = {
                    groupSet1:tagsSelectedDD1,
                    groupSet2:tagsSelectedDD2,
                    groupSet3:tagsSelectedDD3,
                }

                //First Lets Check whether it is planned or unplanned
                let isUnplannedAssignment = false;
                let unplannedReason = unplannedReasonDefaultPlaceholder;
                let unplannedRemarks = "";
                if(!isEmptyVariable(dropdownItem.unplannedReason)){
                    // isUnplannedAssignment = true;
                    unplannedReason = dropdownItem.unplannedReason;
                    unplannedRemarks = dropdownItem.unplannedRemark;
                }

                let newCompanyUnits =[]
                Object.values(companyUnits).map((item)=>{
                    let  tempItem = item
                    tempItem["unitNameAndCity"] = item.unitName+", "+item.city
                    newCompanyUnits.push(tempItem)
                })

                let selectedUnitObj = [];
                if(!isEmptyVariable(dropdownItem.unitList)){
                    let splitArr = dropdownItem.unitList.split(";;");
                    if(!isEmptyArray(splitArr)){
                        splitArr.map((item)=>{
                            newCompanyUnits.map((unit)=>{
                                if(unit.unitId==parseInt(item.split(":-:")[1]))
                                {
                                    selectedUnitObj.push(unit)
                                }
                            })
    
                        })
                    }
                }
                
                let selectedProcessObjArr = [];
                if(!isEmptyVariable(dropdownItem.processList)){
                    let splitArr = dropdownItem.processList.split(",");
                    if(!isEmptyArray(splitArr)){
                        splitArr.forEach((item)=>{
                            if(!isEmptyVariable(item.split(":-:")[1])){
                                selectedProcessObjArr.push({
                                    processId:item.split(":-:")[1]
                                })
                            }
                        })
                    }
                }

                let selectedFunctionObjArr = [];
                if(isEmptyVariable(dropdownItem.processList)){
                    let splitArr = dropdownItem.functionList.split(",");
                    if(!isEmptyArray(splitArr)){
                        splitArr.map((item)=>{
                            if(!isEmptyVariable(item.split(":-:")[1])){
                                selectedFunctionObjArr.push({
                                    functionId:item.split(":-:")[1]
                                });
                            }
                        })
                    }
                }

                let selectedVAIds = [];
                if(!isEmptyVariable(dropdownItem.valueAdditionList)){
                    let splitArr = dropdownItem.valueAdditionList.split(",");
                    if(!isEmptyArray(splitArr)){
                        splitArr.map((item)=>{
                            selectedVAIds.push(parseInt(item.split(":-:")[2]));
                        })
                    }
                }

                let planDate =!isEmptyVariable(dropdownItem.planDate)?moment(dropdownItem.planDate).format("YYYY-MM-DD"):"";
                let startDate = !isEmptyVariable(dropdownItem.startDate)?moment(dropdownItem.startDate).format("YYYY-MM-DD"):"";
                let endDate = !isEmptyVariable(dropdownItem.endDate)?moment(dropdownItem.endDate).format("YYYY-MM-DD"):"";
                let reportDate = !isEmptyVariable(dropdownItem.reportDate)?moment(dropdownItem.reportDate).format("YYYY-MM-DD"):"";
                let periodFromDate = !isEmptyVariable(dropdownItem.periodFromDate)? moment(dropdownItem.periodFromDate).format("YYYY-MM-DD"):"";
                let periodToDate =!isEmptyVariable(dropdownItem.periodToDate)? moment(dropdownItem.periodToDate).format("YYYY-MM-DD"):"";

                this.setState({
                    isUnplannedNewAssignment:isUnplannedAssignment,
                    selectedDepartmentId:dropdownItem.departmentId,
                    planDate:planDate,
                    selectedCompanyId:dropdownItem.companyId,
                    selectedCompanyPlaceholder:dropdownItem.companyName,
                    selectedPlannedAssignment:dropdownItem.companyName,
                    companyUnits:newCompanyUnits,
                    selectedUnitObj:selectedUnitObj,
                    isUnitsDropdownEnabled:true,
    
                    assignmentTypeFiltered:assignmentTypeFiltered,
                    selectedAssignmentTypeId:dropdownItem.assignmentTypeId,
                    selectedAssignmentTypePlaceholder:dropdownItem.assignmentTypeName,

                    observationTemplatesFiltered:observationTemplatesFiltered,
                    selectedObservationTemplateId:isEmptyVariable(dropdownItem.observationTemplateId)?"":dropdownItem.observationTemplateId,
                    selectedObservationTemplatePlaceholder:isEmptyVariable(dropdownItem.observationTemplateName)?observationTemplateDefaultPlaceholder:dropdownItem.observationTemplateName,
                    valueAdditionflagObs:flagO,

                    execTaskTemplatesFiltered:execTaskTemplatesFiltered,
                    selectedExecTaskTemplateId:dropdownItem.executionTemplateId,
                    selectedExecTaskTemplatePlaceholder:dropdownItem.executionTemplateName,
                    valueAdditionflagExec:flagE,
    
                    selectedFunctionObjArr:selectedFunctionObjArr,
                    selectedProcessObjArr:selectedProcessObjArr,
                    assignmentArea:isEmptyVariable(dropdownItem.assignmentArea)?dropdownItem.plannedAssignmentArea:dropdownItem.assignmentArea,
                    selectedId:dropdownItem.assignmentId,

                    startDate:startDate,
                    endDate:endDate,
                    reportDate:reportDate,
                    referenceNo:dropdownItem.referenceNo,

                    selectedRiskPlaceholder:isEmptyVariable(dropdownItem.riskName)?defaultRiskPlaceholder:dropdownItem.riskName,
                    selectedRiskId:dropdownItem.riskId,

                    selectedVAIds:selectedVAIds,

                    periodFromDate:periodFromDate,
                    periodToDate:periodToDate,

                    selectedAssignmentGroups:selectedAssignmentGroups,

                    unplannedReasonPlaceholder:unplannedReason,
                    unplannedRemarks:unplannedRemarks,
                    selectedPlannedAssignmentId:dropdownItem.assignmentId,
                    selectedPlannedDropdownItem:dropdownItem,


                    submitClickedAtleastOnce:false,
                    formErrors:{},
                    showLoader:false,
                    departmentSetting: [],
                    selectedLikelihood: dropdownItem.likelihoodName,
                    selectedLikelihoodId: dropdownItem.likelihoodId,
                },() => {
                    // this.annualDropdownClick(dropdownItem)

                });
            }else{
                let companyName=companyDefaultPlaceholder;
                let companyId="";
                let companyUnits = [];
                if(this.props.departmentArr.length > 1){
                    selectedDepartmentId="";
                    selectedDepartmentName=departmentDropdownDefaultValue;
                }
                // For engagement type Self- add companyId & name
                if( this.props.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF ){
                    companyName=this.props.company.companyName;
                    companyId=this.props.company.companyId;
                    companyUnits = this.props.units.filter(unit => unit.companyId === this.props.company?.companyId);
                }

                let assignmentTypeFiltered = [];
                if(!isEmptyVariable(selectedDepartmentId)){
                    assignmentTypeFiltered = this.props.assignmentType.filter(at => at.departmentId === selectedDepartmentId);
                }

                let observationTemplatesFiltered = [];
                if(!isEmptyVariable(selectedDepartmentId)){
                    observationTemplatesFiltered = this.props.observationTemplates.filter(at => at.departmentId === selectedDepartmentId);
                }

                let departmentSetting = [];
                const departmentIds = !isEmptyArray(this.props.departments) && this.props.departments.map(item => item.departmentId);
                const filteredSettings = !isEmptyArray(this.props.allSettings) && this.props.allSettings.filter(item => departmentIds.includes(item.departmentId));
                departmentSetting = !isEmptyArray(filteredSettings) && 
                filteredSettings.filter(item => item.settingColumn.includes("directScheduled") && item.settingColumnEnabled === "N");

                let hidePlannedAssignment = this.state.hidePlannedAssignment;
                hidePlannedAssignment = !isEmptyArray(this.props.allSettings) && this.props.allSettings.some(e => 
                selectedDepartmentId == e.departmentId && e.settingColumn.includes("directScheduled") && e.settingColumnEnabled === "Y");
        
                let newCompanyUnits =[]
                Object.values(companyUnits).map((item)=>{
                    let  tempItem = item
                    tempItem["unitNameAndCity"] = item.unitName+", "+item.city
                    newCompanyUnits.push(tempItem)
                });
                // If there is only one assignment type filtered, update the state with the selected assignment type details
                if(assignmentTypeFiltered.length === 1){
                    let execTaskTemplatesFiltered = this.props.execTaskTemplates.filter(at => at.assignmentTypeId === assignmentTypeFiltered[0].assignmentTypeId);
                    let flag = execTaskTemplatesFiltered[0]?.fixedTemplateFields?.includes('"valueAddition":"Y"');
                    this.setState({
                        selectedAssignmentTypePlaceholder:assignmentTypeFiltered[0].name,
                        selectedAssignmentTypeId:assignmentTypeFiltered[0].assignmentTypeId,
                        execTaskTemplatesFiltered:execTaskTemplatesFiltered,
                        selectedPlannedDropdownItem:assignmentTypeFiltered[0],
                        selectedExecTaskTemplateId:execTaskTemplatesFiltered[0].templateId,
                        selectedExecTaskTemplatePlaceholder:execTaskTemplatesFiltered[0].templateName,
                        valueAdditionflagObs:flag,
                    })
                }else{
                    // If there are no assignment types filtered, reset the state to default values.
                    this.setState({
                        selectedAssignmentTypePlaceholder:assignmentTypeDefaultPlaceholder,
                        selectedAssignmentTypeId:"",
                        execTaskTemplatesFiltered:[],
                        selectedPlannedDropdownItem:{},
                        selectedExecTaskTemplateId:"",
                        selectedExecTaskTemplatePlaceholder:execTaskTemplateDefaultPlaceholder,
                    })
                }
                let selectedAssignmentGroups = {
                    groupSet1:[],
                    groupSet2:[],
                    groupSet3:[],
                }
                this.setState({
                    selectedDepartmentId:selectedDepartmentId,
                    selectedDepartmentName:selectedDepartmentName,
                    
                    isUnplannedNewAssignment:false,
                    planDate:"",
                    selectedCompanyId:companyId,
                    selectedCompanyPlaceholder:companyName,
                    
                    companyUnits:newCompanyUnits,
                    selectedUnitObj:[],
                    selectedUnitPlaceholder:unitDefaultPlaceholder,
                    isUnitsDropdownEnabled:this.props.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF ? hidePlannedAssignment : false,
                    
                    assignmentTypeFiltered:assignmentTypeFiltered,

                    observationTemplatesFiltered:observationTemplatesFiltered,
                    selectedObservationTemplateId:"",
                    selectedObservationTemplatePlaceholder:observationTemplateDefaultPlaceholder,
                    
                    assignmentArea:"",
                    selectedFunctionObjArr:[],
                    selectedProcessObjArr:[],

                    selectedPlannedAssignment:plannedAssignmentDefaultPlaceholder,
                    datalist:[],
                    disablePlannedAssignment:this.props.departmentArr.length > 1?true:false,

                    startDate:"",
                    endDate:"",
                    reportDate:"",
                    referenceNo:"",

                    selectedRiskPlaceholder:defaultRiskPlaceholder,
                    selectedRiskId:"",

                    selectedValuePlaceholder:defaultVAPlaceholder,
                    selectedVAIds:[],

                    periodFromDate:"",
                    periodToDate:"",
                    unplannedReasonPlaceholder:unplannedReasonDefaultPlaceholder,
                    unplannedRemarks:"",

                    tagsSelectedDD1:[],
                    tagsSelectedDD2:[],
                    tagsSelectedDD3:[],
                    selectedAssignmentGroups:selectedAssignmentGroups,
                    submitClickedAtleastOnce:false,
                    formErrors:{},
                    showLoader:false,
                    selectedPlannedDropdownItem:{},
                    hidePlannedAssignment:hidePlannedAssignment,
                    departmentSetting:departmentSetting,
                    selectedLikelihood: defaultLikelihoodPlaceholder,
                    selectedLikelihoodId: "",
                },()=>{
                    this.getPlannedAssignmentList();
                })
            }
        }
    }

    handleCreateDialogClose = (reload) => {
        this.setState({
            companyUnits:[],
            selectedVAIds:[],
            selectedCompanyObj:{},
            selectedPlannedAssignmentId:""
        },()=>{
            this.props.handleCreateDialogClose(reload);
        })
        
    }

    deptDropdownClick = (item) => {
       
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['department'] = "";
            FormErrorsTemp['functionList'] = "";
            FormErrorsTemp['processList'] = "";
        }
        // Filter the assignment types based on the selected department
        let filteredAT = this.props.assignmentType.filter(at => at.departmentId === item.departmentId);
        
        // Filter the observation templates based on the selected department
        let observationTemplatesFiltered = this.props.observationTemplates.filter(ot => ot.departmentId === item.departmentId);

        // Check if the planned assignment should be hidden based on the department settings
        let hidePlannedAssignment = this.state.hidePlannedAssignment;
        hidePlannedAssignment = !isEmptyArray(this.props.allSettings) && this.props.allSettings.some(e => 
            item.departmentId == e.departmentId && e.settingColumn.includes("directScheduled") && e.settingColumnEnabled === "Y");
        
        // If the selected department has changed, reset the state and fetch the planned assignment list
        if(this.state.selectedDepartmentId != item.departmentId){
            this.state.selectedCompanyObj={}

            // If only one assignment type is found, update the state with the assignment type details
            if(filteredAT.length === 1){
                let execTaskTemplatesFiltered = this.props.execTaskTemplates.filter(at => at.assignmentTypeId === filteredAT[0].assignmentTypeId);
                let flag = execTaskTemplatesFiltered[0]?.fixedTemplateFields?.includes('"valueAddition":"Y"');
                this.setState({
                    selectedAssignmentTypePlaceholder:filteredAT[0].name,
                    selectedAssignmentTypeId:filteredAT[0].assignmentTypeId,
                    execTaskTemplatesFiltered:execTaskTemplatesFiltered,
                    selectedPlannedDropdownItem:filteredAT[0],
                    selectedExecTaskTemplateId:execTaskTemplatesFiltered[0].templateId,
                    selectedExecTaskTemplatePlaceholder:execTaskTemplatesFiltered[0].templateName,
                    valueAdditionflagObs:flag,
                })
            }else{
                // If no assignment types are found, reset the selected assignment type placeholder
                this.setState({
                    selectedAssignmentTypePlaceholder:assignmentTypeDefaultPlaceholder
                })
            }

            // Reset the state for planned assignment, units, and functions
            this.setState({
                disablePlannedAssignment:false,
                hidePlannedAssignment:hidePlannedAssignment,
                isUnplannedNewAssignment:false,
                isUnitsDropdownEnabled:false,
                isUnitsDropdownEnabled:hidePlannedAssignment,
                selectedPlannedAssignment:plannedAssignmentDefaultPlaceholder,
                selectedPlannedAssignmentId:"",
                assignmentArea:"",
                selectedFunctionObjArr:[],
                selectedUnitPlaceholder:unitDefaultPlaceholder,
                selectedUnitObj:[],
                companyUnits:[],
            })
        }
        this.setState({
            selectedDepartmentName:item.name,
            selectedDepartmentId:item.departmentId,
            formErrors:FormErrorsTemp,
            assignmentTypeFiltered:filteredAT,
            observationTemplatesFiltered:observationTemplatesFiltered,
        },()=>{
            this.getPlannedAssignmentList();
        })
    }

    riskDropdownClick = (item) => {
        this.setState({
            selectedRiskPlaceholder:item.name,
            selectedRiskId:item.riskId,
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            if(name === "referenceNo"){
                FormErrorsTemp['referenceNo'] = isEmptyVariable(value)?"Please enter reference no":"";
            }else if(name === "unplannedRemarks"){
            }
        }

        this.setState({
            [name]:value,
            formErrors:FormErrorsTemp
        })
    }

    unplannedDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        FormErrorsTemp["unplanned"] = "";
        this.setState({
            unplannedReasonPlaceholder:item.reason,
            formErrors:FormErrorsTemp
        })
    }

    annualDropdownClick = (dropdownItem) => {

        let companyUnits = [];
        companyUnits = this.props.units.filter(unit => unit.companyId === dropdownItem.companyId);
        let newCompanyUnits =[]
        Object.values(companyUnits).map((item)=>{
            let  tempItem = item
            tempItem["unitNameAndCity"] = item.unitName+", "+item.city
            newCompanyUnits.push(tempItem)
        })
        let assignmentTypeFiltered = this.props.assignmentType.filter(at => at.departmentId === dropdownItem.departmentId);
       
        this.state.selectedCompanyObj=dropdownItem

       
        let selectedUnitObj = [];
        let splitArr = dropdownItem.unitList?.split(",");
        if(!isEmptyArray(splitArr)){
            splitArr.map((item)=>{
                newCompanyUnits.map((unit)=>{
                    if(unit.unitId==parseInt(item.split(":-:")[1]))
                    {
                        selectedUnitObj.push(unit)
                    }
                })

            })
        }

        let selectedProcessObjArr = [];
        if(!isEmptyVariable(dropdownItem.processList)){
            splitArr = dropdownItem.processList.split(",");
            if(!isEmptyArray(splitArr)){
                splitArr.forEach((item)=>{
                    if(!isEmptyVariable(item.split(":-:")[1])){
                        selectedProcessObjArr.push({
                            processId:item.split(":-:")[1]
                        });
                    }
                })
            }
        }

        let selectedFunctionObjArr = [];
        if(!isEmptyVariable(dropdownItem.functionList)){
            splitArr = dropdownItem.functionList.split(",");
            if(!isEmptyArray(splitArr)){
                splitArr.forEach((item)=>{
                    if(!isEmptyVariable(item.split(":-:")[1])){
                        selectedFunctionObjArr.push({
                            functionId:item.split(":-:")[1]
                        });
                    }
                })
            }
        }
        let planDate = moment(dropdownItem.planDate).format("YYYY-MM-DD");
        let execTaskTemplatesFiltered = this.props.execTaskTemplates.filter(at => at.assignmentTypeId === dropdownItem.plannedAssignmentTypeId);
       
      
        this.setState({
            selectedScheduleAssignment:false,
            selectedPlannedAssignment:dropdownItem.companyName,
            selectedPlannedAssignmentId:dropdownItem.assignmentId,
            planDate:planDate,
            selectedCompanyId:dropdownItem.companyId,
            selectedCompanyPlaceholder:dropdownItem.companyName,
            isUnitsDropdownEnabled:true,
            selectedUnitObj:selectedUnitObj,
            companyUnits:newCompanyUnits,
            selectedUnitPlaceholder:unitDefaultPlaceholder,
            assignmentTypeFiltered:assignmentTypeFiltered,
            selectedAssignmentTypeId:dropdownItem.plannedAssignmentTypeId,
            selectedAssignmentTypePlaceholder:dropdownItem.assignmentTypeName,
            execTaskTemplatesFiltered:execTaskTemplatesFiltered,
            selectedFunctionObjArr:selectedFunctionObjArr,
            selectedProcessObjArr:selectedProcessObjArr,
            assignmentArea:dropdownItem.plannedAssignmentArea,
            formErrors:{},
            selectedPlannedDropdownItem:dropdownItem
        })

    }

    onDateChange = (date, dateString) => {
		let planDate = "";
		if (!isEmptyVariable(date)) {
			planDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['planDate'] = "";
		this.setState({
            planDate:planDate,
            formErrors:FormErrorsTemp
        })
    };

    onStartDateChange = (date, dateString) => {
		let startDate = "";
		if (!isEmptyVariable(date)) {
			startDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['startDate'] = "";

        // Clear end date if start date is later than the current end date
        let endDate = this.state.endDate;
        if (endDate && moment(startDate).isAfter(moment(endDate))) {
            endDate = "";
            FormErrorsTemp['endDate'] = "";
        }

        // Clear end date if start date is later than the current end date
        let reportDate = this.state.reportDate;
        if (reportDate && moment(startDate).isAfter(moment(reportDate))) {
            reportDate = "";
            FormErrorsTemp['reportDate'] = "";
        }

		this.setState({
            startDate:startDate,
            endDate:endDate,
            reportDate:reportDate,
            formErrors:FormErrorsTemp
        })
    };

    onEndDateChange = (date, dateString) => {
		let endDate = "";
		if (!isEmptyVariable(date)) {
			endDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['endDate'] = "";

        // Clear end date if start date is later than the current end date
        let reportDate = this.state.reportDate;
        if (reportDate && moment(endDate).isAfter(moment(reportDate))) {
            reportDate = "";
            FormErrorsTemp['reportDate'] = "";
        }

		this.setState({
            endDate:endDate,
            reportDate:reportDate,
            formErrors:FormErrorsTemp
        })
    };

    onReportDateChange = (date, dateString) => {
		let reportDate = "";
		if (!isEmptyVariable(date)) {
			reportDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['reportDate'] = "";
		this.setState({
            reportDate:reportDate,
            formErrors:FormErrorsTemp
        })
    };

    onPeriodFromDateChange = (date, dateString) => {
		let periodFromDate = "";
		if (!isEmptyVariable(date)) {
			periodFromDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['periodFromDate'] = "";
		this.setState({
            periodFromDate:periodFromDate,
            formErrors:FormErrorsTemp
        })
    };

    onPeriodToDateChange = (date, dateString) => {
		let periodToDate = "";
		if (!isEmptyVariable(date)) {
			periodToDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['periodToDate'] = "";
		this.setState({
            periodToDate:periodToDate,
            formErrors:FormErrorsTemp
        })
    };
       
    disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    disabledStartDate = (current) => {
        const { endDate } = this.state;
        return endDate && current > moment(endDate, "YYYY-MM-DD");
    };

    disabledEndDate = (current) => {
        const { startDate } = this.state;
        return startDate && current < moment(startDate, "YYYY-MM-DD");
    };

    disabledReportDate = (current) => {
        const { endDate } = this.state;
        return endDate && current < moment(endDate, "YYYY-MM-DD");
    };

    //Functions
    handleSelectFunctionDialogClose = () => {
        this.setState({
            showSelectFunctionDialog:false,
        })
    }

    handleSelectFunctionDialogSaveClose = (selectedFunctionObjArr) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp['functionList'] = "";
        this.setState({
            showSelectFunctionDialog:false,
            selectedFunctionObjArr:selectedFunctionObjArr,
            formErrors:FormErrorsTemp,
        })
    }

    handleSelectFunctionDialogShow = () => {
        let formErr = this.state.formErrors;
        if(isEmptyVariable(this.state.selectedDepartmentId) && !this.props.isEdit){
            formErr["functionList"] = "Please select the department before function selection"
            this.setState({
                formErrors:formErr
            })
        }else{
            this.setState({
                showSelectFunctionDialog:true,
            })
        }
    }

    //Process
    handleSelectProcessDialogClose = () => {
        this.setState({
            showSelectProcessDialog:false,
        })
    }

    handleSelectProcessDialogSaveClose = (selectedProcessObjArr) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp['processList'] = "";
        this.setState({
            showSelectProcessDialog:false,
            formErrors:FormErrorsTemp,
            selectedProcessObjArr:selectedProcessObjArr
        })
    }

    handleSelectProcessDialogShow = () => {
        if(this.props.assignmentStartedFlag === false){
            let formErr = this.state.formErrors;
            if(isEmptyVariable(this.state.selectedDepartmentId) && !this.state.isEdit){
                formErr["processList"] = "Please select the department before process selection"
                this.setState({
                    formErrors:formErr
                })
            }else{
                this.setState({
                    showSelectProcessDialog:true,
                })
            }
        }
        
    }

    companyDropdownClick = (item) => {
        this.setState({
            showLoader:true,
        })
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['company'] = "";
        }

        let companyUnits = this.props.units.filter(unit => unit.companyId === item.companyId);

        let newCompanyUnits =[]
        Object.values(companyUnits).map((item)=>{
            let  tempItem = item
            tempItem["unitNameAndCity"] = item.unitName+", "+item.city
            newCompanyUnits.push(tempItem)
        })

        this.setState({
            selectedCompanyPlaceholder:this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF?item.companyName:item.unitNameCityList,
            selectedCompanyId:item.companyId,
            formErrors:FormErrorsTemp,

            //reset units when company is changed
            isUnitsDropdownEnabled:true,
            selectedUnitObj:[],
            selectedUnitPlaceholder:unitDefaultPlaceholder,
            companyUnits:companyUnits.map(item=>({...item,unitNameAndCity:item.unitName+", "+item.city})),

            showLoader:false,
        })
    }


    addSelectionVA = (args) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp.selectedVA = "";

        if(!isEmptyVariable(args.itemData.valueAdditionId))
        {
            this.state.selectedVAIds.push(args.itemData.valueAdditionId)
        }

        this.setState({
            selectedVAIds:this.state.selectedVAIds,
            formErrors:FormErrorsTemp
        })

    }

    removeSelectionVA = (args) => {
        if(!isEmptyVariable(args.itemData.valueAdditionId)){
            let idx = this.state.selectedVAIds.indexOf(args.itemData.valueAdditionId);
            if(idx !== -1){
                this.state.selectedVAIds.splice(idx,1);
            }
        }
        this.setState({
            selectedVAIds:this.state.selectedVAIds
        })
    }

    assignmentTypeDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['assignmentType'] = "";
        }

        // Filter the execution task templates based on the selected assignment type
        let execTaskTemplatesFiltered = this.props.execTaskTemplates.filter(at => at.assignmentTypeId === item.assignmentTypeId);

        // If only one template is found, update the state with the template details
        if(execTaskTemplatesFiltered.length === 1){
            let flag = execTaskTemplatesFiltered[0]?.fixedTemplateFields?.includes('"valueAddition":"Y"');
            this.setState({
                selectedExecTaskTemplateId:execTaskTemplatesFiltered[0].templateId,
                selectedExecTaskTemplatePlaceholder:execTaskTemplatesFiltered[0].templateName,
                valueAdditionflagObs:flag,
            })
        }else{
            // If no templates are found, reset the selected template ID and placeholder
            this.setState({
                selectedExecTaskTemplateId:"",
                selectedExecTaskTemplatePlaceholder:execTaskTemplateDefaultPlaceholder,
            })
        }

        this.setState({
            selectedAssignmentTypePlaceholder:item.name,
            selectedAssignmentTypeId:item.assignmentTypeId,
            formErrors:FormErrorsTemp,
            execTaskTemplatesFiltered:execTaskTemplatesFiltered,
            selectedPlannedDropdownItem:item
        })
    }

    observationTemplateDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['observationTemplate'] = "";
        }

        let flag = item?.fixedTemplateFields?.includes('"valueAddition":"Y"');

        this.setState({
            selectedObservationTemplatePlaceholder:item.templateName,
            selectedObservationTemplateId:item.templateId,
            valueAdditionflagExec:flag,
            formErrors:FormErrorsTemp,
        })
    }

    execTaskTemplateDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['execTaskTemplate'] = "";
        }
        let flag = item?.fixedTemplateFields?.includes('"valueAddition":"Y"');

        this.setState({
            selectedExecTaskTemplatePlaceholder:item.templateName,
            selectedExecTaskTemplateId:item.templateId,
            valueAdditionflagObs:flag,
            formErrors:FormErrorsTemp,
        })
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let type = e.target.name;

        let FormErrorsTemp = this.state.formErrors;
        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['assignmentArea'] = "";
        }

        this.setState({
            assignmentArea:type,
            formErrors:FormErrorsTemp
        });
    }

    handleTagChange1 = (newValueArr, actionMeta) => {
		this.setState({
            selectedAssignmentGroups: {...this.state.selectedAssignmentGroups, groupSet1:newValueArr},
        });
	};

    handleTagChange2 = (newValueArr, actionMeta) => {
		this.setState({
            selectedAssignmentGroups: {...this.state.selectedAssignmentGroups, groupSet2:newValueArr},
        });
	};

    handleTagChange3 = (newValueArr, actionMeta) => {
		this.setState({
            selectedAssignmentGroups: {...this.state.selectedAssignmentGroups, groupSet3:newValueArr},
        });
	};

    handleSelectionIsNew = (e) => {
        let isChecked = e.target.checked;
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp.checkListType = "";
       
        
        // For engagement type Self- show/clear companyUnits dropdown
        let isUnitsDropdownEnabled = this.state.isUnitsDropdownEnabled;
        let companyUnits = this.state.companyUnits;
        // let selectedUnitObj = this.state.selectedUnitObj;
        // let selectedUnitPlaceholder = this.state.selectedUnitPlaceholder;
   
        if(this.props.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF){
            companyUnits = this.props.units.filter(unit => unit.companyId === this.props.company?.companyId);
            isUnitsDropdownEnabled= isChecked;
        }else{
            isUnitsDropdownEnabled = false
        }
        if(!isChecked){
            isUnitsDropdownEnabled = false
            // selectedUnitPlaceholder=unitDefaultPlaceholder
            // selectedUnitObj=[];
        }

        let newCompanyUnits =[]
        Object.values(companyUnits).map((item)=>{
            let  tempItem = item
            tempItem["unitNameAndCity"] = item.unitName+", "+item.city
            newCompanyUnits.push(tempItem)
        })

        this.state.selectedCompanyObj={}
        
        this.setState({
            isUnplannedNewAssignment:isChecked,
            isUnitsDropdownEnabled:false,
            formErrors:FormErrorsTemp,
            companyUnits:newCompanyUnits,
            selectedUnitPlaceholder:unitDefaultPlaceholder,
            selectedUnitObj:[],
            selectedPlannedAssignment:plannedAssignmentDefaultPlaceholder,
            // selectedUnitIds:[],
            assignmentArea:"",
            selectedPlannedAssignmentId:"",
            selectedFunctionObjArr:[]
        })
    }

    handleCreateMaster = () => {
        this.setState({
            submitClickedAtleastOnce:true,
            showLoader:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(!this.props.isEdit){
            if(isEmptyVariable(this.state.selectedDepartmentName) 
            || this.state.selectedDepartmentName === departmentDropdownDefaultValue){
                isError = true;
                FormErrorsTemp['department'] = "Please select the Department";
            }
        }



        // if(isEmptyVariable(this.state.planDate)){
        //     isError=true;
        //     FormErrorsTemp['planDate'] = "Please select the plan date";
        // }
        if(!this.state.hidePlannedAssignment && (isEmptyVariable(this.state.selectedPlannedAssignment) 
        || this.state.selectedPlannedAssignment===plannedAssignmentDefaultPlaceholder) && !this.state.isUnplannedNewAssignment)
        {
            isError = true;
            FormErrorsTemp['assignment'] = "Please select an already planned assignment";
        }

        if(this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF && 
            isEmptyVariable(this.state.selectedCompanyPlaceholder) 
        || this.state.selectedCompanyPlaceholder === companyDefaultPlaceholder){
            isError = true;
            FormErrorsTemp['company'] = "Please select Company";
        }

        if(isEmptyVariable(this.state.selectedAssignmentTypePlaceholder) 
        || this.state.selectedAssignmentTypePlaceholder === assignmentTypeDefaultPlaceholder){
            isError = true;
            FormErrorsTemp['assignmentType'] = "Please select Assignment Type";
        }

        // if(isEmptyVariable(this.state.selectedObservationTemplatePlaceholder) 
        // || this.state.selectedObservationTemplatePlaceholder === observationTemplateDefaultPlaceholder){
        //     isError = true;
        //     FormErrorsTemp['observationTemplate'] = "Please select observation template";
        // }

        if(isEmptyVariable(this.state.selectedExecTaskTemplatePlaceholder) 
        || this.state.selectedExecTaskTemplatePlaceholder === execTaskTemplateDefaultPlaceholder){
            isError = true;
            FormErrorsTemp['execTaskTemplate'] = "Please select Execution Template";
        }

        if(isEmptyArray(this.state.selectedUnitObj)){
            isError = true;
            FormErrorsTemp['selectedUnit'] = "Please select Units";
        }

        if(isEmptyVariable(this.state.assignmentArea)){
            isError = true;
            FormErrorsTemp['assignmentArea'] = "Please select Scope";
        }

        if(this.state.assignmentArea==="process" &&
        isEmptyArray(this.state.selectedProcessObjArr)){
            isError = true;
            FormErrorsTemp['processList'] = "Please select Process";
        }

        if(this.state.assignmentArea==="function" &&
        isEmptyArray(this.state.selectedFunctionObjArr)){
            isError = true;
            FormErrorsTemp['functionList'] = "Please select Function";
        }

        //additional fields
        if(isEmptyVariable(this.state.startDate)){
            isError=true;
            FormErrorsTemp['startDate'] = "Please select the Start Date";
        }

        if(isEmptyVariable(this.state.endDate)){
            isError=true;
            FormErrorsTemp['endDate'] = "Please select the End Date";
        }

        var tempStartDate = new Date(this.state.startDate); //Year, Month, Date    
           var tempEndDate = new Date(this.state.endDate);


        if(!isEmptyVariable(this.state.startDate) &&
        !isEmptyVariable(this.state.endDate) &&
        (tempEndDate < tempStartDate)){    
        // !moment(this.state.endDate).isAfter(this.state.startDate)){
            isError=true;
            FormErrorsTemp['endDate'] = "End Date must be greater than Start Date";
        }

        // if(isEmptyVariable(this.state.reportDate)){
        //     isError=true;
        //     FormErrorsTemp['reportDate'] = "Please select the report date";
        // }

        // if(isEmptyVariable(this.state.referenceNo)){
        //     isError=true;
        //     FormErrorsTemp['referenceNo'] = "Please enter reference no";
        // }

        // if(isEmptyVariable(this.state.periodFromDate)){
        //     isError=true;
        //     FormErrorsTemp['periodFromDate'] = "Please select the period from";
        // }

        // if(isEmptyVariable(this.state.periodToDate)){
        //     isError=true;
        //     FormErrorsTemp['periodToDate'] = "Please select the period to";
        // }

        // if(!isEmptyVariable(this.state.periodFromDate) &&
        // !isEmptyVariable(this.state.periodToDate) &&
        // !moment(this.state.periodToDate).isAfter(this.state.periodFromDate)){
        //     isError=true;
        //     FormErrorsTemp['periodToDate'] = "End date must be greater than start date";
        // }

        // if(!isEmptyVariable(this.state.periodToDate) &&
        // !isEmptyVariable(this.state.endDate) &&
        // moment(this.state.periodToDate).isAfter(this.state.endDate)){
        //     isError=true;
        //     FormErrorsTemp['periodToDate'] = "Sample period to date should not be greater than end date";
        // }
        
        if(!isEmptyVariable(this.state.reportDate) &&
        !isEmptyVariable(this.state.endDate) &&
        moment(this.state.endDate).isAfter(this.state.reportDate)){
            isError=true;
            FormErrorsTemp['reportDate'] = "Report Date should not be less than End Date";
        }

        // if(isEmptyVariable(this.state.selectedRiskPlaceholder) ||
        // this.state.selectedRiskPlaceholder===defaultRiskPlaceholder){
        //     isError=true;
        //     FormErrorsTemp['risk'] = "Please select the risk";
        // }

        if((this.state.valueAdditionflagObs || this.state.valueAdditionflagExec) &&
            isEmptyArray(this.state.selectedVAIds)){
            isError=true;
            FormErrorsTemp['selectedVA'] = "Please select the Value Addition";
        }


        if(this.state.isUnplannedNewAssignment){
            if(isEmptyVariable(this.state.unplannedReasonPlaceholder) ||
            this.state.unplannedReasonPlaceholder === unplannedReasonDefaultPlaceholder){
                isError=true;
                FormErrorsTemp['unplanned'] = "Please select Reason";
            }
        }

        let selectedUnitIds =[]
        Object.values(this.state.selectedUnitObj).map((item)=>{
           selectedUnitIds.push(item.unitId)
        })

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";
            let postParams = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,

                units:JSON.stringify(selectedUnitIds),
                assignmentTypeId:this.state.selectedAssignmentTypeId,
                observationTemplateId:this.state.selectedObservationTemplateId,
                executionTemplateId:this.state.selectedExecTaskTemplateId,
                assignmentArea:this.state.assignmentArea,
                referenceNo:this.state.referenceNo,
                startDate:this.state.startDate,
                endDate:this.state.endDate,
                reportDate:this.state.reportDate,
                reportFormat:'Word',
                periodFromDate:this.state.periodFromDate,
                periodToDate:this.state.periodToDate,
                riskId:this.state.selectedRiskId,
                valueAdditions:JSON.stringify(this.state.selectedVAIds),
                likelihoodId:this.state.selectedLikelihoodId,
            }

            if(this.state.assignmentArea === "Process"){
                let processIds = [];

                this.state.selectedProcessObjArr.map(item=>{
                    processIds.push(item.processId.toString())
                })
                postParams.processIds = JSON.stringify(processIds)

            }else if(this.state.assignmentArea === "Function"){
                let functionIds = [];
                this.state.selectedFunctionObjArr.map(item=>{
                    functionIds.push(item.functionId.toString())
                })
                postParams.functionIds = JSON.stringify(functionIds)
            }else if(this.state.assignmentArea === "CheckList"){
                
            }

            if(this.state.selectedAssignmentGroups !== undefined && Object.keys(this.state.selectedAssignmentGroups).length !== 0){
                postParams['selectedAssignmentGroups'] = JSON.stringify(this.state.selectedAssignmentGroups);
            }

            if(this.props.isEdit){
                url = Constants.UpdateScheduledAssignment;
                postParams.assignmentId = this.state.selectedId;

                //if it is unplanned assignment and user is trying to edit it then we will have to pass
                //reason and remark
                if(this.state.isUnplannedNewAssignment){
                    postParams.unplannedReason = this.state.unplannedReasonPlaceholder;
                    postParams.unplannedRemark = this.state.unplannedRemarks;
                }
            }else{
                if(this.state.isUnplannedNewAssignment || this.state.hidePlannedAssignment){
                    url = Constants.ScheduleNewAssignment;
                    postParams.planDate = this.state.planDate;
                    postParams.departmentId = this.state.selectedDepartmentId;
                    postParams.companyId = this.state.selectedCompanyId;
                    postParams.unplannedReason = this.state.unplannedReasonPlaceholder;
                    postParams.unplannedRemark = this.state.unplannedRemarks;
                }else{
                    url = Constants.ScheduleAssignment;
                    postParams.assignmentId = this.state.selectedPlannedAssignmentId;
                }
            }
            // FormErrorsTemp['apiErr'] = "";
            // this.setState({
            //     formErrors:FormErrorsTemp,
            // });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParams)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showCreateDialog:false,
                        showLoader:false,
                        isEdit:false,
                        selectedId:"",
                        name:"",
                        shortName:"",
                        description:"",
                    },() => {
                        this.handleCreateDialogClose(true);
                    });
                }else{
                    toast.error(data.responseMessage);
                    // FormErrorsTemp['apiErr'] = data.responseMessage;
                    this.setState({
                        // formErrors:FormErrorsTemp,
                        showLoader:false
                    });
                }
            });
        }
    }

    getPlannedAssignmentList = () => {
        this.setState({
            showLoader:true,
        });

        // let curDate = new Date();
        // let startDate = curDate.getFullYear()+"-"+(curDate.getMonth()+1)+"-1";
        // let endDate = "2050-12-31";

        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            resultsize:500,
            status:"Reviewed",
            // startDate:startDate,
            // endDate:endDate,
            departmentId:this.state.selectedDepartmentId,
            activeFlag:"Y"
        }

        fetch(Constants.GetPlannedAssignments,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                });
            }
            
        });
    }

    // Akshay's Code Start Here 
    selectedScheduleAssignment = ()=>{

        this.setState({
            selectedScheduleAssignment:true
        })
        

    }

    selectedScheduleAssignmentClose=()=>{
        this.setState({
            selectedScheduleAssignment:false,
        })
    }
    unitDropdownClick = (item) => {
        let unitItem = [];
        if(Array.isArray(item)){
            unitItem = item;
        }else{
            unitItem.push(item);
        }
        let selectedUnitObj=[];
        let FormErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['selectedUnit'] = "";
        }
        for(var i =0;i<unitItem.length;i++)
        {
            selectedUnitObj.push(unitItem[i])
        }
        this.setState({
            selectedUnitObj:selectedUnitObj,
            formErrors:FormErrorsTemp
        })
    }

    showPreviousRefNoDialog = () =>{
        this.setState({
            showPreviousRefDialog:true
        })

    }

    closePreviousAssignDialogBox = () =>{
        this.setState({
            showPreviousRefDialog:false
        })
    }

    likelihoodDropdownClick = (item) => {
        this.setState({
            selectedLikelihood: item.name,
            selectedLikelihoodId: item.riskId,
        })
    }

    render(){
        let fields = {text:"unitNameAndCity",value:"unitId"}
        let fieldsVA = {text:"name",value:"valueAdditionId"}
        return(
            <div>
                <Modal className="custom-dialog scheduled-assignment" show={this.props.showCreateDialog} 
                backdrop="static">
                    <Modal.Header>
                        <h5>{this.props.isEdit?"Edit Assignment":"Schedule Assignment"}</h5>
                        <button 
                            onClick={this.handleCreateDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        
                        <div class="addnew-modal-form">
                            {
                                !this.props.isEdit &&
                                !isEmptyArray(this.props.departmentArr) &&
                                this.props.departmentArr.length > 1 &&
                                <div class="addnew-modal-form-group">
                                    <p>Department*</p>
                                    <RegularDropdown 
                                        placeholder={this.state.selectedDepartmentName}
                                        dropdownArr={this.props.departmentArr}
                                        labelParam="name"
                                        onDropDownItemClick={this.deptDropdownClick}
                                        defaultPlaceholderDropDown={departmentDropdownDefaultValue}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['department']) &&
                                        <span class="cm-error">{this.state.formErrors['department']}</span>
                                    }
                                </div>
                            }

                            {
                                !this.state.hidePlannedAssignment && !this.props.isEdit && 
                                <div className="row custom-row">
                                    <div className="col-md-6 custom-col">
                                        <div class="addnew-modal-form-group">
                                            <p> Planned Assignment*</p>
                                            {/* Akshay's Code Start Here */}
                                            
                            
                                         <input 
                                            type="text" 
                                            placeholder={this.state.selectedPlannedAssignment} 
                                            // name="referenceNo"
                                            disabled={(this.props.departmentArr.length > 1 && 
                                                this.state.selectedDepartmentName == departmentDropdownDefaultValue) ||
                                                this.state.isUnplannedNewAssignment}
                                            maxlength="0" 
                                            style={{caretColor:"transparent"}}
                                            onClick={this.selectedScheduleAssignment}
                                        />
                                            
                                            {/* <RegularDropdown 
                                                placeholder={this.state.selectedPlannedAssignment}
                                                dropdownArr={this.state.datalist}
                                                labelParam={this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF?"companyName":"unitNameCityList"}
                                                onDropDownItemClick={this.annualDropdownClick}
                                                disabled = {this.state.disablePlannedAssignment || this.state.isUnplannedNewAssignment}
                                                defaultPlaceholderDropDown={plannedAssignmentDefaultPlaceholder}
                                            /> */}

                                            {
                                                !this.state.isUnplannedNewAssignment  &&
                                                !isEmptyVariable(this.state.formErrors['assignment']) &&
                                                <span class="cm-error">{this.state.formErrors['assignment']}</span>
                                            }
                                            {/* {
                                                !isEmptyVariable(this.state.formErrors['department']) &&
                                                <span class="cm-error">{this.state.formErrors['department']}</span>
                                            } */}
                                        </div>
                                    </div>
                                    <div className="col-md-6 custom-col">
                                        <div class="addnew-modal-form-group">
                                            <p>&nbsp;</p>
                                            <TreeNodeCheckBox
                                            style={{padding:"7px 0px",marginLeft:0}}
                                            themeSettings={this.props.themeSettings}>
                                                <input 
                                                    type="checkbox"
                                                    name="isUnplannedNewAssignment"
                                                    id="isUnplannedNewAssignment"
                                                    disabled={(this.props.departmentArr.length > 1 && 
                                                        this.state.selectedDepartmentName == departmentDropdownDefaultValue)}
                                                    onChange={this.handleSelectionIsNew}
                                                    checked={this.state.isUnplannedNewAssignment}
                                                />
                                                <label for="isUnplannedNewAssignment"
                                                style={{marginLeft:6}}>Create Unplanned Assignment</label>
                                            </TreeNodeCheckBox>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row custom-row">
                                {
                                    this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                    <div className="col-md-6 custom-col">
                                        <div class="addnew-modal-form-group">
                                            <p>Client*</p>
                                            {/* <RegularDropdown 
                                                placeholder={this.state.selectedCompanyPlaceholder}
                                                dropdownArr={this.props.companies}
                                                labelParam="companyName"
                                                onDropDownItemClick={this.companyDropdownClick}
                                                disabled={!this.state.isUnplannedNewAssignment}
                                                defaultPlaceholderDropDown={companyDefaultPlaceholder}
                                            /> */}

                                            <ReactSelectDropdown
                                                handleReactSelectClose={this.companyDropdownClick}
                                                selectedDDObj= {this.state.selectedCompanyObj}
                                                selectDropdownArr={this.props.companies}
                                                label={"companyName"}
                                                value={"companyId"}
                                                placeholder={this.state.selectedCompanyPlaceholder}
                                                disabled={!this.state.isUnplannedNewAssignment && !this.state.hidePlannedAssignment}
                                            />
                                            {
                                                !isEmptyVariable(this.state.formErrors['company']) &&
                                                <span class="cm-error">{this.state.formErrors['company']}</span>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Units*</p> 
                                                
                                        {/* <MultiSelectComponent 
                                            id="unitsCheckbox"
                                            dataSource={this.state.companyUnits}
                                            fields={fields}
                                            placeholder={this.state.selectedUnitPlaceholder} 
                                            mode="CheckBox"
                                            closePopupOnSelect={false}
                                            allowFiltering={false}
                                            select={this.addSelection}
                                            removed={this.removeSelection}
                                            cssClass="clientsChecklist dialogcl"
                                            value={this.state.selectedUnitIds}
                                            showDropDownIcon={true}
                                            showClearButton={false}
                                            enabled={this.state.isUnitsDropdownEnabled}
                                        >
                                            <Inject services={[CheckBoxSelection]} />
                                        </MultiSelectComponent> */}
                                         <ReactSelectDropdown
                                            handleReactSelectClose={this.unitDropdownClick}
                                            selectedDDObj= {this.state.selectedUnitObj}
                                            selectDropdownArr={this.state.companyUnits}
                                            label={"unitNameAndCity"}
                                            value={"unitId"}
                                            placeholder={this.state.selectedUnitPlaceholder}
                                            disabled={!this.state.isUnitsDropdownEnabled}
                                            // isMultiselect ={true}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['selectedUnit']) &&
                                            <span className="cm-error">{this.state.formErrors['selectedUnit']}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row custom-row">
                                {/* <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Date*</p>
                                        <DatePicker
                                            onChange={this.onDateChange}
                                            format={"DD/MM/YYYY"}
                                            disabledDate={this.disabledDate}
                                            value={!isEmptyVariable(this.state.planDate) ? moment(this.state.planDate) : ""}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['planDate']) &&
                                            <span class="cm-error">{this.state.formErrors['planDate']}</span>
                                        }
                                    </div>
                                </div> */}
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                       
                                        <p>Assignment Type*</p>
                                        <RegularDropdown 
                                            placeholder={this.state.selectedAssignmentTypePlaceholder}
                                            dropdownArr={this.state.assignmentTypeFiltered}
                                            labelParam="name"
                                            onDropDownItemClick={this.assignmentTypeDropdownClick}
                                            defaultPlaceholderDropDown={assignmentTypeDefaultPlaceholder}
                                            disabled={this.props.assignmentStartedFlag}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['assignmentType']) &&
                                            <span class="cm-error">{this.state.formErrors['assignmentType']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Reference No.</p>
                                        <div className='row custom-row'>
                                            <input 
                                                type="text" 
                                                style={{width:"80%",marginLeft:10}}
                                                placeholder="Enter Reference Number" 
                                                name="referenceNo"
                                                onChange ={this.handleChange}
                                                value={this.state.referenceNo}
                                            />
                                           {!isEmptyArray(this.state.selectedUnitObj) && !isEmptyVariable(this.state.selectedAssignmentTypeId) &&
                                            <AssigmentDetailsIconLayout themeSettings={this.props.themeSettings} style={{marginLeft:5}}>
                                                <span className="material-icons" onClick={this.showPreviousRefNoDialog} >info</span>
                                            </AssigmentDetailsIconLayout>}
                                        </div>
                                        {
                                            !isEmptyVariable(this.state.formErrors['referenceNo']) &&
                                            <span className="cm-error">{this.state.formErrors['referenceNo']}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row custom-row">
                                <div class="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Scope*</p>
                                        <TableRadioButton
                                        style={{marginLeft:0,display:"inline-flex"}}
                                        themeSettings={this.props.themeSettings}>
                                            <input 
                                                type="checkbox"
                                                name={"Process"}
                                                id={"processCb"}
                                                onChange={this.handleSelection}
                                                checked={this.state.assignmentArea==="Process"?true:false}
                                                disabled={this.props.assignmentStartedFlag}
                                            />
                                            <label for={"processCb"} 
                                            style={{marginLeft:0}}>Process</label>
                                        </TableRadioButton>
                                        <TableRadioButton
                                        style={{marginLeft:10,display:"inline-flex"}}
                                        themeSettings={this.props.themeSettings}>
                                            <input 
                                                type="checkbox"
                                                name={"Function"}
                                                id={"functionCb"}
                                                onChange={this.handleSelection}
                                                checked={this.state.assignmentArea==="Function"?true:false}
                                                disabled={this.props.assignmentStartedFlag}
                                            />
                                            <label for={"functionCb"} 
                                            style={{marginLeft:0}}>Function</label>
                                        </TableRadioButton>
                                        <TableRadioButton
                                        style={{marginLeft:10,display:"inline-flex"}}
                                        themeSettings={this.props.themeSettings}>
                                            <input 
                                                type="checkbox"
                                                name={"CheckList"}
                                                id={"checklistCb"}
                                                onChange={this.handleSelection}
                                                checked={this.state.assignmentArea==="CheckList"?true:false}
                                                disabled={this.props.assignmentStartedFlag}
                                            />
                                            <label for={"checklistCb"} 
                                            style={{marginLeft:0}}>Checklist</label>
                                        </TableRadioButton>
                                        {
                                            !isEmptyVariable(this.state.formErrors['assignmentArea']) &&
                                            <span className="cm-error">{this.state.formErrors['assignmentArea']}</span>
                                        }
                                    </div>
                                </div>
                                <div class="col-md-6 custom-col">
                                {
                                    this.state.assignmentArea==="Function" &&
                                    <div className="addnew-modal-form-group">
                                        <p>Function</p>
                                        <div className="dummy-dropdown" tabIndex={0} onKeyPress={this.onKeyBtnPress}  
                                        onClick={this.handleSelectFunctionDialogShow}>
                                            <p class="placeholder-text">
                                                {isEmptyArray(this.state.selectedFunctionObjArr)
                                                ?functionDropdownDefaultValue
                                                :this.state.selectedFunctionObjArr.length === 1
                                                ?"1 Function Selected"
                                                :this.state.selectedFunctionObjArr.length+" Functions Selected"}
                                            </p>
                                            <span class="material-icons">keyboard_arrow_right</span>
                                        </div>
                                        {
                                            !isEmptyVariable(this.state.formErrors['functionList']) &&
                                            <span className="cm-error">{this.state.formErrors['functionList']}</span>
                                        }
                                    </div>
                                }   
                                {
                                    this.state.assignmentArea==="Process" &&
                                    <div className="addnew-modal-form-group">
                                        <p>Process</p>
                                        <div className="dummy-dropdown" tabIndex={0} onKeyPress={this.onKeyBtnPress}  
                                        onClick={this.handleSelectProcessDialogShow}>
                                            <p class="placeholder-text">
                                                {isEmptyArray(this.state.selectedProcessObjArr)
                                                ?processDropdownDefaultValue
                                                :this.state.selectedProcessObjArr.length === 1
                                                ?"1 Process Selected"
                                                :this.state.selectedProcessObjArr.length+" Processes Selected"}
                                            </p>
                                            <span class="material-icons">keyboard_arrow_right</span>
                                        </div>
                                        {
                                            !isEmptyVariable(this.state.formErrors['processList']) &&
                                            <span className="cm-error">{this.state.formErrors['processList']}</span>
                                        }
                                    </div>
                                }  
                                </div>
                            </div>
                            
                            <p className="schedule-assignment-heading">Timeline</p>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Start*</p>
                                        <DatePicker
                                            onChange={this.onStartDateChange}
                                            format={"DD/MM/YYYY"}
                                            // disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                                            // disabledDate={this.disabledStartDate}
                                            // disabledDate={this.disabledDate}
                                            value={!isEmptyVariable(this.state.startDate) ? moment(this.state.startDate) : ""}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['startDate']) &&
                                            <span class="cm-error">{this.state.formErrors['startDate']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>End*</p>
                                        <DatePicker
                                            onChange={this.onEndDateChange}
                                            format={"DD/MM/YYYY"}
                                            disabledDate={this.disabledEndDate}
                                            // disabledDate={this.disabledDate}
                                            value={!isEmptyVariable(this.state.endDate) ? moment(this.state.endDate) : ""}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['endDate']) &&
                                            <span class="cm-error">{this.state.formErrors['endDate']}</span>
                                        }
                                    </div>
                                </div>
                            </div> 
                            
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Report</p>
                                        <DatePicker
                                            onChange={this.onReportDateChange}
                                            format={"DD/MM/YYYY"}
                                            disabledDate={this.disabledReportDate}
                                            // disabledDate={this.disabledDate}
                                            value={!isEmptyVariable(this.state.reportDate) ? moment(this.state.reportDate) : ""}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['reportDate']) &&
                                            <span class="cm-error">{this.state.formErrors['reportDate']}</span>
                                        }
                                    </div>
                                </div>
                            </div>

                            <p className="schedule-assignment-heading">Sample Period</p>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>From</p>
                                        <DatePicker
                                            onChange={this.onPeriodFromDateChange}
                                            format={"DD/MM/YYYY"}
                                            value={!isEmptyVariable(this.state.periodFromDate) ? moment(this.state.periodFromDate) : ""}
                                            />
                                        {
                                            !isEmptyVariable(this.state.formErrors['periodFromDate']) &&
                                            <span class="cm-error">{this.state.formErrors['periodFromDate']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>To</p>
                                        <DatePicker
                                            onChange={this.onPeriodToDateChange}
                                            format={"DD/MM/YYYY"}
                                            value={!isEmptyVariable(this.state.periodToDate) ? moment(this.state.periodToDate) : ""}
                                            />
                                        {
                                            !isEmptyVariable(this.state.formErrors['periodToDate']) &&
                                            <span class="cm-error">{this.state.formErrors['periodToDate']}</span>
                                        }
                                    </div>
                                </div>
                            </div>

                            <p className="schedule-assignment-heading">Other Details</p>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Execution Template*</p>
                                        <RegularDropdown 
                                            placeholder={this.state.selectedExecTaskTemplatePlaceholder}
                                            dropdownArr={this.state.execTaskTemplatesFiltered}
                                            labelParam="templateName"
                                            onDropDownItemClick={this.execTaskTemplateDropdownClick}
                                            defaultPlaceholderDropDown={execTaskTemplateDefaultPlaceholder}
                                            disabled={this.props.assignmentStartedFlag}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['execTaskTemplate']) &&
                                            <span class="cm-error">{this.state.formErrors['execTaskTemplate']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Observation Template</p>
                                        <RegularDropdown 
                                            placeholder={this.state.selectedObservationTemplatePlaceholder}
                                            dropdownArr={this.state.observationTemplatesFiltered}
                                            labelParam="templateName"
                                            onDropDownItemClick={this.observationTemplateDropdownClick}
                                            defaultPlaceholderDropDown={observationTemplateDefaultPlaceholder}
                                            disabled={ this.props.assignmentStartedFlag?((isEmptyVariable(this.state.selectedObservationTemplatePlaceholder) ||this.state.selectedObservationTemplatePlaceholder==="Select Observation Template")?false:true):false}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['observationTemplate']) &&
                                            <span class="cm-error">{this.state.formErrors['observationTemplate']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Risk</p>
                                        <RegularDropdown 
                                            placeholder={this.state.selectedRiskPlaceholder}
                                            dropdownArr={this.props.risks}
                                            labelParam="name"
                                            onDropDownItemClick={this.riskDropdownClick}
                                            defaultPlaceholderDropDown={defaultRiskPlaceholder}
                                        />
                                        {/* {
                                            !isEmptyVariable(this.state.formErrors['risk']) &&
                                            <span class="cm-error">{this.state.formErrors['risk']}</span>
                                        } */}

                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Likelihood</p>
                                        <RegularDropdown 
                                            placeholder={this.state.selectedLikelihood}
                                            dropdownArr={this.props.likelihoods}
                                            labelParam="name"
                                            onDropDownItemClick={this.likelihoodDropdownClick}
                                            defaultPlaceholderDropDown={defaultLikelihoodPlaceholder}
                                        />
                                    </div>
                                </div>
                                {
                                    (this.state.valueAdditionflagObs || this.state.valueAdditionflagExec) &&
                                    <div className="col-md-6 custom-col">
                                        <div className="addnew-modal-form-group">
                                            <p>Value Addition</p>
                                            <MultiSelectComponent 
                                                id="vaCheckbox"
                                                dataSource={this.props.valueAdditions}
                                                fields={fieldsVA}
                                                placeholder={this.state.selectedValuePlaceholder} 
                                                mode="CheckBox"
                                                closePopupOnSelect={false}
                                                allowFiltering={false}
                                                select={this.addSelectionVA}
                                                removed={this.removeSelectionVA}
                                                cssClass="clientsChecklist dialogcl"
                                                value={this.state.selectedVAIds}
                                                showDropDownIcon={true}
                                                showClearButton={false}
                                                // enabled={this.props.assignmentStartedFlag?false:true}
                                            >
                                                <Inject services={[CheckBoxSelection]} />
                                            </MultiSelectComponent>
                                            {
                                                !isEmptyVariable(this.state.formErrors['selectedVA']) &&
                                                <span className="cm-error">{this.state.formErrors['selectedVA']}</span>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="row custom-row">

                                {
                                    !isEmptyArray(this.props.allSettings) &&
                                    this.props.allSettings.map(item=>{
                                        if(item.settingColumn.includes("groupSet") 
                                            && item.settingColumnEnabled === "Y" && !isEmptyVariable(item.settingValue)){
                                            return <div className="col-md-6 custom-col">
                                                <div className="addnew-modal-form-group">
                                                    <p>{item.settingValue}</p>
                                                    <CreatableSelect
                                                        isMulti
                                                        placeholder="Add Groups"
                                                        styles={epicCustomStyles}
                                                        classNamePrefix="dialog-react-select"
                                                        value={this.state.selectedAssignmentGroups?.[item.settingColumn]}
                                                        onChange={
                                                            item.settingColumn === "groupSet1"
                                                            ?
                                                                this.handleTagChange1
                                                            :   
                                                                item.settingColumn === "groupSet2"
                                                                ?
                                                                    this.handleTagChange2
                                                                :
                                                                    this.handleTagChange3
                                                        }
                                                        options={
                                                            this.props.assignmentGroups?.[this.state.selectedDepartmentId]?.[item.settingColumn]
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        }else{
                                            return null;
                                        }
                                    })
                                }
                                
                            </div>
                            
                            {
                                this.state.isUnplannedNewAssignment &&
                                !this.state.isEdit &&
                                <div className="row custom-row">
                                    <div className="col-md-6 custom-col">
                                        <div class="addnew-modal-form-group">
                                            <p>Unplanned Reason*</p>
                                            <RegularDropdown 
                                                placeholder={this.state.unplannedReasonPlaceholder}
                                                dropdownArr={this.props.unplannedReason}
                                                labelParam="reason"
                                                onDropDownItemClick={this.unplannedDropdownClick}
                                                defaultPlaceholderDropDown={unplannedReasonDefaultPlaceholder}
                                            />
                                            {
                                                !isEmptyVariable(this.state.formErrors['unplanned']) &&
                                                <span class="cm-error">{this.state.formErrors['unplanned']}</span>
                                            }
                                        </div>
                                    </div>
                                    <div  className="col-md-6 custom-col">
                                        <div class="addnew-modal-form-group">
                                            <p>Unplanned Remarks</p>
                                            <textarea type="text" placeholder="Enter Remarks" spellCheck="true" 
                                            name="unplannedRemarks" value={this.state.unplannedRemarks} onChange={this.handleChange}/>
                                            {
                                                !isEmptyVariable(this.state.formErrors['unplannedRemarks']) &&
                                                <span class="cm-error">{this.state.formErrors['unplannedRemarks']}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* {this.state.formErrors['apiErr'] ?
                                <div className="addnew-modal-form-group">
                                    <span class="cm-error">{this.state.formErrors['apiErr']}</span>
                                </div>
                                : null
                            } */}
                        </div>
                        <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton disabled={this.state.showLoader}
                                themeSettings={this.props.themeSettings} onClick={this.handleCreateMaster} type="button">
                                    {this.state.showLoader ? <div class="loader-btn mt-2"></div> : 'Save'}
                                </DialogSaveButton>
                            </div>
                        </div>
                    </div>

                </Modal>
                {/* Akshay's Code Start Here */}
                 <SelectPlannedAssignment 
                  selectedScheduleAssignment={this.state.selectedScheduleAssignment} 
                  datalist= {this.state.datalist}
                  selectedPlannedAssignmentId = {this.state.selectedPlannedAssignmentId} 
                  companyType = {this.props.company?.companyType }
                  onSaveButtonClick={this.annualDropdownClick} 
                  onCancleButtonClick={this.selectedScheduleAssignmentClose}/>

                {/* Akshay's Code End Here */}
                <SelectFunctionDialog
                    showFunctionsDialog={this.state.showSelectFunctionDialog}
                    handleSelectFuncDialogClose={this.handleSelectFunctionDialogClose}
                    handleSelectFunctionDialogSaveClose={this.handleSelectFunctionDialogSaveClose}
                    selectedFunctionObjArr={this.state.selectedFunctionObjArr}
                    departmentId={this.state.selectedDepartmentId}
                    themeSettings = {this.props.themeSettings}
                />
                <SelectProcessDialog
                    showProcessesDialog={this.state.showSelectProcessDialog}
                    handleSelectProcessDialogClose={this.handleSelectProcessDialogClose}
                    handleSelectProcessDialogSaveClose={this.handleSelectProcessDialogSaveClose}
                    selectedProcessObjArr={this.state.selectedProcessObjArr}
                    departmentId={this.state.selectedDepartmentId}
                    themeSettings = {this.props.themeSettings}
                />
                 <PreviousRefNoDialog
                    showDialogBox={this.state.showPreviousRefDialog}
                    closeDialogBox={this.closePreviousAssignDialogBox}
                    unitList = {this.state.selectedUnitObj}
                    plannedAssignmentId = {this.state.selectedPlannedAssignmentId}
                    companyId = {this.state.selectedCompanyId}
                    assignmentTypeId ={this.state.selectedAssignmentTypeId} 
                    dropdownItem = {this.state.selectedPlannedDropdownItem}
                    themeSettings={this.props.themeSettings}
                    userDetails ={userDetails}
                />
            </div>
        )
    }
}

export default CreateAnnualPlan;