import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import RegularDropdown from '../../common/DropdownMenus/RegularDropdown';
import {DialogSaveButton} from '../../common/Global/globalStyles.style';
import AddCityDialog from '../../common/DropdownMenus/addCityDropdownDialog'
import ReactSelectDropdown from '../../common/DropdownMenus/ReactSelectDropdownWithSearch';
import { toast } from 'react-toastify';
import SelectGroupingDialog from './selectGrouping';

const userDetails  = getLocalStorageVariables();
const unitTypePlaceholder = "Select Unit Type";
const cityPlaceholderText = "Select City";
const companyGroupingPlaceholderText = "Select Grouping";

class AddClientAdminDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,

            companyName:"",
            companyShortName:"",
            companyCode:"",
            address1:"",
            address2:"",
            city:"",
            pinCode:"",
            state:"",
            country:"",
            formErrors:{},
            unitTypeArr:[],
            unitType:unitTypePlaceholder,
            unitTypeShortName:"",

            showAddCityDialog: false,
            selectedUnitTypeObj:{},
            selectedGroupingObjArr: []
        }
        this.textInput = React.createRef();
    }

    componentDidMount(){
        this.getCreateCompanyMasterData();
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showAddCompanyDialog){
            if(this.props.isEdit){

                let selectedUnitTypeObj ={}
                selectedUnitTypeObj["name"]=this.props.editCompanyItem.name
                selectedUnitTypeObj["shortName"]=this.props.editCompanyItem.unitType




                this.setState({
                    formErrors:{},
                    companyName:this.props.editCompanyItem.companyName,
                    companyShortName:this.props.editCompanyItem.companyShortName,
                    companyCode:this.props.editCompanyItem.companyCode,
                    address1:this.props.editCompanyItem.address1,
                    address2:this.props.editCompanyItem.address2,
                    city:this.props.editCompanyItem.city,
                    pinCode:this.props.editCompanyItem.pinCode,
                    state:this.props.editCompanyItem.state,
                    country:this.props.editCompanyItem.country,
                    unitType:this.props.editCompanyItem.name,
                    unitTypeShortName:this.props.editCompanyItem.unitType,
                    selectedUnitTypeObj:selectedUnitTypeObj,
                    selectedGroupingObjArr: this.props.editCompanyItem.selectedGroupingList
                },()=>{
                    this.textInput.current.focus();
                })
            }else{
                this.setState({
                    formErrors:{},
                    companyName:"",
                    companyShortName:"",
                    companyCode:"",
                    address1:"",
                    address2:"",
                    city:"",
                    pinCode:"",
                    state:"",
                    country:"",
                    unitType:unitTypePlaceholder,
                    selectedGroupingObjArr: []
                },()=>{
                    this.textInput.current.focus();
                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    typeDropdownClick = (item) => {
        this.setState({
            unitType:item.name,
            unitTypeShortName:item.shortName
        })
    }


    handleSearchCityDialogClose = (selectedItem) => {

        if(!isEmptyVariable(selectedItem)){
            this.setState({
                city:selectedItem.city,
                state:selectedItem.state,
                country:selectedItem.country,
                showAddCityDialog:false,
            })
        }
		else{
            this.setState({
                showAddCityDialog: false,
            });
        }
	};

	showAddCityDialog = () => {
        if(!isEmptyVariable(this.state.city)){
            this.setState({
                showAddCityDialog: true,
                isEdit:true,
    
            });
        }
        else{
            this.setState({
                showAddCityDialog: true,
    
            });
        }
		
	};

    handleSelectGroupingDialogShow = () => {
        this.setState({
            showGroupingDialog: true
        })
    }

    handleGroupingTreeDialogClose = () => {
        this.setState({
            showGroupingDialog: false,
        })
    }

    handleSelectGroupingDialogSaveClose = (selectedGroupingObjArr) => {
        this.setState({
            showGroupingDialog: false,
            selectedGroupingObjArr: selectedGroupingObjArr
        })
    }
    /************************API CALLS **************************/
    addEditCompany = () => {
        this.setState({
            showBtnLoader:true,
        });

        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.companyName)){
            isError = true;
            FormErrorsTemp['companyName'] = "Please enter company name";
        }

        if(isEmptyVariable(this.state.companyShortName)){
            isError = true;
            FormErrorsTemp['companyShortName'] = "Please enter company short name";
        }

        if(isEmptyVariable(this.state.companyCode)){
            isError = true;
            FormErrorsTemp['companyCode'] = "Please enter client code";
        }

        if(isEmptyVariable(this.state.unitType) || this.state.unitType === unitTypePlaceholder){
            isError = true;
            FormErrorsTemp['unitType'] = "Please select unit type";
        }

        if(isEmptyVariable(this.state.address1)){
            isError = true;
            FormErrorsTemp['address1'] = "Please enter address1";
        }

        if(isEmptyVariable(this.state.city)){
            isError = true;
            FormErrorsTemp['city'] = "Please enter city";
        }

        if(isEmptyVariable(this.state.pinCode)){
            isError = true;
            FormErrorsTemp['pinCode'] = "Please enter pin code";
        }

        if(!isEmptyVariable(this.state.pinCode)){
            var regexp = new RegExp(/^(?=.*[0-9])[ 0-9]+$/);
            if(!regexp.test(this.state.pinCode)) {
                isError = true;
                FormErrorsTemp['pinCode'] = "Please enter valid pin code";
            }
        }

        if(isEmptyVariable(this.state.state)){
            isError = true;
            FormErrorsTemp['state'] = "Please enter state";
        }

        if(isEmptyVariable(this.state.country)){
            isError = true;
            FormErrorsTemp['country'] = "Please enter country";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showBtnLoader:false
            });
            
            setTimeout(function(){
                this.setState({
                    formErrors:{},
                });
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }else{
            let url = Constants.CreateCompany
            if(this.props.isEdit){
                url = Constants.EditCompany
            }
            
            let postParam = {
                companyName:this.state.companyName,
                companyShortName:this.state.companyShortName,
                companyCode:this.state.companyCode,
                address1:this.state.address1,
                address2:this.state.address2,
                city:this.state.city,
                pinCode:this.state.pinCode,
                state:this.state.state,
                country:this.state.country,
                unitType:this.state.unitTypeShortName
            }
            if(!isEmptyArray(this.state.selectedGroupingObjArr)){
                let companyGroupingIdList = [];
                this.state.selectedGroupingObjArr.map(item=>{
                    companyGroupingIdList.push({"unitGroupId":item?.unitGroupId,"rootUnitGroupId":item.rootUnitGroupId})
                })
                postParam.companyGroupingIdList = companyGroupingIdList
            }
            if(this.props.isEdit){
                postParam.companyId = this.props.editCompanyItem.companyId
                postParam.unitId = this.props.editCompanyItem.unitId
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    data:JSON.stringify(postParam)
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showBtnLoader:false,
                    },()=>{
                        this.props.handleAddCompanyDialogClose(true)
                    });
                }else{
                    toast.error(data.responseMessage);
                    // FormErrorsTemp['responseMessage'] = data.responseMessage;
                    this.setState({
                        // formErrors:FormErrorsTemp,
                        showBtnLoader:false
                    });

                    // setTimeout(function(){
                    //     this.setState({
                    //         formErrors:{},
                    //     });
                    // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                }
            });
        }
    }

    getCreateCompanyMasterData = () => {
        this.setState({
            showLoader:true,
        })
        fetch(Constants.GetCompanyCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        unitTypeArr:data.data.unitType
                    })
                }else{
                }
            });
    }

    render() {
        return(
            <Modal className="add-company-dialog custom-dialog" 
            show={this.props.showAddCompanyDialog} backdrop="static">
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit Client":"Add New Client"}</h5>
                    <button 
                        onClick={this.props.handleAddCompanyDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    <div className="addnew-modal-form">
                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Company Name*</p>
                                    <input 
                                        ref={this.textInput}
                                        type="text" 
                                        placeholder="Enter Company Name" 
                                        name="companyName"
                                        onChange ={this.handleChange}
                                        value={this.state.companyName}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['companyName']) &&
                                        <span className="cm-error">{this.state.formErrors['companyName']}</span>
                                    }
                                    
                                </div>
                            </div>
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Company Short Name*</p>
                                    <input 
                                        type="text" 
                                        placeholder="Enter Company Short Name" 
                                        name="companyShortName"
                                        onChange ={this.handleChange}
                                        value={this.state.companyShortName}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['companyShortName']) &&
                                        <span className="cm-error">{this.state.formErrors['companyShortName']}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Client Code*</p>
                                    <input 
                                        type="text" 
                                        placeholder="Enter Client Code" 
                                        name="companyCode"
                                        onChange ={this.handleChange}
                                        value={this.state.companyCode}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['companyCode']) &&
                                        <span className="cm-error">{this.state.formErrors['companyCode']}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <fieldset>
                                    <p>Unit Type*</p>
                                    {/* <RegularDropdown 
                                        placeholder={this.state.unitType}
                                        dropdownArr={this.state.unitTypeArr}
                                        labelParam="name"
                                        onDropDownItemClick={this.typeDropdownClick}
                                        defaultPlaceholderDropDown={unitTypePlaceholder}
                                    /> */}

                                        <ReactSelectDropdown
                                                    selectedDDObj= {this.state.selectedUnitTypeObj}
                                                    handleReactSelectClose={this.typeDropdownClick}
                                                    selectDropdownArr={this.state.unitTypeArr}
                                                    label={"name"}
                                                    value={"shortName"}
                                                    placeholder={this.state.unitType}
                                        />
                                    {
                                        !isEmptyVariable(this.state.formErrors['unitType']) &&
                                        <span className="cm-error">{this.state.formErrors['unitType']}</span>
                                    }
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Address Line 1*</p>
                                    <textarea 
                                        type="text" 
                                        placeholder="Enter Address Line 1" 
                                        name="address1"
                                        onChange ={this.handleChange}
                                        value={this.state.address1}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['address1']) &&
                                        <span className="cm-error">{this.state.formErrors['address1']}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Address Line 2</p>
                                    <textarea
                                        type="text" 
                                        placeholder="Enter Address Line 2" 
                                        name="address2"
                                        onChange ={this.handleChange}
                                        value={this.state.address2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>City*</p>
                                    <div 
                                        className="dummy-dropdown" 
                                        tabIndex={0} 
                                        onClick={this.showAddCityDialog}>
                                            <p class="placeholder-text">
                                                {isEmptyVariable(this.state.city)
                                                ?cityPlaceholderText
                                                :this.state.city}
                                            </p>
                                            <span class="material-icons">keyboard_arrow_right</span>
                                    </div>
                                    {
                                        !isEmptyVariable(this.state.formErrors['city']) &&
                                        <span className="cm-error">{this.state.formErrors['city']}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Pin Code*</p>
                                    <input
                                        type="text" 
                                        placeholder="Enter Pin Code" 
                                        name="pinCode"
                                        onChange ={this.handleChange}
                                        value={this.state.pinCode}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['pinCode']) &&
                                        <span className="cm-error">{this.state.formErrors['pinCode']}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group input-dis">
                                    <p>State*</p>
                                    <input
                                        type="text" 
                                        placeholder="Enter State" 
                                        name="state"
                                        onChange ={this.handleChange}
                                        value={this.state.state}
                                        // disabled={!isEmptyVariable(this.state.state)?true:false}
                                        disabled={true}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['state']) &&
                                        <span className="cm-error">{this.state.formErrors['state']}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group input-dis">
                                    <p>Country*</p>
                                    <input
                                        type="text" 
                                        placeholder="Enter Country" 
                                        name="country"
                                        onChange ={this.handleChange}
                                        value={this.state.country}
                                        // disabled={!isEmptyVariable(this.state.country)?true:false}
                                        disabled={true}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['country']) &&
                                        <span className="cm-error">{this.state.formErrors['country']}</span>
                                    }
                                </div>
                            </div>
                            {/* {
                                !isEmptyVariable(this.state.formErrors['responseMessage']) &&
                                <span className="cm-error">{this.state.formErrors['responseMessage']}</span>
                            } */}
                        </div>
                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Company Grouping</p>
                                    <div 
                                        className="dummy-dropdown" 
                                        tabIndex={0} 
                                        onClick={this.handleSelectGroupingDialogShow}>
                                            <p class="placeholder-text">
                                                {isEmptyArray(this.state.selectedGroupingObjArr)
                                                ?companyGroupingPlaceholderText
                                                :this.state.selectedGroupingObjArr.length === 1
                                                ?"1 Grouping Selected"
                                                :this.state.selectedGroupingObjArr.length+" Grouping Selected"}
                                            </p>
                                            <span class="material-icons">keyboard_arrow_right</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="modal-close-save">
                        <div className="close-save-col">
                            <button onClick={this.props.handleAddCompanyDialogClose} type="button" className="modal-close">Cancel</button>
                            <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.addEditCompany} type="button" className="modal-save">
                                {this.state.showBtnLoader ? <div class="loader-btn mt-2"></div> : 'Save'}
                            </DialogSaveButton>
                        </div>
                    </div>
                </div>
                <AddCityDialog
                    showAddCityDialog = {this.state.showAddCityDialog}
                    handleCityDialogClose = {this.handleSearchCityDialogClose}
                    themeSettings={this.props.themeSettings}
                    isEdit={this.state.isEdit}
                    selectedCity={this.state.city}

                />
                <SelectGroupingDialog
                    showGroupingDialog={this.state.showGroupingDialog}
                    handleGroupingDialogClose={this.handleGroupingTreeDialogClose}
                    handleSelectGroupingDialogSaveClose={this.handleSelectGroupingDialogSaveClose}
                    themeSettings={this.props.themeSettings}
                    isClient={"Y"}
                    companyType={this.props.companyType}
                    selectedGroupingObjArr={this.state.selectedGroupingObjArr}
                />
            </Modal>
        );
    }
}

export default AddClientAdminDialog;