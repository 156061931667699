import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray } from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import RegularDropdown from "../../../common/DropdownMenus/RegularDropdown";
import { DialogSaveButton } from "../../../common/Global/globalStyles.style";
import { toast } from "react-toastify";

const userDetails = getLocalStorageVariables();

class AddCommentDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
			showLoader: false,

			comment: "",
			formErrors: {},
			submitClickedAtleastOnce: false,
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showCreateDialog) {
			this.setState({
				componentDidMountFlag: false,
				showLoader: false,

				comment: "",
				formErrors: {},
				submitClickedAtleastOnce: false,
			});
		}
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handleCreateDialogClose = () => {
		this.props.handleAddDialogClose(false);
	};

	onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
			this.handleCreateComment();
		}
	};

	handleCreateComment = () => {
		this.setState({
			showLoader: true,
			submitClickedAtleastOnce: true,
		});

		let isError = false;
		let FormErrorsTemp = {};

		if (isEmptyVariable(this.state.comment)) {
			isError = true;
			FormErrorsTemp["comment"] = "Please enter comment";
		}

		if (isError) {
			this.setState({
				formErrors: FormErrorsTemp,
				showLoader: false,
			});
		} else {
			let url = "";
			let postParam = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
                assignmentSampleCheckedId: this.props.assignmentSampleCheckedId,
                remark: this.state.comment,
			};

			fetch(Constants.SaveSampleCheckedRemarks, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState(
							{
								showLoader: false,
								comment: "",
								formErrors: {},
							},
							() => {
								this.props.handleAddDialogClose(true);
							}
						);
					} else {
						// FormErrorsTemp["apiErr"] = data.responseMessage;
						toast.error(data.responseMessage);
						this.setState({
							// formErrors: FormErrorsTemp,
							showLoader: false,
						});
					}
				});
		}
	};

	render() {
		return (
			<Modal className="custom-dialog" show={this.props.showCreateDialog} backdrop="static">
				<Modal.Header>
					<h5>Add New Comment</h5>
					<button onClick={this.handleCreateDialogClose} type="button" data-dismiss="modal">
						<span class="material-icons">close</span>
					</button>
				</Modal.Header>
				<hr />
				<div class="modal-body">
					{this.state.showLoader && (
						<div class="loader"></div>
					)}
					<div class="addnew-modal-form">
						<div class="addnew-modal-form-group">
							<p>Comment*</p>
							<textarea
								type="text"
								placeholder="Enter Comment"
								onKeyPress={this.onEnterBtnPress}
								name="comment"
								value={this.state.comment}
								onChange={this.handleChange}
								spellCheck="true" 
							/>
							{!isEmptyVariable(this.state.formErrors["comment"]) && <span class="cm-error">{this.state.formErrors["comment"]}</span>}
							{/* {!isEmptyVariable(this.state.formErrors["apiErr"]) && <span class="cm-error">{this.state.formErrors["apiErr"]}</span>} */}
						</div>
					</div>
					<div class="modal-close-save">
						<div class="close-save-col">
							<button onClick={this.handleCreateDialogClose} type="button" class="modal-close">
								Cancel
							</button>
							<DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.handleCreateComment} type="button" class="modal-save">
								Save
							</DialogSaveButton>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default AddCommentDialog;
