import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,LoadMoreButton,CustomTableRow} from '../../common/Global/globalStyles.style';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import MasterMenuTabs from './topmenu';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../common/AlertDialog';
import ReviewHistoryDialog from '../../common/reviewhistorydialog'
import UpdateReviewStatusDialog from './taskupdatereviewstatusdialog'
import TaskDetailsLayout from './taskDetailsWithTemplates';
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable,sortTable,
    GetOrderedMasterTabMenuTask,removeHtmlTags,getStatusSpan,
    isEmptyArray} from '../../common/Global/commonFunctions';
import {getDropdownActionArrayProcessTasks} from '../../common/Global/reviewFunctions';
import SelecAssignmentTypeDialog from './selectAssignmentTypeDialog';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {
    BrowserRouter as Router,
} from 'react-router-dom';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();

const activeStatus="Active";
const inActiveStatus="Inactive";
const underReviewStatus="Under Review";

const requestChangeCheckText="Request Change";

class OtherMasters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",
            deletePermission:"N",
            actionType:"",

            processMap:[],
            processId:this.props.match.params.id,

            status:activeStatus,
            statusArr:[
                {
                    label:activeStatus,
                    isSelected:true
                },
                {
                    label:underReviewStatus,
                    isSelected:false
                },
                {
                    label:inActiveStatus,
                    isSelected:false
                },
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            allTaskIds:[],
            nextTaskId:"",
            prevTaskId:"",

            departmentArr:[],
            selectedDeptId:"",
            deptPlaceholder:"",

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            deleteReactivateMasterType:"",
            reviewLevel:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            showBtnLoader:false,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,
            showDetailsDialog:false,
            selectedItem:"",

            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:"",
            user:{},

            showSelectAssigmentTypeDialog: false,
        }
    }

    componentDidMount(){
        this.getInitData();
    }
    
    onDragEnd = (result) => {
        const {destination, source, draggableId} = result;

        //Outside the droppable
        if(!destination){
            return
        }

        //if draggable is dropped in same droppable and in same position
        if(destination.droppableId === source.droppableId &&
        destination.index === source.index){
            return
        }

        let processId = destination.droppableId;
        let processObj = this.state.datalist[processId];
        let processObjBackup = JSON.parse(JSON.stringify(this.state.datalist[processId]));

        let newPosition = destination.index;
        let oldPosition = source.index;

        let taskId = draggableId;//dragged taskId
        let newPositionTaskId = processObj.taskList[newPosition].taskId;//task which was in new position

        //lets get the process object from datalist and then rearrange
        //task array
        
        let taskObj = processObj.taskList[oldPosition];
        processObj.taskList.splice(oldPosition,1)//remove from old position
        processObj.taskList.splice(newPosition,0,taskObj);//move the task Obj to new pos

        this.setState({
            datalist:{
                ...this.state.datalist,
                [processId]:processObj
            },
        },()=>{
            //update serve/database
            this.updateTaskPosition(processId,processObjBackup,taskId,newPositionTaskId);
        })
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getTasksForAllProcess();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getTasksForAllProcess();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag === true){
                this.getTasksForAllProcess();
            }
        });
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
        });
    }

    handleCreateDialogClose = () =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            selectedId:"",
            name:"",
            shortName:"",
            description:"",
        });
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }
    handleSelectAssignmentTypeDialogShow = (processObj)=>{
        this.setState({
            showSelectAssigmentTypeDialog: true,
            selectedProcessId:processObj.processId
        })
    }
    handleSelectAssignmentTypeDialogClose = () => {
        this.setState({
            showSelectAssigmentTypeDialog:false,
            selectedProcessId:""
        })
    }
    handleSelectAssignmentTypeDialogSaveClose = (selectedAssigmentTypeId) => {
        // this.setState({
        //     showSelectAssigmentTypeDialog:false,
        //     selectedAssigmentTypeId:selectedAssigmentTypeId
        // });
        this.props.history.push(Constants.URL_ADDTASK_WITH_TEMPLATES+"/"+this.state.selectedProcessId+"-"+selectedAssigmentTypeId);
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getTasksForAllProcess(true);
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getTasksForAllProcess();
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Task",
                proceedBtnLabel:"Delete",
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete task "+removeHtmlTags(dropdownItem.task)+"?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Activate Task",
                proceedBtnLabel:"Reactivate",
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate task "+removeHtmlTags(dropdownItem.task)+"?"
            })
        }else if(item.label === "Edit"){
            this.props.history.push(Constants.URL_EDITTASK_WITH_TEMPLATES+"/"+dropdownItem.taskId+"-"+dropdownItem.assignmentTypeId);
        }else if(item.label === "Submit For Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Submit For Review",
                proceedBtnLabel:"Submit",
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to submit "+removeHtmlTags(dropdownItem.task)+" for review?"
            })
        }else if(item.label === "Start Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+removeHtmlTags(dropdownItem.task)
            })
        }else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.taskId,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.taskId,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Review Remarks"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Review Remarks",
                proceedBtnLabel:"Okay",
                showAlertDialog:true,
                alertDialogMessage:'\"'+dropdownItem.lastReviewRemark+'\"'
            })
        }else if(item.label === "Discard Changes"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Discard Changes",
                proceedBtnLabel:"Discard",
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to discard changes of "+removeHtmlTags(dropdownItem.task)+"?",
                actionType: dropdownItem.previousStatus === "Updated"?"Update":(dropdownItem.previousStatus === "Deleted"?"Delete":"")
            })
        }
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getTasksForAllProcess();
        })
    }
    
    //No need of return value since the array itself is passed and updated.
    addParentIdandHasChildrenFlag = (tempArr, processId, parentProcessId, parentProcessBreadCrumb) => {
        tempArr[processId].parentProcessId = parentProcessId;
        let breadCrumb = "";
        if(isEmptyVariable(parentProcessBreadCrumb)){
            breadCrumb = tempArr[processId].processName;
            tempArr[processId].breadCrumb = breadCrumb;
        }else{
            breadCrumb = parentProcessBreadCrumb + " > " + tempArr[processId].processName;
            tempArr[processId].breadCrumb = breadCrumb;
        }

        if(!isEmptyArray(tempArr[processId].children)){
            tempArr[processId].hasChildren = true;

            tempArr[processId].children.map(childNode => {
                this.addParentIdandHasChildrenFlag(tempArr,childNode.processId,processId,breadCrumb);
            });
        }else{
            tempArr[processId].hasChildren = false;
        }
    }

    deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let {departmentArr} = this.state;

        for(const [i,value] of this.state.departmentArr.entries()){
            if(i === selectedIndex){
                departmentArr[i].isSelected = true;
                placeholder = value.name;
            }else{
                departmentArr[i].isSelected = false;
            }
        }
        
        this.setState({
            selectedDeptId:item.departmentId,
            deptPlaceholder:placeholder
        },() => {
            this.getTasksForAllProcess();
        })
    }
    handleEditTask = (taskId,assignmentTypeId) => {
        this.props.history.push(Constants.URL_EDITTASK_WITH_TEMPLATES+"/"+taskId+"-"+assignmentTypeId)
    }
    handleShowTaskDetailsLayout = (taskId,assignmentTypeId) => {
        let taskIdIndex = this.state.allTaskIds.indexOf(JSON.parse(taskId));
        let prevIndex = -1;
        let nextIndex = -1;

        if(taskIdIndex > -1){
            prevIndex = taskIdIndex-1;
            nextIndex = taskIdIndex+1;

            if(nextIndex >= this.state.allTaskIds.length){
                nextIndex = -1;
            }
        }

        this.setState({
            showTaskDetailsLayout:true,
            selectedTaskIdForDetails:taskId,
            assignmentTypeId:assignmentTypeId,
            prevTaskId:prevIndex > -1?this.state.allTaskIds[prevIndex]:"",
            nextTaskId:nextIndex > -1?this.state.allTaskIds[nextIndex]:"",
        })
    }

    handleHideTaskDetailsLayout = () => {
        this.setState({
            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:""
        })
    }
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetProcessesSubtree,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    rootProcessId:this.state.processId,
                    status:"ALL",
                    activeFlag:"Y"//get values from active tables
                })
            }),
            fetch(Constants.GetTaskForProcess,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    processId:this.state.processId,
                    filterTags:JSON.stringify([]),

                    pageNo:1,//initial page no of all the process taks
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([masterRes,generalRes,menuRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,generalRes,menuRes]) => {
            let processMapTemp = {};
            let datalistTemp = {};
            let menusTemp = {};
            let departmentsTemp = [];
            let userTemp = {};
            let placeholder = "";
            let selectedDeptId = "";
            let allTaskIds = []

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                processMapTemp = masterRes.result.processMap;
                this.addParentIdandHasChildrenFlag(processMapTemp,this.state.processId,this.state.processId,"");
            }else{
                processMapTemp = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                let datalistTempArr = generalRes.data.processList;//list of process and task
                allTaskIds = generalRes.data.allTaskIds;
                datalistTemp = {};

                datalistTempArr.map((item)=>{
                    if(!isEmptyVariable(processMapTemp[item.processId]) && !processMapTemp[item.processId].hasChildren){
                        datalistTemp[item.processId] = item;
                        datalistTemp[item.processId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists
                    }
                })
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
                userTemp = menuRes.data.user;
                departmentsTemp = menuRes.data.departments;

                if(!isEmptyArray(departmentsTemp)){
                    departmentsTemp[0].isSelected = true;
                    placeholder = departmentsTemp[0].name;
                    selectedDeptId = departmentsTemp[0].departmentId;
                }
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenuTask(menusTemp)
            let deletePermission = "N";
            if(menusTemp["Delete Task"] === "ALL"){
                deletePermission = "Y";
            }
            // let menusTemp2 = {
            //     Tasks:"ALL",
            //     Checklist:"ALL"
            // }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,
                deletePermission:deletePermission,

                processMap:processMapTemp,
                allTaskIds:allTaskIds,
                datalist:datalistTemp,
                menus:menusTemp2,
                departmentArr:departmentsTemp,
                deptPlaceholder:placeholder,
                selectedDeptId:selectedDeptId,
                user:userTemp
            })

            let item = this.props.location.state;
            if(!isEmptyVariable(item?.showTaskDetailsLayout)&&item?.showTaskDetailsLayout){
                // let taskId = JSON.parse(item.taskId)
                // let assignmentTypeId = JSON.parse(item.assignmentTypeId)
                this.handleShowTaskDetailsLayout(item.taskId,item.assignmentTypeId)
            }
        })
    }

    //result size change, status change, department change and search
    getTasksForAllProcess = () => {
        this.setState({
            showLoader:true,
        });
        // selectedDeptId
        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            processId:this.state.processId,
            filterTags:JSON.stringify([]),
            
            pageNo:1,//get the initial page
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:this.state.status,
        }
        if(!isEmptyVariable(this.state.selectedDeptId)){
            postParams.departmentId = this.state.selectedDeptId;
        }

        fetch(Constants.GetTaskForProcess,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let datalistTempArr = data.data.processList;//list of process and task
                let datalistTemp = {};

                datalistTempArr.map((item)=>{
                    if(!this.state.processMap[item.processId].hasChildren){
                        datalistTemp[item.processId] = item;
                        datalistTemp[item.processId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists
                    }
                })

                this.setState({
                    showLoader:false,
                    datalist:datalistTemp,
                    allTaskIds:data.data.allTaskIds,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:{},
                    allTaskIds:[],
                    showLoader:false,
                    totalCount:0,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (processId) => {
        // update state with new page of items
        this.setState({
            showLoader:true,
        });
        let pageNo = this.state.datalist[processId].currentPageNo+1;

        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            processId:processId,
            filterTags:JSON.stringify([]),
            
            pageNo:pageNo,//get the initial page
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:this.state.status,
        }
        if(!isEmptyVariable(this.state.selectedDeptId)){
            postParams.departmentId = this.state.selectedDeptId;
        }

        fetch(Constants.GetTaskForProcess,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let datalistTempArr = data.data.processList;//list of process and task
                let dataListTemp = this.state.datalist;
                let selectedProcessObj = dataListTemp[processId];
                selectedProcessObj.currentPageNo = pageNo;
                let newTaskList = datalistTempArr[0].taskList;

                selectedProcessObj.taskList = [
                    ...selectedProcessObj.taskList,
                    ...newTaskList
                ]

                dataListTemp[processId] = selectedProcessObj

                this.setState({
                    showLoader:false,
                    datalist:dataListTemp,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:{},
                    showLoader:false,
                    totalCount:0,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            taskId:this.state.deleteReactivateId
        }
        let callApi = true;

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteTask;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ActivateTask;
        }else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitTaskForReview;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.UpdateTaskReviewStatus;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Discard Changes"){
            url = Constants.DiscardChangesTask;
            postParam.actionType = this.state.actionType;
        }else if(this.state.operationType === "Review Remarks"){
            callApi = false;
            this.setState({
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
            })
        }

        if(callApi){
            this.setState({
                showBtnLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showBtnLoader:false
                    },() => {
                        this.getTasksForAllProcess();
                    });
                }else{
                    this.setState({
                        showBtnLoader:false,
                        deleteReactivateId:"",
                        actionType:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

    updateTaskPosition = (processId, processObjBackup, taskId, newPositionTaskId) => {
        fetch(Constants.UpdateTaskOrder,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                processId:processId,
                taskId:taskId,
                newPositionTaskId:newPositionTaskId,
                rootProcessId:this.state.processId,
                filterTags:JSON.stringify([]),
                search:this.state.searchkey,
                status:this.state.status,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    allTaskIds:data.data.allTaskIds,
                })
            }else{
                this.setState({
                    datalist:{
                        ...this.state.datalist,
                        [processId]:processObjBackup
                    },
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar  />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    this.state.showLoader &&
                                    <div class="loader"></div>
                                }
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    !this.state.showTaskDetailsLayout &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings={themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                        themeSettings={themeSettings}
                                                        onClick = {this.onSearchIconClick} 
                                                        className="material-icons"> search </SearchSpan>
                                                    </div>

                                                    <FilterDropDown
                                                        placeholder={this.state.statusPlaceholder}
                                                        dropdownArr={this.state.statusArr}
                                                        onDropDownItemClick={this.statusDropdownClick}
                                                        themeSettings={themeSettings}
                                                    />
                                                    
                                                    {
                                                        this.state.departmentArr.length > 1 &&
                                                        <FilterDropDown
                                                            placeholder={this.state.deptPlaceholder}
                                                            dropdownArr={this.state.departmentArr}
                                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                                            name={"name"}
                                                            themeSettings={themeSettings}
                                                            dropdownWidth={"120px"}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            Object.values(this.state.datalist).map((processObj,index)=>{
                                                return <div className="process-task-list">
                                                    <div className="process-task-heading-and-btn">
                                                    <div>
                                                        <h6 style={{marginTop:index === 0?15:10}}>{this.state.processMap[processObj.processId].breadCrumb}</h6>
                                                    </div>
                                                    <div>
                                                        <AddNewButtonLayout themeSettings={themeSettings} style={{marginLeft:15}}>
                                                            <a href="javascript:void(0);" 
                                                               onClick={this.handleSelectAssignmentTypeDialogShow.bind(this,processObj)}>
                                                                <span className="material-icons">add_circle</span>
                                                                <p>Add New</p>
                                                            </a>
                                                        </AddNewButtonLayout>
                                                    </div>
                                                    </div>
                                                    {
                                                        !isEmptyArray(processObj.taskList) && 
                                                        <div className="common-tab-bg">
                                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                            <div className="common-table" style={{width:"100%",borderRadius:4}}>
                                                                <div className="table-custom-layout">
                                                                    <div className="table-custom-header">
                                                                        <div className="table-custom-header-row">
                                                                            <p style={{width:"5%",justifyContent:"center"}}>No.</p>
                                                                            <p style={{width:"5%"}}></p>
                                                                            <p style={{width:"65%"}}>Task</p>
                                                                            <p style={{width:"20%"}}>Assignment Type</p>
                                                                            <p style={{width:"5%",justifyContent:"center"}}></p>
                                                                        </div>
                                                                    </div>
                                                                
                                                                    <Droppable droppableId={processObj.processId+""} isDropDisabled = {this.state.status === activeStatus?false:true}>
                                                                    {(provided)=>(
                                                                        <div className="table-custom-body"
                                                                            ref={provided.innerRef}
                                                                            {...provided.droppableProps}
                                                                        >
                                                                        {
                                                                            !isEmptyArray(processObj.taskList) && 
                                                                            processObj.taskList.map((item,idx) => {

                                                                                let actionObj = getDropdownActionArrayProcessTasks(item.allowEdit, item.allowReview, 
                                                                                    item.status, item.currentReviewStatus, this.state.deletePermission, item.previousStatus);
                                                                                let spanObj = getStatusSpan(item.previousStatus,themeSettings);
                                                                                return <Draggable draggableId={item.taskId+""} index={idx} key={item.taskId+""} isDragDisabled = {this.state.status === activeStatus?false:true}>
                                                                                    { (provided,snapshot) => (
                                                                                        <CustomTableRow
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            ref={provided.innerRef}
                                                                                            onClick={this.handleShowTaskDetailsLayout.bind(this,item.taskId,item.assignmentTypeId)}
                                                                                            isDragging = {snapshot.isDragging}
                                                                                            isDragDisabled = {this.state.status === activeStatus?false:true}
                                                                                            themeSettings={themeSettings}
                                                                                        >
                                                                                            <p style={{width:"5%",justifyContent:"center"}}>{(idx+1)}</p>
                                                                                            <p style={{width:"5%"}}>
                                                                                                {/* {ifEmptyReturnEmptyStr(item.riskLevel,"-")} */}
                                                                                                {
                                                                                                    isEmptyVariable(item.riskLevel) ? "-" : (
                                                                                                    <div className="flex-center-layout" >
                                                                                                        {isEmptyVariable(item.colorCode) ? item.riskLevel:""}
                                                                                                        
                                                                                                        {
                                                                                                            !isEmptyVariable(item.colorCode) &&
                                                                                                            <div
                                                                                                                className="risk-level-color-wrapper-round"
                                                                                                                style={{ width: "38px",marginLeft: (!isEmptyVariable(item.colorCode) ?0:8)}}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="risk-level-color-round"
                                                                                                                    style={{ background: item.colorCode }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                )}
                                                                                            </p>
                                                                                            <p style={{width:"65%"}}>
                                                                                                {/* {removeHtmlTags(item.task)} */}
                                                                                                <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: item.task}} />
                                                                                                {
                                                                                                    this.state.status === "Under Review" &&
                                                                                                    <span style={spanObj.spanStyle} className="span-tag">
                                                                                                        {spanObj.spanText}
                                                                                                    </span>
                                                                                                }
                                                                                            </p>
                                                                                            <p style={{width:"20%"}}>{item.assignmentTypeName}</p>
                                                                                            <p style={{width:"5%",justifyContent:"center"}}>
                                                                                                {
                                                                                                    actionObj.actionArr && actionObj.actionArr.length > 0 &&
                                                                                                    this.state.menus[Constants.SCREEN_TASKS] ==="ALL" &&
                                                                                                    <TableDropDown 
                                                                                                        actionArr={actionObj.actionArr}
                                                                                                        onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                                                        dropDownId = {item}
                                                                                                        dropdownWidth={actionObj.dropdownWidth}
                                                                                                        themeSettings={themeSettings}
                                                                                                    />
                                                                                                }
                                                                                            </p>
                                                                                        </CustomTableRow>
                                                                                    )}
                                                                                </Draggable>
                                                                            })
                                                                        }
                                                                        {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                    </Droppable>
                                                                </div>

                                                            </div>
                                                            </DragDropContext>
                                                        </div>
                                                    }
                                                    <div className="loadmore-layout">
                                                        <div>
                                                        {
                                                            processObj.currentPageNo*this.state.resultSize < processObj.count &&
                                                            <LoadMoreButton 
                                                            themeSettings = {themeSettings}
                                                            onClick={this.onChangePage.bind(this,processObj.processId)} type="button">Load More</LoadMoreButton>
                                                        }
                                                        </div>
                                                        {
                                                            processObj.count > 0 &&
                                                            <p>{"Total Records: "+processObj.count}</p>
                                                        }
                                                        
                                                    </div>
                                                

                                                    {
                                                        this.state.componentDidMountFlag && isEmptyArray(processObj.taskList) &&
                                                        <div class="no-items-layout">
                                                            <div class="no-items-card" style={{padding:"10px"}}>
                                                                <h6 style={{margin:0}}>
                                                                {
                                                                    isEmptyVariable(this.state.apiSearchKey)?
                                                                    Constants.NO_RECORDS_WARNING
                                                                    :
                                                                    Constants.EMPTY_SEARCH_WARNING
                                                                }
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            })
                                            
                                        }
                                    </div>
                                }
                                {
                                    this.state.showTaskDetailsLayout &&
                                    <TaskDetailsLayout
                                        taskId = {this.state.selectedTaskIdForDetails}
                                        assignmentTypeId={this.state.assignmentTypeId}
                                        handleHideTaskDetailsLayout = {this.handleHideTaskDetailsLayout}
                                        subscriptionType={this.state.user.subscriptionType}
                                        prevTaskId={this.state.prevTaskId}
                                        nextTaskId={this.state.nextTaskId}
                                        isEdit={true}
                                        handleEditTask={this.handleEditTask}
                                        handleShowTaskDetailsLayout={this.handleShowTaskDetailsLayout}
                                        selectedDeptId={this.state.selectedDeptId}
                                        allTaskIds={this.state.allTaskIds}
                                    />
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                (this.state.operationType === "Review Remarks")
                                ?
                                Constants.ALERT_TYPE_ALERT
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            showLoader={this.state.showBtnLoader}
                            themeSettings={themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterType = {this.state.deleteReactivateMasterType}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        />

                        <ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {"Task"}
                            masterDataId = {this.state.deleteReactivateId}
                        />

                        <SelecAssignmentTypeDialog
                            showOtherMasterDialog={this.state.showSelectAssigmentTypeDialog}
                            handleSelectOtherMasterDialogClose={this.handleSelectAssignmentTypeDialogClose}
                            handleSelectOtherMasterDialogSaveClose={this.handleSelectAssignmentTypeDialogSaveClose}
                            selectedAssigmentTypeId={this.state.selectedAssigmentTypeId}
                            masterType={"Assignment Type"}
                            themeSettings = {themeSettings}
                        />
                    </section>
                </main>
            </Router>
        );
    }
}

export default OtherMasters;