import React from "react";
import { Modal } from "react-bootstrap";
import {
  isEmptyVariable,
  getThemeSettingsVariables,
} from "../Global/commonFunctions";
import {
  AlertDialogSpan,
  DialogProceedButton,
  TableRadioButton,
  FilterCheckBox,
} from "../Global/globalStyles.style";
import * as Constants from "../Global/constants";
import { toast } from "react-toastify";
import Excel from 'exceljs';
const themeSettings = getThemeSettingsVariables();

export default class AlertWithRadioDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: "",
      cols: "",
      formErrors: {},
      isDisableSelected: false,
      selectedRadioBox: "",
      showUploadFileRules: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps) !== JSON.stringify(this.props) &&
      this.props.showAlertDialog
    ) {
      this.setState({
        rows: "",
        cols: "",
        formErrors: {},
        selectedRadioBox: "",
        isDisableSelected: false,
        showUploadFileRules: false
      });
    }
  }

  handleChange = (e) => {
    let name = e.target.name;
    this.setState({ [name]: e.target.value });
  };

  handleProceedButtonClick = () => {
    let formErrors = {};
    let isError = false;

    if (isEmptyVariable(this.state.cols)) {
      formErrors.columns = "Please enter column count";
      isError = true;
    }

    if (isEmptyVariable(this.state.rows)) {
      formErrors.rows = "Please enter row count";
      isError = true;
    }

    if (!isEmptyVariable(this.state.cols)) {
      if (this.state.rows > 15) {
        formErrors.rows = "Maximum 15 rows allowed.";
        isError = true;
      }
    }

    if (!isEmptyVariable(this.state.cols)) {
      if (this.state.cols > 7) {
        formErrors.columns = "Maximum 7 columns allowed.";
        isError = true;
      }
    }

    if (!isEmptyVariable(this.state.cols)) {
      var regexp = new RegExp(/^[0-9]*$/);
      var valueInt = parseInt(this.state.cols);
      if (!regexp.test(valueInt)) {
        isError = true;
        formErrors.columns = "Please enter a valid number";
      }
    }

    if (!isEmptyVariable(this.state.rows)) {
      var regexp = new RegExp(/^[0-9]*$/);
      var valueInt = parseInt(this.state.rows);
      if (!regexp.test(valueInt)) {
        isError = true;
        formErrors.rows = "Please enter a valid number";
      }
    }

    if (isError) {
      this.setState({
        formErrors: formErrors,
        showLoader: false,
      });

      setTimeout(
        function () {
          this.setState({
            formErrors: {},
          });
        }.bind(this),
        Constants.WRNG_MSG_TIMEOUT
      );
    } else {
      this.props.proceedBtnClick(this.state.rows, this.state.cols);
    }
  };

  // Function to handle Excel file upload
  handleExcelFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xlsx, .xls';
    input.click();

    input.addEventListener('change', (e) => {
      var excelfile = e.target.files[0];
      if (excelfile) {
        const allowExcelFile = ['xls', 'xlsx'];
        const fileExtension = e.target.files[0]?.name.split('.').pop().toLowerCase();
        // Check if the file is an allowed Excel file format
        if (!allowExcelFile.includes(fileExtension)) {
          e.target.value = null;
          toast.warning("Please upload a excel file.");
          return;
        }else{
          const workbook = new Excel.Workbook();
          workbook.xlsx.load(excelfile).then(() => {
            const worksheet = workbook.getWorksheet(1);
            let rowCount = 0;
            let excelData = [];
            let isFormulaContain = false;
            let columnCount = 0;

            worksheet.eachRow((row) => {
              // Check if the row has any values
              if (row.hasValues) {
                rowCount++;
                if (rowCount === 1) {
                  columnCount = row.actualCellCount;
                }
              }

              let rowData = [];
              row.eachCell({ includeEmpty: true }, (cell, colIndex, rowIndex,index) => {
                if (typeof cell.value === "object" && cell.value?.formula) {
                  isFormulaContain = true;
                }else if(rowCount <= 16 || columnCount <= 7){
                  rowData.push(cell.value)
                }
              })
              excelData.push(rowData);
            })
            if(rowCount > 16 || columnCount > 7){
                toast.warning("You can upload excel with data in 7 columns and 15 rows at max. In case the data is more, upload the file in annexure.");
            }else if(isFormulaContain){
                toast.warning("Do not upload file with a formula.");
            }else{
              this.props.proceedBtnClick(rowCount, columnCount, excelData);
            }
          })
        }
      }
    })
  }

  /// Akshay's Code Start Here

  /// This Method Handle Checkbox Input for Execute Table.
  handleCheckBoxClick = (e) => {
    if (e.target.name === "YN") {
      this.setState({
        selectedRadioBox: "YN",
      });
    } else if (e.target.name === "YNNA") {
      this.setState({
        selectedRadioBox: "YNNA",
      });
    } else if (e.target.name === "TEXTFIELD") {
      this.setState({
        selectedRadioBox: "TEXTFIELD",
      });
    } else if (e.target.name === "DIS") {
      this.setState({
        isDisableSelected: !this.state.isDisableSelected,
      });
    }
  };

  /// This Method Checks all Validation of Radio Button, Show Error Message And save data For Execute Table.
  handleProceedButtonClickExec = () => {
    let formErrors = {};
    let isError = false;
    if (isEmptyVariable(this.state.rows)) {
      formErrors.rows = "Please enter row count";
      isError = true;
    }
    if (!isEmptyVariable(this.state.rows)) {
      var regexp = new RegExp(/^[0-9]*$/);
      var valueInt = parseInt(this.state.rows);
      if (!regexp.test(valueInt)) {
        isError = true;
        formErrors.rows = "Please enter a valid number";
      }
    }
    if (this.state.selectedRadioBox === "") {
      formErrors.checkBoxErr = "Please select First Row Type";
      isError = true;
    }

    if (isError) {
      this.setState({
        formErrors: formErrors,
        showLoader: false,
      });

      setTimeout(
        function () {
          this.setState({
            formErrors: {},
          });
        }.bind(this),
        Constants.WRNG_MSG_TIMEOUT
      );
    } else {
      this.props.proceedBtnClick(
        this.state.rows,
        this.state.selectedRadioBox,
        this.state.isDisableSelected
      );
    }
  };

  /// Akshay's Code End Here

  render() {
    return (
      <Modal
        show={this.props.showAlertDialog}
        backdrop="static"
      >
        <Modal.Body>
          <div class="dialog-layout">
            <button onClick={this.props.handleAlertDialogClose}>
              <span className="material-icons">close</span>
            </button>
            <AlertDialogSpan
              themeSettings={this.props.themeSettings}
              className="material-icons"
            >
              error_outline
            </AlertDialogSpan>
            {
              // !this.props.type === Constants.ALERT_TYPE_ALERT &&
              !isEmptyVariable(this.props.alertDialogHeading) && (
                <h5>{this.props.alertDialogHeading}</h5>
              )
            }
            <p style={{ marginBottom: this.props.showRadio ? 10 : 15 }}>
              {this.props.alertDialogMessage}
            </p>
            <div className="row custom-row">
              <div className="col-md-6 custom-col">
                <div class="addnew-modal-form">
                  <div class="addnew-modal-form-group">
                    <p style={{ textAlign: "left" }}>Rows</p>
                    <input
                      type="text"
                      placeholder="Enter Number of Rows"
                      name="rows"
                      value={this.state.rows}
                      onChange={this.handleChange}
                    />
                    {!isEmptyVariable(this.state.formErrors["rows"]) && (
                      <p className="cm-error" style={{ textAlign: "left" }}>
                        {this.state.formErrors["rows"]}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {this.props.isUsedForExec !== "Y" && (
                <div className="col-md-6 custom-col">
                  <div class="addnew-modal-form">
                    <div class="addnew-modal-form-group">
                      <p style={{ textAlign: "left" }}>Columns</p>
                      <input
                        type="text"
                        placeholder="Enter Number of Columns"
                        name="cols"
                        value={this.state.cols}
                        onChange={this.handleChange}
                      />
                      {!isEmptyVariable(this.state.formErrors["columns"]) && (
                        <p className="cm-error" style={{ textAlign: "left" }}>
                          {this.state.formErrors["columns"]}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Akshay's Code Start Here */}
            {this.props.isUsedForExec === "Y" && (
              <div className="row custom" style={{ textAlign: "left" }}>
                <div class=" mx-3 col-l">
                  <label style={{ fontWeight: "600", fontSize: 12 }}>
                    First Row Type:
                  </label>
                  {!isEmptyVariable(this.state.formErrors["checkBoxErr"]) && (
                    <p
                      className="cm-error"
                      style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      {this.state.formErrors["checkBoxErr"]}
                    </p>
                  )}
                  <CreateRadioBoxComp
                    item={"TEXTFIELD"}
                    selectedRadioBox={this.state.selectedRadioBox}
                    label={"Text Field"}
                    handleCheckBoxClick={this.handleCheckBoxClick}
                  />
                  <CreateRadioBoxComp
                    item={"YN"}
                    selectedRadioBox={this.state.selectedRadioBox}
                    label={"First row is a dropdown with Yes and No options"}
                    handleCheckBoxClick={this.handleCheckBoxClick}
                  />
                  <CreateRadioBoxComp
                    item={"YNNA"}
                    selectedRadioBox={this.state.selectedRadioBox}
                    label={"First row is a dropdown with Yes,No and NA options"}
                    handleCheckBoxClick={this.handleCheckBoxClick}
                  />

                  {(this.state.selectedRadioBox === "YN" ||
                    this.state.selectedRadioBox === "YNNA") && (
                    <FilterCheckBox
                      themeSettings={themeSettings}
                      style={{ marginLeft: 0, marginBottom: 10 }}
                    >
                      <input
                        type="checkbox"
                        name={"DIS"}
                        id={"DIS"}
                        onChange={this.handleCheckBoxClick}
                        checked={this.state.isDisableSelected ? true : false}
                      />
                      <label for={"DIS"}>
                        Disable the fields when Yes option is not Selected
                      </label>
                    </FilterCheckBox>
                  )}
                </div>
              </div>
            )}
            {/* Akshay's Code End Here */}

            <div class="dialog-btn-layout">
              <a
                class="alert-cancel"
                href="javascript:void(0);"
                onClick={this.props.handleAlertDialogClose}
              >
                Cancel
              </a>
              {this.props.isUsedForExec !== "Y" &&
                <DialogProceedButton
                  themeSettings={this.props.themeSettings}
                  class="alert-proceed"
                  href="javascript:void(0);"
                  onClick={this.handleExcelFile}
                  style={{
                    marginLeft:
                      this.props.type === Constants.ALERT_TYPE_ALERT
                        ? "0px"
                        : "10px",
                  }}
                >
                  Upload Excel
                </DialogProceedButton>
              }
              <DialogProceedButton
                themeSettings={this.props.themeSettings}
                class="alert-proceed"
                href="javascript:void(0);"
                onClick={
                  this.props.isUsedForExec !== "Y"
                    ? this.handleProceedButtonClick
                    : this.handleProceedButtonClickExec
                }
                style={{
                  marginLeft:
                    this.props.type === Constants.ALERT_TYPE_ALERT
                      ? "0px"
                      : "10px",
                }}
              >
                {this.props.proceedBtnLabel}
              </DialogProceedButton>
            </div>
            {this.props.isUsedForExec !== "Y" &&
              <>
                <div class="mt-2">
                  <a
                    href='javascript:void(0);'
                    onClick={() => {
                      this.setState({
                        showUploadFileRules: !this.state.showUploadFileRules
                      })
                    }
                  }
                    style={{ fontSize: "12px", color: "#000", wordWrap: "break-word", borderBottom: '1px solid #000'}}
                  >
                    Read Upload File Rules
                  </a>
                </div>
                {this.state.showUploadFileRules &&
                  <div className="text-left mt-2">
                    <ul>
                      <li>The header of the data should be in the first row i.e. Row 1.</li>
                      <li>Data should start from row number 2.</li>
                      <li>The data should start from first column i.e. Column A.</li>
                      <li>There should not be any empty line between the data.</li>
                      <li>There should not be any formula in the excel sheet.</li>
                      <li>There should not be any merged cells.</li>
                      <li>If still upload does not work, then copy the data and paste it in new file and upload it.</li>
                      <li>Maximum 7 columns and 15 rows allowed</li>
                    </ul>
                  </div>
                }
              </>
            }
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

/// This class Return TableRadioButton Component
/// Akshay's code
export class CreateRadioBoxComp extends React.Component {
  render() {
    return (
      <TableRadioButton
        themeSettings={themeSettings}
        style={{ marginLeft: 0, marginBottom: 10 }}
      >
        <input
          type="checkbox"
          name={this.props.item}
          id={this.props.item}
          onChange={this.props.handleCheckBoxClick}
          checked={
            this.props.selectedRadioBox === this.props.item ? true : false
          }
        />
        <label for={this.props.item}>{this.props.label}</label>
      </TableRadioButton>
    );
  }
}
