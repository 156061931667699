import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {TableRadioButton,DialogSaveButton,TableCheckBox, FilterCheckBox} from '../../../common/Global/globalStyles.style';
import {Modal} from 'react-bootstrap';
import * as TemplateConstants from "../../../common/Global/templateConstants";
import { toast } from 'react-toastify';
import EditTaskDescriptionDialog from "./editTaskDescriptionDialog";

class SelectDropdownValues extends Component{
    
    constructor(props) {
        super(props);

        this.state = {
            dropdownValues:[],
            value_NR:"N",
            showDescriptionDialog:false
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) 
        && this.props.showSelectDropdownDialog) {
            let fieldObj = JSON.parse(JSON.stringify(this.props.fieldObj));
            let dropdownValues = [...fieldObj.dropdownValues];

            if(fieldObj.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT){
                //now check for field Value
                if(!isEmptyVariable(fieldObj.fieldValue)){
                    let dropdownValuesNew = [];
                    dropdownValues.forEach(item=>{
                        if(item.value === fieldObj.fieldValue){
                            item.isChecked = "Y";
                        }else{
                            item.isChecked = "N";
                        }
                        dropdownValuesNew.push(item)
                    })
                    dropdownValues = dropdownValuesNew;
                }
            }else if(fieldObj.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT){
                if(!isEmptyArray(fieldObj.fieldValue)){
                    let dropdownValuesNew = [];
                    dropdownValues.forEach(dropdownItem=>{
                        let flag = fieldObj.fieldValue.some(item=>item === dropdownItem.value)
                        if(flag){
                            dropdownItem.isChecked = "Y";
                        }else{
                            dropdownItem.isChecked = "N";
                        }
                        dropdownValuesNew.push(dropdownItem)
                    })
                    dropdownValues = dropdownValuesNew;
                }
            }

            this.setState({
                dropdownValues:dropdownValues,
                value_NR:fieldObj.fieldValueNR === "Y"?"Y":"N"
            })
        }
    }

    handleSelectionRadio = (e) => {
		let dropdownIndex = e.target.name;

        let newDropdownValues = this.state.dropdownValues.map((item,index)=>{
            if(index+"" === dropdownIndex+""){
                item.isChecked = "Y"
            }else{
                item.isChecked = "N"
            }

            return item;
        })
        this.setState({
            dropdownValues:newDropdownValues
        })

    }

    handleSelectionCheckbox = (e) => {
        let isChecked = e.target.checked;
		let dropdownIndex = e.target.name;

        let newDropdownValues = this.state.dropdownValues.map((item,index)=>{
            if(index+"" === dropdownIndex+""){
                item.isChecked = isChecked?"Y":"N"
            }
            return item;
        })
        
        this.setState({
            dropdownValues:newDropdownValues
        })
    }

    returnSelectedValues = () => {
        let selectedValues = [];
        this.state.dropdownValues.forEach(item=>{
            if(item.isChecked === "Y"){
                selectedValues.push(item.value) 
            }
        })

        let error = false;
        // let errorMsg = "";
        if(isEmptyArray(selectedValues) && this.state.value_NR !== "Y"){
            error = true;
            // errorMsg = "Please select the value";
        }
        
        if(error){
            toast.warning("Please select the value");
            // this.setState({
            //     selectErr:errorMsg
            // })

            // setTimeout(() => {
            //     this.setState({
            //         selectErr:"",
            //     });
            // },Constants.WRNG_MSG_TIMEOUT);
        }else{
            let returnArr =  {
                fieldType:this.props.fieldObj.fieldType,
                selectedValues:selectedValues
            }
            //TODO
            //Check for empty selection
            this.props.dialogSelectedValues(returnArr,this.props.fieldArrIndex,this.state.value_NR);
        }
    }

    handleCheck = (e) =>{
        let isChecked = e.target.checked;

        let newDropdownValues = this.state.dropdownValues.map((item,index)=>{
            item.isChecked = "N";
            return item;
        })

        this.setState({
            value_NR:isChecked?"Y":"N",
            dropdownValues:newDropdownValues
        })
    }

    handleDescription = (item) => {
        this.setState({
            showDescriptionDialog:true,
            selectedItem:item
        })
    }

    handleDescriptionDialogClose = () => {
        this.setState({
            showDescriptionDialog:false,
            selectedItem:{}
        })
    }

    render(){
        return(
            <section>
                <Modal className="task-select-dept-dialog custom-dialog"
                    show={this.props.showSelectDropdownDialog} backdrop="static">
                    <Modal.Header>
                        <h5>{this.props.fieldObj?.fieldName}{this.props.fieldObj?.isMandatorySelected === "Y"?"*":""}</h5>
                        <button 
                            onClick={this.props.handleSelectDropdownDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        <div className="body-wrapper">
                            {
                                this.props.fieldObj?.isNotReqSelected === "Y" &&
                                <div className='exec-not-req-layout'>
                                    <FilterCheckBox themeSettings={this.props.themeSettings}
                                    style={{marginLeft:0}}>
                                        <input 
                                            type="checkbox"
                                            name="value_NR"
                                            id="value_NR"
                                            onChange={this.handleCheck}
                                            checked={this.state.value_NR === "Y"?true:false}
                                            readOnly={this.props.assignmentTaskAllowEdit === "Y" ? false : true}
                                        />
                                        <label for="value_NR">Not Applicable</label>
                                    </FilterCheckBox>
                                </div>
                            }
                            <div style={{position:"relative"}}>
                                {
                                    !isEmptyArray(this.state.dropdownValues) && 
                                    <div className="common-tab-bg">
                                        <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" width={"8%"}></th>
                                                        <th className="text-center" width={"5%"}>No</th>
                                                        <th>Value</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    !isEmptyArray(this.state.dropdownValues) && 
                                                    this.state.dropdownValues.map((item,idx) => {

                                                        return <tr>
                                                            <td>
                                                                {
                                                                    this.props.fieldObj?.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT &&
                                                                    <TableRadioButton
                                                                        themeSettings={this.props.themeSettings}>
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={idx}
                                                                            id={idx}
                                                                            onChange={this.handleSelectionRadio}
                                                                            checked={item.isChecked === "Y"}
                                                                            disabled={this.props.viewOnly === "Y" && this.props.assignmentTaskAllowEdit === "N" ?true:false}
                                                                        />
                                                                        <label for={idx} 
                                                                        style={{marginLeft:6}}></label>
                                                                    </TableRadioButton>
                                                                }

                                                                {
                                                                    this.props.fieldObj?.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT &&
                                                                    <TableCheckBox themeSettings={this.props.themeSettings}>
                                                                    <input
                                                                        type="checkbox"
                                                                        name={idx}
                                                                        id={idx}
                                                                        onChange={this.handleSelectionCheckbox}
                                                                        checked={item.isChecked === "Y"}
                                                                        disabled={this.props.viewOnly === "Y"?true:false}
                                                                    />
                                                                    <label for={idx} style={{ marginLeft: 6 }}></label>
                                                                </TableCheckBox>
                                                                }
                                                            </td>
                                                            <td className="text-center">{(idx+1)}</td>
                                                            <td>{item.value}</td>
                                                            <td onClick={item.value == "Other" && this.props.assignmentTaskAllowEdit !== "N" && this.props.isEditDescription ? this.handleDescription.bind(this,item): ""}>{isEmptyVariable(item.description)?"-":item.description}</td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                                <div className="overlay-nr" style={{display:this.state.value_NR === "Y"?"block":"none"}}>
                                </div>
                            </div>
                            {
                                isEmptyArray(this.state.dropdownValues) &&
                                <div class="no-items-layout">
                                    <div class="no-items-card">
                                        <h6>{Constants.EMPTY_SEARCH_WARNING}</h6>
                                    </div>
                                </div>
                            }
                            {/* {
                                !isEmptyVariable(this.state.selectErr) &&
                                <span className="cm-error">{this.state.selectErr}</span>
                            } */}
                            <div className="modal-close-save m-t-15">
                                <div className="close-save-col">
                                    <button onClick={this.props.handleSelectDropdownDialogClose} type="button" 
                                    className="modal-close m-b-0">Cancel</button>
                                    
                                    {
                                        this.props.assignmentTaskAllowEdit !== "N" &&
                                        <DialogSaveButton  
                                        themeSettings={this.props.themeSettings} 
                                        onClick={this.props.viewOnly === "Y"?this.props.handleSelectDropdownDialogClose:this.returnSelectedValues} 
                                        type="button" 
                                        className="modal-save m-b-0">
                                            Save
                                        </DialogSaveButton>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <EditTaskDescriptionDialog
                    showDescriptionDialog={this.state.showDescriptionDialog}
                    handleDescriptionDialogClose={this.handleDescriptionDialogClose}
                    themeSettings={this.props.themeSettings}
                    item={this.state.selectedItem}
                />
            </section>
        )
    }

}

export default SelectDropdownValues;