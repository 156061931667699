import React, { Component } from 'react';

import Login from './components/pages/Login/loginpage';
import ResetPassword from './components/pages/Login/resetpassword';
import ChangePasswordFirstTimeLogin from './components/pages/Login/changePasswordFirstLogin';
import Profile from './components/pages/Login/profile';
import Notifications from './components/pages/Login/notifications';
import Logout from './components/pages/Logout';
import Dashboard from './components/pages/Dashboard';

import ListCompanies from './components/pages/Companies/companiesmain';
import CityMasterMain from './components/pages/CityMaster/cityMasterMain';
import ListUnits from './components/pages/Companies/unitsmain';
import SearchCity from './components/pages/CityMaster/cityDialog';

import ListClients from './components/pages/Clients/clientsmain';
import AddClient from './components/pages/Clients/addclient';
import EditClient from './components/pages/Clients/editclient';

import ListUsers from './components/pages/UserManagement/listusers';

import ListUserRoles from './components/pages/UserManagement/listuserroles';
import AddUserRole from './components/pages/UserManagement/adduserrole';
import EditUserRole from './components/pages/UserManagement/edituserrole';

import UserDepartments from './components/pages/UserManagement/listuserdepartment';
import Managers from './components/pages/UserManagement/listmanagers';
import GeneralMasters from './components/pages/Masters/GenaralMasters/listgeneralmasters';
import OtherMasters from './components/pages/Masters/OtherMasters/listothermasters';
import FunctionsGrid from './components/pages/Masters/Functions/listfunctionmasters';
import IndustryGrid from './components/pages/Masters/Industry/listindustrymasters';
import ProcessGrid from './components/pages/Masters/Process/listprocessmasters';
import DocumentMaster from './components/pages/Masters/DocumentMaster/documentsmain';
import RiskMaster from './components/pages/Masters/Risk/listriskmaster';

import Tasks from './components/pages/Tasks/listprocessmasters'
// import AddTask from './components/pages/Tasks/addNewTask'
import AddTaskWithTemplates from './components/pages/Tasks/addNewTaskWithTemplates';
import ViewTask from './components/pages/Tasks/processTaskList'
import ViewFunctionTask from './components/pages/Tasks/functionTaskList'
import ListDrafts from './components/pages/Tasks/listDraftTasks'
import ListUnderReview from './components/pages/Tasks/listUnderReviewTasks'

import ChecklistMain from './components/pages/CheckList/checklistMain';

import AnnualPlanning from './components/pages/Planning/Annual/listassignments';
import ScheduledAssignments from './components/pages/Planning/Scheduled/listassignments';

import ExecuteAssignmentList from './components/pages/Execute/assignmentList';
import ExecuteTaskList from './components/pages/Execute/executeTaskList';
import ExecuteObservationList from './components/pages/Execute/Observation/observationList';
import AddObservationWithTemplate from './components/pages/Execute/Observation/addObservationWithTemplates';
// import AddObservation from './components/pages/Execute/Observation/addObservationWithTemplates';
import SampleCheckedList from './components/pages/Execute/SampleChecked/sampleCheckedList';
import WorkingPapersList from './components/pages/Execute/WorkingPapers/workingPapersList';
import AssignmentEmployee from './components/pages/Execute/auditee/assignmentEmployee';
import Timeline from './components/pages/Execute/Timeline/timeline';

import CompletedAssignmentReport from './components/pages/Reports/Completed/completedAssignmentReport';
import DraftAssignmentReport from './components/pages/Reports/Drafts/draftsAssignmentReport';
import OngoingAssignmentReport from './components/pages/Reports/Ongoing/ongoingAssignmentReport';
import OngoingObservations from './components/pages/Reports/HeadingTree/headingTree';
import ongoingAssignmentEmployee from './components/pages/Reports/Common/assignmentEmployee';
import DraftObservations from './components/pages/Reports/HeadingTree/headingTreeDraft';
import FinalObservations from './components/pages/Reports/HeadingTree/headingTreeFinal';
import EditDraftObservation from './components/pages/Reports/Drafts/editDraftObservation';
import EditOngoingObservation from './components/pages/Reports/Ongoing/editOngoingObservationWithTemplates';
import OngoingSampleChecked from './components/pages/Reports/Common/SampleChecked/sampleCheckedList';
import OngoingWorkingPapers from './components/pages/Reports/Common/WorkingPapers/workingPapersList';
import OngoingTimeLine from './components/pages/Reports/Common/Timeline/timelineList';
import DraftSampleChecked from './components/pages/Reports/Common/SampleChecked/sampleCheckedList';
import DraftWorkingPapers from './components/pages/Reports/Common/WorkingPapers/workingPapersList';
import DraftTimeLine from './components/pages/Reports/Common/Timeline/timelineList';
import FinalTimeLine from './components/pages/Reports/Common/Timeline/timelineList';
import draftAssignmentEmployee from './components/pages/Reports/Common/assignmentEmployee';
import FinalSampleChecked from './components/pages/Reports/Common/SampleChecked/sampleCheckedList';
import FinalWorkingPapers from './components/pages/Reports/Common/WorkingPapers/workingPapersList';
import finalAssignmentEmployee from './components/pages/Reports/Common/assignmentEmployee';
import ViewReport from './components/pages/Reports/Common/ViewReport';
import ViewTabularReport from './components/pages/Reports/Common/ViewTabularReport';

import TaskTemplateList from './components/pages/Templates/Common/templateList';
import EditTaskTemplate from './components/pages/Templates/Task/editTaskTemplate';
import ExecuteTaskTemplateList from './components/pages/Templates/Common/templateList';
import AddExecTaskTemplate from './components/pages/Templates/ExecuteTask/addExecTaskTemplate';
import ObservationTemplateList from './components/pages/Templates/Common/templateList';
import AddObservationTemplate from './components/pages/Templates/Observation/addEditObservationTemplate';
import FolloUpTemplateList from './components/pages/Templates/Common/templateList';
import AuditeeCommentTemplateList from './components/pages/Templates/Common/templateList';
import EditAuditeeCommentTemplateList from './components/pages/Templates/AuditeeComment/editAuditeeComment';
import OverallAssignment from './components/pages/Execute/OverallAssignment/overallAssignment';


import departmentSettings from './components/pages/Settings/departmentSettings';
import clientSettings from "./components/pages/Settings/clientSetting"
// import ComingSoon from './components/pages/Masters/Common/comingsoon';
import FollowUpAssignment from './components/pages/Planning/FollowUp/listFollowUpAssignment';
import ExecuteFollowUpAssignment from './components/pages/Execute/FollowUp/listFollowUpAssignment';
import FollowUpTask from './components/pages/Execute/FollowUp/followUpTaskList';
import FollowUpObservation from './components/pages/Execute/FollowUp/followUpObservation';
import CompletedFollowUpObservation from './components/pages/Reports/Completed/FollowUp/CompletedFollowUpObservation';
import CompletedFollowUpTask from './components/pages/Reports/Completed/FollowUp/CompletedFollowUpTask';
import  OngoingOverallAssignment from './components/pages/Reports/Common/OverallAssignment/ongoingOverallAssignment'
import  DraftOverallAssignment from './components/pages/Reports/Common/OverallAssignment/draftOverallAssignment'
import  FinalizedOverallAssignment from './components/pages/Reports/Common/OverallAssignment/finalizedOverallAssignment'
import  InitDraftOverallAssignment from './components/pages/Reports/Common/OverallAssignment/initDraftOverallAssignment'
import OverallTemplateList from './components/pages/Templates/Common/templateList';
import EditOverallTemplate from './components/pages/Templates/Overall/overallTemplate';
import AdminSettings from './components/pages/Settings/adminSetting';
import {
    BrowserRouter as Router,
    Route,Redirect
} from 'react-router-dom';
import { getThemeSettingsVariables } from './components/common/Global/commonFunctions';
import AddEditFollowUpTemplates from './components/pages/Templates/Followup/editFollowupTemplate';
const themeSettings = getThemeSettingsVariables();
const ProtectedRoute = ({ path, component: Comp }) => {
    return (
        <Route

            path={path}

            render={(props) => {
                return localStorage.getItem('loggedInUserDetails')
                ? (
                    <Comp {...props} />
                ) : (
                    <Redirect
                        to={{
                        pathname: "/"}}
                    />
                );
            }}
        />
    );
};

class App extends Component {
    componentDidMount () {
        document.body.style.setProperty("--theme-color", `rgb(${themeSettings.themeColor.r}, ${themeSettings.themeColor.g}, ${themeSettings.themeColor.b}, ${themeSettings.themeColor.a})`);
        document.body.style.setProperty("--theme-hover-color", `rgb(${themeSettings.themeHoverColor.r}, ${themeSettings.themeHoverColor.g}, ${themeSettings.themeHoverColor.b}, ${themeSettings.themeHoverColor.a})`);
    }

    render() {
        return (
            <Router>
                <Route exact path="/" component={Login} />
                <Route exact path="/resetpassword/:id" component={ResetPassword} />
                <Route exact path="/changepassword" component={ChangePasswordFirstTimeLogin} />
                <ProtectedRoute exact path="/dashboard" component={Dashboard}/>
                <ProtectedRoute exact path="/profile" component={Profile}/>
                <ProtectedRoute exact path="/notifications" component={Notifications}/>

                <ProtectedRoute exact path="/clients" component={ListCompanies}/>
                <ProtectedRoute exact path="/cityMaster" component={CityMasterMain}/>
                <ProtectedRoute exact path="/units" component={ListUnits}/>
                <ProtectedRoute exact path="/searchcity" component={SearchCity}/>

                <ProtectedRoute exact path="/listclients" component={ListClients}/>
                <ProtectedRoute exact path="/addclient" component={AddClient}/>
                <ProtectedRoute exact path="/editclient/:id" component={EditClient}/>

                <ProtectedRoute exact path="/users" component={ListUsers}/>
                <ProtectedRoute exact path="/userroles" component={ListUserRoles}/>
                <ProtectedRoute exact path="/adduserrole" component={AddUserRole}/>
                <ProtectedRoute exact path="/userdepartments" component={UserDepartments}/>
                {/* <ProtectedRoute exact path="/managers" component={Managers}/> */}
                <ProtectedRoute exact path="/edituserrole/:id" component={EditUserRole} />

                <ProtectedRoute exact path="/processes" component={ProcessGrid} />
                <ProtectedRoute exact path="/functions" component={FunctionsGrid} />
                <ProtectedRoute exact path="/industries" component={IndustryGrid} />
                <ProtectedRoute exact path="/documents" component={DocumentMaster} />
                <ProtectedRoute exact path="/risk" component={RiskMaster} />
                <ProtectedRoute exact path="/othermasters" component={OtherMasters}/>
                <ProtectedRoute exact path="/generalmasters" component={GeneralMasters}/>

                <ProtectedRoute exact path="/tasks" component={Tasks}/>
                <ProtectedRoute exact path="/drafttasks" component={ListDrafts}/>
                {/* <ProtectedRoute exact path="/addtask/:id" component={AddTask}/> */}
                <ProtectedRoute exact path="/viewtasks/:id" component={ViewTask}/>
                <ProtectedRoute exact path="/viewfunctiontasks/:id" component={ViewFunctionTask}/>
                {/* <ProtectedRoute exact path="/edittask/:id" component={AddTask}/> */}
                <ProtectedRoute exact path="/underreviewtasks" component={ListUnderReview}/>

                <ProtectedRoute exact path="/checklist" component={ChecklistMain}/>
                <ProtectedRoute exact path="/assignments" component={AnnualPlanning}/>
                <ProtectedRoute exact path="/scheduledassignments" component={ScheduledAssignments}/>
                <ProtectedRoute exact path="/followups" component={FollowUpAssignment}/>


                <ProtectedRoute exact path="/executeassignmentlist" component={ExecuteAssignmentList}/>
                <ProtectedRoute exact path="/executefollowups" component={ExecuteFollowUpAssignment}/>
                <ProtectedRoute exact path="/overallexecutetasklist/:id" component={OverallAssignment}/>
                <ProtectedRoute exact path="/followupstask/:id" component={FollowUpTask}/>
                <ProtectedRoute exact path="/followupsobservation/:id" component={FollowUpObservation}/>
                <ProtectedRoute exact path="/executetasklist/:id" component={ExecuteTaskList}/>
                <ProtectedRoute exact path="/observationlist/:id" component={ExecuteObservationList}/>
                <ProtectedRoute exact path="/addobservation/:id" component={AddObservationWithTemplate}/>
                <ProtectedRoute exact path="/editobservation/:id" component={AddObservationWithTemplate}/>
                <ProtectedRoute exact path="/samplecheckedlist/:id" component={SampleCheckedList}/>
                <ProtectedRoute exact path="/assignmentemployee/:id" component={AssignmentEmployee}/>
                <ProtectedRoute exact path="/timeline/:id" component={Timeline}/>

                <ProtectedRoute exact path="/completedassignmentreports" component={CompletedAssignmentReport}/>
                <ProtectedRoute exact path="/draftassignmentreports" component={DraftAssignmentReport}/>
                <ProtectedRoute exact path="/ongoingassignmentreports" component={OngoingAssignmentReport}/>
                <ProtectedRoute exact path="/ongoingsamplechecked/:id" component={OngoingSampleChecked}/>
                <ProtectedRoute exact path="/ongoingworkingpapers/:id" component={OngoingWorkingPapers}/>
                <ProtectedRoute exact path="/ongoingoverall/:id" component={OngoingOverallAssignment}/>
                <ProtectedRoute exact path="/ongoingtimeline/:id" component={OngoingTimeLine}/>
                <ProtectedRoute exact path="/draftsamplechecked/:id" component={DraftSampleChecked}/>
                <ProtectedRoute exact path="/draftworkingpapers/:id" component={DraftWorkingPapers}/>
                <ProtectedRoute exact path="/drafttimeline/:id" component={DraftTimeLine}/>
                <ProtectedRoute exact path="/draftoverall/:id" component={DraftOverallAssignment}/>
                <ProtectedRoute exact path="/initdraftoverall/:id" component={InitDraftOverallAssignment}/>
                <ProtectedRoute exact path="/finalizedoverall/:id" component={FinalizedOverallAssignment}/>
                <ProtectedRoute exact path="/finalsamplechecked/:id" component={FinalSampleChecked}/>
                <ProtectedRoute exact path="/finalworkingpapers/:id" component={FinalWorkingPapers}/>
                <ProtectedRoute exact path="/finaltimeline/:id" component={FinalTimeLine}/>
                <ProtectedRoute exact path="/initdrafttimeline/:id" component={FinalTimeLine}/>

                <ProtectedRoute exact path="/completedfollowupobservation/:id" component={CompletedFollowUpObservation}/>
                <ProtectedRoute exact path="/completedfollowuptask/:id" component={CompletedFollowUpTask}/>
                <ProtectedRoute exact path="/ongoingassignmentemployee/:id" component={ongoingAssignmentEmployee}/>
                <ProtectedRoute exact path="/draftassignmentemployee/:id" component={draftAssignmentEmployee}/>
                <ProtectedRoute exact path="/finalassignmentemployee/:id" component={finalAssignmentEmployee}/>
                <ProtectedRoute exact path="/initdraftassignmentemployee/:id" component={finalAssignmentEmployee}/>


                <ProtectedRoute exact path="/workingpaperslist/:id" component={WorkingPapersList}/>
                <ProtectedRoute exact path="/ongoingobservations/:id" component={OngoingObservations}/>
                <ProtectedRoute exact path="/draftobservations/:id" component={DraftObservations}/>
                <ProtectedRoute exact path="/finalizedobservations/:id" component={FinalObservations}/>
                <ProtectedRoute exact path="/initialdraftobservations/:id" component={FinalObservations}/>
                <ProtectedRoute exact path="/editdraftobservation/:id" component={EditDraftObservation}/>
                <ProtectedRoute exact path="/editongoingobservation/:id" component={EditOngoingObservation}/>
                
                <ProtectedRoute exact path="/viewreport/:id" component={ViewReport}/>
                <ProtectedRoute exact path="/viewtabularreport/:id" component={ViewTabularReport}/>
                
                <ProtectedRoute exact path="/executetasktemplates" component={ExecuteTaskTemplateList}/>
                <ProtectedRoute exact path="/addexectasktemplate/:id" component={AddExecTaskTemplate}/>
                <ProtectedRoute exact path="/editexectasktemplate/:id" component={AddExecTaskTemplate}/>
                <ProtectedRoute exact path="/exectasktemplatedetails/:id" component={AddExecTaskTemplate}/>
                <ProtectedRoute exact path="/tasktemplates" component={TaskTemplateList}/>
                <ProtectedRoute exact path="/edittasktemplate/:id" component={EditTaskTemplate}/>
                <ProtectedRoute exact path="/tasktemplatedetails/:id" component={EditTaskTemplate}/>
                <ProtectedRoute exact path="/observationtemplates" component={ObservationTemplateList}/>
                <ProtectedRoute exact path="/addobservationtemplate/:id" component={AddObservationTemplate}/>
                <ProtectedRoute exact path="/editobservationtemplate/:id" component={AddObservationTemplate}/>
                <ProtectedRoute exact path="/observationtemplatedetails/:id" component={AddObservationTemplate}/>
                <ProtectedRoute exact path="/followuptemplates" component={FolloUpTemplateList}/>
                <ProtectedRoute exact path="/editfollowuptemplate/:id" component={AddEditFollowUpTemplates}/>
                <ProtectedRoute exact path="/followuptemplatedetails/:id" component={AddEditFollowUpTemplates}/>
                <ProtectedRoute exact path="/auditeecommenttemplates" component={AuditeeCommentTemplateList}/>
                <ProtectedRoute exact path="/auditeecommenttemplatesdetails/:id" component={EditAuditeeCommentTemplateList}/>
                <ProtectedRoute exact path="/editauditeecommenttemplates/:id" component={EditAuditeeCommentTemplateList}/>
                <ProtectedRoute exact path="/overalltemplates" component={OverallTemplateList}/>
                <ProtectedRoute exact path="/editoveralltemplates/:id" component={EditOverallTemplate}/>
                <ProtectedRoute exact path="/overalltemplatesdetails/:id" component={EditOverallTemplate}/>

                <ProtectedRoute exact path="/addtaskwithtemplates/:id" component={AddTaskWithTemplates}/>
                <ProtectedRoute exact path="/edittaskwithtemplates/:id" component={AddTaskWithTemplates}/>
                
                
                <ProtectedRoute exact path="/clientsettings" component={clientSettings}/>
                <ProtectedRoute exact path="/departmentsettings" component={departmentSettings}/>
                <ProtectedRoute exact path="/adminSettings" component={AdminSettings}/>

                <ProtectedRoute exact path="/logout" component={Logout} />
            </Router>
        );
    }
}

export default App;
