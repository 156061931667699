import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { TableCheckBox, DialogSaveButton } from "../../../common/Global/globalStyles.style";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray, removeHtmlTags } from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const userDetails = getLocalStorageVariables();

class TagTasksList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,

			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,

			datalist: [],
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showOtherMasterDialog) {
			let docMap = {};

			this.setState(
				{
					componentDidMountFlag: false,
					showLoader: false,

					datalist: [],
					selectedDocumentMap: docMap,
				},
				() => {
					this.GetExecuteTasksList();
				}
			);
		}
	}

	handleSelection = (e) => {
		// e.stopPropagation();
		console.log(e.target);
		let isChecked = e.target.checked;
		let allIndex = e.target.name;
		let allIndexArr = allIndex.split(":-:");
		let parentArrId = allIndexArr[1];
		let childArrId = allIndexArr[0];
		let datalistTemp = this.state.datalist;

		datalistTemp[parentArrId].taskList[childArrId].isTagged = isChecked;

		this.setState({
			datalist: datalistTemp,
		});
	};

	returnSelectedMasterId = () => {
		let selectedTagTasksObjArr = [];
		// let selectAtleastOneTaskErr = "";

		this.state.datalist.forEach((item) => {
			item.taskList.forEach((taskItem) => {
				if (taskItem.isTagged) {
					selectedTagTasksObjArr.push(taskItem.assignmentTaskId);
				}
			});
		});

		if (isEmptyArray(selectedTagTasksObjArr)) {
			// selectAtleastOneTaskErr = "Select atleast one task";
			toast.warning("Select atleast one task");
			// this.setState({
			// 	selectAtleastOneTaskErr: selectAtleastOneTaskErr,
			// });

			// setTimeout(
			// 	function () {
			// 		this.setState({
			// 			selectAtleastOneTaskErr: "",
			// 		});
			// 	}.bind(this),
			// 	Constants.WRNG_MSG_TIMEOUT
			// );
		} else {
			this.props.handleSelectOtherMasterDialogSaveClose(selectedTagTasksObjArr);
		}
		// }
	};

	/*****************************API*********************************/
	GetExecuteTasksList = () => {
		this.setState({
			showLoader: true,
		});

		let url = "";
		if(this.props.isDraftOnward === true){
			url = Constants.GetDraftReportsTasksAll;
		}else{
			url = Constants.GetReportsTasksAll;
		}

		fetch(url, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentId: this.props.assignmentId,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					let dataList = [];
					if(this.props.isDraftOnward === true){
						
						let processTaskList = data.data.assignedTasks;
						let taskMap = data.data.taskMap;

						processTaskList.forEach((item)=>{
							let taskArr = [];
							item.taskList.forEach((taskItem)=>{
								taskItem.task = taskMap[taskItem.taskId];
								taskArr.push(taskItem);
							});
							item.taskList = taskArr;
							dataList.push(item);
						});

					}else{

						dataList = data.data.assignedTasks;
					}

					let dataListNew = [];
					dataList.forEach((item) => {
						item.taskList.forEach((taskItem, idx) => {
							// console.log("-->",this.props.selectedTagTasksObjArr)
                            // console.log("INDEX:"+idx+", ATID"+taskItem.assignmentTaskId);
							if (this.props.selectedTagTasksObjArr.indexOf(taskItem.assignmentTaskId) !== -1) {
							// if (this.props.selectedTagTasksObjArr.indexOf(taskItem.taskId) !== -1) {
								item.taskList[idx].isTagged = true;
							}
						});
						dataListNew.push(item);
					});

					this.setState({
						showLoader: false,
						componentDidMountFlag: true,
						datalist: dataListNew,
						// totalCount: data.data.count,
						// apiSearchKey: this.state.searchkey,
					});
				} else {
					this.setState({
						showLoader: false,
						componentDidMountFlag: true,
						datalist: [],
						// totalCount: 0,
						// apiSearchKey: this.state.searchkey,
					});
				}
			});
	};

	/*****************************API ENDS HERE*********************************/

	render() {
		return (
			<section>
				<Modal className="task-select-table-dialog custom-dialog" show={this.props.showOtherMasterDialog} backdrop="static">
					<Modal.Header>
						<h5>{this.props.masterType}</h5>
						<button onClick={this.props.handleSelectOtherMasterDialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						{this.state.showLoader && (
							<div class="loader"></div>
						)}
						{
							// Need to check component did mount flag, other wise until api is called,
							// it shows no items found layout.
							this.state.componentDidMountFlag && (
								<div className="body-wrapper">
									<div style={{ position: "relative" }}>
										{!isEmptyArray(this.state.datalist) &&
											this.state.datalist.map((item, index) => {
												let showTasks=false;
												showTasks=item.taskList.some((taskItem) =>(isEmptyVariable(taskItem.observationId)&&
												taskItem.userId === userDetails.userId) ||
												taskItem.observationId + "" === this.props.observationId)
												if(showTasks){

													return (
														<div>
															{/* {
																item.processName &&
																<h6 style={{ marginTop: index === 0 ? 15 : 10,fontSize:13}}>{item.processName}</h6>
															}
															{
																item.functionName &&
																<h6 style={{ marginTop: index === 0 ? 15 : 10,fontSize:13 }}>{item.functionName}</h6>
															} */}
															{
																<h6 style={{ marginTop: index === 0 ? 15 : 10,fontSize:13 }}>
																	{
																		isEmptyVariable(item.processName)
																		?(isEmptyVariable(item.functionName)
																			?(item.heading)
																			:item.functionName)
																		:item.processName
																	}
																</h6>
															}
	
															<div className="common-tab-bg">
																<div className="common-table" style={{ width: "100%", border: "1px solid #e5e5e5" }}>
																	<table className="table">
																		<thead>
																			<tr>
																				<th className="text-center" width={"8%"}></th>
																				<th className="text-center" width={"5%"}>
																					No.
																				</th>
																				<th>Task Name</th>
																				<th width={"27%"}>Risk</th>
																			</tr>
																		</thead>
																		<tbody>
																			{!isEmptyArray(item.taskList) &&
																				item.taskList.map((taskItem, idx) => {
																					//show the tasks that are not having any observations linked to it
																					// and show tasks that are only assigned to the User
																					//also show the tasks that are linked to the current observations that is being edited
																					if (
																						(isEmptyVariable(taskItem.observationId) &&
																						taskItem.userId === userDetails.userId) ||
																						taskItem.observationId + "" === this.props.observationId 
																					) {
																						return (
																							<tr
																								style={{background:taskItem.userId === userDetails.userId?"":"#f5f5f5"}}
																							>
																								<td>
																									<TableCheckBox themeSettings={this.props.themeSettings}>
																										<input
																											type="checkbox"
																											name={idx + ":-:" + index}
																											id={taskItem.assignmentTaskId}
																											onChange={this.handleSelection}
																											checked={taskItem.isTagged ? true : false}
																											disabled={this.props.forceDisableCheckbox==="Y"?true:(taskItem.userId === userDetails.userId?false:true)}
																										/>
																										<label for={taskItem.assignmentTaskId} style={{ marginLeft: 6 }}></label>
																									</TableCheckBox>
																								</td>
																								<td className="text-center">
																									{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}
																								</td>
																								<td><div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: taskItem.task}} /></td>
																								<td>
																									{
																										isEmptyVariable(taskItem.riskLevel) ? "-" : (
																										<div className="flex-center-layout">
																											{isEmptyVariable(taskItem.colorCode) ? taskItem.riskLevel:""}
																											
																											{
																												!isEmptyVariable(taskItem.colorCode) &&
																												<div
																													className="risk-level-color-wrapper-round"
																													style={{ width: "38px",marginLeft: (!isEmptyVariable(taskItem.colorCode) ?0:8)}}
																												>
																													<div
																														className="risk-level-color-round"
																														style={{ background: taskItem.colorCode }}
																													/>
																												</div>
																											}
																										</div>
																									)}
																								</td>
																							</tr>
																						);
																					}
																				})}
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													);
												}
											})}
										{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
											<div class="no-items-layout">
												<div class="no-items-card">
													<h6>{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}</h6>
												</div>
											</div>
										)}
									</div>
									{/* {!isEmptyVariable(this.state.selectAtleastOneTaskErr) && <span className="cm-error">{this.state.selectAtleastOneTaskErr}</span>} */}
									<div className="modal-close-save m-t-15">
										<div className="close-save-col">
											<button onClick={this.props.handleSelectOtherMasterDialogClose} type="button" className="modal-close m-b-0">
												Cancel
											</button>
											<DialogSaveButton
												themeSettings={this.props.themeSettings}
												onClick={this.returnSelectedMasterId}
												type="button"
												className="modal-save m-b-0"
											>
												Save
											</DialogSaveButton>
										</div>
									</div>
								</div>
							)
						}
					</div>
				</Modal>
			</section>
		);
	}
}

export default TagTasksList;
