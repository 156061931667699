import React, { Component } from "react";
import { TaskTagButtonsLayout, FilterCheckBox, AddNewButtonLayout } from "../../../../common/Global/globalStyles.style";
import { BrowserRouter as Router } from "react-router-dom";
import * as Constants from "../../../../common/Global/constants";
import {
	getLocalStorageVariables,
	isEmptyVariable,
	GetOrderedMasterTabMenuExecute,
	getThemeSettingsVariables,
	isEmptyArray,
	ifEmptyReturn,
	removeHtmlTags,
	isJsonString,
} from "../../../../common/Global/commonFunctions";
import "react-quill/dist/quill.snow.css";
import ShowObsDetailTagTasksDialog from "./showObsDetailsTagTasks";
import ShowValueAdditionDialog from "./showObsDetailsValueAddition";
import ShowObsDetailsAnnexureDialog from "./showAnnexure";
import ShowObsDetailsBackupDialog from "./showBackup";
import * as TemplateConstants from "../../../../common/Global/templateConstants";
import moment from "moment";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const riskImpactDropdownDefaultValue = "Please select risk/impact";
const mTTagTasks = "Tag Tasks";
//if the status is draft or created then there will be auto save other wise no...
const emptyString = "-";

class ObservationDetails extends Component {
	constructor(props) {
		super(props);

		let assignmentId = "";

		this.state = {
			assignmentId: assignmentId,
			user: {},
			businessRisk: [],
			valueAdditions: [],
			deptDefaultRisk: {},
			// userDefinedFieldsObj: {},
			selectedDepartmentId: "",
			assignmentStatus: "",
			datalist:[],
            datalistLeft:[],
			formCustomRisk:"",

			showLoader: false,
			componentDidMountFlag: false,

			showAlertWithInputDialog: false,
			// rows: 0,
			// cols: 0,

			// formCustomRisk: "",
			// formCustomRisk_NR: "",
			// formCustomRisk_isFocused: false,
			// suggestion: "",
			// suggestion_isFocused: false,
			// suggestion_NR: "",

			// selectedRiskImpactName: riskImpactDropdownDefaultValue,
			// selectedRiskImpactId: "",
			// selectedRiskIdArr: [],
            // selectedRiskDispName: [],


			//TAGS
			//Tag tasks
			showSelectTagTasksDialog: false,
			selectedTagTasksObjArr: [],
			//Risk
			showSelectRiskDialog: false,
			selectedRiskObj: {},
			//Value Addition
			showSelectVADialog: false,
			selectedVAObj: [],
			//Upload annexure
			showSelectUploadAnnexureDialog: false,
			uploadedAnnexureArr: [],
			uploadedBackupArr: [],
			annexure_NR:"",
			//Upload Backup
			showSelectUploadBackupDialog: false,
			selectedUploadBackupObj: [],
			backup_NR:"",

			// formErrors: {},
		};
	}

	componentDidMount() {
		this.initData();
	}

	/************* DIALOGS***************/
	//Tag Tasks
	handleSelectTagTasksDialogClose = () => {
		this.setState({
			showSelectTagTasksDialog: false,
		});
	};
	
	handleSelectTagTasksDialogShow = () => {
		this.setState({
			showSelectTagTasksDialog: true,
		});
	};

	// Value addition
	handleSelectVADialogClose = () => {
		this.setState({
			showSelectVADialog: false,
		});
	};
	
	handleSelectVADialogShow = () => {
	
		this.setState({
			showSelectVADialog: true,
		});
		// }
	};
	// Upload annexure
	handleSelectUploadAnnexureDialogClose = () => {
		this.setState({
			showSelectUploadAnnexureDialog: false,
		});
	};
	
	handleSelectUploadAnnexureDialogShow = () => {

		this.setState({
			showSelectUploadAnnexureDialog: true,
		});
	};

	// Upload backup
	handleSelectUploadBackupDialogClose = () => {
		this.setState({
			showSelectUploadBackupDialog: false,
		});
	};
	
	handleSelectUploadBackupDialogShow = () => {

		this.setState({
			showSelectUploadBackupDialog: true,

		});
		// }
	};

	/************* DIALOGS END HERE***************/

	/************************API CALLS****************************/
	initData = () => {
		this.setState({
			showLoader: true,
		});
		let masterDataUrl="";
		let assignmentUrl = "";
		let url = "";

		if(this.props.isDraftOnward === true && !this.props.isInitDraftReport){
			masterDataUrl= Constants.GetDraftObservationCreateMasterData;
			assignmentUrl = Constants.GetDraftAssignmentsDetails;
			url = Constants.GetDraftObservationDetails;
		}else if(!this.props.isDraftOnward && !this.props.isInitDraftReport){
			masterDataUrl= Constants.GetObservationCreateMasterData;
			assignmentUrl = Constants.GetAssignmentsDetails;
			url = Constants.GetObservationDetails;
		}else if( this.props.isInitDraftReport){
			//TODO
			masterDataUrl= Constants.GetObservationCreateMasterData;
			assignmentUrl = Constants.GetInitDraftReportDetails;
			url = Constants.GetInitDraftObservationDetails;
		}

		Promise.all([
			fetch(masterDataUrl, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.assignmentId,
				}),
			}),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),
			fetch(assignmentUrl, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.assignmentId,
				}),
			}),
			fetch(url, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					observationId: this.props.observationId,
				}),
			}),
		])
			.then(([masterRes, menuRes, assignmentRes, obsservationRes]) => {
				return Promise.all([masterRes.json(), menuRes.json(), assignmentRes.json(), obsservationRes.json()]);
			})
			.then(([masterRes, menuRes, assignmentRes, obsservationRes]) => {
				let menusTemp = {};
				let userTemp = {};
				let businessRiskArr = [];
				let businessRisks = [];

				let deptDefaultRisk = [];
				let valueAdditions = [];
				let assignedDeptId = "";
				let assignmentStatus = "";
				let observationDetails = {};
				let observationTasks = [];
				let observationVAArr = [];
				let observationInstanceColumns = [];
				let observationInstanceValues = [];
				let observationAnnexures = [];
				let observationBackups = [];
				// let userDefinedFieldsObj = {};
				let riskObj = {};
				let likelihoodObj = {};

				let datalistTemp = [];
				let datalistLeftTemp = [];
				let datalist = [];
				let datalistLeft = [];
				let templateObj = {};
				let templateName = "";
				let templateId = "";
				let templateFieldValuesMap = {};
				let formCustomRisk = "";

				if (assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
					if(this.props.isDraftOnward === true){
						assignedDeptId = assignmentRes.data.result.departmentId;
						assignmentStatus = assignmentRes.data.result.assignmentStatus;
					}else {
						assignedDeptId = assignmentRes.data.assignmentDetails.departmentId;
						assignmentStatus = assignmentRes.data.assignmentDetails.assignmentStatus;
					}
				} else {
				}

				if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
					menusTemp = menuRes.data.menus;
					userTemp = menuRes.data.user;
				} else {
					menusTemp = {};
				}
				//Order the menu tabs in a specific way
				let menusTemp2 = GetOrderedMasterTabMenuExecute(menusTemp);

				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					let businessRiskObj = {
						businessRiskId: -1,
						shortName: "",
						name: "Other",
						description: "",
					};
					businessRisks = masterRes.data.businessRisk;
					businessRisks.push(businessRiskObj);

					//add property "displayname: name (description)" to businessRisk array
					businessRisks.forEach(item=>{
						if(item.name !=="Other"){
                            item.displayName = item.name+(!isEmptyVariable(item.description)?": "+item.description:"");
						}
						else{
							item.displayName = item.name
						}
						businessRiskArr.push(item);
					});

					deptDefaultRisk = masterRes.data.riskLevels;
					valueAdditions = masterRes.data.valueAdditions;

					//Template Fields
					templateObj = masterRes.data.templateFields[0];
				
					if(!isEmptyVariable(templateObj.templateFields) && isJsonString(templateObj.templateFields)){
						datalistTemp = JSON.parse(templateObj.templateFields);
					}
					if(!isEmptyVariable(templateObj.templateFieldsLeft) && isJsonString(templateObj.templateFieldsLeft)){
						datalistLeftTemp = JSON.parse(templateObj.templateFieldsLeft);
					}
					
					templateName = templateObj.templateName;
					templateId = templateObj.templateId;

				} else {
					this.setState({
						businessRisk: [],
						valueAdditions: [],
						deptDefaultRisk: [],
						showLoader: false,
						componentDidMountFlag: true,
					});
				}

				if (obsservationRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || obsservationRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (obsservationRes.responseCode === Constants.CODE_SUCCESS) {
					observationDetails = obsservationRes.data.observationDetails;
					observationTasks = obsservationRes.data.observationTasks;
					observationVAArr = obsservationRes.data.valueAdditions;
					observationInstanceColumns = obsservationRes.data.instanceColumns;
					observationInstanceValues = obsservationRes.data.instanceValues;
					observationAnnexures = obsservationRes.data.annexures;
					observationBackups = obsservationRes.data.backups;
					
					riskObj = {
						riskLevelId:obsservationRes.data.observationDetails.riskLevelId,
						riskLevel:obsservationRes.data.observationDetails.riskLevel
					}
					likelihoodObj = {
						likelihoodLevelId: obsservationRes.data.observationDetails.likelihoodLevelId,
						likelihoodLevel: obsservationRes.data.observationDetails.likelihoodLevel,
					}

					// observationDetails = obsservationRes.data.observationDetails;
						
                    observationDetails.templateFieldValues.forEach(item=>{
                        templateFieldValuesMap[JSON.stringify(item.fieldId)] = item;
                    });

                    //Add fieldValue and isNRChecked from ValuesMap to datalist
                    datalist = datalistTemp.map(item=>{
                        if(item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI ||
                            item.fieldType === TemplateConstants.FIELD_TYPE_TABLE ){

                            if(!isEmptyVariable(templateFieldValuesMap[item.fieldId]?.value) &&
                                templateFieldValuesMap[item.fieldId]?.valueNR !== "Y" &&
                                isJsonString(templateFieldValuesMap[item.fieldId]?.value)
                            ){
                                item.fieldValue = JSON.parse(templateFieldValuesMap[item.fieldId]?.value);
                                if(item.fieldType === TemplateConstants.FIELD_TYPE_TABLE){
                                    item.isTableAdded = true;
                                }
                                
                            }else{
                                item.fieldValue = [];
                            }

                        }else{
                            if(templateFieldValuesMap[item.fieldId]?.valueNR !== "Y"){
                                item.fieldValue = templateFieldValuesMap[item.fieldId]?.value;
                            }
                        }

                        item.isNRChecked = templateFieldValuesMap[item.fieldId]?.valueNR;
                        return item;
                    });

                    datalistLeft = datalistLeftTemp.map(item=>{
                        if(item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT){
                            if(!isEmptyVariable(templateFieldValuesMap[item.fieldId]?.value) &&
                                templateFieldValuesMap[item.fieldId]?.valueNR !== "Y"
                            ){

                                item.fieldValue = JSON.parse(templateFieldValuesMap[item.fieldId]?.value);
                            }else{
                                item.fieldValue = [];
                            }
                        }else{
                            if(templateFieldValuesMap[item.fieldId]?.valueNR !== "Y"){
                                item.fieldValue = templateFieldValuesMap[item.fieldId]?.value;
                            }
                        }
                        item.isNRChecked = templateFieldValuesMap[item.fieldId]?.valueNR;
                        return item;
                    });

					//Get Other Risk/Impact Value
					if(!isEmptyVariable(observationDetails.customBusinessRisk)){
						formCustomRisk = observationDetails.customBusinessRisk;
					}
					
	
				} else {
				}


				let impactName = observationDetails.businessRisk;
				// let formCustomRisk = observationDetails.customBusinessRisk;
				// let formCustomRisk_NR = observationDetails.businessRisk_NR;
				// let selectedRiskImpactId = "";
				// let selectedRiskIdArr = [];
				// let selectedRiskDispName = [];

				// //if not required then assign variables to the default values
				// if(formCustomRisk_NR === "Y"){
				// 	formCustomRisk = "";
				// 	impactName = riskImpactDropdownDefaultValue;
				// 	selectedRiskImpactId = "";
				// }else{
				// 	formCustomRisk_NR = "N";
				// 	// impactName = [20:-:Risk,22:-:Risk2,-1:-:Other];
					
				// 	if(!isEmptyVariable(impactName)){
				// 		if(isJsonString(impactName)){
				// 			selectedRiskIdArr=JSON.parse(impactName).map(item=>parseInt(item.split(":-:")[0]));
							
				// 			// show custom risk reactquill if formCustomRisk is not empty 
				// 			if(!isEmptyVariable(formCustomRisk)){
				// 				selectedRiskImpactId = -1;
				// 			}
				// 			// let selectedRiskDispName = [];
				// 			businessRisks.forEach(item=>{
				// 				selectedRiskIdArr.forEach(riskItemId=>{
				// 					if(riskItemId===item.businessRiskId){
				// 						selectedRiskDispName.push(item.displayName);
				// 					}
				// 				})
				// 			});
				// 			console.log("",selectedRiskDispName);
				// 		}
				// 	}
				// }

				this.setState({
					showLoader: false,
					componentDidMountFlag: true,
					menus: menusTemp2,
					user: userTemp,

					deptDefaultRisk: deptDefaultRisk,
					businessRisk: businessRiskArr,
					valueAdditions: valueAdditions,
					// userDefinedFieldsObj: userDefinedFieldsObj,

					selectedDepartmentId: assignedDeptId,
					assignmentStatus: assignmentStatus,
					 formCustomRisk:formCustomRisk,

					selectedRiskObj:riskObj,
					likelihoodObj:likelihoodObj,

					selectedTagTasksObjArr: observationTasks.map((item) => item.taskId),
					selectedRiskId: observationDetails.riskLevelId,
					selectedVAObj: observationVAArr.map((item) => {
						return {
							valueAdditionId: item.valueAdditionId,
							valueAdditionValue: item.valueAdditionValue,
							valueAddition_NR: item.valueAddition_NR,
						};
					}),
					uploadedAnnexureArr: observationAnnexures.map((item) => {
						return {
							fileName: item.documentPath,
							remark: item.remark,
						};
					}),
					annexure_NR:observationDetails.annexure_NR,
					backup_NR:observationDetails.backup_NR,

					uploadedBackupArr: observationBackups.map((item) => {
						return {
							fileName: item.documentPath,
							remark: item.remark,
						};
					}),

                    datalist: datalist,
					datalistLeft: datalistLeft,
					templateName:templateName,
					templateId:templateId,
					templateFieldValuesMap:templateFieldValuesMap
				});
			});
	};

	render() {
		return (
			<Router>
				{this.state.componentDidMountFlag && (
					<div className="m-t-10">
						{this.state.showLoader && (
							<div class="loader"></div>
						)}
						<div className="prev-next-layout">
							<div className="flex-center-layout secondary-top-bar">
								<div className="back-btn-layout" onClick={this.props.handleHideObsDetailsLayout}>
									<span class="material-icons-outlined">arrow_back_ios</span>
								</div>
								<h6>{"Observation Details"}</h6>
							</div>
                            {
                                this.props.allowEdit &&
                                <AddNewButtonLayout themeSettings={themeSettings}>
                                    <a href={this.props.editUrl}>
                                        <span className="material-icons">edit</span>
                                        <p>Edit</p>
                                    </a>
                                </AddNewButtonLayout>
                            }
						</div>

						<div className="m-t-10">
							<div className="row custom-row">
								<div className="col-md-3 custom-col">
									<TaskTagButtonsLayout themeSettings={themeSettings}>
										<ul>
											<li>
												<a href={Constants.URL_OBSERVATIONLIST + "/" + this.props.assignmentId}>Back</a>
											</li>

											<li>
												<div className="tagLayout">
													<div className="tagHeadingLayout">
														<p>Tagged Tasks</p>
															{
																!isEmptyArray(this.state.selectedTagTasksObjArr) && 
																<span class="material-icons">check_circle</span>
															}
													</div>
													{
														isEmptyArray(this.state.selectedTagTasksObjArr)
														?
														<p>{emptyString}</p>
														:
														<div>
                                                        	<button onClick={this.handleSelectTagTasksDialogShow}>View Details</button>
                                                    	</div>
													}
												</div>
											</li>
											<li>
												<div className="row">
													<div className="col-6 pr-1">
														<div className="tagLayout h-100">
															<div className="tagHeadingLayout">
																<p>Risk</p>
																{
																	!isEmptyVariable(this.state.selectedRiskObj.riskLevelId) &&
																	<span class="material-icons">check_circle</span>
																}
															</div>
															{
																isEmptyVariable(this.state.selectedRiskObj.riskLevelId)
																?
																<p>{emptyString}</p>
																:
																<p>{this.state.selectedRiskObj.riskLevel}</p>
															}
														</div>
													</div>
													<div className="col-6 pl-1">
														<div className="tagLayout h-100">
															<div className="tagHeadingLayout">
																<p>Likelihood</p>
																{
																	!isEmptyVariable(this.state.likelihoodObj.likelihoodLevelId) &&
																	<span class="material-icons">check_circle</span>
																}
															</div>
															{
																isEmptyVariable(this.state.likelihoodObj.likelihoodLevelId)
																?
																<p>{emptyString}</p>
																:
																<p>{this.state.likelihoodObj.likelihoodLevel}</p>
															}
														</div>
													</div>
												</div>
											</li>
											<li>
												<div className="tagLayout">
													<div className="tagHeadingLayout">
														<p>Value Addition</p>
															{
																!isEmptyArray(this.state.selectedVAObj) && 
																<span class="material-icons">check_circle</span>
															}
													</div>
													{
														isEmptyArray(this.state.selectedVAObj)
														?
														<p>{emptyString}</p>
														:
														<div>
                                                        	<button onClick={this.handleSelectVADialogShow}>View Details</button>
                                                    	</div>
													}
												</div>
											</li>
											<li>
												<div className="tagLayout">
													<div className="tagHeadingLayout">
														<p>Annexures</p>
															{
																(!isEmptyArray(this.state.uploadedAnnexureArr) || this.state.annexure_NR === "Y" ) && 
																<span class="material-icons">check_circle</span>
															}
													</div>
													{
														(isEmptyArray(this.state.uploadedAnnexureArr) && this.state.annexure_NR !== "Y" )
														?
														<p>{emptyString}</p>
														:
														<div>
                                                        	<button onClick={this.handleSelectUploadAnnexureDialogShow}>View Details</button>
                                                    	</div>
													}
												</div>
												
											</li>
											<li>
												<div className="tagLayout">
													<div className="tagHeadingLayout">
														<p>Backups</p>
															{
																(!isEmptyArray(this.state.uploadedBackupArr) || this.state.backup_NR === "Y" ) && 
																<span class="material-icons">check_circle</span>
															}
													</div>
													{
														isEmptyArray(this.state.uploadedBackupArr)
														?
														<p>{emptyString}</p>
														:
														<div>
                                                        	<button onClick={this.handleSelectUploadBackupDialogShow}>View Details</button>
                                                    	</div>
													}
												</div>
											</li>
											{/* <li>
												<a href="javascript:void(0)" onClick={this.handleSelectAssertionDialogShow}>
													Completed
													{(!isEmptyArray(this.state.selectedAssertionObjArr) || this.state.assertion_NR === "Y") && (
														<span class="material-icons">check_circle</span>
													)}
												</a>
											</li>
											<li>
												<a href="javascript:void(0)" onClick={this.handleSelectAssertionDialogShow}>
													Submit
													{(!isEmptyArray(this.state.selectedAssertionObjArr) || this.state.assertion_NR === "Y") && (
														<span class="material-icons">check_circle</span>
													)}
												</a>
											</li>
											<li>
												<a href="javascript:void(0)" onClick={this.handleSelectAssertionDialogShow}>
													Rework
													{(!isEmptyArray(this.state.selectedAssertionObjArr) || this.state.assertion_NR === "Y") && (
														<span class="material-icons">check_circle</span>
													)}
												</a>
											</li> */}
											{
												this.state.datalistLeft.map((item,index)=>{
													if(item.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT){
														return  <li>
															<div className="tagLayout">
																<div className="tagHeadingLayout">
																	<p>{item.fieldName}</p>
																	{
																		!isEmptyVariable(item.fieldValue) &&
																		<span class="material-icons">check_circle</span>
																	}
																</div>
																{
																	isEmptyVariable(item.fieldValue)
																	?
																	<p>{emptyString}</p>
																	:
																	<p>{item.fieldValue}</p>
																}
															</div>
														</li>

													}else if(item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT){
														return  <li>
															<div className="tagLayout">
																<div className="tagHeadingLayout">
																	<p>{item.fieldName}</p>
																	{
																		!isEmptyArray(item.fieldValue) &&
																		<span class="material-icons">check_circle</span>
																	}
																	
																</div>
																{
																	isEmptyArray(item.fieldValue)
																	?
																	<p>{emptyString}</p>
																	:
																	<p>{item.fieldValue.join(", ")}</p>
																}
															</div>
														</li>

													}
												})
											}
											
											{/* {
												
												this.state.datalistLeft.map((item,index)=>{
													return (
														<li>
															{
																item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN &&
																<div className="tagLayout">
																	<div className="tagHeadingLayout">
																		<p>{item.fieldName}{item.isMandatorySelected === "Y"?"*":""}</p>
																		{
																			!isEmptyVariable(item.fieldValue) &&
																			<span class="material-icons">check_circle</span>
																		}
																	</div>
																	{
																		isEmptyVariable(item.fieldValue)
																		?
																		<p>{emptyString}</p>
																		:
																		<p>{item.fieldValue}</p>
																	}
																</div>
															}
															{
																item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
																<div className="tagLayout">
																	<div className="tagHeadingLayout">
																		<p>{item.fieldName}{item.isMandatorySelected === "Y"?"*":""}</p>
																		{
																			!isEmptyArray(item.fieldValue) &&
																			<span class="material-icons">check_circle</span>
																		}
																	</div>
																	{
																		isEmptyArray(item.fieldValue)
																		?
																		<p>{emptyString}</p>
																		:
																		<p>{item.fieldValue.join(", ")}</p>
																	}
																</div>
															}
														</li>
													)
												})
											} */}
										</ul>
									</TaskTagButtonsLayout>
								</div>
								
								<div className="col-md-9 custom-col">
								{
									this.state.datalist.map((item,index)=>{
                                        let showHeader = true;
                                        if(item.fieldType===TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI){
                                            if(item.isFromRiskMaster){
                                                if(item.isRiskMasterEnabled === "Y"){
                                                    showHeader = true;
                                                }else{
                                                    showHeader = false;
                                                }
                                            }else{
                                                showHeader = true;
                                            }
                                        }
										return (
										<div class="editor-card">
                                            {
                                                showHeader &&
                                                <div className={item.isNotReqSelected === "Y"?"task-header-layout editor-border-bottom m-b-0":"task-header-layout editor-border-bottom"}>
                                                    <h6 className="editor-heading">
                                                    {item.fieldName}{item.isMandatorySelected === "Y"?"*":""}
                                                    </h6>
                                                    {
                                                        item.isNotReqSelected === "Y"
                                                        ?
                                                            <FilterCheckBox themeSettings={themeSettings}
                                                            style={{marginLeft:0}}>
                                                                <input 
                                                                    type="checkbox"
                                                                    name={item.fieldName+":-:"+index}
                                                                    id={item.fieldName+":-:"+index}
                                                                    onChange={this.handleCheck}
                                                                    disabled={true}
                                                                    checked={item.isNRChecked === "Y"?true:false}
                                                                />
                                                                <label for={item.fieldName+":-:"+index}>Not Required</label>
                                                            </FilterCheckBox>
                                                        :
                                                            <div />
                                                    }
                                                </div>
                                            }
											{
												item.fieldType === TemplateConstants.FIELD_TYPE_TEXT &&
												<div class="row custom-row">
													<div class="col-md-12 custom-col">
														{
															item.isNRChecked !== "Y" &&
															<div class="editor-text-layout">
																{
																	isEmptyVariable((item.fieldValue))
																	?
																	<p>{emptyString}</p>
																	:
																		(isEmptyVariable(removeHtmlTags(item.fieldValue))
																		?<p>{emptyString}</p>
																		:
																		<div dangerouslySetInnerHTML={{__html: item.fieldValue}} />)
																}
																
															</div>
														}
													</div>
												</div>
											}
											{
												item.fieldType === TemplateConstants.FIELD_TYPE_DATE &&
												<div class="row custom-row">
													<div class="col-md-12 custom-col">
														{
															item.isNRChecked !== "Y" &&
															<div class="editor-text-layout">
																{
																	isEmptyVariable((item.fieldValue))
																	?
																	<p>{emptyString}</p>
																	:
																	<p>{moment(item.fieldValue).format("DD/MM/YYYY")}</p>
																}
																
															</div>
														}
													</div>
												</div>
											}
											{
												item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                item.isRiskMasterEnabled === "Y" &&
												item.isFromRiskMaster &&
												<div class="row custom-row">
													<div class="col-md-12 custom-col">
														<div class={item.isNRChecked === "Y"?"editor-disabled":""}>
														{
															isEmptyArray(item.fieldValue)?
															<p className="m-b-0">-</p>
															:
															(
															<ul style={{marginBottom:5,paddingLeft:25}}>
																{
																	this.state.businessRisk.map(riskItem=>{
																		if(item.fieldValue.includes(riskItem.businessRiskId)){

																			return(
																				<li className="m-b-0 font-size-p">{riskItem.displayName}</li>
																			)
																		}
																	})  
																}
															</ul>)
														}
														{
															item.fieldValue.some(fieldItem => fieldItem === -1)  &&
															<div class="editor-text-layout">
																<div style={{marginLeft:25}}
																dangerouslySetInnerHTML={{__html: this.state.formCustomRisk}} />
															</div>
														}

														</div>
													</div>
												</div>
											}
											{
												item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
												!item.isFromRiskMaster &&
												<div class="row custom-row">
													<div class="col-md-12 custom-col">
														<div class={item.isNRChecked === "Y"?"editor-disabled":""}>
														{
															isEmptyArray(item.fieldValue)?
															<p className="m-b-0">-</p>
															:
															(
															<ul style={{marginBottom:5,paddingLeft:25}}>
																{
																	item.fieldValue.map(valueItem=>{

																		return(
																			<li className="m-b-0 font-size-p">{valueItem}</li>
																		)
																	})  
																}
															</ul>)
														}
														</div>
													</div>
												</div>
											}
											
											{
												item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN &&
												<div class="row custom-row">
													<div class="col-md-12 custom-col">
                                                        <div class="editor-text-layout">
														{
															isEmptyVariable(item.fieldValue)?
															<p className="m-b-0">-</p>
															:
															<p>{item.fieldValue}</p>
														}
														</div>
													</div>
												</div>
											}

											{
												item.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
												<div className="flex-center-layout" style={{justifyContent:"center"}}>
													{
														!item.isTableAdded &&
														<p>{emptyString}</p> 
													}
													{
														item.isTableAdded &&
														<div
															style={{ width: "100%", marginTop: 10, boxShadow: "none" }}
															class={
																item.isNRChecked === "Y"
																	? "editor-layout editor-disabled common-table"
																	: "common-table editor-layout"
															}
														>
													
															<table class="table instances-table">
																
																{
																	item.fieldValue.map((rowItem, rowIndex) =>  {
																		if(rowIndex === 0){
																			return <thead className="instances-thead">
																				<tr>
																				{
																					rowItem.map((colItem, colIndex) => {
																						if(colIndex !== rowItem.length-1){

																							return <th className="text-center">
																								<div className="instances-table-header">
																									<p>
																										{colItem}
																									</p>
																								</div>
																							</th>
																						}
																					})
																				}
																				</tr>
																				</thead>
																				
																		}else{
																			return <tbody className="instances-thead">
																				<tr>
																				{
																					rowItem.map((colItem, colIndex) => {
																						if(colIndex !== rowItem.length-1){

																							return <td className="text-center">
																								{colItem}
																							</td>
																						}
																					})
																				}
																				</tr>
																			</tbody>

																		}

																	})
																}
															</table>
														</div>
													}
												</div>
											}
											
										</div>
										)
									})
								}
								
							</div>
							</div>
							<div className="row custom-row">
								<div className="col-md-12 custom-col">
									<div className="common-close-save">
										<div className="common-close-save-col">
											<button onClick={this.props.handleHideObsDetailsLayout} type="button" className="common-close">
												Close
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<ShowObsDetailTagTasksDialog
							showOtherMasterDialog={this.state.showSelectTagTasksDialog}
							handleSelectOtherMasterDialogClose={this.handleSelectTagTasksDialogClose}
							selectedTagTasksObjArr={this.state.selectedTagTasksObjArr}
							observationId={this.props.observationId}
							masterType={mTTagTasks}
							themeSettings={themeSettings}
							assignmentId={this.props.assignmentId}
							isDraftOnward = {this.props.isDraftOnward}
							isInitDraftReport = {this.props.isInitDraftReport}
						/>
						
						<ShowValueAdditionDialog
							showVADialog={this.state.showSelectVADialog}
							handleSelectVADialogClose={this.handleSelectVADialogClose}
							selectedVAObj={this.state.selectedVAObj}
							valueAdditionArr={this.state.valueAdditions}
							themeSettings={themeSettings}
						/>
						<ShowObsDetailsAnnexureDialog
							showUploadAnnexureDialog={this.state.showSelectUploadAnnexureDialog}
							handleSelectUploadAnnexureDialogClose={this.handleSelectUploadAnnexureDialogClose}
							uploadedAnnexureArr={this.state.uploadedAnnexureArr}
							themeSettings={themeSettings}
							annexure_NR={this.state.annexure_NR}
						/>
						<ShowObsDetailsBackupDialog
							showUploadBackupDialog={this.state.showSelectUploadBackupDialog}
							handleSelectUploadBackupDialogClose={this.handleSelectUploadBackupDialogClose}
							uploadedBackupArr={this.state.uploadedBackupArr}
							themeSettings={themeSettings}
							backup_NR={this.state.backup_NR}
						/>
						
					</div>
				)}
			</Router>
		);
	}
}
export default ObservationDetails;
