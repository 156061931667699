import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {DialogSaveButton, AddNewButtonLayout} from '../../common/Global/globalStyles.style';
import {getLocalStorageVariables,isEmptyVariable, getThemeSettingsVariables} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from 'react-toastify';
// import AlertDialog from '../../common/AlertDialog';

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();

class StatesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            formErrors:{},
            state:"",

            // city:"",
            // datalist:[],
            // countryList:[],
            // statesList:[],
            // selectedCountry:countryPlaceholder,

            // countryTextBoxFlag:false,
            // stateTextBoxFlag:false,
            // showAddCountryButtonFlag:true,
            // showAddStateButtonFlag:true,

            // showAlertDialog:false,
            // operationType:"",
            // alertDialogMessage:"",
            // alertDialogHeading:"",
            // proceedBtnLabel:"",
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){
            if(this.props.isEdit){
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    formErrors:{},
                    state:this.props.selectedState,
                    // countryList:[],
                    // statesList:[],

                    // city:this.props.city,
                    // selectedCountry:this.props.selectedCountry,
                    // countryTextBoxFlag:false,
                    // stateTextBoxFlag:false,

                    // showAddCountryButtonFlag:false,
                    // showAddStateButtonFlag:false
                    
                })
            }
            // else{
            //     this.setState({
            //         componentDidMountFlag:false,
            //         showLoader:false,
    
            //         formErrors:{},

            //         city:"",
            //         countryList:[],
            //         statesList:[],
            //         selectedCountry:countryPlaceholder,
            //         selectedState:statePlaceholder,
            //         countryTextBoxFlag:false,
            //         stateTextBoxFlag:false,

            //         showAddCountryButtonFlag:true,
            //         showAddStateButtonFlag:true,

            //         showAlertDialog:false,
            //         operationType:"",
            //         alertDialogMessage:"",
            //         alertDialogHeading:"",
            //         proceedBtnLabel:"",

            //     })
            // }
        }
    }

    handleCreateDialogClose = () => {
        this.setState({
            state:"",
            // selectedCountry:"",
            // city:"",
        },()=>{
            this.props.handleCreateDialogClose(false);
        });
    }


    handleOnChangeSelectedState = (e) => {
		console.log(e);
		this.setState({ 
            state:e.state
         });
	};

    // handleAlertDialogClose = () => {
    //     this.setState({
    //         operationType:"",
    //         alertDialogHeading:"",
    //         proceedBtnLabel:"",
    //         alertDialogMessage:"",
    //         showAlertDialog:false,
    //     })
    // }

    handleChange = (e) =>{
       const {name, value} = e.target;
       this.setState({ 
            [name]: value,
        });
    }

    /*******************API CALLS*******************/

    
    handleUpdateState = () => {
        this.setState({
            showLoader:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.state)){
            isError = true;
            FormErrorsTemp['state'] = "Please enter state name";
        }

        // if(this.state.selectedCountry === countryPlaceholder || isEmptyVariable(this.state.selectedCountry)){
        //     isError = true;
        //     FormErrorsTemp['selectedCountry'] = "Please select a Country";
        // }
        // if(this.state.selectedState === statePlaceholder || isEmptyVariable(this.state.selectedState)){
        //     isError = true;
        //     FormErrorsTemp['selectedState'] = "Please select a State";
        // }


        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";
            let postParam = {};
            

            if(this.props.isEdit){
                url = Constants.UpdateState;
                postParam = {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    state:this.state.state,
                    oldState:this.props.selectedState,
                }
            }
            // else{
            //     url = Constants.CreateCity;
            //     postParam = {
            //         email:userDetails.email,
            //         accessToken:userDetails.accessToken,
            //         city:this.state.city,
            //         country:this.state.selectedCountry,
            //         state:this.state.state,
            //     }
            // }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        
                    },() => {
                        this.props.handleCreateDialogClose(true);
                    });
                }else{
                    this.setState({
                        showLoader:false
                    })
                    toast.error(data.responseMessage);
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <Modal enforceFocus={false} className="custom-dialog add-city-dialog" show={this.props.showCreateDialog} backdrop="static">
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit State":"Add New State"}</h5>
                    <button 
                        onClick={this.handleCreateDialogClose}>
                        <span className="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />

                <div className="modal-body">
                    <div className="addnew-modal-form">
                        
                        <div className="addnew-modal-form-group">
                            <p>State*</p>
                            <input type="text" placeholder="Enter State Name"
                            name="state" value={this.state.state} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['state']) &&
                                <span className="cm-error">{this.state.formErrors['state']}</span>
                            }
                        </div>
                    </div>
                    
                    <div className="modal-close-save">
                        <div className="close-save-col">
                            <button onClick={this.handleCreateDialogClose} type="button" className="modal-close">Cancel</button>
                            <DialogSaveButton
                            themeSettings = {themeSettings}
                            onClick={this.handleUpdateState} type="button">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>

                {/* <AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={Constants.ALERT_TYPE_INFO}
                    alertDialogHeading={this.state.alertDialogHeading}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={
                        (this.state.operationType === "Add Country")
                        ?
                        this.handleAddNewCountry
                        :
                        this.handleAddNewState
                    }
                    proceedBtnLabel={this.state.proceedBtnLabel}
                    themeSettings={themeSettings}
                /> */}

            </Modal>

        );
    }
}

export default StatesList;
