import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { getThemeSettingsVariables, isEmptyVariable, isEmptyArray, getLocalStorageVariables} from "../../../common/Global/commonFunctions";
import { BrowserRouter as Router } from "react-router-dom";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import RegularDD from '../../../common/DropdownMenus/RegularDropdown';
import Sidebar from "../../../common/SidebarCollapse";
import Topbar from "../../../common/Topbar";
import FollowupHeading from "../Common/followupHeadingDetails";
import { getMatrixandMyDetails, getOnlyMyObservations } from "../Tasks/execTaskUtilFunctions";
import AddFollowUpTemplateValueDialog from "../Common/addFollowUpTemplateValueDialog";
import { getFollowUpTasks } from "../../../common/Global/reviewFunctions";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import moment from "moment";
import AlertDialog from "../../../common/AlertDialog";
import FollowUpDetailsDialog from "./followUpDetailsDialog";
import FollowUpUpdateAssignmentTaskReviewStatus from "./followUpUpdateReviewStatusDialog";
import ReviewHistoryDialog from "../../../common/reviewhistorydialog";
import ObservationDetailsLayout from "../Observation/ObservationDetails/observationDetails";
import SelectRiskDialog from "./selectRisk";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const filterOpenCloseDropDownArr = [
    {
        label: "Checked & Open",
        isSelected: true,
    },
    {
        label: "Close",
        isSelected: false,
    },
];

class FollowUpObservation extends Component {
    constructor(props) {
        super(props);
        const item = props.location.state;
		this.state = {
            assignmentId: props.match.params.id,
            departmentId: item.departmentId,
            observationTemplateId: item.observationTemplateId,
            observationTaskFilterDropDownArr: item.observationTaskFilterDropDownArr,
            observationTaskFilterPlaceholder: item.observationTaskFilterPlaceholder,

			componentDidMountFlag: false,
			showLoader: false,
			datalist: [],
            allDataList: [],

            assignmentMatrix: [],
            selectedResourceUserName: "",
            selectedResourceId: "",
            myPosition: "",

            assignmentStatus: "",
            assignmentDetails: "",

            followUpObservation:[],
            filterOpenCloseDropDownArr:filterOpenCloseDropDownArr,
            filterOpenClosePlaceholder: "Open",
            showFollowUpTemplateValueDialog:false,
            followUpTaskTemplateList: [],
            selectFollowUpTemplate:"",

            showUpdateReviewDialog: false,
            showReviewHistoryDialog:false,
            deleteReactivateId: "",
            deleteReactivateMasterType: "",
            showAlertDialog: false,
            operationType: "",
            showBtnLoader: false,
            reviewLevel: "",
            alertDialogMessage: "",
            alertDialogHeading: "",
            proceedBtnLabel: "",
            remarks: "",
            showAlertDialogInfo: false,
            alertDialogMessageInfo: "",
            currentReviewLevel:"",
            endReviewLevel:"",

            showFollowUpDetailsDialog: false,
            followUpDetailsItem:{},
            
            //Risk
            showSelectRiskDialog: false,
            selectedRiskObj: "",
            deptDefaultRisk: {},
            observationId:"",
            
			showObsDetailsLayout:false,
			selectedObsIdForDetails:"",
            isShowHeader:true,
            isFollowUpTemplateValueEditDialog:false,
            followUpObservationItem:false
        }
    }

    componentDidMount() {
        this.getInitData();
	}

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.setState({
                componentDidMountFlag:false,
                showLoader: false,
                datalist: [],
                allDataList: [],
            },()=>{
                this.getInitData();
            })
        }
    }

    resourceDropdownItemClick = (selectedItem) => {
        let arr = this.getUserTasksFromUserId(selectedItem.id);
        this.setState({
            selectedResourceId: selectedItem.id,
            selectedResourceUserName: selectedItem.label,
            datalist: arr,
        });
    }

    getUserTasksFromUserId = (userId) => {
        let allData = JSON.parse(JSON.stringify(this.state.allDatalist));
        const arr = allData.map((observation) => {
            let observationList = [];
            observation.obsList.forEach((item) => {
                if (item.userId + "" === userId + "") {
                    observationList.push(item);
                }
            })
            observation.obsList = observationList;
            return observation;
        });
        return arr;
    };

    getRootLevelFunctions = (functionMapTemp) => {
        let rootFunctionArr = [];
        Object.values(functionMapTemp).map((item) => {
            if (item.level === 0) {
                rootFunctionArr.push(item);
            }
        });
        return rootFunctionArr;
    };

    observationFilterDropDownOnClicks = (selectedItem) => {
        let tempObservationFilterDataArray = this.state.observationTaskFilterDropDownArr;
        Object.values(tempObservationFilterDataArray).map((item) => {
            if (selectedItem.label === item.label) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });

        if(selectedItem.label === "Task"){
            this.props.history.replace(Constants.URL_FOLLOWUPTASK + "/" + this.state.assignmentId,{
                departmentId:this.state.departmentId,
                observationTemplateId:this.state.observationTemplateId,
                observationTaskFilterDropDownArr:tempObservationFilterDataArray,
                observationTaskFilterPlaceholder:selectedItem.label,
            });
        }
    }

    filterOpenCloseDropDownOnClick = (observationId,selectedItem) => {
        let tempFilterDataArray = this.state.filterOpenCloseDropDownArr;
        let placeholder = "";
        
        tempFilterDataArray.map((item) => {
            if (item.label === selectedItem.label) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });
        placeholder = (selectedItem.label==="Checked & Open")?"Open":selectedItem.label;
        this.getFollowupTaskTemplatevalues(observationId);

        this.setState({
            showFollowUpTemplateValueDialog:true,
            isFollowUpTemplateValueEditDialog:false,
            filterOpenCloseDropDownArr: tempFilterDataArray,
            selectFollowUpTemplate: selectedItem.label
        })
    }

    handleAlertDialogClose = () => {
        this.setState({
            showAlertDialog: false,
            alertDialogMessage: "",
        });
    };

    handleFollowUpTemplateValueDialogClose = () => {
        this.setState({
            showFollowUpTemplateValueDialog:false
        })
        this.getObservationList()
    }

    handleFollowUpDetailsDialogShow = (followUpItem, observationId, item) => {
        this.setState({
            showFollowUpDetailsDialog:true,
            followUpDetailsItem:followUpItem,
            observationId:observationId,
            followUpObservationItem:this.observationEditable(item)
        });
    }
    
    handleFollowUpDetailsDialogClose = () => {
        this.setState({
            showFollowUpDetailsDialog:false
        });
    }

    handleUpdateReviewDialogClose = (reloadFlag) => {
        this.setState({
            showUpdateReviewDialog: false,
            deleteReactivateMasterType: "",
            deleteReactivateId: "",
            reviewLevel: "",
        },() => {
            if (reloadFlag === true) {
                this.getObservationList();
            }
        });
    };
    
    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateId:"",
        });
    }
    
    onTableDropDownItemClick = (item, dropdownItem) => {
        if (item.label === "Follow Up Details") {
            this.getPreviousFollowUpTaskTemplateValues(dropdownItem.observationId,dropdownItem);
        } else if (item.label === "Submit For Review") {
            this.setState({
                operationType: item.label,
                alertDialogHeading: "Submit For Review",
                proceedBtnLabel: "Submit",
                deleteReactivateId: dropdownItem.observationId,
                showAlertDialog: true,
                alertDialogMessage:
                    "Are you sure you want to submit for review?",
            });
        } else if (item.label === "Start Review") {
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.observationId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+dropdownItem.companyName+" assignment?"
            })
        } else if (item.label === "Update Review Status") {
            this.setState({
                reviewLevel: dropdownItem.reviewLevel,
                deleteReactivateId: dropdownItem.observationId,
                showUpdateReviewDialog: true,
                currentReviewLevel:dropdownItem.currentReviewLevel,
                endReviewLevel:dropdownItem.endReviewLevel
            });
        } else if (item.label === "Review History") {
            this.setState({
                deleteReactivateId:dropdownItem.observationId,
                showReviewHistoryDialog:true,
            })
        }
    };

    // Risk
    handleSelectRiskDialogShow = (observationItem) => {
        //User should select the department before selecting default risk
        let focusObj = this.state.datalist;
        const selectedRisk = this.state.deptDefaultRisk[this.state.assignmentDetails.departmentId].filter(item => {
            if(item.riskLevelId == observationItem.followUpRiskLevelId){
                return item;
            }
        })
        
        this.setState({
            selectedRiskObj:isEmptyArray(selectedRisk)?"":selectedRisk[0],
            showSelectRiskDialog: true,
            observationId:observationItem.observationId,
            ...focusObj,
        });
    };

    handleSelectRiskDialogClose = (reloadFlag) => {
        this.setState({
            showSelectRiskDialog: false,
            selectedRiskObj:""
        },() => {
            if (reloadFlag === true) {
                this.getObservationList();
            }
        });
    };

    handleSelectRiskDialogSaveClose = (selectedRiskObj) => {
        this.setState({
            showSelectRiskDialog: false,
            selectedRiskObj: selectedRiskObj,
        });
        this.getObservationList();
    };

    handleShowObsDetailsLayout = (observationId) => {
        this.setState({
            isShowHeader:false,
            showObsDetailsLayout:true,
            selectedObsIdForDetails:observationId,
        })
    }

	handleHideObsDetailsLayout = () => {
        this.setState({
            isShowHeader:true,
            showObsDetailsLayout:false,
            selectedObsIdForDetails:""
        })
    }

    observationEditable = (observationItem) => {
        let endReviewUserId = "";
        for(var i=0;i<7;i++){
            if(observationItem.endReviewLevel===i){
                endReviewUserId=observationItem[`L${i}UserId`]
            }
        }
        if(!isEmptyVariable(endReviewUserId) && observationItem.currentReviewUserId==userDetails.userId && endReviewUserId==userDetails.userId){
            return false;
        }else{
            return observationItem.allowEdit==="N"?true:false  
        }
    }

    // Upload annexure
    uploadAnnexuresBackupIconClick = (assignmentTaskId,type,assignmentTaskUserId) => {
        if (type === "Annexures") {
            this.setState({
                showSelectUploadAnnexureDialog: true,
                deleteReactivateId: assignmentTaskId,
                isBackup: false,
            });
        } else if (type === "Backups") {
            this.setState({
                showSelectUploadAnnexureDialog: true,
                deleteReactivateId: assignmentTaskId,
                isBackup: true,
            });
        } else if (type === "Review Comments") {
            this.setState({
                deleteReactivateId: assignmentTaskId,
                showReviewCommentsDialog: true,
                assignmentTaskUserId: assignmentTaskUserId,
                type: "Comment",
            });
        }
    };

    handleSelectUploadAnnexureDialogClose = (reloadFlag) => {
        this.setState({
            showSelectUploadAnnexureDialog: false,
            isBackup: false,
        },
        () => {
            if (reloadFlag === true) {
                this.getFollowUpTaskTab();
            }
        });
    };

    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader: true,
        });

        Promise.all([
            fetch(Constants.GetFollowUpDraftReportHeadings, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                    departmentId: this.state.departmentId,
                    donotReport: "N",
                }),
            }),
            fetch(Constants.GetAssignmentsDetails, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                }),
            }),
            fetch(Constants.GetAssignmentFollowUpObservations, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
				}),
            }),
        ])
        .then(([observationRes,assignmentRes, assignFollowUpObservation]) => {
            return Promise.all([observationRes.json(),assignmentRes.json(),assignFollowUpObservation.json()]);
        })
        .then(([observationRes,assignmentRes,assignFollowUpObservation]) => {
            let headingMap = [];
            let rootFunctionArr = [];
            let assignObservation = {};   
            let assignmentStatus = "";
            let assignmentDetails = {};
            let matrixandMyDetails = {};
            let myDatalistTemp = [];
            let deptDefaultRisk = observationRes.result.deptDefaultRisk;
            let task = {};

            if (
                observationRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                observationRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (observationRes.responseCode === Constants.CODE_SUCCESS) {
                headingMap = observationRes.result.headingMap;
                rootFunctionArr = this.getRootLevelFunctions(headingMap);

                let allDataList = JSON.parse(JSON.stringify(rootFunctionArr));
                myDatalistTemp = getOnlyMyObservations(allDataList);

                //need list of users/reviewers
                let assignmentMatrixObj = observationRes.result.assignmentMatrix;
                //Get memberslist & Reviewers list for dropdown
                if(!isEmptyVariable(assignmentMatrixObj)){
                    matrixandMyDetails = getMatrixandMyDetails(assignmentMatrixObj);
                }
            } else {
            }

            if (
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                assignmentStatus = assignmentRes.data.assignmentDetails.assignmentStatus;
                assignmentDetails = assignmentRes.data.assignmentDetails;
            } else {
            }

            let followUpObservationTemp = [];
            if(
                assignFollowUpObservation.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignFollowUpObservation.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ){
                localStorage.clear();
                window.location = "/";
            } else if(assignFollowUpObservation.responseCode === Constants.CODE_SUCCESS) {
                followUpObservationTemp = assignFollowUpObservation.data.followUpObservations;
                followUpObservationTemp.forEach(item=>{
                    assignObservation[item.observationId] = item;
                })
                            
                let datalistTemp = {};  
                rootFunctionArr.map(item => {
                    let updatedTaskList = [];
                    item.obsList.forEach(observation=>{
                        if(!isEmptyVariable(assignObservation[observation.observationId])){
                            const key = observation.observationId;
                            observation.resourceName = isEmptyVariable(assignObservation[key].userName)?"":assignObservation[key].userName;
                            observation.resourceId = isEmptyVariable(assignObservation[key].userId)?"":assignObservation[key].userId;
                            observation.resourcefullName = (isEmptyVariable(assignObservation[key].userName)?"":assignObservation[key].userName)+"("+(isEmptyVariable(assignObservation[key].designation)?"":assignObservation[key].designation)+")";
                            observation.resourceDesignation = isEmptyVariable(assignObservation[key].designation)?"":assignObservation[key].designation;
                        }
                        updatedTaskList.push(observation);
                    });
                    item.obsList = updatedTaskList;
                    datalistTemp[item.nodeId] = item;
                })
            }

            //Now Risk
            let riskObj = {
                riskLevelId: deptDefaultRisk[assignmentDetails.departmentId].riskLevelId,
                riskLevel: deptDefaultRisk[assignmentDetails.departmentId].riskLevel,
            }

            task = {
                selectedRiskObj: riskObj
            }

            this.setState({
                componentDidMountFlag: true,
                showLoader: false,
                datalist: myDatalistTemp,
                allDatalist: rootFunctionArr,
                followUpObservation:followUpObservationTemp,
                assignmentStatus: assignmentStatus,
                assignmentDetails: assignmentDetails,
				assignmentMatrix: matrixandMyDetails.assignmentMatrix,
				selectedResourceUserName:matrixandMyDetails.selectedResourceUserName,
				myPosition:matrixandMyDetails.myPosition,
                selectedResourceId: userDetails.userId,
                ...task,
                deptDefaultRisk:deptDefaultRisk
            });
        });
    }

    getObservationList = () => {
        this.setState({
			showLoader: true,
		});

        Promise.all([
            fetch(Constants.GetFollowUpDraftReportHeadings, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                    departmentId: this.state.departmentId,
                    donotReport: "N",
                }),
            }),
            fetch(Constants.GetAssignmentFollowUpObservations, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
				}),
            }),
        ])
        .then(([observationRes,assignFollowUpObservation]) => {
            return Promise.all([observationRes.json(),assignFollowUpObservation.json()]);
        })
        .then(([observationRes,assignFollowUpObservation]) => {
            let headingMap = [];
            let rootFunctionArr = [];
            let assignObservation = {};
            let myDatalistTemp = [];

            if (
                observationRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                observationRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (observationRes.responseCode === Constants.CODE_SUCCESS) {
                headingMap = observationRes.result.headingMap;
                rootFunctionArr = this.getRootLevelFunctions(headingMap);

                let allDataList = JSON.parse(JSON.stringify(rootFunctionArr));
                myDatalistTemp = allDataList.map(observation=>{
                    let observationList = [];
                    observation.obsList.forEach(item=>{
                        if(item.userId === this.state.selectedResourceId){
                            observationList.push(item);
                        }
                    })
                    observation.obsList = observationList;
                    return observation;
                })
            } else {
            }

            let followUpObservationTemp = [];
            if(
                assignFollowUpObservation.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignFollowUpObservation.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ){
                localStorage.clear();
                window.location = "/";
            } else if(assignFollowUpObservation.responseCode === Constants.CODE_SUCCESS) {
                followUpObservationTemp = assignFollowUpObservation.data.followUpObservations;
                followUpObservationTemp.forEach(item=>{
                    assignObservation[item.observationId] = item;
                })
                            
                let datalistTemp = {};
                rootFunctionArr.map(item => {
                    let updatedTaskList = [];
                    item.obsList.forEach(observation=>{
                        if(!isEmptyVariable(assignObservation[observation.observationId])){
                            const key = observation.observationId;
                            observation.resourceName = isEmptyVariable(assignObservation[key].userName)?"":assignObservation[key].userName;
                            observation.resourceId = isEmptyVariable(assignObservation[key].userId)?"":assignObservation[key].userId;
                            observation.resourcefullName = (isEmptyVariable(assignObservation[key].userName)?"":assignObservation[key].userName)+"("+(isEmptyVariable(assignObservation[key].designation)?"":assignObservation[key].designation)+")";
                            observation.resourceDesignation = isEmptyVariable(assignObservation[key].designation)?"":assignObservation[key].designation;
                        }
                        updatedTaskList.push(observation);
                    });
                    item.obsList = updatedTaskList;
                    datalistTemp[item.nodeId] = item;
                })
            }

            this.setState({
                datalist: myDatalistTemp,
                allDatalist: rootFunctionArr,
                showLoader: false,
                followUpObservation:followUpObservationTemp,
            });
        })
    }

    getFollowupTaskTemplatevalues = (observationId) => {
        this.setState({
			showLoader: true,
		});

        fetch(Constants.GetFollowUpTaskTemplateValues, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                observationId: observationId,
            }),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                let followUpTaskTemplateLists = data.data;

                this.setState({
                    followUpTaskTemplateList: followUpTaskTemplateLists,
                    observationId: observationId,
                    showLoader: false,
                })
            } else {
                this.setState({
                    showLoader: false,
                });
            }
        });
    }
    
    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
        };
        let callApi = true;

        if(this.state.operationType === "Follow Up Details"){
            url = Constants.GetPreviousFollowUpTaskTemplateValues;
            postParam.observationId = this.state.deleteReactivateId;
        }else if (this.state.operationType === "Submit For Review"){
            url = Constants.SubmitFollowUpObservationForReview;
            postParam.assignmentId = this.state.assignmentId;
            postParam.observationId = this.state.deleteReactivateId;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.UpdateFollowUpObservationReviewStatus;
            postParam.assignmentId = this.state.assignmentId;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Update Review Status"){
            url = Constants.UpdateFollowUpObservationReviewStatus;
            postParam.assignmentId = this.state.assignmentId;
            postParam.observationId = this.state.deleteReactivateId;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_APPROVED;
            if (!isEmptyVariable(this.state.remarks)){
                postParam.remark = this.state.remarks;
            }else{
                postParam.remark = "";
            }
        }

        if (callApi) {
            this.setState({
                showBtnLoader: true,
            });

            fetch(url, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams(postParam),
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                ) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.setState({
                        showBtnLoader: false,
                        deleteReactivateId: "",
                        deleteReactivateMasterType: "",
                        operationType: "",
                        alertDialogHeading: "",
                        proceedBtnLabel: "",
                        alertDialogMessage: "",
                        showAlertDialog: false,
                        remarks: "",
                    },() => {
                        this.getObservationList();
                    });
                } else {
                    this.setState({
                        showBtnLoader: false,
                        deleteReactivateId: "",
                        deleteReactivateMasterType: "",
                        operationType: "",
                        alertDialogHeading: "",
                        proceedBtnLabel: "",
                        alertDialogMessage: "",
                        showAlertDialog: false,
                        showAlertDialogInfo: true,
                        alertDialogMessageInfo: data.responseMessage,
                        remarks: "",
                    });
                }
            });
        }
    };

    getPreviousFollowUpTaskTemplateValues = (observationId,item) => {
        fetch(Constants.GetPreviousFollowUpTaskTemplateValues, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                observationId: observationId
            }),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                const previousCheckAndOpen = data.data.previousCheckAndOpen;
                this.handleFollowUpDetailsDialogShow(previousCheckAndOpen,observationId,item);
            } else {
                this.setState({
                    showFollowUpDetailsDialog:false,
                });
            }
        });
    }
    /*******************API CALLS END HERE*******************/

    render() {
        let showEmptyCard = true;
        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />
                        <div className="dashboard-right-bg-collpase">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.componentDidMountFlag &&  this.state.isShowHeader && (
                                    <FollowupHeading
                                        assignmentId={this.state.assignmentId}
                                        assignmentDetails={this.state.assignmentDetails}
                                        assignmentMatrix={this.state.assignmentMatrix}
                                        userDetails ={userDetails}
                                        selectedResourceId={this.state.selectedResourceId}
                                        themeSettings={themeSettings}
                                    />
                                )}
                                {
                                    this.state.showLoader && (
                                        <div class="loader"></div>
                                )}
                                {
                                    this.state.isShowHeader &&
                                    <div className="exe-followup-resource-dd flex-center-layout">
                                        {!isEmptyVariable(this.state.observationTemplateId) &&
                                            <div style={{ marginRight: 10 }}>
                                                <FilterDropDown
                                                    placeholder={this.state.observationTaskFilterPlaceholder}
                                                    dropdownArr={this.state.observationTaskFilterDropDownArr}
                                                    onDropDownItemClick={this.observationFilterDropDownOnClicks}
                                                    themeSettings={themeSettings}
                                                    name={"label"}
                                                    dropdownWidth="200px"
                                                />
                                            </div>
                                        }
                                        {
                                            (this.state.myPosition === "Viewer" ||
                                            this.state.myPosition === "Reviewer") && 
                                            <div style={{width:"200px", marginRight: 30}}>
                                                <RegularDD
                                                    placeholder={this.state.selectedResourceUserName}
                                                    dropdownArr={this.state.assignmentMatrix}
                                                    labelParam="label"
                                                    onDropDownItemClick={this.resourceDropdownItemClick}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag && 
									!this.state.showObsDetailsLayout && (
                                        <div className="body-wrapper common-table-scroll">
                                            {!isEmptyArray(this.state.datalist) && 
                                            this.state.datalist.map(item=>{
                                                return(
                                                    !isEmptyArray(item.obsList) &&
                                                    <div className="common-tab-bg mt-2">
                                                        <div className="common-table" style={{ width: "100%" }}>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr style={{height:"44px"}}>
                                                                        <th>Observation</th>
                                                                        <th style={{minWidth:"100px"}}>Resource</th> 
                                                                        <th style={{minWidth:"100px"}}>Follow Up Date</th>
                                                                        <th></th>
                                                                        <th width={"15%"}>Follow Up Risk</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {!isEmptyArray(item.obsList) &&
                                                                        item.obsList.map((observationItem, idx) => {
                                                                        showEmptyCard = false;
                                                                        let userDetails = this.state.followUpObservation.filter(e => e.observationId == observationItem.observationId); 
                                                                        let actionObj = getFollowUpTasks(observationItem, this.state.assignmentDetails?.reviewAssignmentTask);
                                                                        return (
                                                                                <tr style={{ background: this.observationEditable(observationItem) ? "#f5f5f5" : "#ffffff",height:"44px"}}>
                                                                                    <td onClick={this.handleShowObsDetailsLayout.bind(this, observationItem.observationId)}>
                                                                                        {
                                                                                            !isEmptyVariable(observationItem.observationDetail)?<div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: observationItem.observationDetail}} />:""
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            isEmptyArray(userDetails)?"":(isEmptyVariable(userDetails[0].userName)+isEmptyVariable(userDetails[0].designation))?"":userDetails[0].userName+"("+userDetails[0].designation+")"
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            isEmptyArray(userDetails)?"":isEmptyVariable(userDetails[0].followUpDate)?"":moment(userDetails[0].followUpDate).format("DD/MM/YYYY")
                                                                                        }
                                                                                    </td>
                                                                                    <td style={{minWidth:"100px"}}>
                                                                                        <RegularDD
                                                                                            placeholder={isEmptyVariable(observationItem.closeOpenStatus)?this.state.filterOpenClosePlaceholder:observationItem.closeOpenStatus}
                                                                                            dropdownArr={this.state.filterOpenCloseDropDownArr}
                                                                                            labelParam="label"
                                                                                            onDropDownItemClick={this.filterOpenCloseDropDownOnClick.bind(this,observationItem.observationId)}
                                                                                            disabled={this.observationEditable(observationItem)}
                                                                                        />
                                                                                    </td>
                                                                                    {
                                                                                        !isEmptyVariable(this.state.assignmentDetails.riskId) &&
                                                                                        <td>
                                                                                            {
                                                                                                isEmptyVariable(observationItem.followUpRiskLevel)?
                                                                                                <div className="risk-level-color-wrapper" style={{ width: "110px",height:"30px", marginLeft:0 }}
                                                                                                onClick={this.observationEditable(observationItem)?null:this.handleSelectRiskDialogShow.bind(this,observationItem)}/>
                                                                                                :
                                                                                                <div onClick={this.observationEditable(observationItem)?null:this.handleSelectRiskDialogShow.bind(this,observationItem)}>
                                                                                                    {isEmptyVariable(observationItem.followUpRiskLevel)?"":observationItem.followUpRiskLevel}
                                                                                                </div>
                                                                                            }
                                                                                        </td>
                                                                                    }
                                                                                    {
                                                                                        !isEmptyVariable(this.state.assignmentStatus) && this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_COMPLETED && (
                                                                                        <td className="text-center">
                                                                                            {actionObj.actionArr && actionObj.actionArr.length > 0 && (
                                                                                                <TableDropDown
                                                                                                    actionArr={actionObj.actionArr}
                                                                                                    onDropDownItemClick={this.onTableDropDownItemClick}
                                                                                                    dropDownId={observationItem}
                                                                                                    dropdownWidth={actionObj.dropdownWidth}
                                                                                                    themeSettings={themeSettings}
                                                                                                />
                                                                                            )}
                                                                                        </td>
                                                                                    )}
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                )}
                                            )}
                                            {this.state.componentDidMountFlag && showEmptyCard && (
                                                <div class="no-items-layout">
                                                    <div class="no-items-card">
                                                        <h6>
                                                            {Constants.NO_RECORDS_WARNING}
                                                        </h6>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                                {
                                    this.state.showObsDetailsLayout &&
                                    <ObservationDetailsLayout
                                        observationId = {this.state.selectedObsIdForDetails}
										assignmentId = {this.state.assignmentId}
                                        handleHideObsDetailsLayout = {this.handleHideObsDetailsLayout}
                                        handleShowObsDetailsLayout={this.handleShowObsDetailsLayout}
                                    />
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={Constants.ALERT_TYPE_INFO}
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            showLoader={this.state.showBtnLoader}
                            themeSettings={themeSettings}
                        />
                        <AddFollowUpTemplateValueDialog
                            showFollowUpTemplateValueDialog={this.state.showFollowUpTemplateValueDialog}
                            handleFollowUpTemplateValueDialogClose={this.handleFollowUpTemplateValueDialogClose}
                            isFollowUpTemplateValueEditDialog={false}
                            themeSettings={themeSettings}
                            followUpTaskTemplateList={this.state.followUpTaskTemplateList}
                            observationId={this.state.observationId}
                            assignmentId={this.state.assignmentId}
                            selectFollowUpTemplate={this.state.selectFollowUpTemplate}
                            title={"Follow Up"}
                        />
                        {this.state.showFollowUpDetailsDialog &&
                            <FollowUpDetailsDialog
                                showFollowUpDetailsDialog={this.state.showFollowUpDetailsDialog}
                                followUpDetailsItem={this.state.followUpDetailsItem}
                                handleFollowUpDetailsDialogClose={this.handleFollowUpDetailsDialogClose}
                                themeSettings={themeSettings}
                                item={this.state.followUpObservationItem}
                                observationId={this.state.observationId}
                                assignmentId={this.state.assignmentId}
                            />
                        }
                        <FollowUpUpdateAssignmentTaskReviewStatus
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterDataId={this.state.deleteReactivateId}
                            observationId={this.state.deleteReactivateId}
                            assignmentId={this.state.assignmentId}
                            reviewLevel={this.state.reviewLevel}
                            currentReviewLevel={this.state.currentReviewLevel}
                            endReviewLevel={this.state.endReviewLevel-1}
                            themeSettings={themeSettings}
                        />
                        <SelectRiskDialog
                            showRiskDialog={this.state.showSelectRiskDialog}
                            handleSelectRiskDialogClose={this.handleSelectRiskDialogClose}
                            handleSelectRiskDialogSaveClose={
                                this.handleSelectRiskDialogSaveClose
                            }
                            observationId={this.state.observationId}
                            selectedRiskObj={this.state.selectedRiskObj}
                            riskArr={
                                isEmptyArray(
                                    this.state.deptDefaultRisk[this.state.assignmentDetails.departmentId]
                                )? []
                                    : this.state.deptDefaultRisk[this.state.assignmentDetails.departmentId]
                            }
                            themeSettings={themeSettings}
                        />
                        <ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType={"Follow Up Observation"}
                            masterDataId={this.state.deleteReactivateId}
                        />
                    </section>
                </main>
            </Router>
        )
    }
}
export default FollowUpObservation;