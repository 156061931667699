import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {PageSaveButton,TableCheckBox,CustomTableRow,DialogSaveButton} from '../../../common/Global/globalStyles.style';
// import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
// import AlertDialog from '../../../common/AlertDialog';
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable,truncateString,
    removeHtmlTags,isEmptyArray} from '../../../common/Global/commonFunctions';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdownTable';
import {Modal} from 'react-bootstrap';
import ProcessFunctionListDialog from "../ProcessFunctionsDialog/processFunctionsList"
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();

const activeStatus="Active";
// const inActiveStatus="Inactive";
// const underReviewStatus="Under Review";
const wrankingChecklist = "WR";
const defaultWeightPlaceholder="Select Weight";
const weightedRankingArr = [
    {label:1,id:1},
    {label:2,id:2},
    {label:3,id:3},
    {label:4,id:4},
    {label:5,id:5},
    {label:6,id:6},
    {label:7,id:7},
    {label:8,id:8},
    {label:9,id:9},
    {label:10,id:10}
];

class OtherMasters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            resultSizePlaceholder:Constants.RESULT_SIZE,
            status:activeStatus,

            functionMap:[],
            functionId:this.props.functionId,

            checkListTasks:[],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            allTaskIds:[],
            nextTaskId:"",
            prevTaskId:"",

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:"",
            user:{},

            guidanceDialogFlag:false,
            isEdit:false,
            selectedRankingGuidance:"",
            selectedRankingGuidanceIndex:"",
            // formErrors:{},
            // addErrors:{},

            showProcessFunctionsDialog:false,
        }
    }

    componentDidMount(){
        this.getInitData();
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.setState({
                componentDidMountFlag:false,
                apiSearchKey:"",
                searchkey:"",
                currentPageNo: 1,
                showLoader:false,
                resultSize:Constants.RESULT_SIZE,
                totalCount:0,
                datalist:[],
                resultSizePlaceholder:Constants.RESULT_SIZE,
                status:activeStatus,

                functionMap:[],
                functionId:this.props.functionId,

                checkListTasks:[],
                resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
                allTaskIds:[],
                nextTaskId:"",
                prevTaskId:"",

                showAlertDialogInfo:false,
                alertDialogMessageInfo:"",

                showTaskDetailsLayout:false,
                selectedTaskIdForDetails:"",
                user:{},

                guidanceDialogFlag:false,
                isEdit:false,
                selectedRankingGuidance:"",
                selectedRankingGuidanceIndex:"",
                // formErrors:{},
                // addErrors:{},

                showProcessFunctionsDialog:false,
            },()=>{
                this.getInitData();
            })
            
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleGuidanceDialogClose = () => {
        this.setState({
            guidanceDialogFlag:false,
            isEdit:false,
            selectedRankingGuidance:"",
            selectedRankingGuidanceIndex:"",
        })
    }

    handleAddGuidanceDialogShow = (functionId, index) => {
        this.setState({
            guidanceDialogFlag:true,
            isEdit:false,
            selectedRankingGuidance:"",
            selectedRankingGuidanceIndex:index+":-:"+functionId,
        })
    }

    handleEditGuidanceDialogShow = (functionId, index) => {
        let list = this.state.datalist;
        //if not disabled then show the edit dialog
        if(!list[functionId].taskList[index].disabled){
            this.setState({
                guidanceDialogFlag:true,
                isEdit:true,
                selectedRankingGuidance:list[functionId].taskList[index].rankingGuidance,
                selectedRankingGuidanceIndex:index+":-:"+functionId,
            })
        }
    }
    maxrDropdownClick = (selectedItem,udf) => {
        this.setState({
            showLoader:true
        })

        let iapArr = udf.split(":-:")
        let index = iapArr[0];
        let functionId = iapArr[1];
        let list = this.state.datalist;
        let selectedTaskId = list[functionId].taskList[index].taskId;
        let taskObj = list[functionId].taskList[index];
        let newlist = {};

        for(const [key,value] of Object.entries(list)){
            let newVal = value;
            let newtaskList = value.taskList.map(item=>{
                if(item.taskId === selectedTaskId){
                    item.weight = selectedItem.id;
                }
                return item;
            })
            newVal.taskList = newtaskList;
            newlist[key] = newVal;
        }

        
        this.setState({
            datalist:newlist,
            showLoader:false
        },()=>{
            if(taskObj.isAlreadySelected){
                this.updateChecklistTaskProperties(taskObj.checkListTaskId,taskObj.weight,taskObj.rankingGuidance);
            }
        });
    }

    handleAddRankingGuidance = () => {
        let iapArr = this.state.selectedRankingGuidanceIndex.split(":-:");
        let index = iapArr[0];
        let functionId = iapArr[1];
        let list = this.state.datalist;
        let newlist = {};
        let selectedTaskId = list[functionId].taskList[index].taskId;
        let taskObj = list[functionId].taskList[index];

        for(const [key,value] of Object.entries(list)){
            let newVal = value;
            let newtaskList = value.taskList.map(item=>{
                if(item.taskId === selectedTaskId){
                    item.rankingGuidance = this.state.selectedRankingGuidance;;
                }
                return item;
            })
            newVal.taskList = newtaskList;
            newlist[key] = newVal;
        }

        this.setState({
            datalist:newlist,

            guidanceDialogFlag:false,
            isEdit:false,
            selectedRankingGuidance:"",
            selectedRankingGuidanceIndex:"",
        },()=> {
            if(taskObj.isAlreadySelected){
                this.updateChecklistTaskProperties(taskObj.checkListTaskId,taskObj.weight,taskObj.rankingGuidance);
            }
        });
    }

    onCancelClick = () => {
        this.props.handleBackBtnClick();
    }

    handleProcessFuncDialogClose = () => {
        this.setState({
            showProcessFunctionsDialog:false,
        })
    }

    handleProcessFuncDialogShow = () => {
        this.setState({
            showProcessFunctionsDialog:true,
        })
    }

    //No need of return value since the array itself is passed and updated.
    addParentIdandHasChildrenFlag = (tempArr, functionId, parentFunctionId, parentFunctionBreadCrumb) => {
        tempArr[functionId].parentFunctionId = parentFunctionId;
        let breadCrumb = "";
        if(isEmptyVariable(parentFunctionBreadCrumb)){
            breadCrumb = tempArr[functionId].functionName;
            tempArr[functionId].breadCrumb = breadCrumb;
        }else{
            breadCrumb = parentFunctionBreadCrumb + " > " + tempArr[functionId].functionName;
            tempArr[functionId].breadCrumb = breadCrumb;
        }

        if(!isEmptyArray(tempArr[functionId].children)){
            tempArr[functionId].hasChildren = true;

            tempArr[functionId].children.map(childNode => {
                this.addParentIdandHasChildrenFlag(tempArr,childNode.functionId,functionId,breadCrumb);
            });
        }else{
            tempArr[functionId].hasChildren = false;
        }
    }

    handleShowTaskDetailsLayout = (taskId) => {
        let taskIdIndex = this.state.allTaskIds.indexOf(taskId);
        let prevIndex = -1;
        let nextIndex = -1;

        if(taskIdIndex > -1){
            prevIndex = taskIdIndex-1;
            nextIndex = taskIdIndex+1;

            if(nextIndex >= this.state.allTaskIds.length){
                nextIndex = -1;
            }
        }

        this.setState({
            showTaskDetailsLayout:true,
            selectedTaskIdForDetails:taskId,
            prevTaskId:prevIndex > -1?this.state.allTaskIds[prevIndex]:"",
            nextTaskId:nextIndex > -1?this.state.allTaskIds[nextIndex]:"",
        })
    }

    handleHideTaskDetailsLayout = () => {
        this.setState({
            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:""
        })
    }

    handleStopPropagation = (e) => {
        e.stopPropagation();
    }

    handleSelection = (e) => {
        e.stopPropagation();
        this.setState({
            showLoader:true
        })
        let isChecked = e.target.checked;
        let indexAndFunctionId = e.target.name;
        let iapArr = indexAndFunctionId.split(":-:")
        let index = iapArr[0];
        let functionId = iapArr[1];
        let list = this.state.datalist;

        list[functionId].taskList[index].isSelected = isChecked;;

        // for(const [key,value] of Object.entries(list)){
        //     let newVal = value;
        //     let newtaskList = value.taskList.map(item=>{
        //         if(item.taskId === selectedTaskId){
        //             item.isSelected = isChecked;
        //         }
        //         return item;
        //     })
        //     newVal.taskList = newtaskList;
        //     newlist[key] = newVal;
        // }

        //check whether all the tasks with in func are selected
        let atleastOneNotSelected = list[functionId].taskList.some(item => !item.isSelected)
        list[functionId].isFuncSelected = !atleastOneNotSelected;

        this.setState({
            datalist:list,
            showLoader:false
        });
    }

    handleSelectionFunctionTasks = (e) => {
        e.stopPropagation();
        let isChecked = e.target.checked;
        let functionId = e.target.name;
        let list = this.state.datalist;

        list[functionId].isFuncSelected = isChecked;

        // also select all the tasks
        let newTasklist = list[functionId].taskList.map(item=>{
            if(!item.disabled){ //if item is not disabled then only change it
                item.isSelected = isChecked
            }
            return item;
        })
        list[functionId].taskList = newTasklist;
        this.setState({
            datalist:list
        });
    }
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetFunctionsSubtree,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    rootFunctionId:this.state.functionId,
                })
            }),
            fetch(Constants.GetAddTaskChecklistMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    functionId:this.state.functionId,
                    checkListVersionId:this.props.checklistItem.checkListVersionId
                })
            }),
            fetch(Constants.GetCheckListTasks,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    checkListVersionId:this.props.checklistItem.checkListVersionId
                })
            })
        ])
        .then(([masterRes,generalRes,clTasksRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),clTasksRes.json()]) 
        })
        .then(([masterRes,generalRes,clTasksRes]) => {
            let functionMapTemp = {};
            let datalistTemp = {};
            let allTaskIds = [];
            let checkListTasks = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                functionMapTemp = masterRes.result.functionMap;
                this.addParentIdandHasChildrenFlag(functionMapTemp,this.state.functionId,this.state.functionId,"");
            }else{
                functionMapTemp = [];
            }

            if(clTasksRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                clTasksRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(clTasksRes.responseCode === Constants.CODE_SUCCESS){
                checkListTasks = clTasksRes.data.tasks;
            }else{
                checkListTasks = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                let datalistTempArr = generalRes.data.functionList;//list of process and task
                allTaskIds = generalRes.data.allTaskIds;
                datalistTemp = {};

                datalistTempArr.map((item)=>{
                    datalistTemp[item.functionId] = item;
                    datalistTemp[item.functionId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists

                    item.taskList.map((task,idx)=>{
                        //if it is already added to the list then make it checked
                        //if it is added but added to different heading then disable it

                        let checkedTask = checkListTasks.filter(checkedTask => checkedTask.taskId === task.taskId)

                        if(isEmptyArray(checkedTask)){
                            datalistTemp[item.functionId].taskList[idx].isSelected = false;
                            datalistTemp[item.functionId].taskList[idx].disabled = false;
                        }else{
                            datalistTemp[item.functionId].taskList[idx].isSelected = true;
                            datalistTemp[item.functionId].taskList[idx].isAlreadySelected = true;

                            // add weight and ranking guidance
                            datalistTemp[item.functionId].taskList[idx].weight=checkedTask[0].weight;
                            datalistTemp[item.functionId].taskList[idx].rankingGuidance=checkedTask[0].rankingGuidance;
                            datalistTemp[item.functionId].taskList[idx].checkListTaskId=checkedTask[0].checkListTaskId;

                            //if heading id is same then dont disable the task
                            if(this.props.checkListHeadingId === checkedTask[0].checkListHeadingId){
                                datalistTemp[item.functionId].taskList[idx].disabled = false;
                            }else{
                                datalistTemp[item.functionId].taskList[idx].disabled = true;
                            }
                        }
                    })
                })
            }else{
                datalistTemp = [];
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                functionMap:functionMapTemp,
                checkListTasks:checkListTasks,
                allTaskIds:allTaskIds,
                datalist:datalistTemp,
            })
        })
    }

    submitChecklist = () => {

        this.setState({
            showLoader:true
        })

        let addTaskList = [];
        let removeTaskList = [];
        let isError = false;
        let formErr = {};
        
        Object.values(this.state.datalist).map(functionObj=>{
            functionObj.taskList.map(item=>{
                if(item.isSelected){
                    //now we need to check if the task is already added
                    let checkedTask = this.state.checkListTasks.filter(checkedTask => checkedTask.taskId === item.taskId)

                    if(isEmptyArray(checkedTask)){
                        //item is not there in already
                        let obj = {
                            taskId:item.taskId,
                            weight:isEmptyVariable(item.weight)?"":item.weight,
                            rankingGuidance:isEmptyVariable(item.rankingGuidance)?"":item.rankingGuidance,
                        }
                        addTaskList.push(obj)

                        //Weight is mandatory for WR checklist type
                        if(this.props.checklistItem.checkListType === wrankingChecklist){
                            if(isEmptyVariable(item.weight)){
                                isError = true;
                                // formErr["weightErr"] = "Please select weight for all the checked tasks"
                            }
                        }
                    }
                }else{
                    //if the item is selected before and now it is removed
                    //then we have add it to the removed task
                    if(item.isAlreadySelected){
                        let obj = {
                            taskId:item.taskId,
                        }
                        removeTaskList.push(obj)
                    }
                }
            })
        })

        //now do the validation
        if(isError){
            toast.warning("Please select weight for all the checked tasks");
            // this.setState({
            //     addErrors:formErr
            // });

            // setTimeout(() => {
            //     this.setState({
            //         formErrors:{},
            //     });
            // },Constants.WRNG_MSG_TIMEOUT);
        }else{
            fetch(Constants.AddRemoveCheckListTasks,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    checkListVersionId:this.props.checklistItem.checkListVersionId,
                    checkListHeadingId:this.props.checkListHeadingId,
                    addTaskList:JSON.stringify(addTaskList),
                    removeTaskList:JSON.stringify(removeTaskList)
                })
            })
            .then(response => { return response.json(); } )
            .then(data => {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.props.handleBackBtnClick();
                }else{
                    // formErr["weightErr"] = data.responseMessage;
                    toast.error(data.responseMessage);
                    // this.setState({
                    //     addErrors:formErr
                    // });
        
                    // setTimeout(() => {
                    //     this.setState({
                    //         formErrors:{},
                    //     });
                    // },Constants.WRNG_MSG_TIMEOUT);
                }
            })
        }
        

        
    }

    updateChecklistTaskProperties = (checkListTaskId,weight,rankingGuidance) => {
        fetch(Constants.UpdateChecklistTaskProperties,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    checkListTaskId:checkListTaskId,
                    weight:isEmptyVariable(weight)?"":weight,
                    rankingGuidance:isEmptyVariable(rankingGuidance)?"":rankingGuidance,
                })
            })
            .then(response => { return response.json(); } )
            .then(data => {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    
                }else{
                    //TODO go back to the previous value and show error dialog
                }
            });
    }
    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <div>
                {
                    this.state.showLoader &&
                    <div class="loader"></div>
                }
                {
                    // Need to check component did mount flag, other wise until api is called,
                    // it shows no items found layout.
                    this.state.componentDidMountFlag &&
                    !this.state.showTaskDetailsLayout &&
                    <div className="body-wrapper">
                        <div className="flex-center-layout secondary-top-bar">
                            <div className="back-btn-layout" onClick={this.props.handleBackBtnClick}>
                                <span class="material-icons-outlined">arrow_back_ios</span>
                            </div>
                            <h6>{this.props.checklistItem.checkListName+" > "+this.props.checkListHeading}</h6>
                        </div>
                        {/* <div className="search-addnew-bg">
                            <div className="search-addnew">
                                
                                <div className="search-filter-layout">
                                    <div className="search-col">
                                        <input 
                                            name="searchkey" 
                                            type="text"
                                            onChange={this.handleChangeSearch}
                                            placeholder="Search" 
                                            onKeyPress={this.onEnterBtnPress}
                                            value={this.state.searchkey}
                                        />
                                        <SearchSpan 
                                        themeSettings={themeSettings}
                                        onClick = {this.onSearchIconClick} 
                                        className="material-icons"> search </SearchSpan>
                                    </div>
                                    <FilterDropDown
                                        placeholder={this.state.resultSizePlaceholder}
                                        dropdownArr={this.state.resultSizeArr}
                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                        themeSettings={themeSettings}
                                    />
                                </div>
                            </div>
                        </div> */}

                        {
                            Object.values(this.state.datalist).map((functionObj,index)=>{
                                return <div className="process-task-list">
                                    <h6 style={{marginTop:index === 0?15:10}}>{this.state.functionMap[functionObj.functionId].breadCrumb}</h6>
                                    {
                                        !isEmptyArray(functionObj.taskList) && 
                                        <div className="common-tab-bg">
                                            <div class="common-table" style={{width: "100%", borderRadius: "4px"}}>
                                                <div className="table-custom-layout">
                                                    <div className="table-custom-header">
                                                        <div className="table-custom-header-row">
                                                            <p style={{width:"5%"}}>
                                                                <TableCheckBox onClick={this.handleStopPropagation}
                                                                    themeSettings={themeSettings}>
                                                                    <input 
                                                                        type="checkbox"
                                                                        name={functionObj.functionId}
                                                                        id={functionObj.functionId}
                                                                        onChange={this.handleSelectionFunctionTasks}
                                                                        checked={functionObj.isFuncSelected?true:false}
                                                                    />
                                                                    <label for={functionObj.functionId} 
                                                                    style={{marginLeft:-3}}></label>
                                                                </TableCheckBox>
                                                            </p>
                                                            <p style={{width:"5%",justifyContent:"center"}}>No</p>
                                                            {
                                                                this.props.checklistItem.checkListType === wrankingChecklist &&
                                                                <p style={{width:"50%"}}>Task</p>
                                                            }
                                                            {
                                                                this.props.checklistItem.checkListType !== wrankingChecklist &&
                                                                <p style={{width:"65%"}}>Task</p>
                                                            }
                                                            
                                                            {
                                                                this.props.checklistItem.checkListType === wrankingChecklist &&
                                                                <p style={{width:"15%"}}>Weight</p>
                                                            }
                                                            <p style={{width:"25%"}}>Ranking Guidance</p>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="table-custom-body">
                                                    {
                                                        !isEmptyArray(functionObj.taskList) && 
                                                        functionObj.taskList.map((item,idx) => {
                                                            return <CustomTableRow themeSettings={themeSettings}
                                                            className={item.disabled?"row-disable":""}>
                                                                {/* // onClick={this.handleShowTaskDetailsLayout.bind(this,item.taskId)}> */}
                                                                <div className="table-cell" style={{width:"5%"}}>
                                                                    <TableCheckBox onClick={this.handleStopPropagation}
                                                                        themeSettings={themeSettings}>
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={idx+":-:"+functionObj.functionId}
                                                                            id={item.taskId}
                                                                            onChange={this.handleSelection}
                                                                            disabled={item.disabled}
                                                                            checked={item.isSelected?true:false}
                                                                        />
                                                                        <label for={item.taskId} 
                                                                        style={{marginLeft:6}}></label>
                                                                    </TableCheckBox>
                                                                </div>
                                                                <p style={{width:"5%",justifyContent:"center"}}>{(idx+1)}</p>
                                                                {
                                                                    this.props.checklistItem.checkListType === wrankingChecklist &&
                                                                    <p style={{width:"50%"}}>{removeHtmlTags(item.task)}</p>
                                                                }
                                                                {
                                                                    this.props.checklistItem.checkListType !== wrankingChecklist &&
                                                                    <p style={{width:"65%"}}>{removeHtmlTags(item.task)}</p>
                                                                }
                                                                {
                                                                    this.props.checklistItem.checkListType === wrankingChecklist &&
                                                                    <p style={{width:"15%"}}>
                                                                        <RegularDropdown 
                                                                            placeholder={isEmptyVariable(item.weight)?defaultWeightPlaceholder:item.weight}
                                                                            dropdownArr={weightedRankingArr}
                                                                            labelParam="label"
                                                                            onDropDownItemClick={this.maxrDropdownClick}
                                                                            udf={idx+":-:"+functionObj.functionId}
                                                                            disabled={item.disabled}
                                                                            defaultPlaceholderDropDown={defaultWeightPlaceholder}
                                                                        />
                                                                    </p>
                                                                }
                                                                <p style={{width:"25%"}}>

                                                                    {
                                                                        isEmptyVariable(item.rankingGuidance)
                                                                        ?
                                                                            <button className="add-guidance-btn"
                                                                            disabled={item.disabled}
                                                                            onClick={this.handleAddGuidanceDialogShow.bind(this,functionObj.functionId,idx)}>Add</button>
                                                                        :
                                                                            <div className="guidance-layout">
                                                                                <p>{truncateString(item.rankingGuidance,Constants.DEPT_STRING_LENGTH)}</p>
                                                                                <span className="material-icons" 
                                                                                disabled={item.disabled}
                                                                                onClick={this.handleEditGuidanceDialogShow.bind(this,functionObj.functionId,idx)}>edit</span>
                                                                            </div>
                                                                    }

                                                                </p>
                                                            </CustomTableRow>
                                                        })
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loadmore-layout">
                                        <div>
                                        {/* {
                                            functionObj.currentPageNo*this.state.resultSize < functionObj.count &&
                                            <LoadMoreButton 
                                            themeSettings = {themeSettings}
                                            onClick={this.onChangePage.bind(this,functionObj.functionId)} type="button">Load More</LoadMoreButton>
                                        } */}
                                        </div>
                                        {
                                            functionObj.count > 0 &&
                                            <p>{"Total Records: "+functionObj.count}</p>
                                        }
                                    </div>
                                    {
                                        this.state.componentDidMountFlag && isEmptyArray(functionObj.taskList) &&
                                        <div class="no-items-layout">
                                            <div class="no-items-card" style={{padding:"10px"}}>
                                                <h6 style={{margin:0}}>
                                                {
                                                    isEmptyVariable(this.state.apiSearchKey)?
                                                    Constants.NO_RECORDS_WARNING
                                                    :
                                                    Constants.EMPTY_SEARCH_WARNING
                                                }
                                                </h6>
                                            </div>
                                        </div>
                                    }
                                </div>
                            })
                        }
                        {/* {
                            !isEmptyVariable(this.state.addErrors['weightErr']) &&
                            <span class="cm-error">{this.state.addErrors['weightErr']}</span>
                        } */}
                        <div className="row custom-row">
                            <div className="col-md-12 custom-col">
                                <div className="common-close-save">
                                    <div className="common-close-save-col">
                                        <button onClick={this.onCancelClick} type="button" className="common-close">Cancel</button>
                                        <PageSaveButton 
                                        themeSettings = {themeSettings}
                                        onClick={this.handleProcessFuncDialogShow} type="button">Select Another Function</PageSaveButton>
                                        <PageSaveButton 
                                        themeSettings = {themeSettings}
                                        onClick={this.submitChecklist} type="button">Add to Checklist</PageSaveButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <ProcessFunctionListDialog
                    handleProcessFuncDialogClose = {this.handleProcessFuncDialogClose}
                    showProcessFunctionsDialog = {this.state.showProcessFunctionsDialog}
                    themeSettings={themeSettings}
                    currentPageNo={this.props.currentPageNo}
                    searchkey={this.props.searchkey}
                    resultSize={this.props.resultSize}
                    sort={this.props.sort}
                    sortDir={this.props.sortDir}
                    status={this.props.status}
                    handleActionDropdownItemClick={this.props.handleActionDropdownItemClick}
                    checkListItem = {this.props.checklistItem}
                    checkListHeadingId = {this.props.checkListHeadingId}
                    checkListHeading = {this.props.checkListHeading}
                    departmentId={this.props.processDeptId}
                />

                {
                    // this.state.showTaskDetailsLayout &&
                    // <TaskDetailsLayout
                    //     taskId = {this.state.selectedTaskIdForDetails}
                    //     handleHideTaskDetailsLayout = {this.handleHideTaskDetailsLayout}
                    //     subscriptionType={this.state.user.subscriptionType}
                    //     prevTaskId={this.state.prevTaskId}
                    //     nextTaskId={this.state.nextTaskId}
                    //     handleShowTaskDetailsLayout={this.handleShowTaskDetailsLayout}
                    // />
                }

                {/* <AlertDialog 
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type= {Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                    themeSettings={themeSettings}
                /> */}

                <Modal className="custom-dialog" show={this.state.guidanceDialogFlag} 
                backdrop="static">
                    <Modal.Header>
                        <h5>{this.state.isEdit?"Edit Ranking Guidance":"Add Ranking Guidance"}</h5>
                        <button 
                            onClick={this.handleGuidanceDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        <div class="addnew-modal-form">
                            <div class="addnew-modal-form-group">
                                <p>Ranking Guidance*</p>
                                <textarea type="text" placeholder="Enter Ranking Guidance"
                                name="selectedRankingGuidance" value={this.state.selectedRankingGuidance} 
                                onChange={this.handleChange}/>
                                {
                                    !isEmptyVariable(this.state.formErrors['selectedRankingGuidance']) &&
                                    <span class="cm-error">{this.state.formErrors['selectedRankingGuidance']}</span>
                                }
                            </div>
                        </div>
                        <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.handleGuidanceDialogClose} 
                                    type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton 
                                    themeSettings={themeSettings}
                                    onClick={this.handleAddRankingGuidance} type="button">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>

                </Modal>
            </div>
        );
    }
}

export default OtherMasters;