import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {DialogSaveButton,TreeNodeCheckBox, ExpandCollapseWrapper} from '../../../common/Global/globalStyles.style';
import { toast } from "react-toastify";

const userDetails  = getLocalStorageVariables();

const ProcessTree = (props) => {

    return <div className="dialog-tree-structure">
        <div className="tree-node checkboxparent" style={{marginLeft:(props.level*40)+"px"}}>
            <span 
                onClick={()=>props.onClickNode(props.processObj)}
                class="material-icons" style={{opacity:isEmptyArray(props.processObj.children)?"0":"1"}}>
                {props.processObj.isShowingChildren?"keyboard_arrow_down":"keyboard_arrow_right"}
            </span>
            <TreeNodeCheckBox
            themeSettings={props.themeSettings}>
                <input 
                    type="checkbox"
                    name={props.processObj.processId}
                    id={props.processObj.processId}
                    onChange={props.handleSelection}
                    checked={props.processObj.isSelected?true:false}
                    disabled={props.processObj.isDisabled}
                />
                <label for={props.processObj.processId} 
                style={{marginLeft:6}}>{props.processObj.processName}</label>
            </TreeNodeCheckBox>
            {/* <p for={props.processObj.processId}>{props.processObj.processName}</p> */}
        </div>

        {
            // Exit Condition
            props.processObj.isShowingChildren &&
            props.getChildNodes(props.processObj).map((child, idx)=>{
                let levelTemp = props.level + 1;
                return <ProcessTree 
                    processObj = {child} 
                    getChildNodes = {props.getChildNodes}
                    level={levelTemp}
                    handleSelection={props.handleSelection}
                    onClickNode={props.onClickNode}
                    themeSettings={props.themeSettings}
                />
            })
        }
    </div>
}

class SelectProcessDialog extends Component{
    constructor(props){
        super(props);
        this.state={
            processMap:{},
            rootNodes:[],
            refreshFlag:false,
            showLoader:false,
            // processFormErr:""
        }
        this.selectedProcessObjArr = [];
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showProcessesDialog){
            this.selectedProcessObjArr = [];
            this.setState({
                processMap:{},
                rootNodes:[],
                // processFormErr:"",
                refreshFlag:false,
                showLoader:false,
            },()=>{
                this.selectedProcessObjArr = this.props.selectedProcessObjArr;
                this.getProcessTree();
            })
            
        }
    }

    onClickNode = (node) => {
        if(this.state.processMap[node.processId].isShowingChildren){
            this.state.processMap[node.processId].isShowingChildren = false;
        }else{
            this.state.processMap[node.processId].isShowingChildren = true;
        }
        
        this.setState({
            refreshFlag:!this.state.refreshFlag
        })
    }

    handleSelectProcessDialogClose = () => {

    }

    getRootNodes = (processMap) => {
        return Object.values(processMap).filter(node => node.level === 0)
    }

    getChildNodes = (node) => {
        if(isEmptyArray(node.children)){
            return [];
        }else{
            return node.children.map(item => this.state.processMap[item.processId]);
        }
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let isChecked = e.target.checked;
        let processId = e.target.name;

        this.selectUnSelectAllChildren(processId,isChecked);

        this.setState({
            refreshFlag:!this.state.refreshFlag
        });
    }

    selectUnSelectAllChildren = (processId,isChecked) => {
        if (!this.state.processMap[processId].isDisabled) {
            this.state.processMap[processId].isSelected = isChecked;
        }
        //show children of checked item
        if(isChecked && !isEmptyArray(this.state.processMap[processId].children)){
            //Dont automatically expand the children - nayans input
            // this.state.processMap[processId].isShowingChildren = true;
        }

        this.state.processMap[processId].children.map(child => {
            this.selectUnSelectAllChildren(child.processId,isChecked);
        })
    }

    returnSelectedProcessId = () => {
        this.selectedProcessObjArr = [];
        // let processFormErrTemp = "";

        for (const [key, item] of Object.entries(this.state.processMap)) {
            if(item.isSelected){
                this.selectedProcessObjArr.push(item);
            }else{
            }
        }

        if(isEmptyArray(this.selectedProcessObjArr)){
            toast.warning("Select atleast one process");
            // processFormErrTemp =  "Select atleast one process";
            // this.setState({
            //     processFormErr:processFormErrTemp,
            // })

            // setTimeout(function(){
            //     this.setState({
            //         processFormErr:"",
            //     });
            // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }else{
            // alert(this.selectedProcessObjArr);
            this.props.handleSelectProcessDialogSaveClose(this.selectedProcessObjArr);
        }
    }

    expandCollapseAll = (processId,isChecked) => {
        this.state.processMap[processId].isShowingChildren = isChecked;

        this.state.processMap[processId].children.map(child => {
            this.expandCollapseAll(child.processId,isChecked);
        })

        this.setState({
            processMap:this.state.processMap
        })
    }

    expandAll = () => {
        this.state.rootNodes.map((processObj)=>{
            this.expandCollapseAll(processObj.processId,true);
        })
    }

    collapseAll = () => {
        this.state.rootNodes.map((processObj)=>{
            this.expandCollapseAll(processObj.processId,false);
        })
    }

    /***********************API CALLS***********************/
    getProcessTree = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetProcessesSubtree,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                departmentId:this.props.departmentId,
                activeFlag:"Y"//get values from active tables
                // rootFunctionId:this.props.rootFunctionId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                const processMap = data.result.processMap;
                const rootNodes = this.getRootNodes(processMap);

                //Select Checkboxes from selectedProcessObjArr array
                console.log(this.selectedProcessObjArr)
                console.log(processMap)
                this.selectedProcessObjArr.map((processObj)=>{
                    processMap[processObj.processId].isSelected =true;
                    processMap[processObj.processId].isDisabled =true;
                })

                this.setState({
                    showLoader:false,
                    processMap:processMap,
                    rootNodes:rootNodes
                });
            }else{
                this.setState({
                    processMap:{},
                    showLoader:false,
                });
            }
        });
    }

    render(){
        
        return(
            <Modal className="task-tree-dialog custom-dialog" 
            show={this.props.showProcessesDialog} backdrop="static">
                <Modal.Header>
                    <h5>Process</h5>
                    <button 
                        onClick={this.props.handleSelectProcessDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>

                <hr />
                <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }
                    <ExpandCollapseWrapper themeSettings={this.props.themeSettings}>
                            <div className="expand-collapse-layout"
                                onClick={this.expandAll}
                                style={{marginRight:10}}
                                type="button">
                                <span class="material-icons">unfold_more</span>
                                <p>Expand All</p>
                            </div>
                            <div
                                className="expand-collapse-layout"
                                onClick={this.collapseAll}
                                style={{marginLeft:10}}
                                type="button">
                                <span class="material-icons">unfold_less</span>
                                <p>Collapse All</p>
                            </div>

                        </ExpandCollapseWrapper>
                    <div className="body-wrapper">
                        <div className="tree-wrapper">
                            {
                                this.state.rootNodes.map((item)=>{
                                    return <ProcessTree 
                                        processObj = {item} 
                                        getChildNodes = {this.getChildNodes}
                                        level={0}
                                        handleSelection={this.handleSelection}
                                        themeSettings = {this.props.themeSettings}
                                        onClickNode={this.onClickNode}  //EXPAND/COLLAPSE
                                    />
                                })
                            }
                            {/* {
                                !isEmptyVariable(this.state.processFormErr) &&
                                <span className="cm-error">{this.state.processFormErr}</span>
                            } */}
                        </div>
                        <div className="modal-close-save m-t-15">
                            <div className="close-save-col">
                                <button onClick={this.props.handleSelectProcessDialogClose} type="button" className="modal-close m-b-0">Cancel</button>
                                <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                onClick={this.returnSelectedProcessId} type="button" 
                                className="modal-save m-b-0">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default SelectProcessDialog;